<div class="flex-container flex-container-left">
    <!-- <div class="top-text-container">
        <div class="top-text">
            Welcome to Pilexos
        </div>
    </div> -->
    <div class="top-container-body">
        <div class="top-item-container">
            <div class="top-text-container">

            </div>

            <div class="top-item-container">

                <ng-container *ngIf="pilexosUser.UserTypeId == PilexosUserTypeIds.pilotExaminer">
                    <app-examiner-dashboard></app-examiner-dashboard>
                </ng-container>
                <ng-container *ngIf="pilexosUser.UserTypeId == PilexosUserTypeIds.studentApplicant">
                    <app-applicant-dashboard></app-applicant-dashboard>
                </ng-container>
                <ng-container *ngIf="pilexosUser.UserTypeId != PilexosUserTypeIds.pilotExaminer && pilexosUser.UserTypeId != PilexosUserTypeIds.studentApplicant">
                    
                    <div dwRow>
                        <div dwCol="4">
                            <select [(ngModel)]="adminView" class="form-control">
                                <option value="examiner">Examiner View</option>
                                <option value="applicant">Applicant View</option>
                            </select>
                        </div>
                    </div>
                    
                    <app-examiner-dashboard *ngIf="adminView == 'examiner'"></app-examiner-dashboard>

                    <app-applicant-dashboard *ngIf="adminView == 'applicant'"></app-applicant-dashboard>
                </ng-container>
            </div>
        </div>
    </div>
</div>



<!--<div class="flex-container">
    <div class="top-text-container">
        <div class="top-text">
            Welcome to Pilexos
        </div>
    </div>
    <div class="top-container-body">

        <ng-container *ngIf="pilexosUser.UserTypeId == PilexosUserTypeIds.pilotExaminer">
            Pilot Examiner

            <app-examiner-dashboard></app-examiner-dashboard>
        </ng-container>

         <div class="top-item-container">


            <div class="top-text-container">
                <div class="mid-text" *ngIf="vendorPortalUser?.UserTypeId == VendorPortalUserTypeIds.existingVendorUnverified">
                    We need to verify that you are an existing Vendor. Please complete the request below.
                    <button class="btn-sm btn-warning" (click)="toggleUserType()" title="I have not done work with Wachter before and would like to become a Wachter Vendor">I'm a new Vendor</button>
                </div>
                <div class="mid-text" *ngIf="vendorPortalUser?.UserTypeId == VendorPortalUserTypeIds.newVendor">
                    Please complete a new vendor request to become a Wachter Vendor 
                    <button class="btn-sm btn-warning" (click)="toggleUserType()" title="I am currently or have previous worked for Wachter and would like to view invoices and submit change requests">I'm an existing Vendor</button>
                </div>
                <div class="mid-text" *ngIf="vendorPortalUser?.UserTypeId == VendorPortalUserTypeIds.newVendorApproved">
                    Your Vendor Request has been approved. For any additional changes, please submit a Change Request
                </div>
                <div class="mid-text" *ngIf="vendorPortalUser?.UserTypeId == VendorPortalUserTypeIds.existingVendorVerified">
                    Verification is complete and you can now see your invoices and payments
                </div>
                <div class="mid-text" *ngIf="vendorPortalUser?.UserTypeId == VendorPortalUserTypeIds.wachter">
                    As a Wachter user, you have access to the following areas
                </div>
            </div>
        </div>
        <ng-container *ngFor="let card of topCards">
            <app-menu-item-card [cardData]="card"></app-menu-item-card>
        </ng-container>
    </div>
</div>
 -->