import { ColorRefDTO } from "./calendar.model";
import { EventDateTimeDTO } from "./event-data-time.model";

export interface CalendarEventDTO {
    CalendarId: string;
    Id: string;
    Name: string;
    Description: string;

    OrganizerName: string;
    OrganizerEmail: string;
    Start: EventDateTimeDTO;
    End: EventDateTimeDTO;
    Status: CalendarEventStatus;
    HtmlLink: string;
    ICalUID: string;
    Recurrence: string;
    OrganizerSelf: boolean | null;
    TimeZone: string;
    EndDateTimeUnspecified: boolean;
    AllDayEvent: boolean;
    Color?: ColorRefDTO;

    Transparency?: string;
}

export enum CalendarEventStatus {
    confirmed = 1,
    tenative = 2,
    cancelled = 3
}