import { Component, OnInit } from '@angular/core';
import { DwComponent, DwComponentType } from '@devwareapps/devware-cap';
import { FinancialDashboardChart, FinancialDashboardConfig, FinancialDashboardGroup } from '../../models/financial-dashboard-config.model';
import { FinancialsRepositoryService } from '../../services/financials-repository.service';
import { ChartDataRequestSeries } from '../../models/chart-data-request.model';
import { DwChartTypes } from '../../../z-devware/visualizations/models/dw-chart-preferences.model';
import { AviationTrainingProgramAllItems, CheckRideRequestSubStatusAllItems } from '../../../../meta-data/app-meta-data.service';

@DwComponent({
  key: 'overall-financials',
  name: ' Admin Financials',
  componentType: DwComponentType.pageComponent,
  isGlobal: true
})
@Component({
  selector: 'app-admin-financials',
  template: `
    <app-financial-dashboard [financialDashboardConfig]="financialDashboardConfig">
     
    </app-financial-dashboard>
  `
})
export class AdminFinancialsComponent implements OnInit {
  financialDashboardConfig: FinancialDashboardConfig;

  constructor(private financialsRepositoryService: FinancialsRepositoryService) { }

  ngOnInit(): void {
    this.setupDashboard();
  }

  setupDashboard() {
    this.financialDashboardConfig = {
      contentConfigKey: 'admin-financials',
      groups: [
        this.buildBookingFeesGroup(),
        this.buildExaminerRevenueGroup(),
        this.buildCheckrideStatGroup(),
      ]
    }
  }

  private buildBookingFeesGroup(): FinancialDashboardGroup {
    const group: FinancialDashboardGroup = {
      groupName: 'Pilexos Fees',
      filterOptions: {
        showYear: true,
        showMonth: true,
        showPilotExaminer: true,
      },
      gridConfigKey: 'admin-financials',
      charts: [],
      summaryAdditionalFilterOptions: { excludeStatuses: [] }
    }

    const fields = this.financialsRepositoryService.getFinancialFields();

    group.charts.push({
      chartType: DwChartTypes.bar, title: `Checkrides - Booking Fees`, categoryField: a => fields.monthDisplay, categoryDisplayOverride: 'Month', additionalGroupingFields: [fields.month, fields.year], orderByFields: [fields.year, fields.month],
      series: [
        { dataField: fields.pilexosRevenue, legendDisplay: 'Booking $', chartType: DwChartTypes.bar },
      { dataField: fields.pilexosPaidRevenue, legendDisplay: 'Paid $', chartType: DwChartTypes.bar },
      { dataField: fields.checkrideCount, legendDisplay: 'Checkrides', axis: 'y2', chartType: DwChartTypes.line },
      ],
      additionalFilterOptions: { excludeStatuses: []}
    });

    const yearDisplay = '';
    group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Statuses`, categoryField: a => a.AggrCheckRideRequestStatus, dataField: fields.checkrideCount, containerClass: 'tile-50a', additionalFilterOptions : { excludeStatuses : []} });

    group.tiles = [
      { headerTemplate: '[year] Checkrides', type: 'year', isCurrency: false, iconClass: 'fa fa-plane dw-blue', additionalText: '', dataField: fields.checkrideCount, chartDataIndex: 0, toolTip: 'Checkrides', hideIfNull: false },
      { headerTemplate: '[year] Paid', type: 'year', isCurrency: true, iconClass: 'fa fa-usd-circle dw-green', additionalText: '', dataField: fields.pilexosPaidRevenue, chartDataIndex: 0, toolTip: 'Checkride Revenue', hideIfNull: false },
      { headerTemplate: `[data.${fields.monthDisplay}] Checkrides`, type: 'month', isCurrency: false, iconClass: 'fa fa-plane dw-blue', additionalText: '', dataField: fields.checkrideCount, chartDataIndex: 0, toolTip: 'Checkrides', hideIfNull: false },
      { headerTemplate: `[data.${fields.monthDisplay}] Paid`, type: 'month', isCurrency: true, iconClass: 'fa fa-usd-circle dw-green', additionalText: '', dataField: fields.pilexosPaidRevenue, chartDataIndex: 0, toolTip: 'Checkride Revenue', hideIfNull: false },
    ];

    return group;
  }

  buildExaminerRevenueGroup(): FinancialDashboardGroup {
    const group: FinancialDashboardGroup = {
      groupName: 'Examiner Stats',
      filterOptions: {
        showYear: true,
        showMonth: true,
        showPilotExaminer: true
      },
      gridConfigKey: 'dep-admin-financials',
      charts: []
    }
    const yearDisplay = '';
    const fields = this.financialsRepositoryService.getFinancialFields();

    group.charts.push({ chartType: DwChartTypes.bar, title: `${yearDisplay} Checkride By Examiner`, categoryField: a => a.ScheduledPilotExaminerId, 
    series: [{ dataField: fields.checkrideCount, legendDisplay: 'Checkrides', axis: 'y2', chartType: DwChartTypes.line }, { dataField: fields.pilexosRevenue, legendDisplay: 'Booked $' }, { dataField: fields.pilexosPaidRevenue, legendDisplay: 'Paid $' }] });
 

    group.charts.push(this.createCheckrideChart('Checkrides and Revenue', true, true));
    

    group.tiles = [
      { headerTemplate: '[year] Checkrides', type: 'year', isCurrency: false, iconClass: 'fa fa-plane dw-blue', additionalText: '', dataField: fields.checkrideCount, chartDataIndex: 0, toolTip: 'Checkrides', hideIfNull: false },
      { headerTemplate: '[year] Revenue', type: 'year', isCurrency: true, iconClass: 'fa fa-usd-circle dw-green', additionalText: '', dataField: fields.bookedRevenue, chartDataIndex: 0, toolTip: 'Checkride Revenue', hideIfNull: false },
      // { headerTemplate: `[data.${fields.monthDisplay}] Checkrides`, type: 'month', isCurrency: false, iconClass: 'fa fa-plane dw-blue', additionalText: '', dataField: fields.checkrideCount, chartDataIndex: 0, toolTip: 'Checkrides', hideIfNull: false },
      // { headerTemplate: `[data.${fields.monthDisplay}] Revenue`, type: 'month', isCurrency: true, iconClass: 'fa fa-usd-circle dw-green', additionalText: '', dataField: fields.bookedRevenue, chartDataIndex: 0, toolTip: 'Checkride Revenue', hideIfNull: false },
    ]
    
    return group;
  }

  private createCheckrideChart(title: string, includeCounts: boolean, includeRevenue: boolean): FinancialDashboardChart {
    const fields = this.financialsRepositoryService.getFinancialFields();

    const config: FinancialDashboardChart = {
      chartType: DwChartTypes.bar, title: title, categoryField: a => fields.monthDisplay, categoryDisplayOverride: 'Month', additionalGroupingFields: [fields.month, fields.year],
      series: []
    };
    const allSeries : ChartDataRequestSeries[] = [
      { dataField: fields.requestedCount, legendDisplay: 'Requested', axis: 'y2', chartType: DwChartTypes.line },
      { dataField: fields.bookedCount, legendDisplay: 'Booked', axis: 'y2', chartType: DwChartTypes.line },
      { dataField: fields.completedCount, legendDisplay: 'Completed', axis: 'y2', chartType: DwChartTypes.line },
      { dataField: fields.requestedRevenue, legendDisplay: 'Requested $', chartType: DwChartTypes.bar },
      { dataField: fields.bookedRevenue, legendDisplay: 'Booked $', chartType: DwChartTypes.bar },
      { dataField: fields.paidRevenue, legendDisplay: 'Paid $', chartType: DwChartTypes.bar },
    ];

    if (includeCounts) {
      config.series = [...config.series, ...allSeries.slice(0, 3)];
    }
    
    if (includeRevenue) {
      config.series = [...config.series, ...allSeries.slice(3, 6)];
    }

    return config;
  }

  private buildCheckrideStatGroup(): FinancialDashboardGroup {
    const group: FinancialDashboardGroup = {
      groupName: 'More Stats',
      filterOptions: {
        showYear: true,
        showMonth: true,
        showPilotExaminer: true
      },
      gridConfigKey: 'admin-financials',
      charts: []
    }

    const fields = this.financialsRepositoryService.getFinancialFields();

    const yearDisplay = '';
    // group.charts.push({ chartType: DwChartTypes.bar, title: `${yearDisplay} Checkride By Examiner`, categoryField: a => a.ScheduledPilotExaminerId, 
    //   series: [{ dataField: fields.checkrideCount, legendDisplay: 'Checkrides', axis: 'y2', chartType: DwChartTypes.line }, { dataField: fields.pilexosRevenue, legendDisplay: 'Booked $' }, { dataField: fields.pilexosPaidRevenue, legendDisplay: 'Paid $' }] });
    
    group.charts.push({ chartType: DwChartTypes.column, title: `${yearDisplay} Checkride Types`, categoryField: a => a.AggrCheckRideTypeDisplay, dataField: fields.checkrideCount, containerClass: 'tile-100', hideLegend: true });
    group.charts.push({ chartType: DwChartTypes.column, title: `${yearDisplay} Checkride By Airport`, categoryField: a => a.ScheduledAirportId, dataField: fields.checkrideCount, hideLegend: true });
    group.charts.push({ chartType: DwChartTypes.column, title: `${yearDisplay} Checkride By Flight School`, categoryField: a => a.RoCheckrideApplicantInfoFlightSchoolFinalName, dataField: fields.checkrideCount, hideLegend: true, orderByFields: [{ field: fields.checkrideCount, isDescending: true }] });

    //group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Checkride Types $`, categoryField: a => a.AggrCheckRideTypeDisplay, dataField: fields.bookedRevenue, containerClass: 'tile-50' });
    group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Checkride Outcomes`, categoryField: a => a.CheckRideResultCheckRideOutcomeId, dataField: fields.checkrideCount, containerClass: 'tile-50' });
    group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Program Types`, categoryField: a => a.AvaiationTrainingProgramId, dataField: fields.checkrideCount, containerClass: 'tile-50' });
    group.charts.push({ chartType: DwChartTypes.doughnut, title: `${yearDisplay} Exam Types`, categoryField: a => a.CheckRideTestTypeId, dataField: fields.checkrideCount, containerClass: 'tile-50_new' });
    group.charts.push({ chartType: DwChartTypes.doughnut, title: `${yearDisplay} Statuses`, categoryField: a => a.AggrCheckRideRequestStatus, dataField: fields.checkrideCount, containerClass: 'tile-50', additionalFilterOptions : { excludeStatuses : [CheckRideRequestSubStatusAllItems.Deleted]} });
    group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Cancellation Reasons`, categoryField: a => a.CheckRideCancellationTypeId, dataField: fields.checkrideCount, containerClass: 'tile-50_new', additionalFilterOptions : { includeStatuses : [CheckRideRequestSubStatusAllItems.Cancelled]} });

    group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Checkride Outcomes - Part 141`, categoryField: a => a.CheckRideResultCheckRideOutcomeId, dataField: fields.checkrideCount, containerClass: 'tile-50', additionalFilterOptions : { includeProgramTypes : [AviationTrainingProgramAllItems.Part141] } });
    group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Checkride Outcomes - Part 61`, categoryField: a => a.CheckRideResultCheckRideOutcomeId, dataField: fields.checkrideCount, containerClass: 'tile-50' , additionalFilterOptions : { includeProgramTypes : [AviationTrainingProgramAllItems.Part61] } });
   
    return group;
  }

}
