import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { DwComponent, DwComponentType, DwContext, DwFormControl, DwFormControlInit, DwFormFieldConfig, DwLookupInfo, DwMetaDataService, DwMetaDataServiceToken, getMomentValue } from '@devwareapps/devware-cap';
import { AirportEntity, AirportWithAddressInfoQueryEntity, AppMetaDataLookups } from '../../../../meta-data/app-meta-data.service';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { Observable } from 'rxjs';
import { DateTimeUtilService } from '../../util/date-time-util.service';

@DwComponent({
  key: 'pilexos-email-display',
  name: 'E-mail Display',
  componentType: DwComponentType.formControl,
  isGlobal: true
})

@Component({
  selector: 'app-email-dispaly',
  template: `
  <div class="read-only">
    <a href="mailto:{{email}}" *ngIf='email'>{{email}}</a>
    <ng-container *ngIf="!email">{{missingTextData}}</ng-container>
  </div>
`
})
export class EmailDispalyComponent implements DwFormControl , DwFormControlInit{
  fieldDef: DwFormFieldConfig;
  formGroup: FormGroup;
  context: DwContext;
  useDisplayField?: boolean;
  
  email?:string;
  missingTextData: string;

  constructor(@Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
    private examinerRepositoryService: ExaminerRepositoryService,
    private dateTimeUtilService: DateTimeUtilService
  ) { }


  initControl(): void {
    // More checks needed since the data might not be loaded yet
    this.email = this.control.value;

    this.missingTextData = this.fieldDef.readOnlyMissingDataText || 'None';
  }

   /*
   * Returns the rating Form Control to get or set it's value
   */
   get control(): AbstractControl {

    
    return this.formGroup.get(this.fieldDef.attrName);
  }

}