

<h1 *ngIf="showNotFound">
    <i class="fad fa-user-lock fa-2x" style="--fa-secondary-color: red"></i> Examiner profile not found
</h1>


<div *ngIf="pilotExaminer">
    <dw-default-form #mdForm item="PilotExaminer" configKeyPart="examiner-public-profile" [overrideData]="true" [data]="pilotExaminer" [keyValue]="keyValue">
    </dw-default-form>
</div>

