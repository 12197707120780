<!-- <div dwRow>
    <div dwCol="6">
        <dw-control-wrapper-with-icons [showSuffix]="true" wrapControl="false">


            <p-dropdown control [options]="sectionList" optionLabel="Display" display="chip" optionValue="Id"
                [(ngModel)]="selectedSectionId" [style]="{'min-width':'300px'}" [filter]="true"
                appendTo="body"></p-dropdown>

            <span suffix class="input-group-text" class="btn btn-primary" (click)="goToSection()"> Go to Section</span>
        </dw-control-wrapper-with-icons>

    </div>


</div> -->


<div class="button-bar">
    <div class="pull-left">
        <button class="btn btn-primary" (click)="previousSection()" *ngIf="hasPrevious"
            title="Previous and Save">Previous</button>
    </div>
    <div class="pull-left">
        <dw-control-wrapper-with-icons [showSuffix]="true" wrapControl="false">
            <p-dropdown control [options]="sectionList" optionLabel="Display" display="chip" optionValue="Id"
                [(ngModel)]="selectedSectionId" [style]="{'min-width':'300px'}" [filter]="true"
                appendTo="body"></p-dropdown>

            <span suffix class="input-group-text" class="btn btn-primary" (click)="goToSection()"> Go to Section</span>
        </dw-control-wrapper-with-icons>
    </div>
    <div class="pull-right">
        <button class="btn btn-primary  pull-right" (click)="stop()" *ngIf="hasNext">Stop</button>
        <button class="btn btn-primary  pull-right" (click)="complete()" *ngIf="!hasNext">Complete</button>
        <button class="btn btn-success pull-right" (click)="nextSection()" *ngIf="hasNext"
            title="Next and Save">Next</button>
    </div>
</div>

<app-plan-of-action-execute-section [planOfAction]="planOfAction" [planOfActionSection]="currentSection"
    [sectionTypes]="sectionTypes" [planOfActionQuestionResults]="planOfActionQuestionResults"
    (resultChange)="resultChange($event)"></app-plan-of-action-execute-section>

<div class="button-bar">
    <div class="pull-left">
        <button class="btn btn-primary" (click)="previousSection()" *ngIf="hasPrevious"
            title="Previous and Save">Previous</button>
    </div>
    <div class="pull-right">
        <button class="btn btn-primary  pull-right" (click)="stop()" *ngIf="hasNext">Stop</button>
        <button class="btn btn-primary  pull-right" (click)="complete()" *ngIf="!hasNext">Complete</button>
        <button class="btn btn-success pull-right" (click)="nextSection()" *ngIf="hasNext"
            title="Next and Save">Next</button>
    </div>
</div>