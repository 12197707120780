<div dwRow>
    <div dwCol="3" *ngIf="financialDashboardGroup?.filterOptions?.showYear && years.length>1">
        <label class="label">Year</label>
        <select class="form-control" [(ngModel)]="filters.year" (ngModelChange)="onFilterChange()">
            <option [ngValue]="null">All Years</option>
            <option *ngFor="let year of years" [ngValue]="year">{{year}}</option>
        </select>
    </div>
    <div dwCol="3" *ngIf="financialDashboardGroup?.filterOptions?.showMonth && filters.year">
        <label class="label">Month</label>
        <select class="form-control" [(ngModel)]="filters.month" (ngModelChange)="onFilterChange()">
            <option [ngValue]="null">All Months</option>
            <option *ngFor="let month of months" [ngValue]="month.value">{{month.text}}</option>
        </select>
    </div>
    <div dwCol="3" *ngIf="financialDashboardGroup?.filterOptions?.showPilotExaminer">
        <label class="label">Pilot Examiner</label>
        <dw-search-select-lookup [(ngModel)]="filters.pilotExaminerId" (ngModelChange)="onFilterChange()" [lookupId]="pilotExaminerLookupId"
            showBlankOption="true" blankOptionText="All Pilot Examiners"></dw-search-select-lookup>
    </div>
    <div dwCol="3">
        <ng-content>
    
        </ng-content>
    </div>
</div>