<div class="row">
    <div class="login-image">

    </div>
    <div class="login-input">
        <ng-container *ngFor="let userTypeItem of userTypes">
            <label class="radio-container">
                <input type="radio" name="userType" [value]="userTypeItem.UserTypeId" [(ngModel)]="userType"
                    (ngModelChange)="updateUserType()" />
                <span class="radio-item"> <strong>{{userTypeItem.UserTypeDisplay}}</strong></span>

                <div class="radio-desc">
                    {{userTypeItem.UserTypeDescription}}
                </div>
            </label>
            <div dwRow *ngIf="userType == pilotExaminerUserType && userTypeItem.UserTypeId == pilotExaminerUserType">
                <div dwCol="12">
                    <label class="label">DMS Number <i class="fa fa-info-circle" title="You must have a DMS Number to create a Pilot Examiner account"></i></label> 
                    <input type="text" class="form-control" [(ngModel)]="dmsNumber" (ngModelChange)="updateDMS()" />
                </div>
            </div>

        </ng-container>

        <!-- <label class="radio-container">
            <input type="radio" name="userType" value="newVendor" [(ngModel)]="userType" (ngModelChange)="change()" />
            <span class="radio-item"> <strong>Pilot Examiner</strong></span>

            <div class="radio-desc">
                I am an authorized FAA Pilot Examiner and give checkrides
            </div>
        </label> -->
        <!-- <label class="radio-container">
            <input type="radio" name="userType" value="existingVendor" [(ngModel)]="userType" (ngModelChange)="change()" />
            <span class="radio-item"> <strong>Existing Vendor</strong></span>

            <div class="radio-desc">
                My company is currently working as a Wachter Vendor or have so in the past
            </div>
        </label> -->
    </div>
</div>