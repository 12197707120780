// tslint:disable-next-line: max-line-length
import { Injectable } from '@angular/core';
import { DwCoreConfig, DwAuthGuardService, DwConfig, DwUIMetaDataConfigDefaults, DwConfigServiceToken, DwUIConfig, DwAgGridComponent, DwUIMetaDataConfig, DwConfigProviderBase, DwUiConfigName, DwCoreConfigDefaults, DwAttributeType, DwControlType, DwMediaConfigName, DwMediaConfig, DwFormDebugService, DwLogSeverity } from '@devwareapps/devware-cap';
import { environment } from 'src/environments/environment';

@DwConfig()
@Injectable()
export class AppDevwareConfigProviderService extends DwConfigProviderBase {
    configureCoreConfig(dwCoreConfig: DwCoreConfig) {
        // Set Core configuration values
        dwCoreConfig.baseTitle = environment.baseTitle;
        dwCoreConfig.apiRoot = environment.apiRoot;
        dwCoreConfig.tenantKey = (environment as any).tenantKey;

        if (environment.environmentMessage == 'Dev') {
            DwFormDebugService.showAllMessages = false;
            DwFormDebugService.componentNameList = ['Training', 'DwContextConfigurationBase', 'DwMetadataFormBuilderService'];
            DwFormDebugService.componentNameList = [];
            DwFormDebugService.disableLogging = true;
        }

        // configure logging
        dwCoreConfig.loggingConfig.writeAllToConsole = (environment as any).enableConsoleLogging;

        dwCoreConfig.loggingConfig.serverMessagePrefix = 'Pilexos Angular';
        dwCoreConfig.loggingConfig.writeToServerLevels = [DwLogSeverity.error];

        // Override the default CanActivate guard
        dwCoreConfig.routingConfig.defaultCanActivateGuard = DwAuthGuardService;
        dwCoreConfig.routingConfig.googleAnalyticsTrackingToken = environment.googleAnalyticsTrackingToken;

        // Setup security anomymous access
        dwCoreConfig.securityConfig.allowAnonymousAccess = true;
        dwCoreConfig.securityConfig.securityTokenTimeoutMinutes = 5;

        dwCoreConfig.securityConfig.autoLoginAfterRegistration = true;

        dwCoreConfig.routingConfig.defaultRouteUrl = '';;

        dwCoreConfig.autoUpdateConfig.updateMessageTooltip = 'Refresh your browser to get the latest version of the app';

        //dwCoreConfig.autoUpdateConfig.updateMessage = 30;
        
        if (!environment.disabledUserInactvityTimeout) {
            dwCoreConfig.securityConfig.userInactivitiy = {
                warningTimeoutMinutes: 30,
                warningMessage: 'You will be logged out in 5 minutes due to inactivity.',
                logoutTimeoutMinutes: 5,
                logoutMessage: 'You have been logged out due to inactivity.', 
            };
        }
    }

    configureAdditionalConfig(configName: string, config: any, dwCoreConfig: DwCoreConfig) {
        switch (configName) {
            case DwUiConfigName:
                this.configureUIConfig(config, dwCoreConfig);
                break;

            case DwUIMetaDataConfigDefaults.configName:
                this.configureUIMetaDataConfig(config);
                break;

            case DwMediaConfigName:
                this.configureMediaConfig(config, dwCoreConfig);
        }
    }


    private configureUIConfig(dwUiConfig: DwUIConfig, dwCoreConfig: DwCoreConfig) {
        // Setup Contact us menu
        dwUiConfig.showContactUs = environment.showContactUs;
        dwUiConfig.contactUsEMail = environment.contactUsEmail;

        dwUiConfig.gridComponent = DwAgGridComponent;
        dwUiConfig.pageFooterHeight = 0;
        dwUiConfig.showFooter = false;

        dwUiConfig.allowConfiguration = environment.allowConfiguration;

        dwCoreConfig.globals.apiRoot = dwCoreConfig.apiRoot;
        dwCoreConfig.globals.defaultAvatar = dwUiConfig.defaultAvatarImageUrl;

        dwUiConfig.defaultBackgroundImageUrl = 'assets/pilexos/default_banner.png';
        dwUiConfig.emailValidationRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/;
        
        // Customize the registration process
        dwUiConfig.registrationConfig = {
            registerTitle: 'Sign up for an account',
            registerButtonText: 'Sign up',
            registrationComponentKey: 'pilexos-user-registration-extension'
        }
    }

    private configureUIMetaDataConfig(dwUiMetaDataConfig: DwUIMetaDataConfig) {
        // Default date/time configuration
        dwUiMetaDataConfig.useGmtDateTime = true;
        dwUiMetaDataConfig.use24HourTime = false;
        dwUiMetaDataConfig.pdfRenderWaitTimeMs = 1200;
        dwUiMetaDataConfig.emailValidationRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/;;

        dwUiMetaDataConfig.gridDefaultPreferences.rowHeight = 25;

        dwUiMetaDataConfig.userContextExtendedAttributes = [
            { name: 'UserTypeId', display: 'User Type', attrType: DwAttributeType.Integer, controlType: DwControlType.DropDownList, lookupId: 301 }
        ];
    }

    configureMediaConfig(config: DwMediaConfig, dwCoreConfig: DwCoreConfig) {
        config.defaultMaxFileSizeMb = 10;
    }
}


