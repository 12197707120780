import { Component, Inject, OnInit } from '@angular/core';
import { DwComponent, DwComponentType, DwContainerOptions, DwExpressionService, DwLookupInfo, DwMetaDataFormApi, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwUiConfigRegistryService, DwUIMetaDataConfig, DwUIMetaDataConfigToken } from '@devwareapps/devware-cap';
import { AcsCodesLookupQueryEntity, AppMetaDataItemNames, PlanOfActionEntity, PlanOfActionQuestionResultEntity, PlanOfActionSectionEntity, PlanOfActionSectionTypeAllItems, PlanOfActionSectionTypeEntity, PlanOfActionStatusAllItems } from '../../../../meta-data/app-meta-data.service';
import { AcsCodesStorageItem } from '../../models/acs-code-items.model';
import { GenerateQuestionsActionService } from '../../actions/generate-questions-action.service';
import { PlanOfActionGeneratorService } from '../../services/plan-of-action-generator.service';
import { PlanOfActionRepositoryService } from '../../services/plan-of-action-repository.service';
import { PlanOfActionExecuteVariables } from '../../models/plan-of-action-execute-variables.model';


@DwComponent({
  key: 'plan-of-action-execute',
  name: 'Plan of Action Execute',
  componentType: DwComponentType.formSection,
  parentItemName : AppMetaDataItemNames.PlanOfAction
})
@Component({
  selector: 'app-plan-of-action-execute',
  templateUrl: './plan-of-action-execute.component.html',
  styleUrls: ['./plan-of-action-execute.component.scss']
})
export class PlanOfActionExecuteComponent extends DwSectionBaseComponent {
  static expandedSections : { [prop:number]: boolean } = {};
  
  formApi: DwMetaDataFormApi;
  planOfAction: PlanOfActionEntity;
  planOfActionSections: PlanOfActionSectionEntity[];

  rootFormGroup: any;
  //sectionTypes: PlanOfActionSectionTypeEntity[];
  sectionIndex: number;
  planOfActionQuestionResults: PlanOfActionQuestionResultEntity[];
  currentSection: PlanOfActionSectionEntity;
  sectionTypes: PlanOfActionSectionTypeEntity[];
  hasPrevious: boolean;
  hasNext: boolean;
  selectedSectionId: number;

  sectionList: DwLookupInfo[] = [];
   
  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private planOfActionRepositoryService: PlanOfActionRepositoryService,
    private generateQuestionsActionService: GenerateQuestionsActionService,
    private planofActionGeneratorService: PlanOfActionGeneratorService,
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);

    this.formApi = this.dwMetaDataFormStateService.state.formApi;
  } 

  resultsToSave: PlanOfActionQuestionResultEntity[] = [];

  resultsToSaveMap: { [prop: number]: PlanOfActionQuestionResultEntity } = {};

  resultChange(result: PlanOfActionQuestionResultEntity) {
    if (!result.PlanOfActionResultId) {
      result.PlanOfActionResultId = this.planOfAction.PlanOfActionResultId;
    }
    this.resultsToSaveMap[result.PlanOfActionSectionQuestionId] = result;

    const resultArray: PlanOfActionQuestionResultEntity[] = [];

    for (const key in this.resultsToSaveMap) {
      if (this.resultsToSaveMap.hasOwnProperty(key)) {
        resultArray.push(this.resultsToSaveMap[key]);
      }
    }

    this.resultsToSave = resultArray;
  }

  goToSection() {
    if (!this.selectedSectionId) {
      return;
    }

    this.sectionIndex = this.planOfActionSections.findIndex(x => x.PlanOfActionSectionId == this.selectedSectionId);

    this.setupSection();
  }

  saveCurrent() {
    if (this.resultsToSave.length ==0) {
      return;
    }

    this.planOfActionRepositoryService.savePlanOfActionQuestionResults(this.resultsToSave)
      .subscribe(results => {
        this.resultsToSave = [];
        this.resultsToSaveMap = {};

        for(var result of results) {
           const existingResult = this.planOfActionQuestionResults.find(x => x.PlanOfActionSectionQuestionId == result.PlanOfActionSectionQuestionId);

           if (existingResult) {
            const index = this.planOfActionQuestionResults.indexOf(existingResult);

            this.planOfActionQuestionResults[index] = result;
           } else {
            this.planOfActionQuestionResults.push(result);
           }
        }
      });
  }

  
  ngOnInit(): void {
    this.loadReferenceData();
    this.setupDataWatch();

    console.log('Section created');
  }

  loadReferenceData() {
    this.planOfActionRepositoryService.getPlanOfActionSectionTypes()
      .subscribe(sectionTypes => {
        this.sectionTypes = sectionTypes;
      });
  }

  setupDataWatch() {
    this.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (this.formApi.isDataLoadComplete) {
          this.checkForDataChange();
        }
      });

    if (this.formApi.isDataLoadComplete) {
      this.checkForDataChange();
    }
  }

  initComplete = false;

  private checkForDataChange() {
    this.planOfAction = this.formApi.getFormData<PlanOfActionEntity>();

    if (this.initComplete) {
      return;
    }

    this.initComplete = true;

    if (!this.planOfAction.PlanOfActionResultId) {
      this.planOfActionRepositoryService.createPlanOfActionResult(this.planOfAction)
        .subscribe(planOfAction => {
          this.planOfAction = planOfAction;

          this.formApi.setFormData(planOfAction);

          this.loadPlanOfActionQuestionResults();
        });
    } else {
      this.loadPlanOfActionQuestionResults();
    }

    this.loadPlanOfActionSections();
  }

  private loadPlanOfActionSections() {
    this.planOfActionRepositoryService.loadPlanOfActionSections(this.planOfAction.PlanOfActionId)
      .subscribe(planOfActionSections => {
          this.planOfActionSections = planOfActionSections;

          this.planOfActionSections[0].SectionName

          this.planOfActionSections[0].PlanOfActionSectionId

          this.setupCurrentSection();

          this.setupSectionList();
      }); 

    // const planOfActionSection = this.formGroup.getRawValue();

    // this.planOfActionSection = this.planOfAction.PlanOfActionSection.find(x => x.PlanOfActionSectionId == planOfActionSection.PlanOfActionSectionId);

    // this.setupSection();
    // this.lookupAosAndTask();
    
    // this.setupAcsCodes();
    // this.setupContainer();
  }

  private setupSectionList() {
    const sections = this.planOfActionSections.map(x => {
      return {
        Id: x.PlanOfActionSectionId.toString(),
        Display: this.getSectionDisplay(x)
      }; 
    })

    this.sectionList = [ { Id: '0', Display: ' ' }, ...sections ];
  } 
  private getSectionDisplay(planOfActionSection: PlanOfActionSectionEntity): any {
    switch (planOfActionSection.PlanOfActionSectionTypeId) {
      case PlanOfActionSectionTypeAllItems.AreaOfOperationGround:
      case PlanOfActionSectionTypeAllItems.AreaOfOperationFlight:
        return `${planOfActionSection.AcsAreaOfOperationIdDisplay}`;
        
      case PlanOfActionSectionTypeAllItems.TaskGround:
      case PlanOfActionSectionTypeAllItems.TaskFlight:
        return `${planOfActionSection.AcsAreaOfOperationIdDisplay} - ${planOfActionSection.AcsAreaOfOperationTaskIdDisplay}`;
        
      default:
        return planOfActionSection.SectionName;
    }
  }

  private loadPlanOfActionQuestionResults() {
    if (!this.planOfAction.PlanOfActionResultId) {
      return;
    }

    this.planOfActionRepositoryService.loadPlanOfActionQuestionResults(this.planOfAction.PlanOfActionResultId)
      .subscribe(planOfActionQuestionResults => {
        this.planOfActionQuestionResults = planOfActionQuestionResults;
      });
  }

  setupCurrentSection() {
    if (!this.planOfActionSections) {
      return;
    }

    this.sectionIndex = 0;

    this.setupSection();

    this.currentSection = this.planOfActionSections[this.sectionIndex];
  }

  nextSection() {
    this.sectionIndex++;

    if (this.sectionIndex > this.planOfActionSections.length) {
      this.sectionIndex = this.planOfActionSections.length;
    }

    this.setupSection();
  }

  previousSection() {
    this.sectionIndex--;

    if (this.sectionIndex < 0) {
      this.sectionIndex = 0;
    }

    this.setupSection();
  }

  complete(){

    this.formApi.patchFormData({ PlanOfActionStatusId: PlanOfActionStatusAllItems.Complete})

    this.stop();
  }

  stop() {
    this.saveCurrent();

    this.formApi.patchVariableContext<PlanOfActionExecuteVariables>({ ShowPlan: false});
  }

  setupSection() {
    // Always save first
    this.saveCurrent();

    this.currentSection = this.planOfActionSections[this.sectionIndex];

    this.hasPrevious = this.sectionIndex > 0;
    this.hasNext = this.sectionIndex < this.planOfActionSections.length - 1;
  }

}
