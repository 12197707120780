<dw-router-parent>
    <ng-template>
        <dw-content-manager [configKey]="financialDashboardConfig?.contentConfigKey">
            <h1>
                <dw-content name="title" display="Page Title" [controlType]="textboxControlType">
                    Financials
                </dw-content>
            </h1>
            <dw-content name="detail" display="Header Details">
                Here are all of your financials
            </dw-content>
            <dw-content-component name='component' display='Custom Component'></dw-content-component>
        </dw-content-manager>

        <dw-pills [items]="financialDashboardConfig.groups" [(ngModel)]="currentGroup" valueField="groupName"
            displayField="groupName" (ngModelChange)="setGroup($event)"></dw-pills>

        <div class="dashboard-column-wide">
            <div clas="title-container">
                <ng-container *ngIf="showGrid">
                    <dw-container [options]="gridContainerOptions">
                        <div header>
                            {{selectedInfo?.label}}
                        </div>
                        <div body class="inner-container">
                            <dw-meta-data-grid [item]="'CheckRideRequest'"
                                [configKeyPart]="currentDashboardGroup.gridConfigKey"
                                configKeyPart2="dep-admin-financials" [filters]="gridFilters"></dw-meta-data-grid>

                            <!-- {{gridFilters | json }} -->
                        </div>
                    </dw-container>
                </ng-container>

                <div [hidden]="showGrid">

                    <app-financial-dashboard-filters [(filters)]="chartFilters" (filtersChange)="onFilterChange($event)"
                        [financialDashboardGroup]="currentDashboardGroup" [summaryData]="summaryData?.data">

                        <ng-content>
        
                        </ng-content>
                    </app-financial-dashboard-filters>

                    <div class="tile-contianer" *ngIf="true">
                        <ng-container *ngFor="let tileConfig of tileConfigs">
                            <div class="tile-25">
                                <dw-mini-tile [tileConfig]="tileConfig"></dw-mini-tile>
                            </div>
                        </ng-container>
                    </div>

                    <ng-container *ngFor="let chartResult of currentChartResults">
                        <div class2="tile-50" [ngClass]="chartResult.chartDataRequest?.containerClass">
                            <dw-chart-tile [chartPreferences]="chartResult.chartPreferences" [data]="chartResult.data"
                                [query]="chartResult.query" (dataSelected)="dataSelected($event, chartResult)">
                                <div *ngIf="selectedInfo?.chartResult == chartResult">
                                    <a (click)="toggleShowGrid()" class="dw-link">View Data for
                                        {{selectedInfo?.label}}</a>
                                </div>
                            </dw-chart-tile>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-template>
</dw-router-parent>

<div *ngIf="false">
    <div style="clear:left">
        <br>Colors:<br>
        <div *ngFor="let color of colors" [ngStyle]="{'background-color': color}"
            style="width:100px; height: 100px; margin-left:4px; float:left"></div>
    </div>
    <br><br>
    <div style="clear:left">
        <br><br>Colors2:<br>
        <div *ngFor="let color of colors2" [ngStyle]="{'background-color': color}"
            style="width:100px; height: 100px; margin-left:4px; float:left"></div>
    </div>
</div>