import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DwButtonCommand, DwButtonConfig, DwMessage, DwMessageSeverity, DwModalButtonStandardConfigs, DwModalConfig, DwModalHandler, DwModalResult, DwModalService, DwModalSize } from '@devwareapps/devware-cap';
import { Observable, of } from 'rxjs';
import { PaymentChargeRequest } from '../../models/payment-charge-request.model';
import { CheckridePaymentRequest } from '../../models/checkride-payment-request.model';
import { PaymentModalOptions } from '../../models/payment-modal-options.model';
import { PaymentModalReturn } from '../../models/payment-modal-return.model';
import { PaymentRepositoryService } from '../../services/payment-repository.service';
import { CheckridePaymentResult } from '../../models/checkride-payment-result.model';
import { map, mergeMap } from 'rxjs/operators';
import { CheckRideRequestEntity, CheckRideRequestSubStatusAllItems } from '../../../../meta-data/app-meta-data.service';
import { CurrencyPipe } from '@angular/common';
import { PaymentDiscountResult } from '../../models/payment-discount-result.model';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
  providers: [CurrencyPipe]
})
export class PaymentModalComponent implements OnInit, DwModalHandler {
  chargeRequest: PaymentChargeRequest;

  modalConfig: DwModalConfig<PaymentModalOptions>;
  paymentModalOptions: PaymentModalOptions;
  closeModal: EventEmitter<DwModalResult<PaymentModalReturn>>;
  paymentComplete: boolean;

  processing: boolean = false;
  processingMessage: string;
  checkridepaymentResult: CheckridePaymentResult;

  additionalMessage: string;
  paymentDiscountResult: PaymentDiscountResult;

  constructor(private paymentRespotioryService: PaymentRepositoryService, private currencyPipe: CurrencyPipe) { }

  ngOnInit(): void {

    this.paymentModalOptions = this.modalConfig.data;
    this.chargeRequest = this.paymentModalOptions.chargeRequest || {} as any;

    if (this.paymentModalOptions.checkrideRequest.ExaminerBookingCost > 0) {
      const amount = this.currencyPipe.transform(this.paymentModalOptions.checkrideRequest.ExaminerBookingCost, 'USD', 'symbol', '1.2-2');

      this.additionalMessage = `This does not include the Examiner booking fee of ${amount} which is payable directly to the Examiner.`;
    }
    this.paymentModalOptions.showPaymentParty
  }



  buttonClick(buttonConfig: DwButtonConfig): Observable<DwModalResult<PaymentModalReturn>> {
    const modalResult = new DwModalResult();

    modalResult.closeModal = true;
    modalResult.cancelled = false;
    modalResult.buttonCommand = buttonConfig.command;

    if (this.processing) {
      modalResult.cancelled = true;

      return of(modalResult);
    }

    switch (buttonConfig.command) {
      case DwButtonCommand.saveAndClose:

        if (!this.validateForm()) {
          modalResult.closeModal = false;

          return of(modalResult);
        }

        return this.processPayment();
      default:
        if (this.paymentComplete) {
          modalResult.data = {
            checkridePaymentResult: this.checkridepaymentResult
          };

          return of(modalResult)
        }

        // Otherwise we're cancelling
        modalResult.cancelled = true;

        return of(modalResult);
    }
  }


  processPayment(): Observable<DwModalResult<PaymentModalReturn>> {
    const checkridePaymentRequest: CheckridePaymentRequest = {
      checkRideRequestId: this.modalConfig.data.checkrideRequest.CheckRideRequestId,
      chargeRequest: this.chargeRequest,

    };

    this.processing = true;
    this.processingMessage = 'Validating Payment Information...';

    return this.paymentRespotioryService.CreateCheckridePayment(checkridePaymentRequest)
      .pipe(map((checkridePaymentResult: CheckridePaymentResult) => {

        this.checkridepaymentResult = checkridePaymentResult;
        const modalResult = new DwModalResult();

        if (!checkridePaymentResult.IsSuccess) {
          this.processing = false;
          this.setMessage(checkridePaymentResult.ErrorMessage, DwMessageSeverity.error);

          modalResult.closeModal = false;

          return modalResult;
        }

        this.processingMessage = 'Updating Checkride...';

        this.processing = false;
        modalResult.closeModal = false;

        this.paymentComplete = true;

        this.modalConfig.title = 'Checkride payment successful';
        this.modalConfig.buttons = [DwModalButtonStandardConfigs.instance.closeButton];

        return modalResult;

        // Moved this piece to the SaveCheckridePayment call on server side
        // return this.paymentRespotioryService.updateCheckrideAsPaymentAdded(this.modalConfig.data.checkrideRequest)
        //   .pipe(map((checkrideRequest: CheckRideRequestEntity) => {

        //     this.processing = false;
        //     modalResult.closeModal = false;

        //     this.paymentComplete = true;

        //     this.modalConfig.title = 'Checkride booking successful';
        //     this.modalConfig.buttons = [DwModalButtonStandardConfigs.instance.closeButton];

        //     return modalResult;
        //   }));

      }))

  }

  protected validateForm(): boolean {
    const errors: string[] = [];

    if (this.paymentDiscountResult?.FinalAmount > 0) {
      if (!this.chargeRequest.cardholderName) {
        errors.push('Cardholder Name is required');
      }

      if (!this.chargeRequest.cardNumber) {
        errors.push('Card number Name is required');
      }

      if (!this.chargeRequest.expirationMonth) {
        errors.push('Expiration Month is required');
      }

      if (!this.chargeRequest.expirationYear) {
        errors.push('Expiration Year is required');
      }

      if (!this.chargeRequest.cVC) {
        errors.push('CVC is required');
      }
 
      if (!this.chargeRequest.cardholderPostalCode) {
        errors.push('Postal Code is required');
      }
    }

    if (errors.length > 0) {
      this.setMessage(errors.join(", "), DwMessageSeverity.error);

      return false;
    }

    this.message = null;

    return true;
  }

  message: DwMessage;

  private setMessage(messageBody: string, severity: DwMessageSeverity = DwMessageSeverity.info) {
    this.message = {
      messageBody: messageBody,
      severity: severity
    };
  }

}
