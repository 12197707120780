<div class="dashboard-title">
    Welcome {{examinerName}} to your Pilot Examiner Dashboard!
</div>

<div class="dashboard-container-flex">
    <div class="dashboard-column-left">
        <div class="verticle-container">
            <div class="verticle-item">
                <dw-container [options]="metarContainerOptions" *ngIf="metarData?.length>0">
                    <span header>Your METAR Data</span>
                    <div body>

                        <div *ngFor="let metar of metarData">
                            <b>{{metar.airport?.AirportName}} ({{metar.airport?.AirportCode}})</b><br>
                            {{metar.metar}}
                        </div>
                    </div>
                </dw-container>
            </div>
            <div class="verticle-item-grow">
                <dw-task-container [tasks]="tasks"></dw-task-container>
            </div>
        </div>
    </div>

    <div class="dashboard-column-right" *ngIf="showCalendar">
        <dw-container [options]="calendarContainerOptions">
            <span header>Booking Calendar</span>
            <div body>
                <app-examiner-booking-calendar [compactView]="true"></app-examiner-booking-calendar>
            </div>
        </dw-container>
    </div>
</div>