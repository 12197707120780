import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DwComponent, DwComponentType, DwExpressionService, DwMessage, DwMessageSeverity, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService, formatDateTimeToLocal } from '@devwareapps/devware-cap';
import { AppMetaDataItemNames, CalendarReferenceEntity, DwUserEntity } from '../../../../meta-data/app-meta-data.service';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { CalendarRepositoryService } from '../../services/calendar-repository.service';
import { CalendarAuthConsentService } from '../../services/calendar-auth-consent.service';
import { CalendarDTO } from '../../models/calendar.model';
import { CalendarDelegationSetupResponseDTO } from '../../models/calendar-delegation-setup-response.model';
import { CalendarConfig } from '../../models/calendar-config.model';
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';

@DwComponent({
   key: 'google-calendar-consent',
   name: 'Google Calendar Consent',
   componentType: DwComponentType.formSection,
   isGlobal: false,
   parentItemName: AppMetaDataItemNames.PilotExaminer,
})
@Component({
  selector: 'app-google-calendar-consent',
  templateUrl: './google-calendar-consent.component.html',
  styleUrls: ['./google-calendar-consent.component.scss']
})
export class GoogleCalendarConsentComponent extends DwSectionBaseComponent implements OnInit, OnChanges {
  consentUrl: string;
  calendarReferenceId: number;
  @Input() calendarReference: CalendarReferenceEntity;
  @Output() calendarReferenceChange = new EventEmitter<CalendarReferenceEntity>();

  calendarConfig: CalendarConfig = {};

  userProfile: DwUserEntity;
  @Input() allCalendars: CalendarDTO[] = [];
  message: DwMessage;

  authUrl: string;
  loadingMessage: string;
  editMode: boolean;
  alternateCalendars: CalendarDTO[];
  showPrivacy: boolean = false;
  showCalendarError: boolean = true;
  //manualCode: string;

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    //private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private calendarRepositoryService: CalendarRepositoryService,
    private calendarAuthConsentService: CalendarAuthConsentService,
    private cdr: ChangeDetectorRef,
    private dateTimeUtilService: DateTimeUtilService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
  }

  messageDisplayTime = 2000;

  loading = false;


  togglePrivacy() {
    this.showPrivacy = !this.showPrivacy;
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.setupCalendarConfig();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.calendarReference) {  
      this.setupCalendarConfig();
    }
    if (changes.allCalendars) {
      this.setupAdditionalCalendars();
    }
  }

   calendarLastErrorDateTime: string;

  private setupCalendarConfig() {

    if (this.calendarReference?.CalendarConfigJSON) {
      this.calendarConfig = this.calendarReference.CalendarConfigJSON as any;
    } 

    if (!this.calendarConfig?.AdditionalCalendars) {
      this.calendarConfig = { AdditionalCalendars: [] };
    }

    this.showCalendarError = this.dateTimeUtilService.isToday(this.calendarReference?.LastCalendarErrorDateTime)

    this.calendarLastErrorDateTime = formatDateTimeToLocal(this.calendarReference?.LastCalendarErrorDateTime, true, true);

    this.setupAdditionalCalendars();
  }

  getOAuthConsent() {
    const redirectUrl = this.calendarRepositoryService.getOAuthRedirectUri();

    
    this.calendarRepositoryService.getOAuthConsentUrl(redirectUrl)
      .subscribe(consentUrl => {
        this.authUrl = consentUrl;

        this.loading = true;
        this.loadingMessage = 'Setting up Google Calendar...';
   
        this.calendarAuthConsentService.openSignInWindow(consentUrl, "calendarAuth")
          .subscribe(code => {
            
            if (!code) {
                this.loading = false;
                return;
              }

              //this.manualCode = code;

              // Now we need to setup
              
                this.setupCalendarDelegation(code, redirectUrl);
              
          });
      });
  }

  setupCalendarDelegation(code: string, redirectUrl: string) {
    this.message = null;

     this.calendarRepositoryService.setupCalendarDelegation(code, redirectUrl)
      .subscribe(delegationSetupResponse => {

        setTimeout(() => {
          this.finalizeCalendarDelegation(delegationSetupResponse);
          
        }, 10);

       
      });
  }

  finalizeCalendarDelegation(delegationSetupResponse: CalendarDelegationSetupResponseDTO) {
     this.loading = false;
     
     if (!delegationSetupResponse?.IsSuccess) {
      const fullMessage = `${delegationSetupResponse.ErrorMessage}. <br><br>  A google calendar can only be tied to one pilexos account.  Use the link below to remove Pilexos access, then try again! <br><br>`;

      this.message = {
        messageBody: fullMessage,
        linkUrl: 'https://myaccount.google.com/connections',
        linkText: 'Google Account Connections',
        severity: DwMessageSeverity.error,
        allowClose: true,
      };

      return;
    }

    this.message = {
      messageBody: `Google Calendar setup complete!`,
      severity: DwMessageSeverity.success,
      allowClose: true,
      timeToShow: this.messageDisplayTime
    };

    this.cdr.detectChanges();

    if (delegationSetupResponse.CalendarReferenceId) {
      this.reloadCalendarRef(delegationSetupResponse.CalendarReferenceId);
    }
  }

  gotoEditMode() {
    this.editMode = true;
  }

  cancel() {
    this.editMode = false;
  }

  saveCalendars() {
    if(!this.calendarReference) {
      return;
    }
    this.loading = true;
    this.loadingMessage = 'Saving Settings...';

    this.calendarReference.CalendarConfigJSON = this.calendarConfig as any;

    this.calendarRepositoryService.saveCalendarRef(this.calendarReference)
    .subscribe(calendarRef => {
      this.calendarReference = calendarRef;
      this.calendarReferenceChange.emit(this.calendarReference);

      this.setupCalendarConfig();
      this.editMode = false;
      this.loading = false;

      this.message = {
        messageBody: `Settings updated`,
        severity: DwMessageSeverity.success,
        timeToShow: this.messageDisplayTime,
        allowClose: true
      };
    });

  }

  removeAccess() {
    this.message = null;
    
    this.loading = true;
    this.loadingMessage = 'Removing Google Calendar access...';

    this.calendarRepositoryService.revokeCalendarDelegation()
      .subscribe(revokeResponse => {
        if (revokeResponse.IsSuccess) {
          this.message = {
            messageBody: `Calendar delegation has been removed`,
            severity: DwMessageSeverity.warning,
            allowClose: true,
            timeToShow: this.messageDisplayTime
          };
        } else {
          this.message = {
            messageBody: `Calendar delegation ran into an error: ${revokeResponse.ErrorMessage}`,
            severity: DwMessageSeverity.error,
            allowClose: true,
            timeToShow: this.messageDisplayTime
          };
        }
        
          this.loading = false;
          this.calendarReference = null;
          this.calendarReferenceId = null;

          this.calendarReferenceChange.emit(this.calendarReference);

          this.cdr.detectChanges();

          this.reloadCalendarRef(this.calendarReference?.CalendarReferenceId);
      });

  }

  setupAdditionalCalendars() {
    // build alternative calendar list
    if (!this.calendarReference || !(this.allCalendars?.length > 0)) {
      return;
    }

    if (this.calendarReference.MainCalendarKey) {
      var mainCalendar = this.allCalendars.find(c => c.Id === this.calendarReference.MainCalendarKey);

      this.calendarReference.MainCalendarTimezone = mainCalendar?.TimeZone;
    }

    this.alternateCalendars = this.allCalendars.filter(c => c.Id !== this.calendarReference.MainCalendarKey);
    
    // Remove any calendars listed that are alternate
    for(let index=this.calendarConfig.AdditionalCalendars.length-1; index >= 0; index--) {
      const calId = this.calendarConfig.AdditionalCalendars[index];

      if (!this.alternateCalendars.find(c => c.Id === calId)) {
        this.calendarConfig.AdditionalCalendars.splice(index, 1);
      }
    }

    this.calendarConfig.AdditionalCalendars = [...this.calendarConfig.AdditionalCalendars];
  }

  reloadCalendarRef(calendarRefId: number) {
    this.calendarRepositoryService.getCalendarRef(calendarRefId)
      .subscribe(calendarRef => {
        this.calendarReference = calendarRef;

        this.calendarReferenceChange.emit(this.calendarReference);

        this.setupCalendarConfig();
      })
  }

}
