export class CheckrideFinancialFields { 
    month: string;
    monthDisplay: string;
    year: string;

    // Aggregate fields
    checkrideCount: string = 'CheckrideCount';
    
    requestedCount: string = 'RequestedCount';
    requestedRevenue: string = 'RequestedRevenue';

    bookedCount: string = 'BookedCount';
    bookedRevenue: string = 'BookedRevenue';
    
    completedCount: string = 'CompletedCount';
    completedRevenue: string = 'CompletedRevenue';

    paidRevenue: string = 'PaidRevenue';
    
    pilexosRevenue: string  = 'PilexosRevenue';
    pilexosPaidRevenue: string  = 'PilexosPaidRevenue';
    
    checkrideType: string = 'CheckRideTypeIdDisplay';
    checkrideOutcome: string = 'CheckRideTypeIdDisplay';
;
}