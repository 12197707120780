import { Component, OnInit } from '@angular/core';
import { ExaminerRepositoryService } from '../../services/examiner-repository.service';
import { CheckRideRequestEntity, CheckRideRequestSubStatusAllItems, PilotExaminerEntity, PilotExaminerStatusAllItems } from '../../../../meta-data/app-meta-data.service';
import { DwContainerOptions, formatDateTimeToLocal, getMomentValue } from '@devwareapps/devware-cap';
import { DwTaskItem } from '../../../z-devware/tasks/models/dw-task-item.model';

import * as moment_ from 'moment';
import { AirportMetar } from '../../models/airport-metar.model';
const moment = moment_;

@Component({
  selector: 'app-examiner-dashboard',
  templateUrl: './examiner-dashboard.component.html',
  styleUrls: ['./examiner-dashboard.component.scss']
})
export class ExaminerDashboardComponent implements OnInit {

  tasks: DwTaskItem[] = [];

  examinerName: string;

  calendarContainerOptions: DwContainerOptions =   {
    containerHeaderIcon : 'fa fa-calendar',
    containerHeaderBackgroundColor: 'blue',
    useContainerStyling: true,
    showTitle: true
 }

 metarContainerOptions: DwContainerOptions =   {
  containerHeaderIcon : 'fa fa-cloud',
  containerHeaderBackgroundColor: 'green',
  useContainerStyling: true,
  showTitle: true,
  
}
  pilotExaminer: PilotExaminerEntity;
  metarData: AirportMetar[];
  showCalendar: boolean = false;

  constructor(private examinerRepositoryService: ExaminerRepositoryService) { }

  ngOnInit(): void {
    this.loadExaminerStats();
  }

  showAll = false;

  loadExaminerStats() {
    this.examinerRepositoryService.getCurrentPiloxExaminerStats()
      .subscribe((examiner) => {
          this.pilotExaminer = examiner
          if (examiner?.DwUser) {
            this.examinerName = `${examiner.DwUser?.FirstName} ${examiner.DwUser?.LastName}`;
          }
          
          this.buildExaminerTasks(examiner, this.showAll);

          this.loadExaminerAirportMetarData();
      });
  }


  private buildExaminerTasks(examiner: PilotExaminerEntity, showAll: boolean = false) {
    this.tasks = [];

    if (examiner?.PilotExaminerStatusId != PilotExaminerStatusAllItems.VerifiedLive || showAll) {
      this.tasks.push(this.createNotVerifiedTask(examiner));

      if (!showAll) {
        return;
      }
    }

    this.showCalendar = true;

    if (!examiner.DwUser?.UserPictureMediaId  || showAll) {
      this.tasks.push({ iconClass: 'fa fa-portrait dw-blue', taskTitle: 'Add a profile picture',tooltip: 'A profile picture helps applicants recognize you and better shows off your profile', navigateUrl: '/settings/profile' });
    }
    
    if (!examiner.Description  || showAll) {
      this.tasks.push({ iconClass: 'fa fa-align-justify dw-blue', taskTitle: 'Add a profile description', tooltip: 'A description tells more about you to potential applicants', navigateUrl: '/settings/profile' });
    }

    var scenarios = examiner.CheckRideScenario || [];
    var activeScenarios = scenarios.filter((scenario) => scenario.IsActive);

    var locations = examiner.PilotExaminerLocation || [];
    var actionLocations = locations.filter((location) => location.IsActive);

    if (activeScenarios.length == 0 || showAll) {
      if (scenarios.length==0) {
        this.tasks.push({ iconClass: 'fa fa-check-double dw-orange', taskTitle: 'Add an exam type', tooltip :'An exam type is required to determine what checkride types you can perform', navigateUrl: '/settings/exam-types' });
      } else {
        this.tasks.push({ iconClass: 'fa fa-check-double dw-orange', taskTitle: 'No active exam types', tooltip :'An active exam type is required to determine what checkride types you can perform', navigateUrl: '/settings/exam-types' });
      }
    }

    if (actionLocations.length == 0  || showAll) {
      if (locations.length==0) {
        this.tasks.push({ iconClass: 'fa fa-map dw-orange', taskTitle: 'Add an exam location', tooltip :'An exam location is required to determine what airports you want to perform checkrides', navigateUrl: 'settings/exam-locations' });
      } else {
        this.tasks.push({ iconClass: 'fa fa-map dw-orange', taskTitle: 'No active exam locations', tooltip :'An activeexam location is required to determine what airports you want to perform checkrides', navigateUrl: 'settings/exam-locations' });
      }
    }
    
    if (!examiner.CalendarReference?.CalendarIsSetup  || showAll) {
      this.tasks.push({ iconClass: 'fa fa-calendar dw-orange', taskTitle: 'Setup Calendar Access', tooltip :'Setup access to your calendar to determine availability and add checkride events', navigateUrl: 'calendar' });
    }

    this.addCheckrideTasks(examiner, showAll);

    if (this.tasks.length==0) {
      this.tasks.push({ iconClass: 'fa fa-check dw-green', taskTitle: `No current tasks`, tooltip :'You currently have no pending tasks or upcoming checkrides', navigateUrl: '' });
    }
  }


  addCheckrideTasks(examiner: PilotExaminerEntity, showAll: boolean) {

    // Get all scheduled checkrides - excludes cancelled, etc
    const scheuledCheckrides = examiner.ScheduledCheckRideRequest.filter((checkride) => this.isScheduledCheckride(checkride));
    
    const todaysCheckrides = scheuledCheckrides.filter((checkride) => this.isForToday(checkride));

    if (todaysCheckrides.length>0) {
      const checkrideText = this.pluralizeWord('Checkride', 'Checkrides', todaysCheckrides.length);

      this.tasks.push({ iconClass: 'fa fa-check dw-blue', taskTitle: `${todaysCheckrides.length} ${checkrideText} today`, tooltip :`${todaysCheckrides.length} ${checkrideText} are booked for today`, navigateUrl: '/calendar' });
    }

    const upcomingCheckrides =  scheuledCheckrides.filter((checkride) => this.isForThisWeek(checkride));

    if (upcomingCheckrides.length>0) {
      const checkrideText = this.pluralizeWord('Checkride', 'Checkrides', upcomingCheckrides.length);

      this.tasks.push({ iconClass: 'fa fa-check dw-green', taskTitle: `${upcomingCheckrides.length} ${checkrideText} this week`, tooltip :`${upcomingCheckrides.length} ${checkrideText} are booked later this week`, navigateUrl: '/calendar' });
    }
    
    const pendingCheckrides = examiner.ScheduledCheckRideRequest.filter((checkride) => checkride.CheckRideRequestSubStatusId == CheckRideRequestSubStatusAllItems.PendingApproval);

    if (pendingCheckrides.length>0) {
      const checkrideText = this.pluralizeWord('Checkride', 'Checkrides', pendingCheckrides.length);

      this.tasks.push({ iconClass: 'fa fa-check dw-orange', taskTitle: `${pendingCheckrides.length} ${checkrideText} Pending Approval`, tooltip :`${pendingCheckrides.length} ${checkrideText} are waiting for your approval`, navigateUrl: '/bookings' });
    }

    const pendingOutcomeCheckrides = examiner.ScheduledCheckRideRequest.filter((checkride) => checkride.CheckRideRequestSubStatusId == CheckRideRequestSubStatusAllItems.PendingOutcome);

    if (pendingOutcomeCheckrides.length>0) {
      const checkrideText = this.pluralizeWord('Checkride', 'Checkrides', pendingOutcomeCheckrides.length);

      this.tasks.push({ iconClass: 'fa fa-check dw-orange', taskTitle: `${pendingOutcomeCheckrides.length} ${checkrideText} Pending Outcome`, tooltip :`${pendingOutcomeCheckrides.length} ${checkrideText} need to have their outcome updated`, navigateUrl: '/bookings?tile=booked' });
    }
  }

  private isScheduledCheckride(checkride: CheckRideRequestEntity): boolean {
  
    switch(checkride.CheckRideRequestSubStatusId) {
      case CheckRideRequestSubStatusAllItems.Cancelled:
      case CheckRideRequestSubStatusAllItems.Completed:
      case CheckRideRequestSubStatusAllItems.Rejected:
      case CheckRideRequestSubStatusAllItems.Deleted:
      case CheckRideRequestSubStatusAllItems.Draft:
      case CheckRideRequestSubStatusAllItems.PendingPayment:
      
        return false;
    }

    return true;
  }

  isForToday(checkride: CheckRideRequestEntity): boolean {


    //const scheduleDateTime = getMomentValue(checkride.ScheduledDateTime, true, true);
    const scheduleDateTimeLocal = formatDateTimeToLocal(checkride.ScheduledDateTime, true, true);
    
    const scheduleDateTime = getMomentValue(scheduleDateTimeLocal, true, true);

    formatDateTimeToLocal

    const now = moment();

    if (scheduleDateTime.isSame(now, 'day')) {
      return true;
    }

    return false;
  }

  isForThisWeek(checkride: CheckRideRequestEntity): boolean {
    const scheduleDateTimeLocal = formatDateTimeToLocal(checkride.ScheduledDateTime, true, true);
    const scheduleDateTime = getMomentValue(scheduleDateTimeLocal, true, true);
    const now = moment().add(1, 'days');
    const sat = moment().weekday(6); // 6 is saturday

    if (scheduleDateTime.isSameOrAfter(now, 'day') && scheduleDateTime.isSameOrBefore(sat, 'day')) {
      return true;
    }

    return false;
  }

  private pluralizeWord(word:string, pluralword: string, count: number): string {
    if (count==1) {
      return word;
    }

    return pluralword;
  }

  private createNotVerifiedTask(examiner: PilotExaminerEntity): DwTaskItem {
    const task: DwTaskItem = {
      iconClass: 'fa fa-user-circle',
      taskTitle: 'Pilot Examiner Verification ',
    };

    switch (examiner?.PilotExaminerStatusId) {
      case PilotExaminerStatusAllItems.Submitted:
        task.taskTitle += ' Submitted';
        task.iconClass += ' dw-orange';
        task.tooltip = 'Your verification request has been submitted. We will contact you shortly.';
        break;
      case PilotExaminerStatusAllItems.Rejected:
        task.taskTitle += ' Rejected';
        task.iconClass += ' dw-red';
        task.tooltip = 'Your verification request has been rejected.  Please contact Pilexos support to resolve any issues';
        break;
      case PilotExaminerStatusAllItems.Inactive:
        task.taskTitle = 'Pilot Examiner Profile is Inactive';
        task.iconClass += ' dw-red';
        task.tooltip = 'Your profile is inactive and users are not able to request booking.';
        task.navigateUrl = '/settings/profile';
        break;
      case PilotExaminerStatusAllItems.VerifiedLive:
        this.showCalendar = true;
        task.taskTitle += '  Complete';
        task.iconClass += ' dw-green';
        task.tooltip = 'Your verification request has been completed.  Please contact Pilexos support to resolve any issues';
        break;
      default:
          task.taskTitle += ' On Hold';
          task.iconClass += ' dw-red';
          task.tooltip = 'Please contact Pilexos support to resolve any issues';
          break;
    }

    return task;


  }


  public loadExaminerAirportMetarData() {
    if (!this.pilotExaminer || this.pilotExaminer.PilotExaminerStatusId != PilotExaminerStatusAllItems.VerifiedLive) {
      return;
    }

    this.examinerRepositoryService.getCurrentExaminerMetarData(this.pilotExaminer.PilotExaminerId)
      .subscribe((metarData) => {
        this.metarData = metarData;
      });
  }

}
