import { Inject, Injectable } from "@angular/core";
import { DwOrmDataServiceToken, DwOrmDataService, DwMetaDataServiceToken, DwMetaDataService, DwConfigServiceToken, DwConfigService, DwSecurityUserService, DevwareMetaData, DwUserTypeEntity } from "@devwareapps/devware-cap";
import { Observable } from "rxjs";
import { DwUserTypePilexosEntity } from "../../../meta-data/extensions/dw-user-type-entity.model";


@Injectable({ providedIn: 'root' })
export class PilexosHomePageRepository {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        
    }

    getUserTypes(): Observable<DwUserTypePilexosEntity[]> {
        const query = DevwareMetaData.DwUserType.CreateQuery();

        return this.dwOrmDataService.executeQuery<DwUserTypePilexosEntity>(query);
    }
    
}