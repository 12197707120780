import { DwUser } from "@devwareapps/devware-cap";
import { PilexosUserTypeIds } from "./pilexos-user-type.enum";

export class PilexosUser
{
    user: DwUser;

    UserTypeId: PilexosUserTypeIds;


    constructor(user: DwUser) {
        

        this.setupUser(user);
    }

    private setupUser(user: DwUser) {
        
        this.user = user;

        const attr = this.user.ExtendedAttributes.find(a => a.AttrName=='UserTypeId');

        if (attr) {
            this.UserTypeId = parseInt(attr.AttrValue);
        }
    }

}