import { fieldSelectorType } from "@devwareapps/devware-cap";
import { ChartFilters } from "./chart-filters.model";
import { CheckrideRequestAggregateBaseQueryMetaDataAttributes } from "../../../meta-data/app-meta-data.service";
import { DwChartTypes } from "../../z-devware/visualizations/models/dw-chart-preferences.model";
import { ChartAdditionalFilterOptions } from "./chart-additional-filter.options.model";



export class ChartDataRequest {
    chartType: DwChartTypes = DwChartTypes.pie;
    filters?: ChartFilters;
    additionalFilterOptions?: ChartAdditionalFilterOptions;
    title: string; 
    categoryField: fieldSelectorType<CheckrideRequestAggregateBaseQueryMetaDataAttributes> | string;
    dataField?: fieldSelectorType<CheckrideRequestAggregateBaseQueryMetaDataAttributes> | string;
    categoryDisplayField?: fieldSelectorType<CheckrideRequestAggregateBaseQueryMetaDataAttributes> | string;
    categoryDisplayOverride?: string;

    additionalGroupingFields?: fieldSelectorType<CheckrideRequestAggregateBaseQueryMetaDataAttributes>[] | string[];
    orderByFields?: fieldSelectorType<CheckrideRequestAggregateBaseQueryMetaDataAttributes>[] | string[] | ChartOrderByField[];

    hideLegend?: boolean;
    maxItems?: number;
    maxLegendItems?: number;
    
    includeBlank?: boolean;

    series?: ChartDataRequestSeries[];

    containerClass?: string;
    
}


export class ChartDataRequestSeries {
    dataField: fieldSelectorType<CheckrideRequestAggregateBaseQueryMetaDataAttributes> | string;
    legendDisplay?: string;
    axis?: 'y' | 'y2';
    chartType?: DwChartTypes = DwChartTypes.pie;
    color?: string;
}

export interface ChartOrderByField {
    field: fieldSelectorType<CheckrideRequestAggregateBaseQueryMetaDataAttributes> | string;
    isDescending?: boolean;
}

export function isChartOrderbyField(obj: any): obj is ChartOrderByField {
    return obj.field;
}