import { Component, OnInit } from '@angular/core';
import { DwComponent, DwComponentType } from '@devwareapps/devware-cap';
import { FinancialDashboardChart, FinancialDashboardConfig, FinancialDashboardGroup } from '../../models/financial-dashboard-config.model';
import { FinancialsRepositoryService } from '../../services/financials-repository.service';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { AviationTrainingProgramAllItems, CheckRideRequestSubStatusAllItems, PilotExaminerEntity } from '../../../../meta-data/app-meta-data.service';
import { ChartDataRequestSeries } from '../../models/chart-data-request.model';
import { DwChartTypes } from '../../../z-devware/visualizations/models/dw-chart-preferences.model';
import { CheckrideFinancialFields } from '../../models/checkride-financial-fields.model';

@DwComponent({
  key: 'examiner-financials',
  name: 'Examiner Financials',
  componentType: DwComponentType.pageComponent,
  isGlobal: true
})
@Component({
  selector: 'app-examiner-financials',
  template: `
    <app-financial-dashboard *ngIf="financialDashboardConfig" [financialDashboardConfig]="financialDashboardConfig" (currentGroupChanged)="setGroup($event)">

      <ng-container *ngIf="currentGroup === 'Revenue'">
        <label class="label">Revenue Field</label>
        <select class="form-control" [(ngModel)]="revenueField" (ngModelChange)="setRevenueField()" >
            <option [ngValue]="fields.paidRevenue">Paid</option>
            <option [ngValue]="fields.bookedRevenue">Booked</option>
            <option [ngValue]="fields.requestedRevenue">Requested</option>
        </select>
      </ng-container>
    </app-financial-dashboard>
  `
})
export class ExaminerFinancialsComponent implements OnInit {
  financialDashboardConfig: FinancialDashboardConfig;
  examiner: PilotExaminerEntity;
  currentGroup: string = 'Not Set';
  fields: CheckrideFinancialFields;

  constructor(
    private financialsRepositoryService: FinancialsRepositoryService,
    private examinerRepositoryService: ExaminerRepositoryService,

  ) { }

  setGroup(group: string) {
    this.currentGroup = group;
  }

  ngOnInit(): void {
    this.fields = this.financialsRepositoryService.getFinancialFields();

    this.revenueField = this.fields.paidRevenue;

    this.examinerRepositoryService.getCurrentPilotExaminer()
      .subscribe(examiner => {
        this.examiner = examiner;
        this.setupDashboard();
      });
  }

  revenueField: string;

  setRevenueField() {
    this.setupDashboard();
  }

  setupDashboard() {
    this.financialDashboardConfig = {
      contentConfigKey: 'examiner-financials',
      staticFilter: {
        pilotExaminerId: this.examiner?.PilotExaminerId || -1,
      },
      groups: [
        this.buildRevenueGroup(),
        this.buildCheckrideStatGroup(),
      ]
    }
  }

  buildRevenueGroup(): FinancialDashboardGroup {
    const group: FinancialDashboardGroup = {
      groupName: 'Revenue',
      filterOptions: {
        showYear: true,
      },
      gridConfigKey: 'dep-admin-financials',
      charts: []
    }


    group.charts.push(this.createCheckrideChart('Checkrides and Revenue', true, true));

    const fields = this.financialsRepositoryService.getFinancialFields();


    group.tiles = [
      { headerTemplate: '[year] Checkrides', type: 'year', isCurrency: false, iconClass: 'fa fa-plane dw-blue', additionalText: '', dataField: fields.checkrideCount, chartDataIndex: 0, toolTip: 'Checkrides', hideIfNull: false },
      { headerTemplate: '[year] Revenue', type: 'year', isCurrency: true, iconClass: 'fa fa-usd-circle dw-green', additionalText: '', dataField: this.revenueField, chartDataIndex: 0, toolTip: 'Checkride Revenue', hideIfNull: false },
      { headerTemplate: `[data.${fields.monthDisplay}] Checkrides`, type: 'month', isCurrency: false, iconClass: 'fa fa-plane dw-blue', additionalText: '', dataField: fields.checkrideCount, chartDataIndex: 0, toolTip: 'Checkrides', hideIfNull: false },
      { headerTemplate: `[data.${fields.monthDisplay}] Revenue`, type: 'month', isCurrency: true, iconClass: 'fa fa-usd-circle dw-green', additionalText: '', dataField: this.revenueField, chartDataIndex: 0, toolTip: 'Checkride Revenue', hideIfNull: false },
    ]

    return group;
  }

  private createCheckrideChart(title: string, includeCounts: boolean, includeRevenue: boolean): FinancialDashboardChart {
    const fields = this.financialsRepositoryService.getFinancialFields();

    const config: FinancialDashboardChart = {
      chartType: DwChartTypes.bar, title: title, categoryField: a => fields.monthDisplay, categoryDisplayOverride: 'Month', additionalGroupingFields: [fields.month, fields.year], orderByFields: [fields.year, fields.month],
      series: []
    };
    const allSeries: ChartDataRequestSeries[] = [
      { dataField: fields.requestedCount, legendDisplay: 'Requested', axis: 'y2', chartType: DwChartTypes.line },
      { dataField: fields.bookedCount, legendDisplay: 'Booked', axis: 'y2', chartType: DwChartTypes.line },
      { dataField: fields.completedCount, legendDisplay: 'Completed', axis: 'y2', chartType: DwChartTypes.line },
      { dataField: fields.requestedRevenue, legendDisplay: 'Requested $', chartType: DwChartTypes.bar },
      { dataField: fields.bookedRevenue, legendDisplay: 'Booked $', chartType: DwChartTypes.bar },
      { dataField: fields.paidRevenue, legendDisplay: 'Paid $', chartType: DwChartTypes.bar },
    ];

    if (includeCounts) {
      config.series = [...config.series, ...allSeries.slice(0, 3)];
    }

    if (includeRevenue) {
      config.series = [...config.series, ...allSeries.slice(3, 6)];
    }

    return config;
  }

  private buildCheckrideStatGroup(): FinancialDashboardGroup {
    const group: FinancialDashboardGroup = {
      groupName: 'Stats',
      filterOptions: {
        showYear: true,
        showMonth: true,
      },
      gridConfigKey: 'dep-admin-financials',
      charts: []
    }

    const fields = this.financialsRepositoryService.getFinancialFields();

    const yearDisplay = '';
    group.charts.push({ chartType: DwChartTypes.column, title: `${yearDisplay} Checkride By Airport`, categoryField: a => a.ScheduledAirportId, dataField: fields.checkrideCount, hideLegend: true, orderByFields: [{ field: fields.checkrideCount, isDescending: true }] });
    group.charts.push({ chartType: DwChartTypes.column, title: `${yearDisplay} Checkride By Flight School`, categoryField: a => a.RoCheckrideApplicantInfoFlightSchoolFinalName, dataField: fields.checkrideCount, hideLegend: true, orderByFields: [{ field: fields.checkrideCount, isDescending: true }] });

    //group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Top Airports`, categoryField: a => a.ScheduledAirportId, dataField: fields.checkrideCount, containerClass: 'tile-50', maxItems: 6 });

    group.charts.push({
      chartType: DwChartTypes.column, title: `${yearDisplay} Checkride Types`, categoryField: a => a.AggrCheckRideTypeDisplay, dataField: fields.checkrideCount, hideLegend: true, containerClass: 'tile-502', orderByFields: [{ field: fields.checkrideCount, isDescending: true }],
      series: [
        // { dataField: fields.checkrideCount, legendDisplay: 'Checkrides',  chartType: DwChartTypes.column,  },
        //   { dataField: fields.bookedRevenue, legendDisplay: 'Booked $', chartType: DwChartTypes.column },
      ]
    });


    const allSeries: ChartDataRequestSeries[] = [
      { dataField: fields.checkrideCount, legendDisplay: 'Checkrides', axis: 'y2', chartType: DwChartTypes.line },
      { dataField: fields.bookedRevenue, legendDisplay: 'Booked $', chartType: DwChartTypes.bar },
      // { dataField: fields.completedCount, legendDisplay: 'Completed', axis: 'y2', chartType: DwChartTypes.line },
      // { dataField: fields.requestedRevenue, legendDisplay: 'Requested $', chartType: DwChartTypes.bar },
      // { dataField: fields.bookedRevenue, legendDisplay: 'Booked $', chartType: DwChartTypes.bar },
      // { dataField: fields.paidRevenue, legendDisplay: 'Paid $', chartType: DwChartTypes.bar },
    ];


    // group.charts.push({ chartType: DwChartTypes.bar, title: `${yearDisplay} Checkride Types $`, categoryField: a => a.AggrCheckRideTypeDisplay, dataField: fields.bookedRevenue, hideLegend: true, containerClass: 'tile-502' });
    group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Statuses`, categoryField: a => a.AggrCheckRideRequestStatus, dataField: fields.checkrideCount, containerClass: 'tile-100', additionalFilterOptions: { excludeStatuses: [CheckRideRequestSubStatusAllItems.Deleted, CheckRideRequestSubStatusAllItems.Draft] } });

    group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Checkride Outcomes`, categoryField: a => a.CheckRideResultCheckRideOutcomeId, dataField: fields.checkrideCount, containerClass: 'tile-50' });
    group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Exam Types`, categoryField: a => a.CheckRideTestTypeId, dataField: fields.checkrideCount, containerClass: 'tile-50' });
    group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Program Types`, categoryField: a => a.AvaiationTrainingProgramId, dataField: fields.checkrideCount, containerClass: 'tile-50_new' });
    group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Cancellation Reasons`, categoryField: a => a.CheckRideCancellationTypeId, dataField: fields.checkrideCount, containerClass: 'tile-50', additionalFilterOptions: { includeStatuses: [CheckRideRequestSubStatusAllItems.Cancelled] } });

    group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Checkride Outcomes - Part 141`, categoryField: a => a.CheckRideResultCheckRideOutcomeId, dataField: fields.checkrideCount, containerClass: 'tile-50', additionalFilterOptions: { includeProgramTypes: [AviationTrainingProgramAllItems.Part141] } });
    group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Checkride Outcomes - Part 61`, categoryField: a => a.CheckRideResultCheckRideOutcomeId, dataField: fields.checkrideCount, containerClass: 'tile-50', additionalFilterOptions: { includeProgramTypes: [AviationTrainingProgramAllItems.Part61] } });

    //group.charts.push({ chartType: DwChartTypes.pie, title: `${yearDisplay} Statuses`, categoryField: a => a.AggrCheckRideRequestStatus, dataField: fields.checkrideCount, containerClass: 'tile-50' });

    return group;
  }

}
