import { Component, Inject, OnInit } from '@angular/core';
import { DwComponent, DwComponentType, DwExpressionService, DwFormGroup, DwMetaDataFormApi, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService } from '@devwareapps/devware-cap';
import { AcsCodesLookupQueryEntity, AppMetaDataItemNames, CheckRideRequestEntity, PlanOfActionEntity, PlanOfActionSectionEntity, PlanOfActionSectionQuestionEntity, PlanOfActionSectionTypeAllItems } from '../../../../meta-data/app-meta-data.service';
import { ApplicantRepositoryService } from '../../../applicant/services/applicant-repository.service';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';
import { PlanOfActionRepositoryService } from '../../../plan-of-action/services/plan-of-action-repository.service';
import { AcsCodesStorageItem } from '../../../plan-of-action/models/acs-code-items.model';



@DwComponent({
  key: 'question-edit-control',
  name: ' Question Edit Control',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.PlanOfActionSectionQuestion,
  isGlobal: false
})
@Component({
  selector: 'app-question-edit-control',
  templateUrl: './question-edit-control.component.html',
  styleUrls: ['./question-edit-control.component.scss']
})
export class QuestionEditControlComponent extends DwSectionBaseComponent {
  formApi: DwMetaDataFormApi;
  planOfAction: PlanOfActionEntity;
  planOfActionSection: PlanOfActionSectionEntity;
  planOfActionSectionQuestion: PlanOfActionSectionQuestionEntity;
  
  acsCodes: AcsCodesStorageItem;
  currentCodes: AcsCodesLookupQueryEntity[];

  showAscCode: boolean = true;
  showQuestion: boolean = true;

  currentAcsCode: AcsCodesLookupQueryEntity;
  rootFormGroup: any;
  hideLabels: boolean = false;
  
  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private planOfActionRepositoryService: PlanOfActionRepositoryService,
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);

    this.formApi = this.dwMetaDataFormStateService.state.formApi;
  }

  ngOnInit(): void {
    this.setupDataWatch();
  } 

  setupDataWatch() {
    this.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (this.formApi.isDataLoadComplete) {
          this.checkForDataChange();
        }
      });

    if (this.formApi.isDataLoadComplete) {
      this.checkForDataChange();
    }

    this.subscriptions.push(
      this.formGroup.valueChanges.subscribe(() => {
        this.planOfActionSectionQuestion = this.formGroup.getRawValue();

        this.setupQuestion();
      })
    );
    
  }

  private checkForDataChange() {
    this.planOfAction = this.formApi.getFormData<PlanOfActionEntity>();
    this.setupQuestion();
    if (this.formGroup instanceof DwFormGroup) {
      const dwFormGroup = this.formGroup as DwFormGroup;
      const parentFormGroup = dwFormGroup.parentFormGroup;

      this.planOfActionSection = parentFormGroup.getRawValue();

      this.setupAcsCodes();
    }
  }

  private setupQuestion() {
    if (!this.planOfActionSectionQuestion || !this.acsCodes) {
      return;
    }

    this.currentAcsCode = this.acsCodes?.codesById[this.planOfActionSectionQuestion.AcsCodeId];

    this.hideLabels = (this.planOfActionSectionQuestion.QuestionOrder > 1);

    if (this.planOfActionSection.PlanOfActionSectionTypeId == PlanOfActionSectionTypeAllItems.Manual) {
      this.showAscCode = false;
      this.showQuestion = true;
    } else {
      this.showAscCode = true;
      this.showQuestion = this.planOfAction.OptionsShowQuestionForAcsCode;
    }
  }

  static count=0;

  private setupAcsCodes() {
    if (!this.planOfAction || !this.planOfActionSection) {
      return;
    }

    if (this.acsCodes){
      return;
    }

    QuestionEditControlComponent.count++;

    console.log(`getting acs codes - start  ${QuestionEditControlComponent.count}`);
    this.planOfActionRepositoryService.getAcsCodes(this.planOfAction.AcsTestId, this.planOfActionSection.AcsAreaOfOperationId, this.planOfActionSection.AcsAreaOfOperationTaskId)
      .subscribe(acsCodes => {
        console.log(`getting acs codes - end ${QuestionEditControlComponent.count}`);
        this.acsCodes = acsCodes;

        const blankCode : AcsCodesLookupQueryEntity = {
          _itemName:'',
          AcsCodeId: null,
          AcsCode: ' ',
        };

        this.currentCodes = [blankCode, ...acsCodes.acsCodes];
 
        this.setupQuestion();
      })
  }

  ascCodeChanged(acsCodeId) {

  }

}
