<dw-container>
    <div header>
        {{tileConfig?.containerTitle}}
    </div>
    <div body class="inner-container">
        <div class="icon"><i [class]="tileConfig?.iconClass" class="fa-3x" class2="fa fa-usd-circle dw-green fa-3x"></i></div>
        <div class="title-text">{{ tileConfig?.tileText }}</div>
        <div class="additional-text" *ngIf="tileConfig?.additionalText">{{tileConfig?.additionalText}}</div>
    </div>
</dw-container>
