import { Injectable } from "@angular/core";
import { PaymentRepositoryService } from "../services/payment-repository.service";
import { DwAction, DwActionHandler, DwActionResult, DwActionState, DwActionType, DwMdFormActionState, DwMdFormInitActionHandler, DwMdFormInitActionState, DwMessageSeverity } from "@devwareapps/devware-cap";
import { AppMetaDataItemNames, CheckRidePaymentEntity, PaymentReferenceStatusAllItems } from "../../../meta-data/app-meta-data.service";
import { Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { CheckrideRefundPaymentRequest } from "../models/checkride-refund-payment-request.model";


@DwAction({
    keyName: 'checkride-payment-refund-action',
    display: 'Checkride Payment Refund Action',
    actionType: DwActionType.formAction,
    isGlobal: false,
    parentItemName: AppMetaDataItemNames.CheckRidePayment,
    allowOnce: true,
    defaultButtonConfig: {
        buttonClass: 'btn btn-warning mr-1 mt-1',
        buttonText: 'Refund Payment',
        iconClass: 'fa fa-credit-card'
    },
    defaultConfirmConfig : {
        showConfirmDialog: true,
        showDialogConfig: true,
        message: 'Are you sure you want to refund this payment?',
        title: 'Refund Payment',
    },
    configData: {
        requireValidation: false,
        redirectAfter: false,
        actionMessage: 'Refunding payment...',
    }
})
@Injectable()
export class CheckridePaymentRefundActionService implements DwActionHandler, DwMdFormInitActionHandler  {

    constructor(private paymentRepositoryService: PaymentRepositoryService) {}

    initAction(formInitActionState: DwMdFormInitActionState) {
        formInitActionState.formApi
        .getFormDataChanges<CheckRidePaymentEntity>(350)
        .subscribe(value => this.updateButton(formInitActionState, value));
    }

      /**
   * Updates the button config based on if they can approve or not
   * @param formInitActionState form state
   */
  protected updateButton(formInitActionState: DwMdFormInitActionState, checkRidePaymentEntity: CheckRidePaymentEntity) {
    const buttonConfig = formInitActionState.actionPreference.buttonConfig;

    if (!formInitActionState.formApi.formGroup.pristine) {
        return;
    }
    if (checkRidePaymentEntity?.PaymentReference?.PaymentReferenceStatusId != PaymentReferenceStatusAllItems.Charged) {
        buttonConfig.hidden = true;
    } else {
        buttonConfig.hidden = false;
    }
  }

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {
        const checkridePayment : CheckRidePaymentEntity = actionState.data;
        const result = new DwActionResult();
       
        if (checkridePayment?.PaymentReference?.PaymentReferenceStatusId != PaymentReferenceStatusAllItems.Charged) {
            result.message = {
                messageHeading: 'Payment Refund Failed',
                messageBody: 'Payment must be in charged status to be refuneded',
                severity: DwMessageSeverity.error,
                allowClose: true
                
            };
    
            result.success = false;
            result.cancelled = true;
    
            return of(result);
        }

        const refundRequest: CheckrideRefundPaymentRequest = {
            CheckridePaymentId: checkridePayment.CheckRidePaymentId,
        };


        return this.paymentRepositoryService.refundCheckridePayment(refundRequest)
            .pipe(mergeMap(refundResult => {
                

                if (refundResult.IsSuccess) {
                    result.message = {
                        messageHeading: 'Payment Refund Successful',
                        messageBody: 'Payment has been refunded',
                        severity: DwMessageSeverity.success,
                        allowClose: true,
                        timeToShow: 5000,
                    };

                    result.success = true;
                    return actionState.formApi.loadData(true)
                        .pipe(map(() => {
                            return result;
                        }));
                }

                result.message = {
                    messageHeading: 'Payment Refund Failed',
                    messageBody: refundResult.ErrorMessage,   
                    severity: DwMessageSeverity.error,
                    allowClose: true,
                };
                result.success = false;
                result.cancelled = true;

                return of(result);
            }));
    }

}