import { Inject, Injectable } from "@angular/core";
import { ChartFilters } from "../models/chart-filters.model";
import { Observable, Subject, combineLatest } from "rxjs";
import { AppMetaData, CheckRideRequestEntity, CheckRideRequestQueryBuilder, CheckRideRequestStatusAllItems, CheckRideRequestSubStatusAllItems, CheckrideRequestAggregateBaseQueryBuilder, CheckrideRequestAggregateBaseQueryMetaDataAttributes } from "../../../meta-data/app-meta-data.service";
import { DwField, DwFieldAggregate, DwFieldType, DwItemAttribute, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwQuery, DwQueryBuilderGen, DwQueryFilterGroup, DwQueryHelper, DwQueryMetaData, DwTemplateUtilService, deepCopy, fieldSelectorType } from "@devwareapps/devware-cap";
import { CheckrideFinancialFields } from "../models/checkride-financial-fields.model";
import { map, mergeMap } from "rxjs/operators";
import { DataQueryResult } from "../models/data-query-result.model";
import { BaseQueryResult } from "../models/base-query-result.model";
import { filterFieldSelectorType } from "@devwareapps/devware-cap/devware-orm/query/builders/dw-query-filter-builder-generic.model";
import { DwChartPreferences, DwChartSeries, DwChartTypes } from "../../z-devware/visualizations/models/dw-chart-preferences.model";
import { ChartDataRequest, ChartDataRequestSeries, isChartOrderbyField } from "../models/chart-data-request.model";
import { ChartDataQueryResult } from "../models/chart-data-result.model";
import { DwChartDataSelectedEvent } from "../../z-devware/visualizations/models/dw-chart-data-selected-event.model";
import { DwChartColors } from "../../z-devware/visualizations/models/dw-chart-colors.model";
import { FinancialTileRequest } from "../models/financial-dashboard-config.model";
import { DwTileConfig } from "../../z-devware/visualizations/models/dw-tile-config.model";
import { DwMdTemplateService } from "@devwareapps/devware-cap/devware-ui-metadata/controls/templates-controls/services/dw-md-template-service";
import { ChartAdditionalFilterOptions } from "../models/chart-additional-filter.options.model";

@Injectable({
    providedIn: 'root'
})
export class FinancialsRepositoryService {


    static closeGridSubject = new Subject<void>();

    constructor(@Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        private dwTemplateUtil: DwTemplateUtilService,
    ) {

    }

    closeGrid() {
        FinancialsRepositoryService.closeGridSubject.next();
    }

    getCloseGridObservable(): Observable<void> {
        return FinancialsRepositoryService.closeGridSubject.asObservable();
    }

    buildGridFilters(event: DwChartDataSelectedEvent<any>, chartDataQueryResult: ChartDataQueryResult, chartFilters: ChartFilters): any {
        const filter: any = {
            groupChartFilters: chartDataQueryResult.query.Filter
        };

        filter.groupChartFilters.Op = 'AND';

        const categoryField = this.getFieldName(chartDataQueryResult.chartDataRequest.categoryField, true);

        filter[categoryField] = event.categoryValue;

        if (chartDataQueryResult.chartDataRequest.chartType != DwChartTypes.pie && chartDataQueryResult.chartDataRequest.series?.length > 1) {
            const series = this.findSeries(event, chartDataQueryResult);

            if (series) {
                const dataFieldName = this.getFieldName(series.dataField, true);

                // Find aggregate Field in query
                const aggrField = chartDataQueryResult.query.FieldSettings.IncludeFields.find(f => f.FieldAlias == dataFieldName);

                if (aggrField?.FullFieldName) {
                    filter[aggrField.FullFieldName] = {
                        greaterThan: 0
                    }
                }

                //filter[dataFieldName] = event.dataValue;
            }
        }

        return filter;
    }

    private findSeries(event: DwChartDataSelectedEvent<any>, chartDataQueryResult: ChartDataQueryResult): ChartDataRequestSeries {
        const index = chartDataQueryResult.chartPreferences.series?.findIndex(s => s.dataField == event.series?.dataField);

        if (index >= 0) {
            const series = chartDataQueryResult.chartDataRequest.series[index];

            return series;
        }

        return null;
    }


    getFinancials(filters: ChartFilters, additionalFilterOptions: ChartAdditionalFilterOptions): Observable<DataQueryResult> {
        return this.getFinancialsQuery(filters, additionalFilterOptions)
            .pipe(mergeMap(query => {
                return this.executeQueryData(query, null);
            }));
    }

    getFinancialSummary(filters: ChartFilters, additionalFilterOptions: ChartAdditionalFilterOptions): Observable<DataQueryResult> {

        const newFilters = { ...filters };

        // Remove any year/month filters since we want a multi-year summary here
        newFilters.year = null;
        newFilters.month = null;

        return this.getFinancialSummaryQuery(newFilters, additionalFilterOptions)
            .pipe(mergeMap(query => {
                return this.executeQueryData(query, null);
            }));
    }

    setupCharts(chartDataRequest: ChartDataRequest[], chartFilters: ChartFilters): Observable<ChartDataQueryResult[]> {
        const chartRequestObs: Observable<ChartDataQueryResult>[] = [];

        for (let chartRequest of chartDataRequest) {
            chartRequestObs.push(this.setupChart(chartRequest, chartFilters));
        }

        return combineLatest(chartRequestObs)
            .pipe(map(results => {
                return results;
            }));
    }

    setupChart(chartDataRequest: ChartDataRequest, chartFilters: ChartFilters): Observable<ChartDataQueryResult> {
        return this.getChartData(chartDataRequest, chartFilters)
            .pipe(map(result => {
                return {
                    data: result.data,
                    query: result.query,
                    queryMetaData: result.queryMetaData,
                    chartPreferences: this.buildChartPreferences(result, chartDataRequest),
                    chartDataRequest: chartDataRequest
                } as ChartDataQueryResult;

            }));
    }

    setupTiles(tileRequests: FinancialTileRequest[], chartDataQueryResults: ChartDataQueryResult[], summaryData: any[], chartFilters: ChartFilters): DwTileConfig[] {

        if (!tileRequests || tileRequests.length == 0 || !chartDataQueryResults || !summaryData)  {
            return [];
        }

        const tileConfigs: DwTileConfig[] = [];

        for (let tileRequest of tileRequests) {
            const tileConfig = this.setupTile(tileRequest, chartDataQueryResults, summaryData, chartFilters);

            if (tileConfig) {
                tileConfigs.push(tileConfig);
            }
        }

        return tileConfigs;
    }

    setupTile(tileRequest: FinancialTileRequest, chartDataQueryResults: ChartDataQueryResult[], summaryData: any[], chartFilters: ChartFilters): DwTileConfig {
        const tileData = this.getTileData(tileRequest, chartDataQueryResults, summaryData, chartFilters);

        if (!tileData) {
            return 
        }

        let valueData = tileData[tileRequest.dataField];
        let formatedValueData = valueData;

        if (valueData == null) {
            if (tileRequest.hideIfNull) return;

            valueData = 0;
        }

        if (tileRequest.isCurrency) {
            formatedValueData = this.formatNumeric(valueData || 0, 0, true, false);
        }

        const monthDisplay = tileData[this.getFinancialFields().monthDisplay] || chartFilters.month;
        
        const templateData = { year: chartFilters.year || 'All', month: chartFilters.month, monthDisplay: monthDisplay, ...tileData};

        const containerTile = this.dwTemplateUtil.processTemplate(tileRequest.headerTemplate, templateData);
        const tileConfig: DwTileConfig = {
            containerTitle: containerTile,
            showContainerTitle: true,
            headerColor: tileRequest.headerColor,
            additionalText: tileRequest.additionalText,
            iconClass: tileRequest.iconClass,
            tileText: formatedValueData,
            toolTip: tileRequest.toolTip
        };

        return tileConfig;
    }

    private getTileData(tileRequest: FinancialTileRequest, chartDataQueryResults: ChartDataQueryResult[], summaryData: any[], chartFilters: ChartFilters): any {
        const fields = this.getFinancialFields();
        let data: any;
        switch (tileRequest.type) {
            case 'year':
                data = summaryData?.find(d => d[fields.year] === chartFilters.year);

                break;
            case "month":
                const dataQueryResult = chartDataQueryResults[tileRequest.chartDataIndex || 0];
                data = dataQueryResult?.data.find(d => d[fields.month] === chartFilters.month && d[fields.year] === chartFilters.year);

                if (!data && dataQueryResult?.data?.length > 0) {
                    // Use the first row of data if we can't find the month/year
                    data = dataQueryResult?.data[0];
                }
        }

        return data;
    }

    private processTemplate(tileRequest: FinancialTileRequest, chartDataQueryResults: ChartDataQueryResult[], summaryData: any[], chartFilters: ChartFilters): any {
        const fields = this.getFinancialFields();
        let data: any;
        switch (tileRequest.type) {
            case 'year':
                data = summaryData?.find(d => d[fields.year] === chartFilters.year);

                break;
            case "month":
                const dataQueryResult = chartDataQueryResults[tileRequest.chartDataIndex || 0];
                data = dataQueryResult?.data.find(d => d[fields.month] === chartFilters.month && d[fields.year] === chartFilters.year);

                if (!data && dataQueryResult?.data?.length > 0) {
                    // Use the first row of data if we can't find the month/year
                    data = dataQueryResult?.data[0];
                }
        }

        return data;
    }

    setupPieChart(filters: ChartFilters, additionalFilterOptions: ChartAdditionalFilterOptions, title: string, categoryField: fieldSelectorType<CheckrideRequestAggregateBaseQueryMetaDataAttributes>, dataFieldName: string, categoryDisplayField?: string, hideLegend?: boolean): Observable<ChartDataQueryResult> {
        const categoryFieldName = this.getFieldName(categoryField, false);

        return this.getPieChartData(filters, additionalFilterOptions, categoryFieldName)
            .pipe(map(result => {
                const attr = result.queryMetaData.Attributes.find(a => a.AttributeName == categoryFieldName);

                return {
                    data: result.data,
                    query: result.query,
                    queryMetaData: result.queryMetaData,
                    chartPreferences: this.buildPieChartPreferences(result, title, categoryDisplayField || attr?.DisplayAttributeName || categoryFieldName, dataFieldName, hideLegend)
                } as ChartDataQueryResult;
            }));
    }

    getChartData(chartDataRequest: ChartDataRequest, chartFilters: ChartFilters): Observable<DataQueryResult> {
        // Merge Filters
        const finalFilters = { ...chartDataRequest.filters, ...chartFilters };

        const categoryFieldName = this.getFieldName(chartDataRequest.categoryField, true);

        // Remove any year/month filters since we want a multi-year summary here
      //  finalFilters.month = null;

        return this.buildCheckRideBaseQuery(finalFilters, chartDataRequest.additionalFilterOptions)
            .pipe(mergeMap(result => {
                const query = result.queryBuilder;

                query.addField(f => categoryFieldName);

                for (const groupField of chartDataRequest.additionalGroupingFields || []) {
                    query.addField(f => this.getFieldName(groupField, true));
                }

                if (!chartDataRequest.includeBlank) {
                    query.addFilterAnd(f => f.IsNotBlank(a => categoryFieldName, null));
                }

                for (const orderField of chartDataRequest.orderByFields || []) {
                    let descending = false;
                    let fieldName = "";

                    if (isChartOrderbyField(orderField)) {
                        fieldName = this.getFieldName(orderField.field, true);
                        descending = orderField.isDescending
                    } else {
                        fieldName = this.getFieldName(orderField, true);
                    }

                    if (descending) {
                        query.addOrderByDesc(f => fieldName);
                    } else {
                        query.addOrderBy(f => fieldName);
                    }
                }

                return this.executeQueryData(query.query, result.queryMetaData);
            }));
    }

    private buildChartPreferences(dataQueryResult: DataQueryResult, chartDataRequest: ChartDataRequest): DwChartPreferences {
        let hideLegend = chartDataRequest.hideLegend || false;

        if (!hideLegend && chartDataRequest.chartType == DwChartTypes.pie) {
            hideLegend = dataQueryResult.data.length > (chartDataRequest.maxLegendItems || 5);
        }

        const categoryFieldName = this.getFieldName(chartDataRequest.categoryField, false);
        const categoryDisplayFieldName = this.getFieldName(chartDataRequest.categoryDisplayField, false);

        const attr = dataQueryResult.queryMetaData.Attributes.find(a => a.AttributeName == categoryFieldName);

        const finalCategoryFieldName = categoryDisplayFieldName || attr?.DisplayAttributeName || categoryFieldName;

        const categoryValueFieldName = attr?.AttributeName || categoryFieldName || categoryDisplayFieldName;

        const prefs: DwChartPreferences = {
            chartType: chartDataRequest.chartType,
            containerTitle: `${chartDataRequest.title}`,
            showContainerTitle: true,
            categoryField: finalCategoryFieldName,
            categoryValueField: categoryValueFieldName,
            categoryDisplay: chartDataRequest.categoryDisplayOverride || attr?.AttributeDisplay,
            gridPreferences: {
                showGrid: true,
                categoryColumnWidth: 300,
            },
            hideLegend: hideLegend,
            series: []
        };

        const series = chartDataRequest.series || [];

        if (series.length == 0) {
            if (!chartDataRequest.dataField) {
                throw new Error('Data field is required when series is not specified');
            }
            prefs.series.push({
                chartType: chartDataRequest.chartType,
                dataField: this.getFieldName(chartDataRequest.dataField, false),
                borderColor: 'white',
                alternateColors: true,
                alternateColorStart: 4,
            });
        } else {
            for (let seriesItem of chartDataRequest.series) {
                const index = chartDataRequest.series.indexOf(seriesItem);
                const chartType = seriesItem.chartType || chartDataRequest.chartType;
                const series: DwChartSeries = {
                    chartType: chartType,
                    dataField: this.getFieldName(seriesItem.dataField, false),
                    legendDisplay: seriesItem.legendDisplay,
                    borderColor: 'white',
                    alternateColors: chartType == DwChartTypes.pie,
                    alternateColorStart: 4,
                    axis: seriesItem.axis
                };

                switch (chartType) {
                    case DwChartTypes.pie:
                        series.alternateColors = true;
                        series.alternateColorStart = index;
                        break;
                    case DwChartTypes.line:
                        series.borderColor = seriesItem.color || this.colors[index];
                        break;
                    case DwChartTypes.bar:
                    case DwChartTypes.column:
                        series.backgroundColor = seriesItem.color || this.colors[index];
                        break;
                }

                prefs.series.push(series);
            }
        }

        return prefs;
    }

    private buildPieChartPreferences(dataQueryResult: DataQueryResult, title: string, categoryField: string, dataFieldName: string, hideLegend?: boolean): DwChartPreferences {

        if (hideLegend == null) {
            hideLegend = dataQueryResult.data.length > 5;
        }

        const prefs: DwChartPreferences = {
            chartType: DwChartTypes.pie,
            containerTitle: `${title}`,
            showContainerTitle: true,
            categoryField: categoryField,
            //categoryDisplay: 'Checkride Type',
            gridPreferences: {
                showGrid: true,
                categoryColumnWidth: 300,
            },
            hideLegend: hideLegend,
            series: [
                {
                    chartType: DwChartTypes.pie,
                    dataField: dataFieldName,
                    borderColor: 'white',
                    alternateColors: true,
                    alternateColorStart: 4,
                }
            ]
        };

        return prefs;
    }

    getPieChartData(filters: ChartFilters, additionalFilterOptions: ChartAdditionalFilterOptions, categoryField: string, includeBlank: boolean = false): Observable<DataQueryResult> {
        const newFilters = { ...filters };

        // Remove any year/month filters since we want a multi-year summary here
        newFilters.month = null;

        return this.buildCheckRideBaseQuery(filters, additionalFilterOptions)
            .pipe(mergeMap(result => {
                const query = result.queryBuilder;

                query.addField(f => categoryField);


                if (!includeBlank) {
                    query.addFilterAnd(f => f.IsNotBlank(a => categoryField, null));
                }

                //  const attribute = result.queryMetaData.Attributes.find(a => a.AttributeName == field || a.AttributeName == (field as DwItemAttribute)?.fieldName);

                return this.executeQueryData(query.query, result.queryMetaData);
            }));
    }

    private getFieldName(fieldSelector: fieldSelectorType<CheckrideRequestAggregateBaseQueryMetaDataAttributes> | string, includeItemName: boolean = true): string {
        const fields = new CheckrideRequestAggregateBaseQueryMetaDataAttributes();

        if (!fieldSelector) {
            return '';
        }

        if (typeof fieldSelector == 'string') {
            return fieldSelector;
        }

        const field = fieldSelector(fields);

        if (typeof field == 'string') {
            return field;
        }

        if (field instanceof DwItemAttribute) {

            return includeItemName ? field.fieldNameWithItemPrefix : field.fieldName;
        }

        return includeItemName ? field.FullFieldName : field.FieldName;
    }

    private executeQueryData(query: DwQuery, queryMetaData: DwQueryMetaData): Observable<DataQueryResult> {
        return this.dwOrmDataService.executeQuery(query)
            .pipe(map(data => {
                return {
                    data: data,
                    query: query,
                    queryMetaData: queryMetaData
                };
            }));
    }

    private getFinancialsQuery(filters: ChartFilters, additionalFilterOptions: ChartAdditionalFilterOptions): Observable<DwQuery> {

        return this.buildCheckRideBaseQuery(filters,additionalFilterOptions)
            .pipe(map(result => {
                const query = result.queryBuilder;
                query.addField(f => f.AggrScheduledDateTimeMonthDisplay);
                query.addField(f => f.AggrScheduledDateTimeMonth);
                query.addField(f => f.AggrScheduledDateTimeYear);

                query.addOrderBy(f => f.AggrScheduledDateTimeYear);
                query.addOrderBy(f => f.AggrScheduledDateTimeMonth);

                return query.query;
            }));
    }

    private getFinancialSummaryQuery(filters: ChartFilters, additionalFilterOptions: ChartAdditionalFilterOptions): Observable<DwQuery> {
        return this.buildCheckRideBaseQuery(filters, additionalFilterOptions)
            .pipe(map(result => {
                const query = result.queryBuilder;

                query.query.FieldSettings.AggregateQueryWithRollup = true;

                query.addField(f => f.AggrScheduledDateTimeYear);

                query.addOrderBy(f => f.AggrScheduledDateTimeYear);

                return query.query;
            }));
    }

    private buildCheckRideBaseQuery(filters?: ChartFilters, additionalFilterOptions?: ChartAdditionalFilterOptions): Observable<BaseQueryResult<CheckrideRequestAggregateBaseQueryBuilder>> {

        return AppMetaData.Queries.CheckrideRequestAggregateBase.CreateQueryBuilderWithMetaData(this.dwMetaDataService)
            .pipe(map(qmd => {
                return {
                    queryBuilder: this.setupCheckrideBaseQuery(qmd.queryBuilder, filters, additionalFilterOptions),
                    queryMetaData: qmd.queryMetaData
                }
            }));
    }


    private setupCheckrideBaseQuery(query: CheckrideRequestAggregateBaseQueryBuilder, filters?: ChartFilters, additionalFilterOptions?: ChartAdditionalFilterOptions): CheckrideRequestAggregateBaseQueryBuilder {
        query.query = Object.assign(new DwQuery(query.query.RootTable), deepCopy(query.query));

        query.query.FieldSettings.AggregateQuery = true;

        const qh = new DwQueryHelper();

        // Clear all fields so we can create a clean aggregate query
        qh.clearAllFields(query.query);
 
        query.query.Order = [];

        if (additionalFilterOptions?.includeStatuses) {
            query.addFilterAnd(f => f.InList(a => a.CheckRideRequestSubStatusId, additionalFilterOptions?.includeStatuses));    
        } 
        else {
            // If not include statuses, we use the passed in exclude filters or the defaults
            let excludeStatues = additionalFilterOptions?.excludeStatuses || [CheckRideRequestSubStatusAllItems.Cancelled, CheckRideRequestSubStatusAllItems.Rejected, CheckRideRequestSubStatusAllItems.Deleted, CheckRideRequestSubStatusAllItems.Draft];

            query.addFilterAnd(f => f.NotInList(a => a.CheckRideRequestSubStatusId, excludeStatues));
        }

        if (additionalFilterOptions?.includeProgramTypes) {
            query.addFilterAnd(f => f.InList(a => a.AvaiationTrainingProgramId, additionalFilterOptions.includeProgramTypes));
        }

        query.addFilterAnd(f => f.IsNotBlank(a => a.ScheduledDateTime, null));

        if (filters?.pilotExaminerId) {
            query.addFilterAnd(f => f.Equal(a => a.ScheduledPilotExaminerId, filters.pilotExaminerId));
        }

        if (filters?.year) {
            query.addFilterAnd(f => f.Equal(a => a.AggrScheduledDateTimeYear, filters.year));
        }

        if (filters?.month) {
            query.addFilterAnd(f => f.Equal(a => a.AggrScheduledDateTimeMonth, filters.month));
        }

        const fields = this.getFinancialFields();
        const attrs = AppMetaData.Queries.CheckrideRequestAggregateBase.Attributes;

        query.query.AddField({
            FieldType: DwFieldType.Aggregate,
            AggregateFunction: DwFieldAggregate.Count,
            FieldAlias: fields.checkrideCount
        });

        query.query.AddField({
            FieldType: DwFieldType.Aggregate,
            AggregateFunction: DwFieldAggregate.Sum,
            FieldAlias: fields.requestedCount,
            FullFieldName: attrs.AggrOutcomeIsRequested.fieldNameWithItemPrefix,
        });

        query.query.AddField({
            FieldType: DwFieldType.Aggregate,
            AggregateFunction: DwFieldAggregate.Sum,
            FieldAlias: fields.requestedRevenue,
            FullFieldName: attrs.AggrRevenueIsRequested.fieldNameWithItemPrefix,
        });

        query.query.AddField({
            FieldType: DwFieldType.Aggregate,
            AggregateFunction: DwFieldAggregate.Sum,
            FieldAlias: fields.bookedCount,
            FullFieldName: attrs.AggrOutcomeIsBooked.fieldNameWithItemPrefix,
        });

        query.query.AddField({
            FieldType: DwFieldType.Aggregate,
            AggregateFunction: DwFieldAggregate.Sum,
            FieldAlias: fields.bookedRevenue,
            FullFieldName: attrs.AggrRevenueIsBooked.fieldNameWithItemPrefix,
        });

        query.query.AddField({
            FieldType: DwFieldType.Aggregate,
            AggregateFunction: DwFieldAggregate.Sum,
            FieldAlias: fields.completedCount,
            FullFieldName: attrs.AggrOutcomeIsComplete.fieldNameWithItemPrefix,
        });

        query.query.AddField({
            FieldType: DwFieldType.Aggregate,
            AggregateFunction: DwFieldAggregate.Sum,
            FieldAlias: fields.completedRevenue,
            FullFieldName: attrs.AggrRevenueIsComplete.fieldNameWithItemPrefix,
        });

        query.query.AddField({
            FieldType: DwFieldType.Aggregate,
            AggregateFunction: DwFieldAggregate.Sum,
            FieldAlias: fields.paidRevenue,
            FullFieldName: attrs.AggrCheckridePaymentTotal.fieldNameWithItemPrefix,
        });

        query.query.AddField({
            FieldType: DwFieldType.Aggregate,
            AggregateFunction: DwFieldAggregate.Sum,
            FieldAlias: fields.pilexosRevenue,
            FullFieldName: attrs.PilexosBookingCost.fieldNameWithItemPrefix,
        });

        query.query.AddField({
            FieldType: DwFieldType.Aggregate,
            AggregateFunction: DwFieldAggregate.Sum,
            FieldAlias: fields.pilexosPaidRevenue,
            FullFieldName: attrs.AggrPilexosPaymentTotal.fieldNameWithItemPrefix,
        });

        return query;
    }

    getFinancialFields(): CheckrideFinancialFields {
        const attrs = AppMetaData.Queries.CheckrideRequestAggregateBase.Attributes;

        const fields = new CheckrideFinancialFields();

        fields.month = attrs.AggrScheduledDateTimeMonth.fieldName;
        //fields.monthDisplay = attrs.AggrScheduledDateTimeMonthDisplay.fieldName;
        fields.monthDisplay = attrs.AggrScheduledDateTimeMonthYearDisplay.fieldName;
        fields.year = attrs.AggrScheduledDateTimeYear.fieldName;

        fields.checkrideType = attrs.AggrCheckRideTypeDisplay.fieldName;

        // fields.checkrideOutcome = attrs.AdditionalTravelCost

        // 'CheckRideResultCheckRideOutcomeIdDisplay';

        return fields;
    }

    formatNumeric(value: any, decimals: number, money: boolean, excludeLocalNumericFormatting: boolean): string {
        const numberValue = parseFloat(value);

        if (isNaN(numberValue)) {
            return null;
        }

        let formattedValue = numberValue.toFixed(decimals);

        if (!excludeLocalNumericFormatting) {
            formattedValue = numberValue.toLocaleString('en-us', { maximumFractionDigits: decimals, minimumFractionDigits: decimals });
        }

        if (money) {
            formattedValue = `$${formattedValue}`;
        }

        return formattedValue;
    }

    colors = DwChartColors;
}  
