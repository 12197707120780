import { Component, Input, OnInit } from '@angular/core';
import { MenuItemCardData } from '../../models/menu-item-card-data.model';

@Component({
  selector: 'app-menu-item-card',
  templateUrl: './menu-item-card.component.html',
  styleUrls: ['./menu-item-card.component.scss']
})
export class MenuItemCardComponent implements OnInit {

  @Input() cardData : MenuItemCardData;

  constructor() { }

  ngOnInit(): void {
  }


  buttonClick() {
    this.cardData?.clickFunc();
  }

}
