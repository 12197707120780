import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DwOrmDataServiceToken, DwOrmDataService, DwMetaDataServiceToken, DwMetaDataService, DwConfigServiceToken, DwConfigService, DwSecurityUserService, DwModalResult, DwModalService, DwModalConfig, DwModalButtonStandardConfigs, DwModalSize } from "@devwareapps/devware-cap";
import { CheckridePaymentRequest } from "../models/checkride-payment-request.model";
import { Observable } from "rxjs";
import { CheckridePaymentResult } from "../models/checkride-payment-result.model";
import { AppMetaData, CheckRidePaymentEntity, CheckRideRequestEntity, CheckRideRequestSubStatusAllItems } from "../../../meta-data/app-meta-data.service";
import { PaymentModalComponent } from "../components/payment-modal/payment-modal.component";
import { PaymentModalOptions } from "../models/payment-modal-options.model";
import { CheckrideRefundPaymentResult } from "../models/checkride-refund-payment-result.model";
import { CheckrideRefundPaymentRequest } from "../models/checkride-refund-payment-request.model";
import { PaymentDiscountResult } from "../models/payment-discount-result.model";


@Injectable({ providedIn: 'root' })
export class PaymentRepositoryService {
    apiRoot: string;

    constructor(
        private http: HttpClient,
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        @Inject(DwConfigServiceToken) configService: DwConfigService,
        private dwSecurityUserService: DwSecurityUserService,
        private dwModalService: DwModalService
    ) {
        this.apiRoot = configService.coreConfig.apiRoot;
    }

    /**
     * Creates a Checkride payment
     * @param checkridePaymentRequest Payment Request
     * @returns Checkride payment result indicating success/failure
     */
    public CreateCheckridePayment(checkridePaymentRequest: CheckridePaymentRequest): Observable<CheckridePaymentResult> {
        return this.http.post<CheckridePaymentResult>(`${this.apiRoot}/pilexos/payments/create`, checkridePaymentRequest);
    }

    getCheckrideRequest(checkrideRequestId: number): Observable<CheckRideRequestEntity> {
        const checkRideQuery = AppMetaData.CheckRideRequest.CreateQueryBuilder();

        checkRideQuery.addFilterAnd(filters => filters.Equal(field => field.CheckRideRequestId, checkrideRequestId));

        return this.dwOrmDataService.executeQuerySingle(checkRideQuery);
    }
    /**
     * Admin function to refund a checkride payment
     * @param refundRequest 
     * @returns CheckrideRefundPaymentResult
     */
    refundCheckridePayment(refundRequeset: CheckrideRefundPaymentRequest): Observable<CheckrideRefundPaymentResult> {
        return this.http.post<CheckridePaymentResult>(`${this.apiRoot}/pilexos/payments/refund`, refundRequeset);
    }

    /**
     * Apply a discount code to a payment 
     * @remarks This is only used to show the user the discounted amount, but the logic is reapplied on the server side
     * @param discountCode Discount code provided by the user
     * @param totalAmount Total amount before discount
     * @returns Payment Discount result indicating if the discount is valid and the resulting amount
     */
    applyDiscountCode(discountCode: string, totalAmount: number): Observable<PaymentDiscountResult> {
        return this.http.post<PaymentDiscountResult>(`${this.apiRoot}/pilexos/payments/apply-discount-code?discountCode=${discountCode}&totalAmount=${totalAmount}`, {});
    }
    
}