
<dw-container [options]="containerOptions">
    <span header>{{title}}</span>
    <div body>
        <div class="inner-container">

            <ng-container *ngFor="let task of tasks">
                <div class="task-container-flex" [title]="task.tooltip || ''" (click)="taskClick(task)">
                    <div class="task-icon"><i [class]="task.iconClass"></i></div>
                    <div class="task-title">{{task.taskTitle}}</div>
                </div>
            </ng-container>
        </div>
    </div>
</dw-container>