import { Component, OnInit } from '@angular/core';
import { DwModalButtonStandardConfigs, DwModalConfig, DwModalSize, DwModalService, DwControlType, DwMediaRepositoryService } from '@devwareapps/devware-cap';

@Component({
  selector: 'app-additional-content',
  templateUrl: './additional-content.component.html',
  styleUrls: ['./additional-content.component.scss']
})
export class AdditionalContentComponent implements OnInit {
  // contentItems: ContentItemEntity[];
  // textboxControlType: DwControlType = DwControlType.Textbox;

  // constructor(private vendorHomePageDataService: VendorHomePageDataService, private dwModalService: DwModalService,
  //   private dwMediaRespositoryService: DwMediaRepositoryService) { }

  ngOnInit(): void { 

    // this.vendorHomePageDataService.loadContentItems()
    //   .subscribe(contentItems => {
    //     this.contentItems = contentItems;
    //   })
  }

  // clickContentItem(contentItem: ContentItemEntity) {
  //   switch (contentItem.ContentItemTypeId) {
  //     case ContentItemTypeAllItems.Link:
  //       if (contentItem.LinkUrl) {
  //         window.open(contentItem.LinkUrl, "_blank");
  //       }
  //       break;

  //     case ContentItemTypeAllItems.FileDownload:
  //       this.downloadContent(contentItem);
  //       break;
  //     case ContentItemTypeAllItems.Content:
  //       this.showDialogContent(contentItem.Title, contentItem.ContentHtml);
  //       break;

  //   }
  // }

  // public showDialogContent(title: string, message: string) {
  //   let modalConfig = new DwModalConfig();
  //   modalConfig.title = title;
  //   modalConfig.content = message;

  //   modalConfig.modalSize = DwModalSize.responsive;


  //   modalConfig.buttons = [DwModalButtonStandardConfigs.instance.closeButton];

  //   return this.dwModalService.showModal(modalConfig)
  //     .subscribe(result => {
  //       // Nothing to do with the result
  //     })
  // }

  // private downloadContent(contentItem: ContentItemEntity) {

  //   this.dwMediaRespositoryService.loadMedia(contentItem.MediaId)
  //     .subscribe(roMedias => {
  //       if (!roMedias || roMedias.length != 1) {
  //         return;
  //       }
  //       let roMedia = roMedias[0];

  //       this.dwMediaRespositoryService.downloadMedia(roMedia);

  //       // let fileUrl = `${this.dwMediaRespositoryService.mediaApi}/download/${roMedia.ContainerName}/${roMedia.FileName}`;

  //       // this.dwMediaRespositoryService.downloadFile(fileUrl, roMedia.FileName);
  //     })

  // }
}
