<ng-container *ngIf="searchResult">
    <div class="card card-container examiner-card" (click)="containerClick()"
        [ngClass]="{ 'card-selected': isContainerSelected() }">

        <div class="card-body">
            <div class="data-container-flex">
                <div class="column1">
                    <div class="title">{{searchResult.UserIdDisplay}}</div>
                    <img [src]="imagePath || defaultAvatarMediaUrl"
                        class="avatar-small avatar-banner-position avatar-image">
                </div>
                <div class="column3">
                    <div dwRow style="min-width: 250px">
                        <div dwCol="12">
                            <label class="label">Airport</label><br>
                            {{searchResult.LocationDistancesAirportIdDisplay}}
                        </div>
                        <div dwCol="6">
                            <label class="label">Distance</label><br>
                            {{searchResult.LocationDistancesDistance | number: '1.0-0'}} miles
                        </div>
                        <div dwCol="6">
                            <label class="label">Duration</label><br>
                            {{checkrideDurationDisplay}}
                        </div>
                        <div dwCol="6">
                            <label class="label">Cost <i class="fa fa-info-circle"
                                    title="Checkride cost excluding Pilexos booking cost and any applicable Travel Costs"></i></label><br>
                            {{checkrideCost | currency}}
                        </div>
                        <div dwCol="6">
                            <label class="label">Timezone</label><br>
                            {{searchResult.AirportAddressTimezoneIdDisplay}}
                        </div>
                        <div dwCol="12" *ngIf="searchResult.AdditionalResultDetails?.RequiresTravel">
                            <div class="dw-red" style="margin-top:15px;" title="Preferred airport requires DPE to travel and may incur travel fees">Requires DPE Travel</div>
                        </div>
                    </div>
                </div>
                <div class="column4">
                    <app-schedule-selector *ngIf="showDateSelection" [searchResult]="searchResult"
                        [isContainerSelected]="isContainerSelected()" [(selectedDateTime)]="selectedDateTime"
                        (selectedDateTimeChange)="setSelectedDateTime($event)"></app-schedule-selector>
                    <app-schedule-detail *ngIf="!showDateSelection" [searchResult]="searchresult"
                        [selectedDateTime]="selectedDateTime"></app-schedule-detail>
                </div>
            </div>
        </div>
    </div>
    <div class="select-container">
        <button class="btn btn-success" (click)="confirmSelection()"
            *ngIf="showDateSelection && isContainerSelected() && selectedDateTime"

            title="Select Pilot Examiner and Date/Time">Select and Continue</button>
    </div>
</ng-container>