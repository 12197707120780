import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DwContainerOptions } from '@devwareapps/devware-cap';
import { DwTileConfig } from '../../models/dw-tile-config.model';

@Component({
  selector: 'dw-mini-tile',
  templateUrl: './dw-mini-tile.component.html',
  styleUrls: ['./dw-mini-tile.component.scss']
})
export class DwMiniTileComponent implements OnInit, OnChanges {

  // @Input() headerText: string;
  // @Input() tileText: string;
  // @Input() additionalText:string;

  // @Input() iconClass: string = 'fa fa-usd-circle dw-green fa-3x';
  // @Input() headerBackgroundColor: string;
  // @Input() headerForegroundColor: string;

  @Input() tileConfig: DwTileConfig
  @Input() containerOptions : DwContainerOptions;

  finalContainerOptions : DwContainerOptions;

  @Output() clickEvent: EventEmitter<void> = new EventEmitter<void>();
  
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
      this.setupContainer();
  }

  ngOnInit(): void {
    this.setupContainer();
  }

  private setupContainer() {
    // Allow override of container options
    if (this.containerOptions) {
      this.finalContainerOptions = this.containerOptions;
    } else {
      this.finalContainerOptions = {
        useContainerStyling: true,
        containerAllowCollapse: false,
      }
    }

    this.finalContainerOptions.showTitle = true;
    if (this.tileConfig?.showContainerTitle === false) {
      this.finalContainerOptions.showTitle = false;
    }

    this.finalContainerOptions.containerHeaderBackgroundColor = this.tileConfig?.headerColor || 'green';
  }
}
