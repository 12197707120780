 <dw-container [options]="containerOptions" [(collapsed)]="collapsed" (collapsedChange)="collapsed=$event" *ngIf="containerOptions">
    <span header>
        {{sectionTitle}}
    </span>
    <div body>
        <ng-container>
            <dw-button-bar>
                <button class="btn btn-primary" (click)="addQuestion()">Add Question</button>
                <button class="btn btn-warning" (click)="addAcsCodes()" *ngIf="showAcsCode">Add ACS Codes</button>
            </dw-button-bar>

            <div *ngIf="planOfActionSectionQuestions?.length == 0">
                No items have been added to this section.
            </div>

            <dw-ordered-list [(items)]="planOfActionSectionQuestions" (itemsChange)="onQuestionsChange($event)" *ngIf="planOfActionSectionQuestions">
                <ng-container *dwListItem="let question">
                    <app-plan-of-action-question [question]="question" (questionChange)="onQuestionChange($event)" [currentAcsCodes]="currentAcsCodes" [acsCodes]="acsCodes"
                    [showQuestion]="showQuestion" [showAcsCode]="showAcsCode"></app-plan-of-action-question>
                </ng-container>


            </dw-ordered-list>
        </ng-container>
    </div>
</dw-container>
