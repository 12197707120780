import { Injectable } from "@angular/core";
import { DwAction, DwActionHandler, DwActionResult, DwActionType, DwButtonType, DwMdFormActionState, DwRoutingService } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaDataItemNames, CheckRideRequestEntity } from "../../../../meta-data/app-meta-data.service";




@DwAction({
    keyName: 'rebook-applicant-checkride',
    display: 'Rebook Applicant Checkride',
    parentItemName: [AppMetaDataItemNames.CheckRideRequest, AppMetaDataItemNames.CheckRideResult],
    isGlobal: false,
    actionType: DwActionType.formAction,
    defaultButtonConfig: {
        useParentDisplay: false,
        buttonText: 'Rebook Checkride',
        buttonClass: 'btn btn-warning btn-sm',
        buttonTitle: 'Rebook Checkride',
        buttonType: DwButtonType.standard,
        iconClass: 'fa fa-plane-departure'
    }
})
@Injectable()
export class RebookApplicantCheckrideActionService  implements DwActionHandler  {

    constructor(private dwRoutingService: DwRoutingService) {}

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {
        const result = new DwActionResult();
       
        const checkride = actionState.formApi.getFormData<CheckRideRequestEntity>();

        const url = '/checkrides/add?RebookCheckrideId=' + checkride.CheckRideRequestId;

        this.dwRoutingService.navigateToUrl(url, { });

        return of(result);
    }
}