<!-- {{planOfActionSection | json}}

Area of Operation Id: {{ planOfActionSection?.AcsAreaOfOperationId }}
Task Id: {{ planOfActionSection.AcsAreaOfOperationTaskId }} -->

<div dwRow [formGroup]="formGroup">
    <div dwCol="2" *ngIf="showAscCode">
        <label class="label" [hidden]="hideLabels">ASC Code</label>
        <p-dropdown [options]="currentCodes" optionLabel="AcsCode" display="chip" optionValue="AcsCodeId"
            formControlName="AcsCodeId" (ngModelChange)="ascCodeChanged($event)" [style]="{'width':'100%'}"
            [filter]="true" appendTo="body"></p-dropdown>
    </div>
    <div dwCol="10" *ngIf="showQuestion">
        <label class="label" [hidden]="hideLabels">Question</label>
        <textarea class="form-control" formControlName="QuestionHtml" rows="1"></textarea>

        {{currentAcsCode?.AscCodeDescription}}
    </div>
    <div dwCol="10" *ngIf="!showQuestion">
        <label class="label" [hidden]="hideLabels">ACS Code Description</label>
        <div class="form-control">
            {{currentAcsCode?.AscCodeDescription}}
        </div>
    </div>
</div>