<dw-router-parent>
    <ng-template>

        <dw-content-manager configKey="examiner-calendar" *ngIf="!compactView">
            <h1>
                <dw-content name="title" display="Page Title" [controlType]="textboxControlType">
                    Booking Calendar
                </dw-content>
            </h1>

            <dw-content name="detail" display="Header Details">
                Here you see a calendar with all of your scheduled checkrides.
            </dw-content>

            <dw-content-component name='component' display='Custom Component'></dw-content-component>
        </dw-content-manager>

        <div class="calendar-setup-mobile" *ngIf="!compactView">
            <app-google-calendar-consent [(calendarReference)]="calendarReference" *ngIf="initComplete"
                (calendarReferenceChange)="updateCalendarReference($event)"
                [allCalendars]="allCalendars"></app-google-calendar-consent>
        </div>

        <app-calendar-weekday [options]="options" [events]="events" (eventClick)="eventClick($event)"
            (dateChange)="handleDateChange($event)" [compactView]="compactView" (gotoCalendarClick)="gotoCalendar()">

            <div>
                <b>Checkrides</b><br>
                <div class="calendar-item">
                    <div [ngStyle]="{'background-color': requiresActionEventColor}" class="calendar-color"></div>

                    <div class="calendar-text">Pilexos Checkride - Action Required</div>
                </div>
                <div class="calendar-item">
                    <div [ngStyle]="{'background-color': noActionRequiredEventColor}" class="calendar-color"></div>

                    <div class="calendar-text">Pilexos Checkride - All good</div>
                </div>
            </div>

            <div *ngIf="usedCalendars?.length>0" class="mt-1">
                <b>My Calendars</b><br>

                <ng-container *ngFor="let cal of usedCalendars">
                    <div class="calendar-item" [title]="cal.Description || ''">
                        <div [ngStyle]="{'background-color': cal.Color?.BackgroundColor}" class="calendar-color"></div>

                        <div class="calendar-text">{{cal.Name}}</div>
                    </div>
                </ng-container>
            </div>
            <app-google-calendar-consent [(calendarReference)]="calendarReference" *ngIf="initComplete"
                (calendarReferenceChange)="updateCalendarReference($event)"
                [allCalendars]="allCalendars"></app-google-calendar-consent>

        </app-calendar-weekday>

        <ng-container *ngIf="selectedEvent && false">
            {{selectedEvent | json}}
        </ng-container>

        <ng-container *ngIf="selectedCheckRide && false">
            <h2>Check Ride Request</h2>
            <dw-meta-data-form #mdForm item="CheckRideRequest" [keyValue]="selectedCheckRide.CheckRideRequestId"
                configKeyPart="dep-admin" [handleRedirect]="false" [showButtons]="false" [isModal]="false"
                formMode="view"></dw-meta-data-form>
        </ng-container>




    </ng-template>
</dw-router-parent>