import { Component, EventEmitter, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DwComponent, DwComponentType, DwContainerOptions, DwContext, DwExpressionService, DwMessage, DwMessageSeverity, DwMetaDataFormConfig, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwSectionBasePreference, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService, deepCopy } from '@devwareapps/devware-cap';
import { Observable, of, Subscription } from 'rxjs';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { AppMetaData, AppMetaDataItemNames, CheckrideActiveApplicantCheckridesQueryEntity, CheckRideRequestEntity, CheckRideRequestPaymentPartyEntity, CheckRideTestTypeAllItems, PilotExaminerLocationEntity, PilotExaminerSearchQueryEntity } from '../../../../meta-data/app-meta-data.service';
import { ExaminerLocationSearchRequest } from '../../../examiner/models/examiner-location-search-request.model';
import { ExaminerLocationSelection } from '../../../examiner/models/exminer-location-selection.model';
import { map, tap } from 'rxjs/operators';
import { ApplicantCheckrideVariables } from '../../../applicant/models/applicant-checkride-variables.model';
import { PilotExaminerSearchQueryEntityExt } from '../../../../meta-data/extensions/pilot-examiner-search-query-ext.model';
import { ApplicantRepositoryService } from '../../../applicant/services/applicant-repository.service';



@DwComponent({
  key: 'exminer-booking-search-results',
  name: ' Examiner Booking Search Results',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.CheckRideRequest,
  isGlobal: false
})
@Component({
  selector: 'app-examiner-booking-search-results',
  templateUrl: './examiner-booking-search-results.component.html',
  styleUrls: ['./examiner-booking-search-results.component.scss']
})
export class ExaminerBookingSearchResultsComponent extends DwSectionBaseComponent {

  loading: boolean = false;
  loadingMessage: string;

  message: DwMessage = null;

  showSearch: boolean = true;
  searchResults: PilotExaminerSearchQueryEntityExt[];

  examinerLocationSelection: ExaminerLocationSelection;

  initComplete = false;
  checkRideRequest: CheckRideRequestEntity;
  lastCheckRideRequest: CheckRideRequestEntity;
  backupCheckride: CheckRideRequestEntity;
  allowCancel: boolean;
  variableInitComplete: boolean = false;
  lastVariables: { searchOtherAirports: boolean; withinMiles: number; preferredPilotExaminerId?: number; showProposedSearch?: boolean; showSearch?: boolean; };
  variableWatchSubscription: Subscription;
  resetMessage: string;
  checkRideRequestPaymentPartyList: CheckRideRequestPaymentPartyEntity[];
  activeCheckrides: CheckrideActiveApplicantCheckridesQueryEntity[];
  formApi: any;

  //testType: CheckRideTestTypeAllItems;

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private examinerRepositoryService: ExaminerRepositoryService,
    private applicantRepositoryService: ApplicantRepositoryService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);

    this.formApi = dwMetaDataFormStateService.state.formApi;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.variableWatchSubscription) {
      this.variableWatchSubscription.unsubscribe();
      this.variableWatchSubscription = null;
    }

  }

  setupComponentComplete() {
    this.setupDataWatch();

    this.visibleChange.subscribe(visible => {
      if (visible) {
        this.dataUpdated();
      }

    });

    this.examinerRepositoryService.getCheckRideRequestPaymentParty().subscribe(results => {
      this.checkRideRequestPaymentPartyList = results;
    });
  }

  setupDataWatch() {
    this.subscriptions.push(
      this.dwMetaDataFormStateService.state.formApi.getFormDataChanges(100)
        .subscribe(dataChange => {
          if (this.dwMetaDataFormStateService.state.formApi.isDataLoadComplete) {
            this.dataUpdated();
          }
        }));

    this.setupVariableChangeWatch();

    if (this.dwMetaDataFormStateService.state.formApi.isDataLoadComplete) {
      this.dataUpdated();
    }
  }

  applicantCheckrideVariables: ApplicantCheckrideVariables;

  private dataUpdated() {
    this.checkRideRequest = this.dwMetaDataFormStateService.state.formApi.getFormData<CheckRideRequestEntity>();

    if (!this.variableInitComplete) {

      this.initVariables();
      this.variableInitComplete = true;

    }

    if (!this.visible) {
      return;
    }

    const variables = this.getVariables();
    this.applicantCheckrideVariables = this.dwMetaDataFormStateService.state.formApi.getVariableContextValue<ApplicantCheckrideVariables>();

    if (this.checkforSearchChanges(this.checkRideRequest, variables)) {
      return;
    };

    let forceHideCancel = false;

    if (variables.checkRideNoLongerValid) {
      forceHideCancel = false;
    }

    if (this.checkRideRequest.ScheduledPilotExaminerId && !forceHideCancel) {
      this.allowCancel = true;
    }
    
    if (variables.showSearch) {
      // console.log("Kicking off search for checkride", variables);


      if (!this.searchResults) {
        this.search();
      }
      return;
    }

    if (variables.showProposedSearch) {
      //  console.log("Kicking off proposed search for checkride", variables);
      this.search(true);

      if (!forceHideCancel) {
        this.allowCancel = true;
      }
    }
  }


  checkforSearchChanges(checkRideRequest: CheckRideRequestEntity, variables: { searchOtherAirports: boolean; withinMiles: number; preferredPilotExaminerId?: number; showProposedSearch?: boolean; showSearch?: boolean; }): boolean {

    let resetSearch = false;

    if (!checkRideRequest || !variables || !this.lastCheckRideRequest || !this.lastVariables) {

      this.lastCheckRideRequest = deepCopy(checkRideRequest);
      this.lastVariables = variables;

      return;
    }

    // Check if Grade or Class changes
    if (checkRideRequest.CheckRideGroupId != this.lastCheckRideRequest?.CheckRideGroupId ||
      checkRideRequest.CheckRideTypeId != this.lastCheckRideRequest?.CheckRideTypeId ||
      checkRideRequest.CheckRideTestTypeId != this.lastCheckRideRequest?.CheckRideTestTypeId ||
      checkRideRequest.PreferredDateTime != this.lastCheckRideRequest?.PreferredDateTime ||
      checkRideRequest.PreferredAirportId != this.lastCheckRideRequest?.PreferredAirportId
    ) {
      resetSearch = true;
    } else if (
      variables?.searchOtherAirports != this.lastVariables.searchOtherAirports ||
      variables?.preferredPilotExaminerId != this.lastVariables.preferredPilotExaminerId ||
      variables?.withinMiles != this.lastVariables.withinMiles
    ) {
      resetSearch = true;
    }

    if (resetSearch) {
      this.searchResults = null;
      this.message = {
        messageBody: 'Search critieria has changed.  Please search again',
        severity: DwMessageSeverity.warning
      };
    }

    this.lastCheckRideRequest = deepCopy(checkRideRequest);;
    this.lastVariables = variables;

    return resetSearch;


  }

  initVariables() {
    let showSearch = false;
    if (!this.checkRideRequest.ScheduledPilotExaminerId) {
      showSearch = true;
    }

    this.setVariable(this.SHOW_SEARCH, showSearch);

    this.setVariable(this.SHOW_PROPOSED_SEARCH, false);


  }

  cancelSearch() {
    const variables = this.getVariables();

    if (variables.showSearch) {
      this.setVariable(this.SHOW_SEARCH, false);
    }

    if (variables.showProposedSearch) {
      this.setVariable(this.SHOW_PROPOSED_SEARCH, false);
    }
  }

  examinerLocationSelected() {
    // populate the form
    var entity = AppMetaData.CheckRideRequest.CreateEntity();

    var result = this.examinerLocationSelection.searchResult;


    if (this.examinerLocationSelection.confirmed) {

      const variables = this.getVariables();

      let timeMinutes = this.examinerRepositoryService.getCheckrideTimeMinutes(result, this.checkRideRequest.CheckRideTestTypeId);


      if (variables.showProposedSearch) {
        entity.ProposedDateTime = this.examinerLocationSelection.scheduleDateTime;
        entity.ProposedTimeMinutes = timeMinutes;
        entity.ProposedAirportId = result.LocationDistancesAirportId;

        // Set these too so we can use the standard acceptance workflow
        entity.ScheduledDateTime = this.examinerLocationSelection.scheduleDateTime;
        entity.ScheduledTimeMinutes = timeMinutes;
        entity.ScheduledAirportId = result.LocationDistancesAirportId;
        entity.ScheduledPreTimeMinutes = result.ScenarioPreTimeMinutes;
        entity.ScheduledPostTimeMinutes = result.ScenarioPostTimeMinutes;
        entity.ScheduledCheckRideScenarioId = result.ScenarioCheckRideScenarioId;
        entity.ScheduledPilotExaminerLocationId = result.LocationDistancesPilotExaminerLocationId;
        entity.ExaminerToApplicantNotes = result.CheckRideScenarioRequiredDocumentNotes;

        // Set the proposal pending so we can finish up
        this.setVariable(this.PROPOSAL_PENDING, true);
      }

      if (variables.showSearch) {
        entity.ScheduledPilotExaminerId = result.PilotExaminerId;

        // Need to convert to
        entity.ScheduledDateTime = this.examinerLocationSelection.scheduleDateTime;
        entity.ScheduledTimeMinutes = timeMinutes;
        entity.ScheduledAirportId = result.LocationDistancesAirportId;
        entity.ScheduledPreTimeMinutes = result.ScenarioPreTimeMinutes;
        entity.ScheduledPostTimeMinutes = result.ScenarioPostTimeMinutes;
        entity.ScheduledCheckRideScenarioId = result.ScenarioCheckRideScenarioId;
        entity.ScheduledPilotExaminerLocationId = result.LocationDistancesPilotExaminerLocationId;
      }

      entity.ScheduledCheckRideCost = this.examinerRepositoryService.getFullBookingCost(result, this.checkRideRequest.CheckRideTestTypeId, this.applicantCheckrideVariables); //result.CheckRideScenarioCheckRideCost || 0 + result.PilotExaminerLocationAdditionalLocationCost || 0;;
      entity.AdditionalTravelCost = result.PilotExaminerLocationTravelCost;
      entity.ExaminerBookingCost = result.CheckRideScenarioBookingCost;
      entity.PilexosBookingCost = this.examinerRepositoryService.getPilexosBookingCost(this.checkRideRequest.CheckRideTestTypeId, this.checkRideRequest.PilexosBookingCostPaymentPartyId, this.checkRideRequestPaymentPartyList);
      entity.ExaminerToApplicantNotes = result.CheckRideScenarioRequiredDocumentNotes;

      this.dwMetaDataFormStateService.state.formApi.patchFormData(entity);

      this.searchResults = [result];

      this.setVariable(this.SHOW_PROPOSED_SEARCH, false);
      this.setVariable(this.SHOW_SEARCH, false);
      this.setVariable(this.Checkride_Date_No_Longer_Valid, false);
    }
  }

  // clearFormPilotExaminer() {
  //   var entity = AppMetaData.CheckRideRequest.CreateEntity();

  //   entity.ScheduledPilotExaminerId = null;

  //   this.dwMetaDataFormStateService.state.formApi.patchFormData(entity);
  // }

  search(noMesage: boolean = false) {
    const checkRideRequest = this.dwMetaDataFormStateService.state.formApi.getFormData<CheckRideRequestEntity>();

    const variables = this.getVariables();

    const searchRequest: ExaminerLocationSearchRequest = {
      aviationAuthorityId: checkRideRequest.AviationAuthorityId,
      checkRideGroupId: checkRideRequest.CheckRideGroupId,
      checkRideTypeId: checkRideRequest.CheckRideTypeId,
      checkRideTestTypeId: checkRideRequest.CheckRideTestTypeId,
      preferredAirportId: parseInt(checkRideRequest.PreferredAirportId?.toString()),
      preferredDateTime: this.convertDate(checkRideRequest.PreferredDateTime),
      preferredExaminerId: variables.preferredPilotExaminerId,
      searchOtherAirports: variables.searchOtherAirports,
      searchWithinMiles: variables.withinMiles,
    }

    if (!searchRequest.checkRideGroupId || !searchRequest.checkRideTypeId || !searchRequest.preferredAirportId) {
      this.loading = false;

      if (!noMesage) {
        this.message = {
          messageBody: 'Please fill out the preferred aiport to search',
          severity: DwMessageSeverity.error
        };
      }

      return;
    }

    this.validateActiveCheckrides(searchRequest)
      .subscribe((valid) => {


        if (!valid) {
          this.loading = false;

          if (!noMesage) {
            this.message = {
              messageBody: 'You already have an active checkride scheduled for this Category/Class.  Please cancel the active checkride to add a new one',
              severity: DwMessageSeverity.error
            };
          }

          return;
        }


        this.loadResults(searchRequest, true)
          .subscribe(searchResults => {
            if (this.searchResults.length == 0) {
              this.message = {
                messageBody: 'No check rides available for your current criteria.  Adjust your criteria and try again',
                severity: DwMessageSeverity.warning
              };

              this.examinerLocationSelection = null;

              this.loading = false;

              return;
            }

            const result = this.searchResults[0];

            // Default first result
            this.examinerLocationSelection = {
              searchResult: result,
              scheduleDateTime: null,
              confirmed: false,
            };

            setTimeout(() => {
              this.loading = false;
            }, 250)
          });

      });
  }

  validateActiveCheckrides(searchRequest: ExaminerLocationSearchRequest): Observable<boolean> {
    let ob = this.applicantRepositoryService.getActiveCheckrides(this.checkRideRequest.CheckRideRequestId);

    if (this.activeCheckrides) {
      ob = of(this.activeCheckrides);
    }

    return ob.pipe(map(activeCheckrides => {
      this.activeCheckrides = activeCheckrides;


      const matchingActiveCheckrides = activeCheckrides.find(ac => ac.CheckRideGroupId == searchRequest.checkRideGroupId && ac.CheckRideTypeId == searchRequest.checkRideTypeId);
      return !matchingActiveCheckrides;
    }));
  }

  convertDate(preferredDateTime: string): string {
    // if (preferredDateTime.indexOf('T') < 0) {
    //   preferredDateTime += 'T00:00:00';
    // }

    const date = new Date(preferredDateTime);

    //return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    let dateWithTime = date.toJSON();

    const dateOnly = `${dateWithTime.substring(0, 10)}T00:00:00.000Z`

    return dateOnly;
  }

  loadResults(searchRequest: ExaminerLocationSearchRequest, isSearch: boolean): Observable<PilotExaminerSearchQueryEntityExt[]> {
    this.loading = true;
    this.loadingMessage = "Searching";
    this.message = null;

    this.searchResults = [];

    this.examinerLocationSelection = null;


    let searchOb = this.examinerRepositoryService.searchExaminerByLocation(searchRequest);

    // if (searchRequest.searchWithinMiles == 101) {
    //   searchOb = this.examinerRepositoryService.searchExaminerByLocationOnClient(searchRequest);
    // }

    return searchOb.pipe(tap(searchResults => {
      this.searchResults = searchResults;
    }));
  }


  private getVariables(): { searchOtherAirports: boolean, withinMiles: number, preferredPilotExaminerId?: number, showProposedSearch?: boolean, showSearch?: boolean, checkRideNoLongerValid?: boolean } {
    //const variableContext = this.dwMetaDataFormStateService.state.context['variable_contex_-CheckRideRequest'];
    const variableContext = this.formApi.getVariableContext();

    if (!variableContext) {
      return { searchOtherAirports: false, withinMiles: 0 }
    };

    return {
      searchOtherAirports: variableContext.value['Search_Other_Airports'],
      withinMiles: this.parseInt(variableContext.value['Within_Miles'] || 50),
      preferredPilotExaminerId: this.parseInt(variableContext.value['Preferred_Pilot_Examiner_Id']),
      showProposedSearch: variableContext.value[this.SHOW_PROPOSED_SEARCH] == true,
      showSearch: variableContext.value[this.SHOW_SEARCH] == true,
      checkRideNoLongerValid: variableContext.value['Checkride_Date_No_Longer_Valid'] == true,
    }
  }

  SHOW_SEARCH = 'Show_Search';
  SHOW_PROPOSED_SEARCH = 'Show_Proposed_Search';
  PROPOSAL_PENDING = 'Proposal_Pending';
  Checkride_Date_No_Longer_Valid = 'Checkride_Date_No_Longer_Valid';

  setVariable(variableName: string, value: boolean) {
    const variableContext = this.formApi.getVariableContext();

    if (!variableContext) {
      return;
    }

    //console.log(`Setting ${variableName} to ${value}`);

    const variables = variableContext.value;

    if (variables[variableName] == value) {
      return;
    }

    variables[variableName] = value;

    this.formApi.patchVariableContext(variables);

    //variableContext.next(variables);
  }

  setupVariableChangeWatch() {
    const variableContext = this.formApi.getVariableContext();

    if (!variableContext) {
      return;
    }

    if (this.variableWatchSubscription) {
      this.variableWatchSubscription.unsubscribe();
    }

    this.variableWatchSubscription = variableContext.asObservable().subscribe(variableValues => {
      this.checkforSearchChanges(this.checkRideRequest, this.getVariables());
    });
  }

  private parseInt(value: string): number {
    if (!value) {
      return null;
    }

    return parseInt(value);
  }


}
