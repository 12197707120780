export const DwChartColors = [
    '#519DE9',
    '#7CC674',
    '#F0AB00',
    '#C9190B',
    
    '#06C',
    '#38812F',
    '#8481DD',
    //'#EF9234',
    '#EC7A08',
    '#009596',

    //'#F0AB00'


    
// '#846a6a', '#008f3c', '#aba361', '#5e239d', '#bccce0',

//     '#00876c',
//     '#44946d',
//     '#68a071',
//     '#88ac77',
//     '#a5b781',
//     '#c1c38e',
//     '#dccf9e',
//     '#dabb84',
//     '#daa66d',
//     '#da905c',
//     '#da7752',
//     '#d95c4e',
//     '#d43d51'

]



export const DwChartColors2 = [
    '#20a4f3ff',
    '#f18f01ff',
    '#80ff72ff',
    // '#941c2fff',
    '#d43d51',
    '#3d315bff',

    
'#846a6a', '#008f3c', '#aba361', '#5e239d', '#bccce0',

    '#00876c',
    '#44946d',
    '#68a071',
    '#88ac77',
    '#a5b781',
    '#c1c38e',
    '#dccf9e',
    '#dabb84',
    '#daa66d',
    '#da905c',
    '#da7752',
    '#d95c4e',
    '#d43d51']



export const DwChartColors3 = [
 
        '#00876c',
        '#44946d',
        '#68a071',
        '#88ac77',
        '#a5b781',
        '#c1c38e',
        '#dccf9e',
        '#dabb84',
        '#daa66d',
        '#da905c',
        '#da7752',
        '#d95c4e',
        '#d43d51']


        


//         --carrot-orange: #f18f01ff;
// --celestial-blue: #20a4f3ff;
// --screamin-green: #80ff72ff;
// --burgundy: #941c2fff;
// --space-cadet: #3d315bff;