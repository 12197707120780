import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usre-avatar',
  templateUrl: './usre-avatar.component.html',
  styleUrls: ['./usre-avatar.component.scss']
})
export class UsreAvatarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
