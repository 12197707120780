import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { DwButtonCommand, DwButtonConfig, DwComponent, DwComponentType, DwExpressionService, DwGridEvent, DwGridEvents, DwGridHeightMode, DwMessage, DwMessageSeverity, DwMetaDataFormApi, DwMetaDataFormStateService, DwMetaDataGridPreferences, DwMetaDataGridViewPreference, DwMetaDataService, DwMetaDataServiceToken, DwModalConfig, DwModalHandler, DwModalResult, DwSectionBaseComponent, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService } from '@devwareapps/devware-cap';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { map } from 'underscore';
import { AppMetaDataLookups, AppMetaDataItemNames, PlanOfActionEntity, PilotExaminerEntity, AcsTestEntity, PlanOfActionSectionEntity, AppMetaData, PlanOfActionSectionTypeAllItems, AcsCodeEntity } from '../../../../meta-data/app-meta-data.service';
import { PlanOfActionRepositoryService } from '../../../plan-of-action/services/plan-of-action-repository.service';
import { GenerateSectionsOptions } from '../../../plan-of-action/models/generate-sections-options.model';
import { PlanOfActionGeneratorService } from '../../../plan-of-action/services/plan-of-action-generator.service';
import { AcsCodeStatuses, AcsCodeStorage, AcsCodesStorageItem } from '../../../plan-of-action/models/acs-code-items.model';
import { GenerateCodesOptions } from '../../../plan-of-action/models/generate-codes-options.model';
import { AcsCodeSelectionModalResult } from '../../../plan-of-action/models/acs-code-selection-modal-options.model';
import { DwMdGridCallbacks } from '@devwareapps/devware-cap/devware-ui-metadata/grid/model/callbacks/dw-md-grid-callbacks.interface';


@Component({
  selector: 'app-select-acs-codes',
  templateUrl: './select-acs-codes.component.html',
  styleUrls: ['./select-acs-codes.component.scss']
})
export class SelectAcsCodesComponent {
  @Input() planOfAction: PlanOfActionEntity;
  @Input() planOfActionSection: PlanOfActionSectionEntity;

  message: DwMessage;

  @Input() options: GenerateCodesOptions;
  @Output() optionsChange = new EventEmitter<GenerateCodesOptions>();

  acsTest: AcsTestEntity;

  allAcsCodes: AcsCodeStorage;

  aosCodesItem = AppMetaDataItemNames.AcsCode;

  codesGridFilters: any = {};
  questionGridFilters: any = {};

  codesGridPreferences: Partial<DwMetaDataGridPreferences> = {};
  questionsGridPreferences: Partial<DwMetaDataGridPreferences> = {};
  setupComplete: boolean = false;
  acsCodeStatuses: AcsCodeStatuses;
  gridCallbacks: DwMdGridCallbacks;

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    @Optional() private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private planOfActionRepositoryService: PlanOfActionRepositoryService,
    private planOfActionGeneratorSection: PlanOfActionGeneratorService
  ) {

  }

  ngOnInit(): void {
    this.setupData();

    
    this.gridCallbacks = {
      getRowClass: (row)=> this.getRowClass(row.data)
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.planOfAction?.firstChange === false || changes.planOfActionSection?.firstChange === false) {
      this.setupData();
    }
  }

  onCodeGridEvent(event: DwGridEvent) {
    if (event.eventType as string != 'rowSelectionChange') {
      return;
    }

    this.options.acsCodes =event.eventData;
    this.options.questions = [];

    this.onOptionChange();
  }

  onQuestionGridEvent(event: DwGridEvent) {
    if (event.eventType as string != 'rowSelectionChange') {
      return;
    }
    this.options.acsCodes = [];
    this.options.questions = event.eventData;

    this.onOptionChange();
  }

  onOptionChange() {
    this.optionsChange.emit(this.options);
  }
  
private getRowClass(acsCode: AcsCodeEntity) {
  if (!acsCode) {
    return '';
  }
  const acsStatus = this.acsCodeStatuses.codesById[acsCode.AcsCodeId];

  if (acsStatus) {
    if (acsStatus.missing) {
      return 'dw-red';
    }
    return 'dw-green';
  }

  return '';
}

  private setupData() {
    if (!this.planOfAction) {
      return;
    }

    this.setupFilters();
    // this.filters.AcsTestId = this.planOfAction.AcsTestId;

    this.codesGridPreferences = this.buildGridPreferences(false);
    this.questionsGridPreferences = this.buildGridPreferences(true);

    if (!this.options) {
      this.options = {
        acsCodes:[],
        questions:[],
        allowQuestionSelect: true,
        useQuestionBank: true
      }

      this.optionsChange.emit(this.options);
    }

    this.acsCodeStatuses = this.planOfActionRepositoryService.getAcsCodeStatus(this.planOfAction);

    this.setupComplete = true;
  }

  setupFilters() {

    this.codesGridFilters = {};
    this.questionGridFilters = {}
    
    this.codesGridFilters[AppMetaData.Queries.AcsCodesGrid.Attributes.AcsAreaOfOperationAcsTestId.fieldName] = this.planOfAction.AcsTestId;
    this.questionGridFilters[AppMetaData.Queries.AcsCodesWithQuestions.Attributes.AcsAreaOfOperationAcsTestId.fieldName] = this.planOfAction.AcsTestId;
    
    if (!this.planOfActionSection) {
      return;
    }

    let aosId: number;
    let taskId: number;

    switch(this.planOfActionSection.PlanOfActionSectionTypeId) {
      case PlanOfActionSectionTypeAllItems.AreaOfOperationFlight:
      case PlanOfActionSectionTypeAllItems.AreaOfOperationGround:
        aosId = this.planOfActionSection.AcsAreaOfOperationId;
        break;
      case PlanOfActionSectionTypeAllItems.TaskGround:
      case PlanOfActionSectionTypeAllItems.TaskFlight:
        taskId = this.planOfActionSection.AcsAreaOfOperationTaskId;
        break;
    }
   
    if (taskId) {
      this.codesGridFilters[AppMetaData.Queries.AcsCodesGrid.Attributes.AcsAreaOfOperationTaskId.fieldName] = taskId;
      this.questionGridFilters[AppMetaData.Queries.AcsCodesWithQuestions.Attributes.AcsAreaOfOperationTaskAcsAreaOfOperationTaskId.fieldName] = taskId;
    }

    if (aosId) {
      this.codesGridFilters[AppMetaData.Queries.AcsCodesGrid.Attributes.TaskAcsAreaOfOperationId.fieldName] = aosId;
      this.questionGridFilters[AppMetaData.Queries.AcsCodesWithQuestions.Attributes.AcsAreaOfOperationAcsAreaOfOperationId.fieldName] = aosId;
    }
  }

  buttonClick(buttonConfig: DwButtonConfig): Observable<DwModalResult<any>> {
    let result = new DwModalResult<AcsCodeSelectionModalResult>();

    result.cancelled = false;

    switch (buttonConfig.command) {
      case DwButtonCommand.ok:
      case DwButtonCommand.saveAndClose:
      case DwButtonCommand.save:
        //this.generateSections();

        result.data = {
          GenerateCodesOptions: this.options,
        };

        result.closeModal = true;
        break;
      case DwButtonCommand.cancel:
        result.closeModal = true;
        result.cancelled = true;
    }

    return of(result);
  }

  // generateSections() {
  //   this.planOfActionGeneratorSection.generateQuestions(this.planOfActionSection, this.options);

  // }

  setMessage(message: string, severity: DwMessageSeverity = DwMessageSeverity.error) {
    this.message = {
      messageBody: message,
      severity: severity
    };
  }

  clearMessage() {
    this.message = null;
  }

  private buildGridPreferences(useQuestionBank: boolean): Partial<DwMetaDataGridPreferences> {
    const gridPrefs: Partial<DwMetaDataGridPreferences> = {
      gridStyle: {
        gridStyleName: 'Simple',
        gridHeightMode: DwGridHeightMode.fixedHeight,
        gridHeight: '200'
      },

      allowRowSelect: true,
      useCheckboxSelection: true,
      hideAdd: true,
      hideEditButton: false,
      hideDeleteButton: true,
      hideRefresh: true,
      hideExport: true,
      views: [],
    };

    const viewPref: Partial<DwMetaDataGridViewPreference> = {};

  
    if (useQuestionBank) {
      viewPref.metaDataQueryMeaning = AppMetaData.Queries.AcsCodesWithQuestions.QueryMeaning;
    } else {
      viewPref.metaDataQueryMeaning = AppMetaData.Queries.AcsCodesGrid.QueryMeaning
    }

    gridPrefs.views.push(viewPref as any);

    return gridPrefs;
  }
}