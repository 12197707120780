import { Component, OnInit } from '@angular/core';
import { ApplicantRepositoryService } from '../../services/applicant-repository.service';
import { CheckRideOutcomeAllItems, CheckRideRequestEntity, CheckRideRequestSubStatusAllItems, PilotExaminerEntity, PilotExaminerStatusAllItems, StudentApplicantEntity } from '../../../../meta-data/app-meta-data.service';
import { DwTaskItem } from '../../../z-devware/tasks/models/dw-task-item.model';
import { DwContainerOptions, getMomentValue, sortArrayByField } from '@devwareapps/devware-cap';

import * as moment_ from 'moment';
import { CheckrideTask } from '../../models/checkride-task.model';
const moment = moment_;

@Component({
  selector: 'app-applicant-dashboard',
  templateUrl: './applicant-dashboard.component.html',
  styleUrls: ['./applicant-dashboard.component.scss']
})
export class ApplicantDashboardComponent implements OnInit {
  applicant: StudentApplicantEntity;
  applicantName: string;
  tasks: DwTaskItem[] = [];
  nextCheckRequestId: number;

  checkrideTitle = 'Upcoming Checkride';
  checkrideContainerOptions: DwContainerOptions = {
    containerHeaderIcon: 'fa fa-plane',
    containerHeaderBackgroundColor: 'blue',
    useContainerStyling: true,
    showTitle: true
  }
  allCheckrides: CheckRideRequestEntity[];

  constructor(private applicantRepositoryService: ApplicantRepositoryService) { }

  ngOnInit(): void {
    this.loadStats();
  }

  showAll = false;

  loadStats() {
    this.applicantRepositoryService.getCurrentApplicantinfo()
      .subscribe((applicant) => {
        this.applicant = applicant;
        this.applicantName = `${applicant.DwUser?.FirstName} ${applicant.DwUser?.LastName}`;

        this.buildTasks(applicant, this.showAll);
      });
  }


  private buildTasks(applicant: StudentApplicantEntity, showAll: boolean = false) {
    this.tasks = [];

    this.nextCheckRequestId = null;
    if (!applicant.DwUser?.UserPictureMediaId || showAll) {
      this.tasks.push({ iconClass: 'fa fa-portrait dw-blue', taskTitle: 'Add a profile picture', tooltip: 'A profile picture helps your examiner recognize you and better shows off your profile', navigateUrl: '/applicant-profile' });
    }

    if (!applicant.ApplicationDescription || showAll) {
      this.tasks.push({ iconClass: 'fa fa-align-justify dw-blue', taskTitle: 'Add a profile description', tooltip: 'A description tells more about you to potential examiners', navigateUrl: '/applicant-profile' });
    }

    this.allCheckrides = sortArrayByField(applicant.CheckRideRequest || [], 'ScheduledDateTime', null, true);

    let checkrideTaskInfo: CheckrideTask = {
      task: null,
      showRebookCheckride: false,
      showScheduleCheckride: true,
      checkrideRequest: null
    }

    if (this.allCheckrides.length > 0) {
      const upcomingCheckrides = this.allCheckrides.filter(this.isUpcomingCheckride);

      if (upcomingCheckrides.length > 0) {
        this.nextCheckRequestId = upcomingCheckrides[0]?.CheckRideRequestId;

        for (let checkride of upcomingCheckrides) {
          const checkrideTaskInfo = this.buildCheckrideTask(checkride);

          if (checkrideTaskInfo.task) {
            this.tasks.push(checkrideTaskInfo.task);
          }
        }
        this.setCheckrideTitle();
        return;
      }

      checkrideTaskInfo = this.buildCheckrideTask(this.allCheckrides[0]);

      this.nextCheckRequestId = checkrideTaskInfo.checkrideRequest.CheckRideRequestId;

      if (checkrideTaskInfo?.task) {
        this.tasks.push(checkrideTaskInfo.task);
      }

      this.setCheckrideTitle();

      if (this.tasks.length == 0) {
        this.tasks.push({ iconClass: 'fa fa-check dw-green', taskTitle: `No current tasks`, tooltip: 'You currently have no pending tasks or upcoming checkrides', navigateUrl: '' });
      }

    }

    if (checkrideTaskInfo?.showRebookCheckride) {
      let title = 'Rebook a Checkride';

      this.tasks.push({ iconClass: 'fa fa-plane dw-orange', taskTitle: title, tooltip: title, navigateUrl: `/checkrides/add?returnHome=true&rebookCheckrideId=${checkrideTaskInfo.checkrideRequest?.CheckRideRequestId}` });
    }

    if (checkrideTaskInfo?.showScheduleCheckride) {
      let title = 'Book a Checkride';

      if (applicant.ReferredPilotExaminerIdDisplay) {
        title += ` with ${applicant.ReferredPilotExaminerIdDisplay}`;
      }

      this.tasks.push({ iconClass: 'fa fa-plane dw-green', taskTitle: title, tooltip: title, navigateUrl: '/checkrides/add?returnHome=true' });
    }

  }

  private setCheckrideTitle() {

    const upcomingCheckride = this.allCheckrides.find(cr => cr.CheckRideRequestId == this.nextCheckRequestId);
    if (!upcomingCheckride) {
      return;
    }

    switch (upcomingCheckride.CheckRideRequestSubStatusId) {
      case CheckRideRequestSubStatusAllItems.Completed:
        this.checkrideTitle = 'Completed Checkride';
        return;
        
      case CheckRideRequestSubStatusAllItems.PendingOutcome:
        this.checkrideTitle = 'Checkride Pending Outcome';
        return;
        
      case CheckRideRequestSubStatusAllItems.AcceptedPendingPayment:
      case CheckRideRequestSubStatusAllItems.PendingPayment:
        this.checkrideTitle = 'Checkride - Requires Payment';
        return;
      
        case CheckRideRequestSubStatusAllItems.Rejected:
        this.checkrideTitle = 'Checkride Rejected';
        return;
      
    }

    if (this.isForToday(upcomingCheckride)) {
      this.checkrideTitle = 'Today\'s Checkride';
      return;
    }

    if (this.isBeforeToday(upcomingCheckride)) {
      this.checkrideTitle = 'Last Checkride';
    }

    // switch (upcomingCheckride.CheckRideRequestSubStatusId) {
    //   case CheckRideRequestSubStatusAllItems.Completed:
    //     this.checkrideTitle = 'Completed Checkride';
    //     break;
    //   case CheckRideRequestSubStatusAllItems.PendingOutcome:
    //     this.checkrideTitle = 'Checkride Pending Outcome';
    //     break;
    //   case CheckRideRequestSubStatusAllItems.AcceptedPendingPayment:
    //   case CheckRideRequestSubStatusAllItems.PendingPayment:
    //     this.checkrideTitle = 'Checkride - Requires Payment';
    //     break;

    // }

    // if (upcomingCheckride) {
    //   if (this.isForToday(upcomingCheckride)) {
    //     this.checkrideTitle = 'Today\'s Checkride';
    //   }
    //   if (this.isBeforeToday(upcomingCheckride)) {
    //     switch (upcomingCheckride.CheckRideRequestSubStatusId) {
    //       case CheckRideRequestSubStatusAllItems.Completed:
    //         this.checkrideTitle = 'Completed Checkride';
    //         break;
    //       case CheckRideRequestSubStatusAllItems.PendingOutcome:
    //         this.checkrideTitle = 'Checkride Pending Outcome';
    //         break;
    //       case CheckRideRequestSubStatusAllItems.AcceptedPendingPayment:
    //       case CheckRideRequestSubStatusAllItems.PendingPayment:
    //         this.checkrideTitle = 'Checkride - Requires Payment';
    //         break;

    //     }
    //   }

  }


  private buildCheckrideTask(checkrideRequest: CheckRideRequestEntity): CheckrideTask {
    const task: DwTaskItem = {
      iconClass: 'fa fa-plane',
      taskTitle: '',
      navigateUrl: `/checkrides/edit/${checkrideRequest.CheckRideRequestId}?returnHome=true`
    }

    const result: CheckrideTask = {
      task: task,
      showScheduleCheckride: false,
      showRebookCheckride: false,
      checkrideRequest: checkrideRequest
    }

    switch (checkrideRequest.CheckRideRequestSubStatusId) {
      case CheckRideRequestSubStatusAllItems.PendingApproval:
        task.taskTitle = 'Checkride Pending Approval';
        task.tooltip = 'Your checride has been submitted to the DPE and is awaiting approval';
        task.iconClass += ' dw-orange';
        break;

      case CheckRideRequestSubStatusAllItems.Accepted:
      case CheckRideRequestSubStatusAllItems.AllDetailsComplete:
        task.taskTitle = 'Checkride Booked';
        task.iconClass += ' dw-green';

        break;
      case CheckRideRequestSubStatusAllItems.Rejected:
        task.taskTitle = 'Checkride has been rejected';
        task.tooltip = checkrideRequest.RejectedNotes;
        task.iconClass += ' dw-red';

        break;
      case CheckRideRequestSubStatusAllItems.NewDateProposedByExaminer:
        task.taskTitle = 'Checkride - New Date Proposed - Need Acceptance';
        task.iconClass += ' dw-orange';

        break;
      case CheckRideRequestSubStatusAllItems.RequiresAdditionalInfo:
        task.taskTitle = 'Checkride - Requires Additional Info';
        task.iconClass += ' dw-orange';

        break;

      case CheckRideRequestSubStatusAllItems.PendingOutcome:
        task.taskTitle = 'Checkride Complete - Pending Outcome';
        task.iconClass += ' dw-orange';

        break;

      case CheckRideRequestSubStatusAllItems.PendingPayment:
        task.taskTitle = 'Checkride - Pending Payment';
        task.iconClass += ' dw-orange';
        task.tooltip = 'Please add your payment information to complete your Checkride booking.'

        break;

      case CheckRideRequestSubStatusAllItems.AcceptedPendingPayment:
        task.taskTitle = 'Checkride - Pending Payment';
        task.iconClass += ' dw-orange';
        task.tooltip = 'Please add your payment information to complete your Checkride booking.'

        break;

      case CheckRideRequestSubStatusAllItems.Completed:
        task.taskTitle = `Checkride Complete`;
        task.iconClass += ' dw-green';


        switch (checkrideRequest.CheckRideResult?.CheckRideOutcomeId) {
          case CheckRideOutcomeAllItems.Disapproved:
            task.taskTitle += ` - Disapproved`;
            task.iconClass += ' dw-red';

            if (checkrideRequest.CheckRideResult?.AllowRetest) {
              task.tooltip = 'You may retest for this checkride';
              result.showScheduleCheckride = false;
              result.showRebookCheckride = true;

            } else {
              task.tooltip = 'You are not eligible to retest for this checkride with this examiner';
            }
            break;

          case CheckRideOutcomeAllItems.CertificateIssued:
            task.taskTitle += ` - Certificate Issued`;
            task.iconClass += ' dw-green';

            result.showScheduleCheckride = true;
            break;

          case CheckRideOutcomeAllItems.Discontinued:
            task.taskTitle += ` - Discontinued`;
            task.iconClass += ' dw-orange';

            if (checkrideRequest.CheckRideResult?.AllowRetest) {
              task.tooltip = 'You may retest for this checkride';

              result.showScheduleCheckride = false;
              result.showRebookCheckride = true;
            } else {
              task.tooltip = 'You are not eligible to retest for this checkride with this examiner';
            }

            break;
          default:
            task.taskTitle += ` - Pending Outcome`;
            task.iconClass += ' dw-orange';
        }

        break;

      case CheckRideRequestSubStatusAllItems.Rejected:
        task.taskTitle = `Rejected Checkride`;
        task.iconClass += ' dw-red';

        result.showScheduleCheckride = true;
        break;

      case CheckRideRequestSubStatusAllItems.Cancelled:
        task.taskTitle = `Checkride Cancelled`;
        task.iconClass += ' dw-blue';

        result.showScheduleCheckride = true;
        break;

      case CheckRideRequestSubStatusAllItems.Deleted:
        result.showScheduleCheckride = true;

        return null;
      case CheckRideRequestSubStatusAllItems.Draft:
        task.taskTitle = `Checkride - Draft`;
        task.tooltip = 'Your checkride is in draft mode and has not been submited to an Examiner'
        task.iconClass += ' dw-orange';
        break;
    }

    return result;
  }

  isForThisWeek(checkride: CheckRideRequestEntity): boolean {
    const scheduleDateTime = getMomentValue(checkride.ScheduledDateTime, true, true);
    const now = moment().add(1, 'days');
    const sat = moment().weekday(6); // 6 is saturday

    if (scheduleDateTime.isSameOrAfter(now, 'day') && scheduleDateTime.isSameOrBefore(sat, 'day')) {
      return true;
    }

    return false;
  }


  private isScheduledCheckride(checkride: CheckRideRequestEntity): boolean {

    switch (checkride.CheckRideRequestSubStatusId) {
      case CheckRideRequestSubStatusAllItems.Cancelled:
      case CheckRideRequestSubStatusAllItems.Completed:
      case CheckRideRequestSubStatusAllItems.Rejected:
      case CheckRideRequestSubStatusAllItems.Deleted:
      case CheckRideRequestSubStatusAllItems.Draft:

        return false;
    }

    return true;
  }


  private isUpcomingCheckride(checkride: CheckRideRequestEntity): boolean {

    switch (checkride.CheckRideRequestSubStatusId) {
      case CheckRideRequestSubStatusAllItems.Accepted:
      case CheckRideRequestSubStatusAllItems.AllDetailsComplete:
      case CheckRideRequestSubStatusAllItems.NewDateProposedByExaminer:
      case CheckRideRequestSubStatusAllItems.PendingPayment:
      case CheckRideRequestSubStatusAllItems.Draft:

        return true;
    }

    return false;
  }


  isForToday(checkride: CheckRideRequestEntity): boolean {
    const scheduleDateTime = getMomentValue(checkride.ScheduledDateTime, true, true);
    const now = moment();

    if (scheduleDateTime.isSame(now, 'day')) {
      return true;
    }

    return false;
  }


  isBeforeToday(checkride: CheckRideRequestEntity): boolean {
    const scheduleDateTime = getMomentValue(checkride.ScheduledDateTime, true, true);
    const now = moment();

    if (scheduleDateTime.isBefore(now, 'day')) {
      return true;
    }

    return false;
  }

}
