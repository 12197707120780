import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FinancialDashboardConfig, FinancialDashboardGroup } from '../../models/financial-dashboard-config.model';
import { FinancialsRepositoryService } from '../../services/financials-repository.service';
import { DwComponent, DwComponentType, DwContainerOptions, DwControlType, DwQuery, deepCopy, fieldSelectorType } from '@devwareapps/devware-cap';
import { ChartFilters } from '../../models/chart-filters.model';
import { DwChartPreferences } from '../../../z-devware/visualizations/models/dw-chart-preferences.model';
import { Observable, combineLatest, of } from 'rxjs';
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';
import { DwChartDataSelectedEvent } from '../../../z-devware/visualizations/models/dw-chart-data-selected-event.model';
import { CheckrideRequestAggregateBaseQueryMetaDataAttributes } from '../../../../meta-data/app-meta-data.service';
import { map } from 'rxjs/operators';
import { ChartDataRequest } from '../../models/chart-data-request.model';
import { ChartDataQueryResult } from '../../models/chart-data-result.model';
import { DwTileConfig } from '../../../z-devware/visualizations/models/dw-tile-config.model';
import { DwChartColors, DwChartColors2 } from '../../../z-devware/visualizations/models/dw-chart-colors.model';
import { DataQueryResult } from '../../models/data-query-result.model';

@Component({
  selector: 'app-financial-dashboard',
  templateUrl: './financial-dashboard.component.html',
  styleUrls: ['./financial-dashboard.component.scss']
})
export class FinancialDashboardComponent implements OnInit, OnChanges {
  @Input() financialDashboardConfig: FinancialDashboardConfig;
  textboxControlType: DwControlType = DwControlType.Textbox;

  chartFilters: ChartFilters = {};
  previousChartFilters: ChartFilters = {};
  currentDashboardGroup: FinancialDashboardGroup;
  currentChartResults: ChartDataQueryResult[];
  currentGroup: string;

  @Output() currentGroupChanged = new EventEmitter<string>();

  allChartResults: {
    chartResults: ChartDataQueryResult[],
    tileConfigs: DwTileConfig[]
  }[] = [];

  selectedInfo: { event: DwChartDataSelectedEvent<any>; chartResult: ChartDataQueryResult; label: string; };
  currentMonth: number;

  showGrid: boolean;
  gridFilters?: any;

  gridContainerOptions: DwContainerOptions = {
    showTitle: true,
    useContainerStyling: true,
    containerHeaderBackgroundColor: 'blue'
  }
  summaryData: DataQueryResult;
  tileConfigs: DwTileConfig[];
  currentYear: number;

  constructor(private financialRepositoryService: FinancialsRepositoryService, private dateTimeUtilService: DateTimeUtilService) { }
 

  ngOnInit(): void {
    this.initDashboard();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.financialDashboardConfig?.firstChange==false) {
      
      this.currentDashboardGroup = this.financialDashboardConfig.groups.find(g => g.groupName === this.currentGroup);

      this.loadFinancialSummary(true);

      this.setupDashboard(true);
    }
  }

  private initDashboard() {
    
    this.currentMonth = this.currentMonth || this.dateTimeUtilService.getCurrentMonth();
    this.currentYear = this.currentYear || this.dateTimeUtilService.getCurrentYear();
    
    this.currentDashboardGroup = this.currentDashboardGroup || this.financialDashboardConfig.groups[0];

    // Setup any static filters
    if (this.financialDashboardConfig.staticFilter) {
      this.chartFilters = this.financialDashboardConfig.staticFilter;
    }

    // Init year on first load
    this.chartFilters.year = this.chartFilters.year || this.currentYear;

    this.financialRepositoryService.getCloseGridObservable().subscribe(() => {
      this.toggleShowGrid();
    });

    this.loadFinancialSummary();
       
    this.setupDashboard();
  }

  dataSelected(event: DwChartDataSelectedEvent, chartResult: ChartDataQueryResult) {
    const fields = this.financialRepositoryService.getFinancialFields();

    
    this.selectedInfo = {
      event: event,
      chartResult: chartResult,
      label: `${chartResult.chartDataRequest.title} - ${event.category}`,
    };
    
    this.gridFilters = this.financialRepositoryService.buildGridFilters(event, chartResult, this.chartFilters)
    
    const monthValue = event.data[fields.month];
    const yearValue = event.data[fields.year];
    if (monthValue) {
      this.currentMonth = event.data[fields.month];
      this.currentYear = event.data[fields.year];
    }

    if (event?.browserEvent?.ctrlKey) {
      this.toggleShowGrid();
    }

    this.setupTiles();
  }

  onFilterChange() {

    if (this.chartFilters?.year) {
      this.currentYear=this.chartFilters.year;
    }

    this.loadFinancialSummary();

    this.loadDashboardGroup();
  }

  toggleShowGrid() {
    this.showGrid = !this.showGrid;
  }

  setGroup(groupName: string) {
    this.currentDashboardGroup = this.financialDashboardConfig.groups.find(g => g.groupName === groupName);

    this.currentGroupChanged.emit(groupName);

    this.setupDashboard();

    this.showGrid = false;
  }

  
  private loadFinancialSummary(forceRefresh: boolean = false) {
    if (!forceRefresh && this.summaryData && this.previousChartFilters?.pilotExaminerId == this.chartFilters?.pilotExaminerId) {
      return;
    }

    this.previousChartFilters = deepCopy(this.chartFilters);

    this.financialRepositoryService.getFinancialSummary(this.chartFilters, this.currentDashboardGroup?.summaryAdditionalFilterOptions)
    .subscribe(summaryData => {
      this.summaryData = summaryData;

      this.setupTiles();
    });
  }

  private setupDashboard(forceRefresh: boolean = false) {
    this.currentGroup = this.currentDashboardGroup.groupName;
    this.currentGroupChanged.emit(this.currentGroup);

    const index = this.financialDashboardConfig.groups.findIndex(g => g.groupName === this.currentGroup);

    const results = this.allChartResults[index];

    if (results && !forceRefresh) {
      this.currentChartResults = results.chartResults;
      this.tileConfigs = results.tileConfigs;

      this.setupTiles();
      return;
    }

    // if (this.currentDashboardGroup.filterOptions?.showYear) {
    //   this.chartFilters.year = this.chartFilters.year || this.dateTimeUtilService.getCurrentYear();
    // }

    this.loadDashboardGroup();
  }

  private loadDashboardGroup() {
    this.financialRepositoryService.setupCharts(this.currentDashboardGroup.charts, this.chartFilters)
      .subscribe((chartDataQueryResults: ChartDataQueryResult[]) => {

        const index = this.financialDashboardConfig.groups.findIndex(g => g.groupName === this.currentGroup);
        // store results
        
        this.currentChartResults = chartDataQueryResults;
        
        var tiles = this.setupTiles();
        this.allChartResults[index] = { chartResults: chartDataQueryResults, tileConfigs: tiles };
      });
  }

  private setupTiles() {
    this.tileConfigs = this.financialRepositoryService.setupTiles(this.currentDashboardGroup.tiles, this.currentChartResults, this.summaryData?.data, {year: this.currentYear, month: this.currentMonth });

    return this.tileConfigs;
  }

  colors = DwChartColors;
  colors2 = DwChartColors2;
}
