import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { DwButtonCommand, DwButtonConfig, DwComponent, DwComponentType, DwExpressionService, DwGridEvent, DwGridEvents, DwGridHeightMode, DwMessage, DwMessageSeverity, DwMetaDataFormApi, DwMetaDataFormStateService, DwMetaDataGridPreferences, DwMetaDataGridViewPreference, DwMetaDataService, DwMetaDataServiceToken, DwModalConfig, DwModalHandler, DwModalResult, DwSectionBaseComponent, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService } from '@devwareapps/devware-cap';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { map } from 'underscore';
import { AppMetaDataLookups, AppMetaDataItemNames, PlanOfActionEntity, PilotExaminerEntity, AcsTestEntity } from '../../../../meta-data/app-meta-data.service';
import { PlanOfActionRepositoryService } from '../../../plan-of-action/services/plan-of-action-repository.service';
import { GenerateSectionsOptions } from '../../../plan-of-action/models/generate-sections-options.model';
import { PlanOfActionGeneratorService } from '../../../plan-of-action/services/plan-of-action-generator.service';
import { AcsCodeStorage, AcsCodesStorageItem } from '../../../plan-of-action/models/acs-code-items.model';


@DwComponent({
  key: 'generate-sections',
  name: 'Generate Sections',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.PlanOfAction,
  isGlobal: false
})
@Component({
  selector: 'app-select-sections',
  templateUrl: './select-sections.component.html',
  styleUrls: ['./select-sections.component.scss']
})
export class SelectSectionsComponent extends DwSectionBaseComponent {
  formApi: DwMetaDataFormApi;
  @Input() planOfAction: PlanOfActionEntity;

  message: DwMessage;

  @Input() options: GenerateSectionsOptions;
  @Output() optionsChange = new EventEmitter<GenerateSectionsOptions>();

  acsTest: AcsTestEntity;

  allAcsCodes: AcsCodeStorage;

  aosItem = AppMetaDataItemNames.AcsAreaOfOperation;
  taskItem = AppMetaDataItemNames.AcsAreaOfOperationTask;
  filters: any = {};

  aosGridPreferences: Partial<DwMetaDataGridPreferences> = {};
  taskGridPreferences: Partial<DwMetaDataGridPreferences> = {};
  setupComplete: boolean = false;

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    @Optional() private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private planOfActionRepositoryService: PlanOfActionRepositoryService,
    private planOfActionGeneratorSection: PlanOfActionGeneratorService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);

    this.formApi = this.dwMetaDataFormStateService?.state?.formApi;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.setupDataWatch();

    if (this.planOfAction) {
      this.setupData();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.planOfAction?.firstChange === false) {
      this.setupData();
    }
  }

  onAosGridEvent(event: DwGridEvent) {
    if (event.eventType as string != 'rowSelectionChange') {
      return;
    }

    this.options.AreaOfOperationIds = event.eventData?.map(row => row.AcsAreaOfOperationId) || [];
    this.onOptionChange();
  }

  onTaskGridEvent(event: DwGridEvent) {
    if (event.eventType as string != 'rowSelectionChange') {
      return;
    }

    this.options.TaskIds = event.eventData?.map(row => row.AcsAreaOfOperationTaskId) || [];
    this.onOptionChange();
  }

  onOptionChange() {
    this.optionsChange.emit(this.options);
  }

  setupDataWatch() {
    if (!this.formApi) {
      return;
    }
    this.runAfterFormDataLoaded(() => {
      this.setupData();
    });
  }

  private setupData() {
    if (this.formApi) {
      this.planOfAction = this.formApi.getFormData<PlanOfActionEntity>();
    }

    if (!this.planOfAction) {
      return;
    }

    this.filters.AcsTestId = this.planOfAction.AcsTestId;

    this.aosGridPreferences = this.buildGridPreferences(false);
    this.taskGridPreferences = this.buildGridPreferences(true);

    if (!this.options) {
      this.options = {
        AreaOfOperationIds: [],
        TaskIds: [],
        includeAllSections: true,
        buildTaskSections: false,
        groundPortion: true,
        clearExistingSections: true,
        includeAllAcsCodes: true,
        useQuestionBank: false
      }

      this.optionsChange.emit(this.options);
    }

    this.setupComplete = true;

    this.planOfActionRepositoryService.getAcsTest(this.planOfAction.AcsTestId)
      .subscribe(acsTest => {
        this.acsTest = acsTest;
      });
  }

  buttonClick(buttonConfig: DwButtonConfig): Observable<DwModalResult<any>> {
    let result = new DwModalResult();

    result.cancelled = false;

    switch (buttonConfig.command) {
      case DwButtonCommand.ok:
      case DwButtonCommand.saveAndClose:
      case DwButtonCommand.save:
        this.generateSections();

        result.data = this.planOfAction;
        result.closeModal = true;

      case DwButtonCommand.cancel:
        result.closeModal = true;
        result.cancelled = true;
    }

    return of(result);
  }

  generateSections() {
    this.planOfActionGeneratorSection.generateSections(this.planOfAction, this.options, this.acsTest, this.allAcsCodes);

  }

  setMessage(message: string, severity: DwMessageSeverity = DwMessageSeverity.error) {
    this.message = {
      messageBody: message,
      severity: severity
    };
  }

  clearMessage() {
    this.message = null;
  }

  private buildGridPreferences(taskGrid: boolean): Partial<DwMetaDataGridPreferences> {
    const gridPrefs: Partial<DwMetaDataGridPreferences> = {
      gridStyle: {
        gridStyleName: 'Simple',
        gridHeightMode: DwGridHeightMode.fixedHeight,
        gridHeight: '200'
      },

      allowRowSelect: true,
      useCheckboxSelection: true,
      hideAdd: true,
      hideEditButton: false,
      hideDeleteButton: true,
      hideRefresh: true,
      hideExport: true,
      views: [],
    };

    if (taskGrid) {
      const viewPref: Partial<DwMetaDataGridViewPreference> = {
        metaDataQueryMeaning: 'Acs_Area_of_operation_Task-Grid'
      }

      gridPrefs.views.push(viewPref as any);
    }

    return gridPrefs;
  }
}