import { Component, EventEmitter, Inject, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DwComponent, DwComponentType, DwContainerOptions, DwContext, DwExpressionService, DwFormContextMode, DwMessage, DwMessageSeverity, DwMetaDataFormApi, DwMetaDataFormConfig, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwSectionBasePreference, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService, formatDateTimeFromLocal } from '@devwareapps/devware-cap';
import { Observable, Subscription } from 'rxjs';
import { ExaminerRepositoryService } from '../../services/examiner-repository.service';
import { AppMetaData, AppMetaDataItemNames, ApplicantPlaceholderEntity, CheckRideApplicantTypeAllItems, CheckRideOutcomeAllItems, CheckRideRequestEntity, CheckRideRequestSubStatusAllItems, CheckRideResultEntity, CheckRideScenarioEntity, PilotExaminerLocationEntity, PilotExaminerSearchQueryEntity } from '../../../../meta-data/app-meta-data.service';
import { ExaminerLocationSearchRequest } from '../../models/examiner-location-search-request.model';
import { ExaminerLocationSelection } from '../../models/exminer-location-selection.model';
import { tap } from 'rxjs/operators';
import { CheckrideReferenceInfo } from '../../models/checkride-reschedule-info.model';
import { ExaminerCheckrideVariables } from '../../models/examiner-checkride-variables.model';

@DwComponent({
  key: 'exminer-check-ride-util',
  name: ' Examiner Check Ride Util',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.CheckRideRequest,
  isGlobal: false
})
@Component({
  selector: 'app-examiner-check-ride-util',
  template: `
  `
})
export class ExaminerCheckRideUtilComponent extends DwSectionBaseComponent {
  checkRideRequest: CheckRideRequestEntity;

  initComplete = false;
  rescheduleInfo: CheckrideReferenceInfo;
  checkrideScenarioId: number;
  currentScenario: any;
  checkRideTypeId: number;
  checkrideResultActualDateTime: string;
  formApi: DwMetaDataFormApi;
  applicantDataUpdated: boolean;
  isRebook: boolean;
  testTypeId: number;
  originalCheckRideRequest: any;

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private examinerRepositoryService: ExaminerRepositoryService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);

    this.formApi = this.dwMetaDataFormStateService.state.formApi;
  }

  ngOnInit(): void {
    this.setupDataWatch();
  }

  setupDataWatch() {
    this.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (this.formApi.isDataLoadComplete) {
          this.checkForDataChange();
        }
      });

    if (this.formApi.isDataLoadComplete) {
      this.checkForDataChange();
    }
  }

  private checkForDataChange() {

    const checkRideRequest = this.formApi.getFormData<CheckRideRequestEntity>();

    if (this.checkRideRequest && checkRideRequest && this.checkRideRequest.CheckRideRequestSubStatusId != checkRideRequest.CheckRideRequestSubStatusId) {
      switch (this.checkRideRequest.CheckRideRequestSubStatusId) {
        case CheckRideRequestSubStatusAllItems.NewDateProposedByExaminer:
          this.updateProposedInfo(checkRideRequest);
      }
    }

    if (!this.originalCheckRideRequest) { 
      this.originalCheckRideRequest = checkRideRequest;
    }
    this.checkRideRequest = checkRideRequest;

    this.initCheckride();

    this.checkForScheduleDateTimeChange();

    if (this.checkrideScenarioId !== checkRideRequest.ScheduledCheckRideScenarioId || (this.testTypeId != checkRideRequest.CheckRideTestTypeId && this.testTypeId)) {
      this.updateCheckrideScenario(checkRideRequest.ScheduledCheckRideScenarioId);
    }

    this.checkrideScenarioId = checkRideRequest.ScheduledCheckRideScenarioId;

    if (this.checkRideTypeId !== checkRideRequest.CheckRideTypeId) {
      this.setScenarioFromCheckrideType(checkRideRequest);
    }

    this.checkRideTypeId = checkRideRequest.CheckRideTypeId;

    if (!this.checkRideRequest?.CheckRideResult?.CheckRideDateTime && 
      (this.checkRideRequest?.CheckRideRequestSubStatusId == CheckRideRequestSubStatusAllItems.Completed
      || this.checkRideRequest?.CheckRideRequestSubStatusId == CheckRideRequestSubStatusAllItems.PendingOutcome   
      )) {
      this.updateCheckRideResultActualDateTime();
    }

    if (this.checkRideRequest?.CheckRideRequestSubStatusId == CheckRideRequestSubStatusAllItems.PendingOutcome && this.checkRideRequest?.CheckRideResult?.CheckRideOutcomeId) {
      this.updateCheckRideResultActualDateTime();
    }  

    this.checkrideResultActualDateTime = this.checkRideRequest?.CheckRideResult?.CheckRideDateTime;

    if (this.checkRideRequest?.ApplicantPlaceholderId && this.dwMetaDataFormStateService.state.formContext.contextMode == DwFormContextMode.add) {
      this.setApplicantData();
    }

    this.checkForRebook();
  }

  setApplicantData() {
    if (this.isRebook || this.applicantDataUpdated) {
      return;
    }

    this.applicantDataUpdated = true;

    this.examinerRepositoryService.getApplicantPlaceholder(this.checkRideRequest.ApplicantPlaceholderId)
      .subscribe(applicantPlaceholder => {
        this.updateApplicantData(applicantPlaceholder);
      });
  }

  updateApplicantData(applicantPlaceholder: ApplicantPlaceholderEntity) {
    let checkrideRequest: Partial<CheckRideRequestEntity> = {
      StudentApplicantNameManual: applicantPlaceholder.StudentApplicantNameManual,
      StudentApplicantEmailManual: applicantPlaceholder.StudentApplicantEmailManual,
      StudentApplicantPhoneManual: applicantPlaceholder.StudentApplicantPhoneManual,
      CheckRideGroupId: applicantPlaceholder.CheckRideGroupId,
      CheckRideTypeId: applicantPlaceholder.CheckRideTypeId,
      ScheduledAirportId: applicantPlaceholder.PreferredAirportId,
      PilotCertificateNumber: applicantPlaceholder.PilotCertificateNumber,
      CheckRideTestTypeId: applicantPlaceholder.CheckRideTestTypeId,
      FlightSchoolName: applicantPlaceholder.FlightSchoolName,
      FlightInstructorName: applicantPlaceholder.FlightInstructorName,
      StudentApplicantId: applicantPlaceholder.StudentApplicantId,
      CheckRideApplicantTypeId: CheckRideApplicantTypeAllItems.ManualEntry,
      AuthorityPreCheckRideNumber: applicantPlaceholder.AuthorityPreCheckRideNumber,
      StudentApplicantCitizenshipCountyId: applicantPlaceholder.StudentApplicantCitizenshipCountyId,
      AvaiationTrainingProgramId: applicantPlaceholder.AviationTrainingProgramId,
    };

    // if we have the last checkride, let's just use that and copy all the info
    if (applicantPlaceholder.CheckRideRequest?.length>0) {
      const previousCheckride = applicantPlaceholder.CheckRideRequest[0];

      this.updateCheckrideForRebook(previousCheckride);

      return;
    }

    this.formGroup.patchValue(checkrideRequest);
  }

  private initCheckride() {
    if (this.initComplete) {
      return;
    }

    this.checkrideScenarioId = this.checkRideRequest?.ScheduledCheckRideScenarioId;
    this.checkRideTypeId = this.checkRideRequest?.CheckRideTypeId;
    
    
    this.initComplete = true;
    this.rescheduleInfo = this.examinerRepositoryService.getCheckrideReferenceInfo(this.checkRideRequest.CheckRideRequestId || -1);

    this.checkrideResultActualDateTime = this.checkRideRequest?.CheckRideResult?.CheckRideDateTime;

    
    if (!this.rescheduleInfo) {
      return;
    }

    if (this.rescheduleInfo.proposedStartDateTime) {
      this.handleReschedule();
      return;
    }

    if (this.rescheduleInfo.startDateTime) {
      this.checkRideRequest.ScheduledDateTime = this.rescheduleInfo.startDateTime;

      if (this.rescheduleInfo.durationMinutes) {
        this.checkRideRequest.ScheduledTimeMinutes = this.rescheduleInfo.durationMinutes;
      }

      this.formGroup.patchValue(this.checkRideRequest);
    }
  }

  setScenarioFromCheckrideType(checkRideRequest: CheckRideRequestEntity) {
    const scenarios = this.examinerRepositoryService.getCurrentCheckRideScenarios()
      .subscribe(scenarios => {
        const scenario = scenarios.find(scenario => scenario.IsActive && scenario.CheckRideScenarioTypeMap.find(tm => tm.CheckRideTypeId == checkRideRequest.CheckRideTypeId));

        if (scenario) {
          this.checkrideScenarioId = scenario.CheckRideScenarioId;

          this.updateCheckrideScenarioDefaults(scenario);
        }
      });
  }

  updateCheckrideScenario(checkRideScenarioId: number) {
    this.testTypeId = this.checkRideRequest.CheckRideTestTypeId
    this.examinerRepositoryService.getCurrentCheckRideScenario(checkRideScenarioId)
      .subscribe(scenario => {
        this.currentScenario = scenario;

        if (!scenario) {
          return;
        }

        this.updateCheckrideScenarioDefaults(scenario);
      });
  }

  updateCheckrideScenarioDefaults(checkrideScenario: CheckRideScenarioEntity) {
    const checkrideRequest: Partial<CheckRideRequestEntity> = {
      ScheduledCheckRideScenarioId: checkrideScenario.CheckRideScenarioId,
      //ScheduledTimeMinutes: checkrideScenario.TimeRequiredMinutes,
      ScheduledTimeMinutes:  this.examinerRepositoryService.getCheckrideTimeMinutesFromScenario(this.checkRideRequest.CheckRideTestTypeId, checkrideScenario),
      ScheduledPreTimeMinutes: checkrideScenario.PreTimeMinutes,
      ScheduledPostTimeMinutes: checkrideScenario.PostTimeMinutes,
      ScheduledCheckRideCost: checkrideScenario.CheckRideCost,
      AdditionalTravelCost: checkrideScenario.TravelCost,
      ExaminerBookingCost: checkrideScenario.BookingCost,
      ExaminerToApplicantNotes: checkrideScenario.RequiredDocumentNotes,
    };

    

    setTimeout(() => {
      //this.formGroup.patchValue(checkrideRequest);
      this.formApi.patchFormData(checkrideRequest);
    },50);
  }

  
  updateCheckRideResultActualDateTime() {
    const checkrideResult : Partial<CheckRideResultEntity> = {
      CheckRideDateTime: this.checkRideRequest.ScheduledDateTime,
    };


    
    const checkrideRequest: Partial<CheckRideRequestEntity> = {
      CheckRideResult : checkrideResult as CheckRideResultEntity
    };

    // Only update the status if an outcome has been selected
    if (this.checkRideRequest.CheckRideResult.CheckRideOutcomeId) {
      checkrideRequest.CheckRideRequestSubStatusId = CheckRideRequestSubStatusAllItems.Completed;
    }
    
    setTimeout(() => {
      this.formApi.patchFormData(checkrideRequest);
    },50);
  }

  previousScheduleDateTime: string;

  variables = {
    checkride_DateTime_Conflict: 'Checkride_DateTime_Conflict',
    proposed_DateTime_Conflict: 'Proposed_DateTime_Conflict',
  }

  checkForScheduleDateTimeChange() {
    if (!this.checkRideRequest) {
      return;
    }

    if (this.previousScheduleDateTime != this.checkRideRequest.ScheduledDateTime) {
     // this.checkCalendarAvailability(this.checkRideRequest.ScheduledDateTime, this.variables.checkride_DateTime_Conflict);
    }

    if (!this.rescheduleInfo) {
      return;
    }

    if (this.rescheduleInfo.proposedStartDateTime) {
      return;
    }

    if (this.rescheduleInfo.startDateTime) {
      if (this.checkRideRequest.ScheduledDateTime != this.rescheduleInfo.startDateTime) {
        this.checkRideRequest.ScheduledDateTime = this.rescheduleInfo.startDateTime;
        this.formGroup.patchValue(this.checkRideRequest);
      }
    }
  }

  rebookComplete?: boolean;

  checkForRebook() {
    if (this.rebookComplete || this.dwMetaDataFormStateService.state.formContext.contextMode != DwFormContextMode.add) {
      return;
    }

    // We want to wait until the defaults have been loaded
    if (!this.dwMetaDataFormStateService.state.formContext.defaultsProcessed) {
      return;
    }

    const variables = this.formApi.getVariableContextValue<ExaminerCheckrideVariables>();
    
    if (!variables?.RebookCheckrideId) {
      return;
    }

    this.rebookComplete = true;
    this.isRebook = true;

    
    this.examinerRepositoryService.getRebookCheckrideRequest(variables.RebookCheckrideId)
      .subscribe(checkride => {
        // Wait a half second to make sure the form is loaded
        setTimeout(() => {
          this.updateCheckrideForRebook(checkride);
        });
      });



  }
  
  updateCheckrideForRebook(checkride: CheckRideRequestEntity) {
    if (!checkride) {
      return;
    }
    this.rebookComplete = true;
    
    const updatedCheckride = this.examinerRepositoryService.prepareCheckrideForCopy(checkride);

    let waivePayment = this.examinerRepositoryService.getWaiveCheckridePaymentOnRebook(checkride);

    // Set the preferred pilot examiner to the same as before
    const variables: ExaminerCheckrideVariables = {
      Pilexos_Payment_Waived: waivePayment,
      RebookCheckrideId: checkride.CheckRideRequestId
    };

    this.formApi.patchVariableContext(variables);

    updatedCheckride.PilexosBookingPaymentAdded = waivePayment;
    
    // Clear scheduled fields
    updatedCheckride.ScheduledDateTime = null;
    
    if (checkride.CheckRideResult?.RetestCost) {
      updatedCheckride.ScheduledCheckRideCost = checkride.CheckRideResult?.RetestCost;
    }

    if (!this.checkRideRequest.CheckRideRequestSubStatusId) {
      updatedCheckride.CheckRideRequestSubStatusId = CheckRideRequestSubStatusAllItems.Accepted;
    }

    // Set these fields for it doesn't get overritten by the scenario logic
    this.checkRideTypeId = checkride.CheckRideTypeId;
    this.checkrideScenarioId = checkride.ScheduledCheckRideScenarioId;

    this.formApi.patchFormData(updatedCheckride);
  } 


  private handleReschedule() {
    const scheduleUTCDateTime = formatDateTimeFromLocal(this.rescheduleInfo.proposedStartDateTime, true, true);

    switch (this.checkRideRequest.CheckRideRequestSubStatusId) {
      case CheckRideRequestSubStatusAllItems.PendingApproval:
      case CheckRideRequestSubStatusAllItems.NewDateProposedByExaminer:
      case CheckRideRequestSubStatusAllItems.RequiresAdditionalInfo:
      case CheckRideRequestSubStatusAllItems.RequiresAdditionalInfo:
        break;

      default:
        return;
    };

    const partialCheckRequestRequest: Partial<CheckRideRequestEntity> = {
      ProposedDateTime: scheduleUTCDateTime,
      ProposedAirportId: this.checkRideRequest.ScheduledAirportId,
      ProposedTimeMinutes: this.checkRideRequest.ScheduledTimeMinutes,
      CheckRideRequestSubStatusId: CheckRideRequestSubStatusAllItems.NewDateProposedByExaminer
    };

    this.checkRideRequest.CheckRideRequestSubStatusId = partialCheckRequestRequest.CheckRideRequestSubStatusId;

    // Patch the form data
    this.formApi.patchFormData(partialCheckRequestRequest);
  }

  private updateProposedInfo(checkRideRequest: CheckRideRequestEntity) {

    const partialCheckRequestRequest: Partial<CheckRideRequestEntity> = {
      ProposedDateTime: checkRideRequest.ScheduledDateTime,
      ProposedAirportId: checkRideRequest.ScheduledAirportId,
      ProposedTimeMinutes: checkRideRequest.ScheduledTimeMinutes,
    };

    // Patch the form data
    this.formApi.patchFormData(partialCheckRequestRequest);
  }
}

