import { Component, EventEmitter, OnInit } from '@angular/core';
import { DwButtonCommand, DwButtonConfig, DwComponent, DwComponentType, DwGridHeightMode, DwMessage, DwMessageSeverity, DwMetaDataGridPreferences, DwMetaDataGridViewPreference, DwModalConfig, DwModalHandler, DwModalResult } from '@devwareapps/devware-cap';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { map } from 'underscore';
import { AppMetaDataLookups, AppMetaDataItemNames, PlanOfActionEntity, PilotExaminerEntity, AcsTestEntity } from '../../../../meta-data/app-meta-data.service';
import { PlanOfActionRepositoryService } from '../../../plan-of-action/services/plan-of-action-repository.service';
import { GenerateSectionsOptions } from '../../../plan-of-action/models/generate-sections-options.model';
import { PlanOfActionGeneratorService } from '../../../plan-of-action/services/plan-of-action-generator.service';
import { AcsCodeStorage, AcsCodesStorageItem } from '../../../plan-of-action/models/acs-code-items.model';


@Component({
  selector: 'app-generate-sections-modal',
  templateUrl: './generate-sections-modal.component.html',
  styleUrls: ['./generate-sections-modal.component.scss']
})
export class GenerateSectionsModalComponent  implements OnInit, DwModalHandler {

  modalConfig: DwModalConfig<PlanOfActionEntity>;
  closeModal = new EventEmitter<DwModalResult<PlanOfActionEntity>>();
 
  planOfAction: PlanOfActionEntity;

  message: DwMessage; 
  options: GenerateSectionsOptions;
  acsTest: AcsTestEntity;
  
  allAcsCodes: AcsCodeStorage;

  aosItem = AppMetaDataItemNames.AcsAreaOfOperation;
  taskItem = AppMetaDataItemNames.AcsAreaOfOperationTask;
  filters: any = {};

  aosGridPreferences: Partial<DwMetaDataGridPreferences> = {};
  taskGridPreferences: Partial<DwMetaDataGridPreferences> = {};

  constructor(private planOfActionRespository: PlanOfActionRepositoryService,
    private planOfActionGeneratorSection: PlanOfActionGeneratorService

  ) { }

  ngOnInit(): void {
    this.planOfAction = this.modalConfig.data;

    this.filters.AcsTestId = this.planOfAction.AcsTestId;

    this.aosGridPreferences = this.buildGridPreferences(false);
    this.taskGridPreferences = this.buildGridPreferences(true);

    this.options = {
      AreaOfOperationIds:[],
      TaskIds: [],
      includeAllSections: false,
      buildTaskSections: false,
      groundPortion:true,
      clearExistingSections: false,
      includeAllAcsCodes: false,
      useQuestionBank: false
    }

    this.planOfActionRespository.getAcsCodeStorage(this.planOfAction.AcsTestId)
      .subscribe(allAcsCodes => {
        this.allAcsCodes = allAcsCodes;
      })

    this.planOfActionRespository.getAcsTest(this.planOfAction.AcsTestId)
      .subscribe(acsTest => {
        this.acsTest = acsTest;
      });
  }

  buttonClick(buttonConfig: DwButtonConfig): Observable<DwModalResult<any>> {
    let result = new DwModalResult();

    result.cancelled = false;

    switch (buttonConfig.command) {
      case DwButtonCommand.ok:
      case DwButtonCommand.saveAndClose:
      case DwButtonCommand.save:
        this.generateSections();

        result.data = this.planOfAction;
        result.closeModal = true;

      case DwButtonCommand.cancel:
        result.closeModal = true;
        result.cancelled = true;
    }

    return of(result);
  }

  generateSections() {
     this.planOfActionGeneratorSection.generateSections(this.planOfAction, this.options, this.acsTest, this.allAcsCodes);

  }

  setMessage(message: string, severity: DwMessageSeverity = DwMessageSeverity.error) {
    this.message = {
      messageBody: message,
      severity: severity
    };
  }

  clearMessage() {
    this.message = null;
  }

  private buildGridPreferences(taskGrid: boolean) : Partial<DwMetaDataGridPreferences> {
    const gridPrefs : Partial<DwMetaDataGridPreferences> = {
      gridStyle : {
        gridStyleName: 'Simple',
        gridHeightMode: DwGridHeightMode.fixedHeight,
        gridHeight: '200'
      },
    
      allowRowSelect: true,
      useCheckboxSelection:true,
      hideAdd: true,
      hideEditButton:false,
      hideDeleteButton:true,
      hideRefresh: true,
      hideExport: true,
      views: [],
    };

    if (taskGrid) {
      const viewPref : Partial<DwMetaDataGridViewPreference> = {
        metaDataQueryMeaning: 'Acs_Area_of_operation_Task-Grid'
      }

      gridPrefs.views.push(viewPref as any);
    }

    return gridPrefs;
  }
}


