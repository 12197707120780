<ng-container *ngIf="setupComplete">
    <div dwRow>
        <div dwCol="6">
            <label class="label">Test Portion <i class="fa fa-info-circle"
                title="Indicate if this section is for the ground or flight portion"></i></label>
            <div class="radio-group">

                <label class="radio-container">
                    <input type="radio" name="groundPortion" [value]="true" [(ngModel)]="options.groundPortion" (ngModelChange)="onOptionChange()" />
                    <span class="radio-item"> Ground   </span>
                </label>
                <label class="radio-container">
                    <input type="radio" name="groundPortion" [value]="false" [(ngModel)]="options.groundPortion" (ngModelChange)="onOptionChange()"/>
                    <span class="radio-item"> Flight</span>
                </label>
            </div>
        </div>
        <div dwCol="6">
            <label class="label">Section Level <i class="fa fa-info-circle"
                    title="Determine if sections are created for each Area of Operation or each Area of Operation/Task"></i></label>
            <div class="radio-group">

                <label class="radio-container">
                    <input type="radio" name="sectionLevel" [value]="false" [(ngModel)]="options.buildTaskSections" (ngModelChange)="onOptionChange()" />
                    <span class="radio-item"> Area of Operation   </span>
                </label>
                <label class="radio-container">
                    <input type="radio" name="sectionLevel" [value]="true" [(ngModel)]="options.buildTaskSections" (ngModelChange)="onOptionChange()"/>
                    <span class="radio-item"> Task</span>
                </label>
            </div>
        </div>
    </div>

    <dw-meta-data-grid *ngIf="options.buildTaskSections == false" [item]="aosItem" configKeyPart="selection"
        [filters]="filters" allowConfig="true" [preferences]="aosGridPreferences"
        (gridEvent)="onAosGridEvent($event)"></dw-meta-data-grid>
    <dw-meta-data-grid *ngIf="options.buildTaskSections == true" [item]="taskItem" configKeyPart="selection"
        [filters]="filters" allowConfig="true" [preferences]="taskGridPreferences"
        (gridEvent)="onTaskGridEvent($event)"></dw-meta-data-grid>
</ng-container>