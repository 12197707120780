<div class="consent-container">

    <ng-container *ngIf="calendarReference?.CalendarIsSetup && editMode">
        <div dwRow>
            <div dwCol="12">
                <label class="label">Booking Calendar <i class="fa fa-info-circle"
                        title="Checkride events will be created on this calendar"></i></label>
                <select class="form-control" [(ngModel)]="calendarReference.MainCalendarKey"
                    (change)="setupAdditionalCalendars()">
                    <option *ngFor="let cal of allCalendars" [value]="cal.Id">{{cal.Name}}</option>
                </select>
            </div>
            <div dwCol="12" *ngIf="alternateCalendars?.length > 0">
                <label class="label">Availability Calendars <i class="fa fa-info-circle"
                        title="These calendars are used to determine availability only"></i></label>

                <p-multiSelect [options]="alternateCalendars" [(ngModel)]="calendarConfig.AdditionalCalendars"
                    optionLabel="Name" display="chip" optionValue="Id" display="chip" appendTo="body" baseZIndex="2000"
                    autoZIndex="false" class="multi-edit-auto-expand"></p-multiSelect>
            </div>
        </div> 

        <dw-button-bar *ngIf="!loading">
            <button (click)="saveCalendars()" class="btn btn-success">Save</button>

            <button (click)="removeAccess()" class="btn btn-warning">Remove Access</button>
        </dw-button-bar>
    </ng-container>


    <ng-container *ngIf="calendarReference?.CalendarIsSetup && !editMode">
        <button (click)="gotoEditMode()" class="btn btn-success mt-2" *ngIf="!loading">Update calendar settings</button>
    </ng-container>

    <div *ngIf="calendarReference?.CalendarIsSetup && calendarReference?.CalendarHasErrors && showCalendarError && (allCalendars?.length==0)">
        <div class="dw-red">
            We were unabled to access your calendar at {{calendarLastErrorDateTime}}!  If you continue to have issues, please click the button above to reauthorize access.
        </div>
    </div>
    <ng-container *ngIf="!calendarReference?.CalendarIsSetup">
        <!-- Authorize Pilexos to access your Google Calendar and book checkride events on your behalf. -->
        <div dwRow *ngIf="false">
            <div dwCol="6">
                <label class="label">Manual Code <i class="fa fa-info-circle"
                        title="Checkride events will be created on this calendar"></i></label>

                <a [href]="authUrl" target="_blank">Google Auth url</a>

                <input type="text" [(ngModel)]="manualCode" placeholder="Manual Code" class="form-control" />
            </div>
        </div>

        <br><br>
        <button (click)="getOAuthConsent()" class="btn btn-google" *ngIf="!loading">
            <img src="/assets/google/img/google-calendar-icon.png" class="google-icon" />

            Connect Google Calendar
        </button>

        <br><br>

        <p><a href="https://pilexos.com/privacy-policy" target="_blank" title="See our full privacy policy" class="dw-link">PiExOS Privacy Policy</a></p>

        <div *ngIf="false">
            <a (click)="togglePrivacy()" [hidden]="showPrivacy" class="dw-link mt-4" >How we use your data</a>

            <div [hidden]="!showPrivacy">
                <p>PiExOS uses google calendar access to determine your availability and to book checkride events on your behalf.  We do not share your information with any third parties.</p>

                
                <p><a href="https://pilexos.com/privacy-policy" target="_blank" title="">PiExOS Privacy Policy</a></p>

                
                <p>PiExOS' use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
            </div>
        </div>


    </ng-container>

    <div class="mt-2 mr-2">
        <dw-loading [show]="loading" [text]="loadingMessage"></dw-loading>

        <dw-message [(message)]="message"></dw-message>
    </div>
</div>