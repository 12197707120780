import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateTimeModel, DwComponent, DwComponentType, DwControlType, DwEvent, DwEventService, DwItemAttribute, DwOrmDataChangeEvent, DwOrmDataService, DwQueryMdAttribute, DwQueryMetaData, convertToIsoDateTime, dateTimeAddDays, formatDateTime, formatDateTimeToLocal, getMomentValue } from '@devwareapps/devware-cap';
import { CalendarRepositoryService } from '../../../calendar/services/calendar-repository.service';
import { CalendarOptions } from '../../../calendar/models/calendar-options.model';
import { CalendarWeekDayEvent } from '../../../calendar/models/calendar-weekday-event.model';
import { EventSearchRequestDTO } from '../../../calendar/models/event-search-request.model';
import { CalendarEventDTO, CalendarEventStatus } from '../../../calendar/models/calendar-event.model';
import { CalendarDTO } from '../../../calendar/models/calendar.model';
//import { FullCalendar } from 'primeng/fullcalendar';

//import { OptionsInput } from '@fullcalendar/core';
//import { OptionsInput } from '@fullcalendar/core';

import * as moment_ from 'moment';
const moment = moment_;


import { EventDateTimeDTO } from '../../../calendar/models/event-data-time.model';
import { ExaminerRepositoryService } from '../../services/examiner-repository.service';
import { Subscription, combineLatest, of } from 'rxjs';
import { AppMetaData, AppMetaDataItemNames, CalendarReferenceEntity, CheckRideRequestCalendarQueryEntity, CheckRideRequestEntity, CheckRideRequestSubStatusAllItems } from '../../../../meta-data/app-meta-data.service';
import { CalendarClickEvent, CalendarClickEventType } from '../../../calendar/models/calendar-click-event.model';
import { Router } from '@angular/router';
import { CalendarConfig } from '../../../calendar/models/calendar-config.model';
import { CheckrideReferenceInfo } from '../../models/checkride-reschedule-info.model';
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';

@DwComponent({
  key: 'examiner-booking-calendar',
  name: 'Examiner Booking Calendar',
  componentType: DwComponentType.pageComponent,
  isGlobal: true,
})
@Component({
  selector: 'app-examiner-booking-calendar',
  templateUrl: './examiner-booking-calendar.component.html',
  styleUrls: ['./examiner-booking-calendar.component.scss']
})
export class ExaminerBookingCalendarComponent implements OnInit, OnDestroy {

  @Input() compactView: boolean = false;

  textboxControlType: DwControlType = DwControlType.Textbox;
  allCalendars: CalendarDTO[] = [];
  usedCalendars: CalendarDTO[] = [];
  primaryCalendar: CalendarDTO;

  selectedCheckRide: CheckRideRequestCalendarQueryEntity;
  queryMetaData: DwQueryMetaData;
  calendarReference: CalendarReferenceEntity;
  selectedEvent: CalendarWeekDayEvent;
  calendarConfig: CalendarConfig = {};

  constructor(private calendarRepositoryService: CalendarRepositoryService,
    private examinerRepositoryService: ExaminerRepositoryService,
    private router: Router,
    private dwEventService: DwEventService,
    private cdr: ChangeDetectorRef,
    private dateTimeUtilService: DateTimeUtilService
  ) { }

  options: CalendarOptions;

  events: CalendarWeekDayEvent[] = [];

  eventSearchRequests: EventSearchRequestDTO[] = [];

  subscriptions: Subscription[] = [];

  initComplete = false;

  ngOnInit(): void {
    this.setCalendarOptions();

    this.setupComponent();

    this.setupChangeEvents();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  setupChangeEvents() {
    this.subscriptions.push(
      this.dwEventService.getEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.CheckRideRequest))
        .subscribe((event: DwEvent) => {
          this.handleCheckRideRequestUpdate(event);
        }));
  }

  handleCheckRideRequestUpdate(event: DwEvent<any>) {
    if (!this.initComplete) {
      return;
    }

    const data: DwOrmDataChangeEvent = event.data;

    this.events = [];
    this.eventSearchRequests = [];

    this.loadEventRange(this.options.startDate);
  }

  private setupComponent() {
    const queryMetaDataOb = this.examinerRepositoryService.getCheckRideRequestQueryMetaData();
    const calendarRefOb = this.examinerRepositoryService.getCurrentCalendarRef();

    combineLatest([queryMetaDataOb, calendarRefOb])
      .subscribe(([queryMetaData, calendarRef]) => {

        this.queryMetaData = queryMetaData;
        this.calendarReference = calendarRef;

        this.setupCalendarConfig();

        // If we have the calendar reference is setup, we can setup the calendars
        if (this.calendarReference?.CalendarIsSetup) {
          this.loadAllCalendars(true);

          return;
        }

        this.initComplete = true;

        // Otherwise, we go ahead and load events
        this.loadEventRange(this.options.startDate);
      });
  }

  gotoProfile() {
    this.router.navigate(['settings/profile']);
  }

  gotoCalendar() {
    this.router.navigate(['calendar']);
  }

  private loadAllCalendars(performSearch: boolean = false, clearEvents: boolean = false) {
    this.calendarRepositoryService.getCalendars()
      .subscribe((calendars) => {
        this.allCalendars = calendars;

        this.setupUsedCalendars();

        // initial search
        if (performSearch) {
          this.loadEventRange(this.options.startDate, clearEvents);

          this.initComplete = true;
        }
      });
  }

  updateCalendarReference() {
    // Clear events so they can be reloaded
    if (!this.calendarReference?.CalendarIsSetup) {
      this.usedCalendars = [];
      this.calendarConfig = {};

      // reload events minus the calendar events
      this.loadEventRange(this.options.startDate, true);
      return;
    }

    this.setupCalendarConfig();

    this.loadAllCalendars(true, true);


    this.setupUsedCalendars();
  }

  private setupUsedCalendars() {
    if (!this.allCalendars || !this.calendarConfig || !this.calendarReference?.CalendarIsSetup) {
      return;
    }

    var calendars: CalendarDTO[] = [];

    let main = this.allCalendars.find(c => c.Id == this.calendarReference?.MainCalendarKey);

    if (!main) {
      main = this.allCalendars.find(c => c.IsPrimary);
    }

    if (main) {
      calendars.push(main);
    }

    for (const id of this.calendarConfig.AdditionalCalendars) {
      const calendar = this.allCalendars.find(c => c.Id == id);

      if (calendar) {
        calendars.push(calendar);
      }
    }

    this.usedCalendars = calendars;
  }

  private setupCalendarConfig() {

    if (this.calendarReference?.CalendarConfigJSON) {
      this.calendarConfig = this.calendarReference.CalendarConfigJSON as any;
    }

    if (!this.calendarConfig?.AdditionalCalendars) {
      this.calendarConfig = { AdditionalCalendars: [] };
    }

    this.setupUsedCalendars();
  }

  eventClick(event: CalendarClickEvent) {
    this.selectedEvent = event.eventData;
    this.selectedCheckRide = event.eventData?.data;

    switch (event.eventType) {
      case CalendarClickEventType.moved:
      case CalendarClickEventType.click:
      case CalendarClickEventType.doubleClick:
      case CalendarClickEventType.selected:
        break;

      case CalendarClickEventType.timeRangeSelected:
        this.addNewCheckride(event.start, event.end);
        return;
      default:
        return;
    }
    
    if (!this.selectedCheckRide) {
      return;
    }

    if (event.eventType == CalendarClickEventType.moved) {
      const rescheduleInfo: CheckrideReferenceInfo = {
        proposedStartDateTime: event.eventData.start.toString()
      };

      this.examinerRepositoryService.setCheckrideReferenceInfo(this.selectedCheckRide.CheckRideRequestId, rescheduleInfo);

      // reset the event dates back to the original (if it is updated, we'll reload and move it on the calendar)
      this.setCheckrideEventDates(this.selectedEvent, this.selectedCheckRide);

      this.events = [...this.events];
    }

    this.router.navigate(['calendar/edit', this.selectedCheckRide.CheckRideRequestId]);
  }

  addNewCheckride(start: any, end: any) {

    const startUtcDateTime = this.dateTimeUtilService.convertDateTimeToUtc(start);
    const endUtcDateTime = this.dateTimeUtilService.convertDateTimeToUtc(end);

    const durationMinutes = this.dateTimeUtilService.calcDurationMinutes(startUtcDateTime, endUtcDateTime, 30, 30, 60 * 8);
    const rescheduleInfo: CheckrideReferenceInfo = {
      startDateTime: startUtcDateTime,
      durationMinutes: durationMinutes
    };

    this.examinerRepositoryService.setCheckrideReferenceInfo(-1, rescheduleInfo);

    this.router.navigate(['calendar/add']);
  }


  handleDateChange(date: DateTimeModel) {
    for (const request of this.eventSearchRequests) {
      if (this.isWithinRange(request, date, true)) {
        // we're finished
        return;
      }
    }

    // load dates for month
    this.loadEventRange(date.toString(false));
  }

  isWithinRange(request: EventSearchRequestDTO, date: DateTimeModel | string, checkExactDate: boolean = false): boolean {
    const startDate = getMomentValue(request.StartDateTime, false, false);
    const endDate = getMomentValue(request.EndDateTime, false, false);

    const refDate = date.toString(false);

    if (checkExactDate) {
      const refDateMoment = getMomentValue(refDate, false, false);

      if (startDate.isSameOrBefore(refDateMoment, 'day') && endDate.isSameOrAfter(refDateMoment, 'day')) {
        // We're within range, nothing to do
        return true;
      }

      return false;
    }

    // Compare Sunday throught Saturday since we display events for the entire week
    const monthStart = this.getMonthStart(refDate);
    const monthEnd = this.getMonthEnd(refDate);

    if (startDate.isSameOrBefore(monthStart, 'day') && endDate.isSameOrAfter(monthEnd, 'day')) {
      // We're within range, nothing to do
      return true;
    }

    return false;
  }

  private loadEventRange(refDate: string = null, clearEvents:boolean = false) {

    // Clear search events so the dates will be calculated correctly
    if (clearEvents) {
      this.eventSearchRequests = [];
    }

    let monthStart = this.getMonthStart(refDate);
    let monthEnd = this.getMonthEnd(refDate);

    monthStart = this.adjustMonthStart(monthStart);
    monthEnd = this.adjustMonthEnd(monthEnd);


    const eventSearchRequest: EventSearchRequestDTO = {
      CalendarId: this.calendarReference?.MainCalendarKey,
      AdditionalCalendars: this.calendarConfig?.AdditionalCalendars || [],
      StartDateTime: convertToIsoDateTime(monthStart.format('MM/DD/YYYY'), false),
      EndDateTime: convertToIsoDateTime(monthEnd.format('MM/DD/YYYY'), false),
    };

    this.eventSearchRequests.push(eventSearchRequest);

    let calendarItemsOb = of([]);
    const checkRidesOb = this.examinerRepositoryService.getCheckRidesRequests(this.queryMetaData, eventSearchRequest.StartDateTime, eventSearchRequest.EndDateTime);

    if (this.calendarReference?.CalendarIsSetup) {
      const finalEventSearchRequest : EventSearchRequestDTO = {...eventSearchRequest,
          EndDateTime: convertToIsoDateTime(monthEnd.add(1, 'days').format('MM/DD/YYYY'), false)
      };

      calendarItemsOb = this.calendarRepositoryService.getEvents(finalEventSearchRequest);
    }

    combineLatest([calendarItemsOb, checkRidesOb])
      .subscribe(([calendarItems, checkRides]) => {
        const convertedEvents = this.convertEvents(calendarItems);

        this.addCheckRidesEvents(checkRides, convertedEvents);

        if (clearEvents) {
          this.events = [];
        }

        // Append events to the existing events
        this.events = [...this.events, ...convertedEvents];

        this.cdr.detectChanges();
      });
  }

  private getMonthStart(refDate: string): moment_.Moment {
    return getMomentValue(refDate, false, false).startOf('month').startOf('week');
  }

  private getMonthEnd(refDate: string): moment_.Moment {
    return getMomentValue(refDate, false, false).startOf('month').startOf('week').add(5, 'weeks').endOf('week');
  }

  private adjustMonthStart(refDate: moment_.Moment): moment_.Moment {
    const searchRequest = this.eventSearchRequests.find(r => this.isWithinRange(r, refDate.toISOString(), true));

    if (!searchRequest) {
      return refDate;
    }

    // use end date plus 1 day of search request
    const endDate = getMomentValue(searchRequest.EndDateTime, false, false).add(1, 'days');;

    return endDate;
  }

  private adjustMonthEnd(refDate: moment_.Moment): moment_.Moment {
    const searchRequest = this.eventSearchRequests.find(r => this.isWithinRange(r, refDate.toISOString(), true));

    if (!searchRequest) {
      return refDate;
    }

    // use start date minus one day of search request
    const endDate = getMomentValue(searchRequest.StartDateTime, false, false).add(-1, 'days');

    return endDate;
  }

  addCheckRidesEvents(checkRides: CheckRideRequestCalendarQueryEntity[], convertedEvents: CalendarWeekDayEvent[]) {
    checkRides.forEach(cr => {

      let existingCalendarEvent: CalendarWeekDayEvent;
      if (cr.CalendarEventReferenceKey) {
        existingCalendarEvent = convertedEvents.find(e => e.id == cr.CalendarEventReferenceKey);
      }

      const event = this.convertCheckRideEvent(cr, existingCalendarEvent);

      // only add if there isn't a calendar event already
      if (!existingCalendarEvent) {
        convertedEvents.push(event);
      }

    });
  }

  convertCheckRideEvent(cr: CheckRideRequestCalendarQueryEntity, existingCalendarEvent: CalendarWeekDayEvent): CalendarWeekDayEvent {
    //const start = convertToIsoDateTime(formatDateTimeToLocal(cr.ScheduledDateTime, true, true), true);

    const checkRideColor = this.getCheckRideColor(cr)

    const eventText = this.buildCheckrideEventText(cr);

    const event: CalendarWeekDayEvent = existingCalendarEvent || {
      id: `checkRideId:${cr.CheckRideRequestId}`,
      text: eventText, // `Checkride: ${cr.ApplicantNameApplicantFinalName || 'Not Set'}\nStatus: ${cr.CheckRideRequestSubStatusIdDisplay}`,
      // start: start,
      // end: this.computeEndDateTime(start, cr.ScheduledTimeMinutes),
      barColor: checkRideColor,
    } as any;

    event.text = eventText;

    this.setCheckrideEventDates(event, cr);

    event.data = cr;

    event.backColor = checkRideColor;
    event.fontColor = '#ffffff';
    event.moveDisabled = false;

    event.bubbleHtml = this.buildBubble(cr);

    return event;
  }

  private buildCheckrideEventText(cr: CheckRideRequestCalendarQueryEntity): string {
    var parts = this.buildCheckrideDisplayParts(cr, false);

    parts = ['Checkride'].concat(parts);

    return parts.join('\n');
  }

  
  private buildCheckrideDisplayParts(cr: CheckRideRequestCalendarQueryEntity, includeFullDetails:boolean): string[] {
    var parts = [];

    const fields = AppMetaData.Queries.CheckRideRequestCalendar.Attributes;

    //parts.push(`Checkride for ${cr.ApplicantNameApplicantFinalName || 'Not Set'}`);

    this.addField(parts, cr, fields.ApplicantNameApplicantFinalName);

    const inProposedStatus = cr.CheckRideRequestSubStatusId == CheckRideRequestSubStatusAllItems.NewDateProposedByExaminer;

    this.addField(parts, cr, fields.CheckRideRequestSubStatusId);

    if (inProposedStatus) {
      this.addField(parts, cr, fields.ProposedAirportId, 'Proposed Airport', fields.ScheduledAirportId);
    } else {
      this.addField(parts, cr, fields.ScheduledAirportId);
    }

    if (includeFullDetails) {
      this.addField(parts, cr, fields.CheckRideGroupId);
      this.addField(parts, cr, fields.CheckRideTypeId);
      this.addField(parts, cr, fields.CheckRideTestTypeId);
      this.addField(parts, cr, fields.AuthorityPreCheckRideNumber);
    }
    
    this.addField(parts, cr, fields.AuthorityApprovalCode, null, null, 'Not Set', !includeFullDetails);

    this.addField(parts, cr, fields.ApplicantNameApplicantFinalPhone);

    return parts;
  }

  setCheckrideEventDates(event: CalendarWeekDayEvent, checkride: CheckRideRequestCalendarQueryEntity) {
    let startDate = checkride.ScheduledDateTime;
    
    if (checkride.CheckRideRequestSubStatusId == CheckRideRequestSubStatusAllItems.NewDateProposedByExaminer) {
      startDate = checkride.ProposedDateTime;
    }
    
    const start = this.convertDateTime(startDate);

    event.start = start;
    event.end = this.computeEndDateTime(start, checkride.ScheduledTimeMinutes);
  }


  private buildBubble(cr: CheckRideRequestCalendarQueryEntity): string {

    var parts = this.buildCheckrideDisplayParts(cr, true);
    
    const rows: string[] = [];

    for (const fieldData of parts) {
      const row = `<div class="bubble-content">${fieldData}</div>`
      
      rows.push(row)
    }

    return `<div class="bubble"><div class="bubble-title">Checkride</div>${rows.join('')}</div>`;
  }

  // private buildBubbleOld(cr: CheckRideRequestCalendarQueryEntity): string {

  //   const fields: string[] = [];

  //   // fields.push(AppMetaData.CheckRideRequest.Attributes.StudentApplicantId.fieldName);
  //   // fields.push(AppMetaData.CheckRideRequest.Attributes.CheckRideRequestSubStatusId.fieldName);
  //   // fields.push(AppMetaData.CheckRideRequest.Attributes.ScheduledAirportId.fieldName);

  //   fields.push(AppMetaData.Queries.CheckRideRequestCalendar.Attributes.ApplicantNameApplicantFinalName.fieldName);
  //   fields.push(AppMetaData.Queries.CheckRideRequestCalendar.Attributes.CheckRideRequestSubStatusId.fieldName);
  //   fields.push(AppMetaData.Queries.CheckRideRequestCalendar.Attributes.ScheduledAirportId.fieldName);
  //   fields.push(AppMetaData.Queries.CheckRideRequestCalendar.Attributes.AuthorityApprovalCode.fieldName);
    
  //   const rows: string[] = [];
  //   for (const field of fields) {
  //     const row = this.buildDataRow(cr, field);
  //     rows.push(row)
  //   }

  //   return `<div class="bubble"><div class="bubble-title">Checkride</div>${rows.join('')}</div>`;
  // }

  private buildDataRow(cr: CheckRideRequestCalendarQueryEntity, field: string): string {
    const attr = this.queryMetaData.Attributes.find(a => a.AttributeName == field);

    if (attr) {
      return `<div class="bubble-content">${attr.AttributeDisplay}: ${cr[attr.DisplayAttributeName]}</div>`;
    }
  }

  private addField(fields:string[], cr: CheckRideRequestCalendarQueryEntity, field: string | DwItemAttribute, captionOverride?: string, altField?: string | DwItemAttribute, noDataText: string = 'Not Set', hideIfNoData: boolean = false) {
    let fieldInfo = this.getFieldData(cr, field, noDataText);
    let altFieldInfo = this.getFieldData(cr, altField, noDataText);

    if (!fieldInfo?.data && altFieldInfo) {
      fieldInfo = altFieldInfo;
    }

    if (fieldInfo) {

      if (!fieldInfo.data &&  hideIfNoData) {
        return;
      }
      const text = `${ captionOverride || fieldInfo.attr.AttributeDisplay}: ${fieldInfo.displayData}`;
      
      fields.push(text);
    }
  }


  //   if (field instanceof DwItemAttribute) {
  //     field = field.fieldName;
  //   }

  //   if (altField instanceof DwItemAttribute) {
  //     altField = altField.fieldName;
  //   }

  //   const attr = this.queryMetaData.Attributes.find(a => a.AttributeName == field);
  //   const altAttr = this.queryMetaData.Attributes.find(a => a.AttributeName == altField);

  //   if (attr) {
  //     const data = cr[attr.DisplayAttributeName] || 'Not Set';

  //     const text = `${ captionOverride || attr.AttributeDisplay}: ${data}`;
  //     fields.push(text);
  //   }
  // }

  private getFieldData(cr: CheckRideRequestCalendarQueryEntity, field: string | DwItemAttribute, noDataText: string = 'Not Set'): { attr: DwQueryMdAttribute, data: string, displayData: string } {
    if (field instanceof DwItemAttribute) {
      field = field.fieldName;
    }

    const attr = this.queryMetaData.Attributes.find(a => a.AttributeName == field);

    if (attr) {

      let data = cr[attr.DisplayAttributeName];

      if (data?.length ==0) {
        data = null;
      }
      return {
        attr: attr,
        data: data,
        displayData: data || noDataText,
        
      };
    }
    
    return null;
  }

  requiresActionEventColor = '#ffa500';
  noActionRequiredEventColor = 'green';

  getCheckRideColor(cr: CheckRideRequestCalendarQueryEntity) {
    switch (cr.CheckRideRequestSubStatusId) {
      case CheckRideRequestSubStatusAllItems.PendingApproval:
      case CheckRideRequestSubStatusAllItems.NewDateProposedByExaminer:
      case CheckRideRequestSubStatusAllItems.PendingOutcome:
      case CheckRideRequestSubStatusAllItems.AcceptedPendingPayment:
      case CheckRideRequestSubStatusAllItems.AdditionalInformationNeeded:
      case CheckRideRequestSubStatusAllItems.RequiresAdditionalInfo:
      case CheckRideRequestSubStatusAllItems.PendingPayment:
        return this.requiresActionEventColor;
      default:
        return this.noActionRequiredEventColor;
    }
  }

  computeEndDateTime(ScheduledDateTime: string, ScheduledTimeMinutes: number) {
    const start = getMomentValue(ScheduledDateTime, true, true);

    const end = start.add(ScheduledTimeMinutes, 'minutes');

    return end.toISOString();
  }

  convertEvents(events: CalendarEventDTO[]): CalendarWeekDayEvent[] {

    return events.map(e => this.convertEvent(e))
    // remove blank events
    .filter(e => e);
  }

  private convertDateTime(value: string): string {
    if (!value) {
      return value;
    }


    //let momentValue = getMomentValue(value, true, true);

    const localTime = formatDateTimeToLocal(value, true, true);

    let momentValue = getMomentValue(localTime, true, true);

    //momentValue.add(-5, 'hours');

    let convertedDateTime = momentValue.toISOString(true);

    return convertedDateTime;
  }

  convertEvent(e: CalendarEventDTO): CalendarWeekDayEvent {
    let calendar = this.allCalendars.find(c => c.Id == e.CalendarId);

    if (!calendar) {
      calendar = this.allCalendars.find(c => c.Id == this.calendarReference?.MainCalendarKey);
    }

    const event: CalendarWeekDayEvent = {
      id: e.Id,
      text: e.Name,
      start: this.convertEventDateTime(e.Start),
      end: this.convertEventDateTime(e.End),
      barColor: calendar.Color?.BackgroundColor,
      allday: e.AllDayEvent,
      moveDisabled: true
    };

    switch(e.Status) {
      case CalendarEventStatus.cancelled:
        return null;
      case CalendarEventStatus.tenative:
        event.text = `${event.text} (Tentative)`
        break;
    }

    if (e.AllDayEvent) {
      event.allday = false;
      
      event.start = this.convertEventDateTime(e.Start, '00:00') 
      event.end = this.convertEventDateTime(e.End, '23:59') 
    }

    if (e.Color) {
      event.fontColor = e.Color.ForegroundColor;
      event.backColor = e.Color.BackgroundColor
    } else {
      event.fontColor = this.getForegroundColor(calendar.Color?.ForegroundColor);
      event.backColor = calendar.Color?.BackgroundColor;
    }

    event.fontColor = this.getForegroundColor(event.fontColor);

    if (event.barColor == event.backColor) {
      event.barColor = '#ffffff';
    }

    event.bubbleHtml = `<div class="bubble"><div class="bubble-title">${e.Name}</div>`;

    if (e.Description) event.bubbleHtml += `<div class="bubble-content">${e.Description}</div>`

    if (e.Transparency) {
      event.text = `${event.text} (Free)`
      event.bubbleHtml += `<div class="bubble-content">Event is marked as Free time</div>`
    }

    if (e.HtmlLink) {
      const htmlId = e.HtmlLink.split('eid=')[1];
      const linkUrl = `https://calendar.google.com/calendar/u/1/r/eventedit/${htmlId}`;

      event.bubbleHtml += `<br><a href='${linkUrl}' target='_blank'>View in Google Calendar</a>`;
    }

    event.bubbleHtml += `</div>`

    return event;
  }

  private getForegroundColor(color: string): string {
    return '#fff';

  }

  private convertEventDateTime(eventDate: EventDateTimeDTO, forceTimeForDateOnly?: string): string {
    if (eventDate?.DateTime) {
      return convertToIsoDateTime(formatDateTimeToLocal(eventDate.DateTimeRaw, true, true), true);
    }

    if (eventDate?.Date) {
      let date = eventDate.Date;

      if (forceTimeForDateOnly) {
        date = `${date} ${forceTimeForDateOnly}`;  
      }

      return convertToIsoDateTime(formatDateTimeToLocal(date, true, true), true);
    }

    return null;
  }


  private setCalendarOptions() {
    this.options = {
      startDate: convertToIsoDateTime(moment().format("MM/DD/YYYY"), false),
    };

  };

}


