import { Component, OnInit } from '@angular/core';
import { DwComponent, DwComponentType, DwMenuService } from '@devwareapps/devware-cap';
import { ExaminerRepositoryService } from '../../services/examiner-repository.service';
import { AppMetaDataItemNames, PilotExaminerEntity } from '../../../../meta-data/app-meta-data.service';
import { ActivatedRoute } from '@angular/router';

@DwComponent({
  key: 'examiner-public-profile',
  name: 'Examiner Public Profile',
  componentType: DwComponentType.pageComponent,
  parentItemName: AppMetaDataItemNames.PilotExaminer,
  isGlobal : false,
})
@Component({
  selector: 'app-examiner-public-profile',
  templateUrl: './examiner-public-profile.component.html',
  styleUrls: ['./examiner-public-profile.component.scss']
})
export class ExaminerPublicProfileComponent implements OnInit {
  examinerKey: any;
  keyValue: number;
  pilotExaminer: PilotExaminerEntity;
  showNotFound = false;
  checkrideTypes: string[];
  airports: string[];

  constructor(private menuService: DwMenuService, private route: ActivatedRoute, private examinerRepositoryService: ExaminerRepositoryService) { }

  ngOnInit(): void {
    this.menuService.menuStateObservable.subscribe((menuState) => {
     // console.log(menuState);
      this.setupComponent();
    });

    this.setupComponent();
  }


  initComplete = false;
  private setupComponent() {

    if (this.initComplete) {
      return;
    }

    const params = this.route.snapshot.paramMap;

    this.examinerKey = params.get('examinerKey');

    if (!this.examinerKey) {

      this.examinerKey = this.menuService.getMenuParam('examinerKey');

      if (!this.examinerKey) {
        return;
      }
    }

    this.initComplete = true;

    this.examinerRepositoryService.getExaminerIdByProfileKey(this.examinerKey)
      .subscribe((pilotExaminer) => {
        this.pilotExaminer = pilotExaminer;

        this.examinerRepositoryService.storeReferredExaminer(pilotExaminer);

        if (!this.pilotExaminer) {
          this.showNotFound = true;
        }

        this.setCheckrideTypes();
        this.setupAirports();

        this.keyValue = pilotExaminer?.PilotExaminerId;
      });
  }

  private setCheckrideTypes() {
    if (!this.pilotExaminer) {
     return;
    }

    const checkrideTypes: string[] = [];

    for(const scenario of this.pilotExaminer.CheckRideScenario || []) {
      for(const checkRideTypeMap of scenario.CheckRideScenarioTypeMap || []) {
          checkrideTypes.push(checkRideTypeMap.CheckRideTypeIdDisplay);
      }
    }

    this.checkrideTypes = checkrideTypes;
  }

  
  private setupAirports() {
    if (!this.pilotExaminer) {
     return;
    }

    const airports: string[] = [];

    for(const locaion of this.pilotExaminer.PilotExaminerLocation || []) {
      for(const airport of locaion.PilotExaminerLocationAirport || []) {
        airports.push(airport.AirportIdDisplay);
      }
    }

    this.airports = airports;
  }

}
