

export enum PilexosUserType {
   pilotExaminer='pilotExaminer',
   studentApplicant='pilotApplicant',
   flightSchool='flightSchool',
   pilexosAdmin='pilexosAdmin',
   flightInstructor='flightInstructor',
}

export enum PilexosUserTypeIds {
    pilotExaminer=1,
    studentApplicant=3,
    flightSchool=2,
    pilexosAdmin=4,
    flightInstructor=5,
}

export function convertUserType(userType: PilexosUserType): number {
    switch (userType) {
        case PilexosUserType.pilotExaminer:
            return PilexosUserTypeIds.pilotExaminer;
        case PilexosUserType.studentApplicant:
            return PilexosUserTypeIds.studentApplicant;
        case PilexosUserType.flightSchool:
            return PilexosUserTypeIds.flightSchool;
        case PilexosUserType.flightInstructor:
            return PilexosUserTypeIds.flightInstructor;
        case PilexosUserType.pilexosAdmin:
            return PilexosUserTypeIds.pilexosAdmin;
    }
}

