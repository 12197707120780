import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { DwComponent, DwComponentType, DwContext, DwFormControl, DwFormControlInit, DwFormFieldConfig, DwLookupInfo, DwMetaDataService, DwMetaDataServiceToken, getMomentValue } from '@devwareapps/devware-cap';
import { AirportEntity, AirportWithAddressInfoQueryEntity, AppMetaDataLookups } from '../../../../meta-data/app-meta-data.service';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { Observable } from 'rxjs';
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';
import { DurationDisplayControlComponent } from '../duration-display-control/duration-display-control.component';

@DwComponent({
  key: 'pilexos-duration-control',
  name: 'Duration Control',
  componentType: DwComponentType.formControl,
  isGlobal: true,
  configData : {
    readOnlyComponent: DurationDisplayControlComponent
  }
})
@Component({
  selector: 'app-duration-control',
  template: `
  <select class="form-control" [formControl]="control">
    <option *ngFor="let duration of durations" [NgValue]="duration.Id">
      {{ duration.Display }}
    </option>
  </select>

  <!--<dw-search-select-lookup [lookupId]="" [options]="durations" [formContorlName]="fieldDef.attrName" [formGroup]="formGroup"></dw-search-select-lookup>-->

  {{durations | json}}
  Value: {{control?.value}}
  `
})
export class DurationControlComponent implements DwFormControl , DwFormControlInit {
  fieldDef: DwFormFieldConfig;
  formGroup: FormGroup;
  context: DwContext;
  useDisplayField?: boolean;
  
  formattedDuration: string;
  durations: DwLookupInfo[] = [];



  disabled = false;

  constructor(@Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
    private examinerRepositoryService: ExaminerRepositoryService,
    private dateTimeUtilService: DateTimeUtilService
  ) { }

  initControl(): void {
    this.buildDurationList();

    this.disabled = (this.fieldDef.isReadOnly || this.control?.disabled);
  }

  ngOnInit() {
    this.updateValue();

    this.control.valueChanges.subscribe(value => {
      this.updateValue();
    });

    
  }

  private updateValue() {
    this.formattedDuration = this.dateTimeUtilService.formatDuration2(this.control.value * 60);
  
  }

  private buildDurationList() {
    this.durations = [];

    for(let duration = 60; duration <= 60 * 10; duration += 30) {
      this.durations.push({ Id: duration.toString(), Display: this.dateTimeUtilService.formatDuration2(duration * 60) });
    }
  }

  /*
   * Returns the rating Form Control to get or set it's value
   */
  get control(): AbstractControl {
    return this.formGroup.get(this.fieldDef.attrName);
  }


}

