import { Component, Input, ViewChild, AfterViewInit, OnInit, Output, EventEmitter, ElementRef, HostListener, OnDestroy } from "@angular/core";
import { DayPilot, DayPilotCalendarComponent } from "daypilot-pro-angular";
import { CalendarOptions } from "../../models/calendar-options.model";
import { CalendarWeekDayEvent } from "../../models/calendar-weekday-event.model";
import { DateTimeModel, convertToIsoDateTime, formatDateTime, getMomentValue } from "@devwareapps/devware-cap";
import { NgbDateStruct, NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";

import * as moment_ from 'moment';
import { EventDateTimeDTO } from '../../../calendar/models/event-data-time.model';
import { CalendarClickEvent, CalendarClickEventType } from "../../models/calendar-click-event.model";
const moment = moment_;

@Component({
  selector: 'app-calendar-weekday',
  templateUrl: './calendar-weekday.component.html',
  styleUrls: ['./calendar-weekday.component.scss']
})
export class CalendarWeekdayComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() options: CalendarOptions;
  @Input() events: CalendarWeekDayEvent[];
  @Input() compactView: boolean = false;

  @ViewChild("calendar", { static: false }) calendar: DayPilotCalendarComponent;

  @ViewChild("calendarBody") calendarBodyElement: ElementRef;

  @ViewChild(NgbDatepicker, { static: false }) private dp: NgbDatepicker;

  @Output() dateChange = new EventEmitter<DateTimeModel>();

  @Output() eventClick = new EventEmitter<CalendarClickEvent>();

  @Output() gotoCalendarClick = new EventEmitter<void>();

  hideCalendar = false;

  datetime: DateTimeModel;
  view: 'Day' | 'Week' | 'WorkWeek' | 'Days' | 'Resources' = 'WorkWeek';
  previousViewType: 'Day' | 'Week' | 'WorkWeek' | 'Days' | 'Resources';

  dateDisplay = 'September 2023';

  firstDayOfWeek = 0;

  config: DayPilot.CalendarConfig;
  calendarWidth: any;
  mobileView: boolean = false;
  interval: any;

  onDateSelection(date) {
    var dateModel = DateTimeModel.fromNgcDate(date, false);

    dateModel.timeZoneOffset = 0;
    //dateModel. = 'UTC';

    let isoDate = this.convertDateModelToISO(dateModel);

    isoDate = dateModel.toString(false);

    this.setStartDate(isoDate);
  }


  private convertDateModelToISO(dateModel: DateTimeModel): string {

    if (this.isInteger(dateModel.year) && this.isInteger(dateModel.month) && this.isInteger(dateModel.day)) {
      const year = dateModel.year.toString().padStart(2, '0');
      const month = dateModel.month.toString().padStart(2, '0');
      const day = dateModel.day.toString().padStart(2, '0');

      if (!dateModel.hour) {
        dateModel.hour = 0;
      }
      if (!dateModel.minute) {
        dateModel.minute = 0;
      }
      if (!dateModel.second) {
        dateModel.second = 0;
      }
      if (!dateModel.timeZoneOffset && dateModel.timeZoneOffset !== 0) {
        dateModel.timeZoneOffset = new Date().getTimezoneOffset();
      }

      const hour = dateModel.hour.toString().padStart(2, '0');
      const minute = dateModel.minute.toString().padStart(2, '0');
      const second = dateModel.second.toString().padStart(2, '0');

      const tzo = -dateModel.timeZoneOffset;
      const dif = tzo >= 0 ? '+' : '-';

      const timeString = this.toTimeString(dateModel);

      //let isoString = `${this.pad(year)}-${this.pad(month)}-${this.pad(day)}T${pad(hour)}:${pad(minute)}:${pad(second)}${dif}${pad(tzo / 60)}:${pad(tzo % 60)}`;

      let isoString = `${this.pad(year)}-${this.pad(month)}-${this.pad(day)}T${timeString}${dif}${this.pad(tzo / 60)}:${this.pad(tzo % 60)}`;

      return isoString;
    }
  }



  private toTimeString(dateModel: DateTimeModel): string {
    if (!dateModel.hour) {
      dateModel.hour = 0;
    }
    if (!dateModel.minute) {
      dateModel.minute = 0;
    }
    if (!dateModel.second) {
      dateModel.second = 0;
    }

    const hour = dateModel.hour.toString().padStart(2, '0');
    const minute = dateModel.minute.toString().padStart(2, '0');
    const second = dateModel.second.toString().padStart(2, '0');

    const timeString = `${this.pad(hour)}:${this.pad(minute)}:${this.pad(second)}`;

    return timeString;
  }
  private isInteger(value: any): value is number {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  }

  private pad(num) {
    const norm = Math.floor(Math.abs(num));

    return (norm < 10 ? '0' : '') + norm;
  }

  @HostListener('window:resize', ['$event.target'])
  public onResize(target) {
    this.setCalendarViewBySize();
  }

  private setCalendarViewBySize() {
    const width = this.calendarBodyElement?.nativeElement?.offsetWidth;

    this.calendarWidth = width;
    if (width < 400) {

      this.mobileView = true;
      if (this.config.viewType !== 'Day') {
        this.view = 'Day';
        this.previousViewType = this.config.viewType;
        this.viewChange(this.view);
      }
    } else {
      this.mobileView = false;
      if (this.previousViewType) {
        this.view = this.previousViewType;
        this.viewChange(this.view);
        this.previousViewType = null;
      }
    }
  }

  ngOnInit(): void {
    this.datetime = this.convertFromLocalString(this.options.startDate);
    // this.datetime = DateTimeModel.fromLocalString(this.options.startDate, false);

    // if (this.datetime.timeZoneOffset != 0) {
    //   this.datetime.hour = this.datetime.timeZoneOffset / 60;;
    // }

    if (this.compactView) {
      this.view = 'Day';
    }

    const config: DayPilot.CalendarConfig = {
      viewType: this.view,

      startDate: this.options.startDate || "2023-09-10",
      showHeader: true,
      onEventClick: args => { this.logEvent(CalendarClickEventType.click, args) },
      onEventMove: args => { this.logEvent(CalendarClickEventType.move, args); },
      onEventMoved: args => { this.logEvent(CalendarClickEventType.moved, args); args.preventDefault(); },
      onEventSelect: args => { this.logEvent(CalendarClickEventType.select, args) },
      onEventSelected: args => { this.logEvent(CalendarClickEventType.selected, args) },
      onEventDoubleClick: args => { this.logEvent(CalendarClickEventType.doubleClick, args) },
      onTimeRangeSelected: args => { this.logEvent(CalendarClickEventType.timeRangeSelected, args) },
      onHeaderClick: args => { this.logEvent(CalendarClickEventType.headerClick, args) },
      dayBeginsHour: 0,
      dayEndsHour: 24,
      businessBeginsHour: 8,
      businessEndsHour: 18,
      heightSpec: 'Parent100Pct'
    };

    this.setCalendarViewBySize();

    this.updateConfig(config);

    this.toggleCalendarDisplay();
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  /**
   * Sets a 10 minute interval to hide the calendar, then show it again
   * This is a hack to workaround the trial version error, since this version is no longer supported a license isn't available
   */
  private toggleCalendarDisplay() {
    this.interval = setInterval(() => {
      this.hideCalendar = true;

      setTimeout(() => {
        // hide calendar for 50 ms
        this.hideCalendar = false;
      });

      // }, 10 * 1000);  // Toggle calendar visibility every 10 seconds (testing only)
    }, 10 * 60 * 1000);  // Toggle calendar visibility every 10 minutes
  }

  private logEvent(eventType: CalendarClickEventType, args: any) {
    //console.log(`Calendar Event - ${name}`, args);

    this.eventClick.emit({
      eventType: eventType,
      eventData: args.e?.data,
      mouseEvent: args.originalEvent,
      fullEvent: args.e,
      preventDefault: args.preventDefault,
      start: args.start,
      end: args.end
    });
  }

  viewChange(view) {
    this.updateConfig({ viewType: view });
  }

  dayOffset = 0;

  gotoToday() {
    var startDate = convertToIsoDateTime(moment().utc().format("MM/DD/YYYY"), false);

    this.setStartDate(startDate);
  }


  next() {
    this.dayOffset += this.config.viewType === "Day" ? 1 : 7;

    const newDate = this.addDays(this.dayOffset);

    this.setStartDate(newDate.toISOString());
  }

  previous() {
    this.dayOffset -= this.config.viewType === "Day" ? 1 : 7;

    const newDate = this.addDays(this.dayOffset);

    this.setStartDate(newDate.toISOString());
  }

  private setStartDate(date: string) {
    this.updateConfig({ startDate: date });

    this.datetime = this.convertFromLocalString(date);
    // this.datetime = DateTimeModel.fromLocalString(date, false);


    // if (this.datetime.timeZoneOffset != 0) {
    //   this.datetime.hour = this.datetime.timeZoneOffset / 60;;
    // }

    if (this.dp) {
      this.dp.navigateTo(this.datetime);
    }

    this.dateChange.emit(this.datetime);
  }

  private convertFromLocalString(date: string): DateTimeModel {

    let datetime = DateTimeModel.fromLocalString(date, false);

    if (datetime.timeZoneOffset > 0) {
      datetime.hour = datetime.timeZoneOffset / 60;;
    }


    if (datetime.timeZoneOffset < 0) {
      datetime.hour = 24 - (datetime.timeZoneOffset / 60);
    }

    return datetime;
  }


  private updateConfig(config: Partial<DayPilot.CalendarConfig>) {
    this.config = { ...this.config, ...config };

    switch (this.config.viewType) {
      case 'Day':
        this.dateDisplay = formatDateTime(this.config.startDate, false);
        break;
      default:
        const momentDate = getMomentValue(this.config.startDate, false, false);

        this.dateDisplay = momentDate.format('MMMM YYYY');
        break;
    }
  }

  addDays = function (days) {
    var date = new Date();
    date.setDate(date.getDate() + days);

    return date;
  }


  ngAfterViewInit(): void {
    var from = this.calendar.control.visibleStart();
    var to = this.calendar.control.visibleEnd();
    // this.ds.getEvents(from, to).subscribe(result => {
    //   this.events = result;
    // });
  }

}

