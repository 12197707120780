import { Component, Input, OnInit } from '@angular/core';
import { DwTaskItem } from '../../models/dw-task-item.model';
import { DwContainerOptions } from '@devwareapps/devware-cap';
import { Router } from '@angular/router';

@Component({
  selector: 'dw-task-container',
  templateUrl: './dw-task-container.component.html',
  styleUrls: ['./dw-task-container.component.scss']
})
export class DwTaskContainerComponent implements OnInit {
  @Input() tasks: DwTaskItem[];
  @Input() title: string = 'Tasks';
  @Input() containerOptions: DwContainerOptions;
  
  constructor(private router: Router) { }

  ngOnInit(): void {

    this.containerOptions = this.containerOptions || {
       containerHeaderIcon : 'fa fa-check-circle',
       containerHeaderBackgroundColor: 'orange',
       containerHeight: '200px',
       useContainerStyling: true,
       showTitle: true
    }
  }

  taskClick(task: DwTaskItem) {
    if (task.clickFunc) {
      task.clickFunc();
      return;
    }

    if (task.navigateUrl) {
      this.router.navigateByUrl(task.navigateUrl);
    }
  }



}
