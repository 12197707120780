import { Component, EventEmitter, Inject, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DwComponent, DwComponentType, DwContainerOptions, DwContext, DwExpressionService, DwMessage, DwMessageSeverity, DwMetaDataFormConfig, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwSectionBasePreference, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService, areEqual, deepCopy, formatDateTimeFromLocal } from '@devwareapps/devware-cap';
import { Observable, Subscription } from 'rxjs';
import { ExaminerRepositoryService } from '../../services/examiner-repository.service';
import { AirportEntity, AppMetaData, AppMetaDataItemNames, CheckRideRequestEntity, CheckRideRequestSubStatusAllItems, PilotExaminerLocationEntity, PilotExaminerSearchQueryEntity } from '../../../../meta-data/app-meta-data.service';
import { ExaminerLocationSearchRequest } from '../../models/examiner-location-search-request.model';
import { ExaminerLocationSelection } from '../../models/exminer-location-selection.model';
import { tap } from 'rxjs/operators';
import {  CheckrideReferenceInfo } from '../../models/checkride-reschedule-info.model';

@DwComponent({
  key: 'exminer-location-util',
  name: ' Examiner Location Util',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.PilotExaminerLocation,
  isGlobal: false
})
@Component({
  selector: 'app-examiner-location-util',
  template:`
    <dw-message [(message)]="message"></dw-message>
  `
})
export class ExaminerLocationUtilComponent extends DwSectionBaseComponent {
  pilotExaminerLocation: PilotExaminerLocationEntity;

  initComplete = false;
  originalPilotExaminerLocation: PilotExaminerLocationEntity;
  originalAirportsIds: number[];
  message: DwMessage;

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private examinerRepositoryService: ExaminerRepositoryService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
  }

  ngOnInit(): void {
    this.setupDataWatch();
  }
  
  setupDataWatch() {
    this.dwMetaDataFormStateService.state.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (this.dwMetaDataFormStateService.state.formApi.isDataLoadComplete) {
          this.checkForDataChange();
        }
      });

    if (this.dwMetaDataFormStateService.state.formApi.isDataLoadComplete) {
      this.checkForDataChange();
    }
  }

  private checkForDataChange() {
    this.pilotExaminerLocation = this.dwMetaDataFormStateService.state.formApi.getFormData<PilotExaminerLocationEntity>();
    
    // On init, just copy the original data so we can now watch for changes
    if (!this.initComplete) {
      this.originalAirportsIds = this.pilotExaminerLocation?.PilotExaminerLocationAirport.map(a => a.AirportId);

      this.originalPilotExaminerLocation = deepCopy(this.pilotExaminerLocation);

      this.initComplete = true;

      return;
    }

    const airportIds = this.pilotExaminerLocation?.PilotExaminerLocationAirport.filter(a => !a._isDeleted && a.AirportId).map(a => a.AirportId);

    if (airportIds.length==0 ||  areEqual(airportIds, this.originalAirportsIds)) {
      this.message = null;
      return;
    }

    this.examinerRepositoryService.getAirports(airportIds)
      .subscribe(airports => {
          const airport = airports.find(a => a .AirportId == airportIds[0]);
          
          this.checkAirportTimezones(airports);

          if (airport.AirportAddress.TimezoneId == this.pilotExaminerLocation.BookingScheduleTimeZoneId) {
            return;
          }

          const partialUpdate : Partial<PilotExaminerLocationEntity> = {
            BookingScheduleTimeZoneId : airport.AirportAddress.TimezoneId
          };
          this.dwMetaDataFormStateService.state.formApi.patchFormData(partialUpdate);
      });
  }

  private checkAirportTimezones(airports: AirportEntity[]) {
    this.message = null;

    if (airports.length < 2) {
      return;
    }


    const timezones : number[] = [];

    for(const airport of airports) {
      if (!timezones.includes(airport.AirportAddress.TimezoneId)) {
        timezones.push(airport.AirportAddress.TimezoneId);
      }
    }

    if (timezones.length>1) {

      this.message = {
        messageHeading: 'Timezone Warning',
        messageBody: `The selected airports span ${timezones.length} timezones.  Please not that your booking days will use the booking timezone below.`,
        severity: DwMessageSeverity.warning,
      }
    }
  }
}

