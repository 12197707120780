
<div *ngIf="showSearch">
    <dw-button-bar>
        <button class="btn btn-success" (click)="search()">Search</button>

        <button class="btn btn-danger" (click)="cancelSearch()" *ngIf="allowCancel">Cancel</button>
    </dw-button-bar>
    <h2 *ngIf="searchResults">Pilot Examiner Results <i class="fa fa-info-circle" title="Default search is 4 weeks, if none available, adjust your date further into future"></i></h2> 
    
</div>
<div *ngIf="!showSearch">
    <button class="btn btn-success" (click)="setShowSearch()" *ngIf="!isSectionReadOnly()">Edit Checkride Details</button>
</div>
<div class="parent-container">
    <dw-loading [show]="loading" [text]="loadingMessage"></dw-loading>

    <dw-message [(message)]="message"></dw-message>


    <div *ngIf="searchResults?.length>0" class="result-container">
        <ng-container *ngFor="let searchResult of searchResults">
            <app-examiner-booking-search-result [showDateSelection]="showSearch" [searchResult]="searchResult" [(examinerLocationSelection)]="examinerLocationSelection" [testType]="checkRideRequest.CheckRideTestTypeId" [applicantCheckrideVariables]="applicantCheckrideVariables" (examinerLocationSelectionChange)="examinerLocationSelected()"></app-examiner-booking-search-result>
        </ng-container>
    </div>
</div>