import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PilotExaminerSearchQueryEntityExt } from '../../../../meta-data/extensions/pilot-examiner-search-query-ext.model';
import { formatDateTime } from '@devwareapps/devware-cap';

@Component({
  selector: 'app-schedule-detail',
  templateUrl: './schedule-detail.component.html',
  styleUrls: ['./schedule-detail.component.scss']
})
export class ScheduleDetailComponent implements OnInit {

  @Input() searchResult: PilotExaminerSearchQueryEntityExt;

  @Input() isContainerSelected: boolean;

  @Input() selectedDateTime: string;
  
  selectedDateTimeFormatted: string;
  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void { 
     this.selectedDateTimeFormatted = formatDateTime(this.selectedDateTime, true, true);
  }
  

}
