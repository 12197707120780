import { Component, Inject, OnInit } from '@angular/core';
import { DwActionPreference, DwComponent, DwComponentType, DwExpressionService, DwFormActionConfig, DwFormActionInterceptResult, DwFormActionInterceptor, DwFormActionKeys, DwMenuService, DwMessageSeverity, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwModalButtonStandardConfigs, DwModalConfig, DwModalResult, DwModalService, DwModalSize, DwOrmDataService, DwOrmDataServiceToken, DwSectionBaseComponent, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService, getParameterByName } from '@devwareapps/devware-cap';
import { AppMetaDataItemNames, CheckRideRequestEntity, CheckRideRequestPaymentPartyAllItems, CheckRideRequestSubStatusAllItems, PilotExaminerEntity, StudentApplicantWithDetailQueryEntity } from '../../../../meta-data/app-meta-data.service';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { Observable, of } from 'rxjs';
import { PaymentRepositoryService } from '../../services/payment-repository.service';
import { map, mergeMap } from 'rxjs/operators';
import { CheckridePaymentResult } from '../../models/checkride-payment-result.model';
import { PaymentModalOptions } from '../../models/payment-modal-options.model';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';
import { CurrentStudentContext } from '../../../shared/contexts/current-student-context/current-student-context.service';
import { PaymentChargeRequest } from '../../models/payment-charge-request.model';
import { Router } from '@angular/router';
import { CheckridePaymentUtilComponent } from '../checkride-payment-util/checkride-payment-util.component';
import { CurrentPilotExaminerContext } from '../../../shared/contexts/current-pilot-examiner/current-pilot-examiner.service';


@DwComponent({
  key: 'checkride-payment-util-examiner',
  name: ' Checkride Payment Util Examiner',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.CheckRideRequest,
  isGlobal: false
})
@Component({
  selector: 'app-checkride-payment-util-examiner',
  template: `
    <!--<button class="btn btn-success">
      Pay Pilexos Fee
    </button>-->
  `,
})
export class CheckridePaymentUtilExaminerComponent extends CheckridePaymentUtilComponent {

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getCancelledError():string {
    return 'Payment method was not added.';
  }

  protected processBeforeAction(actionPref: DwActionPreference<DwFormActionConfig>, actionConfig: any): Observable<DwFormActionInterceptResult> {
    const result: DwFormActionInterceptResult = {
      abort: false 
    }

    const isSave = actionPref.actionKey == DwFormActionKeys.formSave || actionPref.actionKey == DwFormActionKeys.formWorkflow

    if (!isSave) {
      return of(result);
    }

    if (actionPref.actionDisplay!='Save with Payment') {
      return of(result);
    }

    const validationResult = this.dwMetaDataFormStateService.state.formApi.validateForm();

    if (!validationResult.isValid) {
      result.abort = true;
      result.message = this.dwMetaDataFormStateService.state.formApi.createValidationMessage(validationResult);

      return of(result);
    }

    const checkrideRequest = this.dwMetaDataFormStateService.state.formApi.getFormData<CheckRideRequestEntity>();

    if (checkrideRequest.PilexosBookingPaymentAdded) {
      return of(result);
    }

    if (checkrideRequest.PilexosBookingCostPaymentPartyId != CheckRideRequestPaymentPartyAllItems.PilotExaminer) {
      return of(result);
    }

    // If it's not in the correct status, update the status (this prevents it from being set to pending approval or other without payment)
    if (!checkrideRequest.CheckRideRequestId) {
      // Update the status to pending payment and save it
      checkrideRequest.CheckRideRequestSubStatusId = CheckRideRequestSubStatusAllItems.AcceptedPendingPayment;
    }

    // Change to always save before the payment - otherwise some fields aren't saved when the e-mail notification is sent
    return this.dwOrmDataService.saveEntity(checkrideRequest, true)
        .pipe(mergeMap(result => {
          // update the data on the form
          this.dwMetaDataFormStateService.state.formApi.patchFormData(result);
          
          return this.addPayment(result);
        }));
  }

  
  protected getUpdateStatusValue() {
    return true;
  }

  protected populateChargeRequestDetails(paymentModalOptions: PaymentModalOptions) {
    const currentExaminerInfo: PilotExaminerEntity = this.context[CurrentPilotExaminerContext]?.value;

    paymentModalOptions.chargeRequest.cardholderName = currentExaminerInfo?.UserIdDisplay;
    paymentModalOptions.chargeRequest.paymentPartyId = paymentModalOptions.checkrideRequest.PilexosBookingCostPaymentPartyId;
    paymentModalOptions.showPaymentParty = true;
    paymentModalOptions.paymentCompleteMessage = "Your payment is complete!";
  }
}
