



<div class="dashboard-title">
    Welcome {{applicantName}} to your Applicant Dashboard!
</div>

<div class="dashboard-container-flex">
    <div class="dashboard-column-left">
        <dw-task-container [tasks]="tasks"></dw-task-container>
    </div>

    
    <div class="dashboard-column-right" *ngIf="nextCheckRequestId">
        <dw-container  [options]="checkrideContainerOptions">
            <span header>{{checkrideTitle}}</span>
            <div body>
                <dw-default-form #mdForm item="CheckRideRequest" configKeyPart="application-next-checkride" [keyValue]="nextCheckRequestId">
                </dw-default-form>
            </div>
        </dw-container>
    </div>
</div>