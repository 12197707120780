import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { DwComponent, DwComponentType, DwContext, DwFormControl, DwFormControlInit, DwFormFieldConfig, DwLookupInfo, DwMetaDataService, DwMetaDataServiceToken, getMomentValue } from '@devwareapps/devware-cap';
import { AirportEntity, AirportWithAddressInfoQueryEntity, AppMetaDataLookups } from '../../../../meta-data/app-meta-data.service';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { Observable } from 'rxjs';
import { DateTimeUtilService } from '../../util/date-time-util.service';

@DwComponent({
  key: 'pilexos-duration-display',
  name: 'Duration Display',
  componentType: DwComponentType.formControl,
  isGlobal: true
})
@Component({
  selector: 'app-duration-display-control',
  template: `
  <div>{{ formattedDuration}}</div>
  `
})
export class DurationDisplayControlComponent implements DwFormControl , DwFormControlInit{
  fieldDef: DwFormFieldConfig;
  formGroup: FormGroup;
  context: DwContext;
  useDisplayField?: boolean;
  
  formattedDuration: string;

  constructor(@Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
    private examinerRepositoryService: ExaminerRepositoryService,
    private dateTimeUtilService: DateTimeUtilService
  ) { }


  initControl(): void {

  }

  ngOnInit() {
    this.updateValue();

    this.control.valueChanges.subscribe(value => {
      this.updateValue();
    });
  }


  private updateValue() {
    this.formattedDuration = this.dateTimeUtilService.formatDuration2(this.control.value * 60);
  
  }

  /*
   * Returns the rating Form Control to get or set it's value
   */
  get control(): AbstractControl {
    return this.formGroup.get(this.fieldDef.attrName);
  }


}

