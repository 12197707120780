


<div class="main-container-flex">


    <div class="left-column" *ngIf="!compactView">
        <ngb-datepicker #dp [(ngModel)]="datetime" (dateSelect)="onDateSelection($event)"
            (select)="onDateSelection($event)" [firstDayOfWeek]="firstDayOfWeek"></ngb-datepicker>
        <ng-content>

        </ng-content>
    </div>
    <div class="right-column">
            <div class="calendar-container-flex">

                <div class="calendar-top">
                    <div class="button-container">
                        <button (click)="gotoToday()" class="btn btn-primary today" *ngIf="!compactView">Today</button>
    
                        <i class="fa fa-chevron-left nav-item" (click)="previous()" title="Previous {{view}}"></i>
                        <i class="fa fa-chevron-right nav-item" (click)="next()" title="Next {{view}}"></i>
    
                        <div class="date-display">{{ dateDisplay }}</div>
    
                        <div class="view-selection" *ngIf="!compactView && !mobileView">
                            <select [(ngModel)]="view" (ngModelChange)="viewChange($event)">
                                <option value="Day">Day</option>
                                <option value="Week">Week</option>
                                <option value="WorkWeek">Work Week</option>
                            </select>
                        </div>
                        <div class="view-selection" *ngIf="compactView">
                            <button (click)="gotoCalendarClick.emit()" *ngIf="!mobileView" class="btn-sm btn-primary">View Full
                                Calendar</button>
                            <button (click)="gotoCalendarClick.emit()" *ngIf="mobileView" class="btn-sm btn-primary">
                                <i class="fa fa-calendar" title="View Full Calendar"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div #calendarBody class="calendar-body">
                    <daypilot-calendar [config]="config" [events]="events" #calendar *ngIf="!hideCalendar"></daypilot-calendar>
                </div>
            </div>
    </div>
</div>

<!-- 
<div class="main-container-flex" *ngIf="false">

    <div class="left-column" *ngIf="!compactView">
        <ngb-datepicker #dp [(ngModel)]="datetime" (dateSelect)="onDateSelection($event)"
            (select)="onDateSelection($event)" [firstDayOfWeek]="firstDayOfWeek"></ngb-datepicker>
        <ng-content>

        </ng-content>
    </div>
    <div class="right-column" [ngClass]="{ 'right-column': !compactView, 'right-column-compact': compactView}">
        <div class="calendar-container-flex">

            <div class="calendar-top">
                <div class="button-container">
                    <button (click)="gotoToday()" class="btn btn-primary today">Today</button>

                    <i class="fa fa-chevron-left nav-item" (click)="previous()" title="Previous {{view}}"></i>
                    <i class="fa fa-chevron-right nav-item" (click)="next()" title="Next {{view}}"></i>

                    <div class="date-display">{{ dateDisplay }}</div>

                    <div class="view-selection" *ngIf="!compactView">
                        <select [(ngModel)]="view" (ngModelChange)="viewChange($event)">
                            <option value="Day">Day</option>
                            <option value="Week">Week</option>
                            <option value="WorkWeek">Work Week</option>
                        </select>
                    </div>
                    <div class="view-selection" *ngIf="compactView">
                        <button (click)="gotoCalendarClick.emit()" class="btn-sm btn-primary">View Full
                            Calendar</button>
                    </div>
                </div>
            </div>
            <div class="calendar-body">asdfasdf
                
                 <daypilot-calendar [config]="config" [events]="events" #calendar></daypilot-calendar> 
            </div>
        </div>

    </div>
</div> -->