import { Inject, Injectable } from "@angular/core";
import { DwOrmDataServiceToken, DwOrmDataService, DwMetaDataServiceToken, DwMetaDataService, DwConfigServiceToken, DwConfigService, DwSecurityUserService, DwSecurityTopics, DwCacheService, flattenArray } from "@devwareapps/devware-cap";
import { Observable } from "rxjs";
import { PilotExaminerEntity, AppMetaData, AcsCodesLookupQueryEntity, PlanOfActionEntity, AcsTestEntity, PlanOfActionSectionTypeEntity, PlanOfActionSectionEntity, PlanOfActionQuestionResultEntity, PlanOfActionResultEntity } from "../../../meta-data/app-meta-data.service";
import { map, mergeMap } from "rxjs/operators";

import { GenerateSectionsOptions } from "../models/generate-sections-options.model";
import { AcsCodeGroup, AcsCodeStatuses, AcsCodeStorage, AcsCodesStorageItem } from "../models/acs-code-items.model";

@Injectable({ providedIn: 'root' })
export class PlanOfActionRepositoryService {
    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        @Inject(DwConfigServiceToken) configService: DwConfigService,
        private dwSecurityUserService: DwSecurityUserService,
        private dwCacheService: DwCacheService
    ) {
    }

    getPlanOfActionSectionTypes(): Observable<PlanOfActionSectionTypeEntity[]> {
        const cacheInvalidationTopics = [];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.PlanOfActionSectionType.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsTestPortion.ItemDetail.itemName));

        return this.dwCacheService.get('planOfActionSectionTypes', this.loadPlanOfActionSectionTypes(), null, cacheInvalidationTopics);
    }

    
    loadPlanOfActionSectionTypes(): Observable<PlanOfActionSectionTypeEntity[]> {
        const query = AppMetaData.PlanOfActionSectionType.CreateQueryBuilder();

        query.addPrefetch(r => r.AcsTestPortion);

        return this.dwOrmDataService.executeQuery(query);
    }

    loadPlanOfActionSections(planOfActionId: number): Observable<PlanOfActionSectionEntity[]> {
        const query = AppMetaData.PlanOfActionSection.CreateQueryBuilder();

        query.query.FieldSettings.LoadAllLookupDisplayFields = true;
        query.addFilterAnd(f => f.Equal(a => a.PlanOfActionId, planOfActionId));

        query.addOrderBy(o => o.SectionOrder);
        var sectionQuestionQuery = AppMetaData.PlanOfActionSectionQuestion.CreateQueryBuilder();

        sectionQuestionQuery.addOrderBy(o => o.QuestionOrder);

        query.addPrefetch(r => r.PlanOfActionSectionQuestion, sectionQuestionQuery.query);
        
        return this.dwOrmDataService.executeQuery(query);
    }

    createPlanOfActionResult(planOfAction: PlanOfActionEntity): Observable<PlanOfActionEntity> {
        planOfAction.PlanOfActionResult = AppMetaData.PlanOfActionResult.CreateEntity();

        planOfAction.PlanOfActionResult.CreatedBy = 1;
        
        return this.dwOrmDataService.saveEntity(planOfAction, true);
    }

    savePlanOfActionQuestionResults(resultsToSave: PlanOfActionQuestionResultEntity[]) {
        return this.dwOrmDataService.saveEntityCollection(resultsToSave, false, false);
    }

    loadPlanOfActionQuestionResults(planOfActionResultId: number): Observable<PlanOfActionQuestionResultEntity[]> {
        const query = AppMetaData.PlanOfActionQuestionResult.CreateQueryBuilder();

        query.addFilterAnd(f => f.Equal(a => a.PlanOfActionResultId, planOfActionResultId));

        //query.addPrefetch(r => r.PlanOfActionSectionQuestion);
        
        return this.dwOrmDataService.executeQuery(query);
    }

    getAcsTest(acsTestId: number): Observable<AcsTestEntity> {
        const query = AppMetaData.AcsTest.CreateQueryBuilder();

        query.query.FieldSettings.LoadAllLookupDisplayFields = true;

        const aooQuery = AppMetaData.AcsAreaOfOperation.CreateQueryBuilder();
        const taskQuery = AppMetaData.AcsAreaOfOperationTask.CreateQueryBuilder();
        
        query.addPrefetch(r => r.AcsAreaOfOperation, aooQuery.query);

        aooQuery.addPrefetch(r => r.AcsAreaOfOperationTask, taskQuery.query);

        taskQuery.addPrefetch(r => r.AcsCode);

        query.addFilterAnd(filters => filters.Equal(field => field.AcsTestId, acsTestId));

        return this.dwOrmDataService.executeQuerySingle(query);
    }

    getAcsCodeStatus(planOfAction: PlanOfActionEntity): AcsCodeStatuses {
        const missedAcsCodes = planOfAction.PlanOfActionRequiredAcsCode.map(a => a.AcsCodeId);
        
        const acsCodeStatuses : AcsCodeStatuses = {
            codesById: {}
        };

        for(const acsCode of missedAcsCodes) {
            acsCodeStatuses.codesById[acsCode] = {
                ascCodeId: acsCode,
                missing: true
            }    
        }

        for(const section of planOfAction.PlanOfActionSection) {
            for(const question of section.PlanOfActionSectionQuestion) {
                const acsCodeStatus = acsCodeStatuses.codesById[question.AcsCodeId];

                if (acsCodeStatus) {
                    acsCodeStatus.missing = false;
                }
            }
        }

        return acsCodeStatuses;
    }

    getAcsCodes(acsTestId: number, areaOfOperationId?: number, taskId?: number): Observable<AcsCodesStorageItem>{
        return this.getAcsCodeStorage(acsTestId)
            .pipe(map(storage => {
                if (taskId) {
                    return storage?.TaskCodes[taskId];
                }
                if (areaOfOperationId) {
                    return storage?.AreaOfOperationCodes[areaOfOperationId];
                }

                return storage;
            }));
    }

    
    getAcsCodeStorage(acsTestId: number): Observable<AcsCodeStorage> {
        const cacheInvalidationTopics = [DwSecurityTopics.SECURITY_CHANGED];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsCode.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsAreaOfOperationTask.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsAreaOfOperation.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsTest.ItemDetail.itemName));

        const key = `acsCodesLookup_${acsTestId}`;

        return this.dwCacheService.get(key, this.loadAcsCodes(acsTestId), null, cacheInvalidationTopics);
    }
    
    loadAcsCodes(acsTestId: number): Observable<AcsCodeStorage> {
        return AppMetaData.Queries.AcsCodesLookup.CreateQueryBuilder(this.dwMetaDataService)
            .pipe(mergeMap(query => {
                
                query.addFilterAnd(f => f.Equal(a => a.AcsTestAcsTestId, acsTestId));

                return this.dwOrmDataService.executeQuery(query)
                    .pipe(map(acsCodes => this.convertAcsCodesToStorage(acsCodes)));
            }));
    }

    /**Convert array into an optimized storage container for getting codes by Area of Operation and Task */
    private convertAcsCodesToStorage(acsCodes: AcsCodesLookupQueryEntity[]): AcsCodeStorage {
        const storage : AcsCodeStorage = {
            acsCodes: acsCodes,
            codesById: {},
            AreaOfOperationCodes: {},
            TaskCodes: {}
        };

        storage.codesById = flattenArray(acsCodes, 'AcsCodeId');

        storage.AreaOfOperationCodes = this.convertGroupCodes(acsCodes, 'AcsAreaOfOperationAcsAreaOfOperationId');
        storage.TaskCodes = this.convertGroupCodes(acsCodes, 'AcsAreaOfOperationTaskId');
                
        return storage;
    }

    private convertGroupCodes(acsCodes: AcsCodesLookupQueryEntity[], groupByField: string) : AcsCodeGroup {
        const groupedCodes = this.groupByArray(acsCodes, groupByField);

        const codeGroup: AcsCodeGroup = {};

        for (const groupId in groupedCodes) {
            if (groupedCodes.hasOwnProperty(groupId)) {
                codeGroup[groupId] = {
                    acsCodes : groupedCodes[groupId],
                    codesById: flattenArray(groupedCodes[groupId], 'AcsCodeId')
                }
            }
        }

        return codeGroup;
    }


    groupByArray<T>(array: T[], fieldName?: string): { [prop: string]: T[] } {
        return array
         .reduce((hash, obj:T) => {
           if(obj[fieldName] === undefined) return hash; 
           return Object.assign(hash, { [obj[fieldName]]:( hash[obj[fieldName]] || [] ).concat(obj)})
         }, {})
    }
}