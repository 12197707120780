<dw-content-manager configKey="home">
    <h1>
        <dw-content name="title" display="Page Title" [controlType]="textboxControlType">
            Welcome to Pilexos!
        </dw-content>
    </h1>

    <dw-content name="detail" display="Header Details">
        You can change the text here by right clicking and select edit.
    </dw-content>

    <dw-content-component name='component' display='Custom Component'></dw-content-component>
</dw-content-manager>