import { Component, OnInit } from '@angular/core';
import { DwComponent, DwComponentType, DwControlType } from '@devwareapps/devware-cap';


@DwComponent({
  name:'Home',
  key: 'home',
  componentType: DwComponentType.pageComponent,
  isGlobal: true
})
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent{
  textboxControlType : DwControlType = DwControlType.Textbox;
}
 