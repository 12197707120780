import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DwComponent, DwComponentType, DwSecurityRepositoryService, DwSecurityRepositoryServiceToken, DwSecurityUserService } from '@devwareapps/devware-cap';
import { MenuItemCardData } from '../../models/menu-item-card-data.model';


@DwComponent({
  key:'pilexos-home-page',
  name: 'Pilexos Home Page',
  componentType: DwComponentType.pageComponent,
  isGlobal: true,
})
@Component({
  selector: 'pilexos-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class PilexosHomeComponent implements OnInit {
  isAuthenticated: boolean;

  constructor(private router:Router,
    private dwSecurityUserService: DwSecurityUserService,
    @Inject(DwSecurityRepositoryServiceToken) private dwSecurityRepositoryService: DwSecurityRepositoryService
  
    ) { }

  ngOnInit(): void {
   

    this.dwSecurityUserService.securityContextChanged().subscribe(change => {
      this.isAuthenticated = this.dwSecurityUserService.securityState.isAuthenticated;
    });
     
    this.isAuthenticated = this.dwSecurityUserService.securityState.isAuthenticated;
  }

}
