import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DwMenuService, DwMenuServiceState } from '@devwareapps/devware-cap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showLayout: boolean = true;

  showSlashScreen: boolean = true;
  environmentMessage: string;
  scrolled = false;

  themeClasses: string;

  constructor(
    private router: Router,
    private menuService: DwMenuService
  ) {
    this.environmentMessage = environment.environmentMessage;
  }

  ngOnInit(): void {

    setTimeout(() => {
      this.showSlashScreen = false;
    }, 200);

    document.addEventListener("scroll", (ev) => {
      let document = ev.target as any;
      if (document.scrollingElement.scrollTop > 50) {
        if (!this.scrolled) {
          this.scrolled = true;
          this.setTheme(this.menuService.menuState);
        }
      }
      else {
        if (this.scrolled) {
          this.scrolled = false;
          this.setTheme(this.menuService.menuState);
        }
      }
    });

    this.menuService.menuStateObservable
      .subscribe(menuState => {
        this.setTheme(menuState);
      })

    this.setTheme(this.menuService.menuState);
  }

  private setTheme(menuState: DwMenuServiceState) {

    // deselect text

    const currentMenu = menuState.currentMenu;

    let themeClass = Themes.home;

    if (!currentMenu) {
      this.themeClasses = themeClass;

      if (this.scrolled) {
        this.themeClasses += ',scrolled';
      }

      return;
    }

    themeClass = Themes.main;

    if (currentMenu.url?.indexOf('admin') >= 0) {
      themeClass = Themes.admin;
    }

    if (currentMenu.data?.referenceKey == 'home') {
      themeClass = Themes.home;
    }

    this.themeClasses = themeClass;

    if (this.scrolled) {
      this.themeClasses += ',scrolled';
    }

    //this.deselectText();
  }

  private deselectText() {
    try {
      if (window.getSelection) { 
        window.getSelection().removeAllRanges(); 
      }
      else 
      if ((document as any).selection) { 
        (document as any).selection.empty(); 
      }
    }
    catch(e) {

    }
  }

  ngOnDestroy(): void {
    document.removeEventListener("scroll", (ev) => { });
  }
}


enum Themes {
  home = 'home-theme',
  main = 'main-theme',
  admin = 'admin-theme'
}
