<div class="card">
    <div class="card-icon-container" [ngClass]="cardData?.iconBackgroundClass">
        <i [ngClass]="cardData?.iconClass" class="card-icon"></i>

        <div class="card-icon-text">
            {{ cardData?.iconText }}
        </div>
    </div>

    <div class="card-main-text">
        <dw-html-view [ngModel]="cardData?.contentHtml"></dw-html-view>
    </div>
    <div class="card-button-area">
        <button [ngClass]="cardData?.buttonClass" class="card-button" (click)="buttonClick()">
            {{ cardData?.buttonText }}
        </button>
    </div>
</div>