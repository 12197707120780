import { Component, Inject, OnInit } from '@angular/core';
import { DwActionPreference, DwComponent, DwComponentType, DwEventService, DwExpressionService, DwFormActionConfig, DwFormActionInterceptResult, DwFormActionInterceptor, DwFormActionKeys, DwFormContextMode, DwMenuService, DwMessageSeverity, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwModalButtonStandardConfigs, DwModalConfig, DwModalResult, DwModalService, DwModalSize, DwOrmDataService, DwOrmDataServiceToken, DwSectionBaseComponent, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService, deepCopy, getParameterByName } from '@devwareapps/devware-cap';
import { AppMetaDataItemNames, CheckRideRequestEntity, CheckRideRequestPaymentPartyAllItems, CheckRideRequestPaymentPartyEntity, CheckRideRequestSubStatusAllItems, StudentApplicantWithDetailQueryEntity } from '../../../../meta-data/app-meta-data.service';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { Observable, of } from 'rxjs';
import { PaymentRepositoryService } from '../../services/payment-repository.service';
import { map, mergeMap } from 'rxjs/operators';
import { CheckridePaymentResult } from '../../models/checkride-payment-result.model';
import { PaymentModalOptions } from '../../models/payment-modal-options.model';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';
import { CurrentStudentContext } from '../../../shared/contexts/current-student-context/current-student-context.service';
import { PaymentChargeRequest } from '../../models/payment-charge-request.model';
import { Router } from '@angular/router';
import { ApplicantRepositoryService } from 'src/app/features/applicant/services/applicant-repository.service';

@DwComponent({
  key: 'checkride-payment-util',
  name: ' Checkride Payment Util',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.CheckRideRequest,
  isGlobal: false
})
@Component({
  selector: 'app-checkride-payment-util',
  template: ``,

})
export class CheckridePaymentUtilComponent extends DwSectionBaseComponent {
  initComplete = false;
  checkRideRequestPaymentPartyList: CheckRideRequestPaymentPartyEntity[];
  originalCheckRideRequest: CheckRideRequestEntity;

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    protected dwMetaDataFormStateService: DwMetaDataFormStateService,
    protected paymentRespositoryService: PaymentRepositoryService,
    @Inject(DwOrmDataServiceToken) protected dwOrmDataService: DwOrmDataService,
    protected dwModalService: DwModalService,
    protected router: Router,
    protected dwMenuService: DwMenuService,
    private examinerRepositoryService: ExaminerRepositoryService,
    private dwEventService: DwEventService,
    private applicantRepositoryService: ApplicantRepositoryService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
  }

  ngOnInit(): void {
    // Add the form inteceptor
    this.dwMetaDataFormStateService.addActionInterceptor(this.getFormActionInterceptor());

    this.setupDataWatch();

    this.examinerRepositoryService.getCheckRideRequestPaymentParty()
      .subscribe(results => {
        this.checkRideRequestPaymentPartyList = results;
        this.checkForDataChange();
      });
  }

  setupDataWatch() {
    this.dwMetaDataFormStateService.state.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (this.dwMetaDataFormStateService.state.formApi.isDataLoadComplete) {
          this.checkForDataChange();
        }
      });

    if (this.dwMetaDataFormStateService.state.formApi.isDataLoadComplete) {
      this.checkForDataChange();
    }
  }

  private checkForDataChange() {
    const checkRideRequest = this.dwMetaDataFormStateService.state.formApi.getFormData<CheckRideRequestEntity>();

    if (checkRideRequest?.PilexosBookingPaymentAdded || !checkRideRequest?.PilexosBookingCostPaymentPartyId) {
      return;
    }

    if (checkRideRequest && !this.originalCheckRideRequest) {
      this.originalCheckRideRequest = deepCopy(checkRideRequest);
    }

    const checkRideRequestPartial: Partial<CheckRideRequestEntity> = {};

    if (checkRideRequest?.CheckRideRequestId && this.originalCheckRideRequest?.PilexosBookingCostPaymentPartyId == checkRideRequest.PilexosBookingCostPaymentPartyId) {
      return;
    }

    if (this.checkRideRequestPaymentPartyList) {
      checkRideRequestPartial.PilexosBookingCost = this.examinerRepositoryService.getPilexosBookingCost(checkRideRequest.CheckRideTestTypeId, checkRideRequest.PilexosBookingCostPaymentPartyId, this.checkRideRequestPaymentPartyList);
    }

    if (this.getUpdateStatusValue())
    switch (checkRideRequest.PilexosBookingCostPaymentPartyId) {
      case CheckRideRequestPaymentPartyAllItems.PilotExaminer:
        checkRideRequestPartial.CheckRideRequestSubStatusId = CheckRideRequestSubStatusAllItems.AcceptedPendingPayment;
        break;
      case CheckRideRequestPaymentPartyAllItems.Applicant:

        checkRideRequestPartial.CheckRideRequestSubStatusId = CheckRideRequestSubStatusAllItems.AcceptedPendingPayment;
        break;
    }

    if (checkRideRequest.PilexosBookingCost != checkRideRequestPartial.PilexosBookingCost || checkRideRequestPartial.CheckRideRequestSubStatusId != checkRideRequest.CheckRideRequestSubStatusId) {
      // Update booking cost based on applicant cost
      setTimeout(() => {
        this.dwMetaDataFormStateService.state.formApi.patchFormData(checkRideRequestPartial);
      },50);
    }
  }

  protected getUpdateStatusValue() {
    return false;
  }

  protected getFormActionInterceptor(): DwFormActionInterceptor {
    // Create a form interceptor so we can latch onto the save and abort if needed
    const interceptor: DwFormActionInterceptor = {
      interceptorName: 'paymentUtilInterceptor',
      onBeforeAction: (actionPref: DwActionPreference<DwFormActionConfig>, actionConfig: any) => {
        return this.processBeforeAction(actionPref, actionConfig);
      },
      onAfterAction: (actionPref: DwActionPreference<DwFormActionConfig>, actionConfig: any, actionResult: any) => {
        return this.processAfterAction(actionPref, actionConfig);
      },
    }

    return interceptor;
  }

  protected processBeforeAction(actionPref: DwActionPreference<DwFormActionConfig>, actionConfig: any): Observable<DwFormActionInterceptResult> {
    const result: DwFormActionInterceptResult = {
      abort: false
    }

    const isSave = actionPref.actionKey == DwFormActionKeys.formSave || actionPref.actionKey == DwFormActionKeys.formWorkflow

    if (!isSave) {
      return of(result);
    }
   
    const validationResult = this.dwMetaDataFormStateService.state.formApi.validateForm();

    if (!validationResult.isValid) {
      result.abort = true;
      result.message = this.dwMetaDataFormStateService.state.formApi.createValidationMessage(validationResult);

      return of(result);
    }

    const checkrideRequest = this.dwMetaDataFormStateService.state.formApi.getFormData<CheckRideRequestEntity>();

    if (checkrideRequest.PilexosBookingPaymentAdded) {
      return of(result);
    }

    if (checkrideRequest.CheckRideRequestSubStatusId == CheckRideRequestSubStatusAllItems.Draft || checkrideRequest.CheckRideRequestSubStatusId == CheckRideRequestSubStatusAllItems.Cancelled) {
      return of(result);
    }

    // If it's not in the correct status, update the status (this prevents it from being set to pending approval or other without payment)
    if (checkrideRequest.CheckRideRequestSubStatusId != CheckRideRequestSubStatusAllItems.PendingPayment && checkrideRequest.CheckRideRequestSubStatusId != CheckRideRequestSubStatusAllItems.AcceptedPendingPayment) {
      // Update the status to pending payment and save it
      checkrideRequest.CheckRideRequestSubStatusId = CheckRideRequestSubStatusAllItems.PendingPayment;
    }
    
    return this.dwOrmDataService.saveEntity(checkrideRequest, true)
        .pipe(mergeMap(result => {
          // update the data on the form
          this.dwMetaDataFormStateService.state.formApi.patchFormData(result);
          return this.addPayment(result);
        }));
    //return this.addPayment(checkrideRequest);
  }

  protected getCancelledError(): string {
    return 'Payment method was not added.  Your Checkride cannot be booked until payment is added.';
  }

  protected addPayment(checkrideRequest: CheckRideRequestEntity): Observable<DwFormActionInterceptResult> {
    const result: DwFormActionInterceptResult = {
      abort: true
    }

    return this.showPaymentModal(checkrideRequest)
      .pipe(mergeMap(paymentResult => {

        if (paymentResult.cancelled) {
          result.abort = true;

          if (checkrideRequest?.CheckRideRequestId) {

            return this.paymentRespositoryService.getCheckrideRequest(checkrideRequest.CheckRideRequestId)
              .pipe(map(checkride => {
                  if (checkride?.PilexosBookingPaymentAdded) {
                    this.paymentSuccessfulAction(result);
                  } else {
                    this.paymentCancelledActionResult(result);
                  }

                  return result;
              }));
          }

          this.paymentCancelledActionResult(result);

          return of(result);
        }

        this.paymentSuccessfulAction(result);

        return of(result);
      }
      ));
  }

  private paymentCancelledActionResult(result: DwFormActionInterceptResult) {

    result.message = {
      messageBody: this.getCancelledError(),
      severity: DwMessageSeverity.error
    };

    return of(result);
  }

  private paymentSuccessfulAction(result: DwFormActionInterceptResult) {
       
        this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.CheckRideRequest), {});

        // Mark as pristine so we can redirect without the message
        this.dwMetaDataFormStateService.state.rootFormGroup.markAsPristine();

        if (getParameterByName(this.router, 'returnhome') == 'true') {
          this.router.navigate(['/']);

          result.abort = true;
        } else {
          // Just cancel the action since everything has been saved
          this.dwMetaDataFormStateService.state.formApi.cancel();
        }
  }


  protected processAfterAction(actionPref: DwActionPreference<DwFormActionConfig>, actionConfig: any): Observable<DwFormActionInterceptResult> {
    const result: DwFormActionInterceptResult = {
      abort: false
    }

    const isSave = actionPref.actionKey == DwFormActionKeys.formSave || actionPref.actionKey == DwFormActionKeys.formWorkflow

    if (!isSave) {
      return of(result);
    }

    if (getParameterByName(this.router, 'returnhome') == 'true') {
      this.router.navigate(['/']);

      result.abort = true;
    }

    return of(result);
  }

  protected showPaymentModal(checkrideRequest: CheckRideRequestEntity): Observable<DwModalResult<CheckridePaymentResult>> {
    //const currentStudentInfo: StudentApplicantWithDetailQueryEntity = this.context[CurrentStudentContext]?.value;

    const chargeRequest: PaymentChargeRequest = {
      amount: checkrideRequest.PilexosBookingCost || 50,
      // cardholderName: currentStudentInfo?.UserIdDisplay,
      // cardholderCountry: currentStudentInfo?.ApplicantAddressCountryIdDisplay,
      // cardholderPostalCode: currentStudentInfo?.ApplicantAddressPostalCode
    } as any;


    const paymentModalOptions: PaymentModalOptions = {
      checkrideRequest: checkrideRequest,
      chargeRequest: chargeRequest
    }

    this.populateChargeRequestDetails(paymentModalOptions);

    let modalConfig = new DwModalConfig<PaymentModalOptions>();

    modalConfig.title = "Add Payment Method";
    modalConfig.component = PaymentModalComponent;
    modalConfig.buttons = DwModalButtonStandardConfigs.instance.SaveAndClose;

    modalConfig.buttons[0].buttonText = "Add payment";
    modalConfig.allowFullScreen = true;
    modalConfig.data = paymentModalOptions; 
    modalConfig.preventClose = true;

    // modalConfig.modalSize = DwModalSize.custom;
    // modalConfig.customModalWidth = '600px';
    // modalConfig.customModalHeight = '800px';

    modalConfig.modalSize = DwModalSize.responsive;

    return this.dwModalService.showModal(modalConfig);
  }

  protected populateChargeRequestDetails(paymentModalOptions: PaymentModalOptions) {
    const currentStudentInfo: StudentApplicantWithDetailQueryEntity = this.context[CurrentStudentContext]?.value;

    const chargeRequest = paymentModalOptions.chargeRequest;
    chargeRequest.cardholderName = currentStudentInfo?.UserIdDisplay;
    chargeRequest.cardholderCountry = currentStudentInfo?.ApplicantAddressCountryIdDisplay,
    chargeRequest.cardholderPostalCode = currentStudentInfo?.ApplicantAddressPostalCode,
    chargeRequest.paymentPartyId = paymentModalOptions.checkrideRequest.PilexosBookingCostPaymentPartyId;

    paymentModalOptions.paymentCompleteMessage = "Your checkride has been booked and sent to your Examiner for acceptance. <br> You will receive an e-mail when your checkride has been accepted.  <br>";
  }

}
