<dw-container [options]="finalContainerOptions" *ngIf="chartPreferences">
    <div header>
        {{chartPreferences?.containerTitle}}

        <div class="pull-right" *ngIf="chartPreferences.gridPreferences?.showGrid && !hideToggle">
            <i class="fa fa-table mr-2" title="See table" [hidden]="!showChart" (click)="toggleShowChart()"></i>
            <i class="fa fa-chart-bar mr-1" title="See chart" [hidden]="showChart" (click)="toggleShowChart()"></i>
        </div>
    </div>
    <div body class="inner-container">
        <div *ngIf="data?.length==0" class="tile-no-data-container">
            <div class="no-data-label">No Data</div>
        </div>
        <ng-container *ngIf="data?.length>0">

            <div class="chart-container" *ngIf="chartConfig && showChart">
                <p-chart [type]="chartConfig?.chartType" [data]="chartConfig?.chartData" [query]="query"
                    [options]="chartConfig?.chartOptions" (onDataSelect)="onDataSelect($event)"></p-chart>
            </div>
            <div *ngIf="!showChart">
                <dw-quick-grid [item]="query.RootTable" [query]="query" [columnPreferences]="columnPreferences"
                    [loadCount]="loadCount" [customizeActions]="true" (gridEvent)="onGridEvent($event)"></dw-quick-grid>
            </div>

            <div class="additional-text">{{additionalText}}</div>

            <ng-content>

            </ng-content>
        </ng-container>
    </div>
</dw-container>