import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-of-action-sections',
  templateUrl: './plan-of-action-sections.component.html',
  styleUrls: ['./plan-of-action-sections.component.scss']
})
export class PlanOfActionSectionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
