// tslint:disable
import { Injectable } from '@angular/core';

import { DwQuery, DwQueryBuilderGen, DwItem2, DwItemAttributeCollection, DwItemAttribute, DwItemRelationshipCollection, DwItemRelationship, DwItemDetail2, DwOrmData } from '@devwareapps/devware-cap';
import * as EnterpriseMetaData from '@devwareapps/devware-cap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/************************************************
   Generated with the Devware CAP CLI 4.4.44
   Code Gen Name   App
   API Url         https://localhost:44370

   Generated By    DEVWARE/anthony.monsees
   Generate On     8/14/2024 5:02:33 PM
************************************************/

export enum AppMetaDataItemNames {
    AcsAreaOfOperation = 'AcsAreaOfOperation',
    AcsAreaOfOperationTask = 'AcsAreaOfOperationTask',
    AcsCode = 'AcsCode',
    AcsCodeSelectionMode = 'AcsCodeSelectionMode',
    AcsCodeType = 'AcsCodeType',
    AcsTest = 'AcsTest',
    AcsTestPortion = 'AcsTestPortion',
    AcsTestStatus = 'AcsTestStatus',
    Address = 'Address',
    Airport = 'Airport',
    ApplicantPlaceholder = 'ApplicantPlaceholder',
    ApplicantPlaceholderStatus = 'ApplicantPlaceholderStatus',
    AviationAuthority = 'AviationAuthority',
    AviationAuthorityDistrictOffice = 'AviationAuthorityDistrictOffice',
    AviationTrainingProgram = 'AviationTrainingProgram',
    CalendarEventReference = 'CalendarEventReference',
    CalendarReference = 'CalendarReference',
    CalendarReferenceLog = 'CalendarReferenceLog',
    CalendarReferenceType = 'CalendarReferenceType',
    CheckRideApplicantType = 'CheckRideApplicantType',
    CheckRideCancellationType = 'CheckRideCancellationType',
    CheckRideGroup = 'CheckRideGroup',
    CheckRideOutcome = 'CheckRideOutcome',
    CheckRidePayment = 'CheckRidePayment',
    CheckRideRequest = 'CheckRideRequest',
    CheckRideRequestPaymentParty = 'CheckRideRequestPaymentParty',
    CheckRideRequestStatus = 'CheckRideRequestStatus',
    CheckRideRequestSubStatus = 'CheckRideRequestSubStatus',
    CheckRideResult = 'CheckRideResult',
    CheckRideScenario = 'CheckRideScenario',
    CheckRideScenarioTypeMap = 'CheckRideScenarioTypeMap',
    CheckRideStatusLevel = 'CheckRideStatusLevel',
    CheckRideTestType = 'CheckRideTestType',
    CheckRideType = 'CheckRideType',
    Country = 'Country',
    Duration = 'Duration',
    DwMediaGroup = 'dw_MediaGroup',
    DwRoMedia = 'dw_roMedia',
    DwUser = 'dw_User',
    DwUserType = 'dw_UserType',
    FlightSchool = 'FlightSchool',
    PaymentCustomerReference = 'PaymentCustomerReference',
    PaymentDiscountCode = 'PaymentDiscountCode',
    PaymentMethod = 'PaymentMethod',
    PaymentProvider = 'PaymentProvider',
    PaymentReference = 'PaymentReference',
    PaymentReferenceCardType = 'PaymentReferenceCardType',
    PaymentReferenceHistory = 'PaymentReferenceHistory',
    PaymentReferenceStatus = 'PaymentReferenceStatus',
    PaymentType = 'PaymentType',
    PilotCertificateType = 'PilotCertificateType',
    PilotExaminer = 'PilotExaminer',
    PilotExaminerLocation = 'PilotExaminerLocation',
    PilotExaminerLocationAirport = 'PilotExaminerLocationAirport',
    PilotExaminerLocationState = 'PilotExaminerLocationState',
    PilotExaminerLocationType = 'PilotExaminerLocationType',
    PilotExaminerStatus = 'PilotExaminerStatus',
    PlanOfAction = 'PlanOfAction',
    PlanOfActionQuestionResult = 'PlanOfActionQuestionResult',
    PlanOfActionRequiredAcsCode = 'PlanOfActionRequiredAcsCode',
    PlanOfActionResult = 'PlanOfActionResult',
    PlanOfActionSection = 'PlanOfActionSection',
    PlanOfActionSectionQuestion = 'PlanOfActionSectionQuestion',
    PlanOfActionSectionType = 'PlanOfActionSectionType',
    PlanOfActionStatus = 'PlanOfActionStatus',
    PlanOfActionStatusType = 'PlanOfActionStatusType',
    PostalCode = 'PostalCode',
    QuestionBank = 'QuestionBank',
    QuestionOutcome = 'QuestionOutcome',
    RoAirportDistances = 'roAirportDistances',
    RoCheckrideAggregateUtil = 'roCheckrideAggregateUtil',
    RoCheckrideApplicantFinalName = 'roCheckrideApplicantFinalName',
    RoCheckRidePaymentAggregate = 'roCheckRidePaymentAggregate',
    RoCheckrideRequestScheduleDateInfo = 'roCheckrideRequestScheduleDateInfo',
    RoPilotExaminerLocationCurrent = 'roPilotExaminerLocationCurrent',
    RoPilotExaminerLocationDistances = 'roPilotExaminerLocationDistances',
    RoPilotExaminerScenario = 'roPilotExaminerScenario',
    Schedule = 'Schedule',
    ScheduleDayOfWeek = 'ScheduleDayOfWeek',
    ScheduleDetail = 'ScheduleDetail',
    StateProvince = 'StateProvince',
    StudentApplicant = 'StudentApplicant',
    TimeZone = 'TimeZone',
}

export enum AppMetaDataLookups {
    AcsAreaOfOperationByAcsTest = 371,
    AcsAreaOfOperationAllItems = 353,
    AcsAreaOfOperationTaskAllItems = 354,
    AcsAreaOfOperationTaskByAreaOfOperation = 372,
    AcsCodeAllItems = 352,
    AcsCodesByAcsTest = 378,
    AcsCodeSelectionModeAllItems = 370,
    AcsCodeTypeAllItems = 351,
    AcsTestAllItems = 348,
    AcsTestPortionAllItems = 377,
    AcsTestStatusAllItems = 369,
    AddressAllItems = 273,
    AirportAllItems = 297,
    AirportWithDetail = 300,
    ApplicantPlaceholderAllItems = 344,
    ApplicantPlaceholderStatusAllItems = 343,
    AviationAuthorityAllItems = 275,
    AviationAuthorityDistrictOfficeAllItems = 341,
    AviationTrainingProgramAllItems = 327,
    CalendarEventReferenceAllItems = 314,
    CalendarReferenceAllItems = 312,
    CalendarReferenceLogAllItems = 315,
    CalendarReferenceTypeAllItems = 313,
    CheckRideApplicantTypeAllItems = 329,
    CheckRideGroupAllItems = 276,
    CheckRideOutcomeAllItems = 279,
    CheckRidePaymentAllItems = 280,
    CheckRideRequestAllItems = 281,
    CheckRideRequestCancelTypeAllItems = 338,
    CheckRideRequestPaymentPartyAllItems = 340,
    CheckRideRequestStatusAllItems = 293,
    CheckRideRequestSubStatusExaminerManualWF = 332,
    CheckRideRequestSubStatusStudent = 311,
    CheckRideRequestSubStatusAllItems = 294,
    CheckRideRequestSubStatusFullStatusDesc = 309,
    CheckRideResultAllItems = 292,
    CheckRideScenarioByExaminer = 342,
    CheckRideScenarioAllItems = 288,
    CheckRideScenarioTypeMapAllItems = 289,
    CheckRideStatusLevelAllItems = 328,
    CheckRideTestTypeAllItems = 295,
    CheckRideTypeWithGrade = 316,
    CheckRideTypeAllItems = 277,
    CheckRideTypeByGroup = 304,
    CountryAllItems = 272,
    DurationAllItems = 333,
    FlightSchoolAllItems = 345,
    MediaGroupAllItems = 201,
    PaymentCustomerReferenceAllItems = 319,
    PaymentDiscountCodeAllItems = 326,
    PaymentMethodAllItems = 290,
    PaymentProviderAllItems = 323,
    PaymentReferenceAllItems = 320,
    PaymentReferenceCardTypeAllItems = 321,
    PaymentReferenceHistoryAllItems = 324,
    PaymentReferenceStatusAllItems = 322,
    PaymentTypeAllItems = 291,
    PilotCertificateTypeAllItems = 346,
    PIlotExaminerActive = 339,
    PilotExaminerAllItems = 286,
    PilotExaminerLocationAllItems = 287,
    PilotExaminerLocationAirportAllItems = 298,
    PilotExaminerLocationStateAllItems = 308,
    PilotExaminerLocationTypeAllItems = 381,
    PilotExaminerStatusAllItems = 285,
    PlanOfActionTemplatesByAcsTest = 376,
    PlanOfActionAllItems = 368,
    PlanOfActionQuestionResultAllItems = 383,
    PlanOfActionRequiredAcsCodeAllItems = 379,
    PlanOfActionResultAllItems = 382,
    PlanOfActionSectionAllItems = 365,
    PlanOfActionSectionQuestionAllItems = 366,
    PlanOfActionSectionTypeAllItems = 364,
    PlanOfActionStatusTemplate = 375,
    PlanOfActionStatusPlan = 373,
    PlanOfActionStatusAllItems = 363,
    PlanOfActionStatusTypeAllItems = 374,
    PostalCodeAllItems = 271,
    QuestionBankAllItems = 367,
    QuestionOutcomeAllItems = 384,
    RoAirportDistancesAllItems = 347,
    RoCheckrideAggregateUtilAllItems = 336,
    RoCheckrideApplicantFinalNameAllItems = 334,
    RoCheckRidePaymentAggregateAllItems = 337,
    RoCheckrideRequestScheduleDateInfoAllItems = 335,
    RoMediaAllItems = 214,
    RoPilotExaminerLocationCurrentAllItems = 307,
    RoPilotExaminerLocationDistancesAllItems = 305,
    RoPilotExaminerScenarioAllItems = 306,
    ScheduleAllItems = 283,
    ScheduleDayOfWeekAllItems = 282,
    ScheduleDetailAllItems = 284,
    SchedulesByUser = 325,
    StateProvinceAllItems = 274,
    StateProvinceByCountry = 299,
    StateProvinceShortName = 303,
    StudentApplicantAllItems = 278,
    TimeZoneUSOnlyLongDescription = 317,
    TimeZoneAllItems = 296,
    UserAllItems = 189,
    UserTypeAllItems = 302,
}


// Generated Classes for MetaData service
export class AcsAreaOfOperationMetaData implements DwItem2<AcsAreaOfOperationEntity, AcsAreaOfOperationMetaDataAttributes,AcsAreaOfOperationMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1202;
        this.ItemDetail.itemName='AcsAreaOfOperation';
        this.ItemDetail.keyName='AcsAreaOfOperationId';
    }

    CreateEntity(): AcsAreaOfOperationEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AcsAreaOfOperationQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AcsAreaOfOperationEntity, AcsAreaOfOperationMetaDataAttributes,AcsAreaOfOperationMetaDataRelations>(query, this);
    }
     Attributes = new AcsAreaOfOperationMetaDataAttributes();
     Relations = new AcsAreaOfOperationMetaDataRelations();

}


export type AcsAreaOfOperationQueryBuilder = DwQueryBuilderGen<AcsAreaOfOperationEntity, AcsAreaOfOperationMetaDataAttributes,AcsAreaOfOperationMetaDataRelations>


export class AcsAreaOfOperationMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AcsAreaOfOperation';
    // Attributes
    AcsAreaOfOperationId = new DwItemAttribute(this.i, 'AcsAreaOfOperationId');
    AcsTestId = new DwItemAttribute(this.i, 'AcsTestId');
    AreaOfOperationName = new DwItemAttribute(this.i, 'AreaOfOperationName');
    AreaOfOperationOrder = new DwItemAttribute(this.i, 'AreaOfOperationOrder');
    AreaOfOperationCode = new DwItemAttribute(this.i, 'AreaOfOperationCode');
    AcsTestPortionId = new DwItemAttribute(this.i, 'AcsTestPortionId');
}

class AcsAreaOfOperationMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Acs Test */
            AcsTest = new DwItemRelationship('AcsTest|AreaOfOperation|AcsTestId', 'AcsTest', 'AcsTest');
            /** Relation: Acs Test Portion */
            AcsTestPortion = new DwItemRelationship('AcsTestPortion|AcsAreaOfOperation|AcsTestPortionId', 'AcsTestPortion', 'AcsTestPortion');
            /** Relation: Acs Area Of Operation Task */
            AcsAreaOfOperationTask = new DwItemRelationship('AcsAreaOfOperation|AcsAreaOfOperationTask|AcsAreaOfOperationId', 'AcsAreaOfOperationTask', 'AcsAreaOfOperationTask');
            /** Relation: Plan Of Action Section */
            PlanOfActionSection = new DwItemRelationship('AcsAreaOfOperation|PlanOfActionSection|AcsAreaOfOperationId', 'PlanOfActionSection', 'PlanOfActionSection');
    };
export class AcsAreaOfOperationTaskMetaData implements DwItem2<AcsAreaOfOperationTaskEntity, AcsAreaOfOperationTaskMetaDataAttributes,AcsAreaOfOperationTaskMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1203;
        this.ItemDetail.itemName='AcsAreaOfOperationTask';
        this.ItemDetail.keyName='AcsAreaOfOperationTaskId';
    }

    CreateEntity(): AcsAreaOfOperationTaskEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AcsAreaOfOperationTaskQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AcsAreaOfOperationTaskEntity, AcsAreaOfOperationTaskMetaDataAttributes,AcsAreaOfOperationTaskMetaDataRelations>(query, this);
    }
     Attributes = new AcsAreaOfOperationTaskMetaDataAttributes();
     Relations = new AcsAreaOfOperationTaskMetaDataRelations();

}


export type AcsAreaOfOperationTaskQueryBuilder = DwQueryBuilderGen<AcsAreaOfOperationTaskEntity, AcsAreaOfOperationTaskMetaDataAttributes,AcsAreaOfOperationTaskMetaDataRelations>


export class AcsAreaOfOperationTaskMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AcsAreaOfOperationTask';
    // Attributes
    AcsAreaOfOperationTaskId = new DwItemAttribute(this.i, 'AcsAreaOfOperationTaskId');
    AcsAreaOfOperationId = new DwItemAttribute(this.i, 'AcsAreaOfOperationId');
    TaskName = new DwItemAttribute(this.i, 'TaskName');
    TaskOrder = new DwItemAttribute(this.i, 'TaskOrder');
    TaskCode = new DwItemAttribute(this.i, 'TaskCode');
}

class AcsAreaOfOperationTaskMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Acs Area Of Operation */
            AcsAreaOfOperation = new DwItemRelationship('AcsAreaOfOperation|AcsAreaOfOperationTask|AcsAreaOfOperationId', 'AcsAreaOfOperation', 'AcsAreaOfOperation');
            /** Relation: Acs Code */
            AcsCode = new DwItemRelationship('AcsAreaOfOperationTask|AcsCode|AcsAreaOfOperationTaskId', 'AcsCode', 'AcsCode');
            /** Relation: Plan Of Action Section */
            PlanOfActionSection = new DwItemRelationship('AcsAreaOfOperationTask|PlanOfActionSection|AcsAreaOfOperationTaskId', 'PlanOfActionSection', 'PlanOfActionSection');
    };
export class AcsCodeMetaData implements DwItem2<AcsCodeEntity, AcsCodeMetaDataAttributes,AcsCodeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1201;
        this.ItemDetail.itemName='AcsCode';
        this.ItemDetail.keyName='AcsCodeId';
    }

    CreateEntity(): AcsCodeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AcsCodeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AcsCodeEntity, AcsCodeMetaDataAttributes,AcsCodeMetaDataRelations>(query, this);
    }
     Attributes = new AcsCodeMetaDataAttributes();
     Relations = new AcsCodeMetaDataRelations();

}


export type AcsCodeQueryBuilder = DwQueryBuilderGen<AcsCodeEntity, AcsCodeMetaDataAttributes,AcsCodeMetaDataRelations>


export class AcsCodeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AcsCode';
    // Attributes
    AcsCodeId = new DwItemAttribute(this.i, 'AcsCodeId');
    AscCodeTypeId = new DwItemAttribute(this.i, 'AscCodeTypeId');
    AcsCode = new DwItemAttribute(this.i, 'AcsCode');
    AcsCodeOrder = new DwItemAttribute(this.i, 'AcsCodeOrder');
    AcsAreaOfOperationTaskId = new DwItemAttribute(this.i, 'AcsAreaOfOperationTaskId');
    AscCodeDescription = new DwItemAttribute(this.i, 'AscCodeDescription');
}

class AcsCodeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Acs Area Of Operation Task */
            AcsAreaOfOperationTask = new DwItemRelationship('AcsAreaOfOperationTask|AcsCode|AcsAreaOfOperationTaskId', 'AcsAreaOfOperationTask', 'AcsAreaOfOperationTask');
            /** Relation: Acs Code Type - Code Type */
            AcsCodeTypeByAscCodeTypeId = new DwItemRelationship('AcsCodeType|AcsCode|AscCodeTypeId', 'AscCodeTypeIdAcsCodeType', 'AscCodeTypeIdAcsCodeType');
            /** Relation: Plan Of Action Required Acs Code */
            PlanOfActionRequiredAcsCode = new DwItemRelationship('AcsCode|PlanOfActionRequiredAcsCode|AscCodeId', 'PlanOfActionRequiredAcsCode', 'PlanOfActionRequiredAcsCode');
            /** Relation: Plan Of Action Section Question */
            PlanOfActionSectionQuestion = new DwItemRelationship('AcsCode|PlanOfActionSectionQuestion|AcsCodeId', 'PlanOfActionSectionQuestion', 'PlanOfActionSectionQuestion');
            /** Relation: Question Bank */
            QuestionBank = new DwItemRelationship('AcsCode|QuestionBank|AcsCodeId', 'QuestionBank', 'QuestionBank');
    };
export class AcsCodeSelectionModeMetaData implements DwItem2<AcsCodeSelectionModeEntity, AcsCodeSelectionModeMetaDataAttributes,AcsCodeSelectionModeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1219;
        this.ItemDetail.itemName='AcsCodeSelectionMode';
        this.ItemDetail.keyName='AcsCodeSelectionModeId';
    }

    CreateEntity(): AcsCodeSelectionModeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AcsCodeSelectionModeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AcsCodeSelectionModeEntity, AcsCodeSelectionModeMetaDataAttributes,AcsCodeSelectionModeMetaDataRelations>(query, this);
    }
     Attributes = new AcsCodeSelectionModeMetaDataAttributes();
     Relations = new AcsCodeSelectionModeMetaDataRelations();

}


export type AcsCodeSelectionModeQueryBuilder = DwQueryBuilderGen<AcsCodeSelectionModeEntity, AcsCodeSelectionModeMetaDataAttributes,AcsCodeSelectionModeMetaDataRelations>


export class AcsCodeSelectionModeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AcsCodeSelectionMode';
    // Attributes
    AcsCodeSelectionModeId = new DwItemAttribute(this.i, 'AcsCodeSelectionModeId');
    AcsCodeSelectionMode = new DwItemAttribute(this.i, 'AcsCodeSelectionMode');
}

class AcsCodeSelectionModeMetaDataRelations implements DwItemRelationshipCollection {
    };
export class AcsCodeTypeMetaData implements DwItem2<AcsCodeTypeEntity, AcsCodeTypeMetaDataAttributes,AcsCodeTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1200;
        this.ItemDetail.itemName='AcsCodeType';
        this.ItemDetail.keyName='AcsCodeTypeId';
    }

    CreateEntity(): AcsCodeTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AcsCodeTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AcsCodeTypeEntity, AcsCodeTypeMetaDataAttributes,AcsCodeTypeMetaDataRelations>(query, this);
    }
     Attributes = new AcsCodeTypeMetaDataAttributes();
     Relations = new AcsCodeTypeMetaDataRelations();

}


export type AcsCodeTypeQueryBuilder = DwQueryBuilderGen<AcsCodeTypeEntity, AcsCodeTypeMetaDataAttributes,AcsCodeTypeMetaDataRelations>


export class AcsCodeTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AcsCodeType';
    // Attributes
    AcsCodeTypeId = new DwItemAttribute(this.i, 'AcsCodeTypeId');
    AcsCodeTypeName = new DwItemAttribute(this.i, 'AcsCodeTypeName');
    AcsCodeTypeShortName = new DwItemAttribute(this.i, 'AcsCodeTypeShortName');
}

class AcsCodeTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Acs Code - Asc Code Type - Many */
            AcsCodeByAscCodeTypeId = new DwItemRelationship('AcsCodeType|AcsCode|AscCodeTypeId', 'AscCodeTypeIdAcsCode', 'AscCodeTypeIdAcsCode');
    };
export class AcsTestMetaData implements DwItem2<AcsTestEntity, AcsTestMetaDataAttributes,AcsTestMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1197;
        this.ItemDetail.itemName='AcsTest';
        this.ItemDetail.keyName='AcsTestId';
    }

    CreateEntity(): AcsTestEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AcsTestQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AcsTestEntity, AcsTestMetaDataAttributes,AcsTestMetaDataRelations>(query, this);
    }
     Attributes = new AcsTestMetaDataAttributes();
     Relations = new AcsTestMetaDataRelations();

}


export type AcsTestQueryBuilder = DwQueryBuilderGen<AcsTestEntity, AcsTestMetaDataAttributes,AcsTestMetaDataRelations>


export class AcsTestMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AcsTest';
    // Attributes
    AcsTestId = new DwItemAttribute(this.i, 'AcsTestId');
    TestName = new DwItemAttribute(this.i, 'TestName');
    TestDescription = new DwItemAttribute(this.i, 'TestDescription');
    CheckRideGroupId = new DwItemAttribute(this.i, 'CheckRideGroupId');
    TestCode = new DwItemAttribute(this.i, 'TestCode');
    TestExpirationDate = new DwItemAttribute(this.i, 'TestExpirationDate');
    AcsTestStatusId = new DwItemAttribute(this.i, 'AcsTestStatusId');
}

class AcsTestMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Acs Test Status */
            AcsTestStatus = new DwItemRelationship('AcsTestStatus|AcsTest|AcsTestStatusId', 'AcsTestStatus', 'AcsTestStatus');
            /** Relation: Checkride Group */
            CheckRideGroup = new DwItemRelationship('CheckRideGroup|AcsTest|CheckRideGroupId', 'CheckRideGroup', 'CheckRideGroup');
            /** Relation: Acs Area Of Operation */
            AcsAreaOfOperation = new DwItemRelationship('AcsTest|AreaOfOperation|AcsTestId', 'AcsAreaOfOperation', 'AcsAreaOfOperation');
            /** Relation: Plan Of Action */
            PlanOfAction = new DwItemRelationship('AcsTest|PlanOfAction|AcsTestId', 'PlanOfAction', 'PlanOfAction');
            /** Relation: Question Bank */
            QuestionBank = new DwItemRelationship('AcsTest|QuestionBank|AcsTestId', 'QuestionBank', 'QuestionBank');
    };
export class AcsTestPortionMetaData implements DwItem2<AcsTestPortionEntity, AcsTestPortionMetaDataAttributes,AcsTestPortionMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1221;
        this.ItemDetail.itemName='AcsTestPortion';
        this.ItemDetail.keyName='AcsTestPortionId';
    }

    CreateEntity(): AcsTestPortionEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AcsTestPortionQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AcsTestPortionEntity, AcsTestPortionMetaDataAttributes,AcsTestPortionMetaDataRelations>(query, this);
    }
     Attributes = new AcsTestPortionMetaDataAttributes();
     Relations = new AcsTestPortionMetaDataRelations();

}


export type AcsTestPortionQueryBuilder = DwQueryBuilderGen<AcsTestPortionEntity, AcsTestPortionMetaDataAttributes,AcsTestPortionMetaDataRelations>


export class AcsTestPortionMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AcsTestPortion';
    // Attributes
    AcsTestPortionId = new DwItemAttribute(this.i, 'AcsTestPortionId');
    AcsTestPortion = new DwItemAttribute(this.i, 'AcsTestPortion');
    HeaderClass = new DwItemAttribute(this.i, 'HeaderClass');
    IconClass = new DwItemAttribute(this.i, 'IconClass');
    AcsTestPortionOrder = new DwItemAttribute(this.i, 'AcsTestPortionOrder');
}

class AcsTestPortionMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Acs Area Of Operation */
            AcsAreaOfOperation = new DwItemRelationship('AcsTestPortion|AcsAreaOfOperation|AcsTestPortionId', 'AcsAreaOfOperation', 'AcsAreaOfOperation');
            /** Relation: Plan Of Action Section */
            PlanOfActionSection = new DwItemRelationship('AcsTestPortion|PlanOfActionSection|AcsTestPortionId', 'PlanOfActionSection', 'PlanOfActionSection');
            /** Relation: Plan Of Action Section Type */
            PlanOfActionSectionType = new DwItemRelationship('AcsTestPortion|PlanOfActionSectionType|AcsTestPortionId', 'PlanOfActionSectionType', 'PlanOfActionSectionType');
    };
export class AcsTestStatusMetaData implements DwItem2<AcsTestStatusEntity, AcsTestStatusMetaDataAttributes,AcsTestStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1218;
        this.ItemDetail.itemName='AcsTestStatus';
        this.ItemDetail.keyName='AcsTestStatusId';
    }

    CreateEntity(): AcsTestStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AcsTestStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AcsTestStatusEntity, AcsTestStatusMetaDataAttributes,AcsTestStatusMetaDataRelations>(query, this);
    }
     Attributes = new AcsTestStatusMetaDataAttributes();
     Relations = new AcsTestStatusMetaDataRelations();

}


export type AcsTestStatusQueryBuilder = DwQueryBuilderGen<AcsTestStatusEntity, AcsTestStatusMetaDataAttributes,AcsTestStatusMetaDataRelations>


export class AcsTestStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AcsTestStatus';
    // Attributes
    AcsTestStatusId = new DwItemAttribute(this.i, 'AcsTestStatusId');
    AcsTestStatus = new DwItemAttribute(this.i, 'AcsTestStatus');
}

class AcsTestStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Acs Test */
            AcsTest = new DwItemRelationship('AcsTestStatus|AcsTest|AcsTestStatusId', 'AcsTest', 'AcsTest');
    };
export class AddressMetaData implements DwItem2<AddressEntity, AddressMetaDataAttributes,AddressMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1157;
        this.ItemDetail.itemName='Address';
        this.ItemDetail.keyName='AddressId';
    }

    CreateEntity(): AddressEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AddressQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AddressEntity, AddressMetaDataAttributes,AddressMetaDataRelations>(query, this);
    }
     Attributes = new AddressMetaDataAttributes();
     Relations = new AddressMetaDataRelations();

}


export type AddressQueryBuilder = DwQueryBuilderGen<AddressEntity, AddressMetaDataAttributes,AddressMetaDataRelations>


export class AddressMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Address';
    // Attributes
    AddressId = new DwItemAttribute(this.i, 'AddressId');
    StreetAddress1 = new DwItemAttribute(this.i, 'StreetAddress1');
    StreetAddress2 = new DwItemAttribute(this.i, 'StreetAddress2');
    City = new DwItemAttribute(this.i, 'City');
    StateProvinceId = new DwItemAttribute(this.i, 'StateProvinceId');
    CountryId = new DwItemAttribute(this.i, 'CountryId');
    CountyName = new DwItemAttribute(this.i, 'CountyName');
    PostalCode = new DwItemAttribute(this.i, 'PostalCode');
    PostalCodePlus4 = new DwItemAttribute(this.i, 'PostalCodePlus4');
    Phone = new DwItemAttribute(this.i, 'Phone');
    Fax = new DwItemAttribute(this.i, 'Fax');
    Latitude = new DwItemAttribute(this.i, 'Latitude');
    Longitude = new DwItemAttribute(this.i, 'Longitude');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    TimezoneId = new DwItemAttribute(this.i, 'TimezoneId');
    ObservesDst = new DwItemAttribute(this.i, 'ObservesDst');
}

class AddressMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Country */
            Country = new DwItemRelationship('Country|Address|CountryId', 'Country', 'Country');
            /** Relation: State Province */
            StateProvince = new DwItemRelationship('StateProvince|Address|StateProvinceId', 'StateProvince', 'StateProvince');
            /** Relation: Time Zone */
            TimeZone = new DwItemRelationship('TimeZone|Address|TimezoneId', 'TimeZone', 'TimeZone');
            /** Relation: Airport - Airport - Many */
            AirportByAirportAddressId = new DwItemRelationship('Address|Airport|AirportAddressId', 'AirportAirport', 'AirportAirport');
            /** Relation: Student Applicant - Applicant - Many */
            StudentApplicantByApplicantAddressId = new DwItemRelationship('Address|StudentApplicant|ApplicantAddressId', 'ApplicantStudentApplicant', 'ApplicantStudentApplicant');
    };
export class AirportMetaData implements DwItem2<AirportEntity, AirportMetaDataAttributes,AirportMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1159;
        this.ItemDetail.itemName='Airport';
        this.ItemDetail.keyName='AirportId';
    }

    CreateEntity(): AirportEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AirportQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AirportEntity, AirportMetaDataAttributes,AirportMetaDataRelations>(query, this);
    }
     Attributes = new AirportMetaDataAttributes();
     Relations = new AirportMetaDataRelations();

}


export type AirportQueryBuilder = DwQueryBuilderGen<AirportEntity, AirportMetaDataAttributes,AirportMetaDataRelations>


export class AirportMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Airport';
    // Attributes
    AirportId = new DwItemAttribute(this.i, 'AirportId');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    AirportName = new DwItemAttribute(this.i, 'AirportName');
    AirportCode = new DwItemAttribute(this.i, 'AirportCode');
    AirportAddressId = new DwItemAttribute(this.i, 'AirportAddressId');
    Elevation = new DwItemAttribute(this.i, 'Elevation');
    OwnershipCode = new DwItemAttribute(this.i, 'OwnershipCode');
    IsActive = new DwItemAttribute(this.i, 'IsActive');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class AirportMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Address - Airport Address */
            AddressByAirportAddressId = new DwItemRelationship('Address|Airport|AirportAddressId', 'AirportAddress', 'AirportAddress');
            /** Relation: Aviation Authority */
            AviationAuthority = new DwItemRelationship('AviationAuthority|Airport|AviationAuthorityId', 'AviationAuthority', 'AviationAuthority');
            /** Relation: Applicant Wait List - Preferred - Many */
            ApplicantPlaceholderByPreferredAirportId = new DwItemRelationship('Airport|ApplicantPlaceholder|PreferredAirportId', 'PreferredApplicantPlaceholder', 'PreferredApplicantPlaceholder');
            /** Relation: Checkride Request - Scheduled - Many */
            CheckRideRequestByScheduledAirportId = new DwItemRelationship('Airport|CheckRideRequest|ScheduledAirportId', 'ScheduledCheckRideRequest', 'ScheduledCheckRideRequest');
            /** Relation: Checkride Request - Preferred - Many */
            CheckRideRequestByPreferredAirportId = new DwItemRelationship('Airport|CheckRideRequest|PreferredAirportId', 'PreferredCheckRideRequest', 'PreferredCheckRideRequest');
            /** Relation: Checkride Request - Proposed - Many */
            CheckRideRequestByProposedAirportId = new DwItemRelationship('Airport|CheckRideRequest|ProposedAirportId', 'ProposedCheckRideRequest', 'ProposedCheckRideRequest');
            /** Relation: Pilot Examiner Location Airport */
            PilotExaminerLocationAirport = new DwItemRelationship('Airport|PilotExaminerLocationAirport|AirportId', 'PilotExaminerLocationAirport', 'PilotExaminerLocationAirport');
            /** Relation: Ro Pilot Examiner Location Distances */
            RoPilotExaminerLocationDistances = new DwItemRelationship('RoPilotExaminerLocationDistances|Airport|AirportId', 'RoPilotExaminerLocationDistances', 'RoPilotExaminerLocationDistances');
            /** Relation: Student Applicant - Main - Many */
            StudentApplicantByMainAirportId = new DwItemRelationship('Airport|StudentApplicant|MainAirportId', 'MainStudentApplicant', 'MainStudentApplicant');
    };
export class ApplicantPlaceholderMetaData implements DwItem2<ApplicantPlaceholderEntity, ApplicantPlaceholderMetaDataAttributes,ApplicantPlaceholderMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1193;
        this.ItemDetail.itemName='ApplicantPlaceholder';
        this.ItemDetail.keyName='ApplicantPlaceholderId';
    }

    CreateEntity(): ApplicantPlaceholderEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ApplicantPlaceholderQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ApplicantPlaceholderEntity, ApplicantPlaceholderMetaDataAttributes,ApplicantPlaceholderMetaDataRelations>(query, this);
    }
     Attributes = new ApplicantPlaceholderMetaDataAttributes();
     Relations = new ApplicantPlaceholderMetaDataRelations();

}


export type ApplicantPlaceholderQueryBuilder = DwQueryBuilderGen<ApplicantPlaceholderEntity, ApplicantPlaceholderMetaDataAttributes,ApplicantPlaceholderMetaDataRelations>


export class ApplicantPlaceholderMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ApplicantPlaceholder';
    // Attributes
    ApplicantPlaceholderId = new DwItemAttribute(this.i, 'ApplicantPlaceholderId');
    ApplicantPlaceholderStatusId = new DwItemAttribute(this.i, 'ApplicantPlaceholderStatusId');
    PilotExaminerId = new DwItemAttribute(this.i, 'PilotExaminerId');
    StudentApplicantId = new DwItemAttribute(this.i, 'StudentApplicantId');
    CheckRideApplicantTypeId = new DwItemAttribute(this.i, 'CheckRideApplicantTypeId');
    StudentApplicantNameManual = new DwItemAttribute(this.i, 'StudentApplicantNameManual');
    StudentApplicantEmailManual = new DwItemAttribute(this.i, 'StudentApplicantEmailManual');
    StudentApplicantPhoneManual = new DwItemAttribute(this.i, 'StudentApplicantPhoneManual');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    CheckRideGroupId = new DwItemAttribute(this.i, 'CheckRideGroupId');
    CheckRideTypeId = new DwItemAttribute(this.i, 'CheckRideTypeId');
    PreferredAirportId = new DwItemAttribute(this.i, 'PreferredAirportId');
    PreferredDateTime = new DwItemAttribute(this.i, 'PreferredDateTime');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    PilotCertificateNumber = new DwItemAttribute(this.i, 'PilotCertificateNumber');
    FlightSchoolName = new DwItemAttribute(this.i, 'FlightSchoolName');
    FlightInstructorName = new DwItemAttribute(this.i, 'FlightInstructorName');
    PrivateNotes = new DwItemAttribute(this.i, 'PrivateNotes');
    CheckRideTestTypeId = new DwItemAttribute(this.i, 'CheckRideTestTypeId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    FlightSchoolNameOther = new DwItemAttribute(this.i, 'FlightSchoolNameOther');
    AuthorityPreCheckRideNumber = new DwItemAttribute(this.i, 'AuthorityPreCheckRideNumber');
    StudentApplicantCitizenshipCountyId = new DwItemAttribute(this.i, 'StudentApplicantCitizenshipCountyId');
    AviationTrainingProgramId = new DwItemAttribute(this.i, 'AviationTrainingProgramId');
    OriginalCheckRideRequestId = new DwItemAttribute(this.i, 'OriginalCheckRideRequestId');
    FlightInstructorEmail = new DwItemAttribute(this.i, 'FlightInstructorEmail');
    FlightInstructorPhone = new DwItemAttribute(this.i, 'FlightInstructorPhone');
    LastContactDateTime = new DwItemAttribute(this.i, 'LastContactDateTime');
    RankingDateTime = new DwItemAttribute(this.i, 'RankingDateTime');
}

class ApplicantPlaceholderMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Airport - Preferred Airport */
            AirportByPreferredAirportId = new DwItemRelationship('Airport|ApplicantPlaceholder|PreferredAirportId', 'PreferredAirport', 'PreferredAirport');
            /** Relation: Applicant Placeholder Status */
            ApplicantPlaceholderStatus = new DwItemRelationship('ApplicantPlaceholderStatus|ApplicantPlaceholder|ApplicantPlaceholderStatusId', 'ApplicantPlaceholderStatus', 'ApplicantPlaceholderStatus');
            /** Relation: Aviation Authority */
            AviationAuthority = new DwItemRelationship('AviationAuthority|ApplicantPlaceholder|AviationAuthorityId', 'AviationAuthority', 'AviationAuthority');
            /** Relation: Aviation Training Program */
            AviationTrainingProgram = new DwItemRelationship('AviationTrainingProgram|ApplicantPlaceholder|AviationTrainingProgramId', 'AviationTrainingProgram', 'AviationTrainingProgram');
            /** Relation: Check Ride Applicant Type */
            CheckRideApplicantType = new DwItemRelationship('CheckRideApplicantType|ApplicantPlaceholder|CheckRideApplicantTypeId', 'CheckRideApplicantType', 'CheckRideApplicantType');
            /** Relation: Checkride Group */
            CheckRideGroup = new DwItemRelationship('CheckRideGroup|ApplicantPlaceholder|CheckRideGroupId', 'CheckRideGroup', 'CheckRideGroup');
            /** Relation: Checkride Test Type */
            CheckRideTestType = new DwItemRelationship('CheckRideTestType|ApplicantPlaceholder|CheckRideTtestTypeId', 'CheckRideTestType', 'CheckRideTestType');
            /** Relation: Checkride Type */
            CheckRideType = new DwItemRelationship('CheckRideType|ApplicantPlaceholder|CheckRideTypeId', 'CheckRideType', 'CheckRideType');
            /** Relation: Country - Applicant Citizenship */
            CountryByStudentApplicantCitizenshipCountyId = new DwItemRelationship('Country|ApplicantPlaceholder|StudentApplicantCitizenshipCountyId', 'StudentApplicantCitizenshipCountyIdCountry', 'StudentApplicantCitizenshipCountyIdCountry');
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchool|ApplicantPlaceholder|FlightSchoolId', 'FlightSchool', 'FlightSchool');
            /** Relation: Pilot Examiner */
            PilotExaminer = new DwItemRelationship('PilotExaminer|ApplicantPlaceholder|PilotExaminerId', 'PilotExaminer', 'PilotExaminer');
            /** Relation: Student Applicant */
            StudentApplicant = new DwItemRelationship('StudentApplicant|ApplicantPlaceholder|StudentApplicantId', 'StudentApplicant', 'StudentApplicant');
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('ApplicantPlaceholder|CheckRideRequest|ApplicantPlaceholderId', 'CheckRideRequest', 'CheckRideRequest');
    };
export class ApplicantPlaceholderStatusMetaData implements DwItem2<ApplicantPlaceholderStatusEntity, ApplicantPlaceholderStatusMetaDataAttributes,ApplicantPlaceholderStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1192;
        this.ItemDetail.itemName='ApplicantPlaceholderStatus';
        this.ItemDetail.keyName='ApplicantPlaceholderStatusId';
    }

    CreateEntity(): ApplicantPlaceholderStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ApplicantPlaceholderStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ApplicantPlaceholderStatusEntity, ApplicantPlaceholderStatusMetaDataAttributes,ApplicantPlaceholderStatusMetaDataRelations>(query, this);
    }
     Attributes = new ApplicantPlaceholderStatusMetaDataAttributes();
     Relations = new ApplicantPlaceholderStatusMetaDataRelations();

}


export type ApplicantPlaceholderStatusQueryBuilder = DwQueryBuilderGen<ApplicantPlaceholderStatusEntity, ApplicantPlaceholderStatusMetaDataAttributes,ApplicantPlaceholderStatusMetaDataRelations>


export class ApplicantPlaceholderStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ApplicantPlaceholderStatus';
    // Attributes
    ApplicantPlaceholderStatusId = new DwItemAttribute(this.i, 'ApplicantPlaceholderStatusId');
    ApplicantPlaceholderStatus = new DwItemAttribute(this.i, 'ApplicantPlaceholderStatus');
}

class ApplicantPlaceholderStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Applicant Wait List */
            ApplicantPlaceholder = new DwItemRelationship('ApplicantPlaceholderStatus|ApplicantPlaceholder|ApplicantPlaceholderStatusId', 'ApplicantPlaceholder', 'ApplicantPlaceholder');
    };
export class AviationAuthorityMetaData implements DwItem2<AviationAuthorityEntity, AviationAuthorityMetaDataAttributes,AviationAuthorityMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1133;
        this.ItemDetail.itemName='AviationAuthority';
        this.ItemDetail.keyName='AviationAuthorityId';
    }

    CreateEntity(): AviationAuthorityEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AviationAuthorityQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AviationAuthorityEntity, AviationAuthorityMetaDataAttributes,AviationAuthorityMetaDataRelations>(query, this);
    }
     Attributes = new AviationAuthorityMetaDataAttributes();
     Relations = new AviationAuthorityMetaDataRelations();

}


export type AviationAuthorityQueryBuilder = DwQueryBuilderGen<AviationAuthorityEntity, AviationAuthorityMetaDataAttributes,AviationAuthorityMetaDataRelations>


export class AviationAuthorityMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AviationAuthority';
    // Attributes
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    AuthorityName = new DwItemAttribute(this.i, 'AuthorityName');
}

class AviationAuthorityMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Airport */
            Airport = new DwItemRelationship('AviationAuthority|Airport|AviationAuthorityId', 'Airport', 'Airport');
            /** Relation: Applicant Wait List */
            ApplicantPlaceholder = new DwItemRelationship('AviationAuthority|ApplicantPlaceholder|AviationAuthorityId', 'ApplicantPlaceholder', 'ApplicantPlaceholder');
            /** Relation: FSDO */
            AviationAuthorityDistrictOffice = new DwItemRelationship('AviationAuthority|AviationAuthorityDistrictOffice|AviationAuthorityId', 'AviationAuthorityDistrictOffice', 'AviationAuthorityDistrictOffice');
            /** Relation: Aviation Training Program */
            AviationTrainingProgram = new DwItemRelationship('AviationAuthority|AviationTrainingProgram|AviationAuthorityId', 'AviationTrainingProgram', 'AviationTrainingProgram');
            /** Relation: Checkride Group */
            CheckRideGroup = new DwItemRelationship('AviationAuthority|CheckRideGroup|AviationAuthorityId', 'CheckRideGroup', 'CheckRideGroup');
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('AviationAuthority|CheckRideRequest|AviationAuthorityId', 'CheckRideRequest', 'CheckRideRequest');
            /** Relation: Pilot Examiner */
            PilotExaminer = new DwItemRelationship('AviationAuthority|PilotExaminer|AviationAuthorityId', 'PilotExaminer', 'PilotExaminer');
            /** Relation: Plan Of Action */
            PlanOfAction = new DwItemRelationship('AviationAuthority|PlanOfAction|AviationAuthorityId', 'PlanOfAction', 'PlanOfAction');
            /** Relation: Student Applicant */
            StudentApplicant = new DwItemRelationship('AviationAuthority|StudentApplicant|AviationAuthorityId', 'StudentApplicant', 'StudentApplicant');
    };
export class AviationAuthorityDistrictOfficeMetaData implements DwItem2<AviationAuthorityDistrictOfficeEntity, AviationAuthorityDistrictOfficeMetaDataAttributes,AviationAuthorityDistrictOfficeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1191;
        this.ItemDetail.itemName='AviationAuthorityDistrictOffice';
        this.ItemDetail.keyName='AviationAuthorityDistrictOfficeId';
    }

    CreateEntity(): AviationAuthorityDistrictOfficeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AviationAuthorityDistrictOfficeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AviationAuthorityDistrictOfficeEntity, AviationAuthorityDistrictOfficeMetaDataAttributes,AviationAuthorityDistrictOfficeMetaDataRelations>(query, this);
    }
     Attributes = new AviationAuthorityDistrictOfficeMetaDataAttributes();
     Relations = new AviationAuthorityDistrictOfficeMetaDataRelations();

}


export type AviationAuthorityDistrictOfficeQueryBuilder = DwQueryBuilderGen<AviationAuthorityDistrictOfficeEntity, AviationAuthorityDistrictOfficeMetaDataAttributes,AviationAuthorityDistrictOfficeMetaDataRelations>


export class AviationAuthorityDistrictOfficeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AviationAuthorityDistrictOffice';
    // Attributes
    AviationAuthorityDistrictOfficeId = new DwItemAttribute(this.i, 'AviationAuthorityDistrictOfficeId');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    DistictOfficeName = new DwItemAttribute(this.i, 'DistictOfficeName');
    PhoneNumber = new DwItemAttribute(this.i, 'PhoneNumber');
}

class AviationAuthorityDistrictOfficeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Aviation Authority */
            AviationAuthority = new DwItemRelationship('AviationAuthority|AviationAuthorityDistrictOffice|AviationAuthorityId', 'AviationAuthority', 'AviationAuthority');
            /** Relation: Pilot Examiner */
            PilotExaminer = new DwItemRelationship('AviationAuthorityDistrictOffice|PilotExaminer|AviationAuthorityDistrictOfficeId', 'PilotExaminer', 'PilotExaminer');
    };
export class AviationTrainingProgramMetaData implements DwItem2<AviationTrainingProgramEntity, AviationTrainingProgramMetaDataAttributes,AviationTrainingProgramMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1181;
        this.ItemDetail.itemName='AviationTrainingProgram';
        this.ItemDetail.keyName='AviationTrainingProgramId';
    }

    CreateEntity(): AviationTrainingProgramEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): AviationTrainingProgramQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<AviationTrainingProgramEntity, AviationTrainingProgramMetaDataAttributes,AviationTrainingProgramMetaDataRelations>(query, this);
    }
     Attributes = new AviationTrainingProgramMetaDataAttributes();
     Relations = new AviationTrainingProgramMetaDataRelations();

}


export type AviationTrainingProgramQueryBuilder = DwQueryBuilderGen<AviationTrainingProgramEntity, AviationTrainingProgramMetaDataAttributes,AviationTrainingProgramMetaDataRelations>


export class AviationTrainingProgramMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AviationTrainingProgram';
    // Attributes
    AviationTrainingProgramId = new DwItemAttribute(this.i, 'AviationTrainingProgramId');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    TrainingProgramName = new DwItemAttribute(this.i, 'TrainingProgramName');
    TrainingProgramShortName = new DwItemAttribute(this.i, 'TrainingProgramShortName');
}

class AviationTrainingProgramMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Aviation Authority */
            AviationAuthority = new DwItemRelationship('AviationAuthority|AviationTrainingProgram|AviationAuthorityId', 'AviationAuthority', 'AviationAuthority');
            /** Relation: Applicant Wait List */
            ApplicantPlaceholder = new DwItemRelationship('AviationTrainingProgram|ApplicantPlaceholder|AviationTrainingProgramId', 'ApplicantPlaceholder', 'ApplicantPlaceholder');
            /** Relation: Checkride Request - Avaiation Training Program - Many */
            CheckRideRequestByAvaiationTrainingProgramId = new DwItemRelationship('AviationTrainingProgram|CheckRideRequest|AvaiationTrainingProgramId', 'AvaiationTrainingProgramIdCheckRideRequest', 'AvaiationTrainingProgramIdCheckRideRequest');
            /** Relation: Student Applicant */
            StudentApplicant = new DwItemRelationship('AviationTrainingProgram|StudentApplicant|AviationTrainingProgramId', 'StudentApplicant', 'StudentApplicant');
    };
export class CalendarEventReferenceMetaData implements DwItem2<CalendarEventReferenceEntity, CalendarEventReferenceMetaDataAttributes,CalendarEventReferenceMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1171;
        this.ItemDetail.itemName='CalendarEventReference';
        this.ItemDetail.keyName='CalendarEventReferenceId';
    }

    CreateEntity(): CalendarEventReferenceEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CalendarEventReferenceQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CalendarEventReferenceEntity, CalendarEventReferenceMetaDataAttributes,CalendarEventReferenceMetaDataRelations>(query, this);
    }
     Attributes = new CalendarEventReferenceMetaDataAttributes();
     Relations = new CalendarEventReferenceMetaDataRelations();

}


export type CalendarEventReferenceQueryBuilder = DwQueryBuilderGen<CalendarEventReferenceEntity, CalendarEventReferenceMetaDataAttributes,CalendarEventReferenceMetaDataRelations>


export class CalendarEventReferenceMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CalendarEventReference';
    // Attributes
    CalendarEventReferenceId = new DwItemAttribute(this.i, 'CalendarEventReferenceId');
    CalendarReferenceId = new DwItemAttribute(this.i, 'CalendarReferenceId');
    EventReferenceKey = new DwItemAttribute(this.i, 'EventReferenceKey');
    CalendarKey = new DwItemAttribute(this.i, 'CalendarKey');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    IsCancelled = new DwItemAttribute(this.i, 'IsCancelled');
}

class CalendarEventReferenceMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Calendar Reference */
            CalendarReference = new DwItemRelationship('CalendarReference|CalendarEventReference|CalendarReferenceId', 'CalendarReference', 'CalendarReference');
            /** Relation: Calendar Reference Log - Calendar Reference Event - Many */
            CalendarReferenceLogByCalendarReferenceEventId = new DwItemRelationship('CalendarEventReference|CalendarReferenceLog|CalendarReferenceEventId', 'CalendarReferenceEventIdCalendarReferenceLog', 'CalendarReferenceEventIdCalendarReferenceLog');
            /** Relation: Checkride Request - Scheduled - Many */
            CheckRideRequestByScheduledCalendarEventReferenceId = new DwItemRelationship('CalendarEventReference|CheckRideRequest|ScheduledCalendarEventReferenceId', 'ScheduledCheckRideRequest', 'ScheduledCheckRideRequest');
    };
export class CalendarReferenceMetaData implements DwItem2<CalendarReferenceEntity, CalendarReferenceMetaDataAttributes,CalendarReferenceMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1169;
        this.ItemDetail.itemName='CalendarReference';
        this.ItemDetail.keyName='CalendarReferenceId';
    }

    CreateEntity(): CalendarReferenceEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CalendarReferenceQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CalendarReferenceEntity, CalendarReferenceMetaDataAttributes,CalendarReferenceMetaDataRelations>(query, this);
    }
     Attributes = new CalendarReferenceMetaDataAttributes();
     Relations = new CalendarReferenceMetaDataRelations();

}


export type CalendarReferenceQueryBuilder = DwQueryBuilderGen<CalendarReferenceEntity, CalendarReferenceMetaDataAttributes,CalendarReferenceMetaDataRelations>


export class CalendarReferenceMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CalendarReference';
    // Attributes
    CalendarReferenceId = new DwItemAttribute(this.i, 'CalendarReferenceId');
    CalendarReferenceTypeId = new DwItemAttribute(this.i, 'CalendarReferenceTypeId');
    MainCalendarKey = new DwItemAttribute(this.i, 'MainCalendarKey');
    RefreshSecretKey = new DwItemAttribute(this.i, 'RefreshSecretKey');
    InitialSecretKey = new DwItemAttribute(this.i, 'InitialSecretKey');
    CalendarConfigJSON = new DwItemAttribute(this.i, 'CalendarConfigJSON');
    CalendarIsSetup = new DwItemAttribute(this.i, 'CalendarIsSetup');
    RefreshCount = new DwItemAttribute(this.i, 'RefreshCount');
    LastRefreshDateTime = new DwItemAttribute(this.i, 'LastRefreshDateTime');
    CalendarHasErrors = new DwItemAttribute(this.i, 'CalendarHasErrors');
    LastCalendarErrorDateTime = new DwItemAttribute(this.i, 'LastCalendarErrorDateTime');
    MainCalendarTimezone = new DwItemAttribute(this.i, 'MainCalendarTimezone');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class CalendarReferenceMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Calendar Reference Type */
            CalendarReferenceType = new DwItemRelationship('CalendarReferenceType|CalendarReference|CalendarReferenceTypeId', 'CalendarReferenceType', 'CalendarReferenceType');
            /** Relation: Calendar Event Reference */
            CalendarEventReference = new DwItemRelationship('CalendarReference|CalendarEventReference|CalendarReferenceId', 'CalendarEventReference', 'CalendarEventReference');
            /** Relation: Calendar Reference Log */
            CalendarReferenceLog = new DwItemRelationship('CalendarReference|CalendarReferenceLog|CalendarReferenceId', 'CalendarReferenceLog', 'CalendarReferenceLog');
            /** Relation: Pilot Examiner */
            PilotExaminer = new DwItemRelationship('CalendarReference|PilotExaminer|CalendarReferenceId', 'PilotExaminer', 'PilotExaminer');
    };
export class CalendarReferenceLogMetaData implements DwItem2<CalendarReferenceLogEntity, CalendarReferenceLogMetaDataAttributes,CalendarReferenceLogMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1172;
        this.ItemDetail.itemName='CalendarReferenceLog';
        this.ItemDetail.keyName='CalendarReferenceLogId';
    }

    CreateEntity(): CalendarReferenceLogEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CalendarReferenceLogQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CalendarReferenceLogEntity, CalendarReferenceLogMetaDataAttributes,CalendarReferenceLogMetaDataRelations>(query, this);
    }
     Attributes = new CalendarReferenceLogMetaDataAttributes();
     Relations = new CalendarReferenceLogMetaDataRelations();

}


export type CalendarReferenceLogQueryBuilder = DwQueryBuilderGen<CalendarReferenceLogEntity, CalendarReferenceLogMetaDataAttributes,CalendarReferenceLogMetaDataRelations>


export class CalendarReferenceLogMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CalendarReferenceLog';
    // Attributes
    CalendarReferenceLogId = new DwItemAttribute(this.i, 'CalendarReferenceLogId');
    CalendarReferenceId = new DwItemAttribute(this.i, 'CalendarReferenceId');
    LogId = new DwItemAttribute(this.i, 'LogId');
    CalendarReferenceEventId = new DwItemAttribute(this.i, 'CalendarReferenceEventId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class CalendarReferenceLogMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Calendar Event Reference - Calendar Reference Event */
            CalendarEventReferenceByCalendarReferenceEventId = new DwItemRelationship('CalendarEventReference|CalendarReferenceLog|CalendarReferenceEventId', 'CalendarReferenceEventIdCalendarEventReference', 'CalendarReferenceEventIdCalendarEventReference');
            /** Relation: Calendar Reference */
            CalendarReference = new DwItemRelationship('CalendarReference|CalendarReferenceLog|CalendarReferenceId', 'CalendarReference', 'CalendarReference');
    };
export class CalendarReferenceTypeMetaData implements DwItem2<CalendarReferenceTypeEntity, CalendarReferenceTypeMetaDataAttributes,CalendarReferenceTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1170;
        this.ItemDetail.itemName='CalendarReferenceType';
        this.ItemDetail.keyName='CalendarReferenceTypeId';
    }

    CreateEntity(): CalendarReferenceTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CalendarReferenceTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CalendarReferenceTypeEntity, CalendarReferenceTypeMetaDataAttributes,CalendarReferenceTypeMetaDataRelations>(query, this);
    }
     Attributes = new CalendarReferenceTypeMetaDataAttributes();
     Relations = new CalendarReferenceTypeMetaDataRelations();

}


export type CalendarReferenceTypeQueryBuilder = DwQueryBuilderGen<CalendarReferenceTypeEntity, CalendarReferenceTypeMetaDataAttributes,CalendarReferenceTypeMetaDataRelations>


export class CalendarReferenceTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CalendarReferenceType';
    // Attributes
    CalendarReferenceTypeId = new DwItemAttribute(this.i, 'CalendarReferenceTypeId');
    CalendarReferenceTypeDisplay = new DwItemAttribute(this.i, 'CalendarReferenceTypeDisplay');
    CalendarReferenceTypeMeaning = new DwItemAttribute(this.i, 'CalendarReferenceTypeMeaning');
}

class CalendarReferenceTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Calendar Reference */
            CalendarReference = new DwItemRelationship('CalendarReferenceType|CalendarReference|CalendarReferenceTypeId', 'CalendarReference', 'CalendarReference');
    };
export class CheckRideApplicantTypeMetaData implements DwItem2<CheckRideApplicantTypeEntity, CheckRideApplicantTypeMetaDataAttributes,CheckRideApplicantTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1183;
        this.ItemDetail.itemName='CheckRideApplicantType';
        this.ItemDetail.keyName='CheckRideApplicantTypeId';
    }

    CreateEntity(): CheckRideApplicantTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideApplicantTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideApplicantTypeEntity, CheckRideApplicantTypeMetaDataAttributes,CheckRideApplicantTypeMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideApplicantTypeMetaDataAttributes();
     Relations = new CheckRideApplicantTypeMetaDataRelations();

}


export type CheckRideApplicantTypeQueryBuilder = DwQueryBuilderGen<CheckRideApplicantTypeEntity, CheckRideApplicantTypeMetaDataAttributes,CheckRideApplicantTypeMetaDataRelations>


export class CheckRideApplicantTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideApplicantType';
    // Attributes
    CheckRideApplicantTypeId = new DwItemAttribute(this.i, 'CheckRideApplicantTypeId');
    ApplicantType = new DwItemAttribute(this.i, 'ApplicantType');
}

class CheckRideApplicantTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Applicant Wait List */
            ApplicantPlaceholder = new DwItemRelationship('CheckRideApplicantType|ApplicantPlaceholder|CheckRideApplicantTypeId', 'ApplicantPlaceholder', 'ApplicantPlaceholder');
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('CheckRideApplicantType|CheckRideRequest|CheckRideApplicantTypeId', 'CheckRideRequest', 'CheckRideRequest');
    };
export class CheckRideCancellationTypeMetaData implements DwItem2<CheckRideCancellationTypeEntity, CheckRideCancellationTypeMetaDataAttributes,CheckRideCancellationTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1189;
        this.ItemDetail.itemName='CheckRideCancellationType';
        this.ItemDetail.keyName='CheckRideCancellationTypeId';
    }

    CreateEntity(): CheckRideCancellationTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideCancellationTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideCancellationTypeEntity, CheckRideCancellationTypeMetaDataAttributes,CheckRideCancellationTypeMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideCancellationTypeMetaDataAttributes();
     Relations = new CheckRideCancellationTypeMetaDataRelations();

}


export type CheckRideCancellationTypeQueryBuilder = DwQueryBuilderGen<CheckRideCancellationTypeEntity, CheckRideCancellationTypeMetaDataAttributes,CheckRideCancellationTypeMetaDataRelations>


export class CheckRideCancellationTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideCancellationType';
    // Attributes
    CheckRideCancellationTypeId = new DwItemAttribute(this.i, 'CheckRideCancellationTypeId');
    CancellationTypeDisplay = new DwItemAttribute(this.i, 'CancellationTypeDisplay');
    CancelOrder = new DwItemAttribute(this.i, 'CancelOrder');
}

class CheckRideCancellationTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('CheckRideRequestCancelType|CheckRideRequest|CheckRideCancellationTypeId', 'CheckRideRequest', 'CheckRideRequest');
    };
export class CheckRideGroupMetaData implements DwItem2<CheckRideGroupEntity, CheckRideGroupMetaDataAttributes,CheckRideGroupMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1134;
        this.ItemDetail.itemName='CheckRideGroup';
        this.ItemDetail.keyName='CheckRideGroupId';
    }

    CreateEntity(): CheckRideGroupEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideGroupQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideGroupEntity, CheckRideGroupMetaDataAttributes,CheckRideGroupMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideGroupMetaDataAttributes();
     Relations = new CheckRideGroupMetaDataRelations();

}


export type CheckRideGroupQueryBuilder = DwQueryBuilderGen<CheckRideGroupEntity, CheckRideGroupMetaDataAttributes,CheckRideGroupMetaDataRelations>


export class CheckRideGroupMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideGroup';
    // Attributes
    CheckRideGroupId = new DwItemAttribute(this.i, 'CheckRideGroupId');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    CheckRideGroup = new DwItemAttribute(this.i, 'CheckRideGroup');
    GroupOrder = new DwItemAttribute(this.i, 'GroupOrder');
}

class CheckRideGroupMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Aviation Authority */
            AviationAuthority = new DwItemRelationship('AviationAuthority|CheckRideGroup|AviationAuthorityId', 'AviationAuthority', 'AviationAuthority');
            /** Relation: Acs Test */
            AcsTest = new DwItemRelationship('CheckRideGroup|AcsTest|CheckRideGroupId', 'AcsTest', 'AcsTest');
            /** Relation: Applicant Wait List */
            ApplicantPlaceholder = new DwItemRelationship('CheckRideGroup|ApplicantPlaceholder|CheckRideGroupId', 'ApplicantPlaceholder', 'ApplicantPlaceholder');
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('CheckRideGroup|CheckRideRequest|CheckRideGroupId', 'CheckRideRequest', 'CheckRideRequest');
            /** Relation: Exam Type */
            CheckRideScenario = new DwItemRelationship('CheckRideGroup|CheckRideScenario|CheckRideGroupId', 'CheckRideScenario', 'CheckRideScenario');
            /** Relation: Checkride Type */
            CheckRideType = new DwItemRelationship('CheckRideGroup|CheckRideType|CheckRideGroupId', 'CheckRideType', 'CheckRideType');
    };
export class CheckRideOutcomeMetaData implements DwItem2<CheckRideOutcomeEntity, CheckRideOutcomeMetaDataAttributes,CheckRideOutcomeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1148;
        this.ItemDetail.itemName='CheckRideOutcome';
        this.ItemDetail.keyName='CheckRideOutcomeId';
    }

    CreateEntity(): CheckRideOutcomeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideOutcomeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideOutcomeEntity, CheckRideOutcomeMetaDataAttributes,CheckRideOutcomeMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideOutcomeMetaDataAttributes();
     Relations = new CheckRideOutcomeMetaDataRelations();

}


export type CheckRideOutcomeQueryBuilder = DwQueryBuilderGen<CheckRideOutcomeEntity, CheckRideOutcomeMetaDataAttributes,CheckRideOutcomeMetaDataRelations>


export class CheckRideOutcomeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideOutcome';
    // Attributes
    CheckRideOutcomeId = new DwItemAttribute(this.i, 'CheckRideOutcomeId');
    CheckRideOutcome = new DwItemAttribute(this.i, 'CheckRideOutcome');
    AllowRetest = new DwItemAttribute(this.i, 'AllowRetest');
    FlagStudentApplication = new DwItemAttribute(this.i, 'FlagStudentApplication');
    FlagStudentNotes = new DwItemAttribute(this.i, 'FlagStudentNotes');
}

class CheckRideOutcomeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Result */
            CheckRideResult = new DwItemRelationship('CheckRideOutcome|CheckRideResult|CheckRideOutcomeId', 'CheckRideResult', 'CheckRideResult');
            /** Relation: Plan Of Action Result */
            PlanOfActionResult = new DwItemRelationship('CheckRideOutcome|PlanOfActionResult|CheckRideOutcomeId', 'PlanOfActionResult', 'PlanOfActionResult');
    };
export class CheckRidePaymentMetaData implements DwItem2<CheckRidePaymentEntity, CheckRidePaymentMetaDataAttributes,CheckRidePaymentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1152;
        this.ItemDetail.itemName='CheckRidePayment';
        this.ItemDetail.keyName='CheckRidePaymentId';
    }

    CreateEntity(): CheckRidePaymentEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRidePaymentQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRidePaymentEntity, CheckRidePaymentMetaDataAttributes,CheckRidePaymentMetaDataRelations>(query, this);
    }
     Attributes = new CheckRidePaymentMetaDataAttributes();
     Relations = new CheckRidePaymentMetaDataRelations();

}


export type CheckRidePaymentQueryBuilder = DwQueryBuilderGen<CheckRidePaymentEntity, CheckRidePaymentMetaDataAttributes,CheckRidePaymentMetaDataRelations>


export class CheckRidePaymentMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRidePayment';
    // Attributes
    CheckRidePaymentId = new DwItemAttribute(this.i, 'CheckRidePaymentId');
    PaymentTypeId = new DwItemAttribute(this.i, 'PaymentTypeId');
    PaymentMethodId = new DwItemAttribute(this.i, 'PaymentMethodId');
    PaymentDateTime = new DwItemAttribute(this.i, 'PaymentDateTime');
    PaymentAmount = new DwItemAttribute(this.i, 'PaymentAmount');
    CheckRideRequestId = new DwItemAttribute(this.i, 'CheckRideRequestId');
    PaymentReferenceId = new DwItemAttribute(this.i, 'PaymentReferenceId');
    PaymentReferenceText = new DwItemAttribute(this.i, 'PaymentReferenceText');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    PaymentDiscountid = new DwItemAttribute(this.i, 'PaymentDiscountid');
    PaymentPartyId = new DwItemAttribute(this.i, 'PaymentPartyId');
    EmailCCReceipt = new DwItemAttribute(this.i, 'EmailCCReceipt');
}

class CheckRidePaymentMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('CheckRideRequest|CheckRidePayment|CheckRideRequestId', 'CheckRideRequest', 'CheckRideRequest');
            /** Relation: Check Ride Request Payment Party - Payment Party */
            CheckRideRequestPaymentPartyByPaymentPartyId = new DwItemRelationship('CheckRideRequestPaymentParty|CheckRidePayment|PaymentPartyId', 'PaymentPartyIdCheckRideRequestPaymentParty', 'PaymentPartyIdCheckRideRequestPaymentParty');
            /** Relation: Payment Discount Code - Payment Discount */
            PaymentDiscountCodeByPaymentDiscountid = new DwItemRelationship('PaymentDiscountCode|CheckRidePayment|PaymentDiscountid', 'PaymentDiscountidPaymentDiscountCode', 'PaymentDiscountidPaymentDiscountCode');
            /** Relation: Payment Method */
            PaymentMethod = new DwItemRelationship('PaymentMethod|CheckRidePayment|PaymentMethodId', 'PaymentMethod', 'PaymentMethod');
            /** Relation: Payment Reference */
            PaymentReference = new DwItemRelationship('PaymentReference|CheckRidePayment|PaymentReferenceId', 'PaymentReference', 'PaymentReference');
            /** Relation: Payment Type */
            PaymentType = new DwItemRelationship('PaymentType|CheckRidePayment|PaymentTypeId', 'PaymentType', 'PaymentType');
            /** Relation: Checkride Request - Checkride Payment Id - Many */
            CheckRideRequestByPilexosBookingCheckRidePaymentId = new DwItemRelationship('CheckRidePayment|CheckRideRequest|PilexosBookingCheckRidePaymentId', 'PilexosBookingCheckRideRequest', 'PilexosBookingCheckRideRequest');
    };
export class CheckRideRequestMetaData implements DwItem2<CheckRideRequestEntity, CheckRideRequestMetaDataAttributes,CheckRideRequestMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1153;
        this.ItemDetail.itemName='CheckRideRequest';
        this.ItemDetail.keyName='CheckRideRequestId';
    }

    CreateEntity(): CheckRideRequestEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideRequestQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideRequestEntity, CheckRideRequestMetaDataAttributes,CheckRideRequestMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideRequestMetaDataAttributes();
     Relations = new CheckRideRequestMetaDataRelations();

}


export type CheckRideRequestQueryBuilder = DwQueryBuilderGen<CheckRideRequestEntity, CheckRideRequestMetaDataAttributes,CheckRideRequestMetaDataRelations>


export class CheckRideRequestMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideRequest';
    // Attributes
    CheckRideRequestId = new DwItemAttribute(this.i, 'CheckRideRequestId');
    StudentApplicantId = new DwItemAttribute(this.i, 'StudentApplicantId');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    CheckRideTypeId = new DwItemAttribute(this.i, 'CheckRideTypeId');
    CheckRideTestTypeId = new DwItemAttribute(this.i, 'CheckRideTestTypeId');
    PilotCertificateNumber = new DwItemAttribute(this.i, 'PilotCertificateNumber');
    CheckRideRequestSubStatusId = new DwItemAttribute(this.i, 'CheckRideRequestSubStatusId');
    PreferredDateTime = new DwItemAttribute(this.i, 'PreferredDateTime');
    ScheduledTimeMinutes = new DwItemAttribute(this.i, 'ScheduledTimeMinutes');
    ScheduledPilotExaminerId = new DwItemAttribute(this.i, 'ScheduledPilotExaminerId');
    ScheduledPreTimeMinutes = new DwItemAttribute(this.i, 'ScheduledPreTimeMinutes');
    ScheduledPostTimeMinutes = new DwItemAttribute(this.i, 'ScheduledPostTimeMinutes');
    AuthorityPreCheckRideNumber = new DwItemAttribute(this.i, 'AuthorityPreCheckRideNumber');
    AirplaneMakeModel = new DwItemAttribute(this.i, 'AirplaneMakeModel');
    AirplaneNNumber = new DwItemAttribute(this.i, 'AirplaneNNumber');
    DocumentsMediaGroupId = new DwItemAttribute(this.i, 'DocumentsMediaGroupId');
    AuthorityPreapprovalCode = new DwItemAttribute(this.i, 'AuthorityPreapprovalCode');
    CheckRideGroupId = new DwItemAttribute(this.i, 'CheckRideGroupId');
    CancellationNotes = new DwItemAttribute(this.i, 'CancellationNotes');
    PreferredAirportId = new DwItemAttribute(this.i, 'PreferredAirportId');
    ScheduledAirportId = new DwItemAttribute(this.i, 'ScheduledAirportId');
    ScheduledDateTime = new DwItemAttribute(this.i, 'ScheduledDateTime');
    ScheduledCheckRideScenarioId = new DwItemAttribute(this.i, 'ScheduledCheckRideScenarioId');
    MedicalCertificateMediaId = new DwItemAttribute(this.i, 'MedicalCertificateMediaId');
    LogBookMediaId = new DwItemAttribute(this.i, 'LogBookMediaId');
    AdditionalDocumentationNotes = new DwItemAttribute(this.i, 'AdditionalDocumentationNotes');
    TrainingMediaId = new DwItemAttribute(this.i, 'TrainingMediaId');
    ScheduledCalendarEventReferenceId = new DwItemAttribute(this.i, 'ScheduledCalendarEventReferenceId');
    EndorsedAndCheckRideReady = new DwItemAttribute(this.i, 'EndorsedAndCheckRideReady');
    ScheduledPilotExaminerLocationId = new DwItemAttribute(this.i, 'ScheduledPilotExaminerLocationId');
    ProposedDateTime = new DwItemAttribute(this.i, 'ProposedDateTime');
    ProposedAirportId = new DwItemAttribute(this.i, 'ProposedAirportId');
    ProposedTimeMinutes = new DwItemAttribute(this.i, 'ProposedTimeMinutes');
    ProposedCalendarEventReferenceId = new DwItemAttribute(this.i, 'ProposedCalendarEventReferenceId');
    ScheduledCheckRideCost = new DwItemAttribute(this.i, 'ScheduledCheckRideCost');
    AdditionalTravelCost = new DwItemAttribute(this.i, 'AdditionalTravelCost');
    PilexosBookingCost = new DwItemAttribute(this.i, 'PilexosBookingCost');
    ExaminerBookingCost = new DwItemAttribute(this.i, 'ExaminerBookingCost');
    RejectedNotes = new DwItemAttribute(this.i, 'RejectedNotes');
    MoreInfoRequestedNotes = new DwItemAttribute(this.i, 'MoreInfoRequestedNotes');
    PilexosBookingPaymentAdded = new DwItemAttribute(this.i, 'PilexosBookingPaymentAdded');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    AvaiationTrainingProgramId = new DwItemAttribute(this.i, 'AvaiationTrainingProgramId');
    CheckRideApplicantTypeId = new DwItemAttribute(this.i, 'CheckRideApplicantTypeId');
    StudentApplicantNameManual = new DwItemAttribute(this.i, 'StudentApplicantNameManual');
    StudentApplicantEmailManual = new DwItemAttribute(this.i, 'StudentApplicantEmailManual');
    StudentApplicantPhoneManual = new DwItemAttribute(this.i, 'StudentApplicantPhoneManual');
    ExaminerPrivateNotes = new DwItemAttribute(this.i, 'ExaminerPrivateNotes');
    ExaminerToApplicantNotes = new DwItemAttribute(this.i, 'ExaminerToApplicantNotes');
    ExaminerToApplicantMediaGroupId = new DwItemAttribute(this.i, 'ExaminerToApplicantMediaGroupId');
    IsAirplaneAirworthy = new DwItemAttribute(this.i, 'IsAirplaneAirworthy');
    EmailApplicantReceipt = new DwItemAttribute(this.i, 'EmailApplicantReceipt');
    CheckRideCancellationTypeId = new DwItemAttribute(this.i, 'CheckRideCancellationTypeId');
    CheckRideCancellationTypeOther = new DwItemAttribute(this.i, 'CheckRideCancellationTypeOther');
    PilexosBookingCostPaymentPartyId = new DwItemAttribute(this.i, 'PilexosBookingCostPaymentPartyId');
    FlightSchoolName = new DwItemAttribute(this.i, 'FlightSchoolName');
    EmailCCReceipt = new DwItemAttribute(this.i, 'EmailCCReceipt');
    CancelledRequestBookingFeeRefund = new DwItemAttribute(this.i, 'CancelledRequestBookingFeeRefund');
    PilexosBookingCheckRidePaymentId = new DwItemAttribute(this.i, 'PilexosBookingCheckRidePaymentId');
    ScheduleAirportLocationNotes = new DwItemAttribute(this.i, 'ScheduleAirportLocationNotes');
    AuthorityApprovalCode = new DwItemAttribute(this.i, 'AuthorityApprovalCode');
    FlightInstructorName = new DwItemAttribute(this.i, 'FlightInstructorName');
    ApplicantPlaceholderId = new DwItemAttribute(this.i, 'ApplicantPlaceholderId');
    AddToWaitlist = new DwItemAttribute(this.i, 'AddToWaitlist');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    FlightSchoolNameOther = new DwItemAttribute(this.i, 'FlightSchoolNameOther');
    StudentApplicantCitizenshipCountyId = new DwItemAttribute(this.i, 'StudentApplicantCitizenshipCountyId');
    AirmanKnowledgeTestScore = new DwItemAttribute(this.i, 'AirmanKnowledgeTestScore');
    FlightInstructorCertificateNumber = new DwItemAttribute(this.i, 'FlightInstructorCertificateNumber');
    CurrentPilotCertifiateTypeId = new DwItemAttribute(this.i, 'CurrentPilotCertifiateTypeId');
    FlightInstructorEmail = new DwItemAttribute(this.i, 'FlightInstructorEmail');
    FlightInstructorPhone = new DwItemAttribute(this.i, 'FlightInstructorPhone');
}

class CheckRideRequestMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Airport - Checkride Airport */
            AirportByScheduledAirportId = new DwItemRelationship('Airport|CheckRideRequest|ScheduledAirportId', 'ScheduledAirport', 'ScheduledAirport');
            /** Relation: Airport - Preferred Airport */
            AirportByPreferredAirportId = new DwItemRelationship('Airport|CheckRideRequest|PreferredAirportId', 'PreferredAirport', 'PreferredAirport');
            /** Relation: Airport - Proposed Airport */
            AirportByProposedAirportId = new DwItemRelationship('Airport|CheckRideRequest|ProposedAirportId', 'ProposedAirport', 'ProposedAirport');
            /** Relation: Applicant Wait List */
            ApplicantPlaceholder = new DwItemRelationship('ApplicantPlaceholder|CheckRideRequest|ApplicantPlaceholderId', 'ApplicantPlaceholder', 'ApplicantPlaceholder');
            /** Relation: Aviation Authority */
            AviationAuthority = new DwItemRelationship('AviationAuthority|CheckRideRequest|AviationAuthorityId', 'AviationAuthority', 'AviationAuthority');
            /** Relation: Aviation Training Program - Program Type */
            AviationTrainingProgramByAvaiationTrainingProgramId = new DwItemRelationship('AviationTrainingProgram|CheckRideRequest|AvaiationTrainingProgramId', 'AvaiationTrainingProgramIdAviationTrainingProgram', 'AvaiationTrainingProgramIdAviationTrainingProgram');
            /** Relation: Calendar Event Reference - Calendar Event Reference */
            CalendarEventReferenceByScheduledCalendarEventReferenceId = new DwItemRelationship('CalendarEventReference|CheckRideRequest|ScheduledCalendarEventReferenceId', 'ScheduledCalendarEventReference', 'ScheduledCalendarEventReference');
            /** Relation: Check Ride Applicant Type */
            CheckRideApplicantType = new DwItemRelationship('CheckRideApplicantType|CheckRideRequest|CheckRideApplicantTypeId', 'CheckRideApplicantType', 'CheckRideApplicantType');
            /** Relation: Check Ride Cancellation Type */
            CheckRideCancellationType = new DwItemRelationship('CheckRideRequestCancelType|CheckRideRequest|CheckRideCancellationTypeId', 'CheckRideCancellationType', 'CheckRideCancellationType');
            /** Relation: Checkride Group */
            CheckRideGroup = new DwItemRelationship('CheckRideGroup|CheckRideRequest|CheckRideGroupId', 'CheckRideGroup', 'CheckRideGroup');
            /** Relation: Checkride Payment - Pilexos Booking Check Ride Payment */
            CheckRidePaymentByPilexosBookingCheckRidePaymentId = new DwItemRelationship('CheckRidePayment|CheckRideRequest|PilexosBookingCheckRidePaymentId', 'PilexosBookingCheckRidePayment', 'PilexosBookingCheckRidePayment');
            /** Relation: Check Ride Request Payment Party - Pilexos Booking Cost Responsible Party */
            CheckRideRequestPaymentPartyByPilexosBookingCostPaymentPartyId = new DwItemRelationship('CheckRideRequestPaymentParty|CheckRideRequest|PilexosBookingCostPaymentPartyId', 'PilexosBookingCostPaymentPartyIdCheckRideRequestPaymentParty', 'PilexosBookingCostPaymentPartyIdCheckRideRequestPaymentParty');
            /** Relation: Checkride Request Sub Status */
            CheckRideRequestSubStatus = new DwItemRelationship('CheckRideRequestSubStatus|CheckRideRequest|CheckRideRequestSubStatusId', 'CheckRideRequestSubStatus', 'CheckRideRequestSubStatus');
            /** Relation: Exam Type - Checkride Exam Type */
            CheckRideScenarioByScheduledCheckRideScenarioId = new DwItemRelationship('CheckRideScenario|CheckRideRequest|ScheduledCheckRideScenarioId', 'ScheduledCheckRideScenario', 'ScheduledCheckRideScenario');
            /** Relation: Checkride Test Type */
            CheckRideTestType = new DwItemRelationship('CheckRideTestType|CheckRideRequest|CheckRideTestTypeId', 'CheckRideTestType', 'CheckRideTestType');
            /** Relation: Checkride Type */
            CheckRideType = new DwItemRelationship('CheckRideType|CheckRideRequest|CheckRideTypeId', 'CheckRideType', 'CheckRideType');
            /** Relation: Country - Applicant Citizenship */
            CountryByStudentApplicantCitizenshipCountyId = new DwItemRelationship('Country|CheckRideRequest|StudentApplicantCitizenshipCountyId', 'StudentApplicantCitizenshipCountyIdCountry', 'StudentApplicantCitizenshipCountyIdCountry');
            /** Relation: Flight School */
            FlightSchool = new DwItemRelationship('FlightSchool|CheckRideRequest|FlightSchoolId', 'FlightSchool', 'FlightSchool');
            /** Relation: Media Group - Documents Media Group */
            DwMediaGroupByDocumentsMediaGroupId = new DwItemRelationship('dw_MediaGroup|CheckRideRequest|DocumentsMediaGroupId', 'DocumentsDwMediaGroup', 'DocumentsDwMediaGroup');
            /** Relation: Pilot Certificate Type - Current Pilot Certifiate Type */
            PilotCertificateTypeByCurrentPilotCertifiateTypeId = new DwItemRelationship('PilotCertificateType|CheckRideRequest|CurrentPilotCertifiateTypeId', 'CurrentPilotCertifiateTypeIdPilotCertificateType', 'CurrentPilotCertifiateTypeIdPilotCertificateType');
            /** Relation: Pilot Examiner - Assigned Pilot Examiner */
            PilotExaminerByScheduledPilotExaminerId = new DwItemRelationship('PilotExaminer|CheckRideRequest|ScheduledPilotExaminerId', 'ScheduledPilotExaminer', 'ScheduledPilotExaminer');
            /** Relation: Pilot Examiner Location - Assigned Pilot Examiner Location */
            PilotExaminerLocationByScheduledPilotExaminerLocationId = new DwItemRelationship('PilotExaminerLocation|CheckRideRequest|ScheduledPilotExaminerLocationId', 'ScheduledPilotExaminerLocation', 'ScheduledPilotExaminerLocation');
            /** Relation: Student Applicant */
            StudentApplicant = new DwItemRelationship('StudentApplicant|CheckRideRequest|StudentApplicantId', 'StudentApplicant', 'StudentApplicant');
            /** Relation: Checkride Payment */
            CheckRidePayment = new DwItemRelationship('CheckRideRequest|CheckRidePayment|CheckRideRequestId', 'CheckRidePayment', 'CheckRidePayment');
            /** Relation: Checkride Result */
            CheckRideResult = new DwItemRelationship('CheckRideRequest|CheckRideResult|CheckRideRequestId', 'CheckRideResult', 'CheckRideResult');
            /** Relation: Plan Of Action */
            PlanOfAction = new DwItemRelationship('CheckRideRequest|PlanOfAction|CheckRideRequestId', 'PlanOfAction', 'PlanOfAction');
            /** Relation: Ro Checkride Aggregate Util - Checkride Request Id */
            RoCheckrideAggregateUtilByCheckrideRequestId = new DwItemRelationship('CheckRideRequest|roCheckRideAggregateUtil|CheckRideRequestId', 'CheckrideRequestIdRoCheckrideAggregateUtil', 'CheckrideRequestIdRoCheckrideAggregateUtil');
            /** Relation: Ro Checkride Applicant Final Name */
            RoCheckrideApplicantFinalName = new DwItemRelationship('CheckRideRequest|roCheckRideApplicanetFinalName|CheckRideRequestId', 'RoCheckrideApplicantFinalName', 'RoCheckrideApplicantFinalName');
            /** Relation: Ro Checkride Request Schedule Date Info - Checkride Request Id */
            RoCheckrideRequestScheduleDateInfoByCheckrideRequestId = new DwItemRelationship('CheckrideRequest|roCheckrideRequestScheduleDateInfo|CheckRideRequestId', 'CheckrideRequestIdRoCheckrideRequestScheduleDateInfo', 'CheckrideRequestIdRoCheckrideRequestScheduleDateInfo');
    };
export class CheckRideRequestPaymentPartyMetaData implements DwItem2<CheckRideRequestPaymentPartyEntity, CheckRideRequestPaymentPartyMetaDataAttributes,CheckRideRequestPaymentPartyMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1190;
        this.ItemDetail.itemName='CheckRideRequestPaymentParty';
        this.ItemDetail.keyName='CheckRideRequestPaymentPartyId';
    }

    CreateEntity(): CheckRideRequestPaymentPartyEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideRequestPaymentPartyQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideRequestPaymentPartyEntity, CheckRideRequestPaymentPartyMetaDataAttributes,CheckRideRequestPaymentPartyMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideRequestPaymentPartyMetaDataAttributes();
     Relations = new CheckRideRequestPaymentPartyMetaDataRelations();

}


export type CheckRideRequestPaymentPartyQueryBuilder = DwQueryBuilderGen<CheckRideRequestPaymentPartyEntity, CheckRideRequestPaymentPartyMetaDataAttributes,CheckRideRequestPaymentPartyMetaDataRelations>


export class CheckRideRequestPaymentPartyMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideRequestPaymentParty';
    // Attributes
    CheckRideRequestPaymentPartyId = new DwItemAttribute(this.i, 'CheckRideRequestPaymentPartyId');
    PaymentPartyDisplay = new DwItemAttribute(this.i, 'PaymentPartyDisplay');
    IsActive = new DwItemAttribute(this.i, 'IsActive');
    PaymentPartyDescription = new DwItemAttribute(this.i, 'PaymentPartyDescription');
    PilexosBookingCost = new DwItemAttribute(this.i, 'PilexosBookingCost');
    PilexosBookingRetestCost = new DwItemAttribute(this.i, 'PilexosBookingRetestCost');
}

class CheckRideRequestPaymentPartyMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Payment - Payment Party - Many */
            CheckRidePaymentByPaymentPartyId = new DwItemRelationship('CheckRideRequestPaymentParty|CheckRidePayment|PaymentPartyId', 'PaymentPartyIdCheckRidePayment', 'PaymentPartyIdCheckRidePayment');
            /** Relation: Checkride Request - Pilexos Booking Cost Payment Party - Many */
            CheckRideRequestByPilexosBookingCostPaymentPartyId = new DwItemRelationship('CheckRideRequestPaymentParty|CheckRideRequest|PilexosBookingCostPaymentPartyId', 'PilexosBookingCostPaymentPartyIdCheckRideRequest', 'PilexosBookingCostPaymentPartyIdCheckRideRequest');
    };
export class CheckRideRequestStatusMetaData implements DwItem2<CheckRideRequestStatusEntity, CheckRideRequestStatusMetaDataAttributes,CheckRideRequestStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1145;
        this.ItemDetail.itemName='CheckRideRequestStatus';
        this.ItemDetail.keyName='CheckRideRequestStatusId';
    }

    CreateEntity(): CheckRideRequestStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideRequestStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideRequestStatusEntity, CheckRideRequestStatusMetaDataAttributes,CheckRideRequestStatusMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideRequestStatusMetaDataAttributes();
     Relations = new CheckRideRequestStatusMetaDataRelations();

}


export type CheckRideRequestStatusQueryBuilder = DwQueryBuilderGen<CheckRideRequestStatusEntity, CheckRideRequestStatusMetaDataAttributes,CheckRideRequestStatusMetaDataRelations>


export class CheckRideRequestStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideRequestStatus';
    // Attributes
    CheckRideRequestStatusId = new DwItemAttribute(this.i, 'CheckRideRequestStatusId');
    CheckRideRequestStatus = new DwItemAttribute(this.i, 'CheckRideRequestStatus');
}

class CheckRideRequestStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Request Sub Status */
            CheckRideRequestSubStatus = new DwItemRelationship('CheckRideRequestStatus|CheckRideRequestSubStatus|CheckRideRequestStatusId', 'CheckRideRequestSubStatus', 'CheckRideRequestSubStatus');
    };
export class CheckRideRequestSubStatusMetaData implements DwItem2<CheckRideRequestSubStatusEntity, CheckRideRequestSubStatusMetaDataAttributes,CheckRideRequestSubStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1146;
        this.ItemDetail.itemName='CheckRideRequestSubStatus';
        this.ItemDetail.keyName='CheckRideRequestSubStatusId';
    }

    CreateEntity(): CheckRideRequestSubStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideRequestSubStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideRequestSubStatusEntity, CheckRideRequestSubStatusMetaDataAttributes,CheckRideRequestSubStatusMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideRequestSubStatusMetaDataAttributes();
     Relations = new CheckRideRequestSubStatusMetaDataRelations();

}


export type CheckRideRequestSubStatusQueryBuilder = DwQueryBuilderGen<CheckRideRequestSubStatusEntity, CheckRideRequestSubStatusMetaDataAttributes,CheckRideRequestSubStatusMetaDataRelations>


export class CheckRideRequestSubStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideRequestSubStatus';
    // Attributes
    CheckRideRequestSubStatusId = new DwItemAttribute(this.i, 'CheckRideRequestSubStatusId');
    CheckRideRequestStatusId = new DwItemAttribute(this.i, 'CheckRideRequestStatusId');
    CheckRideRequestSubStatus = new DwItemAttribute(this.i, 'CheckRideRequestSubStatus');
    WorkflowStatusDetailId = new DwItemAttribute(this.i, 'WorkflowStatusDetailId');
    ApplicantNotes = new DwItemAttribute(this.i, 'ApplicantNotes');
    ExaminerNotes = new DwItemAttribute(this.i, 'ExaminerNotes');
    ApplicantStatusLevelId = new DwItemAttribute(this.i, 'ApplicantStatusLevelId');
    ExaminerStatusLevelId = new DwItemAttribute(this.i, 'ExaminerStatusLevelId');
    ApplicantAllowEdit = new DwItemAttribute(this.i, 'ApplicantAllowEdit');
    ApplicantAllowExaminerChange = new DwItemAttribute(this.i, 'ApplicantAllowExaminerChange');
    ExaminerAllowEdit = new DwItemAttribute(this.i, 'ExaminerAllowEdit');
    OutcomeIsRequested = new DwItemAttribute(this.i, 'OutcomeIsRequested');
    OutcomeIsCancelled = new DwItemAttribute(this.i, 'OutcomeIsCancelled');
    OutcomeIsBooked = new DwItemAttribute(this.i, 'OutcomeIsBooked');
    OutcomeIsComplete = new DwItemAttribute(this.i, 'OutcomeIsComplete');
}

class CheckRideRequestSubStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Request Status */
            CheckRideRequestStatus = new DwItemRelationship('CheckRideRequestStatus|CheckRideRequestSubStatus|CheckRideRequestStatusId', 'CheckRideRequestStatus', 'CheckRideRequestStatus');
            /** Relation: Check Ride Status Level - Applicant Status Level */
            CheckRideStatusLevelByApplicantStatusLevelId = new DwItemRelationship('CheckRideStatusLevel|CheckRideRequestSubStatus|ApplicantStatusLevelId', 'ApplicantStatusLevelIdCheckRideStatusLevel', 'ApplicantStatusLevelIdCheckRideStatusLevel');
            /** Relation: Check Ride Status Level - Examiner Status Level */
            CheckRideStatusLevelByExaminerStatusLevelId = new DwItemRelationship('CheckRideStatusLevel|CheckRideRequestSubStatus|ExaminerStatusLevelId', 'ExaminerStatusLevelIdCheckRideStatusLevel', 'ExaminerStatusLevelIdCheckRideStatusLevel');
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('CheckRideRequestSubStatus|CheckRideRequest|CheckRideRequestSubStatusId', 'CheckRideRequest', 'CheckRideRequest');
    };
export class CheckRideResultMetaData implements DwItem2<CheckRideResultEntity, CheckRideResultMetaDataAttributes,CheckRideResultMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1151;
        this.ItemDetail.itemName='CheckRideResult';
        this.ItemDetail.keyName='CheckRideResultId';
    }

    CreateEntity(): CheckRideResultEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideResultQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideResultEntity, CheckRideResultMetaDataAttributes,CheckRideResultMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideResultMetaDataAttributes();
     Relations = new CheckRideResultMetaDataRelations();

}


export type CheckRideResultQueryBuilder = DwQueryBuilderGen<CheckRideResultEntity, CheckRideResultMetaDataAttributes,CheckRideResultMetaDataRelations>


export class CheckRideResultMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideResult';
    // Attributes
    CheckRideResultId = new DwItemAttribute(this.i, 'CheckRideResultId');
    CheckRideRequestId = new DwItemAttribute(this.i, 'CheckRideRequestId');
    CheckRideDateTime = new DwItemAttribute(this.i, 'CheckRideDateTime');
    CheckRideOutcomeId = new DwItemAttribute(this.i, 'CheckRideOutcomeId');
    AllowRetest = new DwItemAttribute(this.i, 'AllowRetest');
    AllowRetestReason = new DwItemAttribute(this.i, 'AllowRetestReason');
    RetestCost = new DwItemAttribute(this.i, 'RetestCost');
    FlagApplicant = new DwItemAttribute(this.i, 'FlagApplicant');
    FlagApplicantNotes = new DwItemAttribute(this.i, 'FlagApplicantNotes');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    AddToWaitlist = new DwItemAttribute(this.i, 'AddToWaitlist');
    GroundPortionComplete = new DwItemAttribute(this.i, 'GroundPortionComplete');
}

class CheckRideResultMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Outcome */
            CheckRideOutcome = new DwItemRelationship('CheckRideOutcome|CheckRideResult|CheckRideOutcomeId', 'CheckRideOutcome', 'CheckRideOutcome');
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('CheckRideRequest|CheckRideResult|CheckRideRequestId', 'CheckRideRequest', 'CheckRideRequest');
    };
export class CheckRideScenarioMetaData implements DwItem2<CheckRideScenarioEntity, CheckRideScenarioMetaDataAttributes,CheckRideScenarioMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1142;
        this.ItemDetail.itemName='CheckRideScenario';
        this.ItemDetail.keyName='CheckRideScenarioId';
    }

    CreateEntity(): CheckRideScenarioEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideScenarioQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideScenarioEntity, CheckRideScenarioMetaDataAttributes,CheckRideScenarioMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideScenarioMetaDataAttributes();
     Relations = new CheckRideScenarioMetaDataRelations();

}


export type CheckRideScenarioQueryBuilder = DwQueryBuilderGen<CheckRideScenarioEntity, CheckRideScenarioMetaDataAttributes,CheckRideScenarioMetaDataRelations>


export class CheckRideScenarioMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideScenario';
    // Attributes
    CheckRideScenarioId = new DwItemAttribute(this.i, 'CheckRideScenarioId');
    PilotExaminerId = new DwItemAttribute(this.i, 'PilotExaminerId');
    ScenarioName = new DwItemAttribute(this.i, 'ScenarioName');
    TimeRequiredHours = new DwItemAttribute(this.i, 'TimeRequiredHours');
    PreTimeMinutes = new DwItemAttribute(this.i, 'PreTimeMinutes');
    PostTimeMinutes = new DwItemAttribute(this.i, 'PostTimeMinutes');
    CheckRideCost = new DwItemAttribute(this.i, 'CheckRideCost');
    RetestCost = new DwItemAttribute(this.i, 'RetestCost');
    DiscontinuedRetestCost = new DwItemAttribute(this.i, 'DiscontinuedRetestCost');
    DocumentsMediaGroupId = new DwItemAttribute(this.i, 'DocumentsMediaGroupId');
    RequiredDocumentNotes = new DwItemAttribute(this.i, 'RequiredDocumentNotes');
    BookingCost = new DwItemAttribute(this.i, 'BookingCost');
    CheckRideGroupId = new DwItemAttribute(this.i, 'CheckRideGroupId');
    IsActive = new DwItemAttribute(this.i, 'IsActive');
    TravelCost = new DwItemAttribute(this.i, 'TravelCost');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    TimeRequiredMinutes = new DwItemAttribute(this.i, 'TimeRequiredMinutes');
    RetestFlightOnlyCost = new DwItemAttribute(this.i, 'RetestFlightOnlyCost');
    DiscontinuedRetestFlightOnlyCost = new DwItemAttribute(this.i, 'DiscontinuedRetestFlightOnlyCost');
    RetestFlightOnlyTimeReqirementMinutes = new DwItemAttribute(this.i, 'RetestFlightOnlyTimeReqirementMinutes');
    RetestTimeRequiredMinutes = new DwItemAttribute(this.i, 'RetestTimeRequiredMinutes');
    RetestFlightOnlyTimeRequiredMinutes = new DwItemAttribute(this.i, 'RetestFlightOnlyTimeRequiredMinutes');
    DiscontinuedTimeRequiredMinutes = new DwItemAttribute(this.i, 'DiscontinuedTimeRequiredMinutes');
    DiscontinuedFlightOnlyTimeRequiredMinutes = new DwItemAttribute(this.i, 'DiscontinuedFlightOnlyTimeRequiredMinutes');
}

class CheckRideScenarioMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Group */
            CheckRideGroup = new DwItemRelationship('CheckRideGroup|CheckRideScenario|CheckRideGroupId', 'CheckRideGroup', 'CheckRideGroup');
            /** Relation: Pilot Examiner */
            PilotExaminer = new DwItemRelationship('PilotExaminer|CheckRideScenario|PilotExaminerId', 'PilotExaminer', 'PilotExaminer');
            /** Relation: Checkride Request - Checkride Type Id - Many */
            CheckRideRequestByScheduledCheckRideScenarioId = new DwItemRelationship('CheckRideScenario|CheckRideRequest|ScheduledCheckRideScenarioId', 'ScheduledCheckRideRequest', 'ScheduledCheckRideRequest');
            /** Relation: Checkride Scenario Type Map */
            CheckRideScenarioTypeMap = new DwItemRelationship('CheckRideScenario|CheckRideScenarioTypeMap|CheckRideScenarioId', 'CheckRideScenarioTypeMap', 'CheckRideScenarioTypeMap');
            /** Relation: Ro Pilot Examiner Scenario */
            RoPilotExaminerScenario = new DwItemRelationship('CheckRideScenario|roPilotExaminerScenario', 'RoPilotExaminerScenario', 'RoPilotExaminerScenario');
    };
export class CheckRideScenarioTypeMapMetaData implements DwItem2<CheckRideScenarioTypeMapEntity, CheckRideScenarioTypeMapMetaDataAttributes,CheckRideScenarioTypeMapMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1143;
        this.ItemDetail.itemName='CheckRideScenarioTypeMap';
        this.ItemDetail.keyName='CheckRideScenarioTypeMapId';
    }

    CreateEntity(): CheckRideScenarioTypeMapEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideScenarioTypeMapQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideScenarioTypeMapEntity, CheckRideScenarioTypeMapMetaDataAttributes,CheckRideScenarioTypeMapMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideScenarioTypeMapMetaDataAttributes();
     Relations = new CheckRideScenarioTypeMapMetaDataRelations();

}


export type CheckRideScenarioTypeMapQueryBuilder = DwQueryBuilderGen<CheckRideScenarioTypeMapEntity, CheckRideScenarioTypeMapMetaDataAttributes,CheckRideScenarioTypeMapMetaDataRelations>


export class CheckRideScenarioTypeMapMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideScenarioTypeMap';
    // Attributes
    CheckRideScenarioTypeMapId = new DwItemAttribute(this.i, 'CheckRideScenarioTypeMapId');
    CheckRideScenarioId = new DwItemAttribute(this.i, 'CheckRideScenarioId');
    CheckRideTypeId = new DwItemAttribute(this.i, 'CheckRideTypeId');
}

class CheckRideScenarioTypeMapMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Exam Type */
            CheckRideScenario = new DwItemRelationship('CheckRideScenario|CheckRideScenarioTypeMap|CheckRideScenarioId', 'CheckRideScenario', 'CheckRideScenario');
            /** Relation: Checkride Type */
            CheckRideType = new DwItemRelationship('CheckRideType|CheckRideScenarioTypeMap|CheckRideTypeId', 'CheckRideType', 'CheckRideType');
    };
export class CheckRideStatusLevelMetaData implements DwItem2<CheckRideStatusLevelEntity, CheckRideStatusLevelMetaDataAttributes,CheckRideStatusLevelMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1182;
        this.ItemDetail.itemName='CheckRideStatusLevel';
        this.ItemDetail.keyName='CheckRideStatusLevelId';
    }

    CreateEntity(): CheckRideStatusLevelEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideStatusLevelQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideStatusLevelEntity, CheckRideStatusLevelMetaDataAttributes,CheckRideStatusLevelMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideStatusLevelMetaDataAttributes();
     Relations = new CheckRideStatusLevelMetaDataRelations();

}


export type CheckRideStatusLevelQueryBuilder = DwQueryBuilderGen<CheckRideStatusLevelEntity, CheckRideStatusLevelMetaDataAttributes,CheckRideStatusLevelMetaDataRelations>


export class CheckRideStatusLevelMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideStatusLevel';
    // Attributes
    CheckRideStatusLevelId = new DwItemAttribute(this.i, 'CheckRideStatusLevelId');
    StatusLevelDisplay = new DwItemAttribute(this.i, 'StatusLevelDisplay');
}

class CheckRideStatusLevelMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Request Sub Status - Applicant Status Level - Many */
            CheckRideRequestSubStatusByApplicantStatusLevelId = new DwItemRelationship('CheckRideStatusLevel|CheckRideRequestSubStatus|ApplicantStatusLevelId', 'ApplicantStatusLevelIdCheckRideRequestSubStatus', 'ApplicantStatusLevelIdCheckRideRequestSubStatus');
            /** Relation: Checkride Request Sub Status - Examiner Status Level - Many */
            CheckRideRequestSubStatusByExaminerStatusLevelId = new DwItemRelationship('CheckRideStatusLevel|CheckRideRequestSubStatus|ExaminerStatusLevelId', 'ExaminerStatusLevelIdCheckRideRequestSubStatus', 'ExaminerStatusLevelIdCheckRideRequestSubStatus');
    };
export class CheckRideTestTypeMetaData implements DwItem2<CheckRideTestTypeEntity, CheckRideTestTypeMetaDataAttributes,CheckRideTestTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1147;
        this.ItemDetail.itemName='CheckRideTestType';
        this.ItemDetail.keyName='CheckRideTestTypeId';
    }

    CreateEntity(): CheckRideTestTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideTestTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideTestTypeEntity, CheckRideTestTypeMetaDataAttributes,CheckRideTestTypeMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideTestTypeMetaDataAttributes();
     Relations = new CheckRideTestTypeMetaDataRelations();

}


export type CheckRideTestTypeQueryBuilder = DwQueryBuilderGen<CheckRideTestTypeEntity, CheckRideTestTypeMetaDataAttributes,CheckRideTestTypeMetaDataRelations>


export class CheckRideTestTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideTestType';
    // Attributes
    CheckRideTestTypeId = new DwItemAttribute(this.i, 'CheckRideTestTypeId');
    TestType = new DwItemAttribute(this.i, 'TestType');
}

class CheckRideTestTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Applicant Wait List */
            ApplicantPlaceholder = new DwItemRelationship('CheckRideTestType|ApplicantPlaceholder|CheckRideTtestTypeId', 'ApplicantPlaceholder', 'ApplicantPlaceholder');
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('CheckRideTestType|CheckRideRequest|CheckRideTestTypeId', 'CheckRideRequest', 'CheckRideRequest');
    };
export class CheckRideTypeMetaData implements DwItem2<CheckRideTypeEntity, CheckRideTypeMetaDataAttributes,CheckRideTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1135;
        this.ItemDetail.itemName='CheckRideType';
        this.ItemDetail.keyName='CheckRideTypeId';
    }

    CreateEntity(): CheckRideTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CheckRideTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CheckRideTypeEntity, CheckRideTypeMetaDataAttributes,CheckRideTypeMetaDataRelations>(query, this);
    }
     Attributes = new CheckRideTypeMetaDataAttributes();
     Relations = new CheckRideTypeMetaDataRelations();

}


export type CheckRideTypeQueryBuilder = DwQueryBuilderGen<CheckRideTypeEntity, CheckRideTypeMetaDataAttributes,CheckRideTypeMetaDataRelations>


export class CheckRideTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideType';
    // Attributes
    CheckRideTypeId = new DwItemAttribute(this.i, 'CheckRideTypeId');
    CheckRideGroupId = new DwItemAttribute(this.i, 'CheckRideGroupId');
    CheckRideType = new DwItemAttribute(this.i, 'CheckRideType');
    CheckRideDesignation = new DwItemAttribute(this.i, 'CheckRideDesignation');
    TypeOrder = new DwItemAttribute(this.i, 'TypeOrder');
}

class CheckRideTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Group */
            CheckRideGroup = new DwItemRelationship('CheckRideGroup|CheckRideType|CheckRideGroupId', 'CheckRideGroup', 'CheckRideGroup');
            /** Relation: Applicant Wait List */
            ApplicantPlaceholder = new DwItemRelationship('CheckRideType|ApplicantPlaceholder|CheckRideTypeId', 'ApplicantPlaceholder', 'ApplicantPlaceholder');
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('CheckRideType|CheckRideRequest|CheckRideTypeId', 'CheckRideRequest', 'CheckRideRequest');
            /** Relation: Checkride Scenario Type Map */
            CheckRideScenarioTypeMap = new DwItemRelationship('CheckRideType|CheckRideScenarioTypeMap|CheckRideTypeId', 'CheckRideScenarioTypeMap', 'CheckRideScenarioTypeMap');
    };
export class CountryMetaData implements DwItem2<CountryEntity, CountryMetaDataAttributes,CountryMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1156;
        this.ItemDetail.itemName='Country';
        this.ItemDetail.keyName='CountryId';
    }

    CreateEntity(): CountryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CountryQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CountryEntity, CountryMetaDataAttributes,CountryMetaDataRelations>(query, this);
    }
     Attributes = new CountryMetaDataAttributes();
     Relations = new CountryMetaDataRelations();

}


export type CountryQueryBuilder = DwQueryBuilderGen<CountryEntity, CountryMetaDataAttributes,CountryMetaDataRelations>


export class CountryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Country';
    // Attributes
    CountryId = new DwItemAttribute(this.i, 'CountryId');
    CountryName = new DwItemAttribute(this.i, 'CountryName');
    CountryShortName = new DwItemAttribute(this.i, 'CountryShortName');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
}

class CountryMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Address */
            Address = new DwItemRelationship('Country|Address|CountryId', 'Address', 'Address');
            /** Relation: Applicant Wait List - Student Applicant Citizenship County - Many */
            ApplicantPlaceholderByStudentApplicantCitizenshipCountyId = new DwItemRelationship('Country|ApplicantPlaceholder|StudentApplicantCitizenshipCountyId', 'StudentApplicantCitizenshipCountyIdApplicantPlaceholder', 'StudentApplicantCitizenshipCountyIdApplicantPlaceholder');
            /** Relation: Checkride Request - Student Applicant Citizenship County - Many */
            CheckRideRequestByStudentApplicantCitizenshipCountyId = new DwItemRelationship('Country|CheckRideRequest|StudentApplicantCitizenshipCountyId', 'StudentApplicantCitizenshipCountyIdCheckRideRequest', 'StudentApplicantCitizenshipCountyIdCheckRideRequest');
            /** Relation: Plan Of Action - Student Applicant Citizenship - Many */
            PlanOfActionByStudentApplicantCitizenshipCountryId = new DwItemRelationship('Country|PlanOfAction|StudentApplicantCitizenshipCountryId', 'StudentApplicantCitizenshipPlanOfAction', 'StudentApplicantCitizenshipPlanOfAction');
            /** Relation: State Province */
            StateProvince = new DwItemRelationship('Country|StateProvince|CountryId', 'StateProvince', 'StateProvince');
            /** Relation: Student Applicant - Citzenship County - Many */
            StudentApplicantByCitzenshipCountyId = new DwItemRelationship('Country|StudentApplicant|CitzenshipCountyId', 'CitzenshipCountyIdStudentApplicant', 'CitzenshipCountyIdStudentApplicant');
    };
export class DurationMetaData implements DwItem2<DurationEntity, DurationMetaDataAttributes,DurationMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1185;
        this.ItemDetail.itemName='Duration';
        this.ItemDetail.keyName='DurationId';
    }

    CreateEntity(): DurationEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DurationQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<DurationEntity, DurationMetaDataAttributes,DurationMetaDataRelations>(query, this);
    }
     Attributes = new DurationMetaDataAttributes();
     Relations = new DurationMetaDataRelations();

}


export type DurationQueryBuilder = DwQueryBuilderGen<DurationEntity, DurationMetaDataAttributes,DurationMetaDataRelations>


export class DurationMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Duration';
    // Attributes
    DurationId = new DwItemAttribute(this.i, 'DurationId');
    DurationMinutes = new DwItemAttribute(this.i, 'DurationMinutes');
    DurationDisplay = new DwItemAttribute(this.i, 'DurationDisplay');
}

class DurationMetaDataRelations implements DwItemRelationshipCollection {
    };
export class FlightSchoolMetaData implements DwItem2<FlightSchoolEntity, FlightSchoolMetaDataAttributes,FlightSchoolMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1194;
        this.ItemDetail.itemName='FlightSchool';
        this.ItemDetail.keyName='FlightSchoolId';
    }

    CreateEntity(): FlightSchoolEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): FlightSchoolQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<FlightSchoolEntity, FlightSchoolMetaDataAttributes,FlightSchoolMetaDataRelations>(query, this);
    }
     Attributes = new FlightSchoolMetaDataAttributes();
     Relations = new FlightSchoolMetaDataRelations();

}


export type FlightSchoolQueryBuilder = DwQueryBuilderGen<FlightSchoolEntity, FlightSchoolMetaDataAttributes,FlightSchoolMetaDataRelations>


export class FlightSchoolMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'FlightSchool';
    // Attributes
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    FlightSchoolName = new DwItemAttribute(this.i, 'FlightSchoolName');
    IsOther = new DwItemAttribute(this.i, 'IsOther');
}

class FlightSchoolMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Applicant Wait List */
            ApplicantPlaceholder = new DwItemRelationship('FlightSchool|ApplicantPlaceholder|FlightSchoolId', 'ApplicantPlaceholder', 'ApplicantPlaceholder');
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('FlightSchool|CheckRideRequest|FlightSchoolId', 'CheckRideRequest', 'CheckRideRequest');
    };
export class DwMediaGroupMetaData implements DwItem2<EnterpriseMetaData.DwMediaGroupEntity, DwMediaGroupMetaDataAttributes,DwMediaGroupMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=188;
        this.ItemDetail.itemName='dw_MediaGroup';
        this.ItemDetail.keyName='MediaGroupId';
    }

    CreateEntity(): EnterpriseMetaData.DwMediaGroupEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwMediaGroupQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<EnterpriseMetaData.DwMediaGroupEntity, DwMediaGroupMetaDataAttributes,DwMediaGroupMetaDataRelations>(query, this);
    }
     Attributes = new DwMediaGroupMetaDataAttributes();
     Relations = new DwMediaGroupMetaDataRelations();

}


export type DwMediaGroupQueryBuilder = DwQueryBuilderGen<EnterpriseMetaData.DwMediaGroupEntity, DwMediaGroupMetaDataAttributes,DwMediaGroupMetaDataRelations>


export class DwMediaGroupMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_MediaGroup';
    // Attributes
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    GroupName = new DwItemAttribute(this.i, 'GroupName');
    IsGlobalGroup = new DwItemAttribute(this.i, 'IsGlobalGroup');
    IsSecure = new DwItemAttribute(this.i, 'IsSecure');
    MediaGroupId = new DwItemAttribute(this.i, 'MediaGroupId');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    PartyReferenceId = new DwItemAttribute(this.i, 'PartyReferenceId');
}

class DwMediaGroupMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Request - Documents - Many */
            CheckRideRequestByDocumentsMediaGroupId = new DwItemRelationship('dw_MediaGroup|CheckRideRequest|DocumentsMediaGroupId', 'DocumentsCheckRideRequest', 'DocumentsCheckRideRequest');
            /** Relation: Ro Media */
            DwRoMedia = new DwItemRelationship('MediaGroup_roMedia', 'DwroMedia', 'DwroMedia');
    };
export class PaymentCustomerReferenceMetaData implements DwItem2<PaymentCustomerReferenceEntity, PaymentCustomerReferenceMetaDataAttributes,PaymentCustomerReferenceMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1174;
        this.ItemDetail.itemName='PaymentCustomerReference';
        this.ItemDetail.keyName='PaymentCustomerReferenceId';
    }

    CreateEntity(): PaymentCustomerReferenceEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PaymentCustomerReferenceQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PaymentCustomerReferenceEntity, PaymentCustomerReferenceMetaDataAttributes,PaymentCustomerReferenceMetaDataRelations>(query, this);
    }
     Attributes = new PaymentCustomerReferenceMetaDataAttributes();
     Relations = new PaymentCustomerReferenceMetaDataRelations();

}


export type PaymentCustomerReferenceQueryBuilder = DwQueryBuilderGen<PaymentCustomerReferenceEntity, PaymentCustomerReferenceMetaDataAttributes,PaymentCustomerReferenceMetaDataRelations>


export class PaymentCustomerReferenceMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PaymentCustomerReference';
    // Attributes
    PaymentCustomerReferenceId = new DwItemAttribute(this.i, 'PaymentCustomerReferenceId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    CustomerReferenceKey = new DwItemAttribute(this.i, 'CustomerReferenceKey');
    PaymentProviderId = new DwItemAttribute(this.i, 'PaymentProviderId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class PaymentCustomerReferenceMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Payment Provider */
            PaymentProvider = new DwItemRelationship('PaymentProvider|PaymentCustomerReference|PaymentProviderId', 'PaymentProvider', 'PaymentProvider');
            /** Relation: User */
            DwUser = new DwItemRelationship('dw_User|PaymentCustomerReference|UserId', 'DwUser', 'DwUser');
            /** Relation: Payment Reference */
            PaymentReference = new DwItemRelationship('PaymentCustomerReference|PaymentReference|PaymentCustomerReferenceId', 'PaymentReference', 'PaymentReference');
    };
export class PaymentDiscountCodeMetaData implements DwItem2<PaymentDiscountCodeEntity, PaymentDiscountCodeMetaDataAttributes,PaymentDiscountCodeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1180;
        this.ItemDetail.itemName='PaymentDiscountCode';
        this.ItemDetail.keyName='PaymentDiscountCodeId';
    }

    CreateEntity(): PaymentDiscountCodeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PaymentDiscountCodeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PaymentDiscountCodeEntity, PaymentDiscountCodeMetaDataAttributes,PaymentDiscountCodeMetaDataRelations>(query, this);
    }
     Attributes = new PaymentDiscountCodeMetaDataAttributes();
     Relations = new PaymentDiscountCodeMetaDataRelations();

}


export type PaymentDiscountCodeQueryBuilder = DwQueryBuilderGen<PaymentDiscountCodeEntity, PaymentDiscountCodeMetaDataAttributes,PaymentDiscountCodeMetaDataRelations>


export class PaymentDiscountCodeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PaymentDiscountCode';
    // Attributes
    PaymentDiscountCodeId = new DwItemAttribute(this.i, 'PaymentDiscountCodeId');
    DiscountName = new DwItemAttribute(this.i, 'DiscountName');
    DiscountCode = new DwItemAttribute(this.i, 'DiscountCode');
    StartDate = new DwItemAttribute(this.i, 'StartDate');
    EndDate = new DwItemAttribute(this.i, 'EndDate');
    AllowedUses = new DwItemAttribute(this.i, 'AllowedUses');
    IsActive = new DwItemAttribute(this.i, 'IsActive');
    LimitToUserId = new DwItemAttribute(this.i, 'LimitToUserId');
    DiscountAmount = new DwItemAttribute(this.i, 'DiscountAmount');
    CurrentUses = new DwItemAttribute(this.i, 'CurrentUses');
    DiscountPercent = new DwItemAttribute(this.i, 'DiscountPercent');
}

class PaymentDiscountCodeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: User - Limit To User */
            DwUserByLimitToUserId = new DwItemRelationship('dw_User|PaymentDiscountCode|LimitToUserId', 'LimitToDwUser', 'LimitToDwUser');
            /** Relation: Checkride Payment - Payment Discountid - Many */
            CheckRidePaymentByPaymentDiscountid = new DwItemRelationship('PaymentDiscountCode|CheckRidePayment|PaymentDiscountid', 'PaymentDiscountidCheckRidePayment', 'PaymentDiscountidCheckRidePayment');
    };
export class PaymentMethodMetaData implements DwItem2<PaymentMethodEntity, PaymentMethodMetaDataAttributes,PaymentMethodMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1149;
        this.ItemDetail.itemName='PaymentMethod';
        this.ItemDetail.keyName='PaymentMethodId';
    }

    CreateEntity(): PaymentMethodEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PaymentMethodQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PaymentMethodEntity, PaymentMethodMetaDataAttributes,PaymentMethodMetaDataRelations>(query, this);
    }
     Attributes = new PaymentMethodMetaDataAttributes();
     Relations = new PaymentMethodMetaDataRelations();

}


export type PaymentMethodQueryBuilder = DwQueryBuilderGen<PaymentMethodEntity, PaymentMethodMetaDataAttributes,PaymentMethodMetaDataRelations>


export class PaymentMethodMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PaymentMethod';
    // Attributes
    PaymentMethodId = new DwItemAttribute(this.i, 'PaymentMethodId');
    PaymentMethod = new DwItemAttribute(this.i, 'PaymentMethod');
}

class PaymentMethodMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Payment */
            CheckRidePayment = new DwItemRelationship('PaymentMethod|CheckRidePayment|PaymentMethodId', 'CheckRidePayment', 'CheckRidePayment');
    };
export class PaymentProviderMetaData implements DwItem2<PaymentProviderEntity, PaymentProviderMetaDataAttributes,PaymentProviderMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1178;
        this.ItemDetail.itemName='PaymentProvider';
        this.ItemDetail.keyName='PaymentProviderId';
    }

    CreateEntity(): PaymentProviderEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PaymentProviderQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PaymentProviderEntity, PaymentProviderMetaDataAttributes,PaymentProviderMetaDataRelations>(query, this);
    }
     Attributes = new PaymentProviderMetaDataAttributes();
     Relations = new PaymentProviderMetaDataRelations();

}


export type PaymentProviderQueryBuilder = DwQueryBuilderGen<PaymentProviderEntity, PaymentProviderMetaDataAttributes,PaymentProviderMetaDataRelations>


export class PaymentProviderMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PaymentProvider';
    // Attributes
    PaymentProviderId = new DwItemAttribute(this.i, 'PaymentProviderId');
    PaymentProviderDisplay = new DwItemAttribute(this.i, 'PaymentProviderDisplay');
    PaymentProviderMeaning = new DwItemAttribute(this.i, 'PaymentProviderMeaning');
}

class PaymentProviderMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Payment Customer Reference */
            PaymentCustomerReference = new DwItemRelationship('PaymentProvider|PaymentCustomerReference|PaymentProviderId', 'PaymentCustomerReference', 'PaymentCustomerReference');
    };
export class PaymentReferenceMetaData implements DwItem2<PaymentReferenceEntity, PaymentReferenceMetaDataAttributes,PaymentReferenceMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1175;
        this.ItemDetail.itemName='PaymentReference';
        this.ItemDetail.keyName='PaymentReferenceId';
    }

    CreateEntity(): PaymentReferenceEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PaymentReferenceQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PaymentReferenceEntity, PaymentReferenceMetaDataAttributes,PaymentReferenceMetaDataRelations>(query, this);
    }
     Attributes = new PaymentReferenceMetaDataAttributes();
     Relations = new PaymentReferenceMetaDataRelations();

}


export type PaymentReferenceQueryBuilder = DwQueryBuilderGen<PaymentReferenceEntity, PaymentReferenceMetaDataAttributes,PaymentReferenceMetaDataRelations>


export class PaymentReferenceMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PaymentReference';
    // Attributes
    PaymentReferenceId = new DwItemAttribute(this.i, 'PaymentReferenceId');
    PaymentCustomerReferenceId = new DwItemAttribute(this.i, 'PaymentCustomerReferenceId');
    PaymentReferenceKey = new DwItemAttribute(this.i, 'PaymentReferenceKey');
    PaymentReferenceCardTypeId = new DwItemAttribute(this.i, 'PaymentReferenceCardTypeId');
    PaymentReference = new DwItemAttribute(this.i, 'PaymentReference');
    PaymentReferenceStatusId = new DwItemAttribute(this.i, 'PaymentReferenceStatusId');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    PaymentAmount = new DwItemAttribute(this.i, 'PaymentAmount');
    CardHolderName = new DwItemAttribute(this.i, 'CardHolderName');
}

class PaymentReferenceMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Payment Customer Reference */
            PaymentCustomerReference = new DwItemRelationship('PaymentCustomerReference|PaymentReference|PaymentCustomerReferenceId', 'PaymentCustomerReference', 'PaymentCustomerReference');
            /** Relation: Payment Reference Card Type */
            PaymentReferenceCardType = new DwItemRelationship('PaymentReferenceCardType|PaymentReference|PaymentReferenceCardTypeId', 'PaymentReferenceCardType', 'PaymentReferenceCardType');
            /** Relation: Payment Reference Status */
            PaymentReferenceStatus = new DwItemRelationship('PaymentReferenceStatus|PaymentReference|PaymentReferenceStatusId', 'PaymentReferenceStatus', 'PaymentReferenceStatus');
            /** Relation: Checkride Payment */
            CheckRidePayment = new DwItemRelationship('PaymentReference|CheckRidePayment|PaymentReferenceId', 'CheckRidePayment', 'CheckRidePayment');
            /** Relation: Payment Reference History */
            PaymentReferenceHistory = new DwItemRelationship('PaymentReference|PaymentReferenceHistory|PaymentReferenceId', 'PaymentReferenceHistory', 'PaymentReferenceHistory');
    };
export class PaymentReferenceCardTypeMetaData implements DwItem2<PaymentReferenceCardTypeEntity, PaymentReferenceCardTypeMetaDataAttributes,PaymentReferenceCardTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1176;
        this.ItemDetail.itemName='PaymentReferenceCardType';
        this.ItemDetail.keyName='PaymentReferenceCardTypeId';
    }

    CreateEntity(): PaymentReferenceCardTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PaymentReferenceCardTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PaymentReferenceCardTypeEntity, PaymentReferenceCardTypeMetaDataAttributes,PaymentReferenceCardTypeMetaDataRelations>(query, this);
    }
     Attributes = new PaymentReferenceCardTypeMetaDataAttributes();
     Relations = new PaymentReferenceCardTypeMetaDataRelations();

}


export type PaymentReferenceCardTypeQueryBuilder = DwQueryBuilderGen<PaymentReferenceCardTypeEntity, PaymentReferenceCardTypeMetaDataAttributes,PaymentReferenceCardTypeMetaDataRelations>


export class PaymentReferenceCardTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PaymentReferenceCardType';
    // Attributes
    PaymentReferenceCardTypeId = new DwItemAttribute(this.i, 'PaymentReferenceCardTypeId');
    CardTypeDisplay = new DwItemAttribute(this.i, 'CardTypeDisplay');
    CardTypeMeaning = new DwItemAttribute(this.i, 'CardTypeMeaning');
}

class PaymentReferenceCardTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Payment Reference */
            PaymentReference = new DwItemRelationship('PaymentReferenceCardType|PaymentReference|PaymentReferenceCardTypeId', 'PaymentReference', 'PaymentReference');
    };
export class PaymentReferenceHistoryMetaData implements DwItem2<PaymentReferenceHistoryEntity, PaymentReferenceHistoryMetaDataAttributes,PaymentReferenceHistoryMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1179;
        this.ItemDetail.itemName='PaymentReferenceHistory';
        this.ItemDetail.keyName='PaymentReferenceHistoryId';
    }

    CreateEntity(): PaymentReferenceHistoryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PaymentReferenceHistoryQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PaymentReferenceHistoryEntity, PaymentReferenceHistoryMetaDataAttributes,PaymentReferenceHistoryMetaDataRelations>(query, this);
    }
     Attributes = new PaymentReferenceHistoryMetaDataAttributes();
     Relations = new PaymentReferenceHistoryMetaDataRelations();

}


export type PaymentReferenceHistoryQueryBuilder = DwQueryBuilderGen<PaymentReferenceHistoryEntity, PaymentReferenceHistoryMetaDataAttributes,PaymentReferenceHistoryMetaDataRelations>


export class PaymentReferenceHistoryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PaymentReferenceHistory';
    // Attributes
    PaymentReferenceHistoryId = new DwItemAttribute(this.i, 'PaymentReferenceHistoryId');
    PaymentReferenceId = new DwItemAttribute(this.i, 'PaymentReferenceId');
    PaymentReferenceStatusId = new DwItemAttribute(this.i, 'PaymentReferenceStatusId');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
}

class PaymentReferenceHistoryMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Payment Reference */
            PaymentReference = new DwItemRelationship('PaymentReference|PaymentReferenceHistory|PaymentReferenceId', 'PaymentReference', 'PaymentReference');
            /** Relation: Payment Reference Status */
            PaymentReferenceStatus = new DwItemRelationship('PaymentReferenceStatus|PaymentReferenceHistory|PaymentReferenceStatusId', 'PaymentReferenceStatus', 'PaymentReferenceStatus');
    };
export class PaymentReferenceStatusMetaData implements DwItem2<PaymentReferenceStatusEntity, PaymentReferenceStatusMetaDataAttributes,PaymentReferenceStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1177;
        this.ItemDetail.itemName='PaymentReferenceStatus';
        this.ItemDetail.keyName='PaymentReferenceStatusId';
    }

    CreateEntity(): PaymentReferenceStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PaymentReferenceStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PaymentReferenceStatusEntity, PaymentReferenceStatusMetaDataAttributes,PaymentReferenceStatusMetaDataRelations>(query, this);
    }
     Attributes = new PaymentReferenceStatusMetaDataAttributes();
     Relations = new PaymentReferenceStatusMetaDataRelations();

}


export type PaymentReferenceStatusQueryBuilder = DwQueryBuilderGen<PaymentReferenceStatusEntity, PaymentReferenceStatusMetaDataAttributes,PaymentReferenceStatusMetaDataRelations>


export class PaymentReferenceStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PaymentReferenceStatus';
    // Attributes
    PaymentReferenceStatusId = new DwItemAttribute(this.i, 'PaymentReferenceStatusId');
    PaymentReferenceStatus = new DwItemAttribute(this.i, 'PaymentReferenceStatus');
}

class PaymentReferenceStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Payment Reference */
            PaymentReference = new DwItemRelationship('PaymentReferenceStatus|PaymentReference|PaymentReferenceStatusId', 'PaymentReference', 'PaymentReference');
            /** Relation: Payment Reference History */
            PaymentReferenceHistory = new DwItemRelationship('PaymentReferenceStatus|PaymentReferenceHistory|PaymentReferenceStatusId', 'PaymentReferenceHistory', 'PaymentReferenceHistory');
    };
export class PaymentTypeMetaData implements DwItem2<PaymentTypeEntity, PaymentTypeMetaDataAttributes,PaymentTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1150;
        this.ItemDetail.itemName='PaymentType';
        this.ItemDetail.keyName='PaymentTypeId';
    }

    CreateEntity(): PaymentTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PaymentTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PaymentTypeEntity, PaymentTypeMetaDataAttributes,PaymentTypeMetaDataRelations>(query, this);
    }
     Attributes = new PaymentTypeMetaDataAttributes();
     Relations = new PaymentTypeMetaDataRelations();

}


export type PaymentTypeQueryBuilder = DwQueryBuilderGen<PaymentTypeEntity, PaymentTypeMetaDataAttributes,PaymentTypeMetaDataRelations>


export class PaymentTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PaymentType';
    // Attributes
    PaymentTypeId = new DwItemAttribute(this.i, 'PaymentTypeId');
    PaymentType = new DwItemAttribute(this.i, 'PaymentType');
}

class PaymentTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Payment */
            CheckRidePayment = new DwItemRelationship('PaymentType|CheckRidePayment|PaymentTypeId', 'CheckRidePayment', 'CheckRidePayment');
    };
export class PilotCertificateTypeMetaData implements DwItem2<PilotCertificateTypeEntity, PilotCertificateTypeMetaDataAttributes,PilotCertificateTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1195;
        this.ItemDetail.itemName='PilotCertificateType';
        this.ItemDetail.keyName='PilotCertificateTypeId';
    }

    CreateEntity(): PilotCertificateTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PilotCertificateTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PilotCertificateTypeEntity, PilotCertificateTypeMetaDataAttributes,PilotCertificateTypeMetaDataRelations>(query, this);
    }
     Attributes = new PilotCertificateTypeMetaDataAttributes();
     Relations = new PilotCertificateTypeMetaDataRelations();

}


export type PilotCertificateTypeQueryBuilder = DwQueryBuilderGen<PilotCertificateTypeEntity, PilotCertificateTypeMetaDataAttributes,PilotCertificateTypeMetaDataRelations>


export class PilotCertificateTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PilotCertificateType';
    // Attributes
    PilotCertificateTypeId = new DwItemAttribute(this.i, 'PilotCertificateTypeId');
    PilotCertiicateTypeId = new DwItemAttribute(this.i, 'PilotCertiicateTypeId');
}

class PilotCertificateTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Request - Current Pilot Certifiate Type - Many */
            CheckRideRequestByCurrentPilotCertifiateTypeId = new DwItemRelationship('PilotCertificateType|CheckRideRequest|CurrentPilotCertifiateTypeId', 'CurrentPilotCertifiateTypeIdCheckRideRequest', 'CurrentPilotCertifiateTypeIdCheckRideRequest');
            /** Relation: Student Applicant - Current Pilot Certifiate Type - Many */
            StudentApplicantByCurrentPilotCertifiateTypeId = new DwItemRelationship('PilotCertificateType|StudentApplicant|CurrentPilotCertifiateTypeId', 'CurrentPilotCertifiateTypeIdStudentApplicant', 'CurrentPilotCertifiateTypeIdStudentApplicant');
    };
export class PilotExaminerMetaData implements DwItem2<PilotExaminerEntity, PilotExaminerMetaDataAttributes,PilotExaminerMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1137;
        this.ItemDetail.itemName='PilotExaminer';
        this.ItemDetail.keyName='PilotExaminerId';
    }

    CreateEntity(): PilotExaminerEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PilotExaminerQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PilotExaminerEntity, PilotExaminerMetaDataAttributes,PilotExaminerMetaDataRelations>(query, this);
    }
     Attributes = new PilotExaminerMetaDataAttributes();
     Relations = new PilotExaminerMetaDataRelations();

}


export type PilotExaminerQueryBuilder = DwQueryBuilderGen<PilotExaminerEntity, PilotExaminerMetaDataAttributes,PilotExaminerMetaDataRelations>


export class PilotExaminerMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PilotExaminer';
    // Attributes
    PilotExaminerId = new DwItemAttribute(this.i, 'PilotExaminerId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    PilotExaminerStatusId = new DwItemAttribute(this.i, 'PilotExaminerStatusId');
    Description = new DwItemAttribute(this.i, 'Description');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    DefaultPreTimeMinutes = new DwItemAttribute(this.i, 'DefaultPreTimeMinutes');
    DefaultPostTimeMinutes = new DwItemAttribute(this.i, 'DefaultPostTimeMinutes');
    TravelRadiusMiles = new DwItemAttribute(this.i, 'TravelRadiusMiles');
    AuthorityExaminerNumber = new DwItemAttribute(this.i, 'AuthorityExaminerNumber');
    CalendarIsLinked = new DwItemAttribute(this.i, 'CalendarIsLinked');
    CalendarReferenceId = new DwItemAttribute(this.i, 'CalendarReferenceId');
    RequireAuthorityPreCheckRideNumber = new DwItemAttribute(this.i, 'RequireAuthorityPreCheckRideNumber');
    PublicProfileIsActive = new DwItemAttribute(this.i, 'PublicProfileIsActive');
    PublicProfileSlug = new DwItemAttribute(this.i, 'PublicProfileSlug');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    CheckRideRequestLeadTimeDays = new DwItemAttribute(this.i, 'CheckRideRequestLeadTimeDays');
    CheckRideRequestBookingMaxWeeksOut = new DwItemAttribute(this.i, 'CheckRideRequestBookingMaxWeeksOut');
    AviationAuthorityDistrictOfficeId = new DwItemAttribute(this.i, 'AviationAuthorityDistrictOfficeId');
    DailyTravelRadiusLockMiles = new DwItemAttribute(this.i, 'DailyTravelRadiusLockMiles');
}

class PilotExaminerMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Aviation Authority */
            AviationAuthority = new DwItemRelationship('AviationAuthority|PilotExaminer|AviationAuthorityId', 'AviationAuthority', 'AviationAuthority');
            /** Relation: FSDO */
            AviationAuthorityDistrictOffice = new DwItemRelationship('AviationAuthorityDistrictOffice|PilotExaminer|AviationAuthorityDistrictOfficeId', 'AviationAuthorityDistrictOffice', 'AviationAuthorityDistrictOffice');
            /** Relation: Calendar Reference */
            CalendarReference = new DwItemRelationship('CalendarReference|PilotExaminer|CalendarReferenceId', 'CalendarReference', 'CalendarReference');
            /** Relation: Pilot Examiner Status */
            PilotExaminerStatus = new DwItemRelationship('PilotExaminerStatus|PilotExaminer|PilotExaminerStatusId', 'PilotExaminerStatus', 'PilotExaminerStatus');
            /** Relation: User */
            DwUser = new DwItemRelationship('dw_User|PilotExaminer|UserId', 'DwUser', 'DwUser');
            /** Relation: Applicant Wait List */
            ApplicantPlaceholder = new DwItemRelationship('PilotExaminer|ApplicantPlaceholder|PilotExaminerId', 'ApplicantPlaceholder', 'ApplicantPlaceholder');
            /** Relation: Checkride Request - Scheduled - Many */
            CheckRideRequestByScheduledPilotExaminerId = new DwItemRelationship('PilotExaminer|CheckRideRequest|ScheduledPilotExaminerId', 'ScheduledCheckRideRequest', 'ScheduledCheckRideRequest');
            /** Relation: Exam Type */
            CheckRideScenario = new DwItemRelationship('PilotExaminer|CheckRideScenario|PilotExaminerId', 'CheckRideScenario', 'CheckRideScenario');
            /** Relation: Pilot Examiner Location */
            PilotExaminerLocation = new DwItemRelationship('PilotExaminer|PilotExaminerLocation|PilotExaminerId', 'PilotExaminerLocation', 'PilotExaminerLocation');
            /** Relation: Plan Of Action */
            PlanOfAction = new DwItemRelationship('PilotExaminer|PlanOfAction|PilotExaminerId', 'PlanOfAction', 'PlanOfAction');
            /** Relation: Ro Pilot Examiner Location Distances */
            RoPilotExaminerLocationDistances = new DwItemRelationship('PilotExaminer|RoPilotExaminerLocation', 'RoPilotExaminerLocationDistances', 'RoPilotExaminerLocationDistances');
            /** Relation: Ro Pilot Examiner Scenario */
            RoPilotExaminerScenario = new DwItemRelationship('PIlotExaminer|RoPilotExaminerScenario', 'RoPilotExaminerScenario', 'RoPilotExaminerScenario');
            /** Relation: Student Applicant - Referred - Many */
            StudentApplicantByReferredPilotExaminerId = new DwItemRelationship('PilotExaminer|StudentApplicant|ReferredPilotExaminerId', 'ReferredStudentApplicant', 'ReferredStudentApplicant');
    };
export class PilotExaminerLocationMetaData implements DwItem2<PilotExaminerLocationEntity, PilotExaminerLocationMetaDataAttributes,PilotExaminerLocationMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1141;
        this.ItemDetail.itemName='PilotExaminerLocation';
        this.ItemDetail.keyName='PilotExaminerLocationId';
    }

    CreateEntity(): PilotExaminerLocationEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PilotExaminerLocationQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PilotExaminerLocationEntity, PilotExaminerLocationMetaDataAttributes,PilotExaminerLocationMetaDataRelations>(query, this);
    }
     Attributes = new PilotExaminerLocationMetaDataAttributes();
     Relations = new PilotExaminerLocationMetaDataRelations();

}


export type PilotExaminerLocationQueryBuilder = DwQueryBuilderGen<PilotExaminerLocationEntity, PilotExaminerLocationMetaDataAttributes,PilotExaminerLocationMetaDataRelations>


export class PilotExaminerLocationMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PilotExaminerLocation';
    // Attributes
    PilotExaminerLocationId = new DwItemAttribute(this.i, 'PilotExaminerLocationId');
    PilotExaminerId = new DwItemAttribute(this.i, 'PilotExaminerId');
    LocationName = new DwItemAttribute(this.i, 'LocationName');
    SchedulingStartDate = new DwItemAttribute(this.i, 'SchedulingStartDate');
    SchedulingEndDate = new DwItemAttribute(this.i, 'SchedulingEndDate');
    TravelRadiusMile = new DwItemAttribute(this.i, 'TravelRadiusMile');
    BookingScheduleId = new DwItemAttribute(this.i, 'BookingScheduleId');
    TravelCost = new DwItemAttribute(this.i, 'TravelCost');
    IsDefaultLocation = new DwItemAttribute(this.i, 'IsDefaultLocation');
    IsActive = new DwItemAttribute(this.i, 'IsActive');
    AdditionalLocationCost = new DwItemAttribute(this.i, 'AdditionalLocationCost');
    BookingScheduleTimeZoneId = new DwItemAttribute(this.i, 'BookingScheduleTimeZoneId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    ShowMetar = new DwItemAttribute(this.i, 'ShowMetar');
    PilotExaminerLocationTypeId = new DwItemAttribute(this.i, 'PilotExaminerLocationTypeId');
}

class PilotExaminerLocationMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Pilot Examiner */
            PilotExaminer = new DwItemRelationship('PilotExaminer|PilotExaminerLocation|PilotExaminerId', 'PilotExaminer', 'PilotExaminer');
            /** Relation: Pilot Examiner Location Type */
            PilotExaminerLocationType = new DwItemRelationship('PilotExaminerLocationType|PilotExaminerLocation|PilotExaminerLocationTypeId', 'PilotExaminerLocationType', 'PilotExaminerLocationType');
            /** Relation: Custom Calendar - Booking Days */
            ScheduleByBookingScheduleId = new DwItemRelationship('Schedule|PilotExaminerLocation|BookingScheduleId', 'BookingSchedule', 'BookingSchedule');
            /** Relation: Time Zone - Booking Timezone */
            TimeZoneByBookingScheduleTimeZoneId = new DwItemRelationship('TimeZone|PilotExaminerLocation|BookingScheduleTimeZoneId', 'BookingScheduleTimeZoneIdTimeZone', 'BookingScheduleTimeZoneIdTimeZone');
            /** Relation: Checkride Request - Scheduled - Many */
            CheckRideRequestByScheduledPilotExaminerLocationId = new DwItemRelationship('PilotExaminerLocation|CheckRideRequest|ScheduledPilotExaminerLocationId', 'ScheduledCheckRideRequest', 'ScheduledCheckRideRequest');
            /** Relation: Pilot Examiner Location Airport */
            PilotExaminerLocationAirport = new DwItemRelationship('PilotExaminerLocation|PilotExaminerLocationAirport|PilotExaminerLocationId', 'PilotExaminerLocationAirport', 'PilotExaminerLocationAirport');
            /** Relation: Ro Pilot Examiner Location Current */
            RoPilotExaminerLocationCurrent = new DwItemRelationship('PilotExaminerLocation|roPilotExaminerLocationCurrent', 'RoPilotExaminerLocationCurrent', 'RoPilotExaminerLocationCurrent');
            /** Relation: Ro Pilot Examiner Location Distances */
            RoPilotExaminerLocationDistances = new DwItemRelationship('PIlotExaminerLocation|RoPilotExaminerLocation', 'RoPilotExaminerLocationDistances', 'RoPilotExaminerLocationDistances');
    };
export class PilotExaminerLocationAirportMetaData implements DwItem2<PilotExaminerLocationAirportEntity, PilotExaminerLocationAirportMetaDataAttributes,PilotExaminerLocationAirportMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1160;
        this.ItemDetail.itemName='PilotExaminerLocationAirport';
        this.ItemDetail.keyName='PilotExaminerLocationAirportId';
    }

    CreateEntity(): PilotExaminerLocationAirportEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PilotExaminerLocationAirportQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PilotExaminerLocationAirportEntity, PilotExaminerLocationAirportMetaDataAttributes,PilotExaminerLocationAirportMetaDataRelations>(query, this);
    }
     Attributes = new PilotExaminerLocationAirportMetaDataAttributes();
     Relations = new PilotExaminerLocationAirportMetaDataRelations();

}


export type PilotExaminerLocationAirportQueryBuilder = DwQueryBuilderGen<PilotExaminerLocationAirportEntity, PilotExaminerLocationAirportMetaDataAttributes,PilotExaminerLocationAirportMetaDataRelations>


export class PilotExaminerLocationAirportMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PilotExaminerLocationAirport';
    // Attributes
    PilotExaminerLocationAirportId = new DwItemAttribute(this.i, 'PilotExaminerLocationAirportId');
    PilotExaminerLocationId = new DwItemAttribute(this.i, 'PilotExaminerLocationId');
    AirportId = new DwItemAttribute(this.i, 'AirportId');
}

class PilotExaminerLocationAirportMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Airport */
            Airport = new DwItemRelationship('Airport|PilotExaminerLocationAirport|AirportId', 'Airport', 'Airport');
            /** Relation: Pilot Examiner Location */
            PilotExaminerLocation = new DwItemRelationship('PilotExaminerLocation|PilotExaminerLocationAirport|PilotExaminerLocationId', 'PilotExaminerLocation', 'PilotExaminerLocation');
    };
export class PilotExaminerLocationStateMetaData implements DwItem2<PilotExaminerLocationStateEntity, PilotExaminerLocationStateMetaDataAttributes,PilotExaminerLocationStateMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1168;
        this.ItemDetail.itemName='PilotExaminerLocationState';
        this.ItemDetail.keyName='PilotExaminerLocationStateId';
    }

    CreateEntity(): PilotExaminerLocationStateEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PilotExaminerLocationStateQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PilotExaminerLocationStateEntity, PilotExaminerLocationStateMetaDataAttributes,PilotExaminerLocationStateMetaDataRelations>(query, this);
    }
     Attributes = new PilotExaminerLocationStateMetaDataAttributes();
     Relations = new PilotExaminerLocationStateMetaDataRelations();

}


export type PilotExaminerLocationStateQueryBuilder = DwQueryBuilderGen<PilotExaminerLocationStateEntity, PilotExaminerLocationStateMetaDataAttributes,PilotExaminerLocationStateMetaDataRelations>


export class PilotExaminerLocationStateMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PilotExaminerLocationState';
    // Attributes
    PilotExaminerLocationStateId = new DwItemAttribute(this.i, 'PilotExaminerLocationStateId');
    StateMeaning = new DwItemAttribute(this.i, 'StateMeaning');
    StateDisplay = new DwItemAttribute(this.i, 'StateDisplay');
}

class PilotExaminerLocationStateMetaDataRelations implements DwItemRelationshipCollection {
    };
export class PilotExaminerLocationTypeMetaData implements DwItem2<PilotExaminerLocationTypeEntity, PilotExaminerLocationTypeMetaDataAttributes,PilotExaminerLocationTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1224;
        this.ItemDetail.itemName='PilotExaminerLocationType';
        this.ItemDetail.keyName='PilotExaminerLocationTypeId';
    }

    CreateEntity(): PilotExaminerLocationTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PilotExaminerLocationTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PilotExaminerLocationTypeEntity, PilotExaminerLocationTypeMetaDataAttributes,PilotExaminerLocationTypeMetaDataRelations>(query, this);
    }
     Attributes = new PilotExaminerLocationTypeMetaDataAttributes();
     Relations = new PilotExaminerLocationTypeMetaDataRelations();

}


export type PilotExaminerLocationTypeQueryBuilder = DwQueryBuilderGen<PilotExaminerLocationTypeEntity, PilotExaminerLocationTypeMetaDataAttributes,PilotExaminerLocationTypeMetaDataRelations>


export class PilotExaminerLocationTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PilotExaminerLocationType';
    // Attributes
    PilotExaminerLocationTypeId = new DwItemAttribute(this.i, 'PilotExaminerLocationTypeId');
    PilotExaminerLocationType = new DwItemAttribute(this.i, 'PilotExaminerLocationType');
}

class PilotExaminerLocationTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Pilot Examiner Location */
            PilotExaminerLocation = new DwItemRelationship('PilotExaminerLocationType|PilotExaminerLocation|PilotExaminerLocationTypeId', 'PilotExaminerLocation', 'PilotExaminerLocation');
    };
export class PilotExaminerStatusMetaData implements DwItem2<PilotExaminerStatusEntity, PilotExaminerStatusMetaDataAttributes,PilotExaminerStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1136;
        this.ItemDetail.itemName='PilotExaminerStatus';
        this.ItemDetail.keyName='PilotExaminerStatusId';
    }

    CreateEntity(): PilotExaminerStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PilotExaminerStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PilotExaminerStatusEntity, PilotExaminerStatusMetaDataAttributes,PilotExaminerStatusMetaDataRelations>(query, this);
    }
     Attributes = new PilotExaminerStatusMetaDataAttributes();
     Relations = new PilotExaminerStatusMetaDataRelations();

}


export type PilotExaminerStatusQueryBuilder = DwQueryBuilderGen<PilotExaminerStatusEntity, PilotExaminerStatusMetaDataAttributes,PilotExaminerStatusMetaDataRelations>


export class PilotExaminerStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PilotExaminerStatus';
    // Attributes
    PilotExaminerStatusId = new DwItemAttribute(this.i, 'PilotExaminerStatusId');
    PilotExaminerStatus = new DwItemAttribute(this.i, 'PilotExaminerStatus');
    WorkflowStatusDetailId = new DwItemAttribute(this.i, 'WorkflowStatusDetailId');
}

class PilotExaminerStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Pilot Examiner */
            PilotExaminer = new DwItemRelationship('PilotExaminerStatus|PilotExaminer|PilotExaminerStatusId', 'PilotExaminer', 'PilotExaminer');
    };
export class PlanOfActionMetaData implements DwItem2<PlanOfActionEntity, PlanOfActionMetaDataAttributes,PlanOfActionMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1217;
        this.ItemDetail.itemName='PlanOfAction';
        this.ItemDetail.keyName='PlanOfActionId';
    }

    CreateEntity(): PlanOfActionEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PlanOfActionQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PlanOfActionEntity, PlanOfActionMetaDataAttributes,PlanOfActionMetaDataRelations>(query, this);
    }
     Attributes = new PlanOfActionMetaDataAttributes();
     Relations = new PlanOfActionMetaDataRelations();

}


export type PlanOfActionQueryBuilder = DwQueryBuilderGen<PlanOfActionEntity, PlanOfActionMetaDataAttributes,PlanOfActionMetaDataRelations>


export class PlanOfActionMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PlanOfAction';
    // Attributes
    PlanOfActionId = new DwItemAttribute(this.i, 'PlanOfActionId');
    PlanOfActionName = new DwItemAttribute(this.i, 'PlanOfActionName');
    PlanOfActionStatusId = new DwItemAttribute(this.i, 'PlanOfActionStatusId');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    AcsTestId = new DwItemAttribute(this.i, 'AcsTestId');
    PilotExaminerId = new DwItemAttribute(this.i, 'PilotExaminerId');
    CheckRideRequestId = new DwItemAttribute(this.i, 'CheckRideRequestId');
    CheckrideDateTime = new DwItemAttribute(this.i, 'CheckrideDateTime');
    StudentApplicantNameManual = new DwItemAttribute(this.i, 'StudentApplicantNameManual');
    StudentApplicantEmailManual = new DwItemAttribute(this.i, 'StudentApplicantEmailManual');
    StudentApplicantPhoneManual = new DwItemAttribute(this.i, 'StudentApplicantPhoneManual');
    StudentApplicantCitizenshipCountryId = new DwItemAttribute(this.i, 'StudentApplicantCitizenshipCountryId');
    AuthorityPreCheckRideNumber = new DwItemAttribute(this.i, 'AuthorityPreCheckRideNumber');
    PilotCertificateNumber = new DwItemAttribute(this.i, 'PilotCertificateNumber');
    AirplaneNNumber = new DwItemAttribute(this.i, 'AirplaneNNumber');
    AuthorityPreapprovalCode = new DwItemAttribute(this.i, 'AuthorityPreapprovalCode');
    AuthorityApprovalCode = new DwItemAttribute(this.i, 'Authority Approval Code');
    ApplicationPaperworkCorrect = new DwItemAttribute(this.i, 'ApplicationPaperworkCorrect');
    EndorsementsPaperworkCorrect = new DwItemAttribute(this.i, 'EndorsementsPaperworkCorrect');
    OptionsShowQuestionForAcsCode = new DwItemAttribute(this.i, 'OptionsShowQuestionForAcsCode');
    IsTemplate = new DwItemAttribute(this.i, 'IsTemplate');
    IsSharedTemplate = new DwItemAttribute(this.i, 'IsSharedTemplate');
    TemplatePlanOfActionId = new DwItemAttribute(this.i, 'TemplatePlanOfActionId');
    IsBuiltIn = new DwItemAttribute(this.i, 'IsBuiltIn');
    OwnerUserId = new DwItemAttribute(this.i, 'OwnerUserId');
    PlanOfActionResultId = new DwItemAttribute(this.i, 'PlanOfActionResultId');
}

class PlanOfActionMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Acs Test */
            AcsTest = new DwItemRelationship('AcsTest|PlanOfAction|AcsTestId', 'AcsTest', 'AcsTest');
            /** Relation: Aviation Authority */
            AviationAuthority = new DwItemRelationship('AviationAuthority|PlanOfAction|AviationAuthorityId', 'AviationAuthority', 'AviationAuthority');
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('CheckRideRequest|PlanOfAction|CheckRideRequestId', 'CheckRideRequest', 'CheckRideRequest');
            /** Relation: Country - Student Applicant Citizenship Country */
            CountryByStudentApplicantCitizenshipCountryId = new DwItemRelationship('Country|PlanOfAction|StudentApplicantCitizenshipCountryId', 'StudentApplicantCitizenshipCountry', 'StudentApplicantCitizenshipCountry');
            /** Relation: Pilot Examiner */
            PilotExaminer = new DwItemRelationship('PilotExaminer|PlanOfAction|PilotExaminerId', 'PilotExaminer', 'PilotExaminer');
            /** Relation: Plan Of Action Result */
            PlanOfActionResult = new DwItemRelationship('PlanOfActionResult|PlanOfAction|PlanOfActionResultId', 'PlanOfActionResult', 'PlanOfActionResult');
            /** Relation: Plan Of Action Status */
            PlanOfActionStatus = new DwItemRelationship('PlanOfActionStatus|PlanOfAction|PlanOfActionStatusId', 'PlanOfActionStatus', 'PlanOfActionStatus');
            /** Relation: Plan Of Action - Template Plan Of Action - One */
            PlanOfActionByTemplatePlanOfActionId = new DwItemRelationship('PlanOfAction|PlanOfAction|TemplatePlanOfActionId', 'TemplatePlanOfAction', 'TemplatePlanOfAction');
            /** Relation: Plan Of Action Required Acs Code */
            PlanOfActionRequiredAcsCode = new DwItemRelationship('PlanOfAction|PlanOfActionRequiredAcsCode|PlanOfActionId', 'PlanOfActionRequiredAcsCode', 'PlanOfActionRequiredAcsCode');
            /** Relation: Plan Of Action Section */
            PlanOfActionSection = new DwItemRelationship('PlanOfAction|PlanOfActionSection|PlanOfActionId', 'PlanOfActionSection', 'PlanOfActionSection');
    };
export class PlanOfActionQuestionResultMetaData implements DwItem2<PlanOfActionQuestionResultEntity, PlanOfActionQuestionResultMetaDataAttributes,PlanOfActionQuestionResultMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1226;
        this.ItemDetail.itemName='PlanOfActionQuestionResult';
        this.ItemDetail.keyName='PlanOfActionQuestionResultId';
    }

    CreateEntity(): PlanOfActionQuestionResultEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PlanOfActionQuestionResultQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PlanOfActionQuestionResultEntity, PlanOfActionQuestionResultMetaDataAttributes,PlanOfActionQuestionResultMetaDataRelations>(query, this);
    }
     Attributes = new PlanOfActionQuestionResultMetaDataAttributes();
     Relations = new PlanOfActionQuestionResultMetaDataRelations();

}


export type PlanOfActionQuestionResultQueryBuilder = DwQueryBuilderGen<PlanOfActionQuestionResultEntity, PlanOfActionQuestionResultMetaDataAttributes,PlanOfActionQuestionResultMetaDataRelations>


export class PlanOfActionQuestionResultMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PlanOfActionQuestionResult';
    // Attributes
    PlanOfActionQuestionResultId = new DwItemAttribute(this.i, 'PlanOfActionQuestionResultId');
    PlanOfActionResultId = new DwItemAttribute(this.i, 'PlanOfActionResultId');
    PlanOfActionSectionQuestionId = new DwItemAttribute(this.i, 'PlanOfActionSectionQuestionId');
    QuestionOutcomeId = new DwItemAttribute(this.i, 'QuestionOutcomeId');
    DiscussQuestion = new DwItemAttribute(this.i, 'DiscussQuestion');
    Notes = new DwItemAttribute(this.i, 'Notes');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class PlanOfActionQuestionResultMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Plan Of Action Result */
            PlanOfActionResult = new DwItemRelationship('PlanOfActionResult|PlanOfActionQuestionResult|PlanOfActionResultId', 'PlanOfActionResult', 'PlanOfActionResult');
            /** Relation: Plan Of Action Section Question */
            PlanOfActionSectionQuestion = new DwItemRelationship('PlanOfActionSectionQuestion|PlanOfActionQuestionResult|PlanOfActionSectionQuestionId', 'PlanOfActionSectionQuestion', 'PlanOfActionSectionQuestion');
            /** Relation: Question Outcome */
            QuestionOutcome = new DwItemRelationship('QuestionOutcome|PlanOfActionQuestionResult|QuestionOutcomeId', 'QuestionOutcome', 'QuestionOutcome');
    };
export class PlanOfActionRequiredAcsCodeMetaData implements DwItem2<PlanOfActionRequiredAcsCodeEntity, PlanOfActionRequiredAcsCodeMetaDataAttributes,PlanOfActionRequiredAcsCodeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1222;
        this.ItemDetail.itemName='PlanOfActionRequiredAcsCode';
        this.ItemDetail.keyName='PlanOfActionRequiredAcsCodeId';
    }

    CreateEntity(): PlanOfActionRequiredAcsCodeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PlanOfActionRequiredAcsCodeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PlanOfActionRequiredAcsCodeEntity, PlanOfActionRequiredAcsCodeMetaDataAttributes,PlanOfActionRequiredAcsCodeMetaDataRelations>(query, this);
    }
     Attributes = new PlanOfActionRequiredAcsCodeMetaDataAttributes();
     Relations = new PlanOfActionRequiredAcsCodeMetaDataRelations();

}


export type PlanOfActionRequiredAcsCodeQueryBuilder = DwQueryBuilderGen<PlanOfActionRequiredAcsCodeEntity, PlanOfActionRequiredAcsCodeMetaDataAttributes,PlanOfActionRequiredAcsCodeMetaDataRelations>


export class PlanOfActionRequiredAcsCodeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PlanOfActionRequiredAcsCode';
    // Attributes
    PlanOfActionRequiredAcsCodeId = new DwItemAttribute(this.i, 'PlanOfActionRequiredAcsCodeId');
    PlanOfActionId = new DwItemAttribute(this.i, 'PlanOfActionId');
    AcsCodeId = new DwItemAttribute(this.i, 'AcsCodeId');
}

class PlanOfActionRequiredAcsCodeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Acs Code */
            AcsCode = new DwItemRelationship('AcsCode|PlanOfActionRequiredAcsCode|AscCodeId', 'AcsCode', 'AcsCode');
            /** Relation: Plan Of Action */
            PlanOfAction = new DwItemRelationship('PlanOfAction|PlanOfActionRequiredAcsCode|PlanOfActionId', 'PlanOfAction', 'PlanOfAction');
    };
export class PlanOfActionResultMetaData implements DwItem2<PlanOfActionResultEntity, PlanOfActionResultMetaDataAttributes,PlanOfActionResultMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1225;
        this.ItemDetail.itemName='PlanOfActionResult';
        this.ItemDetail.keyName='PlanOfActionResultId';
    }

    CreateEntity(): PlanOfActionResultEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PlanOfActionResultQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PlanOfActionResultEntity, PlanOfActionResultMetaDataAttributes,PlanOfActionResultMetaDataRelations>(query, this);
    }
     Attributes = new PlanOfActionResultMetaDataAttributes();
     Relations = new PlanOfActionResultMetaDataRelations();

}


export type PlanOfActionResultQueryBuilder = DwQueryBuilderGen<PlanOfActionResultEntity, PlanOfActionResultMetaDataAttributes,PlanOfActionResultMetaDataRelations>


export class PlanOfActionResultMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PlanOfActionResult';
    // Attributes
    PlanOfActionResultId = new DwItemAttribute(this.i, 'PlanOfActionResultId');
    CheckRideStartDateTime = new DwItemAttribute(this.i, 'CheckRideStartDateTime');
    CheckRideEndDateTime = new DwItemAttribute(this.i, 'CheckRideEndDateTime');
    TotalTimeGroundMinutes = new DwItemAttribute(this.i, 'TotalTimeGroundMinutes');
    TotalTimeFlightMinute = new DwItemAttribute(this.i, 'TotalTimeFlightMinute');
    CheckRideOutcomeId = new DwItemAttribute(this.i, 'CheckRideOutcomeId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class PlanOfActionResultMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Outcome */
            CheckRideOutcome = new DwItemRelationship('CheckRideOutcome|PlanOfActionResult|CheckRideOutcomeId', 'CheckRideOutcome', 'CheckRideOutcome');
            /** Relation: Plan Of Action */
            PlanOfAction = new DwItemRelationship('PlanOfActionResult|PlanOfAction|PlanOfActionResultId', 'PlanOfAction', 'PlanOfAction');
            /** Relation: Plan Of Action Question Result */
            PlanOfActionQuestionResult = new DwItemRelationship('PlanOfActionResult|PlanOfActionQuestionResult|PlanOfActionResultId', 'PlanOfActionQuestionResult', 'PlanOfActionQuestionResult');
    };
export class PlanOfActionSectionMetaData implements DwItem2<PlanOfActionSectionEntity, PlanOfActionSectionMetaDataAttributes,PlanOfActionSectionMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1214;
        this.ItemDetail.itemName='PlanOfActionSection';
        this.ItemDetail.keyName='PlanOfActionSectionId';
    }

    CreateEntity(): PlanOfActionSectionEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PlanOfActionSectionQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PlanOfActionSectionEntity, PlanOfActionSectionMetaDataAttributes,PlanOfActionSectionMetaDataRelations>(query, this);
    }
     Attributes = new PlanOfActionSectionMetaDataAttributes();
     Relations = new PlanOfActionSectionMetaDataRelations();

}


export type PlanOfActionSectionQueryBuilder = DwQueryBuilderGen<PlanOfActionSectionEntity, PlanOfActionSectionMetaDataAttributes,PlanOfActionSectionMetaDataRelations>


export class PlanOfActionSectionMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PlanOfActionSection';
    // Attributes
    PlanOfActionSectionId = new DwItemAttribute(this.i, 'PlanOfActionSectionId');
    PlanOfActionId = new DwItemAttribute(this.i, 'PlanOfActionId');
    SectionName = new DwItemAttribute(this.i, 'SectionName');
    PlanOfActionSectionTypeId = new DwItemAttribute(this.i, 'PlanOfActionSectionTypeId');
    AcsAreaOfOperationId = new DwItemAttribute(this.i, 'AcsAreaOfOperationId');
    AcsAreaOfOperationTaskId = new DwItemAttribute(this.i, 'AcsAreaOfOperationTaskId');
    SectionOrder = new DwItemAttribute(this.i, 'SectionOrder');
    AcsTestPortionId = new DwItemAttribute(this.i, 'AcsTestPortionId');
}

class PlanOfActionSectionMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Acs Area Of Operation */
            AcsAreaOfOperation = new DwItemRelationship('AcsAreaOfOperation|PlanOfActionSection|AcsAreaOfOperationId', 'AcsAreaOfOperation', 'AcsAreaOfOperation');
            /** Relation: Acs Area Of Operation Task */
            AcsAreaOfOperationTask = new DwItemRelationship('AcsAreaOfOperationTask|PlanOfActionSection|AcsAreaOfOperationTaskId', 'AcsAreaOfOperationTask', 'AcsAreaOfOperationTask');
            /** Relation: Acs Test Portion */
            AcsTestPortion = new DwItemRelationship('AcsTestPortion|PlanOfActionSection|AcsTestPortionId', 'AcsTestPortion', 'AcsTestPortion');
            /** Relation: Plan Of Action */
            PlanOfAction = new DwItemRelationship('PlanOfAction|PlanOfActionSection|PlanOfActionId', 'PlanOfAction', 'PlanOfAction');
            /** Relation: Plan Of Action Section Type */
            PlanOfActionSectionType = new DwItemRelationship('PlanOfActionSectionType|PlanOfActionSection|PlanOfActionSectionTypeId', 'PlanOfActionSectionType', 'PlanOfActionSectionType');
            /** Relation: Plan Of Action Section Question */
            PlanOfActionSectionQuestion = new DwItemRelationship('PlanOfActionSection|PlanOfActionSectionQuestion|PlanOfActionSectionId', 'PlanOfActionSectionQuestion', 'PlanOfActionSectionQuestion');
    };
export class PlanOfActionSectionQuestionMetaData implements DwItem2<PlanOfActionSectionQuestionEntity, PlanOfActionSectionQuestionMetaDataAttributes,PlanOfActionSectionQuestionMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1215;
        this.ItemDetail.itemName='PlanOfActionSectionQuestion';
        this.ItemDetail.keyName='PlanOfActionSectionQuestionId';
    }

    CreateEntity(): PlanOfActionSectionQuestionEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PlanOfActionSectionQuestionQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PlanOfActionSectionQuestionEntity, PlanOfActionSectionQuestionMetaDataAttributes,PlanOfActionSectionQuestionMetaDataRelations>(query, this);
    }
     Attributes = new PlanOfActionSectionQuestionMetaDataAttributes();
     Relations = new PlanOfActionSectionQuestionMetaDataRelations();

}


export type PlanOfActionSectionQuestionQueryBuilder = DwQueryBuilderGen<PlanOfActionSectionQuestionEntity, PlanOfActionSectionQuestionMetaDataAttributes,PlanOfActionSectionQuestionMetaDataRelations>


export class PlanOfActionSectionQuestionMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PlanOfActionSectionQuestion';
    // Attributes
    PlanOfActionSectionQuestionId = new DwItemAttribute(this.i, 'PlanOfActionSectionQuestionId');
    PlanOfActionSectionId = new DwItemAttribute(this.i, 'PlanOfActionSectionId');
    AcsCodeId = new DwItemAttribute(this.i, 'AcsCodeId');
    QuestionHtml = new DwItemAttribute(this.i, 'QuestionHtml');
    QuestionOrder = new DwItemAttribute(this.i, 'QuestionOrder');
}

class PlanOfActionSectionQuestionMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Acs Code */
            AcsCode = new DwItemRelationship('AcsCode|PlanOfActionSectionQuestion|AcsCodeId', 'AcsCode', 'AcsCode');
            /** Relation: Plan Of Action Section */
            PlanOfActionSection = new DwItemRelationship('PlanOfActionSection|PlanOfActionSectionQuestion|PlanOfActionSectionId', 'PlanOfActionSection', 'PlanOfActionSection');
            /** Relation: Plan Of Action Question Result */
            PlanOfActionQuestionResult = new DwItemRelationship('PlanOfActionSectionQuestion|PlanOfActionQuestionResult|PlanOfActionSectionQuestionId', 'PlanOfActionQuestionResult', 'PlanOfActionQuestionResult');
    };
export class PlanOfActionSectionTypeMetaData implements DwItem2<PlanOfActionSectionTypeEntity, PlanOfActionSectionTypeMetaDataAttributes,PlanOfActionSectionTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1213;
        this.ItemDetail.itemName='PlanOfActionSectionType';
        this.ItemDetail.keyName='PlanOfActionSectionTypeId';
    }

    CreateEntity(): PlanOfActionSectionTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PlanOfActionSectionTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PlanOfActionSectionTypeEntity, PlanOfActionSectionTypeMetaDataAttributes,PlanOfActionSectionTypeMetaDataRelations>(query, this);
    }
     Attributes = new PlanOfActionSectionTypeMetaDataAttributes();
     Relations = new PlanOfActionSectionTypeMetaDataRelations();

}


export type PlanOfActionSectionTypeQueryBuilder = DwQueryBuilderGen<PlanOfActionSectionTypeEntity, PlanOfActionSectionTypeMetaDataAttributes,PlanOfActionSectionTypeMetaDataRelations>


export class PlanOfActionSectionTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PlanOfActionSectionType';
    // Attributes
    PlanOfActionSectionTypeId = new DwItemAttribute(this.i, 'PlanOfActionSectionTypeId');
    PlanOfActionSectionType = new DwItemAttribute(this.i, 'PlanOfActionSectionType');
    HasAreaOfOperation = new DwItemAttribute(this.i, 'HasAreaOfOperation');
    HasAreaOfOperationTask = new DwItemAttribute(this.i, 'HasAreaOfOperationTask');
    ShowSkillsOnly = new DwItemAttribute(this.i, 'ShowSkillsOnly');
    AcsTestPortionId = new DwItemAttribute(this.i, 'AcsTestPortionId');
}

class PlanOfActionSectionTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Acs Test Portion */
            AcsTestPortion = new DwItemRelationship('AcsTestPortion|PlanOfActionSectionType|AcsTestPortionId', 'AcsTestPortion', 'AcsTestPortion');
            /** Relation: Plan Of Action Section */
            PlanOfActionSection = new DwItemRelationship('PlanOfActionSectionType|PlanOfActionSection|PlanOfActionSectionTypeId', 'PlanOfActionSection', 'PlanOfActionSection');
    };
export class PlanOfActionStatusMetaData implements DwItem2<PlanOfActionStatusEntity, PlanOfActionStatusMetaDataAttributes,PlanOfActionStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1212;
        this.ItemDetail.itemName='PlanOfActionStatus';
        this.ItemDetail.keyName='PlanOfActionStatusId';
    }

    CreateEntity(): PlanOfActionStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PlanOfActionStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PlanOfActionStatusEntity, PlanOfActionStatusMetaDataAttributes,PlanOfActionStatusMetaDataRelations>(query, this);
    }
     Attributes = new PlanOfActionStatusMetaDataAttributes();
     Relations = new PlanOfActionStatusMetaDataRelations();

}


export type PlanOfActionStatusQueryBuilder = DwQueryBuilderGen<PlanOfActionStatusEntity, PlanOfActionStatusMetaDataAttributes,PlanOfActionStatusMetaDataRelations>


export class PlanOfActionStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PlanOfActionStatus';
    // Attributes
    PlanOfActionStatusId = new DwItemAttribute(this.i, 'PlanOfActionStatusId');
    PlanOfActionStatus = new DwItemAttribute(this.i, 'PlanOfActionStatus');
    PlanOfActionStatusTypeId = new DwItemAttribute(this.i, 'PlanOfActionStatusTypeId');
}

class PlanOfActionStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Plan Of Action Status Type */
            PlanOfActionStatusType = new DwItemRelationship('PlanOfActionStatusType|PlanOfActionStatus|PlanOfActionStatusTypeId', 'PlanOfActionStatusType', 'PlanOfActionStatusType');
            /** Relation: Plan Of Action */
            PlanOfAction = new DwItemRelationship('PlanOfActionStatus|PlanOfAction|PlanOfActionStatusId', 'PlanOfAction', 'PlanOfAction');
    };
export class PlanOfActionStatusTypeMetaData implements DwItem2<PlanOfActionStatusTypeEntity, PlanOfActionStatusTypeMetaDataAttributes,PlanOfActionStatusTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1220;
        this.ItemDetail.itemName='PlanOfActionStatusType';
        this.ItemDetail.keyName='PlanOfActionStatusTypeId';
    }

    CreateEntity(): PlanOfActionStatusTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PlanOfActionStatusTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PlanOfActionStatusTypeEntity, PlanOfActionStatusTypeMetaDataAttributes,PlanOfActionStatusTypeMetaDataRelations>(query, this);
    }
     Attributes = new PlanOfActionStatusTypeMetaDataAttributes();
     Relations = new PlanOfActionStatusTypeMetaDataRelations();

}


export type PlanOfActionStatusTypeQueryBuilder = DwQueryBuilderGen<PlanOfActionStatusTypeEntity, PlanOfActionStatusTypeMetaDataAttributes,PlanOfActionStatusTypeMetaDataRelations>


export class PlanOfActionStatusTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PlanOfActionStatusType';
    // Attributes
    PlanOfActionStatusTypeId = new DwItemAttribute(this.i, 'PlanOfActionStatusTypeId');
    PlanOfActionStatusType = new DwItemAttribute(this.i, 'PlanOfActionStatusType');
}

class PlanOfActionStatusTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Plan Of Action Status */
            PlanOfActionStatus = new DwItemRelationship('PlanOfActionStatusType|PlanOfActionStatus|PlanOfActionStatusTypeId', 'PlanOfActionStatus', 'PlanOfActionStatus');
    };
export class PostalCodeMetaData implements DwItem2<PostalCodeEntity, PostalCodeMetaDataAttributes,PostalCodeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1131;
        this.ItemDetail.itemName='PostalCode';
        this.ItemDetail.keyName='PostalCodeId';
    }

    CreateEntity(): PostalCodeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): PostalCodeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<PostalCodeEntity, PostalCodeMetaDataAttributes,PostalCodeMetaDataRelations>(query, this);
    }
     Attributes = new PostalCodeMetaDataAttributes();
     Relations = new PostalCodeMetaDataRelations();

}


export type PostalCodeQueryBuilder = DwQueryBuilderGen<PostalCodeEntity, PostalCodeMetaDataAttributes,PostalCodeMetaDataRelations>


export class PostalCodeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PostalCode';
    // Attributes
    PostalCodeId = new DwItemAttribute(this.i, 'PostalCodeId');
    StateProvinceId = new DwItemAttribute(this.i, 'StateProvinceId');
    PostalCode = new DwItemAttribute(this.i, 'PostalCode');
    Plus4 = new DwItemAttribute(this.i, 'Plus4');
    City = new DwItemAttribute(this.i, 'City');
    County = new DwItemAttribute(this.i, 'County');
    AreaCode = new DwItemAttribute(this.i, 'AreaCode');
    Latitude = new DwItemAttribute(this.i, 'Latitude');
    Longitude = new DwItemAttribute(this.i, 'Longitude');
    GmtOffset = new DwItemAttribute(this.i, 'GmtOffset');
    ObservesDst = new DwItemAttribute(this.i, 'ObservesDst');
    IsMetropolitanArea = new DwItemAttribute(this.i, 'IsMetropolitanArea');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    TimeZoneId = new DwItemAttribute(this.i, 'TimeZoneId');
}

class PostalCodeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: State Province */
            StateProvince = new DwItemRelationship('StateProvince|PostalCode|StateProvinceId', 'StateProvince', 'StateProvince');
            /** Relation: Time Zone - Timezone */
            TimeZoneByTimeZoneId = new DwItemRelationship('TimeZone|PostalCode|TimeZoneId', 'TimeZoneIdTimeZone', 'TimeZoneIdTimeZone');
    };
export class QuestionBankMetaData implements DwItem2<QuestionBankEntity, QuestionBankMetaDataAttributes,QuestionBankMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1216;
        this.ItemDetail.itemName='QuestionBank';
        this.ItemDetail.keyName='QuestionBankId';
    }

    CreateEntity(): QuestionBankEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuestionBankQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuestionBankEntity, QuestionBankMetaDataAttributes,QuestionBankMetaDataRelations>(query, this);
    }
     Attributes = new QuestionBankMetaDataAttributes();
     Relations = new QuestionBankMetaDataRelations();

}


export type QuestionBankQueryBuilder = DwQueryBuilderGen<QuestionBankEntity, QuestionBankMetaDataAttributes,QuestionBankMetaDataRelations>


export class QuestionBankMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuestionBank';
    // Attributes
    QuestionBankId = new DwItemAttribute(this.i, 'QuestionBankId');
    OwnerUserId = new DwItemAttribute(this.i, 'OwnerUserId');
    AcsTestId = new DwItemAttribute(this.i, 'AcsTestId');
    AcsCodeId = new DwItemAttribute(this.i, 'AcsCodeId');
    QuestionHtml = new DwItemAttribute(this.i, 'QuestionHtml');
    IsBuiltIn = new DwItemAttribute(this.i, 'IsBuiltIn');
    IsShared = new DwItemAttribute(this.i, 'IsShared');
    QuestionShortName = new DwItemAttribute(this.i, 'QuestionShortName');
}

class QuestionBankMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Acs Code */
            AcsCode = new DwItemRelationship('AcsCode|QuestionBank|AcsCodeId', 'AcsCode', 'AcsCode');
            /** Relation: Acs Test */
            AcsTest = new DwItemRelationship('AcsTest|QuestionBank|AcsTestId', 'AcsTest', 'AcsTest');
    };
export class QuestionOutcomeMetaData implements DwItem2<QuestionOutcomeEntity, QuestionOutcomeMetaDataAttributes,QuestionOutcomeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1227;
        this.ItemDetail.itemName='QuestionOutcome';
        this.ItemDetail.keyName='QuestionOutcomeId';
    }

    CreateEntity(): QuestionOutcomeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): QuestionOutcomeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<QuestionOutcomeEntity, QuestionOutcomeMetaDataAttributes,QuestionOutcomeMetaDataRelations>(query, this);
    }
     Attributes = new QuestionOutcomeMetaDataAttributes();
     Relations = new QuestionOutcomeMetaDataRelations();

}


export type QuestionOutcomeQueryBuilder = DwQueryBuilderGen<QuestionOutcomeEntity, QuestionOutcomeMetaDataAttributes,QuestionOutcomeMetaDataRelations>


export class QuestionOutcomeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'QuestionOutcome';
    // Attributes
    QuestionOutcomeId = new DwItemAttribute(this.i, 'QuestionOutcomeId');
    QuestionOutcome = new DwItemAttribute(this.i, 'QuestionOutcome');
    OutcomeOrder = new DwItemAttribute(this.i, 'OutcomeOrder');
}

class QuestionOutcomeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Plan Of Action Question Result */
            PlanOfActionQuestionResult = new DwItemRelationship('QuestionOutcome|PlanOfActionQuestionResult|QuestionOutcomeId', 'PlanOfActionQuestionResult', 'PlanOfActionQuestionResult');
    };
export class RoAirportDistancesMetaData implements DwItem2<RoAirportDistancesEntity, RoAirportDistancesMetaDataAttributes,RoAirportDistancesMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1196;
        this.ItemDetail.itemName='roAirportDistances';
        this.ItemDetail.keyName='';
    }

    CreateEntity(): RoAirportDistancesEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoAirportDistancesQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoAirportDistancesEntity, RoAirportDistancesMetaDataAttributes,RoAirportDistancesMetaDataRelations>(query, this);
    }
     Attributes = new RoAirportDistancesMetaDataAttributes();
     Relations = new RoAirportDistancesMetaDataRelations();

}


export type RoAirportDistancesQueryBuilder = DwQueryBuilderGen<RoAirportDistancesEntity, RoAirportDistancesMetaDataAttributes,RoAirportDistancesMetaDataRelations>


export class RoAirportDistancesMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roAirportDistances';
    // Attributes
    AirportId = new DwItemAttribute(this.i, 'AirportId');
    AirportName = new DwItemAttribute(this.i, 'AirportName');
    Airportcode = new DwItemAttribute(this.i, 'airportcode');
    ReferenceAirportName = new DwItemAttribute(this.i, 'ReferenceAirportName');
    ReferenceAirportCode = new DwItemAttribute(this.i, 'ReferenceAirportCode');
    Distance = new DwItemAttribute(this.i, 'Distance');
    ReferenceAirportId = new DwItemAttribute(this.i, 'ReferenceAirportId');
}

class RoAirportDistancesMetaDataRelations implements DwItemRelationshipCollection {
    };
export class RoCheckrideAggregateUtilMetaData implements DwItem2<RoCheckrideAggregateUtilEntity, RoCheckrideAggregateUtilMetaDataAttributes,RoCheckrideAggregateUtilMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1187;
        this.ItemDetail.itemName='roCheckrideAggregateUtil';
        this.ItemDetail.keyName='CheckrideRequestId';
    }

    CreateEntity(): RoCheckrideAggregateUtilEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoCheckrideAggregateUtilQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoCheckrideAggregateUtilEntity, RoCheckrideAggregateUtilMetaDataAttributes,RoCheckrideAggregateUtilMetaDataRelations>(query, this);
    }
     Attributes = new RoCheckrideAggregateUtilMetaDataAttributes();
     Relations = new RoCheckrideAggregateUtilMetaDataRelations();

}


export type RoCheckrideAggregateUtilQueryBuilder = DwQueryBuilderGen<RoCheckrideAggregateUtilEntity, RoCheckrideAggregateUtilMetaDataAttributes,RoCheckrideAggregateUtilMetaDataRelations>


export class RoCheckrideAggregateUtilMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roCheckrideAggregateUtil';
    // Attributes
    CheckrideRequestId = new DwItemAttribute(this.i, 'CheckrideRequestId');
    ScheduledDateTime = new DwItemAttribute(this.i, 'ScheduledDateTime');
    ScheduledDateTimeYear = new DwItemAttribute(this.i, 'ScheduledDateTimeYear');
    ScheduledDateTimeMonth = new DwItemAttribute(this.i, 'ScheduledDateTimeMonth');
    ScheduledDateTimeMonthDisplay = new DwItemAttribute(this.i, 'ScheduledDateTimeMonthDisplay');
    CheckRideRequestStatusId = new DwItemAttribute(this.i, 'CheckRideRequestStatusId');
    CheckRideRequestStatus = new DwItemAttribute(this.i, 'CheckRideRequestStatus');
    PilexosPaymentDateTimeYear = new DwItemAttribute(this.i, 'PilexosPaymentDateTimeYear');
    PilexosPaymentDateTimeMonth = new DwItemAttribute(this.i, 'PilexosPaymentDateTimeMonth');
    PilexosPaymentTotal = new DwItemAttribute(this.i, 'PilexosPaymentTotal');
    CheckridePaymentDateTimeYear = new DwItemAttribute(this.i, 'CheckridePaymentDateTimeYear');
    CheckridePaymentDateTimeMonth = new DwItemAttribute(this.i, 'CheckridePaymentDateTimeMonth');
    CheckridePaymentTotal = new DwItemAttribute(this.i, 'CheckridePaymentTotal');
    OutcomeIsRequested = new DwItemAttribute(this.i, 'OutcomeIsRequested');
    OutcomeIsBooked = new DwItemAttribute(this.i, 'OutcomeIsBooked');
    OutcomeIsComplete = new DwItemAttribute(this.i, 'OutcomeIsComplete');
    OutcomeIsCancelled = new DwItemAttribute(this.i, 'OutcomeIsCancelled');
    RevenueIsRequested = new DwItemAttribute(this.i, 'RevenueIsRequested');
    RevenueIsBooked = new DwItemAttribute(this.i, 'RevenueIsBooked');
    RevenueIsComplete = new DwItemAttribute(this.i, 'RevenueIsComplete');
    RevenueIsCancelled = new DwItemAttribute(this.i, 'RevenueIsCancelled');
    CheckRideTypeDisplay = new DwItemAttribute(this.i, 'CheckRideTypeDisplay');
    ScheduledDateTimeMonthYearDisplay = new DwItemAttribute(this.i, 'ScheduledDateTimeMonthYearDisplay');
}

class RoCheckrideAggregateUtilMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Request - Check Re Request */
            CheckRideRequestByCheckrideRequestId = new DwItemRelationship('CheckRideRequest|roCheckRideAggregateUtil|CheckRideRequestId', 'CheckrideRequestIdCheckRideRequest', 'CheckrideRequestIdCheckRideRequest');
    };
export class RoCheckrideApplicantFinalNameMetaData implements DwItem2<RoCheckrideApplicantFinalNameEntity, RoCheckrideApplicantFinalNameMetaDataAttributes,RoCheckrideApplicantFinalNameMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1184;
        this.ItemDetail.itemName='roCheckrideApplicantFinalName';
        this.ItemDetail.keyName='';
    }

    CreateEntity(): RoCheckrideApplicantFinalNameEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoCheckrideApplicantFinalNameQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoCheckrideApplicantFinalNameEntity, RoCheckrideApplicantFinalNameMetaDataAttributes,RoCheckrideApplicantFinalNameMetaDataRelations>(query, this);
    }
     Attributes = new RoCheckrideApplicantFinalNameMetaDataAttributes();
     Relations = new RoCheckrideApplicantFinalNameMetaDataRelations();

}


export type RoCheckrideApplicantFinalNameQueryBuilder = DwQueryBuilderGen<RoCheckrideApplicantFinalNameEntity, RoCheckrideApplicantFinalNameMetaDataAttributes,RoCheckrideApplicantFinalNameMetaDataRelations>


export class RoCheckrideApplicantFinalNameMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roCheckrideApplicantFinalName';
    // Attributes
    CheckRideRequestId = new DwItemAttribute(this.i, 'CheckRideRequestId');
    StudentApplicantNameManual = new DwItemAttribute(this.i, 'StudentApplicantNameManual');
    StudentProfileName = new DwItemAttribute(this.i, 'StudentProfileName');
    ApplicantFinalName = new DwItemAttribute(this.i, 'ApplicantFinalName');
    CheckRideApplicantTypeId = new DwItemAttribute(this.i, 'CheckRideApplicantTypeId');
    ApplicantFinalEmail = new DwItemAttribute(this.i, 'ApplicantFinalEmail');
    ApplicantFinalPhone = new DwItemAttribute(this.i, 'ApplicantFinalPhone');
    FlightSchoolFinalName = new DwItemAttribute(this.i, 'FlightSchoolFinalName');
}

class RoCheckrideApplicantFinalNameMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('CheckRideRequest|roCheckRideApplicanetFinalName|CheckRideRequestId', 'CheckRideRequest', 'CheckRideRequest');
    };
export class RoCheckRidePaymentAggregateMetaData implements DwItem2<RoCheckRidePaymentAggregateEntity, RoCheckRidePaymentAggregateMetaDataAttributes,RoCheckRidePaymentAggregateMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1188;
        this.ItemDetail.itemName='roCheckRidePaymentAggregate';
        this.ItemDetail.keyName='CheckrideRequestId';
    }

    CreateEntity(): RoCheckRidePaymentAggregateEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoCheckRidePaymentAggregateQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoCheckRidePaymentAggregateEntity, RoCheckRidePaymentAggregateMetaDataAttributes,RoCheckRidePaymentAggregateMetaDataRelations>(query, this);
    }
     Attributes = new RoCheckRidePaymentAggregateMetaDataAttributes();
     Relations = new RoCheckRidePaymentAggregateMetaDataRelations();

}


export type RoCheckRidePaymentAggregateQueryBuilder = DwQueryBuilderGen<RoCheckRidePaymentAggregateEntity, RoCheckRidePaymentAggregateMetaDataAttributes,RoCheckRidePaymentAggregateMetaDataRelations>


export class RoCheckRidePaymentAggregateMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roCheckRidePaymentAggregate';
    // Attributes
    CheckrideRequestId = new DwItemAttribute(this.i, 'CheckrideRequestId');
    PaymentDateTimeYear = new DwItemAttribute(this.i, 'PaymentDateTimeYear');
    PaymentDateTimeMonth = new DwItemAttribute(this.i, 'PaymentDateTimeMonth');
    IsCheckridePayment = new DwItemAttribute(this.i, 'IsCheckridePayment');
    Count = new DwItemAttribute(this.i, 'Count');
    PaymentTotal = new DwItemAttribute(this.i, 'PaymentTotal');
}

class RoCheckRidePaymentAggregateMetaDataRelations implements DwItemRelationshipCollection {
    };
export class RoCheckrideRequestScheduleDateInfoMetaData implements DwItem2<RoCheckrideRequestScheduleDateInfoEntity, RoCheckrideRequestScheduleDateInfoMetaDataAttributes,RoCheckrideRequestScheduleDateInfoMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1186;
        this.ItemDetail.itemName='roCheckrideRequestScheduleDateInfo';
        this.ItemDetail.keyName='CheckrideRequestId';
    }

    CreateEntity(): RoCheckrideRequestScheduleDateInfoEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoCheckrideRequestScheduleDateInfoQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoCheckrideRequestScheduleDateInfoEntity, RoCheckrideRequestScheduleDateInfoMetaDataAttributes,RoCheckrideRequestScheduleDateInfoMetaDataRelations>(query, this);
    }
     Attributes = new RoCheckrideRequestScheduleDateInfoMetaDataAttributes();
     Relations = new RoCheckrideRequestScheduleDateInfoMetaDataRelations();

}


export type RoCheckrideRequestScheduleDateInfoQueryBuilder = DwQueryBuilderGen<RoCheckrideRequestScheduleDateInfoEntity, RoCheckrideRequestScheduleDateInfoMetaDataAttributes,RoCheckrideRequestScheduleDateInfoMetaDataRelations>


export class RoCheckrideRequestScheduleDateInfoMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roCheckrideRequestScheduleDateInfo';
    // Attributes
    ScheduledDateTime = new DwItemAttribute(this.i, 'ScheduledDateTime');
    ScheduledDateTimeYear = new DwItemAttribute(this.i, 'ScheduledDateTimeYear');
    ScheduledDateTimeMonth = new DwItemAttribute(this.i, 'ScheduledDateTimeMonth');
    CheckrideRequestId = new DwItemAttribute(this.i, 'CheckrideRequestId');
    ScheduledDateTimeMonthDisplay = new DwItemAttribute(this.i, 'ScheduledDateTimeMonthDisplay');
}

class RoCheckrideRequestScheduleDateInfoMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Checkride Request - Check Re Request */
            CheckRideRequestByCheckrideRequestId = new DwItemRelationship('CheckrideRequest|roCheckrideRequestScheduleDateInfo|CheckRideRequestId', 'CheckrideRequestIdCheckRideRequest', 'CheckrideRequestIdCheckRideRequest');
    };
export class DwRoMediaMetaData implements DwItem2<EnterpriseMetaData.DwRoMediaEntity, DwRoMediaMetaDataAttributes,DwRoMediaMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=201;
        this.ItemDetail.itemName='dw_roMedia';
        this.ItemDetail.keyName='';
    }

    CreateEntity(): EnterpriseMetaData.DwRoMediaEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwRoMediaQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<EnterpriseMetaData.DwRoMediaEntity, DwRoMediaMetaDataAttributes,DwRoMediaMetaDataRelations>(query, this);
    }
     Attributes = new DwRoMediaMetaDataAttributes();
     Relations = new DwRoMediaMetaDataRelations();

}


export type DwRoMediaQueryBuilder = DwQueryBuilderGen<EnterpriseMetaData.DwRoMediaEntity, DwRoMediaMetaDataAttributes,DwRoMediaMetaDataRelations>


export class DwRoMediaMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_roMedia';
    // Attributes
    ContainerName = new DwItemAttribute(this.i, 'ContainerName');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    FileName = new DwItemAttribute(this.i, 'FileName');
    ImageHeight = new DwItemAttribute(this.i, 'ImageHeight');
    ImageWidth = new DwItemAttribute(this.i, 'ImageWidth');
    IsDeleted = new DwItemAttribute(this.i, 'IsDeleted');
    IsSecure = new DwItemAttribute(this.i, 'IsSecure');
    MediaDetailKey = new DwItemAttribute(this.i, 'MediaDetailKey');
    MediaFolderId = new DwItemAttribute(this.i, 'MediaFolderId');
    MediaGroupId = new DwItemAttribute(this.i, 'MediaGroupId');
    MediaId = new DwItemAttribute(this.i, 'MediaId');
    MediaName = new DwItemAttribute(this.i, 'MediaName');
    MediaPath = new DwItemAttribute(this.i, 'MediaPath');
    MediaSizeType = new DwItemAttribute(this.i, 'MediaSizeType');
    MediaSizeTypeId = new DwItemAttribute(this.i, 'MediaSizeTypeId');
    MediaTypeId = new DwItemAttribute(this.i, 'MediaTypeId');
    SizeInBytes = new DwItemAttribute(this.i, 'SizeInBytes');
    Version = new DwItemAttribute(this.i, 'Version');
}

class DwRoMediaMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Media Group */
            DwMediaGroup = new DwItemRelationship('MediaGroup_roMedia', 'DwMediaGroup', 'DwMediaGroup');
            /** Relation: User - Media */
            DwUserByUserPictureMediaId = new DwItemRelationship('roMedia|User|UserPictureMediaId', 'UserPictureDwUser', 'UserPictureDwUser');
    };
export class RoPilotExaminerLocationCurrentMetaData implements DwItem2<RoPilotExaminerLocationCurrentEntity, RoPilotExaminerLocationCurrentMetaDataAttributes,RoPilotExaminerLocationCurrentMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1167;
        this.ItemDetail.itemName='roPilotExaminerLocationCurrent';
        this.ItemDetail.keyName='PilotExaminerLocationId';
    }

    CreateEntity(): RoPilotExaminerLocationCurrentEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoPilotExaminerLocationCurrentQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoPilotExaminerLocationCurrentEntity, RoPilotExaminerLocationCurrentMetaDataAttributes,RoPilotExaminerLocationCurrentMetaDataRelations>(query, this);
    }
     Attributes = new RoPilotExaminerLocationCurrentMetaDataAttributes();
     Relations = new RoPilotExaminerLocationCurrentMetaDataRelations();

}


export type RoPilotExaminerLocationCurrentQueryBuilder = DwQueryBuilderGen<RoPilotExaminerLocationCurrentEntity, RoPilotExaminerLocationCurrentMetaDataAttributes,RoPilotExaminerLocationCurrentMetaDataRelations>


export class RoPilotExaminerLocationCurrentMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roPilotExaminerLocationCurrent';
    // Attributes
    PilotExaminerLocationId = new DwItemAttribute(this.i, 'PilotExaminerLocationId');
    PilotExaminerLocationStateId = new DwItemAttribute(this.i, 'PilotExaminerLocationStateId');
}

class RoPilotExaminerLocationCurrentMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Pilot Examiner Location */
            PilotExaminerLocation = new DwItemRelationship('PilotExaminerLocation|roPilotExaminerLocationCurrent', 'PilotExaminerLocation', 'PilotExaminerLocation');
    };
export class RoPilotExaminerLocationDistancesMetaData implements DwItem2<RoPilotExaminerLocationDistancesEntity, RoPilotExaminerLocationDistancesMetaDataAttributes,RoPilotExaminerLocationDistancesMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1165;
        this.ItemDetail.itemName='roPilotExaminerLocationDistances';
        this.ItemDetail.keyName='';
    }

    CreateEntity(): RoPilotExaminerLocationDistancesEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoPilotExaminerLocationDistancesQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoPilotExaminerLocationDistancesEntity, RoPilotExaminerLocationDistancesMetaDataAttributes,RoPilotExaminerLocationDistancesMetaDataRelations>(query, this);
    }
     Attributes = new RoPilotExaminerLocationDistancesMetaDataAttributes();
     Relations = new RoPilotExaminerLocationDistancesMetaDataRelations();

}


export type RoPilotExaminerLocationDistancesQueryBuilder = DwQueryBuilderGen<RoPilotExaminerLocationDistancesEntity, RoPilotExaminerLocationDistancesMetaDataAttributes,RoPilotExaminerLocationDistancesMetaDataRelations>


export class RoPilotExaminerLocationDistancesMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roPilotExaminerLocationDistances';
    // Attributes
    ReferenceAirportId = new DwItemAttribute(this.i, 'ReferenceAirportId');
    ReferenceAirportName = new DwItemAttribute(this.i, 'ReferenceAirportName');
    ReferenceAirportLatitude = new DwItemAttribute(this.i, 'ReferenceAirportLatitude');
    ReferenceAirportLongitude = new DwItemAttribute(this.i, 'ReferenceAirportLongitude');
    AirportId = new DwItemAttribute(this.i, 'AirportId');
    AirportName = new DwItemAttribute(this.i, 'AirportName');
    Latitude = new DwItemAttribute(this.i, 'Latitude');
    Longitude = new DwItemAttribute(this.i, 'Longitude');
    Distance = new DwItemAttribute(this.i, 'Distance');
    PilotExaminerLocationId = new DwItemAttribute(this.i, 'PilotExaminerLocationId');
    PilotExaminerId = new DwItemAttribute(this.i, 'PilotExaminerId');
    WithinTravelRadius = new DwItemAttribute(this.i, 'WithinTravelRadius');
    TravelRadiusMile = new DwItemAttribute(this.i, 'TravelRadiusMile');
}

class RoPilotExaminerLocationDistancesMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Airport */
            Airport = new DwItemRelationship('RoPilotExaminerLocationDistances|Airport|AirportId', 'Airport', 'Airport');
            /** Relation: Pilot Examiner */
            PilotExaminer = new DwItemRelationship('PilotExaminer|RoPilotExaminerLocation', 'PilotExaminer', 'PilotExaminer');
            /** Relation: Pilot Examiner Location */
            PilotExaminerLocation = new DwItemRelationship('PIlotExaminerLocation|RoPilotExaminerLocation', 'PilotExaminerLocation', 'PilotExaminerLocation');
    };
export class RoPilotExaminerScenarioMetaData implements DwItem2<RoPilotExaminerScenarioEntity, RoPilotExaminerScenarioMetaDataAttributes,RoPilotExaminerScenarioMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1166;
        this.ItemDetail.itemName='roPilotExaminerScenario';
        this.ItemDetail.keyName='CheckRideScenarioTypeMapId';
    }

    CreateEntity(): RoPilotExaminerScenarioEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoPilotExaminerScenarioQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoPilotExaminerScenarioEntity, RoPilotExaminerScenarioMetaDataAttributes,RoPilotExaminerScenarioMetaDataRelations>(query, this);
    }
     Attributes = new RoPilotExaminerScenarioMetaDataAttributes();
     Relations = new RoPilotExaminerScenarioMetaDataRelations();

}


export type RoPilotExaminerScenarioQueryBuilder = DwQueryBuilderGen<RoPilotExaminerScenarioEntity, RoPilotExaminerScenarioMetaDataAttributes,RoPilotExaminerScenarioMetaDataRelations>


export class RoPilotExaminerScenarioMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roPilotExaminerScenario';
    // Attributes
    PilotExaminerId = new DwItemAttribute(this.i, 'PilotExaminerId');
    UserId = new DwItemAttribute(this.i, 'userId');
    PilotExaminerStatusId = new DwItemAttribute(this.i, 'PilotExaminerStatusId');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    CheckRideScenarioId = new DwItemAttribute(this.i, 'CheckRideScenarioId');
    CheckRideGroupId = new DwItemAttribute(this.i, 'CheckRideGroupId');
    PreTimeMinutes = new DwItemAttribute(this.i, 'PreTimeMinutes');
    PostTimeMinutes = new DwItemAttribute(this.i, 'PostTimeMinutes');
    IsActive = new DwItemAttribute(this.i, 'IsActive');
    TimeRequiredHours = new DwItemAttribute(this.i, 'TimeRequiredHours');
    CheckRideTypeId = new DwItemAttribute(this.i, 'CheckRideTypeId');
    CheckRideGroup = new DwItemAttribute(this.i, 'CheckRideGroup');
    CheckRideType = new DwItemAttribute(this.i, 'CheckRideType');
    CheckRideScenarioTypeMapId = new DwItemAttribute(this.i, 'CheckRideScenarioTypeMapId');
}

class RoPilotExaminerScenarioMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Exam Type */
            CheckRideScenario = new DwItemRelationship('CheckRideScenario|roPilotExaminerScenario', 'CheckRideScenario', 'CheckRideScenario');
            /** Relation: Pilot Examiner */
            PilotExaminer = new DwItemRelationship('PIlotExaminer|RoPilotExaminerScenario', 'PilotExaminer', 'PilotExaminer');
            /** Relation: User - Examiner Name */
            DwUserByuserId = new DwItemRelationship('User|RoPilotExaminer|UserId', 'UserIdDwUser', 'UserIdDwUser');
    };
export class ScheduleMetaData implements DwItem2<ScheduleEntity, ScheduleMetaDataAttributes,ScheduleMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1139;
        this.ItemDetail.itemName='Schedule';
        this.ItemDetail.keyName='ScheduleId';
    }

    CreateEntity(): ScheduleEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ScheduleQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ScheduleEntity, ScheduleMetaDataAttributes,ScheduleMetaDataRelations>(query, this);
    }
     Attributes = new ScheduleMetaDataAttributes();
     Relations = new ScheduleMetaDataRelations();

}


export type ScheduleQueryBuilder = DwQueryBuilderGen<ScheduleEntity, ScheduleMetaDataAttributes,ScheduleMetaDataRelations>


export class ScheduleMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Schedule';
    // Attributes
    ScheduleId = new DwItemAttribute(this.i, 'ScheduleId');
    ScheduleName = new DwItemAttribute(this.i, 'ScheduleName');
    DefaultStartTime = new DwItemAttribute(this.i, 'DefaultStartTime');
    DefaultEndTime = new DwItemAttribute(this.i, 'DefaultEndTime');
    WeekDaysOnly = new DwItemAttribute(this.i, 'WeekDaysOnly');
    UseCustomDays = new DwItemAttribute(this.i, 'UseCustomDays');
    ScheduleOwnerUserId = new DwItemAttribute(this.i, 'ScheduleOwnerUserId');
    IsBuiltInSchedule = new DwItemAttribute(this.i, 'IsBuiltInSchedule');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
}

class ScheduleMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: User - Calendar Owner User */
            DwUserByScheduleOwnerUserId = new DwItemRelationship('dw_User|Schedule|ScheduleOwnerUserId', 'ScheduleOwnerDwUser', 'ScheduleOwnerDwUser');
            /** Relation: Pilot Examiner Location - Calendar Id - Many */
            PilotExaminerLocationByBookingScheduleId = new DwItemRelationship('Schedule|PilotExaminerLocation|BookingScheduleId', 'BookingPilotExaminerLocation', 'BookingPilotExaminerLocation');
            /** Relation: Calendar Detail */
            ScheduleDetail = new DwItemRelationship('Schedule|ScheduleDetail|ScheduleId', 'ScheduleDetail', 'ScheduleDetail');
    };
export class ScheduleDayOfWeekMetaData implements DwItem2<ScheduleDayOfWeekEntity, ScheduleDayOfWeekMetaDataAttributes,ScheduleDayOfWeekMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1138;
        this.ItemDetail.itemName='ScheduleDayOfWeek';
        this.ItemDetail.keyName='ScheduleDayOfWeekId';
    }

    CreateEntity(): ScheduleDayOfWeekEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ScheduleDayOfWeekQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ScheduleDayOfWeekEntity, ScheduleDayOfWeekMetaDataAttributes,ScheduleDayOfWeekMetaDataRelations>(query, this);
    }
     Attributes = new ScheduleDayOfWeekMetaDataAttributes();
     Relations = new ScheduleDayOfWeekMetaDataRelations();

}


export type ScheduleDayOfWeekQueryBuilder = DwQueryBuilderGen<ScheduleDayOfWeekEntity, ScheduleDayOfWeekMetaDataAttributes,ScheduleDayOfWeekMetaDataRelations>


export class ScheduleDayOfWeekMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ScheduleDayOfWeek';
    // Attributes
    ScheduleDayOfWeekId = new DwItemAttribute(this.i, 'ScheduleDayOfWeekId');
    DayOfWeek = new DwItemAttribute(this.i, 'DayOfWeek');
    DayOfWeekOrder = new DwItemAttribute(this.i, 'DayOfWeekOrder');
}

class ScheduleDayOfWeekMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Calendar Detail */
            ScheduleDetail = new DwItemRelationship('ScheduleDayOfWeek|ScheduleDetail|ScheduleDayOfWeekId', 'ScheduleDetail', 'ScheduleDetail');
    };
export class ScheduleDetailMetaData implements DwItem2<ScheduleDetailEntity, ScheduleDetailMetaDataAttributes,ScheduleDetailMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1140;
        this.ItemDetail.itemName='ScheduleDetail';
        this.ItemDetail.keyName='ScheduleDetailId';
    }

    CreateEntity(): ScheduleDetailEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ScheduleDetailQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ScheduleDetailEntity, ScheduleDetailMetaDataAttributes,ScheduleDetailMetaDataRelations>(query, this);
    }
     Attributes = new ScheduleDetailMetaDataAttributes();
     Relations = new ScheduleDetailMetaDataRelations();

}


export type ScheduleDetailQueryBuilder = DwQueryBuilderGen<ScheduleDetailEntity, ScheduleDetailMetaDataAttributes,ScheduleDetailMetaDataRelations>


export class ScheduleDetailMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ScheduleDetail';
    // Attributes
    ScheduleDetailId = new DwItemAttribute(this.i, 'ScheduleDetailId');
    ScheduleId = new DwItemAttribute(this.i, 'ScheduleId');
    ScheduleDayOfWeekId = new DwItemAttribute(this.i, 'ScheduleDayOfWeekId');
    StartTime = new DwItemAttribute(this.i, 'StartTime');
    EndTime = new DwItemAttribute(this.i, 'EndTime');
}

class ScheduleDetailMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Custom Calendar */
            Schedule = new DwItemRelationship('Schedule|ScheduleDetail|ScheduleId', 'Schedule', 'Schedule');
            /** Relation: Calendar Day Of Week */
            ScheduleDayOfWeek = new DwItemRelationship('ScheduleDayOfWeek|ScheduleDetail|ScheduleDayOfWeekId', 'ScheduleDayOfWeek', 'ScheduleDayOfWeek');
    };
export class StateProvinceMetaData implements DwItem2<StateProvinceEntity, StateProvinceMetaDataAttributes,StateProvinceMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1158;
        this.ItemDetail.itemName='StateProvince';
        this.ItemDetail.keyName='StateProvinceId';
    }

    CreateEntity(): StateProvinceEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): StateProvinceQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<StateProvinceEntity, StateProvinceMetaDataAttributes,StateProvinceMetaDataRelations>(query, this);
    }
     Attributes = new StateProvinceMetaDataAttributes();
     Relations = new StateProvinceMetaDataRelations();

}


export type StateProvinceQueryBuilder = DwQueryBuilderGen<StateProvinceEntity, StateProvinceMetaDataAttributes,StateProvinceMetaDataRelations>


export class StateProvinceMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'StateProvince';
    // Attributes
    StateProvinceId = new DwItemAttribute(this.i, 'StateProvinceId');
    CountryId = new DwItemAttribute(this.i, 'CountryId');
    StateProvinceName = new DwItemAttribute(this.i, 'StateProvinceName');
    StateProvinceShortName = new DwItemAttribute(this.i, 'StateProvinceShortName');
    StateCode = new DwItemAttribute(this.i, 'StateCode');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
}

class StateProvinceMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Country */
            Country = new DwItemRelationship('Country|StateProvince|CountryId', 'Country', 'Country');
            /** Relation: Address */
            Address = new DwItemRelationship('StateProvince|Address|StateProvinceId', 'Address', 'Address');
            /** Relation: Postal Code */
            PostalCode = new DwItemRelationship('StateProvince|PostalCode|StateProvinceId', 'PostalCode', 'PostalCode');
    };
export class StudentApplicantMetaData implements DwItem2<StudentApplicantEntity, StudentApplicantMetaDataAttributes,StudentApplicantMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1144;
        this.ItemDetail.itemName='StudentApplicant';
        this.ItemDetail.keyName='StudentApplicantId';
    }

    CreateEntity(): StudentApplicantEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): StudentApplicantQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<StudentApplicantEntity, StudentApplicantMetaDataAttributes,StudentApplicantMetaDataRelations>(query, this);
    }
     Attributes = new StudentApplicantMetaDataAttributes();
     Relations = new StudentApplicantMetaDataRelations();

}


export type StudentApplicantQueryBuilder = DwQueryBuilderGen<StudentApplicantEntity, StudentApplicantMetaDataAttributes,StudentApplicantMetaDataRelations>


export class StudentApplicantMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'StudentApplicant';
    // Attributes
    StudentApplicantId = new DwItemAttribute(this.i, 'StudentApplicantId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    ApplicationDescription = new DwItemAttribute(this.i, 'ApplicationDescription');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    PilotCertificateNumber = new DwItemAttribute(this.i, 'PilotCertificateNumber');
    TravelRadiusMiles = new DwItemAttribute(this.i, 'TravelRadiusMiles');
    Citizenship = new DwItemAttribute(this.i, 'Citizenship');
    ApplicantAddressId = new DwItemAttribute(this.i, 'ApplicantAddressId');
    MainAirportId = new DwItemAttribute(this.i, 'MainAirportId');
    MainAircraft = new DwItemAttribute(this.i, 'MainAircraft');
    ReferredPilotExaminerId = new DwItemAttribute(this.i, 'ReferredPilotExaminerId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    AviationTrainingProgramId = new DwItemAttribute(this.i, 'AviationTrainingProgramId');
    CitzenshipCountyId = new DwItemAttribute(this.i, 'CitzenshipCountyId');
}

class StudentApplicantMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Address - Applicant Address */
            AddressByApplicantAddressId = new DwItemRelationship('Address|StudentApplicant|ApplicantAddressId', 'ApplicantAddress', 'ApplicantAddress');
            /** Relation: Airport - Main Airport */
            AirportByMainAirportId = new DwItemRelationship('Airport|StudentApplicant|MainAirportId', 'MainAirport', 'MainAirport');
            /** Relation: Aviation Authority */
            AviationAuthority = new DwItemRelationship('AviationAuthority|StudentApplicant|AviationAuthorityId', 'AviationAuthority', 'AviationAuthority');
            /** Relation: Aviation Training Program */
            AviationTrainingProgram = new DwItemRelationship('AviationTrainingProgram|StudentApplicant|AviationTrainingProgramId', 'AviationTrainingProgram', 'AviationTrainingProgram');
            /** Relation: Country - Citzenship */
            CountryByCitzenshipCountyId = new DwItemRelationship('Country|StudentApplicant|CitzenshipCountyId', 'CitzenshipCountyIdCountry', 'CitzenshipCountyIdCountry');
            /** Relation: Pilot Certificate Type - CurrentPilotCertifiateTypeId */
            PilotCertificateTypeByCurrentPilotCertifiateTypeId = new DwItemRelationship('PilotCertificateType|StudentApplicant|CurrentPilotCertifiateTypeId', 'CurrentPilotCertifiateTypeIdPilotCertificateType', 'CurrentPilotCertifiateTypeIdPilotCertificateType');
            /** Relation: Pilot Examiner - Referred Pilot Examiner */
            PilotExaminerByReferredPilotExaminerId = new DwItemRelationship('PilotExaminer|StudentApplicant|ReferredPilotExaminerId', 'ReferredPilotExaminer', 'ReferredPilotExaminer');
            /** Relation: User */
            DwUser = new DwItemRelationship('dw_User|StudentApplicant|UserId', 'DwUser', 'DwUser');
            /** Relation: Applicant Wait List */
            ApplicantPlaceholder = new DwItemRelationship('StudentApplicant|ApplicantPlaceholder|StudentApplicantId', 'ApplicantPlaceholder', 'ApplicantPlaceholder');
            /** Relation: Checkride Request */
            CheckRideRequest = new DwItemRelationship('StudentApplicant|CheckRideRequest|StudentApplicantId', 'CheckRideRequest', 'CheckRideRequest');
    };
export class TimeZoneMetaData implements DwItem2<TimeZoneEntity, TimeZoneMetaDataAttributes,TimeZoneMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1132;
        this.ItemDetail.itemName='TimeZone';
        this.ItemDetail.keyName='TimezoneId';
    }

    CreateEntity(): TimeZoneEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): TimeZoneQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<TimeZoneEntity, TimeZoneMetaDataAttributes,TimeZoneMetaDataRelations>(query, this);
    }
     Attributes = new TimeZoneMetaDataAttributes();
     Relations = new TimeZoneMetaDataRelations();

}


export type TimeZoneQueryBuilder = DwQueryBuilderGen<TimeZoneEntity, TimeZoneMetaDataAttributes,TimeZoneMetaDataRelations>


export class TimeZoneMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'TimeZone';
    // Attributes
    TimezoneId = new DwItemAttribute(this.i, 'TimezoneId');
    TimezoneName = new DwItemAttribute(this.i, 'TimezoneName');
    TimezoneShortName = new DwItemAttribute(this.i, 'TimezoneShortName');
    GmtOffSet = new DwItemAttribute(this.i, 'GmtOffSet');
    TimeZoneDescription = new DwItemAttribute(this.i, 'TimeZoneDescription');
    IANAIdentifier = new DwItemAttribute(this.i, 'IANAIdentifier');
    DotNetBCLIdentifier = new DwItemAttribute(this.i, 'DotNetBCLIdentifier');
}

class TimeZoneMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Address */
            Address = new DwItemRelationship('TimeZone|Address|TimezoneId', 'Address', 'Address');
            /** Relation: Pilot Examiner Location - Booking Schedule - Many */
            PilotExaminerLocationByBookingScheduleTimeZoneId = new DwItemRelationship('TimeZone|PilotExaminerLocation|BookingScheduleTimeZoneId', 'BookingScheduleTimeZoneIdPilotExaminerLocation', 'BookingScheduleTimeZoneIdPilotExaminerLocation');
            /** Relation: Postal Code - Many */
            PostalCodeByTimeZoneId = new DwItemRelationship('TimeZone|PostalCode|TimeZoneId', 'TimeZoneIdPostalCode', 'TimeZoneIdPostalCode');
    };
export class DwUserMetaData implements DwItem2<EnterpriseMetaData.DwUserEntity, DwUserMetaDataAttributes,DwUserMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=176;
        this.ItemDetail.itemName='dw_User';
        this.ItemDetail.keyName='UserId';
    }

    CreateEntity(): EnterpriseMetaData.DwUserEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwUserQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<EnterpriseMetaData.DwUserEntity, DwUserMetaDataAttributes,DwUserMetaDataRelations>(query, this);
    }
     Attributes = new DwUserMetaDataAttributes();
     Relations = new DwUserMetaDataRelations();

}


export type DwUserQueryBuilder = DwQueryBuilderGen<EnterpriseMetaData.DwUserEntity, DwUserMetaDataAttributes,DwUserMetaDataRelations>


export class DwUserMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_User';
    // Attributes
    AlternateName = new DwItemAttribute(this.i, 'AlternateName');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    Email = new DwItemAttribute(this.i, 'Email');
    FirstName = new DwItemAttribute(this.i, 'FirstName');
    ForcePasswordChange = new DwItemAttribute(this.i, 'ForcePasswordChange');
    IsActive = new DwItemAttribute(this.i, 'IsActive');
    IsEmailVerified = new DwItemAttribute(this.i, 'IsEmailVerified');
    IsTwoFactorAuthenticationEnabled = new DwItemAttribute(this.i, 'IsTwoFactorAuthenticationEnabled');
    LastLoginDateTime = new DwItemAttribute(this.i, 'LastLoginDateTime');
    LastName = new DwItemAttribute(this.i, 'LastName');
    LastPasswordChangeDateTime = new DwItemAttribute(this.i, 'LastPasswordChangeDateTime');
    MobilePhone = new DwItemAttribute(this.i, 'MobilePhone');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    OrganizationId = new DwItemAttribute(this.i, 'OrganizationId');
    PartyReferenceId = new DwItemAttribute(this.i, 'PartyReferenceId');
    TwoFactorAuthenticationTypeId = new DwItemAttribute(this.i, 'TwoFactorAuthenticationTypeId');
    UserBackgroundMediaId = new DwItemAttribute(this.i, 'UserBackgroundMediaId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    UserKey = new DwItemAttribute(this.i, 'UserKey');
    UserName = new DwItemAttribute(this.i, 'UserName');
    UserPictureMediaId = new DwItemAttribute(this.i, 'UserPictureMediaId');
    UserTypeId = new DwItemAttribute(this.i, 'UserTypeId');
}

class DwUserMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Ro Media - User Picture Media */
            DwRoMediaByUserPictureMediaId = new DwItemRelationship('roMedia|User|UserPictureMediaId', 'UserPictureDwroMedia', 'UserPictureDwroMedia');
            /** Relation: User Type */
            DwUserType = new DwItemRelationship('dw_UserType|dw_User|UserTypeId', 'DwUserType', 'DwUserType');
            /** Relation: Payment Customer Reference */
            PaymentCustomerReference = new DwItemRelationship('dw_User|PaymentCustomerReference|UserId', 'PaymentCustomerReference', 'PaymentCustomerReference');
            /** Relation: Payment Discount Code - Limit To - Many */
            PaymentDiscountCodeByLimitToUserId = new DwItemRelationship('dw_User|PaymentDiscountCode|LimitToUserId', 'LimitToPaymentDiscountCode', 'LimitToPaymentDiscountCode');
            /** Relation: Pilot Examiner */
            PilotExaminer = new DwItemRelationship('dw_User|PilotExaminer|UserId', 'PilotExaminer', 'PilotExaminer');
            /** Relation: Ro Pilot Examiner Scenario - User - Many */
            RoPilotExaminerScenarioByuserId = new DwItemRelationship('User|RoPilotExaminer|UserId', 'UserIdRoPilotExaminerScenario', 'UserIdRoPilotExaminerScenario');
            /** Relation: Custom Calendar - Schedule Owner - Many */
            ScheduleByScheduleOwnerUserId = new DwItemRelationship('dw_User|Schedule|ScheduleOwnerUserId', 'ScheduleOwnerSchedule', 'ScheduleOwnerSchedule');
            /** Relation: Student Applicant */
            StudentApplicant = new DwItemRelationship('dw_User|StudentApplicant|UserId', 'StudentApplicant', 'StudentApplicant');
    };
export class DwUserTypeMetaData implements DwItem2<EnterpriseMetaData.DwUserTypeEntity, DwUserTypeMetaDataAttributes,DwUserTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=1162;
        this.ItemDetail.itemName='dw_UserType';
        this.ItemDetail.keyName='UserTypeId';
    }

    CreateEntity(): EnterpriseMetaData.DwUserTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): DwUserTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<EnterpriseMetaData.DwUserTypeEntity, DwUserTypeMetaDataAttributes,DwUserTypeMetaDataRelations>(query, this);
    }
     Attributes = new DwUserTypeMetaDataAttributes();
     Relations = new DwUserTypeMetaDataRelations();

}


export type DwUserTypeQueryBuilder = DwQueryBuilderGen<EnterpriseMetaData.DwUserTypeEntity, DwUserTypeMetaDataAttributes,DwUserTypeMetaDataRelations>


export class DwUserTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'dw_UserType';
    // Attributes
    UserTypeId = new DwItemAttribute(this.i, 'UserTypeId');
    UserTypeDisplay = new DwItemAttribute(this.i, 'UserTypeDisplay');
    UserTypeDescription = new DwItemAttribute(this.i, 'UserTypeDescription');
    UserTypeClasses = new DwItemAttribute(this.i, 'UserTypeClasses');
    ShowOnRegistration = new DwItemAttribute(this.i, 'ShowOnRegistration');
    UserTypeOrder = new DwItemAttribute(this.i, 'UserTypeOrder');
}

class DwUserTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: User */
            DwUser = new DwItemRelationship('dw_UserType|dw_User|UserTypeId', 'DwUser', 'DwUser');
    };
// Generated Query Classes for MetaData Service
export class PilotExaminerSearchQueryMetaData implements DwItem2<PilotExaminerSearchQueryEntity, PilotExaminerSearchQueryMetaDataAttributes,PilotExaminerMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Pilot_Examiner_Search';

    constructor() {
        this.ItemDetail.itemId=1137;
        this.ItemDetail.itemName='PilotExaminer';
        this.ItemDetail.keyName='PilotExaminerId';
    }

    CreateEntity(): PilotExaminerSearchQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): PilotExaminerSearchQueryBuilder {
        return new DwQueryBuilderGen<PilotExaminerSearchQueryEntity, PilotExaminerSearchQueryMetaDataAttributes,PilotExaminerMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<PilotExaminerSearchQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: PilotExaminerSearchQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<PilotExaminerSearchQueryEntity, PilotExaminerSearchQueryMetaDataAttributes,PilotExaminerMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new PilotExaminerSearchQueryMetaDataAttributes();
     Relations = new PilotExaminerMetaDataRelations();

}


export type PilotExaminerSearchQueryBuilder = DwQueryBuilderGen<PilotExaminerSearchQueryEntity, PilotExaminerSearchQueryMetaDataAttributes,PilotExaminerMetaDataRelations>


export class PilotExaminerSearchQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PilotExaminer';
    // Attributes
    PilotExaminerId = new DwItemAttribute(this.i, 'PilotExaminerId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    PilotExaminerStatusId = new DwItemAttribute(this.i, 'PilotExaminerStatusId');
    Description = new DwItemAttribute(this.i, 'Description');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    DefaultPreTimeMinutes = new DwItemAttribute(this.i, 'DefaultPreTimeMinutes');
    DefaultPostTimeMinutes = new DwItemAttribute(this.i, 'DefaultPostTimeMinutes');
    TravelRadiusMiles = new DwItemAttribute(this.i, 'TravelRadiusMiles');
    AuthorityExaminerNumber = new DwItemAttribute(this.i, 'AuthorityExaminerNumber');
    CalendarIsLinked = new DwItemAttribute(this.i, 'CalendarIsLinked');
    CalendarReferenceId = new DwItemAttribute(this.i, 'CalendarReferenceId');
    RequireAuthorityPreCheckRideNumber = new DwItemAttribute(this.i, 'RequireAuthorityPreCheckRideNumber');
    PublicProfileIsActive = new DwItemAttribute(this.i, 'PublicProfileIsActive');
    PublicProfileSlug = new DwItemAttribute(this.i, 'PublicProfileSlug');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    CheckRideRequestLeadTimeDays = new DwItemAttribute(this.i, 'CheckRideRequestLeadTimeDays');
    CheckRideRequestBookingMaxWeeksOut = new DwItemAttribute(this.i, 'CheckRideRequestBookingMaxWeeksOut');
    AviationAuthorityDistrictOfficeId = new DwItemAttribute(this.i, 'AviationAuthorityDistrictOfficeId');
    DailyTravelRadiusLockMiles = new DwItemAttribute(this.i, 'DailyTravelRadiusLockMiles');
    ScenarioCheckRideScenarioId = new DwItemAttribute('roPilotExaminerScenario', 'ScenarioCheckRideScenarioId');
    ScenarioPreTimeMinutes = new DwItemAttribute('roPilotExaminerScenario', 'ScenarioPreTimeMinutes');
    ScenarioPostTimeMinutes = new DwItemAttribute('roPilotExaminerScenario', 'ScenarioPostTimeMinutes');
    ScenarioIsActive = new DwItemAttribute('roPilotExaminerScenario', 'ScenarioIsActive');
    ScenarioTimeRequiredHours = new DwItemAttribute('roPilotExaminerScenario', 'ScenarioTimeRequiredHours');
    ScenarioCheckRideGroupId = new DwItemAttribute('roPilotExaminerScenario', 'ScenarioCheckRideGroupId');
    ScenarioCheckRideTypeId = new DwItemAttribute('roPilotExaminerScenario', 'ScenarioCheckRideTypeId');
    CheckRideScenarioCheckRideCost = new DwItemAttribute('CheckRideScenario', 'CheckRideScenarioCheckRideCost');
    CheckRideScenarioRetestCost = new DwItemAttribute('CheckRideScenario', 'CheckRideScenarioRetestCost');
    CheckRideScenarioDiscontinuedRetestCost = new DwItemAttribute('CheckRideScenario', 'CheckRideScenarioDiscontinuedRetestCost');
    CheckRideScenarioBookingCost = new DwItemAttribute('CheckRideScenario', 'CheckRideScenarioBookingCost');
    CheckRideScenarioDiscontinuedRetestFlightOnlyCost = new DwItemAttribute('CheckRideScenario', 'CheckRideScenarioDiscontinuedRetestFlightOnlyCost');
    CheckRideScenarioRetestFlightOnlyCost = new DwItemAttribute('CheckRideScenario', 'CheckRideScenarioRetestFlightOnlyCost');
    CheckRideScenarioTravelCost = new DwItemAttribute('CheckRideScenario', 'CheckRideScenarioTravelCost');
    CheckRideScenarioTimeRequiredMinutes = new DwItemAttribute('CheckRideScenario', 'CheckRideScenarioTimeRequiredMinutes');
    CheckRideScenarioRequiredDocumentNotes = new DwItemAttribute('CheckRideScenario', 'CheckRideScenarioRequiredDocumentNotes');
    CheckRideScenarioRetestTimeRequiredMinutes = new DwItemAttribute('CheckRideScenario', 'CheckRideScenarioRetestTimeRequiredMinutes');
    CheckRideScenarioRetestFlightOnlyTimeRequiredMinutes = new DwItemAttribute('CheckRideScenario', 'CheckRideScenarioRetestFlightOnlyTimeRequiredMinutes');
    CheckRideScenarioDiscontinuedTimeRequiredMinutes = new DwItemAttribute('CheckRideScenario', 'CheckRideScenarioDiscontinuedTimeRequiredMinutes');
    CheckRideScenarioDiscontinuedFlightOnlyTimeRequiredMinutes = new DwItemAttribute('CheckRideScenario', 'CheckRideScenarioDiscontinuedFlightOnlyTimeRequiredMinutes');
    LocationDistancesPilotExaminerLocationId = new DwItemAttribute('roPilotExaminerLocationDistances', 'LocationDistancesPilotExaminerLocationId');
    LocationDistancesAirportId = new DwItemAttribute('roPilotExaminerLocationDistances', 'LocationDistancesAirportId');
    LocationDistancesDistance = new DwItemAttribute('roPilotExaminerLocationDistances', 'LocationDistancesDistance');
    LocationDistancesReferenceAirportId = new DwItemAttribute('roPilotExaminerLocationDistances', 'LocationDistancesReferenceAirportId');
    LocationDistancesWithinTravelRadius = new DwItemAttribute('roPilotExaminerLocationDistances', 'LocationDistancesWithinTravelRadius');
    PilotExaminerLocationSchedulingStartDate = new DwItemAttribute('PilotExaminerLocation', 'PilotExaminerLocationSchedulingStartDate');
    PilotExaminerLocationSchedulingEndDate = new DwItemAttribute('PilotExaminerLocation', 'PilotExaminerLocationSchedulingEndDate');
    PilotExaminerLocationTravelRadiusMile = new DwItemAttribute('PilotExaminerLocation', 'PilotExaminerLocationTravelRadiusMile');
    PilotExaminerLocationBookingScheduleId = new DwItemAttribute('PilotExaminerLocation', 'PilotExaminerLocationBookingScheduleId');
    PilotExaminerLocationIsActive = new DwItemAttribute('PilotExaminerLocation', 'PilotExaminerLocationIsActive');
    PilotExaminerLocationTravelCost = new DwItemAttribute('PilotExaminerLocation', 'PilotExaminerLocationTravelCost');
    PilotExaminerLocationAdditionalLocationCost = new DwItemAttribute('PilotExaminerLocation', 'PilotExaminerLocationAdditionalLocationCost');
    PilotExaminerLocationBookingScheduleTimeZoneId = new DwItemAttribute('PilotExaminerLocation', 'PilotExaminerLocationBookingScheduleTimeZoneId');
    PilotExaminerLocationPilotExaminerLocationId = new DwItemAttribute('PilotExaminerLocation', 'PilotExaminerLocationPilotExaminerLocationId');
    PilotExaminerLocationLocationName = new DwItemAttribute('PilotExaminerLocation', 'PilotExaminerLocationLocationName');
    PilotExaminerLocationPilotExaminerLocationTypeId = new DwItemAttribute('PilotExaminerLocation', 'PilotExaminerLocationPilotExaminerLocationTypeId');
    RoPilotExaminerLocationCurrentPilotExaminerLocationStateId = new DwItemAttribute('roPilotExaminerLocationCurrent', 'RoPilotExaminerLocationCurrentPilotExaminerLocationStateId');
    AirportAddressTimezoneId = new DwItemAttribute('Address', 'AirportAddressTimezoneId');
    AirportAddressObservesDst = new DwItemAttribute('Address', 'AirportAddressObservesDst');
    DwUserFirstName = new DwItemAttribute('dw_User', 'DwUserFirstName');
    DwUserLastName = new DwItemAttribute('dw_User', 'DwUserLastName');
    DwUserUserPictureMediaId = new DwItemAttribute('dw_User', 'DwUserUserPictureMediaId');
    DwUserUserBackgroundMediaId = new DwItemAttribute('dw_User', 'DwUserUserBackgroundMediaId');
    UserPictureDwroMediaMediaPath = new DwItemAttribute('dw_roMedia', 'UserPictureDwroMediaMediaPath');
    UserPictureDwroMediaImageWidth = new DwItemAttribute('dw_roMedia', 'UserPictureDwroMediaImageWidth');
    UserPictureDwroMediaImageHeight = new DwItemAttribute('dw_roMedia', 'UserPictureDwroMediaImageHeight');
}
export class PilotExaminerCalendarReferenceQueryMetaData implements DwItem2<PilotExaminerCalendarReferenceQueryEntity, PilotExaminerCalendarReferenceQueryMetaDataAttributes,PilotExaminerMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Pilot_Examiner_Calendar_Reference';

    constructor() {
        this.ItemDetail.itemId=1137;
        this.ItemDetail.itemName='PilotExaminer';
        this.ItemDetail.keyName='PilotExaminerId';
    }

    CreateEntity(): PilotExaminerCalendarReferenceQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): PilotExaminerCalendarReferenceQueryBuilder {
        return new DwQueryBuilderGen<PilotExaminerCalendarReferenceQueryEntity, PilotExaminerCalendarReferenceQueryMetaDataAttributes,PilotExaminerMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<PilotExaminerCalendarReferenceQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: PilotExaminerCalendarReferenceQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<PilotExaminerCalendarReferenceQueryEntity, PilotExaminerCalendarReferenceQueryMetaDataAttributes,PilotExaminerMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new PilotExaminerCalendarReferenceQueryMetaDataAttributes();
     Relations = new PilotExaminerMetaDataRelations();

}


export type PilotExaminerCalendarReferenceQueryBuilder = DwQueryBuilderGen<PilotExaminerCalendarReferenceQueryEntity, PilotExaminerCalendarReferenceQueryMetaDataAttributes,PilotExaminerMetaDataRelations>


export class PilotExaminerCalendarReferenceQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PilotExaminer';
    // Attributes
    PilotExaminerId = new DwItemAttribute(this.i, 'PilotExaminerId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    PilotExaminerStatusId = new DwItemAttribute(this.i, 'PilotExaminerStatusId');
    CalendarReferenceCalendarReferenceId = new DwItemAttribute('CalendarReference', 'CalendarReferenceCalendarReferenceId');
    CalendarReferenceCalendarReferenceTypeId = new DwItemAttribute('CalendarReference', 'CalendarReferenceCalendarReferenceTypeId');
    CalendarReferenceMainCalendarKey = new DwItemAttribute('CalendarReference', 'CalendarReferenceMainCalendarKey');
    CalendarReferenceCalendarConfigJSON = new DwItemAttribute('CalendarReference', 'CalendarReferenceCalendarConfigJSON');
    DwUserUserId = new DwItemAttribute('dw_User', 'DwUserUserId');
    DwUserUserName = new DwItemAttribute('dw_User', 'DwUserUserName');
    DwUserUserTypeId = new DwItemAttribute('dw_User', 'DwUserUserTypeId');
    DwUserEmail = new DwItemAttribute('dw_User', 'DwUserEmail');
    DwUserFirstName = new DwItemAttribute('dw_User', 'DwUserFirstName');
    DwUserLastName = new DwItemAttribute('dw_User', 'DwUserLastName');
    DwUserLastLoginDateTime = new DwItemAttribute('dw_User', 'DwUserLastLoginDateTime');
}
export class CheckRideRequestCalendarQueryMetaData implements DwItem2<CheckRideRequestCalendarQueryEntity, CheckRideRequestCalendarQueryMetaDataAttributes,CheckRideRequestMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Check_Ride_Request-Calendar';

    constructor() {
        this.ItemDetail.itemId=1153;
        this.ItemDetail.itemName='CheckRideRequest';
        this.ItemDetail.keyName='CheckRideRequestId';
    }

    CreateEntity(): CheckRideRequestCalendarQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): CheckRideRequestCalendarQueryBuilder {
        return new DwQueryBuilderGen<CheckRideRequestCalendarQueryEntity, CheckRideRequestCalendarQueryMetaDataAttributes,CheckRideRequestMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<CheckRideRequestCalendarQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: CheckRideRequestCalendarQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<CheckRideRequestCalendarQueryEntity, CheckRideRequestCalendarQueryMetaDataAttributes,CheckRideRequestMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new CheckRideRequestCalendarQueryMetaDataAttributes();
     Relations = new CheckRideRequestMetaDataRelations();

}


export type CheckRideRequestCalendarQueryBuilder = DwQueryBuilderGen<CheckRideRequestCalendarQueryEntity, CheckRideRequestCalendarQueryMetaDataAttributes,CheckRideRequestMetaDataRelations>


export class CheckRideRequestCalendarQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideRequest';
    // Attributes
    CheckRideRequestId = new DwItemAttribute(this.i, 'CheckRideRequestId');
    CheckRideTypeId = new DwItemAttribute(this.i, 'CheckRideTypeId');
    CheckRideGroupId = new DwItemAttribute(this.i, 'CheckRideGroupId');
    CheckRideTestTypeId = new DwItemAttribute(this.i, 'CheckRideTestTypeId');
    PilotCertificateNumber = new DwItemAttribute(this.i, 'PilotCertificateNumber');
    CheckRideRequestSubStatusId = new DwItemAttribute(this.i, 'CheckRideRequestSubStatusId');
    ScheduledDateTime = new DwItemAttribute(this.i, 'ScheduledDateTime');
    ScheduledTimeMinutes = new DwItemAttribute(this.i, 'ScheduledTimeMinutes');
    ProposedDateTime = new DwItemAttribute(this.i, 'ProposedDateTime');
    ScheduledAirportId = new DwItemAttribute(this.i, 'ScheduledAirportId');
    AuthorityPreapprovalCode = new DwItemAttribute(this.i, 'AuthorityPreapprovalCode');
    AuthorityApprovalCode = new DwItemAttribute(this.i, 'AuthorityApprovalCode');
    ProposedAirportId = new DwItemAttribute(this.i, 'ProposedAirportId');
    ProposedTimeMinutes = new DwItemAttribute(this.i, 'ProposedTimeMinutes');
    AuthorityPreCheckRideNumber = new DwItemAttribute(this.i, 'AuthorityPreCheckRideNumber');
    CheckRideRequestSubStatusCheckRideRequestStatusId = new DwItemAttribute('CheckRideRequestSubStatus', 'CheckRideRequestSubStatusCheckRideRequestStatusId');
    CheckRideResultCheckRideRequestId = new DwItemAttribute('CheckRideResult', 'CheckRideResultCheckRideRequestId');
    CheckRideResultCheckRideDateTime = new DwItemAttribute('CheckRideResult', 'CheckRideResultCheckRideDateTime');
    CheckRideResultCheckRideOutcomeId = new DwItemAttribute('CheckRideResult', 'CheckRideResultCheckRideOutcomeId');
    CheckRideResultAllowRetest = new DwItemAttribute('CheckRideResult', 'CheckRideResultAllowRetest');
    CheckRideResultAllowRetestReason = new DwItemAttribute('CheckRideResult', 'CheckRideResultAllowRetestReason');
    CheckRideResultRetestCost = new DwItemAttribute('CheckRideResult', 'CheckRideResultRetestCost');
    AirportAirportName = new DwItemAttribute('Airport', 'AirportAirportName');
    AirportAirportCode = new DwItemAttribute('Airport', 'AirportAirportCode');
    AirportAddressCity = new DwItemAttribute('Address', 'AirportAddressCity');
    AirportAddressStateProvinceId = new DwItemAttribute('Address', 'AirportAddressStateProvinceId');
    AirportAddressPostalCode = new DwItemAttribute('Address', 'AirportAddressPostalCode');
    AirportAddressCountryId = new DwItemAttribute('Address', 'AirportAddressCountryId');
    AirportAddressLatitude = new DwItemAttribute('Address', 'AirportAddressLatitude');
    AirportAddressLongitude = new DwItemAttribute('Address', 'AirportAddressLongitude');
    AirportAddressTimezoneId = new DwItemAttribute('Address', 'AirportAddressTimezoneId');
    AirportAddressObservesDst = new DwItemAttribute('Address', 'AirportAddressObservesDst');
    ScheduledPilotExaminerUserId = new DwItemAttribute('PilotExaminer', 'ScheduledPilotExaminerUserId');
    CalendarEventReferenceKey = new DwItemAttribute('CalendarEventReference', 'CalendarEventReferenceKey');
    ApplicantNameApplicantFinalEmail = new DwItemAttribute('roCheckrideApplicantFinalName', 'ApplicantNameApplicantFinalEmail');
    ApplicantNameApplicantFinalName = new DwItemAttribute('roCheckrideApplicantFinalName', 'ApplicantNameApplicantFinalName');
    ApplicantNameApplicantFinalPhone = new DwItemAttribute('roCheckrideApplicantFinalName', 'ApplicantNameApplicantFinalPhone');
    ApplicantNameFlightSchoolFinalName = new DwItemAttribute('roCheckrideApplicantFinalName', 'ApplicantNameFlightSchoolFinalName');
    ApplicantNameCheckRideApplicantTypeId = new DwItemAttribute('roCheckrideApplicantFinalName', 'ApplicantNameCheckRideApplicantTypeId');
}
export class PilotExaminerCurrentAirportsQueryMetaData implements DwItem2<PilotExaminerCurrentAirportsQueryEntity, PilotExaminerCurrentAirportsQueryMetaDataAttributes,AirportMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Pilot_Examiner_Current_Airports';

    constructor() {
        this.ItemDetail.itemId=1159;
        this.ItemDetail.itemName='Airport';
        this.ItemDetail.keyName='AirportId';
    }

    CreateEntity(): PilotExaminerCurrentAirportsQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): PilotExaminerCurrentAirportsQueryBuilder {
        return new DwQueryBuilderGen<PilotExaminerCurrentAirportsQueryEntity, PilotExaminerCurrentAirportsQueryMetaDataAttributes,AirportMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<PilotExaminerCurrentAirportsQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: PilotExaminerCurrentAirportsQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<PilotExaminerCurrentAirportsQueryEntity, PilotExaminerCurrentAirportsQueryMetaDataAttributes,AirportMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new PilotExaminerCurrentAirportsQueryMetaDataAttributes();
     Relations = new AirportMetaDataRelations();

}


export type PilotExaminerCurrentAirportsQueryBuilder = DwQueryBuilderGen<PilotExaminerCurrentAirportsQueryEntity, PilotExaminerCurrentAirportsQueryMetaDataAttributes,AirportMetaDataRelations>


export class PilotExaminerCurrentAirportsQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Airport';
    // Attributes
    AirportId = new DwItemAttribute(this.i, 'AirportId');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    AirportName = new DwItemAttribute(this.i, 'AirportName');
    AirportCode = new DwItemAttribute(this.i, 'AirportCode');
    AirportAddressId = new DwItemAttribute(this.i, 'AirportAddressId');
    Elevation = new DwItemAttribute(this.i, 'Elevation');
    OwnershipCode = new DwItemAttribute(this.i, 'OwnershipCode');
    IsActive = new DwItemAttribute(this.i, 'IsActive');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    PilotExaminerLocationPilotExaminerId = new DwItemAttribute('PilotExaminerLocation', 'PilotExaminerLocationPilotExaminerId');
    PilotExaminerLocationShowMetar = new DwItemAttribute('PilotExaminerLocation', 'PilotExaminerLocationShowMetar');
    RoPilotExaminerLocationCurrentPilotExaminerLocationStateId = new DwItemAttribute('roPilotExaminerLocationCurrent', 'RoPilotExaminerLocationCurrentPilotExaminerLocationStateId');
}
export class PilotExaminerPublicProfileQueryMetaData implements DwItem2<PilotExaminerPublicProfileQueryEntity, PilotExaminerPublicProfileQueryMetaDataAttributes,PilotExaminerMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Pilot_Examiner_Public_Profile';

    constructor() {
        this.ItemDetail.itemId=1137;
        this.ItemDetail.itemName='PilotExaminer';
        this.ItemDetail.keyName='PilotExaminerId';
    }

    CreateEntity(): PilotExaminerPublicProfileQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): PilotExaminerPublicProfileQueryBuilder {
        return new DwQueryBuilderGen<PilotExaminerPublicProfileQueryEntity, PilotExaminerPublicProfileQueryMetaDataAttributes,PilotExaminerMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<PilotExaminerPublicProfileQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: PilotExaminerPublicProfileQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<PilotExaminerPublicProfileQueryEntity, PilotExaminerPublicProfileQueryMetaDataAttributes,PilotExaminerMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new PilotExaminerPublicProfileQueryMetaDataAttributes();
     Relations = new PilotExaminerMetaDataRelations();

}


export type PilotExaminerPublicProfileQueryBuilder = DwQueryBuilderGen<PilotExaminerPublicProfileQueryEntity, PilotExaminerPublicProfileQueryMetaDataAttributes,PilotExaminerMetaDataRelations>


export class PilotExaminerPublicProfileQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'PilotExaminer';
    // Attributes
    PilotExaminerId = new DwItemAttribute(this.i, 'PilotExaminerId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    Description = new DwItemAttribute(this.i, 'Description');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    AuthorityExaminerNumber = new DwItemAttribute(this.i, 'AuthorityExaminerNumber');
    PublicProfileIsActive = new DwItemAttribute(this.i, 'PublicProfileIsActive');
    PublicProfileSlug = new DwItemAttribute(this.i, 'PublicProfileSlug');
    DwUserUserBackgroundMediaId = new DwItemAttribute('dw_User', 'DwUserUserBackgroundMediaId');
    DwUserUserPictureMediaId = new DwItemAttribute('dw_User', 'DwUserUserPictureMediaId');
}
export class StudentApplicantWithDetailQueryMetaData implements DwItem2<StudentApplicantWithDetailQueryEntity, StudentApplicantWithDetailQueryMetaDataAttributes,StudentApplicantMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Student_Applicant_with_detail';

    constructor() {
        this.ItemDetail.itemId=1144;
        this.ItemDetail.itemName='StudentApplicant';
        this.ItemDetail.keyName='StudentApplicantId';
    }

    CreateEntity(): StudentApplicantWithDetailQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): StudentApplicantWithDetailQueryBuilder {
        return new DwQueryBuilderGen<StudentApplicantWithDetailQueryEntity, StudentApplicantWithDetailQueryMetaDataAttributes,StudentApplicantMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<StudentApplicantWithDetailQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: StudentApplicantWithDetailQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<StudentApplicantWithDetailQueryEntity, StudentApplicantWithDetailQueryMetaDataAttributes,StudentApplicantMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new StudentApplicantWithDetailQueryMetaDataAttributes();
     Relations = new StudentApplicantMetaDataRelations();

}


export type StudentApplicantWithDetailQueryBuilder = DwQueryBuilderGen<StudentApplicantWithDetailQueryEntity, StudentApplicantWithDetailQueryMetaDataAttributes,StudentApplicantMetaDataRelations>


export class StudentApplicantWithDetailQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'StudentApplicant';
    // Attributes
    StudentApplicantId = new DwItemAttribute(this.i, 'StudentApplicantId');
    UserId = new DwItemAttribute(this.i, 'UserId');
    ApplicationDescription = new DwItemAttribute(this.i, 'ApplicationDescription');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    PilotCertificateNumber = new DwItemAttribute(this.i, 'PilotCertificateNumber');
    TravelRadiusMiles = new DwItemAttribute(this.i, 'TravelRadiusMiles');
    Citizenship = new DwItemAttribute(this.i, 'Citizenship');
    ApplicantAddressId = new DwItemAttribute(this.i, 'ApplicantAddressId');
    MainAirportId = new DwItemAttribute(this.i, 'MainAirportId');
    MainAircraft = new DwItemAttribute(this.i, 'MainAircraft');
    ReferredPilotExaminerId = new DwItemAttribute(this.i, 'ReferredPilotExaminerId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    AviationTrainingProgramId = new DwItemAttribute(this.i, 'AviationTrainingProgramId');
    CitzenshipCountyId = new DwItemAttribute(this.i, 'CitzenshipCountyId');
    DwUserFirstName = new DwItemAttribute('dw_User', 'DwUserFirstName');
    DwUserLastName = new DwItemAttribute('dw_User', 'DwUserLastName');
    DwUserEmail = new DwItemAttribute('dw_User', 'DwUserEmail');
    DwUserUserBackgroundMediaId = new DwItemAttribute('dw_User', 'DwUserUserBackgroundMediaId');
    DwUserUserPictureMediaId = new DwItemAttribute('dw_User', 'DwUserUserPictureMediaId');
    DwUserMobilePhone = new DwItemAttribute('dw_User', 'DwUserMobilePhone');
    ApplicantAddressStreetAddress1 = new DwItemAttribute('Address', 'ApplicantAddressStreetAddress1');
    ApplicantAddressStreetAddress2 = new DwItemAttribute('Address', 'ApplicantAddressStreetAddress2');
    ApplicantAddressCity = new DwItemAttribute('Address', 'ApplicantAddressCity');
    ApplicantAddressStateProvinceId = new DwItemAttribute('Address', 'ApplicantAddressStateProvinceId');
    ApplicantAddressCountryId = new DwItemAttribute('Address', 'ApplicantAddressCountryId');
    ApplicantAddressPostalCode = new DwItemAttribute('Address', 'ApplicantAddressPostalCode');
    ApplicantAddressLatitude = new DwItemAttribute('Address', 'ApplicantAddressLatitude');
    ApplicantAddressLongitude = new DwItemAttribute('Address', 'ApplicantAddressLongitude');
}
export class AirportWithAddressInfoQueryMetaData implements DwItem2<AirportWithAddressInfoQueryEntity, AirportWithAddressInfoQueryMetaDataAttributes,AirportMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Airport_with_Address_info';

    constructor() {
        this.ItemDetail.itemId=1159;
        this.ItemDetail.itemName='Airport';
        this.ItemDetail.keyName='AirportId';
    }

    CreateEntity(): AirportWithAddressInfoQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): AirportWithAddressInfoQueryBuilder {
        return new DwQueryBuilderGen<AirportWithAddressInfoQueryEntity, AirportWithAddressInfoQueryMetaDataAttributes,AirportMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<AirportWithAddressInfoQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: AirportWithAddressInfoQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<AirportWithAddressInfoQueryEntity, AirportWithAddressInfoQueryMetaDataAttributes,AirportMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new AirportWithAddressInfoQueryMetaDataAttributes();
     Relations = new AirportMetaDataRelations();

}


export type AirportWithAddressInfoQueryBuilder = DwQueryBuilderGen<AirportWithAddressInfoQueryEntity, AirportWithAddressInfoQueryMetaDataAttributes,AirportMetaDataRelations>


export class AirportWithAddressInfoQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Airport';
    // Attributes
    AirportId = new DwItemAttribute(this.i, 'AirportId');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    AirportName = new DwItemAttribute(this.i, 'AirportName');
    AirportCode = new DwItemAttribute(this.i, 'AirportCode');
    AirportAddressId = new DwItemAttribute(this.i, 'AirportAddressId');
    Elevation = new DwItemAttribute(this.i, 'Elevation');
    OwnershipCode = new DwItemAttribute(this.i, 'OwnershipCode');
    IsActive = new DwItemAttribute(this.i, 'IsActive');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    AirportAddressCity = new DwItemAttribute('Address', 'AirportAddressCity');
    AirportAddressStateProvinceId = new DwItemAttribute('Address', 'AirportAddressStateProvinceId');
    AirportAddressPostalCode = new DwItemAttribute('Address', 'AirportAddressPostalCode');
    AirportAddressCountyName = new DwItemAttribute('Address', 'AirportAddressCountyName');
    AirportAddressTimezoneId = new DwItemAttribute('Address', 'AirportAddressTimezoneId');
    AirportAddressObservesDst = new DwItemAttribute('Address', 'AirportAddressObservesDst');
    AirportAddressLatitude = new DwItemAttribute('Address', 'AirportAddressLatitude');
    AirportAddressLongitude = new DwItemAttribute('Address', 'AirportAddressLongitude');
}
export class CheckrideRequestAggregateBaseQueryMetaData implements DwItem2<CheckrideRequestAggregateBaseQueryEntity, CheckrideRequestAggregateBaseQueryMetaDataAttributes,CheckRideRequestMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Checkride_Request-Aggregate_Base';

    constructor() {
        this.ItemDetail.itemId=1153;
        this.ItemDetail.itemName='CheckRideRequest';
        this.ItemDetail.keyName='CheckRideRequestId';
    }

    CreateEntity(): CheckrideRequestAggregateBaseQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): CheckrideRequestAggregateBaseQueryBuilder {
        return new DwQueryBuilderGen<CheckrideRequestAggregateBaseQueryEntity, CheckrideRequestAggregateBaseQueryMetaDataAttributes,CheckRideRequestMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<CheckrideRequestAggregateBaseQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: CheckrideRequestAggregateBaseQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<CheckrideRequestAggregateBaseQueryEntity, CheckrideRequestAggregateBaseQueryMetaDataAttributes,CheckRideRequestMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new CheckrideRequestAggregateBaseQueryMetaDataAttributes();
     Relations = new CheckRideRequestMetaDataRelations();

}


export type CheckrideRequestAggregateBaseQueryBuilder = DwQueryBuilderGen<CheckrideRequestAggregateBaseQueryEntity, CheckrideRequestAggregateBaseQueryMetaDataAttributes,CheckRideRequestMetaDataRelations>


export class CheckrideRequestAggregateBaseQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideRequest';
    // Attributes
    CheckRideRequestId = new DwItemAttribute(this.i, 'CheckRideRequestId');
    CheckRideGroupId = new DwItemAttribute(this.i, 'CheckRideGroupId');
    CheckRideTypeId = new DwItemAttribute(this.i, 'CheckRideTypeId');
    CheckRideTestTypeId = new DwItemAttribute(this.i, 'CheckRideTestTypeId');
    CheckRideRequestSubStatusId = new DwItemAttribute(this.i, 'CheckRideRequestSubStatusId');
    CheckRideApplicantTypeId = new DwItemAttribute(this.i, 'CheckRideApplicantTypeId');
    AvaiationTrainingProgramId = new DwItemAttribute(this.i, 'AvaiationTrainingProgramId');
    ScheduledDateTime = new DwItemAttribute(this.i, 'ScheduledDateTime');
    ScheduledAirportId = new DwItemAttribute(this.i, 'ScheduledAirportId');
    ScheduledCheckRideCost = new DwItemAttribute(this.i, 'ScheduledCheckRideCost');
    PilexosBookingCost = new DwItemAttribute(this.i, 'PilexosBookingCost');
    ExaminerBookingCost = new DwItemAttribute(this.i, 'ExaminerBookingCost');
    AdditionalTravelCost = new DwItemAttribute(this.i, 'AdditionalTravelCost');
    ScheduledPilotExaminerId = new DwItemAttribute(this.i, 'ScheduledPilotExaminerId');
    CheckRideCancellationTypeId = new DwItemAttribute(this.i, 'CheckRideCancellationTypeId');
    CheckRideCancellationTypeOther = new DwItemAttribute(this.i, 'CheckRideCancellationTypeOther');
    PilexosBookingCostPaymentPartyId = new DwItemAttribute(this.i, 'PilexosBookingCostPaymentPartyId');
    StudentApplicantCitizenshipCountyId = new DwItemAttribute(this.i, 'StudentApplicantCitizenshipCountyId');
    FlightSchoolId = new DwItemAttribute(this.i, 'FlightSchoolId');
    FlightSchoolNameOther = new DwItemAttribute(this.i, 'FlightSchoolNameOther');
    AggrScheduledDateTime = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrScheduledDateTime');
    AggrScheduledDateTimeYear = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrScheduledDateTimeYear');
    AggrScheduledDateTimeMonth = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrScheduledDateTimeMonth');
    AggrScheduledDateTimeMonthDisplay = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrScheduledDateTimeMonthDisplay');
    AggrScheduledDateTimeMonthYearDisplay = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrScheduledDateTimeMonthYearDisplay');
    AggrCheckRideRequestStatusId = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrCheckRideRequestStatusId');
    AggrCheckRideRequestStatus = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrCheckRideRequestStatus');
    AggrPilexosPaymentDateTimeYear = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrPilexosPaymentDateTimeYear');
    AggrPilexosPaymentDateTimeMonth = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrPilexosPaymentDateTimeMonth');
    AggrPilexosPaymentTotal = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrPilexosPaymentTotal');
    AggrCheckridePaymentDateTimeYear = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrCheckridePaymentDateTimeYear');
    AggrCheckridePaymentDateTimeMonth = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrCheckridePaymentDateTimeMonth');
    AggrCheckridePaymentTotal = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrCheckridePaymentTotal');
    AggrOutcomeIsRequested = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrOutcomeIsRequested');
    AggrOutcomeIsBooked = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrOutcomeIsBooked');
    AggrOutcomeIsComplete = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrOutcomeIsComplete');
    AggrOutcomeIsCancelled = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrOutcomeIsCancelled');
    AggrRevenueIsRequested = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrRevenueIsRequested');
    AggrRevenueIsBooked = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrRevenueIsBooked');
    AggrRevenueIsComplete = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrRevenueIsComplete');
    AggrRevenueIsCancelled = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrRevenueIsCancelled');
    AggrCheckRideTypeDisplay = new DwItemAttribute('roCheckrideAggregateUtil', 'AggrCheckRideTypeDisplay');
    CheckRideResultCheckRideOutcomeId = new DwItemAttribute('CheckRideResult', 'CheckRideResultCheckRideOutcomeId');
    AirportAirportCode = new DwItemAttribute('Airport', 'AirportAirportCode');
    RoCheckrideApplicantInfoFlightSchoolFinalName = new DwItemAttribute('roCheckrideApplicantFinalName', 'RoCheckrideApplicantInfoFlightSchoolFinalName');
}
export class CheckrideActiveApplicantCheckridesQueryMetaData implements DwItem2<CheckrideActiveApplicantCheckridesQueryEntity, CheckrideActiveApplicantCheckridesQueryMetaDataAttributes,CheckRideRequestMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Checkride-Active_Applicant_Checkrides';

    constructor() {
        this.ItemDetail.itemId=1153;
        this.ItemDetail.itemName='CheckRideRequest';
        this.ItemDetail.keyName='CheckRideRequestId';
    }

    CreateEntity(): CheckrideActiveApplicantCheckridesQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): CheckrideActiveApplicantCheckridesQueryBuilder {
        return new DwQueryBuilderGen<CheckrideActiveApplicantCheckridesQueryEntity, CheckrideActiveApplicantCheckridesQueryMetaDataAttributes,CheckRideRequestMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<CheckrideActiveApplicantCheckridesQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: CheckrideActiveApplicantCheckridesQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<CheckrideActiveApplicantCheckridesQueryEntity, CheckrideActiveApplicantCheckridesQueryMetaDataAttributes,CheckRideRequestMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new CheckrideActiveApplicantCheckridesQueryMetaDataAttributes();
     Relations = new CheckRideRequestMetaDataRelations();

}


export type CheckrideActiveApplicantCheckridesQueryBuilder = DwQueryBuilderGen<CheckrideActiveApplicantCheckridesQueryEntity, CheckrideActiveApplicantCheckridesQueryMetaDataAttributes,CheckRideRequestMetaDataRelations>


export class CheckrideActiveApplicantCheckridesQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CheckRideRequest';
    // Attributes
    CheckRideRequestId = new DwItemAttribute(this.i, 'CheckRideRequestId');
    StudentApplicantId = new DwItemAttribute(this.i, 'StudentApplicantId');
    AviationAuthorityId = new DwItemAttribute(this.i, 'AviationAuthorityId');
    CheckRideRequestSubStatusId = new DwItemAttribute(this.i, 'CheckRideRequestSubStatusId');
    ScheduledDateTime = new DwItemAttribute(this.i, 'ScheduledDateTime');
    CheckRideTypeId = new DwItemAttribute(this.i, 'CheckRideTypeId');
    CheckRideGroupId = new DwItemAttribute(this.i, 'CheckRideGroupId');
    StudentApplicantStudentApplicantId = new DwItemAttribute('StudentApplicant', 'StudentApplicantStudentApplicantId');
    StudentApplicantUserId = new DwItemAttribute('StudentApplicant', 'StudentApplicantUserId');
}
export class AcsCodesLookupQueryMetaData implements DwItem2<AcsCodesLookupQueryEntity, AcsCodesLookupQueryMetaDataAttributes,AcsCodeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Acs_Codes-Lookup';

    constructor() {
        this.ItemDetail.itemId=1201;
        this.ItemDetail.itemName='AcsCode';
        this.ItemDetail.keyName='AcsCodeId';
    }

    CreateEntity(): AcsCodesLookupQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): AcsCodesLookupQueryBuilder {
        return new DwQueryBuilderGen<AcsCodesLookupQueryEntity, AcsCodesLookupQueryMetaDataAttributes,AcsCodeMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<AcsCodesLookupQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: AcsCodesLookupQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<AcsCodesLookupQueryEntity, AcsCodesLookupQueryMetaDataAttributes,AcsCodeMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new AcsCodesLookupQueryMetaDataAttributes();
     Relations = new AcsCodeMetaDataRelations();

}


export type AcsCodesLookupQueryBuilder = DwQueryBuilderGen<AcsCodesLookupQueryEntity, AcsCodesLookupQueryMetaDataAttributes,AcsCodeMetaDataRelations>


export class AcsCodesLookupQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AcsCode';
    // Attributes
    AcsCodeId = new DwItemAttribute(this.i, 'AcsCodeId');
    AscCodeTypeId = new DwItemAttribute(this.i, 'AscCodeTypeId');
    AcsCode = new DwItemAttribute(this.i, 'AcsCode');
    AcsCodeOrder = new DwItemAttribute(this.i, 'AcsCodeOrder');
    AcsAreaOfOperationTaskId = new DwItemAttribute(this.i, 'AcsAreaOfOperationTaskId');
    AscCodeDescription = new DwItemAttribute(this.i, 'AscCodeDescription');
    TaskTaskName = new DwItemAttribute('AcsAreaOfOperationTask', 'TaskTaskName');
    TaskTaskOrder = new DwItemAttribute('AcsAreaOfOperationTask', 'TaskTaskOrder');
    TaskTaskCode = new DwItemAttribute('AcsAreaOfOperationTask', 'TaskTaskCode');
    TaskAcsAreaOfOperationTaskId = new DwItemAttribute('AcsAreaOfOperationTask', 'TaskAcsAreaOfOperationTaskId');
    AcsAreaOfOperationAcsAreaOfOperationId = new DwItemAttribute('AcsAreaOfOperation', 'AcsAreaOfOperationAcsAreaOfOperationId');
    AcsAreaOfOperationAreaOfOperationName = new DwItemAttribute('AcsAreaOfOperation', 'AcsAreaOfOperationAreaOfOperationName');
    AcsAreaOfOperationAreaOfOperationOrder = new DwItemAttribute('AcsAreaOfOperation', 'AcsAreaOfOperationAreaOfOperationOrder');
    AcsAreaOfOperationAreaOfOperationCode = new DwItemAttribute('AcsAreaOfOperation', 'AcsAreaOfOperationAreaOfOperationCode');
    AcsTestTestName = new DwItemAttribute('AcsTest', 'AcsTestTestName');
    AcsTestTestDescription = new DwItemAttribute('AcsTest', 'AcsTestTestDescription');
    AcsTestCheckRideGroupId = new DwItemAttribute('AcsTest', 'AcsTestCheckRideGroupId');
    AcsTestTestCode = new DwItemAttribute('AcsTest', 'AcsTestTestCode');
    AcsTestAcsTestId = new DwItemAttribute('AcsTest', 'AcsTestAcsTestId');
    AcsTestAcsTestStatusId = new DwItemAttribute('AcsTest', 'AcsTestAcsTestStatusId');
}
export class AcsCodesGridQueryMetaData implements DwItem2<AcsCodesGridQueryEntity, AcsCodesGridQueryMetaDataAttributes,AcsCodeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Acs_Codes-Grid';

    constructor() {
        this.ItemDetail.itemId=1201;
        this.ItemDetail.itemName='AcsCode';
        this.ItemDetail.keyName='AcsCodeId';
    }

    CreateEntity(): AcsCodesGridQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): AcsCodesGridQueryBuilder {
        return new DwQueryBuilderGen<AcsCodesGridQueryEntity, AcsCodesGridQueryMetaDataAttributes,AcsCodeMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<AcsCodesGridQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: AcsCodesGridQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<AcsCodesGridQueryEntity, AcsCodesGridQueryMetaDataAttributes,AcsCodeMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new AcsCodesGridQueryMetaDataAttributes();
     Relations = new AcsCodeMetaDataRelations();

}


export type AcsCodesGridQueryBuilder = DwQueryBuilderGen<AcsCodesGridQueryEntity, AcsCodesGridQueryMetaDataAttributes,AcsCodeMetaDataRelations>


export class AcsCodesGridQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AcsCode';
    // Attributes
    AcsCodeId = new DwItemAttribute(this.i, 'AcsCodeId');
    AscCodeTypeId = new DwItemAttribute(this.i, 'AscCodeTypeId');
    AcsCode = new DwItemAttribute(this.i, 'AcsCode');
    AcsCodeOrder = new DwItemAttribute(this.i, 'AcsCodeOrder');
    AcsAreaOfOperationTaskId = new DwItemAttribute(this.i, 'AcsAreaOfOperationTaskId');
    AscCodeDescription = new DwItemAttribute(this.i, 'AscCodeDescription');
    TaskTaskName = new DwItemAttribute('AcsAreaOfOperationTask', 'TaskTaskName');
    TaskTaskOrder = new DwItemAttribute('AcsAreaOfOperationTask', 'TaskTaskOrder');
    TaskTaskCode = new DwItemAttribute('AcsAreaOfOperationTask', 'TaskTaskCode');
    TaskAcsAreaOfOperationId = new DwItemAttribute('AcsAreaOfOperationTask', 'TaskAcsAreaOfOperationId');
    AcsAreaOfOperationAreaOfOperationName = new DwItemAttribute('AcsAreaOfOperation', 'AcsAreaOfOperationAreaOfOperationName');
    AcsAreaOfOperationAreaOfOperationOrder = new DwItemAttribute('AcsAreaOfOperation', 'AcsAreaOfOperationAreaOfOperationOrder');
    AcsAreaOfOperationAreaOfOperationCode = new DwItemAttribute('AcsAreaOfOperation', 'AcsAreaOfOperationAreaOfOperationCode');
    AcsAreaOfOperationAcsTestId = new DwItemAttribute('AcsAreaOfOperation', 'AcsAreaOfOperationAcsTestId');
    AcsTestTestName = new DwItemAttribute('AcsTest', 'AcsTestTestName');
    AcsTestTestDescription = new DwItemAttribute('AcsTest', 'AcsTestTestDescription');
    AcsTestCheckRideGroupId = new DwItemAttribute('AcsTest', 'AcsTestCheckRideGroupId');
    AcsTestTestCode = new DwItemAttribute('AcsTest', 'AcsTestTestCode');
}
export class AcsCodesWithQuestionsQueryMetaData implements DwItem2<AcsCodesWithQuestionsQueryEntity, AcsCodesWithQuestionsQueryMetaDataAttributes,AcsCodeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'Acs_Codes_With_Questions';

    constructor() {
        this.ItemDetail.itemId=1201;
        this.ItemDetail.itemName='AcsCode';
        this.ItemDetail.keyName='AcsCodeId';
    }

    CreateEntity(): AcsCodesWithQuestionsQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): AcsCodesWithQuestionsQueryBuilder {
        return new DwQueryBuilderGen<AcsCodesWithQuestionsQueryEntity, AcsCodesWithQuestionsQueryMetaDataAttributes,AcsCodeMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<AcsCodesWithQuestionsQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: AcsCodesWithQuestionsQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<AcsCodesWithQuestionsQueryEntity, AcsCodesWithQuestionsQueryMetaDataAttributes,AcsCodeMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new AcsCodesWithQuestionsQueryMetaDataAttributes();
     Relations = new AcsCodeMetaDataRelations();

}


export type AcsCodesWithQuestionsQueryBuilder = DwQueryBuilderGen<AcsCodesWithQuestionsQueryEntity, AcsCodesWithQuestionsQueryMetaDataAttributes,AcsCodeMetaDataRelations>


export class AcsCodesWithQuestionsQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'AcsCode';
    // Attributes
    AcsCodeId = new DwItemAttribute(this.i, 'AcsCodeId');
    AscCodeTypeId = new DwItemAttribute(this.i, 'AscCodeTypeId');
    AcsCode = new DwItemAttribute(this.i, 'AcsCode');
    AcsCodeOrder = new DwItemAttribute(this.i, 'AcsCodeOrder');
    AcsAreaOfOperationTaskId = new DwItemAttribute(this.i, 'AcsAreaOfOperationTaskId');
    AscCodeDescription = new DwItemAttribute(this.i, 'AscCodeDescription');
    AcsAreaOfOperationTaskAcsAreaOfOperationTaskId = new DwItemAttribute('AcsAreaOfOperationTask', 'AcsAreaOfOperationTaskAcsAreaOfOperationTaskId');
    AcsAreaOfOperationTaskAcsAreaOfOperationId = new DwItemAttribute('AcsAreaOfOperationTask', 'AcsAreaOfOperationTaskAcsAreaOfOperationId');
    AcsAreaOfOperationTaskTaskName = new DwItemAttribute('AcsAreaOfOperationTask', 'AcsAreaOfOperationTaskTaskName');
    AcsAreaOfOperationTaskTaskOrder = new DwItemAttribute('AcsAreaOfOperationTask', 'AcsAreaOfOperationTaskTaskOrder');
    AcsAreaOfOperationTaskTaskCode = new DwItemAttribute('AcsAreaOfOperationTask', 'AcsAreaOfOperationTaskTaskCode');
    AcsAreaOfOperationAcsTestId = new DwItemAttribute('AcsAreaOfOperation', 'AcsAreaOfOperationAcsTestId');
    AcsAreaOfOperationAreaOfOperationName = new DwItemAttribute('AcsAreaOfOperation', 'AcsAreaOfOperationAreaOfOperationName');
    AcsAreaOfOperationAreaOfOperationOrder = new DwItemAttribute('AcsAreaOfOperation', 'AcsAreaOfOperationAreaOfOperationOrder');
    AcsAreaOfOperationAreaOfOperationCode = new DwItemAttribute('AcsAreaOfOperation', 'AcsAreaOfOperationAreaOfOperationCode');
    AcsAreaOfOperationAcsAreaOfOperationId = new DwItemAttribute('AcsAreaOfOperation', 'AcsAreaOfOperationAcsAreaOfOperationId');
    QuestionBankQuestionBankId = new DwItemAttribute('QuestionBank', 'QuestionBankQuestionBankId');
    QuestionBankIsBuiltIn = new DwItemAttribute('QuestionBank', 'QuestionBankIsBuiltIn');
    QuestionBankIsShared = new DwItemAttribute('QuestionBank', 'QuestionBankIsShared');
    QuestionBankOwnerUserId = new DwItemAttribute('QuestionBank', 'QuestionBankOwnerUserId');
    QuestionBankAcsTestId = new DwItemAttribute('QuestionBank', 'QuestionBankAcsTestId');
    QuestionBankAcsCodeId = new DwItemAttribute('QuestionBank', 'QuestionBankAcsCodeId');
    QuestionBankQuestionHtml = new DwItemAttribute('QuestionBank', 'QuestionBankQuestionHtml');
    QuestionBankQuestionShortName = new DwItemAttribute('QuestionBank', 'QuestionBankQuestionShortName');
}


// Generated Query Classes for MetaData Service

class MetaDataQueries {
    PilotExaminerSearch : PilotExaminerSearchQueryMetaData = new PilotExaminerSearchQueryMetaData();
    PilotExaminerCalendarReference : PilotExaminerCalendarReferenceQueryMetaData = new PilotExaminerCalendarReferenceQueryMetaData();
    CheckRideRequestCalendar : CheckRideRequestCalendarQueryMetaData = new CheckRideRequestCalendarQueryMetaData();
    PilotExaminerCurrentAirports : PilotExaminerCurrentAirportsQueryMetaData = new PilotExaminerCurrentAirportsQueryMetaData();
    PilotExaminerPublicProfile : PilotExaminerPublicProfileQueryMetaData = new PilotExaminerPublicProfileQueryMetaData();
    StudentApplicantWithDetail : StudentApplicantWithDetailQueryMetaData = new StudentApplicantWithDetailQueryMetaData();
    AirportWithAddressInfo : AirportWithAddressInfoQueryMetaData = new AirportWithAddressInfoQueryMetaData();
    CheckrideRequestAggregateBase : CheckrideRequestAggregateBaseQueryMetaData = new CheckrideRequestAggregateBaseQueryMetaData();
    CheckrideActiveApplicantCheckrides : CheckrideActiveApplicantCheckridesQueryMetaData = new CheckrideActiveApplicantCheckridesQueryMetaData();
    AcsCodesLookup : AcsCodesLookupQueryMetaData = new AcsCodesLookupQueryMetaData();
    AcsCodesGrid : AcsCodesGridQueryMetaData = new AcsCodesGridQueryMetaData();
    AcsCodesWithQuestions : AcsCodesWithQuestionsQueryMetaData = new AcsCodesWithQuestionsQueryMetaData();
}

export class AppMetaData {

    static AcsAreaOfOperation : AcsAreaOfOperationMetaData = new AcsAreaOfOperationMetaData();
    static AcsAreaOfOperationTask : AcsAreaOfOperationTaskMetaData = new AcsAreaOfOperationTaskMetaData();
    static AcsCode : AcsCodeMetaData = new AcsCodeMetaData();
    static AcsCodeSelectionMode : AcsCodeSelectionModeMetaData = new AcsCodeSelectionModeMetaData();
    static AcsCodeType : AcsCodeTypeMetaData = new AcsCodeTypeMetaData();
    static AcsTest : AcsTestMetaData = new AcsTestMetaData();
    static AcsTestPortion : AcsTestPortionMetaData = new AcsTestPortionMetaData();
    static AcsTestStatus : AcsTestStatusMetaData = new AcsTestStatusMetaData();
    static Address : AddressMetaData = new AddressMetaData();
    static Airport : AirportMetaData = new AirportMetaData();
    static ApplicantPlaceholder : ApplicantPlaceholderMetaData = new ApplicantPlaceholderMetaData();
    static ApplicantPlaceholderStatus : ApplicantPlaceholderStatusMetaData = new ApplicantPlaceholderStatusMetaData();
    static AviationAuthority : AviationAuthorityMetaData = new AviationAuthorityMetaData();
    static AviationAuthorityDistrictOffice : AviationAuthorityDistrictOfficeMetaData = new AviationAuthorityDistrictOfficeMetaData();
    static AviationTrainingProgram : AviationTrainingProgramMetaData = new AviationTrainingProgramMetaData();
    static CalendarEventReference : CalendarEventReferenceMetaData = new CalendarEventReferenceMetaData();
    static CalendarReference : CalendarReferenceMetaData = new CalendarReferenceMetaData();
    static CalendarReferenceLog : CalendarReferenceLogMetaData = new CalendarReferenceLogMetaData();
    static CalendarReferenceType : CalendarReferenceTypeMetaData = new CalendarReferenceTypeMetaData();
    static CheckRideApplicantType : CheckRideApplicantTypeMetaData = new CheckRideApplicantTypeMetaData();
    static CheckRideCancellationType : CheckRideCancellationTypeMetaData = new CheckRideCancellationTypeMetaData();
    static CheckRideGroup : CheckRideGroupMetaData = new CheckRideGroupMetaData();
    static CheckRideOutcome : CheckRideOutcomeMetaData = new CheckRideOutcomeMetaData();
    static CheckRidePayment : CheckRidePaymentMetaData = new CheckRidePaymentMetaData();
    static CheckRideRequest : CheckRideRequestMetaData = new CheckRideRequestMetaData();
    static CheckRideRequestPaymentParty : CheckRideRequestPaymentPartyMetaData = new CheckRideRequestPaymentPartyMetaData();
    static CheckRideRequestStatus : CheckRideRequestStatusMetaData = new CheckRideRequestStatusMetaData();
    static CheckRideRequestSubStatus : CheckRideRequestSubStatusMetaData = new CheckRideRequestSubStatusMetaData();
    static CheckRideResult : CheckRideResultMetaData = new CheckRideResultMetaData();
    static CheckRideScenario : CheckRideScenarioMetaData = new CheckRideScenarioMetaData();
    static CheckRideScenarioTypeMap : CheckRideScenarioTypeMapMetaData = new CheckRideScenarioTypeMapMetaData();
    static CheckRideStatusLevel : CheckRideStatusLevelMetaData = new CheckRideStatusLevelMetaData();
    static CheckRideTestType : CheckRideTestTypeMetaData = new CheckRideTestTypeMetaData();
    static CheckRideType : CheckRideTypeMetaData = new CheckRideTypeMetaData();
    static Country : CountryMetaData = new CountryMetaData();
    static Duration : DurationMetaData = new DurationMetaData();
    static FlightSchool : FlightSchoolMetaData = new FlightSchoolMetaData();
    static DwMediaGroup : DwMediaGroupMetaData = new DwMediaGroupMetaData();
    static PaymentCustomerReference : PaymentCustomerReferenceMetaData = new PaymentCustomerReferenceMetaData();
    static PaymentDiscountCode : PaymentDiscountCodeMetaData = new PaymentDiscountCodeMetaData();
    static PaymentMethod : PaymentMethodMetaData = new PaymentMethodMetaData();
    static PaymentProvider : PaymentProviderMetaData = new PaymentProviderMetaData();
    static PaymentReference : PaymentReferenceMetaData = new PaymentReferenceMetaData();
    static PaymentReferenceCardType : PaymentReferenceCardTypeMetaData = new PaymentReferenceCardTypeMetaData();
    static PaymentReferenceHistory : PaymentReferenceHistoryMetaData = new PaymentReferenceHistoryMetaData();
    static PaymentReferenceStatus : PaymentReferenceStatusMetaData = new PaymentReferenceStatusMetaData();
    static PaymentType : PaymentTypeMetaData = new PaymentTypeMetaData();
    static PilotCertificateType : PilotCertificateTypeMetaData = new PilotCertificateTypeMetaData();
    static PilotExaminer : PilotExaminerMetaData = new PilotExaminerMetaData();
    static PilotExaminerLocation : PilotExaminerLocationMetaData = new PilotExaminerLocationMetaData();
    static PilotExaminerLocationAirport : PilotExaminerLocationAirportMetaData = new PilotExaminerLocationAirportMetaData();
    static PilotExaminerLocationState : PilotExaminerLocationStateMetaData = new PilotExaminerLocationStateMetaData();
    static PilotExaminerLocationType : PilotExaminerLocationTypeMetaData = new PilotExaminerLocationTypeMetaData();
    static PilotExaminerStatus : PilotExaminerStatusMetaData = new PilotExaminerStatusMetaData();
    static PlanOfAction : PlanOfActionMetaData = new PlanOfActionMetaData();
    static PlanOfActionQuestionResult : PlanOfActionQuestionResultMetaData = new PlanOfActionQuestionResultMetaData();
    static PlanOfActionRequiredAcsCode : PlanOfActionRequiredAcsCodeMetaData = new PlanOfActionRequiredAcsCodeMetaData();
    static PlanOfActionResult : PlanOfActionResultMetaData = new PlanOfActionResultMetaData();
    static PlanOfActionSection : PlanOfActionSectionMetaData = new PlanOfActionSectionMetaData();
    static PlanOfActionSectionQuestion : PlanOfActionSectionQuestionMetaData = new PlanOfActionSectionQuestionMetaData();
    static PlanOfActionSectionType : PlanOfActionSectionTypeMetaData = new PlanOfActionSectionTypeMetaData();
    static PlanOfActionStatus : PlanOfActionStatusMetaData = new PlanOfActionStatusMetaData();
    static PlanOfActionStatusType : PlanOfActionStatusTypeMetaData = new PlanOfActionStatusTypeMetaData();
    static PostalCode : PostalCodeMetaData = new PostalCodeMetaData();
    static QuestionBank : QuestionBankMetaData = new QuestionBankMetaData();
    static QuestionOutcome : QuestionOutcomeMetaData = new QuestionOutcomeMetaData();
    static RoAirportDistances : RoAirportDistancesMetaData = new RoAirportDistancesMetaData();
    static RoCheckrideAggregateUtil : RoCheckrideAggregateUtilMetaData = new RoCheckrideAggregateUtilMetaData();
    static RoCheckrideApplicantFinalName : RoCheckrideApplicantFinalNameMetaData = new RoCheckrideApplicantFinalNameMetaData();
    static RoCheckRidePaymentAggregate : RoCheckRidePaymentAggregateMetaData = new RoCheckRidePaymentAggregateMetaData();
    static RoCheckrideRequestScheduleDateInfo : RoCheckrideRequestScheduleDateInfoMetaData = new RoCheckrideRequestScheduleDateInfoMetaData();
    static DwRoMedia : DwRoMediaMetaData = new DwRoMediaMetaData();
    static RoPilotExaminerLocationCurrent : RoPilotExaminerLocationCurrentMetaData = new RoPilotExaminerLocationCurrentMetaData();
    static RoPilotExaminerLocationDistances : RoPilotExaminerLocationDistancesMetaData = new RoPilotExaminerLocationDistancesMetaData();
    static RoPilotExaminerScenario : RoPilotExaminerScenarioMetaData = new RoPilotExaminerScenarioMetaData();
    static Schedule : ScheduleMetaData = new ScheduleMetaData();
    static ScheduleDayOfWeek : ScheduleDayOfWeekMetaData = new ScheduleDayOfWeekMetaData();
    static ScheduleDetail : ScheduleDetailMetaData = new ScheduleDetailMetaData();
    static StateProvince : StateProvinceMetaData = new StateProvinceMetaData();
    static StudentApplicant : StudentApplicantMetaData = new StudentApplicantMetaData();
    static TimeZone : TimeZoneMetaData = new TimeZoneMetaData();
    static DwUser : DwUserMetaData = new DwUserMetaData();
    static DwUserType : DwUserTypeMetaData = new DwUserTypeMetaData();
    static Queries = new MetaDataQueries();
}
// Generated Entity Interfaces
export interface AcsAreaOfOperationEntity extends DwOrmData {
    // Attributes
    AcsAreaOfOperationId?: number;
    AcsTestId?: number;
    AreaOfOperationName?: string;
    AreaOfOperationOrder?: number;
    AreaOfOperationCode?: string;
    AcsTestPortionId?: number;

    // Display Attributes
    AcsTestIdDisplay?: string;
    AcsTestPortionIdDisplay?: string;

    // Relations
    AcsTest? : AcsTestEntity;
    AcsTestPortion? : AcsTestPortionEntity;
    AcsAreaOfOperationTask? : AcsAreaOfOperationTaskEntity[];
    PlanOfActionSection? : PlanOfActionSectionEntity[];
}

export interface AcsAreaOfOperationTaskEntity extends DwOrmData {
    // Attributes
    AcsAreaOfOperationTaskId?: number;
    AcsAreaOfOperationId?: number;
    TaskName?: string;
    TaskOrder?: number;
    TaskCode?: string;

    // Display Attributes
    AcsAreaOfOperationIdDisplay?: string;

    // Relations
    AcsAreaOfOperation? : AcsAreaOfOperationEntity;
    AcsCode? : AcsCodeEntity[];
    PlanOfActionSection? : PlanOfActionSectionEntity[];
}

export interface AcsCodeEntity extends DwOrmData {
    // Attributes
    AcsCodeId?: number;
    AscCodeTypeId?: number;
    AcsCode?: string;
    AcsCodeOrder?: number;
    AcsAreaOfOperationTaskId?: number;
    AscCodeDescription?: string;

    // Display Attributes
    AscCodeTypeIdDisplay?: string;
    AcsAreaOfOperationTaskIdDisplay?: string;

    // Relations
    AcsAreaOfOperationTask? : AcsAreaOfOperationTaskEntity;
    AscCodeTypeIdAcsCodeType? : AcsCodeTypeEntity;
    PlanOfActionRequiredAcsCode? : PlanOfActionRequiredAcsCodeEntity[];
    PlanOfActionSectionQuestion? : PlanOfActionSectionQuestionEntity[];
    QuestionBank? : QuestionBankEntity[];
}

export interface AcsCodeSelectionModeEntity extends DwOrmData {
    // Attributes
    AcsCodeSelectionModeId?: number;
    AcsCodeSelectionMode?: string;


    // Relations
}

export interface AcsCodeTypeEntity extends DwOrmData {
    // Attributes
    AcsCodeTypeId?: number;
    AcsCodeTypeName?: string;
    AcsCodeTypeShortName?: string;


    // Relations
    AscCodeTypeIdAcsCode? : AcsCodeEntity[];
}

export interface AcsTestEntity extends DwOrmData {
    // Attributes
    AcsTestId?: number;
    TestName?: string;
    TestDescription?: string;
    CheckRideGroupId?: number;
    TestCode?: string;
    TestExpirationDate?: string;
    AcsTestStatusId?: number;

    // Display Attributes
    CheckRideGroupIdDisplay?: string;
    AcsTestStatusIdDisplay?: string;

    // Relations
    AcsTestStatus? : AcsTestStatusEntity;
    CheckRideGroup? : CheckRideGroupEntity;
    AcsAreaOfOperation? : AcsAreaOfOperationEntity[];
    PlanOfAction? : PlanOfActionEntity[];
    QuestionBank? : QuestionBankEntity[];
}

export interface AcsTestPortionEntity extends DwOrmData {
    // Attributes
    AcsTestPortionId?: number;
    AcsTestPortion?: string;
    HeaderClass?: string;
    IconClass?: string;
    AcsTestPortionOrder?: number;


    // Relations
    AcsAreaOfOperation? : AcsAreaOfOperationEntity[];
    PlanOfActionSection? : PlanOfActionSectionEntity[];
    PlanOfActionSectionType? : PlanOfActionSectionTypeEntity[];
}

export interface AcsTestStatusEntity extends DwOrmData {
    // Attributes
    AcsTestStatusId?: number;
    AcsTestStatus?: string;


    // Relations
    AcsTest? : AcsTestEntity[];
}

export interface AddressEntity extends DwOrmData {
    // Attributes
    AddressId?: number;
    StreetAddress1?: string;
    StreetAddress2?: string;
    City?: string;
    StateProvinceId?: number;
    CountryId?: number;
    CountyName?: string;
    PostalCode?: string;
    PostalCodePlus4?: string;
    Phone?: string;
    Fax?: string;
    Latitude?: number;
    Longitude?: number;
    CreatedDateTime?: string;
    CreatedBy?: number;
    ModifiedDateTime?: string;
    ModifiedBy?: number;
    TimezoneId?: number;
    ObservesDst?: boolean;

    // Display Attributes
    StateProvinceIdDisplay?: string;
    CountryIdDisplay?: string;
    TimezoneIdDisplay?: string;

    // Relations
    Country? : CountryEntity;
    StateProvince? : StateProvinceEntity;
    TimeZone? : TimeZoneEntity;
    AirportAirport? : AirportEntity[];
    ApplicantStudentApplicant? : StudentApplicantEntity[];
}

export interface AirportEntity extends DwOrmData {
    // Attributes
    AirportId?: number;
    AviationAuthorityId?: number;
    AirportName?: string;
    AirportCode?: string;
    AirportAddressId?: number;
    Elevation?: number;
    OwnershipCode?: string;
    IsActive?: boolean;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    AviationAuthorityIdDisplay?: string;
    AirportAddressIdDisplay?: string;

    // Relations
    AirportAddress? : AddressEntity;
    AviationAuthority? : AviationAuthorityEntity;
    PreferredApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    ScheduledCheckRideRequest? : CheckRideRequestEntity[];
    PreferredCheckRideRequest? : CheckRideRequestEntity[];
    ProposedCheckRideRequest? : CheckRideRequestEntity[];
    PilotExaminerLocationAirport? : PilotExaminerLocationAirportEntity[];
    RoPilotExaminerLocationDistances? : RoPilotExaminerLocationDistancesEntity[];
    MainStudentApplicant? : StudentApplicantEntity[];
}

export interface ApplicantPlaceholderEntity extends DwOrmData {
    // Attributes
    ApplicantPlaceholderId?: number;
    ApplicantPlaceholderStatusId?: number;
    PilotExaminerId?: number;
    StudentApplicantId?: number;
    CheckRideApplicantTypeId?: number;
    StudentApplicantNameManual?: string;
    StudentApplicantEmailManual?: string;
    StudentApplicantPhoneManual?: string;
    AviationAuthorityId?: number;
    CheckRideGroupId?: number;
    CheckRideTypeId?: number;
    PreferredAirportId?: number;
    PreferredDateTime?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    PilotCertificateNumber?: string;
    FlightSchoolName?: string;
    FlightInstructorName?: string;
    PrivateNotes?: string;
    CheckRideTestTypeId?: number;
    FlightSchoolId?: number;
    FlightSchoolNameOther?: string;
    AuthorityPreCheckRideNumber?: string;
    StudentApplicantCitizenshipCountyId?: number;
    AviationTrainingProgramId?: number;
    OriginalCheckRideRequestId?: number;
    FlightInstructorEmail?: string;
    FlightInstructorPhone?: string;
    LastContactDateTime?: string;
    RankingDateTime?: string;

    // Display Attributes
    ApplicantPlaceholderStatusIdDisplay?: string;
    PilotExaminerIdDisplay?: string;
    StudentApplicantIdDisplay?: string;
    CheckRideApplicantTypeIdDisplay?: string;
    AviationAuthorityIdDisplay?: string;
    CheckRideGroupIdDisplay?: string;
    CheckRideTypeIdDisplay?: string;
    PreferredAirportIdDisplay?: string;
    CreatedByDisplay?: string;
    CheckRideTestTypeIdDisplay?: string;
    FlightSchoolIdDisplay?: string;
    StudentApplicantCitizenshipCountyIdDisplay?: string;
    AviationTrainingProgramIdDisplay?: string;

    // Relations
    PreferredAirport? : AirportEntity;
    ApplicantPlaceholderStatus? : ApplicantPlaceholderStatusEntity;
    AviationAuthority? : AviationAuthorityEntity;
    AviationTrainingProgram? : AviationTrainingProgramEntity;
    CheckRideApplicantType? : CheckRideApplicantTypeEntity;
    CheckRideGroup? : CheckRideGroupEntity;
    CheckRideTestType? : CheckRideTestTypeEntity;
    CheckRideType? : CheckRideTypeEntity;
    StudentApplicantCitizenshipCountyIdCountry? : CountryEntity;
    FlightSchool? : FlightSchoolEntity;
    PilotExaminer? : PilotExaminerEntity;
    StudentApplicant? : StudentApplicantEntity;
    CheckRideRequest? : CheckRideRequestEntity[];
}

export interface ApplicantPlaceholderStatusEntity extends DwOrmData {
    // Attributes
    ApplicantPlaceholderStatusId?: number;
    ApplicantPlaceholderStatus?: string;


    // Relations
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
}

export interface AviationAuthorityEntity extends DwOrmData {
    // Attributes
    AviationAuthorityId?: number;
    AuthorityName?: string;


    // Relations
    Airport? : AirportEntity[];
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    AviationAuthorityDistrictOffice? : AviationAuthorityDistrictOfficeEntity[];
    AviationTrainingProgram? : AviationTrainingProgramEntity[];
    CheckRideGroup? : CheckRideGroupEntity[];
    CheckRideRequest? : CheckRideRequestEntity[];
    PilotExaminer? : PilotExaminerEntity[];
    PlanOfAction? : PlanOfActionEntity[];
    StudentApplicant? : StudentApplicantEntity[];
}

export interface AviationAuthorityDistrictOfficeEntity extends DwOrmData {
    // Attributes
    AviationAuthorityDistrictOfficeId?: number;
    AviationAuthorityId?: number;
    DistictOfficeName?: string;
    PhoneNumber?: string;

    // Display Attributes
    AviationAuthorityIdDisplay?: string;

    // Relations
    AviationAuthority? : AviationAuthorityEntity;
    PilotExaminer? : PilotExaminerEntity[];
}

export interface AviationTrainingProgramEntity extends DwOrmData {
    // Attributes
    AviationTrainingProgramId?: number;
    AviationAuthorityId?: number;
    TrainingProgramName?: string;
    TrainingProgramShortName?: string;

    // Display Attributes
    AviationAuthorityIdDisplay?: string;

    // Relations
    AviationAuthority? : AviationAuthorityEntity;
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    AvaiationTrainingProgramIdCheckRideRequest? : CheckRideRequestEntity[];
    StudentApplicant? : StudentApplicantEntity[];
}

export interface CalendarEventReferenceEntity extends DwOrmData {
    // Attributes
    CalendarEventReferenceId?: number;
    CalendarReferenceId?: number;
    EventReferenceKey?: string;
    CalendarKey?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    IsCancelled?: boolean;

    // Display Attributes
    CalendarReferenceIdDisplay?: string;

    // Relations
    CalendarReference? : CalendarReferenceEntity;
    CalendarReferenceEventIdCalendarReferenceLog? : CalendarReferenceLogEntity[];
    ScheduledCheckRideRequest? : CheckRideRequestEntity[];
}

export interface CalendarReferenceEntity extends DwOrmData {
    // Attributes
    CalendarReferenceId?: number;
    CalendarReferenceTypeId?: number;
    MainCalendarKey?: string;
    RefreshSecretKey?: string;
    InitialSecretKey?: string;
    CalendarConfigJSON?: string;
    CalendarIsSetup?: boolean;
    RefreshCount?: number;
    LastRefreshDateTime?: string;
    CalendarHasErrors?: boolean;
    LastCalendarErrorDateTime?: string;
    MainCalendarTimezone?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CalendarReferenceTypeIdDisplay?: string;

    // Relations
    CalendarReferenceType? : CalendarReferenceTypeEntity;
    CalendarEventReference? : CalendarEventReferenceEntity[];
    CalendarReferenceLog? : CalendarReferenceLogEntity[];
    PilotExaminer? : PilotExaminerEntity[];
}

export interface CalendarReferenceLogEntity extends DwOrmData {
    // Attributes
    CalendarReferenceLogId?: number;
    CalendarReferenceId?: number;
    LogId?: number;
    CalendarReferenceEventId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CalendarReferenceIdDisplay?: string;
    LogIdDisplay?: string;
    CalendarReferenceEventIdDisplay?: string;

    // Relations
    CalendarReferenceEventIdCalendarEventReference? : CalendarEventReferenceEntity;
    CalendarReference? : CalendarReferenceEntity;
}

export interface CalendarReferenceTypeEntity extends DwOrmData {
    // Attributes
    CalendarReferenceTypeId?: number;
    CalendarReferenceTypeDisplay?: string;
    CalendarReferenceTypeMeaning?: string;


    // Relations
    CalendarReference? : CalendarReferenceEntity[];
}

export interface CheckRideApplicantTypeEntity extends DwOrmData {
    // Attributes
    CheckRideApplicantTypeId?: number;
    ApplicantType?: string;


    // Relations
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    CheckRideRequest? : CheckRideRequestEntity[];
}

export interface CheckRideCancellationTypeEntity extends DwOrmData {
    // Attributes
    CheckRideCancellationTypeId?: number;
    CancellationTypeDisplay?: string;
    CancelOrder?: number;


    // Relations
    CheckRideRequest? : CheckRideRequestEntity[];
}

export interface CheckRideGroupEntity extends DwOrmData {
    // Attributes
    CheckRideGroupId?: number;
    AviationAuthorityId?: number;
    CheckRideGroup?: string;
    GroupOrder?: number;

    // Display Attributes
    AviationAuthorityIdDisplay?: string;

    // Relations
    AviationAuthority? : AviationAuthorityEntity;
    AcsTest? : AcsTestEntity[];
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    CheckRideRequest? : CheckRideRequestEntity[];
    CheckRideScenario? : CheckRideScenarioEntity[];
    CheckRideType? : CheckRideTypeEntity[];
}

export interface CheckRideOutcomeEntity extends DwOrmData {
    // Attributes
    CheckRideOutcomeId?: number;
    CheckRideOutcome?: string;
    AllowRetest?: boolean;
    FlagStudentApplication?: boolean;
    FlagStudentNotes?: string;


    // Relations
    CheckRideResult? : CheckRideResultEntity[];
    PlanOfActionResult? : PlanOfActionResultEntity[];
}

export interface CheckRidePaymentEntity extends DwOrmData {
    // Attributes
    CheckRidePaymentId?: number;
    PaymentTypeId?: number;
    PaymentMethodId?: number;
    PaymentDateTime?: string;
    PaymentAmount?: number;
    CheckRideRequestId?: number;
    PaymentReferenceId?: number;
    PaymentReferenceText?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    PaymentDiscountid?: number;
    PaymentPartyId?: number;
    EmailCCReceipt?: string;

    // Display Attributes
    PaymentTypeIdDisplay?: string;
    PaymentMethodIdDisplay?: string;
    CheckRideRequestIdDisplay?: string;
    PaymentReferenceIdDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    PaymentDiscountidDisplay?: string;
    PaymentPartyIdDisplay?: string;

    // Relations
    CheckRideRequest? : CheckRideRequestEntity;
    PaymentPartyIdCheckRideRequestPaymentParty? : CheckRideRequestPaymentPartyEntity;
    PaymentDiscountidPaymentDiscountCode? : PaymentDiscountCodeEntity;
    PaymentMethod? : PaymentMethodEntity;
    PaymentReference? : PaymentReferenceEntity;
    PaymentType? : PaymentTypeEntity;
    PilexosBookingCheckRideRequest? : CheckRideRequestEntity[];
}

export interface CheckRideRequestEntity extends DwOrmData {
    // Attributes
    CheckRideRequestId?: number;
    StudentApplicantId?: number;
    AviationAuthorityId?: number;
    CheckRideTypeId?: number;
    CheckRideTestTypeId?: number;
    PilotCertificateNumber?: string;
    CheckRideRequestSubStatusId?: number;
    PreferredDateTime?: string;
    ScheduledTimeMinutes?: number;
    ScheduledPilotExaminerId?: number;
    ScheduledPreTimeMinutes?: number;
    ScheduledPostTimeMinutes?: number;
    AuthorityPreCheckRideNumber?: string;
    AirplaneMakeModel?: string;
    AirplaneNNumber?: string;
    DocumentsMediaGroupId?: number;
    AuthorityPreapprovalCode?: string;
    CheckRideGroupId?: number;
    CancellationNotes?: string;
    PreferredAirportId?: number;
    ScheduledAirportId?: number;
    ScheduledDateTime?: string;
    ScheduledCheckRideScenarioId?: number;
    MedicalCertificateMediaId?: number;
    LogBookMediaId?: number;
    AdditionalDocumentationNotes?: string;
    TrainingMediaId?: number;
    ScheduledCalendarEventReferenceId?: number;
    EndorsedAndCheckRideReady?: boolean;
    ScheduledPilotExaminerLocationId?: number;
    ProposedDateTime?: string;
    ProposedAirportId?: number;
    ProposedTimeMinutes?: number;
    ProposedCalendarEventReferenceId?: number;
    ScheduledCheckRideCost?: number;
    AdditionalTravelCost?: number;
    PilexosBookingCost?: number;
    ExaminerBookingCost?: number;
    RejectedNotes?: string;
    MoreInfoRequestedNotes?: string;
    PilexosBookingPaymentAdded?: boolean;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    AvaiationTrainingProgramId?: number;
    CheckRideApplicantTypeId?: number;
    StudentApplicantNameManual?: string;
    StudentApplicantEmailManual?: string;
    StudentApplicantPhoneManual?: string;
    ExaminerPrivateNotes?: string;
    ExaminerToApplicantNotes?: string;
    ExaminerToApplicantMediaGroupId?: number;
    IsAirplaneAirworthy?: boolean;
    EmailApplicantReceipt?: boolean;
    CheckRideCancellationTypeId?: number;
    CheckRideCancellationTypeOther?: string;
    PilexosBookingCostPaymentPartyId?: number;
    FlightSchoolName?: string;
    EmailCCReceipt?: string;
    CancelledRequestBookingFeeRefund?: boolean;
    PilexosBookingCheckRidePaymentId?: number;
    ScheduleAirportLocationNotes?: string;
    AuthorityApprovalCode?: string;
    FlightInstructorName?: string;
    ApplicantPlaceholderId?: number;
    AddToWaitlist?: boolean;
    FlightSchoolId?: number;
    FlightSchoolNameOther?: string;
    StudentApplicantCitizenshipCountyId?: number;
    AirmanKnowledgeTestScore?: number;
    FlightInstructorCertificateNumber?: string;
    CurrentPilotCertifiateTypeId?: number;
    FlightInstructorEmail?: string;
    FlightInstructorPhone?: string;

    // Display Attributes
    StudentApplicantIdDisplay?: string;
    AviationAuthorityIdDisplay?: string;
    CheckRideTypeIdDisplay?: string;
    CheckRideTestTypeIdDisplay?: string;
    CheckRideRequestSubStatusIdDisplay?: string;
    ScheduledTimeMinutesDisplay?: string;
    ScheduledPilotExaminerIdDisplay?: string;
    DocumentsMediaGroupIdDisplay?: string;
    CheckRideGroupIdDisplay?: string;
    PreferredAirportIdDisplay?: string;
    ScheduledAirportIdDisplay?: string;
    ScheduledCheckRideScenarioIdDisplay?: string;
    MedicalCertificateMediaIdDisplay?: string;
    LogBookMediaIdDisplay?: string;
    ScheduledPilotExaminerLocationIdDisplay?: string;
    ProposedAirportIdDisplay?: string;
    ProposedTimeMinutesDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    AvaiationTrainingProgramIdDisplay?: string;
    CheckRideApplicantTypeIdDisplay?: string;
    CheckRideCancellationTypeIdDisplay?: string;
    PilexosBookingCostPaymentPartyIdDisplay?: string;
    PilexosBookingCheckRidePaymentIdDisplay?: string;
    ApplicantPlaceholderIdDisplay?: string;
    FlightSchoolIdDisplay?: string;
    StudentApplicantCitizenshipCountyIdDisplay?: string;
    CurrentPilotCertifiateTypeIdDisplay?: string;

    // Relations
    ScheduledAirport? : AirportEntity;
    PreferredAirport? : AirportEntity;
    ProposedAirport? : AirportEntity;
    ApplicantPlaceholder? : ApplicantPlaceholderEntity;
    AviationAuthority? : AviationAuthorityEntity;
    AvaiationTrainingProgramIdAviationTrainingProgram? : AviationTrainingProgramEntity;
    ScheduledCalendarEventReference? : CalendarEventReferenceEntity;
    CheckRideApplicantType? : CheckRideApplicantTypeEntity;
    CheckRideCancellationType? : CheckRideCancellationTypeEntity;
    CheckRideGroup? : CheckRideGroupEntity;
    PilexosBookingCheckRidePayment? : CheckRidePaymentEntity;
    PilexosBookingCostPaymentPartyIdCheckRideRequestPaymentParty? : CheckRideRequestPaymentPartyEntity;
    CheckRideRequestSubStatus? : CheckRideRequestSubStatusEntity;
    ScheduledCheckRideScenario? : CheckRideScenarioEntity;
    CheckRideTestType? : CheckRideTestTypeEntity;
    CheckRideType? : CheckRideTypeEntity;
    StudentApplicantCitizenshipCountyIdCountry? : CountryEntity;
    FlightSchool? : FlightSchoolEntity;
    DocumentsDwMediaGroup? : EnterpriseMetaData.DwMediaGroupEntity;
    CurrentPilotCertifiateTypeIdPilotCertificateType? : PilotCertificateTypeEntity;
    ScheduledPilotExaminer? : PilotExaminerEntity;
    ScheduledPilotExaminerLocation? : PilotExaminerLocationEntity;
    StudentApplicant? : StudentApplicantEntity;
    CheckRidePayment? : CheckRidePaymentEntity[];
    CheckRideResult? : CheckRideResultEntity;
    PlanOfAction? : PlanOfActionEntity[];
    CheckrideRequestIdRoCheckrideAggregateUtil? : RoCheckrideAggregateUtilEntity;
    RoCheckrideApplicantFinalName? : RoCheckrideApplicantFinalNameEntity;
    CheckrideRequestIdRoCheckrideRequestScheduleDateInfo? : RoCheckrideRequestScheduleDateInfoEntity;
}

export interface CheckRideRequestPaymentPartyEntity extends DwOrmData {
    // Attributes
    CheckRideRequestPaymentPartyId?: number;
    PaymentPartyDisplay?: string;
    IsActive?: boolean;
    PaymentPartyDescription?: string;
    PilexosBookingCost?: number;
    PilexosBookingRetestCost?: number;


    // Relations
    PaymentPartyIdCheckRidePayment? : CheckRidePaymentEntity[];
    PilexosBookingCostPaymentPartyIdCheckRideRequest? : CheckRideRequestEntity[];
}

export interface CheckRideRequestStatusEntity extends DwOrmData {
    // Attributes
    CheckRideRequestStatusId?: number;
    CheckRideRequestStatus?: string;


    // Relations
    CheckRideRequestSubStatus? : CheckRideRequestSubStatusEntity[];
}

export interface CheckRideRequestSubStatusEntity extends DwOrmData {
    // Attributes
    CheckRideRequestSubStatusId?: number;
    CheckRideRequestStatusId?: number;
    CheckRideRequestSubStatus?: string;
    WorkflowStatusDetailId?: number;
    ApplicantNotes?: string;
    ExaminerNotes?: string;
    ApplicantStatusLevelId?: number;
    ExaminerStatusLevelId?: number;
    ApplicantAllowEdit?: boolean;
    ApplicantAllowExaminerChange?: boolean;
    ExaminerAllowEdit?: boolean;
    OutcomeIsRequested?: number;
    OutcomeIsCancelled?: number;
    OutcomeIsBooked?: number;
    OutcomeIsComplete?: number;

    // Display Attributes
    CheckRideRequestStatusIdDisplay?: string;
    WorkflowStatusDetailIdDisplay?: string;
    ApplicantStatusLevelIdDisplay?: string;
    ExaminerStatusLevelIdDisplay?: string;

    // Relations
    CheckRideRequestStatus? : CheckRideRequestStatusEntity;
    ApplicantStatusLevelIdCheckRideStatusLevel? : CheckRideStatusLevelEntity;
    ExaminerStatusLevelIdCheckRideStatusLevel? : CheckRideStatusLevelEntity;
    CheckRideRequest? : CheckRideRequestEntity[];
}

export interface CheckRideResultEntity extends DwOrmData {
    // Attributes
    CheckRideResultId?: number;
    CheckRideRequestId?: number;
    CheckRideDateTime?: string;
    CheckRideOutcomeId?: number;
    AllowRetest?: boolean;
    AllowRetestReason?: string;
    RetestCost?: number;
    FlagApplicant?: boolean;
    FlagApplicantNotes?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    AddToWaitlist?: boolean;
    GroundPortionComplete?: boolean;

    // Display Attributes
    CheckRideOutcomeIdDisplay?: string;

    // Relations
    CheckRideOutcome? : CheckRideOutcomeEntity;
    CheckRideRequest? : CheckRideRequestEntity;
}

export interface CheckRideScenarioEntity extends DwOrmData {
    // Attributes
    CheckRideScenarioId?: number;
    PilotExaminerId?: number;
    ScenarioName?: string;
    TimeRequiredHours?: number;
    PreTimeMinutes?: number;
    PostTimeMinutes?: number;
    CheckRideCost?: number;
    RetestCost?: number;
    DiscontinuedRetestCost?: number;
    DocumentsMediaGroupId?: number;
    RequiredDocumentNotes?: string;
    BookingCost?: number;
    CheckRideGroupId?: number;
    IsActive?: boolean;
    TravelCost?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    TimeRequiredMinutes?: number;
    RetestFlightOnlyCost?: number;
    DiscontinuedRetestFlightOnlyCost?: number;
    RetestFlightOnlyTimeReqirementMinutes?: number;
    RetestTimeRequiredMinutes?: number;
    RetestFlightOnlyTimeRequiredMinutes?: number;
    DiscontinuedTimeRequiredMinutes?: number;
    DiscontinuedFlightOnlyTimeRequiredMinutes?: number;

    // Display Attributes
    PilotExaminerIdDisplay?: string;
    CheckRideGroupIdDisplay?: string;
    TimeRequiredMinutesDisplay?: string;
    RetestFlightOnlyTimeReqirementMinutesDisplay?: string;
    RetestTimeRequiredMinutesDisplay?: string;
    RetestFlightOnlyTimeRequiredMinutesDisplay?: string;
    DiscontinuedTimeRequiredMinutesDisplay?: string;
    DiscontinuedFlightOnlyTimeRequiredMinutesDisplay?: string;

    // Relations
    CheckRideGroup? : CheckRideGroupEntity;
    PilotExaminer? : PilotExaminerEntity;
    ScheduledCheckRideRequest? : CheckRideRequestEntity[];
    CheckRideScenarioTypeMap? : CheckRideScenarioTypeMapEntity[];
    RoPilotExaminerScenario? : RoPilotExaminerScenarioEntity[];
}

export interface CheckRideScenarioTypeMapEntity extends DwOrmData {
    // Attributes
    CheckRideScenarioTypeMapId?: number;
    CheckRideScenarioId?: number;
    CheckRideTypeId?: number;

    // Display Attributes
    CheckRideScenarioIdDisplay?: string;
    CheckRideTypeIdDisplay?: string;

    // Relations
    CheckRideScenario? : CheckRideScenarioEntity;
    CheckRideType? : CheckRideTypeEntity;
}

export interface CheckRideStatusLevelEntity extends DwOrmData {
    // Attributes
    CheckRideStatusLevelId?: number;
    StatusLevelDisplay?: string;


    // Relations
    ApplicantStatusLevelIdCheckRideRequestSubStatus? : CheckRideRequestSubStatusEntity[];
    ExaminerStatusLevelIdCheckRideRequestSubStatus? : CheckRideRequestSubStatusEntity[];
}

export interface CheckRideTestTypeEntity extends DwOrmData {
    // Attributes
    CheckRideTestTypeId?: number;
    TestType?: string;


    // Relations
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    CheckRideRequest? : CheckRideRequestEntity[];
}

export interface CheckRideTypeEntity extends DwOrmData {
    // Attributes
    CheckRideTypeId?: number;
    CheckRideGroupId?: number;
    CheckRideType?: string;
    CheckRideDesignation?: string;
    TypeOrder?: number;

    // Display Attributes
    CheckRideGroupIdDisplay?: string;

    // Relations
    CheckRideGroup? : CheckRideGroupEntity;
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    CheckRideRequest? : CheckRideRequestEntity[];
    CheckRideScenarioTypeMap? : CheckRideScenarioTypeMapEntity[];
}

export interface CountryEntity extends DwOrmData {
    // Attributes
    CountryId?: number;
    CountryName?: string;
    CountryShortName?: string;
    CreatedDateTime?: string;
    CreatedBy?: number;
    ModifiedDateTime?: string;
    ModifiedBy?: number;


    // Relations
    Address? : AddressEntity[];
    StudentApplicantCitizenshipCountyIdApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    StudentApplicantCitizenshipCountyIdCheckRideRequest? : CheckRideRequestEntity[];
    StudentApplicantCitizenshipPlanOfAction? : PlanOfActionEntity[];
    StateProvince? : StateProvinceEntity[];
    CitzenshipCountyIdStudentApplicant? : StudentApplicantEntity[];
}

export interface DurationEntity extends DwOrmData {
    // Attributes
    DurationId?: number;
    DurationMinutes?: number;
    DurationDisplay?: string;


    // Relations
}

export interface FlightSchoolEntity extends DwOrmData {
    // Attributes
    FlightSchoolId?: number;
    FlightSchoolName?: string;
    IsOther?: boolean;


    // Relations
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    CheckRideRequest? : CheckRideRequestEntity[];
}

export interface DwMediaGroupEntity extends DwOrmData {
    // Attributes
    CreatedBy?: number;
    CreatedDateTime?: string;
    GroupName?: string;
    IsGlobalGroup?: boolean;
    IsSecure?: boolean;
    MediaGroupId?: number;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    PartyReferenceId?: number;

    // Display Attributes
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    PartyReferenceIdDisplay?: string;

    // Relations
    DocumentsCheckRideRequest? : CheckRideRequestEntity[];
    DwroMedia? : EnterpriseMetaData.DwRoMediaEntity[];
}

export interface PaymentCustomerReferenceEntity extends DwOrmData {
    // Attributes
    PaymentCustomerReferenceId?: number;
    UserId?: number;
    CustomerReferenceKey?: string;
    PaymentProviderId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    UserIdDisplay?: string;
    PaymentProviderIdDisplay?: string;

    // Relations
    PaymentProvider? : PaymentProviderEntity;
    DwUser? : EnterpriseMetaData.DwUserEntity;
    PaymentReference? : PaymentReferenceEntity[];
}

export interface PaymentDiscountCodeEntity extends DwOrmData {
    // Attributes
    PaymentDiscountCodeId?: number;
    DiscountName?: string;
    DiscountCode?: string;
    StartDate?: string;
    EndDate?: string;
    AllowedUses?: number;
    IsActive?: boolean;
    LimitToUserId?: number;
    DiscountAmount?: number;
    CurrentUses?: number;
    DiscountPercent?: number;

    // Display Attributes
    LimitToUserIdDisplay?: string;

    // Relations
    LimitToDwUser? : EnterpriseMetaData.DwUserEntity;
    PaymentDiscountidCheckRidePayment? : CheckRidePaymentEntity[];
}

export interface PaymentMethodEntity extends DwOrmData {
    // Attributes
    PaymentMethodId?: number;
    PaymentMethod?: string;


    // Relations
    CheckRidePayment? : CheckRidePaymentEntity[];
}

export interface PaymentProviderEntity extends DwOrmData {
    // Attributes
    PaymentProviderId?: number;
    PaymentProviderDisplay?: string;
    PaymentProviderMeaning?: string;


    // Relations
    PaymentCustomerReference? : PaymentCustomerReferenceEntity[];
}

export interface PaymentReferenceEntity extends DwOrmData {
    // Attributes
    PaymentReferenceId?: number;
    PaymentCustomerReferenceId?: number;
    PaymentReferenceKey?: string;
    PaymentReferenceCardTypeId?: number;
    PaymentReference?: string;
    PaymentReferenceStatusId?: number;
    CreatedDateTime?: string;
    CreatedBy?: number;
    ModifiedDateTime?: string;
    ModifiedBy?: number;
    PaymentAmount?: number;
    CardHolderName?: string;

    // Display Attributes
    PaymentCustomerReferenceIdDisplay?: string;
    PaymentReferenceCardTypeIdDisplay?: string;
    PaymentReferenceStatusIdDisplay?: string;

    // Relations
    PaymentCustomerReference? : PaymentCustomerReferenceEntity;
    PaymentReferenceCardType? : PaymentReferenceCardTypeEntity;
    PaymentReferenceStatus? : PaymentReferenceStatusEntity;
    CheckRidePayment? : CheckRidePaymentEntity[];
    PaymentReferenceHistory? : PaymentReferenceHistoryEntity[];
}

export interface PaymentReferenceCardTypeEntity extends DwOrmData {
    // Attributes
    PaymentReferenceCardTypeId?: number;
    CardTypeDisplay?: string;
    CardTypeMeaning?: string;


    // Relations
    PaymentReference? : PaymentReferenceEntity[];
}

export interface PaymentReferenceHistoryEntity extends DwOrmData {
    // Attributes
    PaymentReferenceHistoryId?: number;
    PaymentReferenceId?: number;
    PaymentReferenceStatusId?: number;
    CreatedDateTime?: string;

    // Display Attributes
    PaymentReferenceIdDisplay?: string;
    PaymentReferenceStatusIdDisplay?: string;

    // Relations
    PaymentReference? : PaymentReferenceEntity;
    PaymentReferenceStatus? : PaymentReferenceStatusEntity;
}

export interface PaymentReferenceStatusEntity extends DwOrmData {
    // Attributes
    PaymentReferenceStatusId?: number;
    PaymentReferenceStatus?: string;


    // Relations
    PaymentReference? : PaymentReferenceEntity[];
    PaymentReferenceHistory? : PaymentReferenceHistoryEntity[];
}

export interface PaymentTypeEntity extends DwOrmData {
    // Attributes
    PaymentTypeId?: number;
    PaymentType?: string;


    // Relations
    CheckRidePayment? : CheckRidePaymentEntity[];
}

export interface PilotCertificateTypeEntity extends DwOrmData {
    // Attributes
    PilotCertificateTypeId?: number;
    PilotCertiicateTypeId?: string;


    // Relations
    CurrentPilotCertifiateTypeIdCheckRideRequest? : CheckRideRequestEntity[];
    CurrentPilotCertifiateTypeIdStudentApplicant? : StudentApplicantEntity[];
}

export interface PilotExaminerEntity extends DwOrmData {
    // Attributes
    PilotExaminerId?: number;
    UserId?: number;
    PilotExaminerStatusId?: number;
    Description?: string;
    AviationAuthorityId?: number;
    DefaultPreTimeMinutes?: number;
    DefaultPostTimeMinutes?: number;
    TravelRadiusMiles?: number;
    AuthorityExaminerNumber?: string;
    CalendarIsLinked?: boolean;
    CalendarReferenceId?: number;
    RequireAuthorityPreCheckRideNumber?: boolean;
    PublicProfileIsActive?: boolean;
    PublicProfileSlug?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    CheckRideRequestLeadTimeDays?: number;
    CheckRideRequestBookingMaxWeeksOut?: number;
    AviationAuthorityDistrictOfficeId?: number;
    DailyTravelRadiusLockMiles?: number;

    // Display Attributes
    UserIdDisplay?: string;
    PilotExaminerStatusIdDisplay?: string;
    AviationAuthorityIdDisplay?: string;
    CalendarReferenceIdDisplay?: string;
    AviationAuthorityDistrictOfficeIdDisplay?: string;

    // Relations
    AviationAuthority? : AviationAuthorityEntity;
    AviationAuthorityDistrictOffice? : AviationAuthorityDistrictOfficeEntity;
    CalendarReference? : CalendarReferenceEntity;
    PilotExaminerStatus? : PilotExaminerStatusEntity;
    DwUser? : EnterpriseMetaData.DwUserEntity;
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    ScheduledCheckRideRequest? : CheckRideRequestEntity[];
    CheckRideScenario? : CheckRideScenarioEntity[];
    PilotExaminerLocation? : PilotExaminerLocationEntity[];
    PlanOfAction? : PlanOfActionEntity[];
    RoPilotExaminerLocationDistances? : RoPilotExaminerLocationDistancesEntity[];
    RoPilotExaminerScenario? : RoPilotExaminerScenarioEntity[];
    ReferredStudentApplicant? : StudentApplicantEntity[];
}

export interface PilotExaminerLocationEntity extends DwOrmData {
    // Attributes
    PilotExaminerLocationId?: number;
    PilotExaminerId?: number;
    LocationName?: string;
    SchedulingStartDate?: string;
    SchedulingEndDate?: string;
    TravelRadiusMile?: number;
    BookingScheduleId?: number;
    TravelCost?: number;
    IsDefaultLocation?: boolean;
    IsActive?: boolean;
    AdditionalLocationCost?: number;
    BookingScheduleTimeZoneId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    ShowMetar?: boolean;
    PilotExaminerLocationTypeId?: number;

    // Display Attributes
    PilotExaminerIdDisplay?: string;
    BookingScheduleIdDisplay?: string;
    BookingScheduleTimeZoneIdDisplay?: string;
    PilotExaminerLocationTypeIdDisplay?: string;

    // Relations
    PilotExaminer? : PilotExaminerEntity;
    PilotExaminerLocationType? : PilotExaminerLocationTypeEntity;
    BookingSchedule? : ScheduleEntity;
    BookingScheduleTimeZoneIdTimeZone? : TimeZoneEntity;
    ScheduledCheckRideRequest? : CheckRideRequestEntity[];
    PilotExaminerLocationAirport? : PilotExaminerLocationAirportEntity[];
    RoPilotExaminerLocationCurrent? : RoPilotExaminerLocationCurrentEntity;
    RoPilotExaminerLocationDistances? : RoPilotExaminerLocationDistancesEntity[];
}

export interface PilotExaminerLocationAirportEntity extends DwOrmData {
    // Attributes
    PilotExaminerLocationAirportId?: number;
    PilotExaminerLocationId?: number;
    AirportId?: number;

    // Display Attributes
    PilotExaminerLocationAirportIdDisplay?: string;
    PilotExaminerLocationIdDisplay?: string;
    AirportIdDisplay?: string;

    // Relations
    Airport? : AirportEntity;
    PilotExaminerLocation? : PilotExaminerLocationEntity;
}

export interface PilotExaminerLocationStateEntity extends DwOrmData {
    // Attributes
    PilotExaminerLocationStateId?: number;
    StateMeaning?: string;
    StateDisplay?: string;


    // Relations
}

export interface PilotExaminerLocationTypeEntity extends DwOrmData {
    // Attributes
    PilotExaminerLocationTypeId?: number;
    PilotExaminerLocationType?: string;


    // Relations
    PilotExaminerLocation? : PilotExaminerLocationEntity[];
}

export interface PilotExaminerStatusEntity extends DwOrmData {
    // Attributes
    PilotExaminerStatusId?: number;
    PilotExaminerStatus?: string;
    WorkflowStatusDetailId?: number;

    // Display Attributes
    WorkflowStatusDetailIdDisplay?: string;

    // Relations
    PilotExaminer? : PilotExaminerEntity[];
}

export interface PlanOfActionEntity extends DwOrmData {
    // Attributes
    PlanOfActionId?: number;
    PlanOfActionName?: string;
    PlanOfActionStatusId?: number;
    AviationAuthorityId?: number;
    AcsTestId?: number;
    PilotExaminerId?: number;
    CheckRideRequestId?: number;
    CheckrideDateTime?: string;
    StudentApplicantNameManual?: string;
    StudentApplicantEmailManual?: string;
    StudentApplicantPhoneManual?: string;
    StudentApplicantCitizenshipCountryId?: number;
    AuthorityPreCheckRideNumber?: string;
    PilotCertificateNumber?: string;
    AirplaneNNumber?: string;
    AuthorityPreapprovalCode?: string;
    // Attribute Authority Approval Code has invalid characters and can't be included
    ApplicationPaperworkCorrect?: boolean;
    EndorsementsPaperworkCorrect?: boolean;
    OptionsShowQuestionForAcsCode?: boolean;
    IsTemplate?: boolean;
    IsSharedTemplate?: boolean;
    TemplatePlanOfActionId?: number;
    IsBuiltIn?: boolean;
    OwnerUserId?: number;
    PlanOfActionResultId?: number;

    // Display Attributes
    PlanOfActionStatusIdDisplay?: string;
    AviationAuthorityIdDisplay?: string;
    AcsTestIdDisplay?: string;
    PilotExaminerIdDisplay?: string;
    CheckRideRequestIdDisplay?: string;
    StudentApplicantCitizenshipCountryIdDisplay?: string;
    TemplatePlanOfActionIdDisplay?: string;
    PlanOfActionResultIdDisplay?: string;

    // Relations
    AcsTest? : AcsTestEntity;
    AviationAuthority? : AviationAuthorityEntity;
    CheckRideRequest? : CheckRideRequestEntity;
    StudentApplicantCitizenshipCountry? : CountryEntity;
    PilotExaminer? : PilotExaminerEntity;
    PlanOfActionResult? : PlanOfActionResultEntity;
    PlanOfActionStatus? : PlanOfActionStatusEntity;
    TemplatePlanOfAction? : PlanOfActionEntity;
    PlanOfActionRequiredAcsCode? : PlanOfActionRequiredAcsCodeEntity[];
    PlanOfActionSection? : PlanOfActionSectionEntity[];
    // Duplicate property excluded
    // TemplatePlanOfAction : PlanOfActionEntity[];
}

export interface PlanOfActionQuestionResultEntity extends DwOrmData {
    // Attributes
    PlanOfActionQuestionResultId?: number;
    PlanOfActionResultId?: number;
    PlanOfActionSectionQuestionId?: number;
    QuestionOutcomeId?: number;
    DiscussQuestion?: boolean;
    Notes?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    PlanOfActionResultIdDisplay?: string;
    PlanOfActionSectionQuestionIdDisplay?: string;
    QuestionOutcomeIdDisplay?: string;

    // Relations
    PlanOfActionResult? : PlanOfActionResultEntity;
    PlanOfActionSectionQuestion? : PlanOfActionSectionQuestionEntity;
    QuestionOutcome? : QuestionOutcomeEntity;
}

export interface PlanOfActionRequiredAcsCodeEntity extends DwOrmData {
    // Attributes
    PlanOfActionRequiredAcsCodeId?: number;
    PlanOfActionId?: number;
    AcsCodeId?: number;

    // Display Attributes
    PlanOfActionIdDisplay?: string;
    AcsCodeIdDisplay?: string;

    // Relations
    AcsCode? : AcsCodeEntity;
    PlanOfAction? : PlanOfActionEntity;
}

export interface PlanOfActionResultEntity extends DwOrmData {
    // Attributes
    PlanOfActionResultId?: number;
    CheckRideStartDateTime?: string;
    CheckRideEndDateTime?: string;
    TotalTimeGroundMinutes?: number;
    TotalTimeFlightMinute?: number;
    CheckRideOutcomeId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    CheckRideOutcomeIdDisplay?: string;

    // Relations
    CheckRideOutcome? : CheckRideOutcomeEntity;
    PlanOfAction? : PlanOfActionEntity[];
    PlanOfActionQuestionResult? : PlanOfActionQuestionResultEntity[];
}

export interface PlanOfActionSectionEntity extends DwOrmData {
    // Attributes
    PlanOfActionSectionId?: number;
    PlanOfActionId?: number;
    SectionName?: string;
    PlanOfActionSectionTypeId?: number;
    AcsAreaOfOperationId?: number;
    AcsAreaOfOperationTaskId?: number;
    SectionOrder?: number;
    AcsTestPortionId?: number;

    // Display Attributes
    PlanOfActionIdDisplay?: string;
    PlanOfActionSectionTypeIdDisplay?: string;
    AcsAreaOfOperationIdDisplay?: string;
    AcsAreaOfOperationTaskIdDisplay?: string;
    AcsTestPortionIdDisplay?: string;

    // Relations
    AcsAreaOfOperation? : AcsAreaOfOperationEntity;
    AcsAreaOfOperationTask? : AcsAreaOfOperationTaskEntity;
    AcsTestPortion? : AcsTestPortionEntity;
    PlanOfAction? : PlanOfActionEntity;
    PlanOfActionSectionType? : PlanOfActionSectionTypeEntity;
    PlanOfActionSectionQuestion? : PlanOfActionSectionQuestionEntity[];
}

export interface PlanOfActionSectionQuestionEntity extends DwOrmData {
    // Attributes
    PlanOfActionSectionQuestionId?: number;
    PlanOfActionSectionId?: number;
    AcsCodeId?: number;
    QuestionHtml?: string;
    QuestionOrder?: number;

    // Display Attributes
    PlanOfActionSectionIdDisplay?: string;
    AcsCodeIdDisplay?: string;

    // Relations
    AcsCode? : AcsCodeEntity;
    PlanOfActionSection? : PlanOfActionSectionEntity;
    PlanOfActionQuestionResult? : PlanOfActionQuestionResultEntity[];
}

export interface PlanOfActionSectionTypeEntity extends DwOrmData {
    // Attributes
    PlanOfActionSectionTypeId?: number;
    PlanOfActionSectionType?: string;
    HasAreaOfOperation?: boolean;
    HasAreaOfOperationTask?: boolean;
    ShowSkillsOnly?: boolean;
    AcsTestPortionId?: number;

    // Display Attributes
    AcsTestPortionIdDisplay?: string;

    // Relations
    AcsTestPortion? : AcsTestPortionEntity;
    PlanOfActionSection? : PlanOfActionSectionEntity[];
}

export interface PlanOfActionStatusEntity extends DwOrmData {
    // Attributes
    PlanOfActionStatusId?: number;
    PlanOfActionStatus?: string;
    PlanOfActionStatusTypeId?: number;

    // Display Attributes
    PlanOfActionStatusTypeIdDisplay?: string;

    // Relations
    PlanOfActionStatusType? : PlanOfActionStatusTypeEntity;
    PlanOfAction? : PlanOfActionEntity[];
}

export interface PlanOfActionStatusTypeEntity extends DwOrmData {
    // Attributes
    PlanOfActionStatusTypeId?: number;
    PlanOfActionStatusType?: string;


    // Relations
    PlanOfActionStatus? : PlanOfActionStatusEntity[];
}

export interface PostalCodeEntity extends DwOrmData {
    // Attributes
    PostalCodeId?: number;
    StateProvinceId?: number;
    PostalCode?: string;
    Plus4?: string;
    City?: string;
    County?: string;
    AreaCode?: string;
    Latitude?: number;
    Longitude?: number;
    GmtOffset?: number;
    ObservesDst?: boolean;
    IsMetropolitanArea?: boolean;
    CreatedDateTime?: string;
    CreatedBy?: number;
    ModifiedDateTime?: string;
    ModifiedBy?: number;
    TimeZoneId?: number;

    // Display Attributes
    StateProvinceIdDisplay?: string;
    TimeZoneIdDisplay?: string;

    // Relations
    StateProvince? : StateProvinceEntity;
    TimeZoneIdTimeZone? : TimeZoneEntity;
}

export interface QuestionBankEntity extends DwOrmData {
    // Attributes
    QuestionBankId?: number;
    OwnerUserId?: number;
    AcsTestId?: number;
    AcsCodeId?: number;
    QuestionHtml?: string;
    IsBuiltIn?: boolean;
    IsShared?: boolean;
    QuestionShortName?: string;

    // Display Attributes
    AcsTestIdDisplay?: string;
    AcsCodeIdDisplay?: string;

    // Relations
    AcsCode? : AcsCodeEntity;
    AcsTest? : AcsTestEntity;
}

export interface QuestionOutcomeEntity extends DwOrmData {
    // Attributes
    QuestionOutcomeId?: number;
    QuestionOutcome?: string;
    OutcomeOrder?: number;


    // Relations
    PlanOfActionQuestionResult? : PlanOfActionQuestionResultEntity[];
}

export interface RoAirportDistancesEntity extends DwOrmData {
    // Attributes
    AirportId?: number;
    AirportName?: string;
    airportcode?: string;
    ReferenceAirportName?: string;
    ReferenceAirportCode?: string;
    Distance?: number;
    ReferenceAirportId?: number;


    // Relations
}

export interface RoCheckrideAggregateUtilEntity extends DwOrmData {
    // Attributes
    CheckrideRequestId?: number;
    ScheduledDateTime?: string;
    ScheduledDateTimeYear?: number;
    ScheduledDateTimeMonth?: number;
    ScheduledDateTimeMonthDisplay?: string;
    CheckRideRequestStatusId?: number;
    CheckRideRequestStatus?: string;
    PilexosPaymentDateTimeYear?: number;
    PilexosPaymentDateTimeMonth?: number;
    PilexosPaymentTotal?: number;
    CheckridePaymentDateTimeYear?: number;
    CheckridePaymentDateTimeMonth?: number;
    CheckridePaymentTotal?: number;
    OutcomeIsRequested?: number;
    OutcomeIsBooked?: number;
    OutcomeIsComplete?: number;
    OutcomeIsCancelled?: number;
    RevenueIsRequested?: number;
    RevenueIsBooked?: number;
    RevenueIsComplete?: number;
    RevenueIsCancelled?: number;
    CheckRideTypeDisplay?: string;
    ScheduledDateTimeMonthYearDisplay?: string;

    // Display Attributes
    CheckrideRequestIdDisplay?: string;

    // Relations
    CheckrideRequestIdCheckRideRequest? : CheckRideRequestEntity;
}

export interface RoCheckrideApplicantFinalNameEntity extends DwOrmData {
    // Attributes
    CheckRideRequestId?: number;
    StudentApplicantNameManual?: string;
    StudentProfileName?: string;
    ApplicantFinalName?: string;
    CheckRideApplicantTypeId?: number;
    ApplicantFinalEmail?: string;
    ApplicantFinalPhone?: string;
    FlightSchoolFinalName?: string;

    // Display Attributes
    CheckRideRequestIdDisplay?: string;

    // Relations
    CheckRideRequest? : CheckRideRequestEntity;
}

export interface RoCheckRidePaymentAggregateEntity extends DwOrmData {
    // Attributes
    CheckrideRequestId?: number;
    PaymentDateTimeYear?: number;
    PaymentDateTimeMonth?: number;
    IsCheckridePayment?: number;
    Count?: number;
    PaymentTotal?: number;


    // Relations
}

export interface RoCheckrideRequestScheduleDateInfoEntity extends DwOrmData {
    // Attributes
    ScheduledDateTime?: string;
    ScheduledDateTimeYear?: number;
    ScheduledDateTimeMonth?: number;
    CheckrideRequestId?: number;
    ScheduledDateTimeMonthDisplay?: string;

    // Display Attributes
    CheckrideRequestIdDisplay?: string;

    // Relations
    CheckrideRequestIdCheckRideRequest? : CheckRideRequestEntity;
}

export interface DwRoMediaEntity extends DwOrmData {
    // Attributes
    ContainerName?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    FileName?: string;
    ImageHeight?: number;
    ImageWidth?: number;
    IsDeleted?: boolean;
    IsSecure?: boolean;
    MediaDetailKey?: string;
    MediaFolderId?: number;
    MediaGroupId?: number;
    MediaId?: number;
    MediaName?: string;
    MediaPath?: string;
    MediaSizeType?: string;
    MediaSizeTypeId?: number;
    MediaTypeId?: number;
    SizeInBytes?: number;
    Version?: number;

    // Display Attributes
    CreatedByDisplay?: string;
    MediaGroupIdDisplay?: string;
    MediaIdDisplay?: string;

    // Relations
    DwMediaGroup? : EnterpriseMetaData.DwMediaGroupEntity;
    UserPictureDwUser? : EnterpriseMetaData.DwUserEntity;
}

export interface RoPilotExaminerLocationCurrentEntity extends DwOrmData {
    // Attributes
    PilotExaminerLocationId?: number;
    PilotExaminerLocationStateId?: number;

    // Display Attributes
    PilotExaminerLocationIdDisplay?: string;
    PilotExaminerLocationStateIdDisplay?: string;

    // Relations
    PilotExaminerLocation? : PilotExaminerLocationEntity;
}

export interface RoPilotExaminerLocationDistancesEntity extends DwOrmData {
    // Attributes
    ReferenceAirportId?: number;
    ReferenceAirportName?: string;
    ReferenceAirportLatitude?: number;
    ReferenceAirportLongitude?: number;
    AirportId?: number;
    AirportName?: string;
    Latitude?: number;
    Longitude?: number;
    Distance?: number;
    PilotExaminerLocationId?: number;
    PilotExaminerId?: number;
    WithinTravelRadius?: boolean;
    TravelRadiusMile?: number;

    // Display Attributes
    ReferenceAirportIdDisplay?: string;
    AirportIdDisplay?: string;
    PilotExaminerLocationIdDisplay?: string;
    PilotExaminerIdDisplay?: string;

    // Relations
    Airport? : AirportEntity;
    PilotExaminer? : PilotExaminerEntity;
    PilotExaminerLocation? : PilotExaminerLocationEntity;
}

export interface RoPilotExaminerScenarioEntity extends DwOrmData {
    // Attributes
    PilotExaminerId?: number;
    userId?: number;
    PilotExaminerStatusId?: number;
    AviationAuthorityId?: number;
    CheckRideScenarioId?: number;
    CheckRideGroupId?: number;
    PreTimeMinutes?: number;
    PostTimeMinutes?: number;
    IsActive?: boolean;
    TimeRequiredHours?: number;
    CheckRideTypeId?: number;
    CheckRideGroup?: string;
    CheckRideType?: string;
    CheckRideScenarioTypeMapId?: number;

    // Display Attributes
    PilotExaminerIdDisplay?: string;
    userIdDisplay?: string;
    CheckRideScenarioIdDisplay?: string;

    // Relations
    CheckRideScenario? : CheckRideScenarioEntity;
    PilotExaminer? : PilotExaminerEntity;
    UserIdDwUser? : EnterpriseMetaData.DwUserEntity;
}

export interface ScheduleEntity extends DwOrmData {
    // Attributes
    ScheduleId?: number;
    ScheduleName?: string;
    DefaultStartTime?: string;
    DefaultEndTime?: string;
    WeekDaysOnly?: boolean;
    UseCustomDays?: boolean;
    ScheduleOwnerUserId?: number;
    IsBuiltInSchedule?: boolean;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;

    // Display Attributes
    ScheduleOwnerUserIdDisplay?: string;

    // Relations
    ScheduleOwnerDwUser? : EnterpriseMetaData.DwUserEntity;
    BookingPilotExaminerLocation? : PilotExaminerLocationEntity[];
    ScheduleDetail? : ScheduleDetailEntity[];
}

export interface ScheduleDayOfWeekEntity extends DwOrmData {
    // Attributes
    ScheduleDayOfWeekId?: number;
    DayOfWeek?: string;
    DayOfWeekOrder?: number;


    // Relations
    ScheduleDetail? : ScheduleDetailEntity[];
}

export interface ScheduleDetailEntity extends DwOrmData {
    // Attributes
    ScheduleDetailId?: number;
    ScheduleId?: number;
    ScheduleDayOfWeekId?: number;
    StartTime?: string;
    EndTime?: string;

    // Display Attributes
    ScheduleIdDisplay?: string;
    ScheduleDayOfWeekIdDisplay?: string;

    // Relations
    Schedule? : ScheduleEntity;
    ScheduleDayOfWeek? : ScheduleDayOfWeekEntity;
}

export interface StateProvinceEntity extends DwOrmData {
    // Attributes
    StateProvinceId?: number;
    CountryId?: number;
    StateProvinceName?: string;
    StateProvinceShortName?: string;
    StateCode?: string;
    CreatedDateTime?: string;
    CreatedBy?: number;
    ModifiedDateTime?: string;
    ModifiedBy?: number;

    // Display Attributes
    CountryIdDisplay?: string;

    // Relations
    Country? : CountryEntity;
    Address? : AddressEntity[];
    PostalCode? : PostalCodeEntity[];
}

export interface StudentApplicantEntity extends DwOrmData {
    // Attributes
    StudentApplicantId?: number;
    UserId?: number;
    ApplicationDescription?: string;
    AviationAuthorityId?: number;
    PilotCertificateNumber?: string;
    TravelRadiusMiles?: number;
    Citizenship?: string;
    ApplicantAddressId?: number;
    MainAirportId?: number;
    MainAircraft?: string;
    ReferredPilotExaminerId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    AviationTrainingProgramId?: number;
    CitzenshipCountyId?: number;

    // Display Attributes
    UserIdDisplay?: string;
    AviationAuthorityIdDisplay?: string;
    ApplicantAddressIdDisplay?: string;
    MainAirportIdDisplay?: string;
    ReferredPilotExaminerIdDisplay?: string;
    AviationTrainingProgramIdDisplay?: string;
    CitzenshipCountyIdDisplay?: string;

    // Relations
    ApplicantAddress? : AddressEntity;
    MainAirport? : AirportEntity;
    AviationAuthority? : AviationAuthorityEntity;
    AviationTrainingProgram? : AviationTrainingProgramEntity;
    CitzenshipCountyIdCountry? : CountryEntity;
    CurrentPilotCertifiateTypeIdPilotCertificateType? : PilotCertificateTypeEntity;
    ReferredPilotExaminer? : PilotExaminerEntity;
    DwUser? : EnterpriseMetaData.DwUserEntity;
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    CheckRideRequest? : CheckRideRequestEntity[];
}

export interface TimeZoneEntity extends DwOrmData {
    // Attributes
    TimezoneId?: number;
    TimezoneName?: string;
    TimezoneShortName?: string;
    GmtOffSet?: number;
    TimeZoneDescription?: string;
    IANAIdentifier?: string;
    DotNetBCLIdentifier?: string;


    // Relations
    Address? : AddressEntity[];
    BookingScheduleTimeZoneIdPilotExaminerLocation? : PilotExaminerLocationEntity[];
    TimeZoneIdPostalCode? : PostalCodeEntity[];
}

export interface DwUserEntity extends DwOrmData {
    // Attributes
    AlternateName?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    Email?: string;
    FirstName?: string;
    ForcePasswordChange?: boolean;
    IsActive?: boolean;
    IsEmailVerified?: boolean;
    IsTwoFactorAuthenticationEnabled?: boolean;
    LastLoginDateTime?: string;
    LastName?: string;
    LastPasswordChangeDateTime?: string;
    MobilePhone?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    OrganizationId?: number;
    PartyReferenceId?: number;
    TwoFactorAuthenticationTypeId?: number;
    UserBackgroundMediaId?: number;
    UserId?: number;
    UserKey?: string;
    UserName?: string;
    UserPictureMediaId?: number;
    UserTypeId?: number;

    // Display Attributes
    AlternateNameDisplay?: string;
    CreatedByDisplay?: string;
    ModifiedByDisplay?: string;
    OrganizationIdDisplay?: string;
    PartyReferenceIdDisplay?: string;
    TwoFactorAuthenticationTypeIdDisplay?: string;
    UserBackgroundMediaIdDisplay?: string;
    UserPictureMediaIdDisplay?: string;
    UserTypeIdDisplay?: string;

    // Relations
    UserPictureDwroMedia? : EnterpriseMetaData.DwRoMediaEntity;
    DwUserType? : EnterpriseMetaData.DwUserTypeEntity;
    PaymentCustomerReference? : PaymentCustomerReferenceEntity[];
    LimitToPaymentDiscountCode? : PaymentDiscountCodeEntity[];
    PilotExaminer? : PilotExaminerEntity;
    UserIdRoPilotExaminerScenario? : RoPilotExaminerScenarioEntity[];
    ScheduleOwnerSchedule? : ScheduleEntity[];
    StudentApplicant? : StudentApplicantEntity;
}

export interface DwUserTypeEntity extends DwOrmData {
    // Attributes
    UserTypeId?: number;
    UserTypeDisplay?: string;
    UserTypeDescription?: string;
    UserTypeClasses?: string;
    ShowOnRegistration?: boolean;
    UserTypeOrder?: number;


    // Relations
    DwUser? : EnterpriseMetaData.DwUserEntity[];
}

// Generated Query Interfaces
/** Entity for Query Meaning Pilot_Examiner_Search  */
export interface PilotExaminerSearchQueryEntity extends DwOrmData {
    // Attributes
    PilotExaminerId?: number;
    UserId?: number;
    UserIdDisplay?: string;
    PilotExaminerStatusId?: number;
    PilotExaminerStatusIdDisplay?: string;
    Description?: string;
    AviationAuthorityId?: number;
    AviationAuthorityIdDisplay?: string;
    DefaultPreTimeMinutes?: number;
    DefaultPostTimeMinutes?: number;
    TravelRadiusMiles?: number;
    AuthorityExaminerNumber?: string;
    CalendarIsLinked?: boolean;
    CalendarReferenceId?: number;
    CalendarReferenceIdDisplay?: string;
    RequireAuthorityPreCheckRideNumber?: boolean;
    PublicProfileIsActive?: boolean;
    PublicProfileSlug?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    CheckRideRequestLeadTimeDays?: number;
    CheckRideRequestBookingMaxWeeksOut?: number;
    AviationAuthorityDistrictOfficeId?: number;
    AviationAuthorityDistrictOfficeIdDisplay?: string;
    DailyTravelRadiusLockMiles?: number;
    ScenarioCheckRideScenarioId?: number;
    ScenarioCheckRideScenarioIdDisplay?: string;
    ScenarioPreTimeMinutes?: number;
    ScenarioPostTimeMinutes?: number;
    ScenarioIsActive?: boolean;
    ScenarioTimeRequiredHours?: number;
    ScenarioCheckRideGroupId?: number;
    ScenarioCheckRideTypeId?: number;
    CheckRideScenarioCheckRideCost?: number;
    CheckRideScenarioRetestCost?: number;
    CheckRideScenarioDiscontinuedRetestCost?: number;
    CheckRideScenarioBookingCost?: number;
    CheckRideScenarioDiscontinuedRetestFlightOnlyCost?: number;
    CheckRideScenarioRetestFlightOnlyCost?: number;
    CheckRideScenarioTravelCost?: number;
    CheckRideScenarioTimeRequiredMinutes?: number;
    CheckRideScenarioTimeRequiredMinutesDisplay?: string;
    CheckRideScenarioRequiredDocumentNotes?: string;
    CheckRideScenarioRetestTimeRequiredMinutes?: number;
    CheckRideScenarioRetestTimeRequiredMinutesDisplay?: string;
    CheckRideScenarioRetestFlightOnlyTimeRequiredMinutes?: number;
    CheckRideScenarioRetestFlightOnlyTimeRequiredMinutesDisplay?: string;
    CheckRideScenarioDiscontinuedTimeRequiredMinutes?: number;
    CheckRideScenarioDiscontinuedTimeRequiredMinutesDisplay?: string;
    CheckRideScenarioDiscontinuedFlightOnlyTimeRequiredMinutes?: number;
    CheckRideScenarioDiscontinuedFlightOnlyTimeRequiredMinutesDisplay?: string;
    LocationDistancesPilotExaminerLocationId?: number;
    LocationDistancesPilotExaminerLocationIdDisplay?: string;
    LocationDistancesAirportId?: number;
    LocationDistancesAirportIdDisplay?: string;
    LocationDistancesDistance?: number;
    LocationDistancesReferenceAirportId?: number;
    LocationDistancesReferenceAirportIdDisplay?: string;
    LocationDistancesWithinTravelRadius?: boolean;
    PilotExaminerLocationSchedulingStartDate?: string;
    PilotExaminerLocationSchedulingEndDate?: string;
    PilotExaminerLocationTravelRadiusMile?: number;
    PilotExaminerLocationBookingScheduleId?: number;
    PilotExaminerLocationBookingScheduleIdDisplay?: string;
    PilotExaminerLocationIsActive?: boolean;
    PilotExaminerLocationTravelCost?: number;
    PilotExaminerLocationAdditionalLocationCost?: number;
    PilotExaminerLocationBookingScheduleTimeZoneId?: number;
    PilotExaminerLocationBookingScheduleTimeZoneIdDisplay?: string;
    PilotExaminerLocationPilotExaminerLocationId?: number;
    PilotExaminerLocationLocationName?: string;
    PilotExaminerLocationPilotExaminerLocationTypeId?: number;
    PilotExaminerLocationPilotExaminerLocationTypeIdDisplay?: string;
    RoPilotExaminerLocationCurrentPilotExaminerLocationStateId?: number;
    RoPilotExaminerLocationCurrentPilotExaminerLocationStateIdDisplay?: string;
    AirportAddressTimezoneId?: number;
    AirportAddressTimezoneIdDisplay?: string;
    AirportAddressObservesDst?: boolean;
    DwUserFirstName?: string;
    DwUserLastName?: string;
    DwUserUserPictureMediaId?: number;
    DwUserUserPictureMediaIdDisplay?: string;
    DwUserUserBackgroundMediaId?: number;
    DwUserUserBackgroundMediaIdDisplay?: string;
    UserPictureDwroMediaMediaPath?: string;
    UserPictureDwroMediaImageWidth?: number;
    UserPictureDwroMediaImageHeight?: number;

    // Relations
    AviationAuthority? : AviationAuthorityEntity;
    AviationAuthorityDistrictOffice? : AviationAuthorityDistrictOfficeEntity;
    CalendarReference? : CalendarReferenceEntity;
    PilotExaminerStatus? : PilotExaminerStatusEntity;
    DwUser? : EnterpriseMetaData.DwUserEntity;
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    ScheduledCheckRideRequest? : CheckRideRequestEntity[];
    CheckRideScenario? : CheckRideScenarioEntity[];
    PilotExaminerLocation? : PilotExaminerLocationEntity[];
    PlanOfAction? : PlanOfActionEntity[];
    RoPilotExaminerLocationDistances? : RoPilotExaminerLocationDistancesEntity[];
    RoPilotExaminerScenario? : RoPilotExaminerScenarioEntity[];
    ReferredStudentApplicant? : StudentApplicantEntity[];
}

/** Entity for Query Meaning Pilot_Examiner_Calendar_Reference  */
export interface PilotExaminerCalendarReferenceQueryEntity extends DwOrmData {
    // Attributes
    PilotExaminerId?: number;
    UserId?: number;
    PilotExaminerStatusId?: number;
    CalendarReferenceCalendarReferenceId?: number;
    CalendarReferenceCalendarReferenceTypeId?: number;
    CalendarReferenceMainCalendarKey?: string;
    CalendarReferenceCalendarConfigJSON?: string;
    DwUserUserId?: number;
    DwUserUserName?: string;
    DwUserUserTypeId?: number;
    DwUserEmail?: string;
    DwUserFirstName?: string;
    DwUserLastName?: string;
    DwUserLastLoginDateTime?: string;

    // Relations
    AviationAuthority? : AviationAuthorityEntity;
    AviationAuthorityDistrictOffice? : AviationAuthorityDistrictOfficeEntity;
    CalendarReference? : CalendarReferenceEntity;
    PilotExaminerStatus? : PilotExaminerStatusEntity;
    DwUser? : EnterpriseMetaData.DwUserEntity;
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    ScheduledCheckRideRequest? : CheckRideRequestEntity[];
    CheckRideScenario? : CheckRideScenarioEntity[];
    PilotExaminerLocation? : PilotExaminerLocationEntity[];
    PlanOfAction? : PlanOfActionEntity[];
    RoPilotExaminerLocationDistances? : RoPilotExaminerLocationDistancesEntity[];
    RoPilotExaminerScenario? : RoPilotExaminerScenarioEntity[];
    ReferredStudentApplicant? : StudentApplicantEntity[];
}

/** Entity for Query Meaning Check_Ride_Request-Calendar  */
export interface CheckRideRequestCalendarQueryEntity extends DwOrmData {
    // Attributes
    CheckRideRequestId?: number;
    CheckRideTypeId?: number;
    CheckRideTypeIdDisplay?: string;
    CheckRideGroupId?: number;
    CheckRideGroupIdDisplay?: string;
    CheckRideTestTypeId?: number;
    CheckRideTestTypeIdDisplay?: string;
    PilotCertificateNumber?: string;
    CheckRideRequestSubStatusId?: number;
    CheckRideRequestSubStatusIdDisplay?: string;
    ScheduledDateTime?: string;
    ScheduledTimeMinutes?: number;
    ScheduledTimeMinutesDisplay?: string;
    ProposedDateTime?: string;
    ScheduledAirportId?: number;
    ScheduledAirportIdDisplay?: string;
    AuthorityPreapprovalCode?: string;
    AuthorityApprovalCode?: string;
    ProposedAirportId?: number;
    ProposedAirportIdDisplay?: string;
    ProposedTimeMinutes?: number;
    ProposedTimeMinutesDisplay?: string;
    AuthorityPreCheckRideNumber?: string;
    CheckRideRequestSubStatusCheckRideRequestStatusId?: number;
    CheckRideRequestSubStatusCheckRideRequestStatusIdDisplay?: string;
    CheckRideResultCheckRideRequestId?: number;
    CheckRideResultCheckRideDateTime?: string;
    CheckRideResultCheckRideOutcomeId?: number;
    CheckRideResultCheckRideOutcomeIdDisplay?: string;
    CheckRideResultAllowRetest?: boolean;
    CheckRideResultAllowRetestReason?: string;
    CheckRideResultRetestCost?: number;
    AirportAirportName?: string;
    AirportAirportCode?: string;
    AirportAddressCity?: string;
    AirportAddressStateProvinceId?: number;
    AirportAddressStateProvinceIdDisplay?: string;
    AirportAddressPostalCode?: string;
    AirportAddressCountryId?: number;
    AirportAddressCountryIdDisplay?: string;
    AirportAddressLatitude?: number;
    AirportAddressLongitude?: number;
    AirportAddressTimezoneId?: number;
    AirportAddressTimezoneIdDisplay?: string;
    AirportAddressObservesDst?: boolean;
    ScheduledPilotExaminerUserId?: number;
    ScheduledPilotExaminerUserIdDisplay?: string;
    CalendarEventReferenceKey?: string;
    ApplicantNameApplicantFinalEmail?: string;
    ApplicantNameApplicantFinalName?: string;
    ApplicantNameApplicantFinalPhone?: string;
    ApplicantNameFlightSchoolFinalName?: string;
    ApplicantNameCheckRideApplicantTypeId?: number;

    // Relations
    ScheduledAirport? : AirportEntity;
    PreferredAirport? : AirportEntity;
    ProposedAirport? : AirportEntity;
    ApplicantPlaceholder? : ApplicantPlaceholderEntity;
    AviationAuthority? : AviationAuthorityEntity;
    AvaiationTrainingProgramIdAviationTrainingProgram? : AviationTrainingProgramEntity;
    ScheduledCalendarEventReference? : CalendarEventReferenceEntity;
    CheckRideApplicantType? : CheckRideApplicantTypeEntity;
    CheckRideCancellationType? : CheckRideCancellationTypeEntity;
    CheckRideGroup? : CheckRideGroupEntity;
    PilexosBookingCheckRidePayment? : CheckRidePaymentEntity;
    PilexosBookingCostPaymentPartyIdCheckRideRequestPaymentParty? : CheckRideRequestPaymentPartyEntity;
    CheckRideRequestSubStatus? : CheckRideRequestSubStatusEntity;
    ScheduledCheckRideScenario? : CheckRideScenarioEntity;
    CheckRideTestType? : CheckRideTestTypeEntity;
    CheckRideType? : CheckRideTypeEntity;
    StudentApplicantCitizenshipCountyIdCountry? : CountryEntity;
    FlightSchool? : FlightSchoolEntity;
    DocumentsDwMediaGroup? : EnterpriseMetaData.DwMediaGroupEntity;
    CurrentPilotCertifiateTypeIdPilotCertificateType? : PilotCertificateTypeEntity;
    ScheduledPilotExaminer? : PilotExaminerEntity;
    ScheduledPilotExaminerLocation? : PilotExaminerLocationEntity;
    StudentApplicant? : StudentApplicantEntity;
    CheckRidePayment? : CheckRidePaymentEntity[];
    CheckRideResult? : CheckRideResultEntity;
    PlanOfAction? : PlanOfActionEntity[];
    CheckrideRequestIdRoCheckrideAggregateUtil? : RoCheckrideAggregateUtilEntity;
    RoCheckrideApplicantFinalName? : RoCheckrideApplicantFinalNameEntity;
    CheckrideRequestIdRoCheckrideRequestScheduleDateInfo? : RoCheckrideRequestScheduleDateInfoEntity;
}

/** Entity for Query Meaning Pilot_Examiner_Current_Airports  */
export interface PilotExaminerCurrentAirportsQueryEntity extends DwOrmData {
    // Attributes
    AirportId?: number;
    AviationAuthorityId?: number;
    AviationAuthorityIdDisplay?: string;
    AirportName?: string;
    AirportCode?: string;
    AirportAddressId?: number;
    AirportAddressIdDisplay?: string;
    Elevation?: number;
    OwnershipCode?: string;
    IsActive?: boolean;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    PilotExaminerLocationPilotExaminerId?: number;
    PilotExaminerLocationPilotExaminerIdDisplay?: string;
    PilotExaminerLocationShowMetar?: boolean;
    RoPilotExaminerLocationCurrentPilotExaminerLocationStateId?: number;
    RoPilotExaminerLocationCurrentPilotExaminerLocationStateIdDisplay?: string;

    // Relations
    AirportAddress? : AddressEntity;
    AviationAuthority? : AviationAuthorityEntity;
    PreferredApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    ScheduledCheckRideRequest? : CheckRideRequestEntity[];
    PreferredCheckRideRequest? : CheckRideRequestEntity[];
    ProposedCheckRideRequest? : CheckRideRequestEntity[];
    PilotExaminerLocationAirport? : PilotExaminerLocationAirportEntity[];
    RoPilotExaminerLocationDistances? : RoPilotExaminerLocationDistancesEntity[];
    MainStudentApplicant? : StudentApplicantEntity[];
}

/** Entity for Query Meaning Pilot_Examiner_Public_Profile  */
export interface PilotExaminerPublicProfileQueryEntity extends DwOrmData {
    // Attributes
    PilotExaminerId?: number;
    UserId?: number;
    Description?: string;
    AviationAuthorityId?: number;
    AuthorityExaminerNumber?: string;
    PublicProfileIsActive?: boolean;
    PublicProfileSlug?: string;
    DwUserUserBackgroundMediaId?: number;
    DwUserUserPictureMediaId?: number;

    // Relations
    AviationAuthority? : AviationAuthorityEntity;
    AviationAuthorityDistrictOffice? : AviationAuthorityDistrictOfficeEntity;
    CalendarReference? : CalendarReferenceEntity;
    PilotExaminerStatus? : PilotExaminerStatusEntity;
    DwUser? : EnterpriseMetaData.DwUserEntity;
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    ScheduledCheckRideRequest? : CheckRideRequestEntity[];
    CheckRideScenario? : CheckRideScenarioEntity[];
    PilotExaminerLocation? : PilotExaminerLocationEntity[];
    PlanOfAction? : PlanOfActionEntity[];
    RoPilotExaminerLocationDistances? : RoPilotExaminerLocationDistancesEntity[];
    RoPilotExaminerScenario? : RoPilotExaminerScenarioEntity[];
    ReferredStudentApplicant? : StudentApplicantEntity[];
}

/** Entity for Query Meaning Student_Applicant_with_detail  */
export interface StudentApplicantWithDetailQueryEntity extends DwOrmData {
    // Attributes
    StudentApplicantId?: number;
    UserId?: number;
    UserIdDisplay?: string;
    ApplicationDescription?: string;
    AviationAuthorityId?: number;
    AviationAuthorityIdDisplay?: string;
    PilotCertificateNumber?: string;
    TravelRadiusMiles?: number;
    Citizenship?: string;
    ApplicantAddressId?: number;
    ApplicantAddressIdDisplay?: string;
    MainAirportId?: number;
    MainAirportIdDisplay?: string;
    MainAircraft?: string;
    ReferredPilotExaminerId?: number;
    ReferredPilotExaminerIdDisplay?: string;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    AviationTrainingProgramId?: number;
    AviationTrainingProgramIdDisplay?: string;
    CitzenshipCountyId?: number;
    CitzenshipCountyIdDisplay?: string;
    DwUserFirstName?: string;
    DwUserLastName?: string;
    DwUserEmail?: string;
    DwUserUserBackgroundMediaId?: number;
    DwUserUserBackgroundMediaIdDisplay?: string;
    DwUserUserPictureMediaId?: number;
    DwUserUserPictureMediaIdDisplay?: string;
    DwUserMobilePhone?: string;
    ApplicantAddressStreetAddress1?: string;
    ApplicantAddressStreetAddress2?: string;
    ApplicantAddressCity?: string;
    ApplicantAddressStateProvinceId?: number;
    ApplicantAddressStateProvinceIdDisplay?: string;
    ApplicantAddressCountryId?: number;
    ApplicantAddressCountryIdDisplay?: string;
    ApplicantAddressPostalCode?: string;
    ApplicantAddressLatitude?: number;
    ApplicantAddressLongitude?: number;

    // Relations
    ApplicantAddress? : AddressEntity;
    MainAirport? : AirportEntity;
    AviationAuthority? : AviationAuthorityEntity;
    AviationTrainingProgram? : AviationTrainingProgramEntity;
    CitzenshipCountyIdCountry? : CountryEntity;
    CurrentPilotCertifiateTypeIdPilotCertificateType? : PilotCertificateTypeEntity;
    ReferredPilotExaminer? : PilotExaminerEntity;
    DwUser? : EnterpriseMetaData.DwUserEntity;
    ApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    CheckRideRequest? : CheckRideRequestEntity[];
}

/** Entity for Query Meaning Airport_with_Address_info  */
export interface AirportWithAddressInfoQueryEntity extends DwOrmData {
    // Attributes
    AirportId?: number;
    AviationAuthorityId?: number;
    AviationAuthorityIdDisplay?: string;
    AirportName?: string;
    AirportCode?: string;
    AirportAddressId?: number;
    AirportAddressIdDisplay?: string;
    Elevation?: number;
    OwnershipCode?: string;
    IsActive?: boolean;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    AirportAddressCity?: string;
    AirportAddressStateProvinceId?: number;
    AirportAddressStateProvinceIdDisplay?: string;
    AirportAddressPostalCode?: string;
    AirportAddressCountyName?: string;
    AirportAddressTimezoneId?: number;
    AirportAddressTimezoneIdDisplay?: string;
    AirportAddressObservesDst?: boolean;
    AirportAddressLatitude?: number;
    AirportAddressLongitude?: number;

    // Relations
    AirportAddress? : AddressEntity;
    AviationAuthority? : AviationAuthorityEntity;
    PreferredApplicantPlaceholder? : ApplicantPlaceholderEntity[];
    ScheduledCheckRideRequest? : CheckRideRequestEntity[];
    PreferredCheckRideRequest? : CheckRideRequestEntity[];
    ProposedCheckRideRequest? : CheckRideRequestEntity[];
    PilotExaminerLocationAirport? : PilotExaminerLocationAirportEntity[];
    RoPilotExaminerLocationDistances? : RoPilotExaminerLocationDistancesEntity[];
    MainStudentApplicant? : StudentApplicantEntity[];
}

/** Entity for Query Meaning Checkride_Request-Aggregate_Base  */
export interface CheckrideRequestAggregateBaseQueryEntity extends DwOrmData {
    // Attributes
    CheckRideRequestId?: number;
    CheckRideGroupId?: number;
    CheckRideGroupIdDisplay?: string;
    CheckRideTypeId?: number;
    CheckRideTypeIdDisplay?: string;
    CheckRideTestTypeId?: number;
    CheckRideTestTypeIdDisplay?: string;
    CheckRideRequestSubStatusId?: number;
    CheckRideRequestSubStatusIdDisplay?: string;
    CheckRideApplicantTypeId?: number;
    CheckRideApplicantTypeIdDisplay?: string;
    AvaiationTrainingProgramId?: number;
    AvaiationTrainingProgramIdDisplay?: string;
    ScheduledDateTime?: string;
    ScheduledAirportId?: number;
    ScheduledAirportIdDisplay?: string;
    ScheduledCheckRideCost?: number;
    PilexosBookingCost?: number;
    ExaminerBookingCost?: number;
    AdditionalTravelCost?: number;
    ScheduledPilotExaminerId?: number;
    ScheduledPilotExaminerIdDisplay?: string;
    CheckRideCancellationTypeId?: number;
    CheckRideCancellationTypeIdDisplay?: string;
    CheckRideCancellationTypeOther?: string;
    PilexosBookingCostPaymentPartyId?: number;
    PilexosBookingCostPaymentPartyIdDisplay?: string;
    StudentApplicantCitizenshipCountyId?: number;
    StudentApplicantCitizenshipCountyIdDisplay?: string;
    FlightSchoolId?: number;
    FlightSchoolIdDisplay?: string;
    FlightSchoolNameOther?: string;
    AggrScheduledDateTime?: string;
    AggrScheduledDateTimeYear?: number;
    AggrScheduledDateTimeMonth?: number;
    AggrScheduledDateTimeMonthDisplay?: string;
    AggrScheduledDateTimeMonthYearDisplay?: string;
    AggrCheckRideRequestStatusId?: number;
    AggrCheckRideRequestStatus?: string;
    AggrPilexosPaymentDateTimeYear?: number;
    AggrPilexosPaymentDateTimeMonth?: number;
    AggrPilexosPaymentTotal?: number;
    AggrCheckridePaymentDateTimeYear?: number;
    AggrCheckridePaymentDateTimeMonth?: number;
    AggrCheckridePaymentTotal?: number;
    AggrOutcomeIsRequested?: number;
    AggrOutcomeIsBooked?: number;
    AggrOutcomeIsComplete?: number;
    AggrOutcomeIsCancelled?: number;
    AggrRevenueIsRequested?: number;
    AggrRevenueIsBooked?: number;
    AggrRevenueIsComplete?: number;
    AggrRevenueIsCancelled?: number;
    AggrCheckRideTypeDisplay?: string;
    CheckRideResultCheckRideOutcomeId?: number;
    CheckRideResultCheckRideOutcomeIdDisplay?: string;
    AirportAirportCode?: string;
    RoCheckrideApplicantInfoFlightSchoolFinalName?: string;

    // Relations
    ScheduledAirport? : AirportEntity;
    PreferredAirport? : AirportEntity;
    ProposedAirport? : AirportEntity;
    ApplicantPlaceholder? : ApplicantPlaceholderEntity;
    AviationAuthority? : AviationAuthorityEntity;
    AvaiationTrainingProgramIdAviationTrainingProgram? : AviationTrainingProgramEntity;
    ScheduledCalendarEventReference? : CalendarEventReferenceEntity;
    CheckRideApplicantType? : CheckRideApplicantTypeEntity;
    CheckRideCancellationType? : CheckRideCancellationTypeEntity;
    CheckRideGroup? : CheckRideGroupEntity;
    PilexosBookingCheckRidePayment? : CheckRidePaymentEntity;
    PilexosBookingCostPaymentPartyIdCheckRideRequestPaymentParty? : CheckRideRequestPaymentPartyEntity;
    CheckRideRequestSubStatus? : CheckRideRequestSubStatusEntity;
    ScheduledCheckRideScenario? : CheckRideScenarioEntity;
    CheckRideTestType? : CheckRideTestTypeEntity;
    CheckRideType? : CheckRideTypeEntity;
    StudentApplicantCitizenshipCountyIdCountry? : CountryEntity;
    FlightSchool? : FlightSchoolEntity;
    DocumentsDwMediaGroup? : EnterpriseMetaData.DwMediaGroupEntity;
    CurrentPilotCertifiateTypeIdPilotCertificateType? : PilotCertificateTypeEntity;
    ScheduledPilotExaminer? : PilotExaminerEntity;
    ScheduledPilotExaminerLocation? : PilotExaminerLocationEntity;
    StudentApplicant? : StudentApplicantEntity;
    CheckRidePayment? : CheckRidePaymentEntity[];
    CheckRideResult? : CheckRideResultEntity;
    PlanOfAction? : PlanOfActionEntity[];
    CheckrideRequestIdRoCheckrideAggregateUtil? : RoCheckrideAggregateUtilEntity;
    RoCheckrideApplicantFinalName? : RoCheckrideApplicantFinalNameEntity;
    CheckrideRequestIdRoCheckrideRequestScheduleDateInfo? : RoCheckrideRequestScheduleDateInfoEntity;
}

/** Entity for Query Meaning Checkride-Active_Applicant_Checkrides  */
export interface CheckrideActiveApplicantCheckridesQueryEntity extends DwOrmData {
    // Attributes
    CheckRideRequestId?: number;
    StudentApplicantId?: number;
    StudentApplicantIdDisplay?: string;
    AviationAuthorityId?: number;
    AviationAuthorityIdDisplay?: string;
    CheckRideRequestSubStatusId?: number;
    CheckRideRequestSubStatusIdDisplay?: string;
    ScheduledDateTime?: string;
    CheckRideTypeId?: number;
    CheckRideTypeIdDisplay?: string;
    CheckRideGroupId?: number;
    CheckRideGroupIdDisplay?: string;
    StudentApplicantStudentApplicantId?: number;
    StudentApplicantUserId?: number;
    StudentApplicantUserIdDisplay?: string;

    // Relations
    ScheduledAirport? : AirportEntity;
    PreferredAirport? : AirportEntity;
    ProposedAirport? : AirportEntity;
    ApplicantPlaceholder? : ApplicantPlaceholderEntity;
    AviationAuthority? : AviationAuthorityEntity;
    AvaiationTrainingProgramIdAviationTrainingProgram? : AviationTrainingProgramEntity;
    ScheduledCalendarEventReference? : CalendarEventReferenceEntity;
    CheckRideApplicantType? : CheckRideApplicantTypeEntity;
    CheckRideCancellationType? : CheckRideCancellationTypeEntity;
    CheckRideGroup? : CheckRideGroupEntity;
    PilexosBookingCheckRidePayment? : CheckRidePaymentEntity;
    PilexosBookingCostPaymentPartyIdCheckRideRequestPaymentParty? : CheckRideRequestPaymentPartyEntity;
    CheckRideRequestSubStatus? : CheckRideRequestSubStatusEntity;
    ScheduledCheckRideScenario? : CheckRideScenarioEntity;
    CheckRideTestType? : CheckRideTestTypeEntity;
    CheckRideType? : CheckRideTypeEntity;
    StudentApplicantCitizenshipCountyIdCountry? : CountryEntity;
    FlightSchool? : FlightSchoolEntity;
    DocumentsDwMediaGroup? : EnterpriseMetaData.DwMediaGroupEntity;
    CurrentPilotCertifiateTypeIdPilotCertificateType? : PilotCertificateTypeEntity;
    ScheduledPilotExaminer? : PilotExaminerEntity;
    ScheduledPilotExaminerLocation? : PilotExaminerLocationEntity;
    StudentApplicant? : StudentApplicantEntity;
    CheckRidePayment? : CheckRidePaymentEntity[];
    CheckRideResult? : CheckRideResultEntity;
    PlanOfAction? : PlanOfActionEntity[];
    CheckrideRequestIdRoCheckrideAggregateUtil? : RoCheckrideAggregateUtilEntity;
    RoCheckrideApplicantFinalName? : RoCheckrideApplicantFinalNameEntity;
    CheckrideRequestIdRoCheckrideRequestScheduleDateInfo? : RoCheckrideRequestScheduleDateInfoEntity;
}

/** Entity for Query Meaning Acs_Codes-Lookup  */
export interface AcsCodesLookupQueryEntity extends DwOrmData {
    // Attributes
    AcsCodeId?: number;
    AscCodeTypeId?: number;
    AscCodeTypeIdDisplay?: string;
    AcsCode?: string;
    AcsCodeOrder?: number;
    AcsAreaOfOperationTaskId?: number;
    AcsAreaOfOperationTaskIdDisplay?: string;
    AscCodeDescription?: string;
    TaskTaskName?: string;
    TaskTaskOrder?: number;
    TaskTaskCode?: string;
    TaskAcsAreaOfOperationTaskId?: number;
    AcsAreaOfOperationAcsAreaOfOperationId?: number;
    AcsAreaOfOperationAreaOfOperationName?: string;
    AcsAreaOfOperationAreaOfOperationOrder?: number;
    AcsAreaOfOperationAreaOfOperationCode?: string;
    AcsTestTestName?: string;
    AcsTestTestDescription?: string;
    AcsTestCheckRideGroupId?: number;
    AcsTestCheckRideGroupIdDisplay?: string;
    AcsTestTestCode?: string;
    AcsTestAcsTestId?: number;
    AcsTestAcsTestStatusId?: number;
    AcsTestAcsTestStatusIdDisplay?: string;

    // Relations
    AcsAreaOfOperationTask? : AcsAreaOfOperationTaskEntity;
    AscCodeTypeIdAcsCodeType? : AcsCodeTypeEntity;
    PlanOfActionRequiredAcsCode? : PlanOfActionRequiredAcsCodeEntity[];
    PlanOfActionSectionQuestion? : PlanOfActionSectionQuestionEntity[];
    QuestionBank? : QuestionBankEntity[];
}

/** Entity for Query Meaning Acs_Codes-Grid  */
export interface AcsCodesGridQueryEntity extends DwOrmData {
    // Attributes
    AcsCodeId?: number;
    AscCodeTypeId?: number;
    AscCodeTypeIdDisplay?: string;
    AcsCode?: string;
    AcsCodeOrder?: number;
    AcsAreaOfOperationTaskId?: number;
    AcsAreaOfOperationTaskIdDisplay?: string;
    AscCodeDescription?: string;
    TaskTaskName?: string;
    TaskTaskOrder?: number;
    TaskTaskCode?: string;
    TaskAcsAreaOfOperationId?: number;
    TaskAcsAreaOfOperationIdDisplay?: string;
    AcsAreaOfOperationAreaOfOperationName?: string;
    AcsAreaOfOperationAreaOfOperationOrder?: number;
    AcsAreaOfOperationAreaOfOperationCode?: string;
    AcsAreaOfOperationAcsTestId?: number;
    AcsAreaOfOperationAcsTestIdDisplay?: string;
    AcsTestTestName?: string;
    AcsTestTestDescription?: string;
    AcsTestCheckRideGroupId?: number;
    AcsTestCheckRideGroupIdDisplay?: string;
    AcsTestTestCode?: string;

    // Relations
    AcsAreaOfOperationTask? : AcsAreaOfOperationTaskEntity;
    AscCodeTypeIdAcsCodeType? : AcsCodeTypeEntity;
    PlanOfActionRequiredAcsCode? : PlanOfActionRequiredAcsCodeEntity[];
    PlanOfActionSectionQuestion? : PlanOfActionSectionQuestionEntity[];
    QuestionBank? : QuestionBankEntity[];
}

/** Entity for Query Meaning Acs_Codes_With_Questions  */
export interface AcsCodesWithQuestionsQueryEntity extends DwOrmData {
    // Attributes
    AcsCodeId?: number;
    AscCodeTypeId?: number;
    AscCodeTypeIdDisplay?: string;
    AcsCode?: string;
    AcsCodeOrder?: number;
    AcsAreaOfOperationTaskId?: number;
    AcsAreaOfOperationTaskIdDisplay?: string;
    AscCodeDescription?: string;
    AcsAreaOfOperationTaskAcsAreaOfOperationTaskId?: number;
    AcsAreaOfOperationTaskAcsAreaOfOperationId?: number;
    AcsAreaOfOperationTaskAcsAreaOfOperationIdDisplay?: string;
    AcsAreaOfOperationTaskTaskName?: string;
    AcsAreaOfOperationTaskTaskOrder?: number;
    AcsAreaOfOperationTaskTaskCode?: string;
    AcsAreaOfOperationAcsTestId?: number;
    AcsAreaOfOperationAcsTestIdDisplay?: string;
    AcsAreaOfOperationAreaOfOperationName?: string;
    AcsAreaOfOperationAreaOfOperationOrder?: number;
    AcsAreaOfOperationAreaOfOperationCode?: string;
    AcsAreaOfOperationAcsAreaOfOperationId?: number;
    QuestionBankQuestionBankId?: number;
    QuestionBankIsBuiltIn?: boolean;
    QuestionBankIsShared?: boolean;
    QuestionBankOwnerUserId?: number;
    QuestionBankAcsTestId?: number;
    QuestionBankAcsTestIdDisplay?: string;
    QuestionBankAcsCodeId?: number;
    QuestionBankAcsCodeIdDisplay?: string;
    QuestionBankQuestionHtml?: string;
    QuestionBankQuestionShortName?: string;

    // Relations
    AcsAreaOfOperationTask? : AcsAreaOfOperationTaskEntity;
    AscCodeTypeIdAcsCodeType? : AcsCodeTypeEntity;
    PlanOfActionRequiredAcsCode? : PlanOfActionRequiredAcsCodeEntity[];
    PlanOfActionSectionQuestion? : PlanOfActionSectionQuestionEntity[];
    QuestionBank? : QuestionBankEntity[];
}

    // Lookup Value Enums  {
export enum PilotExaminerStatusAllItems {
        Pending = 1,
        Submitted = 2,
        Rejected = 3,
        VerifiedLive = 4,
        Inactive = 1001,
    }

export enum CheckRideRequestStatusAllItems {
        Requested = 1,
        Booked = 2,
        Cancelled = 3,
        PostCheckride = 4,
        Complete = 5,
    }

export enum CheckRideRequestSubStatusAllItems {
        PendingApproval = 1,
        Cancelled = 3,
        NewDateProposedByExaminer = 4,
        Accepted = 5,
        RequiresAdditionalInfo = 6,
        AllDetailsComplete = 7,
        Rejected = 8,
        PendingOutcome = 9,
        Completed = 10,
        Deleted = 11,
        Draft = 12,
        PendingPayment = 14,
        AdditionalInformationNeeded = 15,
        AcceptedPendingPayment = 16,
    }

export enum CheckRideRequestSubStatusFullStatusDesc {
        RequestedPendingApproval = 1,
        CancelledCancelled = 3,
        RequestedNewDateProposedByExaminer = 4,
        BookedAccepted = 5,
        BookedRequiresAdditionalInfo = 6,
        BookedAllDetailsComplete = 7,
        RequestedRejected = 8,
        PostCheckridePendingOutcome = 9,
        CompleteCompleted = 10,
        CompleteDeleted = 11,
        RequestedDraft = 12,
        RequestedPendingPayment = 14,
        RequestedAdditionalInformationNeeded = 15,
        BookedAcceptedPendingPayment = 16,
    }

export enum CheckRideRequestSubStatusStudent {
        PendingApproval = 1,
        Cancelled = 3,
        NewDateProposedByExaminer = 4,
        Accepted = 5,
        RequiresAdditionalInfo = 6,
        AllDetailsComplete = 7,
        Rejected = 8,
        PendingOutcome = 9,
        Completed = 10,
        Deleted = 11,
        Draft = 12,
        PendingPayment = 14,
        AdditionalInformationNeeded = 15,
        AcceptedPendingPayment = 16,
    }

export enum PaymentReferenceStatusAllItems {
        Pending = 1,
        Charged = 2,
        Refunded = 3,
        Cancelled = 4,
    }

export enum CheckRideStatusLevelAllItems {
        AllGoodGreen = 1,
        WarningOrange = 2,
        DangerRed = 3,
    }

export enum CheckRideRequestSubStatusExaminerManualWF {
        PendingApproval = 1,
        Cancelled = 3,
        NewDateProposedByExaminer = 4,
        Accepted = 5,
        RequiresAdditionalInfo = 6,
        AllDetailsComplete = 7,
        Rejected = 8,
        PendingOutcome = 9,
        Completed = 10,
        Deleted = 11,
        Draft = 12,
        PendingPayment = 14,
        AdditionalInformationNeeded = 15,
        AcceptedPendingPayment = 16,
    }

export enum ApplicantPlaceholderStatusAllItems {
        Requested = 1,
        Booked = 2,
        Cancelled = 3,
        Complete = 4,
        Deleted = 5,
    }

export enum PlanOfActionStatusAllItems {
        Draft = 1,
        Active = 2,
        Complete = 3,
        Cancelled = 4,
        Deleted = 5,
        Inactive = 6,
    }

export enum AcsTestStatusAllItems {
        Active = 1,
        Expired = 2,
        Deleted = 3,
    }

export enum PlanOfActionStatusPlan {
        Draft = 1,
        Active = 2,
        Complete = 3,
        Cancelled = 4,
        Deleted = 5,
    }

export enum PlanOfActionStatusTypeAllItems {
        Shared = 1,
        PlanStatus = 2,
        TemplateStatus = 3,
    }

export enum PlanOfActionStatusTemplate {
        Active = 2,
        Deleted = 5,
        Inactive = 6,
    }

export enum MediaGroupAllItems {
        ItemAttr = 1,
        // Duplicate property Name found
        ItemAttr_2 = 2,
        // Duplicate property Name found
        ItemAttr_3 = 3,
        // Duplicate property Name found
        ItemAttr_4 = 4,
        // Duplicate property Name found
        ItemAttr_5 = 5,
        // Duplicate property Name found
        ItemAttr_6 = 6,
        // Duplicate property Name found
        ItemAttr_7 = 7,
        // Duplicate property Name found
        ItemAttr_8 = 8,
        // Duplicate property Name found
        ItemAttr_9 = 9,
        // Duplicate property Name found
        ItemAttr_10 = 10,
        // Duplicate property Name found
        ItemAttr_11 = 11,
        // Duplicate property Name found
        ItemAttr_12 = 12,
        // Duplicate property Name found
        ItemAttr_13 = 13,
        // Duplicate property Name found
        ItemAttr_14 = 14,
        // Duplicate property Name found
        ItemAttr_15 = 15,
        // Duplicate property Name found
        ItemAttr_16 = 16,
        // Duplicate property Name found
        ItemAttr_17 = 17,
        // Duplicate property Name found
        ItemAttr_18 = 18,
        // Duplicate property Name found
        ItemAttr_19 = 19,
        // Duplicate property Name found
        ItemAttr_20 = 20,
        // Duplicate property Name found
        ItemAttr_21 = 21,
        // Duplicate property Name found
        ItemAttr_22 = 22,
        // Duplicate property Name found
        ItemAttr_23 = 23,
        // Duplicate property Name found
        ItemAttr_24 = 24,
        // Duplicate property Name found
        ItemAttr_25 = 25,
        // Duplicate property Name found
        ItemAttr_26 = 26,
        // Duplicate property Name found
        ItemAttr_27 = 27,
        // Duplicate property Name found
        ItemAttr_28 = 28,
        // Duplicate property Name found
        ItemAttr_29 = 29,
        // Duplicate property Name found
        ItemAttr_30 = 30,
        // Duplicate property Name found
        ItemAttr_31 = 31,
        // Duplicate property Name found
        ItemAttr_32 = 32,
        // Duplicate property Name found
        ItemAttr_33 = 33,
        // Duplicate property Name found
        ItemAttr_34 = 34,
        // Duplicate property Name found
        ItemAttr_35 = 35,
        // Duplicate property Name found
        ItemAttr_36 = 36,
        // Duplicate property Name found
        ItemAttr_37 = 37,
        // Duplicate property Name found
        ItemAttr_38 = 38,
        // Duplicate property Name found
        ItemAttr_39 = 39,
        // Duplicate property Name found
        ItemAttr_40 = 40,
        // Duplicate property Name found
        ItemAttr_41 = 41,
        // Duplicate property Name found
        ItemAttr_42 = 42,
        // Duplicate property Name found
        ItemAttr_43 = 43,
        // Duplicate property Name found
        ItemAttr_44 = 44,
        // Duplicate property Name found
        ItemAttr_45 = 45,
        // Duplicate property Name found
        ItemAttr_46 = 46,
        // Duplicate property Name found
        ItemAttr_47 = 47,
        // Duplicate property Name found
        ItemAttr_48 = 48,
        // Duplicate property Name found
        ItemAttr_49 = 49,
        // Duplicate property Name found
        ItemAttr_50 = 50,
        // Duplicate property Name found
        ItemAttr_51 = 51,
        // Duplicate property Name found
        ItemAttr_52 = 52,
        // Duplicate property Name found
        ItemAttr_53 = 53,
        // Duplicate property Name found
        ItemAttr_54 = 54,
        // Duplicate property Name found
        ItemAttr_55 = 55,
        // Duplicate property Name found
        ItemAttr_56 = 56,
        // Duplicate property Name found
        ItemAttr_57 = 57,
        // Duplicate property Name found
        ItemAttr_58 = 58,
        // Duplicate property Name found
        ItemAttr_59 = 59,
        // Duplicate property Name found
        ItemAttr_60 = 60,
        // Duplicate property Name found
        ItemAttr_61 = 61,
        // Duplicate property Name found
        ItemAttr_62 = 62,
        // Duplicate property Name found
        ItemAttr_63 = 63,
        // Duplicate property Name found
        ItemAttr_64 = 64,
        // Duplicate property Name found
        ItemAttr_65 = 65,
        // Duplicate property Name found
        ItemAttr_66 = 66,
        // Duplicate property Name found
        ItemAttr_67 = 67,
        // Duplicate property Name found
        ItemAttr_68 = 68,
        // Duplicate property Name found
        ItemAttr_69 = 69,
        // Duplicate property Name found
        ItemAttr_70 = 70,
        // Duplicate property Name found
        ItemAttr_71 = 71,
        // Duplicate property Name found
        ItemAttr_72 = 72,
        // Duplicate property Name found
        ItemAttr_73 = 73,
        // Duplicate property Name found
        ItemAttr_74 = 74,
        // Duplicate property Name found
        ItemAttr_75 = 75,
        // Duplicate property Name found
        ItemAttr_76 = 76,
        // Duplicate property Name found
        ItemAttr_77 = 77,
        // Duplicate property Name found
        ItemAttr_78 = 78,
        // Duplicate property Name found
        ItemAttr_79 = 79,
        // Duplicate property Name found
        ItemAttr_80 = 80,
        // Duplicate property Name found
        ItemAttr_81 = 81,
        // Duplicate property Name found
        ItemAttr_82 = 82,
        // Duplicate property Name found
        ItemAttr_83 = 83,
        // Duplicate property Name found
        ItemAttr_84 = 84,
    }

export enum CheckRideGroupAllItems {
        Private = 1,
        Instrument = 2,
        Commercial = 3,
        CFI = 4,
        AirlineTransportPilot = 8,
        SportPilot = 9,
        AddOn = 10,
        Administrative = 11,
    }

export enum CheckRideTypeByGroup {
    }

export enum CheckRideTypeAllItems {
        AirplaneSingleEngineLand = 1,
        AirplaneSingleEngineSea = 2,
        AirplaneMultiEngineLand = 3,
        AirplaneMultiEngineSea = 4,
        LighterThanAirBalloon = 5,
        PoweredChuteLand = 6,
        GliderLand = 7,
        // Duplicate property Name found
        AirplaneSingleEngineLand_8 = 8,
        // Duplicate property Name found
        AirplaneSingleEngineSea_9 = 9,
        // Duplicate property Name found
        AirplaneMultiEngineLand_10 = 10,
        // Duplicate property Name found
        AirplaneMultiEngineSea_11 = 11,
        RotorcraftHelicopter = 12,
        RotorcraftGyroplane = 13,
        // Duplicate property Name found
        AirplaneSingleEngineLand_14 = 14,
        // Duplicate property Name found
        RotorcraftHelicopter_15 = 15,
        // Duplicate property Name found
        RotorcraftGyroplane_16 = 16,
        // Duplicate property Name found
        GliderLand_17 = 17,
        // Duplicate property Name found
        PoweredChuteLand_18 = 18,
        // Duplicate property Name found
        LighterThanAirBalloon_19 = 19,
        // Duplicate property Name found
        AirplaneSingleEngineLand_20 = 20,
        // Duplicate property Name found
        AirplaneMultiEngineLand_21 = 21,
        // Duplicate property Name found
        RotorcraftHelicopter_22 = 22,
        AirplaneSingleEngine = 25,
        LighterThanAirAirship = 26,
        AirplaneMultiEngine = 27,
        RotorcraftHelicopterSingleEngine = 28,
        AirplaneInstrumentInstructorAddOn = 29,
        AirplaneMultiEngineInstructorAddOn = 30,
        // Duplicate property Name found
        AirplaneSingleEngineSea_31 = 31,
        // Duplicate property Name found
        AirplaneSingleEngineLand_32 = 32,
        // Duplicate property Name found
        AirplaneSingleEngineSea_33 = 33,
        // Duplicate property Name found
        AirplaneMultiEngineLand_34 = 34,
        // Duplicate property Name found
        AirplaneMultiEngineSea_35 = 35,
        // Duplicate property Name found
        RotorcraftHelicopterSingleEngine_36 = 36,
        RotorcraftHelicopterMultiEngine = 37,
        // Duplicate property Name found
        RotorcraftHelicopterMultiEngine_38 = 38,
        PoweredChuteSea = 39,
        // Duplicate property Name found
        PoweredChuteSea_40 = 40,
        SportSingleEngineLand = 41,
        SportSingleEngineSea = 42,
        InstrumentSingleEngine = 43,
        CommercialSingleEngineLand = 44,
        SeaplaneSingleEngine = 45,
        SeaplaneMultiEngine = 46,
        Glider = 47,
        LighterThanAir = 48,
        RotorcraftSingleEngine = 49,
        RotorcraftMultiEngine = 50,
        CommercialMultiEngine = 51,
        TurbopropSingleEngine = 52,
        TurbopropMultiEngine = 53,
        TurbojetSingleEngine = 54,
        TurbojetMultiEngine = 55,
        ReciprocatingSingleEngine = 56,
        ReciprocatingMultiEngine = 57,
        ForeignPilotExaminerFPE = 58,
        MilitaryCompetencyExaminerMCE = 59,
        GroundInstructorExaminerGIE = 60,
        FlightInstructorRenewalExaminerFIRE = 61,
        RemotePilotExaminerRPE = 62,
        AirmanCert141ACR141 = 63,
        AirmanCertFlightInstructorRefresherCourseACRFIRC = 64,
        InstrumentMultiEngine = 1001,
    }

// Excluding lookup CheckRideScenarioTypeMap All Items because it contains 131 items which exceeds the max item setting of 100.  Override in cli config for code gen using ExcludeLookupsMaxCount
export enum PaymentTypeAllItems {
        PilExOSFee = 1,
        CheckridePayment = 2,
        CheckrideRefund = 3,
        ExaminerFee = 4,
    }

export enum CheckRideTestTypeAllItems {
        InitialTest = 1,
        Retest = 2,
        Continuance = 3,
        RetestFlightOnly = 4,
        ContinuanceFlightOnly = 5,
        Admin = 6,
    }

export enum UserTypeAllItems {
        PIlotExaminer = 1,
        FlightSchoolInstructor = 2,
        StudentApplicant = 3,
        PilexosAdmin = 4,
    }

export enum CalendarReferenceTypeAllItems {
        GoogleCalendar = 1,
    }

export enum CheckRideTypeWithGrade {
        CFIAirplaneSingleEngineLand = 1,
        CFIAirplaneSingleEngineSea = 2,
        CFIAirplaneMultiEngineLand = 3,
        CFIAirplaneMultiEngineSea = 4,
        CFILighterThanAirBalloon = 5,
        CFIPoweredChuteLand = 6,
        CFIGliderLand = 7,
        PrivateAirplaneSingleEngineLand = 8,
        PrivateAirplaneSingleEngineSea = 9,
        PrivateAirplaneMultiEngineLand = 10,
        PrivateAirplaneMultiEngineSea = 11,
        CFIRotorcraftHelicopter = 12,
        CFIRotorcraftGyroplane = 13,
        InstrumentAirplaneSingleEngineLand = 14,
        PrivateRotorcraftHelicopter = 15,
        PrivateRotorcraftGyroplane = 16,
        PrivateGliderLand = 17,
        SportPilotPoweredChuteLand = 18,
        PrivateLighterThanAirBalloon = 19,
        SportPilotAirplaneSingleEngineLand = 20,
        InstrumentAirplaneMultiEngineLand = 21,
        InstrumentRotorcraftHelicopter = 22,
        CommercialAirplaneSingleEngine = 25,
        CFILighterThanAirAirship = 26,
        CommercialAirplaneMultiEngine = 27,
        CommercialRotorcraftHelicopterSingleEngine = 28,
        CFIAirplaneInstrumentInstructorAddOn = 29,
        CFIAirplaneMultiEngineInstructorAddOn = 30,
        SportPilotAirplaneSingleEngineSea = 31,
        AirlineTransportPilotAirplaneSingleEngineLand = 32,
        AirlineTransportPilotAirplaneSingleEngineSea = 33,
        AirlineTransportPilotAirplaneMultiEngineLand = 34,
        AirlineTransportPilotAirplaneMultiEngineSea = 35,
        AirlineTransportPilotRotorcraftHelicopterSingleEngine = 36,
        AirlineTransportPilotRotorcraftHelicopterMultiEngine = 37,
        CommercialRotorcraftHelicopterMultiEngine = 38,
        CFIPoweredChuteSea = 39,
        SportPilotPoweredChuteSea = 40,
        CFISportSingleEngineLand = 41,
        CFISportSingleEngineSea = 42,
        AddOnInstrumentSingleEngine = 43,
        AddOnCommercialSingleEngineLand = 44,
        AddOnSeaplaneSingleEngine = 45,
        AddOnSeaplaneMultiEngine = 46,
        AddOnGlider = 47,
        AddOnLighterThanAir = 48,
        AddOnRotorcraftSingleEngine = 49,
        AddOnRotorcraftMultiEngine = 50,
        AddOnCommercialMultiEngine = 51,
        AddOnTurbopropSingleEngine = 52,
        AddOnTurbopropMultiEngine = 53,
        AddOnTurbojetSingleEngine = 54,
        AddOnTurbojetMultiEngine = 55,
        AddOnReciprocatingSingleEngine = 56,
        AddOnReciprocatingMultiEngine = 57,
        AdministrativeForeignPilotExaminerFPE = 58,
        AdministrativeMilitaryCompetencyExaminerMCE = 59,
        AdministrativeGroundInstructorExaminerGIE = 60,
        AdministrativeFlightInstructorRenewalExaminerFIRE = 61,
        AdministrativeRemotePilotExaminerRPE = 62,
        AdministrativeAirmanCert141ACR141 = 63,
        AdministrativeAirmanCertFlightInstructorRefresherCourseACRFIRC = 64,
        AddOnInstrumentMultiEngine = 1001,
    }

export enum PaymentReferenceCardTypeAllItems {
        Visa = 1,
        Matsercard = 2,
        AmericanExpress = 3,
        Discover = 4,
        Unknown = 5,
    }

export enum CheckRideApplicantTypeAllItems {
        StudentAccount = 1,
        ManualEntry = 2,
    }

export enum CheckRideRequestCancelTypeAllItems {
        ApplicantNoShow = 1,
        ApplicantNotReady = 2,
        Mechanical = 3,
        Weather = 4,
        Other = 5,
    }

export enum PilotCertificateTypeAllItems {
        Student = 1,
        Private = 2,
        Commercial = 3,
        ATP = 4,
    }

export enum AcsCodeTypeAllItems {
        Knowledge = 1,
        RiskManagement = 2,
        Skills = 3,
    }

export enum PlanOfActionSectionTypeAllItems {
        Manual = 1,
        AreaOfOperationGround = 2,
        TaskGround = 3,
        AreaOfOperationFlight = 4,
        TaskFlight = 5,
    }

export enum PilotExaminerLocationTypeAllItems {
        Home = 1,
        Travel = 2,
    }

export enum CheckRideOutcomeAllItems {
        CertificateIssued = 1,
        Disapproved = 2,
        Discontinued = 3,
    }

export enum CheckRideRequestPaymentPartyAllItems {
        PilotExaminer = 1,
        Applicant = 2,
    }

export enum AviationTrainingProgramAllItems {
        Part61 = 1,
        Part141 = 2,
    }




