import { Injectable } from "@angular/core";
import { DwAction, DwActionHandler, DwActionResult, DwActionType, DwButtonType, DwFormActionConfig, DwMdFormActionState, DwRoutingService } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaDataItemNames, CheckRideRequestEntity, CheckRideRequestSubStatusAllItems } from "../../../../meta-data/app-meta-data.service";
import { mergeMap } from "rxjs/operators";




@DwAction<DwFormActionConfig>({
    keyName: 'accept-new-proposed-date',
    display: 'Accept Proposed Date',
    parentItemName: [AppMetaDataItemNames.CheckRideRequest],
    isGlobal: false,
    actionType: DwActionType.formAction,
    defaultButtonConfig: {
        useParentDisplay: false,
        buttonText: 'Accept',
        buttonClass: 'btn btn-success',
        buttonTitle: 'Accept',
        buttonType: DwButtonType.standard,
        iconClass: 'fa fa-check'
    },
    configData: {
        requireValidation: true,
        redirectAfter: true,
        actionMessage: 'Saving...',
    }
})
@Injectable()
export class AcceptProposedDateActionService  implements DwActionHandler  {

    constructor(private dwRoutingService: DwRoutingService) {}

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {
        
       
        const checkride = actionState.formApi.getFormData<CheckRideRequestEntity>();

        checkride.CheckRideRequestSubStatusId = CheckRideRequestSubStatusAllItems.Accepted;
        checkride.ScheduledDateTime = checkride.ProposedDateTime;
        if(checkride.ProposedTimeMinutes) {
            checkride.ScheduledTimeMinutes = checkride.ProposedTimeMinutes;
        }
        if (checkride.ProposedAirportId) {
            checkride.ScheduledAirportId = checkride.ProposedAirportId;
        }

        actionState.formApi.formGroup.patchValue(checkride);

        // Save the checkride first, then navigate to the rebook page
        return actionState.formApi.saveData(true)

        .pipe(mergeMap(saveResult => {
            const result = new DwActionResult();

            if (saveResult.cancelled || saveResult.validationFailed) {
                result.cancelled = true;
                return of(result);
            }
    
            result.success = true;
            return of(result);
        }));
    }
}