import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PilotExaminerLocationEntity, PilotExaminerSearchQueryEntity } from '../../../../meta-data/app-meta-data.service';
import { DwUIConfigToken, DwUIConfig, DwConfigServiceToken, DwConfigService, DwInputConverter, DwMessage, DwMessageSeverity } from '@devwareapps/devware-cap';
import { ExaminerTimeSlotGrouping, ExaminterTimeSlotItem } from '../../../examiner/models/examiner-time-slot-grouping.model';
import { ExaminerLocationSelection } from '../../../examiner/models/exminer-location-selection.model';
import { ExaminerTimeSlotEntityExt, PilotExaminerSearchQueryEntityExt } from '../../../../meta-data/extensions/pilot-examiner-search-query-ext.model';


@Component({
  selector: 'app-schedule-selector',
  templateUrl: './schedule-selector.component.html',
  styleUrls: ['./schedule-selector.component.scss']
})
export class ScheduleSelectorComponent implements OnInit, AfterViewInit {
  @Input() searchResult: PilotExaminerSearchQueryEntityExt;

  @Input() isContainerSelected: boolean;

  @Input() selectedDateTime: string;
  @Output() selectedDateTimeChange = new EventEmitter<string>();

  @ViewChild("container") containerElement: ElementRef;
  visibleTimeslotGroupings: ExaminerTimeSlotGrouping[] = [];

  timeSlotsToShow = 12; 
  timeSlotIncrement = 12;
  hasMoreTimeslots: boolean;
  controlWidth: any;
  constructor() { }

  noAvailabilityMessage: DwMessage;;

  ngAfterViewInit(): void {
    this.setControlSize();
  }

  ngOnInit(): void {

    if (this.searchResult.AvailableTimeSlots.length == 0) {
       this.setupNoAvailabilityMessage();

    }

    this.setupVisibleTimeslowGroupings();

    this.setControlSize();

    //this.setupDefaultTimeslot();
  }

  private setupNoAvailabilityMessage() {
    const weeksOut = this.searchResult.CheckRideRequestBookingMaxWeeksOut ? this.searchResult.CheckRideRequestBookingMaxWeeksOut : 8;

    
    let message = `No availability found for the selected date and up to ${weeksOut} weeks out`;

    if (weeksOut == 1) {
      message = `No availability found for the selected date and up to a week out`;
    }

    this.noAvailabilityMessage = {
      messageBody: message,
      severity: DwMessageSeverity.warning
    }


  }
  
  @HostListener('window:resize', ['$event.target'])
  public onResize(target) {
    this.setControlSize();
  }

  private setControlSize() {
    const width = this.containerElement?.nativeElement?.offsetWidth;
    
    this.controlWidth = width;
    
    if (this.controlWidth < 200) {
      this.timeSlotIncrement = 2;
    } else if (this.controlWidth < 400) {
      this.timeSlotIncrement = 4;
    } else if (this.controlWidth < 600) {
      this.timeSlotIncrement = 8;
    } else {
      this.timeSlotIncrement = 12;
    }

    this.timeSlotsToShow = this.timeSlotIncrement;

    this.setupVisibleTimeslowGroupings();
  }
  
  showMore() {
    this.timeSlotsToShow += this.timeSlotIncrement;

    this.setupVisibleTimeslowGroupings();
  }
  private setupVisibleTimeslowGroupings() {
    if (!this.searchResult.AvailableTimeSlots) {
      this.visibleTimeslotGroupings = this.buildSampleTimeslots();
      return;
    }

    const timeSlots = [...this.searchResult.AvailableTimeSlots];

    if (this.timeSlotsToShow < timeSlots.length) {
      timeSlots.length = this.timeSlotsToShow;
      this.hasMoreTimeslots = true;
    } else {
      this.hasMoreTimeslots = false;
    }

    this.visibleTimeslotGroupings = this.mapTimeslotData(timeSlots);
  }

  setupDefaultTimeslot() {
    if (this.isContainerSelected) {
      if (!this.selectedDateTime) {
        if (this.visibleTimeslotGroupings?.length > 0) {
          this.setSelectedTimeslot(this.visibleTimeslotGroupings[0].timeslots[0]);
        }
      }
    }
  }

  getDateContainer(group: ExaminerTimeSlotGrouping): string {
    if (group.timeslots.length >= 2 && group.timeslots.length <= 5) {
      return `date-container-${group.timeslots.length}`;
    }

    if (group.timeslots.length > 5) {
      return 'date-container-full';
    }

    return '';
  }

  setSelectedTimeslot(selectedTimeSlot: ExaminterTimeSlotItem) {

    this.selectedDateTime = selectedTimeSlot.fullDateTime;

    this.selectedDateTimeChange.emit(this.selectedDateTime);
  }


  isTimeslotSelected(timeSlot: ExaminterTimeSlotItem) {
    if (this.isContainerSelected) {
      return this.selectedDateTime == timeSlot.fullDateTime;
    }
    return false;
  }
  
  private mapTimeslotData(timeslots: ExaminerTimeSlotEntityExt[]): ExaminerTimeSlotGrouping[] {
    const timeSlotGroupings: ExaminerTimeSlotGrouping[] = [];

    let timeSlotGroup: ExaminerTimeSlotGrouping;

    for (const timeSlot of timeslots) {

      if (timeSlotGroup?.dateDisplay !== timeSlot.Date) {
        timeSlotGroup = {
          dateDisplay: timeSlot.Date,
          timeslots: []
        };

        timeSlotGroupings.push(timeSlotGroup);
      }

      timeSlotGroup.timeslots.push({
        timeRangeDisplay: timeSlot.TimeRange,
        fullDateTime: timeSlot.StartDateTimeUtc
      });
    }

    return timeSlotGroupings;
  }


  private buildSampleTimeslots() {
    const timeslotGroupings: ExaminerTimeSlotGrouping[] = [];


    timeslotGroupings.push({
      dateDisplay: '9/1/2023',
      timeslots: [
        { timeRangeDisplay: '8am - 1pm', fullDateTime: '9/1/2023 8am' },
        { timeRangeDisplay: '1pm - 6pm', fullDateTime: '9/1/2023 1pm' }
      ]
    });

    timeslotGroupings.push({
      dateDisplay: '9/4/2023',
      timeslots: [
        { timeRangeDisplay: '8am - 1pm', fullDateTime: '9/4/2023 8am' },
        { timeRangeDisplay: '1pm - 6pm', fullDateTime: '9/4/2023 1pm' }
      ]
    })

    timeslotGroupings.push({
      dateDisplay: '9/9/2023',
      timeslots: [
        { timeRangeDisplay: '8am - 1pm', fullDateTime: '9/9/2023 8am' },
        { timeRangeDisplay: '1pm - 6pm', fullDateTime: '9/9/2023 1pm' }
      ]
    })

    timeslotGroupings.push({
      dateDisplay: '9/14/2023',
      timeslots: [
        { timeRangeDisplay: '8am - 1pm', fullDateTime: '9/14/2023 8am' },
        { timeRangeDisplay: '1pm - 6pm', fullDateTime: '9/14/2023 1pm' }
      ]
    })

    timeslotGroupings.push({
      dateDisplay: '9/23/2023',
      timeslots: [
        { timeRangeDisplay: '8am - 1pm', fullDateTime: '9/23/2023 8am' },
        { timeRangeDisplay: '1pm - 6pm', fullDateTime: '9/23/2023 1pm' }
      ]
    })

    return timeslotGroupings;
  }

}
