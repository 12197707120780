import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CheckRideTestTypeAllItems, PilotExaminerLocationEntity, PilotExaminerSearchQueryEntity } from '../../../../meta-data/app-meta-data.service';
import { DwUIConfigToken, DwUIConfig, DwConfigServiceToken, DwConfigService } from '@devwareapps/devware-cap';
import { ExaminerTimeSlotGrouping, ExaminterTimeSlotItem } from '../../../examiner/models/examiner-time-slot-grouping.model';
import { ExaminerLocationSelection } from '../../../examiner/models/exminer-location-selection.model';
import { ExaminerTimeSlotEntityExt, PilotExaminerSearchQueryEntityExt } from '../../../../meta-data/extensions/pilot-examiner-search-query-ext.model';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';
import { ApplicantCheckrideVariables } from '../../../applicant/models/applicant-checkride-variables.model';

@Component({
  selector: 'app-examiner-booking-search-result',
  templateUrl: './examiner-booking-search-result.component.html',
  styleUrls: ['./examiner-booking-search-result.component.scss']
})
export class ExaminerBookingSearchResultComponent implements OnInit, OnChanges {
  @Input() searchResult: PilotExaminerSearchQueryEntityExt;
  apiRoot: string;
  defaultAvatarMediaUrl: string;
  imagePath: string;

  selectedDateTime: string;

  @Input() showDateSelection: boolean = true;

  @Input() examinerLocationSelection: ExaminerLocationSelection;
  @Output() examinerLocationSelectionChange = new EventEmitter<ExaminerLocationSelection>();

  @Input() applicantCheckrideVariables: ApplicantCheckrideVariables;
  fullCheckrideCost: number;
  checkrideCost: number;

  @Input() testType: CheckRideTestTypeAllItems;
  checkrideDuration: number;
  checkrideDurationDisplay: string;

  constructor(@Inject(DwUIConfigToken) private uiConfig: DwUIConfig,
    @Inject(DwConfigServiceToken) private dwConfigService: DwConfigService,
    private examinerRepositoryService: ExaminerRepositoryService,
    private dateTimeUtilService: DateTimeUtilService

  ) {
    this.apiRoot = dwConfigService.coreConfig.apiRoot;
  }

  ngOnInit(): void {
    this.setupImage();

    this.setupData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupImage();

    if (changes.examinerLocationSelection) {
      this.selectedDateTime = this.examinerLocationSelection?.scheduleDateTime;
    }

    if (changes.searchResult || changes.testType) {
      this.setupData();
    }
  }

  setupData() {
    if (!this.searchResult) {
      return;
    }

    this.checkrideCost = this.examinerRepositoryService.getFullBookingCost(this.searchResult, this.testType, this.applicantCheckrideVariables);
    const checkrideDurationMinutes = this.examinerRepositoryService.getCheckrideTimeMinutes(this.searchResult, this.testType)
    this.checkrideDurationDisplay =  this.dateTimeUtilService.formatDuration2(checkrideDurationMinutes * 60);

    this.fullCheckrideCost = this.checkrideCost + this.searchResult.PilotExaminerLocationTravelCost || 0;
  }



  setSelectedDateTime(selectedDateTime: string) {

    this.selectedDateTime = selectedDateTime;

    this.examinerLocationSelection = {
      searchResult: this.searchResult,
      scheduleDateTime: selectedDateTime,
      confirmed: false
    };

    this.examinerLocationSelectionChange.emit(this.examinerLocationSelection);
  }

  confirmSelection() {
    this.examinerLocationSelection.confirmed = true;

    this.examinerLocationSelectionChange.emit(this.examinerLocationSelection);
  }
  isContainerSelected() {
    return (this.examinerLocationSelection?.searchResult?.LocationDistancesPilotExaminerLocationId == this.searchResult?.LocationDistancesPilotExaminerLocationId &&
      this.examinerLocationSelection?.searchResult?.LocationDistancesAirportId == this.searchResult?.LocationDistancesAirportId);
  }

  containerClick() {
    if (!this.isContainerSelected()) {

      if (this.searchResult.AvailableTimeSlots?.length > 0) {

        this.setSelectedDateTime(this.searchResult.AvailableTimeSlots[0].StartDateTimeUtc);
      }
    }
  }

  private setupImage() {
    this.defaultAvatarMediaUrl = this.uiConfig.defaultAvatarImageUrl;

    if (!this.searchResult) {
      return;
    }

    const mediaPath = this.searchResult.UserPictureDwroMediaMediaPath;
    if (mediaPath) {
      this.imagePath = `${this.apiRoot}/media/download/${mediaPath}`;
    }
  }

}
