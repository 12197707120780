import { Inject, Injectable } from "@angular/core";
import { DwDataItemKeyValues, DwDataRequest, DwDatasource, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwOrmDataSource, DwQuery, DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";
import { Observable } from "rxjs";

@DwDatasource({
    keyName: 'PlanOfActionDataSource',
    display: 'Plan of Action Data Source',
    itemName: AppMetaDataItemNames.PlanOfAction,
})
@Injectable()
export class PlanOfActionDataSourceService extends DwOrmDataSource {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) metaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        super(dwOrmDataService, metaDataService, AppMetaDataItemNames.PlanOfAction);
    }

    initDataSource() {
        
    }
    
    loadItemFromQuery(query: DwQuery, keyValues: DwDataItemKeyValues | number): Observable<any> {

        const planOfActionQuery = AppMetaData.PlanOfAction.CreateQueryBuilder(query);

        planOfActionQuery.addPrefetch(r => r.PlanOfActionRequiredAcsCode);

        const prefetchSection = query.Prefetch.find(p => p.RelationName === AppMetaData.PlanOfAction.Relations.PlanOfActionSection.relationName);

        const sectionQuery = AppMetaData.PlanOfActionSection.CreateQueryBuilder(prefetchSection?.Query);

        sectionQuery.addOrderBy(a => a.SectionOrder);

        prefetchSection.Query = sectionQuery.query;

        let prefetchSectionQuestion = sectionQuery.query.Prefetch?.find(p => p.RelationName === AppMetaData.PlanOfActionSection.Relations.PlanOfActionSectionQuestion.relationName);

        if (!prefetchSectionQuestion) {
            sectionQuery.addPrefetch(r => r.PlanOfActionSectionQuestion);

            prefetchSectionQuestion = sectionQuery.query.Prefetch?.find(p => p.RelationName === AppMetaData.PlanOfActionSection.Relations.PlanOfActionSectionQuestion.relationName);
        }

        const questionQuery = AppMetaData.PlanOfActionSectionQuestion.CreateQueryBuilder(prefetchSectionQuestion?.Query);

        questionQuery.addOrderBy(a => a.QuestionOrder);

        prefetchSectionQuestion.Query = questionQuery.query;
        
        return super.loadItemFromQuery(planOfActionQuery.query, keyValues);
    }

   
    // prepareRequestQuery(request: DwDataRequest): DwQuery {
    //     const baseQuery = super.prepareRequestQuery(request);

    // }

}