import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItemCardData } from '../../models/menu-item-card-data.model';
import { PilexosUserType, PilexosUserTypeIds} from '../../../shared/models/pilexos-user-type.enum';
import { DwSecurityOrchestrationService, DwSecurityRepositoryService, DwSecurityRepositoryServiceToken } from '@devwareapps/devware-cap';
import { PilexosHomePageRepository } from '../../services/pilexos-home-page-repository.service';
import { DwUserTypePilexosEntity } from '../../../../meta-data/extensions/dw-user-type-entity.model';

@Component({
  selector: 'app-annonymous-home-view',
  templateUrl: './annonymous-home-view.component.html',
  styleUrls: ['./annonymous-home-view.component.scss']
})
export class AnnonymousHomeViewComponent implements OnInit {


  constructor(private router: Router,
    private dwSecurityOrchestrationService: DwSecurityOrchestrationService,
    @Inject(DwSecurityRepositoryServiceToken) private dwSecurityRepositoryService: DwSecurityRepositoryService,
    private homePageRepository: PilexosHomePageRepository

  ) { }

  topCards: MenuItemCardData[] = [];

  ngOnInit(): void {
   

    this.homePageRepository.getUserTypes()
      .subscribe(userTypes => {
        this.buildAnonymousCards(userTypes);
      })
  }
  buildAnonymousCards(userTypes: DwUserTypePilexosEntity[]) {
    this.topCards = [];

    for(const userType of userTypes) {
      const card = this.buildUserTypeCard(userType);

      if (card) {
        this.topCards.push(card);
      }
    }

  }

  buildUserTypeCard(userType: DwUserTypePilexosEntity) {
    if (!userType.ShowOnRegistration) {
      return null;
    }
    const card : MenuItemCardData ={
      iconClass: this.getDefaultIcon(userType),
      iconBackgroundClass: 'dw-blue-bg',
      iconText: userType.UserTypeDisplay,
      buttonClass: 'btn btn-primary',
      buttonText: 'SIGN UP',
      contentHtml: userType.UserTypeDisplay,
      clickFunc: () => { this.register(userType.UserTypeId as any) }
    }

    if (userType.UserTypeClasses) {
      card.iconClass = userType.UserTypeClasses;
    }

    return card;
  }

  private getDefaultIcon(userType: DwUserTypePilexosEntity) {
    switch (userType.UserTypeId) {
      case PilexosUserTypeIds.pilotExaminer:
        return 'fa fa-clipboard-list-check';
      case PilexosUserTypeIds.studentApplicant:
        return 'fa fa-user-circle';
      case PilexosUserTypeIds.flightSchool:
        return 'fa fa-users';
    }
  }


  private buildAnonymousCardsOld() {
    this.topCards = [];

    
    this.topCards.push({
      iconClass: 'fa fa-clipboard-list-check',
      iconBackgroundClass: 'dw-blue-bg',
      iconText: 'Pilot Examiners',
      buttonClass: 'btn btn-primary',
      buttonText: 'SIGN UP',
      contentHtml: 'Pilot Examiners',
      clickFunc: () => { this.register(PilexosUserType.pilotExaminer) }
    });

    this.topCards.push({
      iconClass: 'fa fa-user-circle',
      iconBackgroundClass: 'dw-blue-bg',
      
      buttonClass: 'btn btn-primary',
      buttonText: 'SIGN UP',
      contentHtml: 'Student Pilots',
      clickFunc: () => { this.register(PilexosUserType.studentApplicant) }
    });

    
    this.topCards.push({
      iconClass: 'fa fa-users',
      iconBackgroundClass: 'dw-blue-bg',
      
      buttonClass: 'btn btn-primary',
      buttonText: 'SIGN UP',
      contentHtml: 'Flight Schools',
      clickFunc: () => { this.register(PilexosUserType.flightInstructor) }
    });
  }

  login() {
    this.router.navigateByUrl("/login");
  }

  register(userType: PilexosUserType) {
    this.router.navigateByUrl(`/register?type=${userType}`);
  }

}
