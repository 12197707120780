import { CalendarWeekDayEvent } from "./calendar-weekday-event.model";

export interface CalendarClickEvent {
    eventType : CalendarClickEventType;
    eventData: CalendarWeekDayEvent;
    mouseEvent: MouseEvent;

    fullEvent: any;
    start: any;
    end: any;

    preventDefault: () => void;
}


export enum CalendarClickEventType {
    click = 'click',
    move = 'move',
    moved = 'moved',
    select = 'select',
    selected = 'selected',
    doubleClick = 'doubleClick',
    timeRangeSelected = 'timeRangeSelected',
    headerClick = 'headerClick'
}


