import { Injectable } from "@angular/core";
import { DwChartConfig, DwChartPreferences, DwChartTypes } from "../models/dw-chart-preferences.model";
import { DwGridConfig, DwGridHeightMode, DwGridPreferences, DwMetaDataGridPreferences, DwMetaDataGridViewPreference, DwQuery } from "@devwareapps/devware-cap";
import { DwChartColors, DwChartColors2, DwChartColors3 } from "../models/dw-chart-colors.model";



@Injectable({ providedIn: 'root' })
export class DwChartBuilderService {

    colors = DwChartColors;

    buildChartConfig(chartPreference: DwChartPreferences, data: any[]): DwChartConfig {

        const chartConfig: DwChartConfig = {
            chartType: this.convertChartType(chartPreference.chartType),
            chartOptions: {
                title: {
                    display: false,
                    text: 'Checkride Types',
                    color: '#ffffff',
                    fontSize: 18,
                    position: 'top'
                },
                legend: {
                    display: !chartPreference.hideLegend,
                    position: 'bottom'
                },
            },
            chartData: {
                labels: this.getDataForSeries(chartPreference.categoryField, data, chartPreference.maxItemsToShow),
                datasets: []
            }
        };

        let hasY2Axis = false;

        for (const series of chartPreference.series) {
            const dataSet: any = {
                type: this.convertChartType(series.chartType),
                label: series.legendDisplay,
                backgroundColor: series.backgroundColor || series.borderColor,
                borderColor: series.borderColor || series.backgroundColor,
                fill: false,
                data: this.getDataForSeries(series.dataField, data, chartPreference.maxItemsToShow)
            };

            if (series.alternateColors) {
                dataSet.backgroundColor = [...this.colors, ...DwChartColors2, ...this.colors]; 
            }

            if (series.axis === 'y2') {
                hasY2Axis = true; 
                dataSet.yAxisID = 'y2';
            }

            chartConfig.chartData.datasets.push(dataSet);
        }

        if (hasY2Axis) {
            chartConfig.chartOptions.scales = {
                yAxes: [{
                    type: 'linear',
                    position: 'left',
                    // options: {
                    //     ticks: {
                    //         beginAtZero: true,
                    //     }
                    // },
                    ticks: {
                        beginAtZero: true,
                    },
                    // gridLines: {
                    //     // color: 'blue'
                    // }
                },
                {
                    type: 'linear',
                    position: 'right',
                    id: 'y2',
                    ticks: {
                        beginAtZero: true,
                        key: 'Anthony-test-3'
                    },
                    gridLines: {
                        color: 'green',
                        display: false,
                    }
                }
                ]
            };
        } else {
            this.setupBarColumnSingleAxis(chartPreference, chartConfig);
        }

        return chartConfig;
    }
    setupBarColumnSingleAxis(chartPreference: DwChartPreferences, chartConfig: DwChartConfig) {

        switch(chartPreference.chartType) {
            case DwChartTypes.bar:
                chartConfig.chartOptions.scales = {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ]
                };
                break;
            case DwChartTypes.column:
      
                chartConfig.chartOptions.scales = {
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ]
                };
                break;
        }

    }

    private convertChartType(chartType: DwChartTypes): string {
        switch (chartType) {
            case DwChartTypes.bar:
                return 'bar';
            case DwChartTypes.line:
                return 'line';
            case DwChartTypes.pie:
                return 'pie';
            case DwChartTypes.column:
                //return 'bar';
                return 'horizontalBar';
            case DwChartTypes.doughnut:
                return 'doughnut';
            case DwChartTypes.polarArea:
                return 'polarArea';
            case DwChartTypes.radar:
                return 'radar';
        }

    }


    buildGridPreferences(chartPreferences: DwChartPreferences, query: DwQuery): any {
        const gridPreferences = new DwMetaDataGridPreferences();

        gridPreferences.passCurrentDataSource = false;

        gridPreferences.showDisplayProperties = query?.FieldSettings?.LoadAllLookupDisplayFields;

        gridPreferences.showFooter = true;
        gridPreferences.loadTotalRowCount = true;

        gridPreferences.hideAdd = true;
        gridPreferences.hideEditButton = true;
        gridPreferences.hideDeleteButton = true;
        gridPreferences.hideRefresh = true;
        gridPreferences.hideExport = false;
        gridPreferences.showFilter = true;
        gridPreferences.showFilterOnLoad = true;
        gridPreferences.gridStyle = {
            gridHeight: '300',
            gridHeightMode: DwGridHeightMode.fixedHeight,
            gridStyleName: 'Simple',
        }

        const view: DwMetaDataGridViewPreference =
        {
            columnPreferences: [],
            actionPreferences: {},
            display: 'Default',
            filters: null,
            item: query.RootTable,
            useCustomActions: true,
            isDefault: true,
            query: query,
            includeAllFields: true
        };

        gridPreferences.views = [view];

        if (chartPreferences.gridPreferences?.columnPreferences) {
            view.columnPreferences = chartPreferences.gridPreferences.columnPreferences;
        } else {
            // Add Columns
            view.columnPreferences.push({
                field: chartPreferences.categoryField,
                label: chartPreferences.categoryDisplay,
                width: chartPreferences.gridPreferences?.categoryColumnWidth || 200,
                columnIndex: 0,
            });

            // Add Series Field
            for (const series of chartPreferences.series) {
                view.columnPreferences.push({
                    field: series.dataField,
                    label: series.legendDisplay,
                    width: chartPreferences.gridPreferences?.aggregateColumnWidth || 100,
                    columnIndex: view.columnPreferences.length,
                });
            }
        }
        return gridPreferences;
    }


    private getDataForSeries(fieldName: string, financialData: any[], maxItemsToShow: number): any[] {
        const seriesData: any[] = [];

        if (maxItemsToShow) {
            financialData = [...financialData];
            financialData.length = maxItemsToShow;
        }

        for (const data of financialData) {
            seriesData.push(data[fieldName]);
        }


        return seriesData;
    }



}