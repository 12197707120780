import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map, mergeMap } from 'rxjs/operators'
import { AppMetaData, CalendarReferenceEntity, CheckRideGroupAllItems, CheckRideTypeAllItems, PilotExaminerEntity, PilotExaminerLocationEntity, PilotExaminerSearchQueryEntity, PilotExaminerStatusAllItems } from "../../../meta-data/app-meta-data.service";
import { DwMetaDataServiceToken, DwMetaDataService, DwOrmDataService, DwOrmDataServiceToken, DwQuery, deepCopy, DwConfigService, DwConfigServiceToken, DwLookupInfo, SuppressErrorMessagesHeader, sortArrayByField } from "@devwareapps/devware-cap";
import { ExaminerLocationSearchRequest } from "../../examiner/models/examiner-location-search-request.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CalendarDTO } from "../models/calendar.model";
import { CalendarEventDTO } from "../models/calendar-event.model";
import { EventSearchRequestDTO } from "../models/event-search-request.model";
import { CalendarDelegationSetupResponseDTO } from "../models/calendar-delegation-setup-response.model";
import { CalendarDelegationRevokeResponseDTO } from "../models/calendar-delegation-revoke-response.model";

@Injectable({ providedIn: 'root' })
export class CalendarRepositoryService {
    apiRoot: string;

    constructor(
        private http: HttpClient,
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        @Inject(DwConfigServiceToken) configService: DwConfigService,
    ) {
        this.apiRoot = configService.coreConfig.apiRoot;
    }

    getCalendars(): Observable<CalendarDTO[]> {
        let url = `${this.apiRoot}/pilexos/calendars/list`;

        let headers = new HttpHeaders();

        headers = headers.append(SuppressErrorMessagesHeader, 'true');

        return this.http.get<CalendarDTO[]>(url, { headers: headers })
            .pipe(
                map(calendars => {
                    var sorted = sortArrayByField(calendars, 'IsPrimary', 'IsReadOnly', true);

                    var filtered = sorted.filter(c => !c.IsReadOnly);

                    return filtered;
                }));
    }

    getCalendarRef(calendarRefId: number): Observable<CalendarReferenceEntity> {
        var query = AppMetaData.CalendarReference.CreateQueryBuilder();

        query.addFilterAnd(f => f.Equal(a => a.CalendarReferenceId, calendarRefId));

        return this.dwOrmDataService.executeQuerySingle(query);
    }

    saveCalendarRef(calendarReference: CalendarReferenceEntity): Observable<CalendarReferenceEntity> {
        return this.dwOrmDataService.saveEntity(calendarReference, false);
    }

    getEvents(request: EventSearchRequestDTO): Observable<CalendarEventDTO[]> {
        let url = `${this.apiRoot}/pilexos/calendars/events/list`;

        let headers = new HttpHeaders();

        headers = headers.append(SuppressErrorMessagesHeader, 'true');

        return this.http.post<CalendarEventDTO[]>(url, request, { headers: headers });
    }

    getOAuthRedirectUri() {
        let redirectUri = `${location.origin}/assets/oauth/google-oauth-consent.html`;

        return redirectUri;
    }

    getOAuthConsentUrl(redirectUri: string, calendarTypeMeaning: string = 'GOOGLE'): Observable<string> {
        //redirectUri = 'https://localhost:4204/assets/oauth/google-oauth-consent.html'

        let url = `${this.apiRoot}/pilexos/calendars/consentUrl?calendarTypeMeaning=${calendarTypeMeaning}&redirectUri=${redirectUri}`;

        let headers = new HttpHeaders();

        headers = headers.append(SuppressErrorMessagesHeader, 'true');

        return this.http.get<string>(url, { headers: headers });
    }

    setupCalendarDelegation(code: string, redirectUrl: string, calendarTypeMeaning: string = 'GOOGLE'):Observable<CalendarDelegationSetupResponseDTO> {
        let url = `${this.apiRoot}/pilexos/calendars/setup`;

        let headers = new HttpHeaders();

        headers = headers.append(SuppressErrorMessagesHeader, 'true');

        var request = {
            DelegationCode: code,
            OriginalRedirectUrl: redirectUrl,
            CalendarTypeMeaning: calendarTypeMeaning
        }

        return this.http.post<CalendarDelegationSetupResponseDTO>(url, request, { headers: headers });
    }

    revokeCalendarDelegation(): Observable<CalendarDelegationRevokeResponseDTO> {

        let url = `${this.apiRoot}/pilexos/calendars/revoke`;

        let headers = new HttpHeaders();
        headers = headers.append(SuppressErrorMessagesHeader, 'true');

        return this.http.post<CalendarDelegationRevokeResponseDTO>(url, {}, { headers: headers });
    }

}
