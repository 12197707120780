import { Component, OnInit } from '@angular/core';
import { DwChartColors } from '../../../z-devware/visualizations/models/dw-chart-colors.model';

@Component({
  selector: 'app-examiner-financial-snapshot',
  templateUrl: './examiner-financial-snapshot.component.html',
  styleUrls: ['./examiner-financial-snapshot.component.scss']
})
export class ExaminerFinancialSnapshotComponent implements OnInit {

  constructor() { }
  
  colors = DwChartColors;

  checkrideTypeData = {
    labels: ['ASEL', 'ASES', 'AMEL', 'AMES', 'Helicopter', 'Glider', 'Balloon', 'Gyroplane', 'Powered Lift', 'Airship', 'Weight Shift Control', 'Powered Parachute'],
    datasets: [
      {
        data: [5, 3, 4, 2, 1, 2, 3, 4, 5, 6, 7, 8],
      }]
  };

  
  checkrideOutcomeData = {
    labels: ['Certificate Issued', 'Disapproved', 'Discontinued'],
    datasets: [
      {
        data: [15, 3, 5],
        backgroundColor: [this.colors[0], this.colors[this.colors.length-1], this.colors[8]]
      }]
  };

  checkrideTypeOptions = {
    title: {
      display: true,
      text: 'Checkride Types',
      color: '#ffffff',
      fontSize: 18,
      position: 'top'
    },
    legend: {
      display: false,
      position: 'bottom'
    }
  };

  checkrideOutcomeOptions = {
    title: {
      display: true,
      text: 'Checkride Outcomes',
      fontSize: 18,
      color:'white',
      position: 'top'
    },
    legend: {
      display: true,
      position: 'bottom'
    }
  }

  ngOnInit(): void {
  }

}
