import { Injectable } from "@angular/core";
import { PlanOfActionEntity, PilotExaminerEntity, AcsTestEntity, AcsAreaOfOperationEntity, PlanOfActionSectionEntity, AcsAreaOfOperationTaskEntity, PlanOfActionSectionTypeAllItems, AcsCodesLookupQueryEntity, PlanOfActionSectionQuestionEntity, AppMetaDataItemNames, PlanOfActionRequiredAcsCodeEntity } from "../../../meta-data/app-meta-data.service";
import { AcsCodeStorage, AcsCodesStorageItem } from "../models/acs-code-items.model";
import { GenerateSectionsOptions } from "../models/generate-sections-options.model";
import { GenerateCodesOptions } from "../models/generate-codes-options.model";

@Injectable({ providedIn: 'root' })
export class PlanOfActionGeneratorService {
  
  

    generateSections(planOfAction: PlanOfActionEntity, options: GenerateSectionsOptions, acsTest: AcsTestEntity, allAcsCodes: AcsCodeStorage) {
        planOfAction.PlanOfActionSection = planOfAction.PlanOfActionSection || [];
        if (options.clearExistingSections) {
            planOfAction.PlanOfActionSection = [];
        }

        const areaOfOperations = acsTest.AcsAreaOfOperation.filter(aoo =>  options.buildTaskSections || options.AreaOfOperationIds.includes(aoo.AcsAreaOfOperationId));

        for (const aoo of areaOfOperations) {
            if (options.buildTaskSections) {
                const tasks = aoo.AcsAreaOfOperationTask.filter(task => options.TaskIds.includes(task.AcsAreaOfOperationTaskId));

                for (const task of tasks) {
                    this.generateSection(planOfAction, aoo, task, options, allAcsCodes)
                }
            } else {
                const section = this.generateSection(planOfAction, aoo, null, options, allAcsCodes);
            }
        }
    }

    generateSection(planOfAction: PlanOfActionEntity, aoo: AcsAreaOfOperationEntity, task: AcsAreaOfOperationTaskEntity, options: GenerateSectionsOptions, allAcsCodes: AcsCodeStorage) {
        const section: PlanOfActionSectionEntity = {
            _itemName: AppMetaDataItemNames.PlanOfActionSection,
            PlanOfActionId: planOfAction.PlanOfActionId,
            PlanOfActionSectionTypeId: this.getSectionTypeId(options),
            AcsAreaOfOperationId: aoo.AcsAreaOfOperationId,
            AcsAreaOfOperationTaskId: task?.AcsAreaOfOperationTaskId,
            PlanOfActionSectionQuestion: [],
            SectionOrder: planOfAction.PlanOfActionSection.length + 1
        };

        planOfAction.PlanOfActionSection.push(section);

        // if (options.includeAllAcsCodes) {
        //     let ascCodes = allAcsCodes.AreaOfOperationCodes[aoo.AcsAreaOfOperationId]?.acsCodes || [];

        //     if (task) {
        //         ascCodes = allAcsCodes.TaskCodes[aoo.AcsAreaOfOperationId]?.acsCodes || [];
        //     }

        //     this.generateQuestions(section, ascCodes, options.useQuestionBank);
        // }
    }

    getSectionTypeId(options: GenerateSectionsOptions): number {
        if (options.groundPortion) {
            if (options.buildTaskSections) {
                return PlanOfActionSectionTypeAllItems.TaskGround;
            }
            return PlanOfActionSectionTypeAllItems.AreaOfOperationGround;
        }
        if (options.buildTaskSections) {
            return PlanOfActionSectionTypeAllItems.TaskFlight;
        }
        return PlanOfActionSectionTypeAllItems.AreaOfOperationFlight;
        
    }

    generateQuestions(section: PlanOfActionSectionEntity, questions: PlanOfActionSectionQuestionEntity[], options: GenerateCodesOptions) {
        if (!options.useQuestionBank) {
            for(let acsCode of options.acsCodes) {
                const question: PlanOfActionSectionQuestionEntity = {
                    _itemName: AppMetaDataItemNames.PlanOfActionSectionQuestion,
                    PlanOfActionSectionId: section.PlanOfActionSectionId,
                    AcsCodeId: acsCode.AcsCodeId
                }
    
                questions.push(question);
            }
            return;
        }

        for(let acsCodeQuestion of options.questions) {
            const question: PlanOfActionSectionQuestionEntity = {
                _itemName: AppMetaDataItemNames.PlanOfActionSectionQuestion,
                PlanOfActionSectionId: section.PlanOfActionSectionId,
                AcsCodeId: acsCodeQuestion.AcsCodeId,
                QuestionHtml : acsCodeQuestion.QuestionBankQuestionHtml
            }

            questions.push(question);
        }

    }

    addRequiredAcsCodes(planOfAction: PlanOfActionEntity, options: GenerateCodesOptions) {
        planOfAction.PlanOfActionRequiredAcsCode = planOfAction.PlanOfActionRequiredAcsCode || [];

        for(const acsCode of options?.acsCodes || []) {
            const existingCode = planOfAction.PlanOfActionRequiredAcsCode.find(porac => porac.AcsCodeId == acsCode.AcsCodeId);

            if (!existingCode) {
                const newCode: PlanOfActionRequiredAcsCodeEntity = {
                    _itemName: AppMetaDataItemNames.PlanOfActionRequiredAcsCode,
                    PlanOfActionId: planOfAction.PlanOfActionId,
                    AcsCodeId: acsCode.AcsCodeId
                }

                planOfAction.PlanOfActionRequiredAcsCode.push(newCode);
            }
        }
    }
}


