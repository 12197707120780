<div class="question-container" [ngClass]="questionClass">
    <div class="row-one">
        <div class="column-one">
            <div class="acs-code" *ngIf="showAcsCode">
                <span *ngIf="acsCode" [title]="acsCode?.AscCodeDescription">
                    {{acsCode?.AcsCode}}
                </span>
            </div>
                <div class="question-result" *ngIf="showAcsCode">
                    <dw-radio-lookup [(ngModel)]="result.QuestionOutcomeId" (ngModelChange)="onChange()" [lookupId]="outcomeLookupId">
                    </dw-radio-lookup>
                </div>
                <div class="question-result" *ngIf="!showAcsCode">
                   <input type="checkbox">
                </div>
        </div>
        <div class="column-two">
            <div class="question">
                <dw-html-view [ngModel]="question?.QuestionHtml" *ngIf="showQuestion"></dw-html-view>
                <div *ngIf="!showQuestion">{{acsCode?.AscCodeDescription}}</div>
            </div>
            <div class="question-notes" *ngIf="showNotes">
                <input type="text"[(ngModel)]="result.Notes" (ngModelChange)="onChange()" placeholder="Notes" class="form-control">
            </div>
        </div>
    </div>
    <!-- <div class="row-two">

        <div class="question-discuss">
            <label class="check-box-container">
                Discuss
                <input type="checkbox">
                <span class="checkmark"></span>
              </label><br>

            <input type="checkbox"> Discuss
        </div>
        <div class="question-notes">
            <input type="text" placeholder="Notes" class="form-control">
        </div>
    </div> -->
</div>
