import { DwGridColumnPreference } from "@devwareapps/devware-cap";


export enum DwChartTypes {
    'bar',
    'line',
    'pie',
    'column',
    'doughnut',
    'polarArea',
    'radar',
}

export interface DwChartPreferences {
    chartType: DwChartTypes;
    categoryField: string;
    categoryValueField?: string;
    categoryDisplay?: string;
    series: DwChartSeries[];
    maxItemsToShow?: number;
    
    showContainerTitle?: boolean;
    containerTitle?: string;
    hideLegend?: boolean; 
    containerHeaderColor?: string;
    containerIcon?: string;
    
    item?: string;
    gridPreferences?: DwChartGridPreferences;
    
    hideChartDataToggle?: boolean;
    showGridFirst?: boolean;
}

export interface DwChartGridPreferences {
    showGrid?: boolean;
    gridConfigKeyPart?: string;
    categoryColumnWidth?: number;
    aggregateColumnWidth?: number;
    columnPreferences?: DwGridColumnPreference[];
} 

export interface DwChartSeries { 
    chartType: DwChartTypes;
    legendDisplay?: string;
    backgroundColor?: string;
    borderColor?: string;

    dataField: string;
    axis?: 'y' | 'y2';
    alternateColors?: boolean;
    alternateColorStart?: number;
}

export interface DwChartColorInfo {
    backgroundColor: string;
    color: string;
    borderColor: string;
}

export interface DwChartConfig {
    chartType: string;
    chartOptions: any;
    chartData: any;
}

  