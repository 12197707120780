import { Component, Inject, OnInit } from '@angular/core';
import { DwSectionBaseComponent, DwMetaDataFormApi, DwExpressionService, DwUiConfigRegistryService, DwUIMetaDataConfigToken, DwUIMetaDataConfig, DwMetaDataServiceToken, DwMetaDataService, DwMetaDataFormStateService, DwComponent, DwComponentType, DwLookupInfo, DwContainerOptions, DwExpandCollapseStyle, DwExpandCollapseLocation, sortArrayByField } from '@devwareapps/devware-cap';
import { PlanOfActionEntity, PlanOfActionSectionEntity, PlanOfActionSectionQuestionEntity, AcsCodesLookupQueryEntity, AppMetaDataItemNames, AppMetaDataLookups, PlanOfActionSectionTypeAllItems, PlanOfActionSectionTypeEntity } from '../../../../meta-data/app-meta-data.service';
import { AcsCodesStorageItem } from '../../../plan-of-action/models/acs-code-items.model';
import { PlanOfActionRepositoryService } from '../../../plan-of-action/services/plan-of-action-repository.service';
import { FormControl } from '@angular/forms';
import { GenerateQuestionsActionService } from '../../../plan-of-action/actions/generate-questions-action.service';
import { PlanOfActionGeneratorService } from '../../../plan-of-action/services/plan-of-action-generator.service';

@DwComponent({
  key: 'plan-of-action-section',
  name: 'Plan of Action Section',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.PlanOfActionSection,
  isGlobal: false
})
@Component({
  selector: 'app-plan-of-action-section',
  templateUrl: './plan-of-action-section.component.html',
  styleUrls: ['./plan-of-action-section.component.scss']
})
export class PlanOfActionSectionComponent extends DwSectionBaseComponent {
  static expandedSections : { [prop:number]: boolean } = {};
  
  formApi: DwMetaDataFormApi;
  planOfAction: PlanOfActionEntity;
  planOfActionSection: PlanOfActionSectionEntity;

  

  acsCodes: AcsCodesStorageItem;
  collapsed: boolean = true;

  showAcsCode: boolean = true;
  showQuestion: boolean = true;

  rootFormGroup: any;
  hideLabels: boolean = false;
  aosLookupItem: DwLookupInfo;
  taskLookupItem: DwLookupInfo;

  planOfActionSectionQuestionFC: FormControl;

  planOfActionSectionQuestions: PlanOfActionSectionQuestionEntity[] = [];

  containerOptions: DwContainerOptions;
  sectionTypes: PlanOfActionSectionTypeEntity[];
  sectionTitle: string;
  currentAcsCodes: AcsCodesLookupQueryEntity[];
  sectionType: PlanOfActionSectionTypeEntity;
  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private planOfActionRepositoryService: PlanOfActionRepositoryService,
    private generateQuestionsActionService: GenerateQuestionsActionService,
    private planofActionGeneratorService: PlanOfActionGeneratorService,
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);

    this.formApi = this.dwMetaDataFormStateService.state.formApi;
  }

  ngOnInit(): void {
    this.loadReferenceData();
    this.setupDataWatch();

    console.log('Section created');
  }

  addQuestion() {
    this.planOfActionSectionQuestions.push({
      _itemName: AppMetaDataItemNames.PlanOfActionSectionQuestion,
      PlanOfActionSectionQuestionId: this.getNextQuestionId(),
    });

    this.onQuestionsChange();
  }

  nextKeyValue = 0;

  getNextQuestionId(): number {
    // Need to determine what keys values have been used
    if (this.planOfActionSectionQuestions.length == 0) {
      this.nextKeyValue--;

      return this.nextKeyValue;
    }

    let minKeyValue = 0;

    for (let question of this.planOfActionSectionQuestions) {
      const keyValue = question.PlanOfActionSectionQuestionId;

      if (keyValue < minKeyValue) {
        minKeyValue = keyValue;
      }
    };

    this.nextKeyValue = minKeyValue - 1;

    return this.nextKeyValue;
  }

  addAcsCodes() {
    this.generateQuestionsActionService.showAcsCodeSelectionModal(this.planOfAction, this.planOfActionSection)
      .subscribe(modalResult => {
        if (modalResult.cancelled) {
          modalResult.cancelled = true;

          return;
        }

        if (modalResult.data) {
          this.planofActionGeneratorService.generateQuestions(this.planOfActionSection, this.planOfActionSectionQuestions, modalResult.data.GenerateCodesOptions);

          this.planOfActionSection.PlanOfActionSectionQuestion = this.planOfActionSectionQuestions;
          this.onQuestionsChange();
        }
      });
  }

  onQuestionChange(question: PlanOfActionSectionQuestionEntity) {
    this.onQuestionsChange();
    //this.planOfActionSectionQuestionFC.setValue(this.planOfActionSectionQuestions);
  }

  onQuestionsChange() {
    for (let i = 0; i < this.planOfActionSectionQuestions.length; i++) {
      this.planOfActionSectionQuestions[i].QuestionOrder = i + 1;
    }

    this.planOfActionSectionQuestionFC.setValue(this.planOfActionSectionQuestions);
  }

  loadReferenceData() {
    this.planOfActionRepositoryService.getPlanOfActionSectionTypes()
      .subscribe(sectionTypes => {
        this.sectionTypes = sectionTypes;

        this.setupContainer();
      });
  }

  setupDataWatch() {
    this.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (this.formApi.isDataLoadComplete) {
          this.checkForDataChange();
        }
      });

    if (this.formApi.isDataLoadComplete) {
      this.checkForDataChange();
    }
  }

  private checkForDataChange() {
    this.planOfAction = this.formApi.getFormData<PlanOfActionEntity>();

    const planOfActionSection = this.formGroup.getRawValue();

    this.planOfActionSection = this.planOfAction.PlanOfActionSection.find(x => x.PlanOfActionSectionId == planOfActionSection.PlanOfActionSectionId);

    this.setupSection();
    this.lookupAosAndTask();
    
    this.setupAcsCodes();
    this.setupContainer();
  }

  setupSection() {
    if (this.planOfActionSectionQuestionFC) {
      return;
    }

    this.planOfActionSectionQuestions = sortArrayByField(this.planOfActionSection.PlanOfActionSectionQuestion || [], 'QuestionOrder');

    this.planOfActionSectionQuestionFC = new FormControl(this.planOfActionSectionQuestions);

    this.formGroup.addControl('PlanOfActionSectionQuestion', this.planOfActionSectionQuestionFC);
  }

  lookupAosAndTask() {
    if (this.planOfActionSection?.AcsAreaOfOperationId && this.aosLookupItem?.Id != this.planOfActionSection?.AcsAreaOfOperationId?.toString()) {
      this.dwMetaDataService.getLookupItem(AppMetaDataLookups.AcsAreaOfOperationAllItems, this.planOfActionSection?.AcsAreaOfOperationId)
        .subscribe(aosLookupItem => {
          this.aosLookupItem = aosLookupItem;
          this.setupContainer();
        })
    }

    if (this.planOfActionSection?.AcsAreaOfOperationTaskId && this.taskLookupItem?.Id != this.planOfActionSection?.AcsAreaOfOperationTaskId?.toString()) {
      this.dwMetaDataService.getLookupItem(AppMetaDataLookups.AcsAreaOfOperationTaskAllItems, this.planOfActionSection?.AcsAreaOfOperationTaskId)
        .subscribe(taskLookupItem => {
          this.taskLookupItem = taskLookupItem;
          this.setupContainer();
        })
    }
  }

  private setupContainer() {
    this.sectionType = this.sectionTypes?.find(x => x.PlanOfActionSectionTypeId == this.planOfActionSection?.PlanOfActionSectionTypeId);


    console.log('setupContainer');
    if (!this.sectionType) {
      return;
    }

    this.showQuestion = this.planOfAction.OptionsShowQuestionForAcsCode;

    switch (this.planOfActionSection.PlanOfActionSectionTypeId) {
      case PlanOfActionSectionTypeAllItems.AreaOfOperationGround:
      case PlanOfActionSectionTypeAllItems.AreaOfOperationFlight:
        this.sectionTitle = `${this.aosLookupItem?.Display}`;
        this.showAcsCode = true;
        break;
      case PlanOfActionSectionTypeAllItems.TaskGround:
      case PlanOfActionSectionTypeAllItems.TaskFlight:
        this.sectionTitle = `${this.aosLookupItem?.Display} - ${this.taskLookupItem?.Display}`;
        this.showAcsCode = true;
        break;
      default:
        this.sectionTitle = this.planOfActionSection.SectionName;
        this.showAcsCode = false;
        this.showQuestion = true;
    }

    this.containerOptions = {
      useContainerStyling: true,
      styleName: 'TITLE_ONLY_LINE',
      // expandCollapseStyle: DwExpandCollapseStyle.chevron,
      // expandCollpaseLocation: DwExpandCollapseLocation.left,
      containerAllowCollapse: true,
      //containerCollapseDefault: true,
      containerHeaderForegroundColor: this.sectionType.AcsTestPortion.HeaderClass,
      containerHeaderIcon: this.sectionType.AcsTestPortion.IconClass,
      showTitle: true,
    }
  }


  private setupAcsCodes() {
    if (!this.planOfAction || !this.planOfActionSection) {
      return;
    }

    if (this.acsCodes) {
      return;
    }

    if (!this.planOfActionSection.AcsAreaOfOperationId) {
      return;
    }

    //console.log(`getting acs codes - start  ${QuestionEditControlComponent.count}`);
    this.planOfActionRepositoryService.getAcsCodes(this.planOfAction.AcsTestId, this.planOfActionSection.AcsAreaOfOperationId, this.planOfActionSection.AcsAreaOfOperationTaskId)
      .subscribe(acsCodes => {
        //  console.log(`getting acs codes - end ${QuestionEditControlComponent.count}`);
        this.acsCodes = acsCodes;

        const blankCode: AcsCodesLookupQueryEntity = {
          _itemName: '',
          AcsCodeId: null,
          AcsCode: ' ',
        };

        this.currentAcsCodes = [blankCode, ...acsCodes.acsCodes];

        this.setupContainer();
      })
  }

}
