import { Component, OnInit } from '@angular/core';
import { DwComponent, DwComponentType, DwSecurityUserRegistration, DwUserRegistrationExtensionBase, getParameterByName, sortArrayByField } from '@devwareapps/devware-cap';
import { PilexosUserType, PilexosUserTypeIds, convertUserType } from '../../../shared/models/pilexos-user-type.enum';
import { Router } from '@angular/router';
import { PilexosHomePageRepository } from '../../services/pilexos-home-page-repository.service';
import { DwUserTypePilexosEntity } from '../../../../meta-data/extensions/dw-user-type-entity.model';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';

@DwComponent({
  key: 'pilexos-user-registration-extension',
  name: 'Pilexos User Registration Component',
  isGlobal: false,
  componentType: DwComponentType.pageComponent
})
@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent extends DwUserRegistrationExtensionBase {
  userType: PilexosUserTypeIds;
  userTypes: DwUserTypePilexosEntity[];
  dmsNumber: string;

  pilotExaminerUserType = PilexosUserTypeIds.pilotExaminer;

  constructor(private router: Router,
    private homePageRepository: PilexosHomePageRepository,
    private examinerRepositoryService: ExaminerRepositoryService) {
    super();
  }

  setupComponent() {

    const userTypeMeaning = getParameterByName(this.router, 'type');

    if (!isNaN(parseInt(userTypeMeaning))) {
      this.userType = parseInt(userTypeMeaning);
    } else {
      this.userType = convertUserType(userTypeMeaning);
    }

    if (!this.userType) {
      this.userType = PilexosUserTypeIds.studentApplicant;
    }

    this.updateUserType();

    this.homePageRepository.getUserTypes()
      .subscribe(userTypes => {
        this.userTypes = sortArrayByField(userTypes.filter(ut => ut.ShowOnRegistration), 'UserTypeOrder');
      });
  }

  customValalidation(userRegistration: DwSecurityUserRegistration, error: string[]) {
    if (!this.userType) {
      error.push('User type is required');
    }

    if (this.userType == PilexosUserTypeIds.pilotExaminer && !this.dmsNumber) {
      error.push('DMS number is required');
    }
  }

  updateUserType() {
    this.data.additionalUserFields[0] = {
      FieldName: 'UserTypeId',
      FieldValue: this.userType.toString()
    };

    const preferredPilotExaminer = this.examinerRepositoryService.getReferredExaminer();

    this.data.additionalData[0] = {
      DataKey: 'ReferredPilotExaminerId',
      DataValue: preferredPilotExaminer?.PilotExaminerId?.toString()
    };

    this.dataChange.emit(this.data);
  }

  updateDMS() {
    this.data.additionalData[0] = {
      DataKey: 'AuthorityExaminerNumber',
      DataValue: this.dmsNumber
    };

    this.dataChange.emit(this.data);
  }

}
