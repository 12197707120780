<dw-container [options]="containerOptions" [(collapsed)]="collapsed" (collapsedChange)="collapsed=$event"
    *ngIf="containerOptions">
    <span header>
        {{sectionTitle}}
    </span>
    <div body>
        <div *ngIf="planOfActionSection.PlanOfActionSectionQuestion.length === 0">
            <div class="alert alert-info">No questions found for this section</div>
        </div>
        <ng-container *ngFor="let question of planOfActionSection.PlanOfActionSectionQuestion">
            <app-plan-of-action-execute-question [planOfAction]="planOfAction" [question]="question"
                [acsCodes]="acsCodes" [showAcsCode]="showAcsCode" [result]="resultMapping[question.PlanOfActionSectionQuestionId]"
                (resultChange)="updateResult($event)"></app-plan-of-action-execute-question>
        </ng-container>
    </div>
</dw-container>