import { Injectable } from '@angular/core';
import { DevwareMetaData, DwAppPermissionEntity, DwAppPermissionMetaData, DwContextDefinition, DwCustomContextBase, DwCustomContextDefinition, DwOrmDataService, DwQuery, DwQueryHelper, DwSecurityTopics, DwSecurityUserService } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames, PilotExaminerEntity, StudentApplicantEntity } from '../../../../meta-data/app-meta-data.service';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';


export const CurrentStudentContext= 'currentStudentContext';
@DwCustomContextDefinition({
    keyName: CurrentStudentContext,
    display: 'Current Student Context',
    isGlobal: true,
})
@Injectable()
export class CurrentStudentContextService extends DwCustomContextBase {

    constructor(private dwSecurityUserService: DwSecurityUserService,
        private examinerRepositoryService: ExaminerRepositoryService
        
        ) {
        super();
    }

    setupContextDefinition(contextDef: DwContextDefinition) {
        contextDef.buildQueryFunction = (context) => this.buildQuery(context);

        contextDef.item = AppMetaDataItemNames.StudentApplicant;
        contextDef.metaDataQueryId = 1019;
        
        contextDef.cacheInvalidationTopics = [DwSecurityTopics.SECURITY_CHANGED];

        contextDef.cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.StudentApplicant.ItemDetail.itemName));
        contextDef.cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.DwUser.ItemDetail.itemName));

        contextDef.getCustomContextFunction = (resolvedContext, contextData: StudentApplicantEntity) => {
            const referredPilotExaminer = this.examinerRepositoryService.getReferredExaminer();

            if (referredPilotExaminer && contextData?.ReferredPilotExaminerId !== referredPilotExaminer.PilotExaminerId) {
                contextData.ReferredPilotExaminerId = referredPilotExaminer.PilotExaminerId;

                this.examinerRepositoryService.updateStudentReferredExaminer(contextData.StudentApplicantId, referredPilotExaminer.PilotExaminerId);

                console.log('Overriding Student Context with referred pilot examiner id', contextData.ReferredPilotExaminerId);
            }

            return contextData;
        }
    }

    buildQuery(resolvedContext: any): DwQuery {
        const query = AppMetaData.StudentApplicant.CreateQueryBuilder();

        query.query.FieldSettings.LoadAllLookupDisplayFields = true;

        const user = this.dwSecurityUserService.securityContext?.ApplicationUser;

        query.addFilterAnd(filters => filters.Equal(f => f.UserId, user?.UserId || -1));

        return query.query;
    }

}
