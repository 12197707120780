import { Component, EventEmitter, Inject, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DwComponent, DwComponentType, DwContainerOptions, DwContext, DwExpressionService, DwFormActionInterceptResult, DwFormActionInterceptor, DwFormActionKeys, DwFormContextMode, DwMessage, DwMessageSeverity, DwMetaDataFormApi, DwMetaDataFormConfig, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwSectionBasePreference, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService, formatDateTimeFromLocal } from '@devwareapps/devware-cap';
import { Observable, Subscription, of } from 'rxjs';

import { AppMetaData, AppMetaDataItemNames, ApplicantPlaceholderEntity, CheckRideOutcomeAllItems, CheckRideRequestEntity, CheckRideRequestSubStatusAllItems, CheckRideResultEntity, CheckRideScenarioEntity, PilotExaminerLocationEntity, PilotExaminerSearchQueryEntity, StudentApplicantEntity } from '../../../../meta-data/app-meta-data.service';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { ApplicantCheckrideVariables } from '../../models/applicant-checkride-variables.model';
import { ApplicantRepositoryService } from '../../services/applicant-repository.service';
import { CheckCalendarAvailabilityRequestDTO } from '../../../examiner/models/check-calendar-availability-request.model';
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';
import { CurrentStudentContext } from 'src/app/features/shared/contexts/current-student-context/current-student-context.service';
import { CheckCalendarAvailabilityResultDTO } from '../../../examiner/models/check-calendar-availability-result.model';
import { map } from 'rxjs/operators';

@DwComponent({
  key: 'applicant-check-ride-util',
  name: ' Applicant Check Ride Util',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.CheckRideRequest,
  isGlobal: false
})
@Component({
  selector: 'app-applicant-check-ride-util',
  template: `
  `
})
export class ApplicantCheckRideUtilComponent extends DwSectionBaseComponent {
  checkRideRequest: CheckRideRequestEntity;

  originalCheckRideRequest: CheckRideRequestEntity;

  rebookSetupComplete = false;
  formApi: DwMetaDataFormApi;
  applicantDataUpdated: boolean;
  applicantCheckride: CheckRideRequestEntity;
  examinerScheduleRechecked: boolean;
  activeCheckrideChecked: boolean;

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private examinerRepositoryService: ExaminerRepositoryService,
    private applicantRepositoryService: ApplicantRepositoryService,
    private dateTimeUtilService: DateTimeUtilService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);

    this.formApi = this.dwMetaDataFormStateService.state.formApi;
  }

  ngOnInit(): void {
    this.setupDataWatch();

    this.setupFormActionInterceptor()
  }

  formActionInterceptor: DwFormActionInterceptor

  setupFormActionInterceptor() {
    this.formActionInterceptor = {
      interceptorName: 'applicantCheckRideUrl',
      order: 10,
      onBeforeAction: (actionPref, actionConfig) => {
        const result: DwFormActionInterceptResult = {
          abort: false
        }

        if (actionPref.actionKey != DwFormActionKeys.formWorkflow) {
          return of(result);
        }

        return this.validateScheduleOnSave();
        
      }
    }

    // Register the action interceptor with the form
    this.dwMetaDataFormStateService.addActionInterceptor(this.formActionInterceptor);
  }

  validateScheduleOnSave(): Observable<DwFormActionInterceptResult> {
    const result: DwFormActionInterceptResult = {
      abort: false,
    }
    if (!this.originalCheckRideRequest) {

      return of(result);
    }

    if (this.formApi.getFormConfig().formMode != DwFormContextMode.edit) {
      return of(result);
    }

    if (this.originalCheckRideRequest?.CheckRideRequestSubStatusId != CheckRideRequestSubStatusAllItems.Draft &&
      this.originalCheckRideRequest?.CheckRideRequestSubStatusId != CheckRideRequestSubStatusAllItems.PendingPayment &&
      this.originalCheckRideRequest?.CheckRideRequestSubStatusId != CheckRideRequestSubStatusAllItems.Rejected
    ) {
      return of(result);
    }

    return this.checkCalendarAvailability()
        .pipe(map(availability => {
          if (availability.HasConflicts) {
            result.abort = true;
            result.message = 'The scheduled date/time is no longer available on the Examiners calendar. Please select a new date/time.';

            // Recheck the schedule since it's now no longer avaialble
            setTimeout(() => {
              this.examinerScheduleRechecked = false;
              this.recheckExaminerSchedule();
            },200);
          }

          return result;
        }));
  }

  setupDataWatch() {
    this.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (this.formApi.isDataLoadComplete) {
          this.checkForDataChange();
        }
      });

    if (this.formApi.isDataLoadComplete) {
      this.checkForDataChange();
    }
  }

  private checkForDataChange() {

    const checkRideRequest = this.formApi.getFormData<CheckRideRequestEntity>();

    this.checkRideRequest = checkRideRequest;

    if (!this.originalCheckRideRequest) {
      this.originalCheckRideRequest = checkRideRequest;
    }

    this.fillInMissingDefaults();

    this.recheckExaminerSchedule();

    this.checkForActiveCheckrides();

    this.setupRebook();
  }

  fillInMissingDefaults() {
    if (!this.checkRideRequest.StudentApplicantId) {
      console.log('Missing Student Application Id, populating');

      const studentContext = this.context[CurrentStudentContext]?.value as StudentApplicantEntity;

      if (studentContext?.StudentApplicantId) {
        const checkrideRequest: Partial<CheckRideRequestEntity> = {
          StudentApplicantId: studentContext?.StudentApplicantId,
          AviationAuthorityId: studentContext?.AviationAuthorityId
        };

        this.formApi.patchFormData(checkrideRequest);
      }

    }
  }

  checkForActiveCheckrides() {
    if (this.activeCheckrideChecked || !this.checkRideRequest) {
      return;
    }

    this.activeCheckrideChecked = true;

    if (this.dwMetaDataFormStateService.state.formContext.contextMode != DwFormContextMode.add) {
      return;
    }

    //if (!this.checkRideRequest.CheckRideRequestId)

    this.applicantRepositoryService.getActiveCheckrides(this.checkRideRequest.CheckRideRequestId)
      .subscribe(activeCheckrides => {
        const variables: ApplicantCheckrideVariables = {};

        variables.Active_Checkrides_Exists = activeCheckrides.length > 0;

        this.formApi.patchVariableContext(variables);
      });
  }

  recheckExaminerSchedule() {
    if (this.examinerScheduleRechecked || !this.checkRideRequest) {
      return;
    }

    this.examinerScheduleRechecked = true;


    if (this.formApi.getFormConfig().formMode != DwFormContextMode.edit) {
      return;
    }

    if (this.checkRideRequest?.CheckRideRequestSubStatusId == CheckRideRequestSubStatusAllItems.Draft ||
      this.checkRideRequest?.CheckRideRequestSubStatusId == CheckRideRequestSubStatusAllItems.PendingPayment ||
      this.checkRideRequest?.CheckRideRequestSubStatusId == CheckRideRequestSubStatusAllItems.Rejected
    ) {

      this.checkCalendarAvailability()
        .subscribe(availability => {
          if (availability.HasConflicts) {
            const checkrideRequest: Partial<CheckRideRequestEntity> = {
              PreferredDateTime: this.dateTimeUtilService.getCurrentDateTime(),
            };

            this.formApi.patchFormData(checkrideRequest);

            const variables = this.formApi.getVariableContextValue<ApplicantCheckrideVariables>();

            if (this.checkRideRequest?.CheckRideRequestSubStatusId != CheckRideRequestSubStatusAllItems.Rejected) {
              variables.Show_Search = true;
              variables.Preferred_Pilot_Examiner_Id = this.checkRideRequest.ScheduledPilotExaminerId;
            }

            variables.Checkride_Date_No_Longer_Valid = true;

            this.formApi.patchVariableContext(variables);
          }
        });
    }
  }

  checkCalendarAvailability(): Observable<CheckCalendarAvailabilityResultDTO> {
    if (!this.checkRideRequest) {
      return of(null);
    }

    //this.examinerScheduleRechecked = true;

    const request: CheckCalendarAvailabilityRequestDTO = {
      CheckRideDateTime: this.checkRideRequest.ScheduledDateTime,
      DurationMinutes: this.checkRideRequest.ScheduledTimeMinutes,
      PilotExaminerId: this.checkRideRequest.ScheduledPilotExaminerId,
      CheckRideRequestId: this.checkRideRequest.CheckRideRequestId
    };

    return this.examinerRepositoryService.checkCalendarAvailability(request);

    // .subscribe(availability => {
    //   if (availability.HasConflicts) {
    //     const checkrideRequest: Partial<CheckRideRequestEntity> = {
    //       PreferredDateTime : this.dateTimeUtilService.getCurrentDateTime(),
    //     };

    //     this.formApi.patchFormData(checkrideRequest);

    //     const variables = this.formApi.getVariableContextValue<ApplicantCheckrideVariables>();

    //     if (this.checkRideRequest?.CheckRideRequestSubStatusId != CheckRideRequestSubStatusAllItems.Rejected) {
    //       variables.Show_Search = true;
    //       variables.Preferred_Pilot_Examiner_Id = this.checkRideRequest.ScheduledPilotExaminerId;
    //     }

    //     variables.Checkride_Date_No_Longer_Valid = true;

    //     this.formApi.patchVariableContext(variables);
    //   }
    // });

  }

  setupRebook() {
    // We want to wait until the defaults have been loaded
    if (!this.dwMetaDataFormStateService.state.formContext.defaultsProcessed) {
      return;
    }

    const variables = this.formApi.getVariableContextValue<ApplicantCheckrideVariables>();

    if (!variables?.RebookCheckrideId) {
      return;
    }

    if (!variables.Rebook_Pilot_Examiner_Id) {
      this.rebookSetupComplete = false;
    }

    if (this.rebookSetupComplete) {
      return;
    }

    this.rebookSetupComplete = true;

    // Use the loaded checkride if it is already loaded
    let ob = of(this.applicantCheckride);

    if (!this.applicantCheckride) {
      ob = this.applicantRepositoryService.getApplicantCheckrideRequest(variables.RebookCheckrideId)
    }

    ob.subscribe(checkride => {
      this.applicantCheckride = checkride;
      // Wait a half second to make sure the form is loaded
      setTimeout(() => {
        this.updateCheckride(checkride);
      });
    });
  }

  updateCheckride(checkride: CheckRideRequestEntity) {
    if (!checkride) {
      return;
    }

    const updatedCheckride = this.examinerRepositoryService.prepareCheckrideForCopy(checkride);

    let waivePayment = this.examinerRepositoryService.getWaiveCheckridePaymentOnRebook(checkride);

    // Set the preferred pilot examiner to the same as before
    const variables: ApplicantCheckrideVariables = {
      Preferred_Pilot_Examiner_Id: checkride.ScheduledPilotExaminerId,
      Pilexos_Payment_Waived: waivePayment,
      Examiner_Rebook_Cost: checkride.CheckRideResult?.RetestCost,
      Rebook_Pilot_Examiner_Id: checkride.ScheduledPilotExaminerId,
      Show_Search: true
    };

    this.formApi.patchVariableContext(variables);

    updatedCheckride.PilexosBookingPaymentAdded = waivePayment;

    // Clear scheduled fields
    updatedCheckride.ScheduledDateTime = null;
    updatedCheckride.ScheduledPilotExaminerId = null;
    updatedCheckride.ExaminerBookingCost = checkride.ExaminerBookingCost;

    this.formApi.patchFormData(updatedCheckride);
  }

}

