import { Injectable } from '@angular/core';
import { DevwareMetaData, DwAppPermissionEntity, DwAppPermissionMetaData, DwContextDefinition, DwCustomContextBase, DwCustomContextDefinition, DwOrmDataService, DwQuery, DwQueryHelper, DwSecurityTopics, DwSecurityUserService } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames } from '../../../../meta-data/app-meta-data.service';



export const CurrentPilotExaminerContext= 'currentPilotExaminerContext';
@DwCustomContextDefinition({
    keyName: CurrentPilotExaminerContext,
    display: 'Current Pilot Examiner Context',
    isGlobal: true,
})
@Injectable()
export class CurrentPilotExaminerContextService extends DwCustomContextBase {

    constructor(private dwSecurityUserService: DwSecurityUserService,
        
        ) {
        super();
    }

    setupContextDefinition(contextDef: DwContextDefinition) {
        contextDef.buildQueryFunction = (context) => this.buildQuery(context);

        contextDef.item = AppMetaDataItemNames.PilotExaminer;
        //contextDef.metaDataQueryId = 1019;

        contextDef.cacheInvalidationTopics = [DwSecurityTopics.SECURITY_CHANGED];

        contextDef.cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.PilotExaminer.ItemDetail.itemName));
        contextDef.cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.DwUser.ItemDetail.itemName));
    }

    buildQuery(resolvedContext: any): DwQuery {
        const query = AppMetaData.PilotExaminer.CreateQueryBuilder();

        if (!this.dwSecurityUserService.securityContext?.ApplicationUser) {
            return null;
        }
   
        query.query.FieldSettings.LoadAllLookupDisplayFields = true;
        
        const user = this.dwSecurityUserService.securityContext.ApplicationUser;

        query.addFilterAnd(filters => filters.Equal(f => f.UserId, user?.UserId || -1));

        return query.query;

    }


}
