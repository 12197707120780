import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DwMenu, DwMenuService, DwMetaDataRoutingNavigationService, DwSecurityUserService, DwUser, DwMenuActionType, DwMenuCategory, DwControlType, DwSecurityOrchestrationService, DwActionExecutionService, DwActionState } from '@devwareapps/devware-cap';
import { Subscription } from 'rxjs/internal/Subscription';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PilexosUser } from '../../../shared/models/pilexos-user.model';
import { PilexosUserTypeIds } from '../../../shared/models/pilexos-user-type.enum';


@Component({
  selector: 'app-authenticated-home-view',
  templateUrl: './authenticated-home-view.component.html',
  styleUrls: ['./authenticated-home-view.component.scss']
})
export class AuthenticatedHomeViewComponent implements OnInit, OnDestroy {
  user: DwUser;
  textboxControlType: DwControlType = DwControlType.Textbox;
  pilexosUser: PilexosUser;

  adminView: string = 'examiner';
  readonly PilexosUserTypeIds = PilexosUserTypeIds;

  constructor(private router: Router,
    private dwMenuService: DwMenuService,
    private dwSecurityUserService: DwSecurityUserService,
    private dwMetaDataRoutingNavigationService: DwMetaDataRoutingNavigationService,
    private dwSecurityOrchestrationService: DwSecurityOrchestrationService,
    private dwActionExecutionService: DwActionExecutionService
  ) { 

    //this.pilexosUser.UserTypeId == PilexosUserTypeIds.pilotExaminer
  }



  subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.dwSecurityUserService.securityContextChanged().subscribe((result) => {
      this.setupSecurity();
    });

    this.setupSecurity();

  }

  ngOnDestroy(): void {
    // unsubscribe from all 
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }


  private setupSecurity() {
    if (!this.dwSecurityUserService.securityState.isAuthenticated || this.dwSecurityUserService.securityState.isAuthenticating) {
      return;
    }

    this.pilexosUser = new PilexosUser(this.dwSecurityUserService.securityContext.ApplicationUser);
  }


}
