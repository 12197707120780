import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AcsCodesLookupQueryEntity, AppMetaData, AppMetaDataLookups, PlanOfActionEntity, PlanOfActionQuestionResultEntity, PlanOfActionSectionQuestionEntity } from '../../../../meta-data/app-meta-data.service';
import { AcsCodesStorageItem } from '../../models/acs-code-items.model';

@Component({
  selector: 'app-plan-of-action-execute-question',
  templateUrl: './plan-of-action-execute-question.component.html',
  styleUrls: ['./plan-of-action-execute-question.component.scss']
})
export class PlanOfActionExecuteQuestionComponent implements OnInit, OnChanges {
  @Input() question: PlanOfActionSectionQuestionEntity;
  @Input() result: PlanOfActionQuestionResultEntity;
  @Output() resultChange = new EventEmitter<PlanOfActionQuestionResultEntity>();

  @Input() planOfAction: PlanOfActionEntity;
  @Input() acsCodes: AcsCodesStorageItem;
  @Input() showAcsCode: boolean;
  acsCode: AcsCodesLookupQueryEntity;
  showQuestion: boolean;
  showNotes: boolean;

  questionClass : string;
  constructor() { }

  outcomeLookupId = AppMetaDataLookups.QuestionOutcomeAllItems;

  ngOnInit(): void {
    this.setupQuestion();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.setupQuestion();
  }

  setupQuestion() {
    if (this.result == null) {
      this.result = AppMetaData.PlanOfActionQuestionResult.CreateEntity();
      this.result.PlanOfActionSectionQuestionId = this.question?.PlanOfActionSectionQuestionId;
    }

    if (this.acsCodes && this.question?.AcsCodeId && this.question.AcsCodeId != this.acsCode?.AcsCodeId) {
      this.acsCode = this.acsCodes.codesById[this.question.AcsCodeId];
    }

    this.showQuestion = !this.showAcsCode || (this.planOfAction.OptionsShowQuestionForAcsCode && !!this.question?.QuestionHtml);

    this.showNotes = this.showAcsCode;

    this.questionClass = this.showAcsCode ? '' : 'basic-question';
  }

  onChange() {
    this.resultChange.emit(this.result);
  }

} 
 