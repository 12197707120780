import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class CalendarAuthConsentService {
    windowObjectReference = null;
    previousUrl = null;


    openSignInWindow(url, name): Observable<string> {
        const subject = new Subject<string>();

        let returnComplete = false;
        (window as any).cap = {
            oAuthCallback: (code) => {
                returnComplete = true;
                subject.next(code);
            }
        }

        const popupWindow = window.open(url, name,  'toolbar=no, menubar=no, width=600, height=700, top=100, left=100');

        let interval = setInterval(() => {
            if (popupWindow?.closed) {
                clearInterval(interval);
                if (!returnComplete) {
                    subject.next(null);
                }
            }
        }, 1000);

        // popupWindow.window.addEventListener('load', () => {
        //     popupWindow.window.addEventListener('unload', () => {
        //         console.log('Auth window closed');
        //         if (!returnComplete) {
        //             subject.next(null);
        //         }
        //     });
        // });

        // popupWindow.window.addEventListener('unload', () => {
        //     console.log('Auth window closed');
        //     if (!returnComplete) {
        //         subject.next(null);
        //     }
        // });


        // popupWindow.addEventListener('onBeforeClose', (event) => {
        //     console.log('Auth window closed');
        //     if (!returnComplete) {
        //         subject.next(null);
        //     }
        // });

        return subject.asObservable();
    }

    openSignInWindowOld(url, name) {
        const receiveMessage = (message) => {
            //console.log('calendarAUthConsentService Message received from popup', message);
        }

        // remove any existing event listeners
        window.removeEventListener('message', receiveMessage);

        // window features
        const strWindowFeatures =
            'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

        if (this.windowObjectReference === null || this.windowObjectReference.closed) {
            /* if the pointer to the window object in memory does not exist
             or if such pointer exists but the window was closed */
             this.windowObjectReference = window.open(url, name, strWindowFeatures);
        } else if (this.previousUrl !== url) {
            /* if the resource to load is different,
             then we load it in the already opened secondary window and then
             we bring such window back on top/in front of its parent window. */
            this.windowObjectReference = window.open(url, name, strWindowFeatures);
            this.windowObjectReference.focus();
        } else {
            /* else the window reference must exist and the window
             is not closed; therefore, we can bring it back on top of any other
             window with the focus() method. There would be no need to re-create
             the window or to reload the referenced resource. */
            this.windowObjectReference.focus();
        }

        // add the listener for receiving a message from the popup
        window.addEventListener('message', event => receiveMessage(event), false);
        // assign the previous URL
        this.previousUrl = url;
    };
}