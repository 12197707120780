import { Inject, Injectable } from "@angular/core";
import { Observable, combineLatest, of } from "rxjs";
import { catchError, map, mergeMap } from 'rxjs/operators'
import { AirportEntity, AppMetaData, AppMetaDataItemNames, ApplicantPlaceholderEntity, CalendarReferenceEntity, CheckRideGroupAllItems, CheckRideOutcomeAllItems, CheckRideRequestCalendarQueryEntity, CheckRideRequestEntity, CheckRideRequestPaymentPartyAllItems, CheckRideRequestPaymentPartyEntity, CheckRideRequestSubStatusAllItems, CheckRideScenarioEntity, CheckRideTestTypeAllItems, CheckRideTypeAllItems, PilotExaminerEntity, PilotExaminerLocationEntity, PilotExaminerSearchQueryEntity, PilotExaminerStatusAllItems, StudentApplicantEntity } from "../../../meta-data/app-meta-data.service";
import { DwMetaDataServiceToken, DwMetaDataService, DwOrmDataService, DwOrmDataServiceToken, DwQuery, deepCopy, DwConfigService, DwConfigServiceToken, DwLookupInfo, SuppressErrorMessagesHeader, DwSecurityUserService, DwQueryMetaData, DwCacheService, DwSecurityTopics, DwQueryFilter } from "@devwareapps/devware-cap";
import { ExaminerLocationSearchRequest } from "../models/examiner-location-search-request.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CheckrideReferenceInfo } from "../models/checkride-reschedule-info.model";
import { AirportMetar } from "../models/airport-metar.model";
import { WeatherBasicMetarDTO } from "../models/weather-basic-metar.model";
import { CheckCalendarAvailabilityRequestDTO } from "../models/check-calendar-availability-request.model";
import { CheckCalendarAvailabilityResultDTO } from "../models/check-calendar-availability-result.model";
import { ApplicantCheckrideVariables } from "../../applicant/models/applicant-checkride-variables.model";
import { PilotExaminerSearchQueryEntityExt } from "../../../meta-data/extensions/pilot-examiner-search-query-ext.model";

@Injectable({ providedIn: 'root' })
export class ExaminerRepositoryService {




    apiRoot: string;
    private static checkrideRescheduleInfo: Map<number, CheckrideReferenceInfo> = new Map<number, CheckrideReferenceInfo>();

    private static referredPilotExaminer: PilotExaminerEntity = null;

    constructor(
        private http: HttpClient,
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        @Inject(DwConfigServiceToken) configService: DwConfigService,
        private dwSecurityUserService: DwSecurityUserService,
        private dwCacheService: DwCacheService,
    ) {
        this.apiRoot = configService.coreConfig.apiRoot;
    }

    storeReferredExaminer(pilotExaminer: PilotExaminerEntity) {
        ExaminerRepositoryService.referredPilotExaminer = pilotExaminer;

        this.dwSecurityUserService.securityContextChanged().subscribe(() => {

        });
    }

    getReferredExaminer() {
        return ExaminerRepositoryService.referredPilotExaminer;
    }


    updateStudentReferredExaminer(studentApplicantId: number, pilotExaminerId: number) {
        if (!studentApplicantId) {
            return;
        }

        const studentApplicant: Partial<StudentApplicantEntity> =
            this.dwOrmDataService.createDummyEntity(AppMetaData.StudentApplicant.ItemDetail.itemName, {
                StudentApplicantId: studentApplicantId,
                ReferredPilotExaminerId: pilotExaminerId
            });

        this.dwOrmDataService.saveEntity(studentApplicant)
            .subscribe(applicant => {

            });
    }

    prepareCheckrideForCopy(checkrideRequest: CheckRideRequestEntity): CheckRideRequestEntity {
        const newCheckRide = deepCopy(checkrideRequest);

        newCheckRide.CheckRideRequestId = null;

        newCheckRide.ScheduledDateTime = null;

        newCheckRide.RejectedNotes = null;
        newCheckRide.ProposedDateTime = null;
        newCheckRide.ProposedAirportId = null;
        newCheckRide.ProposedTimeMinutes = null;
        newCheckRide.MoreInfoRequestedNotes = null;
        newCheckRide.CancellationNotes = null;
        newCheckRide.CheckRideCancellationTypeId = null;
        newCheckRide.CheckRideCancellationTypeOther = null;
        newCheckRide.ScheduledCalendarEventReferenceId = null;
        newCheckRide.PilexosBookingCheckRidePaymentId = null;
        newCheckRide.ProposedCalendarEventReferenceId = null;
        newCheckRide.AddToWaitlist = false;
        newCheckRide.PreferredAirportId = newCheckRide.ScheduledAirportId;

        delete newCheckRide.PreferredDateTime;
        delete newCheckRide.PreferredDateTime;
        delete newCheckRide.CheckRidePayment;
        delete newCheckRide.CheckRideRequestSubStatusId;
        delete newCheckRide.ExaminerBookingCost;
        delete newCheckRide.ScheduledCheckRideCost;
        delete newCheckRide.AdditionalTravelCost;
        delete newCheckRide.CheckRideResult;
        delete newCheckRide.CheckRidePayment;

        newCheckRide.PilexosBookingPaymentAdded = false;

        newCheckRide.CheckRideTestTypeId = this.getRebookTestTypeId(checkrideRequest);

        return newCheckRide;
    }

    getRebookTestTypeId(checkrideRequest: CheckRideRequestEntity): number {
        switch (checkrideRequest.CheckRideResult?.CheckRideOutcomeId) {
            case CheckRideOutcomeAllItems.Discontinued:
                if (checkrideRequest.CheckRideResult?.GroundPortionComplete) {
                    return CheckRideTestTypeAllItems.ContinuanceFlightOnly;
                }
                return CheckRideTestTypeAllItems.Continuance;

            case CheckRideOutcomeAllItems.Disapproved:
                if (checkrideRequest.CheckRideResult?.GroundPortionComplete) {
                    return CheckRideTestTypeAllItems.RetestFlightOnly;
                }
                return CheckRideTestTypeAllItems.Retest;
        }

        return null;
    }


    getWaiveCheckridePaymentOnRebook(checkrideRequest: CheckRideRequestEntity): boolean {
        if (checkrideRequest.CheckRideResult?.CheckRideOutcomeId == CheckRideOutcomeAllItems.Discontinued && checkrideRequest.CheckRideResult?.AllowRetest) {

            if (!checkrideRequest.PilexosBookingPaymentAdded) {
                return false;
            }

            return true;
        }

        return false;
    }

    getApplicantPlaceholder(ApplicantPlaceholderId: number): Observable<ApplicantPlaceholderEntity> {
        const query = AppMetaData.ApplicantPlaceholder.CreateQueryBuilder();

        query.addFilterAnd(filters => filters.Equal(field => field.ApplicantPlaceholderId, ApplicantPlaceholderId));

        const subQuery = AppMetaData.CheckRideRequest.CreateQueryBuilder();

        subQuery.addPrefetch(r => r.CheckRideResult);
        subQuery.addPrefetch(r => r.CheckRidePayment);

        subQuery.addOrderByDesc(f => f.ScheduledDateTime);
        subQuery.setupPaging(1, 1);

        query.addPrefetch(r => r.CheckRideRequest, subQuery.query);

        return this.dwOrmDataService.executeQuerySingle(query);
    }

    getRebookCheckrideRequest(rebookCheckrideId: number): Observable<CheckRideRequestEntity> {
        const checkRideQuery = AppMetaData.CheckRideRequest.CreateQueryBuilder();

        checkRideQuery.addFilterAnd(filters => filters.Equal(field => field.CheckRideRequestId, rebookCheckrideId));

        checkRideQuery.addRelation(r => r.PilotExaminerByScheduledPilotExaminerId);

        checkRideQuery.addFilterAnd(filters => filters.Equal(field => AppMetaData.PilotExaminer.Attributes.UserId, this.dwSecurityUserService.securityContext.ApplicationUser?.UserId));

        // Check the result as well
        checkRideQuery.addPrefetch(r => r.CheckRideResult);
        checkRideQuery.addPrefetch(r => r.CheckRidePayment);

        return this.dwOrmDataService.executeQuerySingle(checkRideQuery);
    }


    getCurrentPilotExaminer(): Observable<PilotExaminerEntity> {

        const cacheInvalidationTopics = [DwSecurityTopics.SECURITY_CHANGED];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.PilotExaminer.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.DwUser.ItemDetail.itemName));

        return this.dwCacheService.get('currentPilotExaminer', this.loadCurrentPilotExaminer(), null, cacheInvalidationTopics);
    }

    private loadCurrentPilotExaminer(): Observable<PilotExaminerEntity> {
        const query = AppMetaData.PilotExaminer.CreateQueryBuilder();

        const user = this.dwSecurityUserService.securityContext.ApplicationUser;

        query.addFilterAnd(filters => filters.Equal(f => f.UserId, user?.UserId || -1));

        return this.dwOrmDataService.executeQuerySingle(query);
    }

    getExaminerProfile(pilotExaminerId: number, includeCalendarRef: boolean = false): Observable<PilotExaminerEntity> {
        const query = AppMetaData.PilotExaminer.CreateQueryBuilder();

        query.addFilterAnd(filters => filters.Equal(field => field.PilotExaminerId, pilotExaminerId));

        return this.dwOrmDataService.executeQuerySingle(query);
    }

    getExaminerIdByProfileKey(examinerKey: string): Observable<PilotExaminerEntity> {

        return this.dwMetaDataService.getSavedQueryMetaData(AppMetaData.PilotExaminer.ItemDetail.itemName, null, 'Pilot_Examiner_Public_Profile')
            .pipe(mergeMap(queryMetaData => {
                return this.getExaminerIdByProfileKeyWithMetaData(queryMetaData, examinerKey);
            }));
    }


    getCurrentCheckRideScenario(checkRideScenarioId: number): Observable<CheckRideScenarioEntity> {
        return this.getCurrentCheckRideScenarios()
            .pipe(map(scenarios => {
                return scenarios.find(s => s.CheckRideScenarioId == checkRideScenarioId);
            }));
    }

    getCurrentCheckRideScenarios() {
        const cacheInvalidationTopics = [DwSecurityTopics.SECURITY_CHANGED];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.CheckRideScenario.ItemDetail.itemName));

        return this.dwCacheService.get('CurrentCheckRideScenarios', this.loadCurrentCheckRideScenarios(), null, cacheInvalidationTopics);
    }

    private loadCurrentCheckRideScenarios(): Observable<CheckRideScenarioEntity[]> {
        const query = AppMetaData.CheckRideScenario.CreateQueryBuilder();

        query.addRelation(r => r.PilotExaminer);

        const user = this.dwSecurityUserService.securityContext.ApplicationUser;

        query.addFilterAnd(f => f.Equal(a => AppMetaData.PilotExaminer.Attributes.UserId.fieldNameWithItemPrefix, user.UserId));

        query.addFilterAnd(f => f.IsTrue(a => a.IsActive, true));
        query.addPrefetch(r => r.CheckRideScenarioTypeMap);

        return this.dwOrmDataService.executeQuery(query);
    }

    private getExaminerIdByProfileKeyWithMetaData(queryMetaData: DwQueryMetaData, examinerKey: string): Observable<PilotExaminerEntity> {
        const query = AppMetaData.PilotExaminer.CreateQueryBuilder(queryMetaData.DataQuery);

        if (!examinerKey) {
            return of(null);
        }

        query.addFilterAnd(f => f.Equal(a => a.PublicProfileSlug, examinerKey));


        const checkRideTypeMapQuery = AppMetaData.CheckRideScenarioTypeMap.CreateQueryBuilder();

        checkRideTypeMapQuery.query.FieldSettings.LoadAllLookupDisplayFields = true;

        const checkRideScenarioQuery = AppMetaData.CheckRideScenario.CreateQueryBuilder();

        checkRideScenarioQuery.addPrefetch(r => r.CheckRideScenarioTypeMap, checkRideTypeMapQuery.query);

        checkRideScenarioQuery.addFilterAnd(f => f.Equal(a => a.IsActive, true));

        query.query.FieldSettings.LoadAllLookupDisplayFields = false;

        query.addPrefetch(r => r.CheckRideScenario, checkRideScenarioQuery.query);

        const airportQuery = AppMetaData.PilotExaminerLocationAirport.CreateQueryBuilder();

        airportQuery.query.FieldSettings.LoadAllLookupDisplayFields = true;
        const locationQuery = AppMetaData.PilotExaminerLocation.CreateQueryBuilder();

        locationQuery.addFilterAnd(f => f.Equal(a => a.IsActive, true));
        locationQuery.addPrefetch(r => r.PilotExaminerLocationAirport, airportQuery.query);

        query.addPrefetch(r => r.PilotExaminerLocation, locationQuery.query);

        return this.dwOrmDataService.executeQuerySingle(query);
    }

    public setCheckrideReferenceInfo(checkrideId: number, info: CheckrideReferenceInfo) {
        ExaminerRepositoryService.checkrideRescheduleInfo.set(checkrideId, info);
    }

    public getCheckrideReferenceInfo(checkrideId: number = -1) {
        return ExaminerRepositoryService.checkrideRescheduleInfo.get(checkrideId);
    }

    public clearCheckrideReferenceInfo(checkrideId: number = -1) {
        return ExaminerRepositoryService.checkrideRescheduleInfo.delete(checkrideId);
    }

    public getAirports(airportIds: number[]): Observable<AirportEntity[]> {
        var query = AppMetaData.Airport.CreateQueryBuilder();

        query.addFilterAnd(filters => filters.InList(a => a.AirportId, airportIds));

        query.addPrefetch(r => r.AddressByAirportAddressId);

        return this.dwOrmDataService.executeQuery(query);
    }

    getCurrentCalendarRef(): Observable<CalendarReferenceEntity> {
        const query = AppMetaData.PilotExaminer.CreateQueryBuilder();

        const userId = this.dwSecurityUserService.securityContext.ApplicationUser?.UserId || 0;

        query.addFilterAnd(filters => filters.Equal(field => AppMetaData.PilotExaminer.Attributes.UserId, userId));

        query.addPrefetch(r => r.CalendarReference);

        return this.dwOrmDataService.executeQuerySingle(query)
            .pipe(map(pilotExaminer => {
                return pilotExaminer?.CalendarReference;
            }));
    }

    getCheckRideRequestQueryMetaData(): Observable<DwQueryMetaData> {
        return this.dwMetaDataService.getSavedQueryMetaData(AppMetaData.PilotExaminer.ItemDetail.itemName, null, 'Check_Ride_Request-Calendar');
    }

    getCheckRidesRequests(queryMetaData: DwQueryMetaData, startDate: string, endDate: string): Observable<CheckRideRequestCalendarQueryEntity[]> {
        const prebuiltQuery = this.cloneQuery(queryMetaData);

        const query = AppMetaData.CheckRideRequest.CreateQueryBuilder(prebuiltQuery);

        const userId = this.dwSecurityUserService.securityContext.ApplicationUser?.UserId || 0;

        // We need to use both the Schedule Date Time and the Proposed Date Time (when the checkride is in proposed status)
        query.addFilterAnd(f =>
            f.GroupOr(f =>
                f.GroupAnd(
                    f => this.setExactTimeCompareValue(f.GreaterThanEqual(f2 => f2.ScheduledDateTime, startDate), false),
                    f => this.setExactTimeCompareValue(f.LessThanEqual(f2 => f2.ScheduledDateTime, endDate), false)
                ), f =>
                f.GroupAnd(
                    f => this.setExactTimeCompareValue(f.GreaterThanEqual(f2 => f2.ProposedDateTime, startDate), false),
                    f => this.setExactTimeCompareValue(f.LessThanEqual(f2 => f2.ProposedDateTime, endDate), false),
                    f => f.Equal(a => a.CheckRideRequestSubStatusId, CheckRideRequestSubStatusAllItems.NewDateProposedByExaminer),
                )
            )
        );
        // query.addFilterAnd(f => this.setExactTimeCompareValue(f.GreaterThanEqual(f2 => f2.ScheduledDateTime, startDate), false));
        // query.addFilterAnd(f => this.setExactTimeCompareValue(f.LessThanEqual(f2 => f2.ScheduledDateTime, endDate), false));

        // query.addFilterAnd(f => this.setExactTimeCompareValue(f.GreaterThanEqual(f2 => f2.ScheduledDateTime, startDate), false));
        // query.addFilterAnd(f => this.setExactTimeCompareValue(f.LessThanEqual(f2 => f2.ScheduledDateTime, endDate), false));

        query.addFilterAnd(filters => filters.Equal(field => AppMetaData.PilotExaminer.Attributes.UserId, userId));

        // This is added in the query so it's easy to maintain
        //query.addFilterAnd(fb => fb.InList(f => f.CheckRideRequestSubStatusId, [CheckRideRequestSubStatusAllItems.PendingApproval, CheckRideRequestSubStatusAllItems.PendingOutcome, CheckRideRequestSubStatusAllItems.NewDateProposedByExaminer, CheckRideRequestSubStatusAllItems.PendingPayment]));

        return this.dwOrmDataService.executeQuery<CheckRideRequestCalendarQueryEntity>(query);
    }

    private setExactTimeCompareValue(filter: DwQueryFilter, value: boolean): DwQueryFilter {
        filter.ExactDateTimeCompare = value;

        return filter;
    }

    getCheckRidesRequestsOld(startDate: string, endDate: string): Observable<CheckRideRequestEntity[]> {
        const query = AppMetaData.CheckRideRequest.CreateQueryBuilder();

        query.getFieldSettings().LoadAllLookupDisplayFields = true;

        const userId = this.dwSecurityUserService.securityContext.ApplicationUser?.UserId || 0;

        query.addRelation(r => r.PilotExaminerByScheduledPilotExaminerId);

        query.addFilterAnd(f => f.GreaterThanEqual(f2 => f2.ScheduledDateTime, startDate));
        query.addFilterAnd(f => f.LessThanEqual(f2 => f2.ScheduledDateTime, endDate));

        query.addFilterAnd(filters => filters.Equal(field => AppMetaData.PilotExaminer.Attributes.UserId, userId));

        return this.dwOrmDataService.executeQuery(query);
    }

    getExaminerAndLocationInfo(pilotExaminerId: number, airportId: number): Observable<PilotExaminerSearchQueryEntity> {

        // Load the Pilot_Examiner_Search_Query as a starting point, then add our own filters based on the search Request
        return this.dwMetaDataService.getSavedQueryMetaData(AppMetaData.PilotExaminer.ItemDetail.itemName, null, 'Pilot_Examiner_Search')
            .pipe(mergeMap(queryMetaData => {
                const prebuiltQuery = this.cloneQuery(queryMetaData);

                const query = AppMetaData.PilotExaminer.CreateQueryBuilder(prebuiltQuery);


                query.addFilterAnd(filters => filters.Equal(field => field.PilotExaminerId, pilotExaminerId));

                // Add filters by location
                const roLocationDistanceFields = AppMetaData.RoPilotExaminerLocationDistances.Attributes;

                // Set Reference to match the prefered airport (this is used to calc the distance from the preferred airport and any DPE airports)
                query.addFilterAnd(f => f.Equal(f2 => roLocationDistanceFields.ReferenceAirportId, airportId));
                query.addFilterAnd(f => f.Equal(f2 => roLocationDistanceFields.AirportId, airportId));

                return this.dwOrmDataService.executeQuerySingle<PilotExaminerSearchQueryEntity>(query);
            }));
    }

    /**
    * Search Pilot Examiners Scenarios & Locations to find possible matches
    * @param searchRequest 
    * @returns  
    */
    searchExaminerByLocation(searchRequest: ExaminerLocationSearchRequest): Observable<PilotExaminerSearchQueryEntityExt[]> {
        let url = `${this.apiRoot}/pilexos/search-examiners`;

        let headers = new HttpHeaders();

        //headers = headers.append(SuppressErrorMessagesHeader, 'true');

        return this.http.post<PilotExaminerSearchQueryEntityExt[]>(url, searchRequest, { headers: headers });
    }

    DEFAULT_BOOKING_COST = 50;

    getPilexosBookingCost(checkrideTestTypeId: number, partyType: CheckRideRequestPaymentPartyAllItems, items: CheckRideRequestPaymentPartyEntity[]): number {
        const party = items.find(i => i.CheckRideRequestPaymentPartyId == partyType);

        if (checkrideTestTypeId == CheckRideTestTypeAllItems.Retest && (party?.PilexosBookingRetestCost || party?.PilexosBookingRetestCost == 0)) {
            return party?.PilexosBookingRetestCost;
        }

        return party?.PilexosBookingCost || this.DEFAULT_BOOKING_COST;
    }

    getCheckRideRequestPaymentParty(): Observable<CheckRideRequestPaymentPartyEntity[]> {
        const cacheInvalidationTopics = [];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.CheckRideRequestPaymentParty.ItemDetail.itemName));

        return this.dwCacheService.get('CheckRideRequestPaymentParty', this.loadCheckRideRequestPaymentParty(), null, cacheInvalidationTopics);
    }

    private loadCheckRideRequestPaymentParty(): Observable<CheckRideRequestPaymentPartyEntity[]> {
        var query = AppMetaData.CheckRideRequestPaymentParty.CreateQueryBuilder();

        return this.dwOrmDataService.executeQuery(query);
    }

    /**
    * Check Pilot Examiners Scenarios & Locations to find possible matches
    * @param searchRequest 
    * @returns  
    */
    checkCalendarAvailability(request: CheckCalendarAvailabilityRequestDTO): Observable<CheckCalendarAvailabilityResultDTO> {
        let url = `${this.apiRoot}/pilexos/check-calendar-availability`;

        let headers = new HttpHeaders();

        // headers = headers.append(SuppressErrorMessagesHeader, 'true');

        return this.http.post<CheckCalendarAvailabilityResultDTO>(url, request, { headers: headers });
    }

    /**
     * Search Pilot Examiners Scenarios & Locations to find possible matches
     * @param searchRequest 
     * @returns  
     */
    searchExaminerByLocationOnClient(searchRequest: ExaminerLocationSearchRequest): Observable<PilotExaminerSearchQueryEntity[]> {

        // Load the Pilot_Examiner_Search_Query as a starting point, then add our own filters based on the search Request
        return this.dwMetaDataService.getSavedQueryMetaData(AppMetaData.PilotExaminer.ItemDetail.itemName, null, 'Pilot_Examiner_Search')
            .pipe(mergeMap(queryMetaData => {
                const finalQuery = this.buildSearchQuery(queryMetaData, searchRequest);

                return this.dwOrmDataService.executeQuery(finalQuery);
            }));
    }

    private buildSearchQuery(queryMetaData: DwQueryMetaData, searchRequest: ExaminerLocationSearchRequest): DwQuery {

        const prebuiltQuery = this.cloneQuery(queryMetaData)
        const query = AppMetaData.PilotExaminer.CreateQueryBuilder(prebuiltQuery);

        if (searchRequest.preferredExaminerId) {
            query.addFilterAnd(filters => filters.Equal(field => field.PilotExaminerId, searchRequest.preferredExaminerId));
        }

        // Add Basic Pilot Examiner Search fields
        query.addFilterAnd(f => f.Equal(f2 => f2.AviationAuthorityId, searchRequest.aviationAuthorityId));

        // Add filters for Scenarios items
        const roScenarioFields = AppMetaData.RoPilotExaminerScenario.Attributes;

        query.addFilterAnd(f => f.Equal(f2 => roScenarioFields.CheckRideTypeId, searchRequest.checkRideTypeId));


        // Add filters by location
        const roLocationDistanceFields = AppMetaData.RoPilotExaminerLocationDistances.Attributes;

        // Set Reference to match the prefered airport (this is used to calc the distance from the preferred airport and any DPE airports)
        query.addFilterAnd(f => f.Equal(f2 => roLocationDistanceFields.ReferenceAirportId, searchRequest.preferredAirportId));

        if (!searchRequest.searchOtherAirports) {
            query.addFilterAnd(f => f.LessThanEqual(f2 => roLocationDistanceFields.Distance, 0));
        } else {
            query.addFilterAnd(f => f.LessThanEqual(f2 => roLocationDistanceFields.Distance, searchRequest.searchWithinMiles));
        }

        query.addOrderBy(f => roLocationDistanceFields.Distance);

        return query.query;
    }

    private cloneQuery(queryMetaData: DwQueryMetaData): DwQuery {
        const newQuery = new DwQuery(queryMetaData.DataQuery.RootTable);

        Object.assign(newQuery, deepCopy(queryMetaData.DataQuery));

        (newQuery as any).BaseQueryMeaning = queryMetaData.QueryMeaning;

        return newQuery;
    }

    searchExaminerByLocationCustom(searchRequest: ExaminerLocationSearchRequest): Observable<PilotExaminerSearchQueryEntity[]> {
        const query = AppMetaData.PilotExaminer.CreateQueryBuilder();

        if (searchRequest.preferredExaminerId) {
            query.addFilterAnd(filters => filters.Equal(field => field.PilotExaminerId, searchRequest.preferredExaminerId));
        }

        // Add Relations (joins to limit the data for the search)
        query.addRelation(r => r.RoPilotExaminerLocationDistances);
        query.addRelation(r => r.RoPilotExaminerScenario);

        // Add a prefetch
        query.addPrefetch(r => r.DwUser);


        // Add Basic Pilot Examiner Search fields
        query.addFilterAnd(f => f.Equal(f2 => f2.AviationAuthorityId, searchRequest.aviationAuthorityId));

        query.addFilterAnd(f => f.Equal(f2 => f2.PilotExaminerStatusId, PilotExaminerStatusAllItems.VerifiedLive));

        // Add filters for Scenarios items
        const roScenarioFields = AppMetaData.RoPilotExaminerScenario.Attributes;

        query.addFilterAnd(f => f.Equal(f2 => roScenarioFields.CheckRideGroupId, searchRequest.checkRideGroupId));
        query.addFilterAnd(f => f.Equal(f2 => roScenarioFields.CheckRideTypeId, searchRequest.checkRideTypeId));


        // Add filters by location
        const roLocationDistanceFields = AppMetaData.RoPilotExaminerLocationDistances.Attributes;

        // Set Reference to match the prefered airport (this is used to calc the distance from the preferred airport and any DPE airports)
        query.addFilterAnd(f => f.Equal(f2 => roLocationDistanceFields.ReferenceAirportId, searchRequest.preferredAirportId));

        if (!searchRequest.searchOtherAirports) {
            query.addFilterAnd(f => f.LessThanEqual(f2 => roLocationDistanceFields.Distance, 0));
        } else {
            query.addFilterAnd(f => f.LessThanEqual(f2 => roLocationDistanceFields.Distance, searchRequest.searchWithinMiles));
        }

        query.addOrderBy(f => roLocationDistanceFields.Distance);

        return this.dwOrmDataService.executeQuery<PilotExaminerSearchQueryEntity>(query);
    }

    searchExaminerByLocationOldOLD(searchRequest: ExaminerLocationSearchRequest): Observable<PilotExaminerLocationEntity[]> {
        const query = AppMetaData.PilotExaminerLocation.CreateQueryBuilder();

        if (searchRequest.preferredExaminerId) {
            query.addFilterAnd(filters => filters.Equal(field => field.PilotExaminerId, searchRequest.preferredExaminerId));
        }

        query.addRelation(r => r.PilotExaminer);

        query.addPrefetch(r => r.PilotExaminer, this.buildPilotExaminerQuery());


        // query.addFilterAnd(filters => filters.GreaterThanEqual(f=>f.SchedulingStartDate, searchRequest.preferredDateTime));

        return this.dwOrmDataService.executeQuery(query);
    }


    getCurrentPiloxExaminerStats(): Observable<PilotExaminerEntity> {
        const query = AppMetaData.PilotExaminer.CreateQueryBuilder();

        query.addFilterAnd(filters => filters.Equal(field => field.UserId, this.dwSecurityUserService.securityContext.ApplicationUser?.UserId));

        query.query.FieldSettings.IncludeRootItemFields = false;

        query.addFields(f => [f.PilotExaminerId, f.UserId, f.PilotExaminerStatusId, f.AviationAuthorityId, f.AuthorityExaminerNumber, f.Description]);

        // Build user query
        var userQuery = AppMetaData.DwUser.CreateQueryBuilder();

        userQuery.query.FieldSettings.IncludeRootItemFields = false;

        userQuery.addFields(f => [f.FirstName, f.LastName, f.UserPictureMediaId]);

        query.addPrefetch(r => r.DwUser, userQuery.query);
        query.addPrefetch(r => r.CalendarReference);

        query.addPrefetch(r => r.PilotExaminerLocation, AppMetaData.PilotExaminerLocation.CreateQueryBuilder().addFields(f => [f.IsActive, f.PilotExaminerId]).query);
        query.addPrefetch(r => r.CheckRideScenario, AppMetaData.CheckRideScenario.CreateQueryBuilder().addFields(f => [f.IsActive, f.PilotExaminerId]).query);

        // Build checkride query
        const checkRideQuery = AppMetaData.CheckRideRequest.CreateQueryBuilder();

        checkRideQuery.query.FieldSettings.IncludeRootItemFields = false;

        checkRideQuery.addFields(f => [f.ScheduledPilotExaminerId, f.CheckRideRequestSubStatusId, f.StudentApplicantId, f.ScheduledDateTime]);

        // We want 
        checkRideQuery.addFilterOr(filters => filters.GroupOr(
            fb => fb.GroupAnd(
                fb => fb.DateFilters.GreaterThanDays(f => f.ScheduledDateTime, -1),
                fb => fb.DateFilters.LessThanEqualDays(f => f.ScheduledDateTime, 14),
            ),
            fb => fb.InList(f => f.CheckRideRequestSubStatusId, [CheckRideRequestSubStatusAllItems.PendingApproval, CheckRideRequestSubStatusAllItems.PendingOutcome, CheckRideRequestSubStatusAllItems.NewDateProposedByExaminer, CheckRideRequestSubStatusAllItems.PendingPayment]),
        ));

        query.addPrefetch(r => r.CheckRideRequestByScheduledPilotExaminerId, checkRideQuery.query);

        return this.dwOrmDataService.executeQuerySingle(query);
    }

    private buildPilotExaminerQuery(): DwQuery {
        const query = AppMetaData.PilotExaminer.CreateQueryBuilder();

        //query.addFilterAnd(filters => filters.Equal(field => field.PilotExaminerId, pilotExaminerId));

        query.addPrefetch(r => r.DwUser);

        return query.query;
    }


    //return 
    // }

    // getCheckRidesRequests(queryMetaData: DwQueryMetaData, startDate: string, endDate: string): Observable<CheckRideRequestCalendarQueryEntity[]> {
    //     const prebuiltQuery = this.cloneQuery(queryMetaData.DataQuery);

    //     const query = AppMetaData.CheckRideRequest.CreateQueryBuilder(prebuiltQuery);

    public getPilotExaminerCurrentAirports(pilotExaminerId: number): Observable<AirportEntity[]> {
        return this.dwMetaDataService.getSavedQueryMetaData(AppMetaDataItemNames.Airport, null, 'Pilot_Examiner_Current_Airports')
            .pipe(mergeMap(queryMetaData => {
                const prebuiltQuery = this.cloneQuery(queryMetaData);

                var query = AppMetaData.Airport.CreateQueryBuilder(prebuiltQuery);

                query.addFilterAnd(filters => filters.Equal(field => 'PilotExaminerLocationShowMetar', true));

                query.addFilterAnd(filters => filters.Equal(field => AppMetaData.PilotExaminer.Attributes.PilotExaminerId, pilotExaminerId));

                return this.dwOrmDataService.executeQuery(query);
            }));
    }

    public getCurrentExaminerMetarData(pilotExaminerId: number, maxAirports: number = 5): Observable<AirportMetar[]> {

        return this.getPilotExaminerCurrentAirports(pilotExaminerId)
            .pipe(mergeMap(airports => {
                const obs: Observable<AirportMetar>[] = [];

                if (airports.length == 0) {
                    return of([])
                }

                if (airports.length > maxAirports) {
                    airports.length = maxAirports;
                }


                return this.getAirportMetars(airports);
            }));
    }

    getAirportMetars(airports: AirportEntity[]): Observable<AirportMetar[]> {
        const apiParams = airports.map(airport => `${airport.AirportCode}`).join(',');
        const url = `${this.apiRoot}/pilexos/weather/airports/metars?airportCodes=${apiParams}`;

        let headers = new HttpHeaders();

        headers = headers.append(SuppressErrorMessagesHeader, 'true');

        return this.http.get<WeatherBasicMetarDTO[]>(url, { headers: headers })
            .pipe(map((serverMetars) => {
                const metars: AirportMetar[] = [];

                for (var metar of serverMetars) {
                    var airport = airports.find(a => a.AirportCode == metar.AirportCode);

                    metars.push({
                        airport: airport,
                        metar: metar.Metar
                    });
                }
                return metars;
            }),
                catchError(err => {
                    console.error('Error loading Metar data', err);

                    return of([]);
                }));
    }


    getFullBookingCost(result: PilotExaminerSearchQueryEntityExt, testType: CheckRideTestTypeAllItems, applicantCheckrideVariables: ApplicantCheckrideVariables): number {
        let bookingCost = result.CheckRideScenarioCheckRideCost || 0;

        // Override the booking cost if the examiner is the same as the rebook examiner and they provided a rebooking cost
        if (applicantCheckrideVariables?.Rebook_Pilot_Examiner_Id == result.PilotExaminerId) {
            if (applicantCheckrideVariables?.Examiner_Rebook_Cost) {
                bookingCost = applicantCheckrideVariables.Examiner_Rebook_Cost;
                return bookingCost;
            }
        }

        switch (testType) {
            case CheckRideTestTypeAllItems.Retest:
                if (result.CheckRideScenarioRetestCost) {
                    bookingCost = result.CheckRideScenarioRetestCost;
                }

                break;
            case CheckRideTestTypeAllItems.RetestFlightOnly:
                if (result.CheckRideScenarioRetestFlightOnlyCost) {
                    bookingCost = result.CheckRideScenarioRetestFlightOnlyCost;
                } else if (result.CheckRideScenarioRetestCost) {
                    bookingCost = result.CheckRideScenarioRetestCost;
                }

                break;
            case CheckRideTestTypeAllItems.Continuance:
                if (result.CheckRideScenarioDiscontinuedRetestCost) {
                    bookingCost = result.CheckRideScenarioDiscontinuedRetestCost;
                }

                break;
            case CheckRideTestTypeAllItems.ContinuanceFlightOnly:
                if (result.CheckRideScenarioDiscontinuedRetestFlightOnlyCost) {
                    bookingCost = result.CheckRideScenarioDiscontinuedRetestFlightOnlyCost;
                } else if (result.CheckRideScenarioDiscontinuedRetestCost) {
                    bookingCost = result.CheckRideScenarioDiscontinuedRetestCost;
                }
                break;
        }

        if (bookingCost == 0) {
            bookingCost = result.CheckRideScenarioCheckRideCost || 0;
        }

        if (result.PilotExaminerLocationAdditionalLocationCost) {
            bookingCost += result.PilotExaminerLocationAdditionalLocationCost;
        }

        // Handle travel costs adjustments
        if (result.PilotExaminerLocationTravelCost) {
            if (result.AdditionalResultDetails?.RequiresTravel) {
                if (result.PilotExaminerLocationTravelCost > 0) {
                    bookingCost += result.PilotExaminerLocationTravelCost;
                }
            } else {
                if (result.PilotExaminerLocationTravelCost < 0) {
                    bookingCost += result.PilotExaminerLocationTravelCost;
                }
            }
        }

        return bookingCost;
    }


    getCheckrideTimeMinutes(result: PilotExaminerSearchQueryEntityExt, testType: CheckRideTestTypeAllItems) {

        // This logic moved to the server
        return result.CheckrideDurationMinutes;

        // let timeMinutes = result.CheckRideScenarioTimeRequiredMinutes || 0;

        // switch (testType) {
        //     case CheckRideTestTypeAllItems.RetestFlightOnly:
        //     case CheckRideTestTypeAllItems.ContinuanceFlightOnly:
        //         if (result.CheckRideScenarioRetestFlightOnlyTimeReqirementMinutes) {
        //             timeMinutes = result.CheckRideScenarioRetestFlightOnlyTimeReqirementMinutes;
        //         }
        //         break;
        // }

        // return timeMinutes;
    }

    getCheckrideTimeMinutesFromScenario(testType: CheckRideTestTypeAllItems, checkrideScenario: CheckRideScenarioEntity): number {
        let timeMinutes = checkrideScenario.TimeRequiredMinutes;

        let overrideTimeMinutes: number = 0;

        switch (testType) {
            case CheckRideTestTypeAllItems.Retest:
                overrideTimeMinutes = checkrideScenario.RetestTimeRequiredMinutes;
                break;
            case CheckRideTestTypeAllItems.RetestFlightOnly:
                overrideTimeMinutes = checkrideScenario.RetestFlightOnlyTimeReqirementMinutes;
                break;
            case CheckRideTestTypeAllItems.Continuance:
                overrideTimeMinutes = checkrideScenario.DiscontinuedTimeRequiredMinutes;
                break;
            case CheckRideTestTypeAllItems.ContinuanceFlightOnly:
                overrideTimeMinutes = checkrideScenario.DiscontinuedFlightOnlyTimeRequiredMinutes;
                break;
        }

        if (overrideTimeMinutes) {
            return overrideTimeMinutes;
        }

        return timeMinutes;
    }

    translateDateTime(dateTime: string, timezoneId): Observable<string> {
        const url = `${this.apiRoot}/pilexos/time/translate?dateTime=${dateTime}&timezoneId=${timezoneId}`;

        return this.http.get<string>(url);
    }

}
