import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentChargeRequest } from '../../models/payment-charge-request.model';
import { environment } from '../../../../../environments/environment';
import { AppMetaDataLookups, CheckRideRequestPaymentPartyAllItems } from '../../../../meta-data/app-meta-data.service';
import { PaymentRepositoryService } from '../../services/payment-repository.service';
import { PaymentDiscountResult } from '../../models/payment-discount-result.model';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit {
  showAddTestCardInfo: boolean = false;
  @Input() chargeRequest: PaymentChargeRequest;
  @Output() chargeRequestChange = new EventEmitter<PaymentChargeRequest>();

  @Input() additionalMessage: string;
  @Input() showPaymentParty: boolean = false;

  payWithApplicantCard = false;
  
  years: string[] = [];

  paymentPartyLookupId = AppMetaDataLookups.CheckRideRequestPaymentPartyAllItems;

  @Input() paymentDiscountResult: PaymentDiscountResult;
  @Output() paymentDiscountResultChange = new EventEmitter<PaymentDiscountResult>();

  constructor(private paymentRespotioryService: PaymentRepositoryService,) { }

  ngOnInit(): void {
    this.setupYears();

    this.showAddTestCardInfo = environment.production === false;
  }

  changePartyType() {
    this.chargeRequest.paymentPartyId = this.payWithApplicantCard ? CheckRideRequestPaymentPartyAllItems.Applicant : CheckRideRequestPaymentPartyAllItems.PilotExaminer;
  }

  applyDiscountCode() {
    this.paymentRespotioryService.applyDiscountCode(this.chargeRequest.paymentDiscountCode, this.chargeRequest.amount)
    .subscribe(result => {
      this.paymentDiscountResult = result;

      this.paymentDiscountResultChange.emit(result);
    });
  }

  onChange() {
    this.chargeRequestChange.emit(this.chargeRequest);
  }

  private setupYears(): void {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 10; i++) {
      years.push((currentYear + i).toString());
    }
    this.years = years;
  }

  addTestCardInfo() {
    this.chargeRequest.cardNumber = '4242424242424242';
    this.chargeRequest.expirationMonth = '12';
    this.chargeRequest.expirationYear = '2025';
    this.chargeRequest.cVC = '123';
    this.chargeRequest.cardholderPostalCode = '12345';
  }
}
