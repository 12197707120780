import { Injectable } from "@angular/core";
import { DwAction, DwActionType, DwActionHandler, DwMdFormInitActionHandler, DwMdFormInitActionState, DwActionResult, DwActionState, DwMdFormActionState, DwGridActionResult, DwMetaDataGridActionState, DwModalButtonStandardConfigs, DwModalConfig, DwModalSize, ensureArray, DwModalService, DwMdFormContextInfo } from "@devwareapps/devware-cap";
import { AppMetaDataItemNames, PlanOfActionEntity } from "../../../meta-data/app-meta-data.service";
import { PaymentRepositoryService } from "../../payments/services/payment-repository.service";
import { Observable, of} from "rxjs";
import { PlanOfActionRepositoryService } from "../services/plan-of-action-repository.service";
import { map } from "rxjs/operators";
import { GenerateSectionsModalComponent } from "../../plan-of-action/components/generate-sections-modal/generate-sections-modal.component";


@DwAction({
    keyName: 'generate-sections-action',
    display: 'Generate Sections',
    actionType: DwActionType.formAction,
    isGlobal: false,
    parentItemName: AppMetaDataItemNames.PlanOfAction,
    allowOnce: true,
    defaultButtonConfig: {
        buttonClass: 'btn btn-warning mr-1 mt-1',
        buttonText: 'Generate Sections',
        iconClass: 'fa fa-tasks-alt'
    },
    configData: {
        requireValidation: false,
        redirectAfter: false,
        actionMessage: '',
    }
})
@Injectable()
export class GenerateSectionsActionService implements DwActionHandler  {

    constructor(private planOfActionRepositoryService: PlanOfActionRepositoryService,
        private dwModalService: DwModalService

    ) {}
    
    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {
        
        const result = new DwActionResult();
        result.cancelled = false;
        result.success = true;

        const planOfAction  = actionState.formApi.getFormData<PlanOfActionEntity>();

        return this.showModal(actionState, planOfAction);
    }


    private showModal(actionState: DwMdFormActionState, planOfAction: PlanOfActionEntity): Observable<DwActionResult> {
        let modalConfig = new DwModalConfig();

        modalConfig.buttons = DwModalButtonStandardConfigs.instance.okAndClose;

        modalConfig.allowFullScreen = true;
        modalConfig.modalSize = DwModalSize.medium;

        modalConfig.component = GenerateSectionsModalComponent;

        modalConfig.title = 'Generate Sections';
        modalConfig.data = planOfAction;

        
        return this.dwModalService.showModal<any,any>(modalConfig)
            .pipe(map(modalResult => {
                const result = new DwActionResult();

                if (result.cancelled) {
                    result.cancelled = true;

                    return result;
                }
                const planOfAction = modalConfig.data;

                if (planOfAction) {
                    
                    // Try to patch the data itself so it will create the form array entries for sections & questions
                    actionState.formApi.patchContextData(DwMdFormContextInfo.DATA_CONTEXT_NAME, planOfAction); 
                    actionState.formApi.formGroup.patchValue(planOfAction);
                    actionState.formApi.setFormData(planOfAction);

                    // Old - wasn't working - might need to research why
                    //actionState.formApi.patchFormData(modalResult.data);
                }

                return result;
            }));
    }
}