<div class="flex-container">
    <div class="top-text-container">
        <div class="top-text">
            Welcome to Pilexos
        </div>
    </div>
    <div class="top-container-body">
        <div class="top-item-container">
            <div class="top-text-container">

            </div>

            <div class="top-item-container-more-padding">
                <ng-container *ngFor="let card of topCards">
                    <app-menu-item-card [cardData]="card"></app-menu-item-card>
                </ng-container>
            </div>
        </div>
    </div>
</div>
