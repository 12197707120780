<ng-container *ngIf="chargeRequest">
    <div dwRow>
        <div dwCol="12">
            <div><span class="label">Pilexos Booking Fee: </span><span>{{chargeRequest?.amount || 50 | currency}}</span>
            </div>
            <div *ngIf="paymentDiscountResult?.IsValid">
                <span class="label">Discount Amount: </span><span>{{ paymentDiscountResult?.FinalDiscountAmount |
                    currency }}</span><br>
                <span class="label">Payment Amount: </span><span>{{ paymentDiscountResult?.FinalAmount |
                    currency}}</span>
            </div>
            <div *ngIf="additionalMessage && !showPaymentParty">
                <span>Payment will be charged when your Examiner accepts the checkride.</span>

                <span>Note: {{additionalMessage}}</span>
            </div>
        </div>
    </div>
    <div dwRow>
        <div dwCol="12">
            <label class="label">Discount Code</label>
            <input type="text" class="form-control" [(ngModel)]="chargeRequest.paymentDiscountCode"
                (ngModelChange)="onChange()" />
            <button (click)="applyDiscountCode()" class="btn btn-primary">Apply</button>

            <div *ngIf="paymentDiscountResult"
                [ngClass]="{ 'dw-red': !paymentDiscountResult.IsValid , 'dw-green': paymentDiscountResult.IsValid }">
                {{paymentDiscountResult.Message}}
            </div>
        </div>
    </div>
    <ng-container *ngIf="paymentDiscountResult?.FinalAmount !== 0">
        <div dwRow *ngIf="showPaymentParty">
            <div dwCol="12">
                <label>
                    <br>
                    <span class="pilexos-checkbox">
                        <input type="checkbox" [(ngModel)]="payWithApplicantCard" (ngModelChange)="changePartyType()">
                        <span>Pay with Applicant Credit Card</span>
                    </span>
                </label>
                <br>
            </div>
        </div>
        <div dwRow>
            <div dwCol="12">
                <label class="label">Cardholder Name</label>
                <input type="text" class="form-control" [(ngModel)]="chargeRequest.cardholderName"
                    (ngModelChange)="onChange()" />
            </div>
        </div>
        <a (click)="addTestCardInfo()" class="dw-link" *ngIf="showAddTestCardInfo"
            title="Add test card information.  This is only available outside of the live site">Add test card info</a>
        <div dwRow>
            <div dwCol="12">
                <label class="label">Credit Card Number</label>
                <input type="text" class="form-control" [(ngModel)]="chargeRequest.cardNumber"
                    (ngModelChange)="onChange()" />
            </div>
        </div>
        <div dwRow>
            <div dwCol="6">
                <label class="label">Expiration Month</label>
                <select class="form-control" [(ngModel)]="chargeRequest.expirationMonth" (ngModelChange)="onChange()">
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select>
            </div>
            <div dwCol="6">
                <label class="label">Expiration Year</label>
                <select class="form-control" [(ngModel)]="chargeRequest.expirationYear" (ngModelChange)="onChange()">
                    <option *ngFor="let year of years" [ngValue]="year">{{year}}</option>
                </select>
            </div>

        </div>
        <div dwRow>
            <div dwCol="6">
                <label class="label">CVC Code</label>
                <input type="text" class="form-control" [(ngModel)]="chargeRequest.cVC" (ngModelChange)="onChange()" />
            </div>

            <div dwCol="6">
                <label class="label">Postal Code</label>
                <input type="text" class="form-control" [(ngModel)]="chargeRequest.cardholderPostalCode"
                    (ngModelChange)="onChange()" />
            </div>
        </div>
        <div dwRow>
            <div dwCol="6">
                <label class="label">CC E-mail Receipt</label>
                <input type="text" class="form-control" [(ngModel)]="chargeRequest.EmailCCReceipt"
                    (ngModelChange)="onChange()" />
            </div>

            <div dwCol="6">

            </div>
        </div>
    </ng-container>
</ng-container>