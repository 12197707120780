<dw-dynamic-routing-init></dw-dynamic-routing-init>

<div class="dw-splash" *ngIf="showSlashScreen">
    <img src="/assets/pilexos/img/pilexos_logo_with_tagline.jpg" class="dw-splash-logo">
    <!-- <div class="dw-splash-center-div">
        <i class="fa fa-spinner fa-pulse fa-fw fa-4x dw-red"></i><span>Loading...</span>
    </div> -->
</div>
<div [hidden]="showSlashScreen">
    <dw-layout *ngIf="showLayout" [bodyClasses]="themeClasses">
        <div logo>

        </div>
        <div breadcrumb>

        </div>
        <div topMenu>
            <div class="alert alert-warning env-message" [hidden]="!environmentMessage"
                style="margin-left:50px; margin-top:12px;">
                {{environmentMessage}}
            </div>
        </div>
    </dw-layout>
</div>