import { Component, EventEmitter, Inject, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DwComponent, DwComponentType, DwContainerOptions, DwContext, DwExpressionService, DwMessage, DwMessageSeverity, DwMetaDataFormConfig, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwSectionBasePreference, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService } from '@devwareapps/devware-cap';
import { Observable, Subscription } from 'rxjs';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { AppMetaData, AppMetaDataItemNames, CheckRideRequestEntity, PilotExaminerLocationEntity, PilotExaminerSearchQueryEntity } from '../../../../meta-data/app-meta-data.service';
import { ExaminerLocationSearchRequest } from '../../../examiner/models/examiner-location-search-request.model';
import { ExaminerLocationSelection } from '../../../examiner/models/exminer-location-selection.model';
import { tap } from 'rxjs/operators';

@DwComponent({
  key: 'examiner-booking-assigned',
  name: 'Examiner Booking Assigment',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.CheckRideRequest,
  isGlobal: false
})

@Component({
  selector: 'app-examiner-booking-assigned',
  templateUrl: './examiner-booking-assigned.component.html',
  styleUrls: ['./examiner-booking-assigned.component.scss']
})
export class ExaminerBookingAssignedComponent extends DwSectionBaseComponent {

  loading: boolean = false;
  loadingMessage: string;

  message: DwMessage = null;

  showSearch: boolean = true;
  searchResults: PilotExaminerSearchQueryEntity[];

  examinerLocationSelection: ExaminerLocationSelection;

  initComplete = false;
  checkRideRequest: CheckRideRequestEntity;

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private examinerRepositoryService: ExaminerRepositoryService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
  }

  setupComponentComplete() {
    this.setupDataWatch();
  }

  setupDataWatch() {
    this.dwMetaDataFormStateService.state.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (!this.searchResults && this.dwMetaDataFormStateService.state.formApi.isDataLoadComplete) {
          this.dataUpdated();
        }
      });

    if (this.dwMetaDataFormStateService.state.formApi.isDataLoadComplete) {
      this.dataUpdated();
    }
  }

  private dataUpdated() {
    this.checkRideRequest = this.dwMetaDataFormStateService.state.formApi.getFormData<CheckRideRequestEntity>();




  }
}
