<ng-container *ngIf="!paymentComplete">
    <dw-message [(message)]="message"></dw-message>

    <app-payment-info [(chargeRequest)]="chargeRequest" [additionalMessage]="additionalMessage" [showPaymentParty]="paymentModalOptions.showPaymentParty" [(paymentDiscountResult)]="paymentDiscountResult"></app-payment-info>
</ng-container>

<dw-loading [show]="processing" [text]="processingMessage"></dw-loading>

<ng-container *ngIf="paymentComplete">
    <span>
        <dw-html-view [ngModel]="paymentModalOptions.paymentCompleteMessage"></dw-html-view>
    </span>
</ng-container>