
import { Component, EventEmitter, Inject, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DwComponent, DwComponentType, DwContainerOptions, DwContext, DwExpressionService, DwMessage, DwMessageSeverity, DwMetaDataFormConfig, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwSectionBasePreference, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService, formatDateTimeFromLocal } from '@devwareapps/devware-cap';
import { Observable, Subject, Subscription } from 'rxjs';
import { ExaminerRepositoryService } from '../../services/examiner-repository.service';
import { AppMetaData, AppMetaDataItemNames, CheckRideRequestEntity, CheckRideRequestSubStatusAllItems, PilotExaminerLocationEntity, PilotExaminerSearchQueryEntity } from '../../../../meta-data/app-meta-data.service';
import { ExaminerLocationSearchRequest } from '../../models/examiner-location-search-request.model';
import { ExaminerLocationSelection } from '../../models/exminer-location-selection.model';
import { debounceTime, tap } from 'rxjs/operators';
import { CheckrideReferenceInfo } from '../../models/checkride-reschedule-info.model';
import { CheckCalendarAvailabilityRequestDTO } from '../../models/check-calendar-availability-request.model';
import { CheckCalendarAvailabilityResultDTO } from '../../models/check-calendar-availability-result.model';
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';

@DwComponent({
  key: 'exminer-calendar-check',
  name: ' Examiner Calendar Check Util',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.CheckRideRequest,
  isGlobal: false
})
@Component({
  selector: 'app-examiner-calendar-check',
  templateUrl: './examiner-calendar-check.component.html',
  styleUrls: ['./examiner-calendar-check.component.scss']
})
export class ExaminerCalendarCheckComponent extends DwSectionBaseComponent {
  checkRideRequest: CheckRideRequestEntity;

  initComplete = false;

  previousDateTime: string;
  previousDuration: number;

  lastCheckDateTime: string;
  lastCheckDuration: number;

  allFields = {
    scheduled: {
      dateField: 'ScheduledDateTime',
      durationField: 'ScheduledTimeMinutes'
    },
    proposed: {
      dateField: 'ProposedDateTime',
      durationField: 'ProposedTimeMinutes',
      alternateDurationField: 'ScheduledTimeMinutes'
    }
  }

  currentFields: {
    dateField: string,
    durationField: string,
    alternateDurationField?: string
  } = this.allFields.scheduled;

  private dateTimeChangedSubject = new Subject<any>();
  checkingAvailability: boolean = false;
  lastCheckResult: CheckCalendarAvailabilityResultDTO;

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private examinerRepositoryService: ExaminerRepositoryService,
    private dateTimeUtilService: DateTimeUtilService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
  }

  ngOnInit(): void {
    this.setupDataWatch();


  }

  setupDataWatch() {
    this.dwMetaDataFormStateService.state.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (this.dwMetaDataFormStateService.state.formApi.isDataLoadComplete) {
          this.checkForDataChange();
        }
      });

    if (this.dwMetaDataFormStateService.state.formApi.isDataLoadComplete) {
      this.checkForDataChange();
    }

    this.dateTimeChangedSubject
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.checkAvaiability();
      });
  }


  checkAvaiability() {
    if (this.checkingAvailability) {
      return;
    }

    if (!this.previousDateTime) {
      return;
    }

    if (this.lastCheckDateTime == this.previousDateTime && this.lastCheckDuration == this.previousDuration) {
      return;
    }

    this.checkingAvailability = true;

    this.lastCheckDateTime = this.previousDateTime;
    this.lastCheckDuration = this.previousDuration;

    const request: CheckCalendarAvailabilityRequestDTO = {
      CheckRideDateTime: this.dateTimeUtilService.convertDateTimeToJson(this.lastCheckDateTime),
      DurationMinutes: this.lastCheckDuration,
      CheckRideRequestId: this.checkRideRequest.CheckRideRequestId,
    }

    this.examinerRepositoryService.checkCalendarAvailability(request)
      .subscribe(
        result => {
          this.lastCheckResult = result;

          this.checkingAvailability = false;
        },
        error => {
          this.checkingAvailability = false;
        });

  }

  private checkForDataChange() {
    const checkRideRequest = this.dwMetaDataFormStateService.state.formApi.getFormData<CheckRideRequestEntity>();

    this.checkRideRequest = checkRideRequest;

    this.checkForScheduleDateTimeChange();
  }

  
  private convertDateTime(preferredDateTime: string): string {
    // if (preferredDateTime.indexOf('T') < 0) {
    //   preferredDateTime += 'T00:00:00';
    // }

    const date = new Date(preferredDateTime);

    //return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    let dateWithTime = date.toJSON();

    return dateWithTime;
  }


  checkForScheduleDateTimeChange() {
    if (!this.checkRideRequest) {
      return;
    }

    const currentDateTime = this.checkRideRequest[this.currentFields.dateField];
    const currentDuration = this.checkRideRequest[this.currentFields.durationField] || (this.currentFields.alternateDurationField ? this.checkRideRequest[this.currentFields.alternateDurationField] : -1);

    if (this.previousDateTime == currentDateTime && currentDuration == this.previousDuration) {
      return;
    }

    this.previousDateTime = currentDateTime;
    this.previousDuration = currentDuration;

    if (currentDuration <= 0) {
      return;
    }

    // trigger a data change so it will check the calendar (allows a debounceTime so we don't keep hitting the server)
    this.dateTimeChangedSubject.next();
  }
}

