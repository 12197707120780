import { AfterContentInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DwChartConfig, DwChartPreferences, DwChartTypes } from '../../models/dw-chart-preferences.model';
import { DwChartBuilderService } from '../../services/dw-chart-builder.service';
import { DwContainerOptions, DwGridColumnPreference, DwGridEvent, DwQuery } from '@devwareapps/devware-cap';
import { DwChartDataSelectedEvent } from '../../models/dw-chart-data-selected-event.model';
@Component({
  selector: 'dw-chart-tile',
  templateUrl: './dw-chart-tile.component.html',
  styleUrls: ['./dw-chart-tile.component.scss']
})
export class DwChartTileComponent implements OnInit, OnChanges, AfterContentInit {

  @Input() chartPreferences: DwChartPreferences
  @Input() data: any[];
  @Input() containerOptions : DwContainerOptions;
  
  @Input() query: DwQuery;
  
  @Input() additionalText: string;
  
  @Output() dataSelected = new EventEmitter<DwChartDataSelectedEvent>();
  
  chartConfig: DwChartConfig;
  gridPreferences: any;
  hideToggle = false;

  finalContainerOptions : DwContainerOptions;
  columnPreferences: DwGridColumnPreference[];

  constructor(private dwChartBuilderService: DwChartBuilderService) { }

  showChart = true;
  ngOnChanges(changes: SimpleChanges) {
    this.setupChart();
    this.setupContainer();
  }

  ngOnInit(): void {
    this.setupChart();
    this.setupContainer();
  }

  ngAfterContentInit(): void {
  }

  toggleShowChart() {
    this.showChart = !this.showChart;
  }

  onDataSelect(event: any) {
    const itemIndex = event?.element?._index;
    const datasetIndex = event?.element?._datasetIndex;

    if (itemIndex>=0) {
      const datset = event?.dataset[itemIndex];
      const dataItem = this.data[itemIndex];
      const series = this.chartPreferences.series[datasetIndex];

      const dwEvent: DwChartDataSelectedEvent = {
        data: dataItem,
        series: series,
        dataValue: dataItem[series.dataField],
        category: dataItem[this.chartPreferences.categoryField],
        categoryValue: dataItem[this.chartPreferences.categoryValueField],
        browserEvent: event.originalEvent
      };

      this.dataSelected.emit(dwEvent);
    }
  }

  
  onGridEvent(event: DwGridEvent) {
    const dataItem = event.eventData;

    const series = this.chartPreferences.series.find(s => s.dataField === event.eventColumn.field);

    const dataValue = dataItem[event.eventColumn.field];

    const dwEvent: DwChartDataSelectedEvent = {
      data: dataItem,
      series: series,
      dataValue: dataValue,
      category: dataItem[this.chartPreferences.categoryField],
      categoryValue: dataItem[this.chartPreferences.categoryValueField],
      browserEvent: event.browserEvent as PointerEvent
    };

    this.dataSelected.emit(dwEvent);
}


  private setupChart() {
    if (!this.chartPreferences || !this.data) {
      return;
    }

    if ((this.data.length === 1 && this.chartPreferences.chartType != DwChartTypes.pie) || this.chartPreferences.showGridFirst) {
      this.showChart = false;
    }

    if (this.chartPreferences.hideChartDataToggle) {
      this.hideToggle = true;
    }

    this.chartConfig = this.dwChartBuilderService.buildChartConfig(this.chartPreferences, this.data);

    this.gridPreferences = this.dwChartBuilderService.buildGridPreferences(this.chartPreferences, this.query);

    this.columnPreferences = this.gridPreferences.views[0].columnPreferences;
  }


  private setupGrid() {
  }

  private setupContainer() {
    // Allow override of container options
    if (this.containerOptions) {
      this.finalContainerOptions = this.containerOptions;
    } else {
      this.finalContainerOptions = {
        useContainerStyling: true,
        containerAllowCollapse: false,
      }
    }

    this.finalContainerOptions.showTitle = this.chartPreferences?.showContainerTitle;

    this.finalContainerOptions.containerHeaderBackgroundColor = 'blue';
  }

  

}
