import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, mergeMap } from 'rxjs/operators'
import { AppMetaData, CalendarReferenceEntity, CheckRideGroupAllItems, CheckRideRequestCalendarQueryEntity, CheckRideRequestEntity, CheckRideRequestSubStatusAllItems, CheckRideTypeAllItems, CheckrideActiveApplicantCheckridesQueryEntity, PilotExaminerEntity, PilotExaminerLocationEntity, PilotExaminerSearchQueryEntity, PilotExaminerStatusAllItems, StudentApplicantEntity } from "../../../meta-data/app-meta-data.service";
import { DwMetaDataServiceToken, DwMetaDataService, DwOrmDataService, DwOrmDataServiceToken, DwQuery, deepCopy, DwConfigService, DwConfigServiceToken, DwLookupInfo, SuppressErrorMessagesHeader, DwSecurityUserService, DwQueryMetaData } from "@devwareapps/devware-cap";


@Injectable({ providedIn: 'root' })
export class ApplicantRepositoryService {
   

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        @Inject(DwConfigServiceToken) configService: DwConfigService,
        private dwSecurityUserService: DwSecurityUserService,
    ) {
    }

    getApplicationProfile(pilotExaminerId: number, includeCalendarRef:boolean = false): Observable<PilotExaminerEntity> {
        const query = AppMetaData.PilotExaminer.CreateQueryBuilder();

        query.addFilterAnd(filters => filters.Equal(field => field.PilotExaminerId, pilotExaminerId));

        return this.dwOrmDataService.executeQuerySingle(query);
    }


    getActiveCheckrides(currentCheckrideRequestId: number):Observable<CheckrideActiveApplicantCheckridesQueryEntity[]>{
        return AppMetaData.Queries.CheckrideActiveApplicantCheckrides.CreateQueryBuilder(this.dwMetaDataService)
            .pipe(mergeMap(query => {
                query.query = this.cloneQuery(query.query);
                
                const userId = this.dwSecurityUserService.securityContext.ApplicationUser?.UserId;

                if (!userId) {
                    return of([]);
                }

                query.addFilterAnd(f => f.Equal(a => a.StudentApplicantUserId, userId));

                if (currentCheckrideRequestId) {
                    query.addFilterAnd(f => f.NotEqual(a => a.CheckRideRequestId, currentCheckrideRequestId));
                }

                return this.dwOrmDataService.executeQuery(query)
            }));
    }
    
    getCurrentApplicantinfo(): Observable<StudentApplicantEntity> {
        const query = AppMetaData.StudentApplicant.CreateQueryBuilder();

        query.addFilterAnd(filters => filters.Equal(field => field.UserId, this.dwSecurityUserService.securityContext.ApplicationUser?.UserId));

        query.query.FieldSettings.IncludeRootItemFields = false;

        query.addFields(f => [f.ApplicantAddressId, f.UserId, f.ApplicationDescription, f.AviationAuthorityId, f.PilotCertificateNumber, f.Citizenship]);

        // Build user query
        var userQuery = AppMetaData.DwUser.CreateQueryBuilder();

        userQuery.query.FieldSettings.IncludeRootItemFields = false;

        userQuery.addFields(f => [f.FirstName, f.LastName, f.UserPictureMediaId]);

        query.addPrefetch(r => r.DwUser, userQuery.query);
        
        // Build checkride query
        const checkRideQuery = AppMetaData.CheckRideRequest.CreateQueryBuilder();

        checkRideQuery.query.FieldSettings.IncludeRootItemFields = false;

        checkRideQuery.addFields(f => [f.ScheduledPilotExaminerId, f.CheckRideRequestSubStatusId, f.StudentApplicantId, f.ScheduledDateTime]);
        checkRideQuery.addFilterAnd(f => f.NotInList(f => f.CheckRideRequestSubStatusId, [CheckRideRequestSubStatusAllItems.Cancelled, CheckRideRequestSubStatusAllItems.Deleted]))
        // Check the result as well
        checkRideQuery.addPrefetch(r => r.CheckRideResult);

        query.addPrefetch(r => r.CheckRideRequest, checkRideQuery.query);

        return this.dwOrmDataService.executeQuerySingle(query);
    }

    getApplicantCheckrideRequest(checkrideRequestId: number): Observable<CheckRideRequestEntity> {
        const checkRideQuery = AppMetaData.CheckRideRequest.CreateQueryBuilder();

        checkRideQuery.addFilterAnd(filters => filters.Equal(field => field.CheckRideRequestId, checkrideRequestId));

        checkRideQuery.addRelation(r => r.StudentApplicant);

        checkRideQuery.addFilterAnd(filters => filters.Equal(field => AppMetaData.StudentApplicant.Attributes.UserId, this.dwSecurityUserService.securityContext.ApplicationUser?.UserId));
        
        // Check the result as well
        checkRideQuery.addPrefetch(r => r.CheckRideResult);
        checkRideQuery.addPrefetch(r => r.CheckRidePayment);

        return this.dwOrmDataService.executeQuerySingle(checkRideQuery);
    }

    
    cloneQuery(query: DwQuery): DwQuery {
        const newQuery = new DwQuery(query.RootTable);

        const clonedQuery = deepCopy(query);

        return Object.assign(newQuery, clonedQuery);
    }
} 