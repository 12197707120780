import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ChartFilters } from '../../models/chart-filters.model';
import { FinancialDashboardGroup } from '../../models/financial-dashboard-config.model';
import { FinancialsRepositoryService } from '../../services/financials-repository.service';
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';
import { AppMetaDataLookups } from '../../../../meta-data/app-meta-data.service';

@Component({
  selector: 'app-financial-dashboard-filters',
  templateUrl: './financial-dashboard-filters.component.html',
  styleUrls: ['./financial-dashboard-filters.component.scss']
})
export class FinancialDashboardFiltersComponent implements OnInit, OnChanges {
  @Input() filters: ChartFilters;
  @Output() filtersChange = new EventEmitter<ChartFilters>();
  @Input() summaryData: any[] = [];
  @Input() financialDashboardGroup: FinancialDashboardGroup;
  
  years: number[];
  months = [{value: 1, text: 'January'}, {value: 2, text: 'February'}, {value: 3, text: 'March'}, {value: 4, text: 'April'}, {value: 5, text: 'May'}, {value: 6, text: 'June'}, {value: 7, text: 'July'}, {value: 8, text: 'August'}, {value: 9, text: 'September'}, {value: 10, text: 'October'}, {value: 11, text: 'November'}, {value: 12, text: 'December'}]

  pilotExaminerLookupId = AppMetaDataLookups.PilotExaminerAllItems;

  constructor(private financialRepositoryService: FinancialsRepositoryService, private dateTimeUtilService: DateTimeUtilService) { }
  
  ngOnInit(): void {
    this.setupFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupFilters();
  }

  private yearInitComplete = false;

  private setupFilters() {
    if (!this.financialDashboardGroup) {
      return;
    }

    const fields = this.financialRepositoryService.getFinancialFields();

    this.filters = this.filters || {};

    if (this.filters.month == undefined) {
      this.filters.month = null;
    }

    if (this.financialDashboardGroup.filterOptions?.showYear) {

      if (!this.filters.year && !this.yearInitComplete) {
        this.yearInitComplete = true;
        this.filters.year = this.dateTimeUtilService.getCurrentYear();
      }

      this.years = this.summaryData?.filter(d => d[fields.year]).map(d => d[fields.year]) || [this.filters.year];
    }

  }

  onFilterChange() {
    if (this.filters.year==null) {
      this.filters.month = null;
    }

    this.filtersChange.emit(this.filters);
  }

}
