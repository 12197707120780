
<div [hidden]="!checkingAvailability" class="loading-panel">
    <i class="fa fa-spinner fa-pulse fa-fw dw-blue"></i>
    <span>Checking availability...</span>
</div>

<ng-container *ngIf="!checkingAvailability && lastCheckResult">
    <div [hidden]="lastCheckResult.HasConflicts" class="loading-panel">
        <i class="fa fa-check-circle dw-green"></i>
        <span> All Clear</span>
    </div>
    
    <div [hidden]="!lastCheckResult.HasConflicts" class="loading-panel">
        <i class="fa fa-exclamation-triangle dw-red"></i>
        <span> Conflicts with another Checkride or Appointment</span>
    </div>
</ng-container>