import { Injectable } from "@angular/core";
import { DwAction, DwGridActionConfig, DwGridActionKeys, DwActionType, DwButtonType, DwActionHandler, DwMetaDataGridNavigationService, DwMetaDataGridActionState, DwActionResult } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaDataItemNames } from "../../../../meta-data/app-meta-data.service";
import { FinancialsRepositoryService } from "../../services/financials-repository.service";

@DwAction<DwGridActionConfig>({
    keyName: 'grid-return-to-financials',
    display: 'Return to Charts',
    actionType: DwActionType.gridAction,
    isGlobal: false,
    parentItemName: AppMetaDataItemNames.CheckRideRequest,
    allowOnce: true,
    defaultButtonConfig: {
        useParentDisplay: true,
        buttonText: '',
        buttonClass: 'btn btn-warning',
        iconClass: 'fa fa-chart-bar',
        command: 'return',
        buttonType: DwButtonType.standard,
        
    },
})
@Injectable()
export class GridReturnToFinancialsActionService implements DwActionHandler {

    constructor(private metaDataGridNavigationService: DwMetaDataGridNavigationService,
        private financialsRepositoryService: FinancialsRepositoryService
        
        ) { }

    handleAction(actionState: DwMetaDataGridActionState<any>): Observable<DwActionResult> {

        this.financialsRepositoryService.closeGrid();

        return of({
            success: true,
        });
    }
}
