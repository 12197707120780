<div #container class="dates-container-flex">

    <ng-container *ngIf="visibleTimeslotGroupings?.length > 0">
        <div class="date-container" *ngFor="let group of visibleTimeslotGroupings" [ngClass]="getDateContainer(group)">
            <div>{{group.dateDisplay}}</div>
            <div class="timeslots">
                <ng-container *ngFor="let slot of group.timeslots">
                    <div class="timeslot" (click)="setSelectedTimeslot(slot)"
                        [ngClass]="{ 'timeslot-selected': isTimeslotSelected(slot) }">
                        {{slot.timeRangeDisplay}}
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="show-more" [hidden]="!hasMoreTimeslots" (click)="showMore()">Show More</div>
    </ng-container>
    <div *ngIf="visibleTimeslotGroupings?.length == 0">
        <br><br>
        <dw-message [message]="noAvailabilityMessage"></dw-message>
        
    </div>
</div>