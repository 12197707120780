import { Component, EventEmitter, OnInit } from '@angular/core';
import { DwButtonCommand, DwButtonConfig, DwComponent, DwComponentType, DwGridHeightMode, DwMessage, DwMessageSeverity, DwMetaDataGridPreferences, DwMetaDataGridViewPreference, DwModalConfig, DwModalHandler, DwModalResult } from '@devwareapps/devware-cap';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { map } from 'underscore';
import { AppMetaDataLookups, AppMetaDataItemNames, PlanOfActionEntity, PilotExaminerEntity, AcsTestEntity, PlanOfActionSectionEntity } from '../../../../meta-data/app-meta-data.service';
import { PlanOfActionRepositoryService } from '../../../plan-of-action/services/plan-of-action-repository.service';
import { GenerateSectionsOptions } from '../../../plan-of-action/models/generate-sections-options.model';
import { PlanOfActionGeneratorService } from '../../../plan-of-action/services/plan-of-action-generator.service';
import { AcsCodeStorage, AcsCodesStorageItem } from '../../../plan-of-action/models/acs-code-items.model';
import { GenerateCodesOptions } from '../../../plan-of-action/models/generate-codes-options.model';
import { AcsCodeSelectionModalOptions, AcsCodeSelectionModalResult } from '../../../plan-of-action/models/acs-code-selection-modal-options.model';

@Component({
  selector: 'app-generate-questions-modal',
  templateUrl: './generate-questions-modal.component.html',
  styleUrls: ['./generate-questions-modal.component.scss']
})
export class GenerateQuestionsModalComponent implements OnInit, DwModalHandler {

  modalConfig: DwModalConfig<AcsCodeSelectionModalOptions>;
  closeModal = new EventEmitter<DwModalResult<PlanOfActionEntity>>();
 
  planOfAction: PlanOfActionEntity;
  planOfActionSection: PlanOfActionSectionEntity;
  message: DwMessage;
  
  options: GenerateCodesOptions;
  constructor(private planOfActionRespository: PlanOfActionRepositoryService,
    private planOfActionGeneratorSection: PlanOfActionGeneratorService

  ) { }

  ngOnInit(): void {
    this.planOfAction = this.modalConfig.data.planOfAction;
    this.planOfActionSection = this.modalConfig.data.planOfActionSection;

    this.options = {
      acsCodes:[],
      questions:[],
      allowQuestionSelect: this.modalConfig.data.allowQuestionSelect,
      useQuestionBank: false
    }
  }

  buttonClick(buttonConfig: DwButtonConfig): Observable<DwModalResult<any>> {
    let result = new DwModalResult<AcsCodeSelectionModalResult>();

    result.cancelled = false;

    switch (buttonConfig.command) {
      case DwButtonCommand.ok:
      case DwButtonCommand.saveAndClose:
      case DwButtonCommand.save:
        // moved to service to this modal can be reused
        //this.generateQuestions();

        result.data = {
          GenerateCodesOptions: this.options,
        };

        result.closeModal = true;
        break;
      case DwButtonCommand.cancel:
        result.closeModal = true;
        result.cancelled = true;
    }

    return of(result);
  }

  generateQuestions() {
     this.planOfActionGeneratorSection.generateQuestions(this.planOfActionSection, this.planOfActionSection.PlanOfActionSectionQuestion, this.options);
  }

  setMessage(message: string, severity: DwMessageSeverity = DwMessageSeverity.error) {
    this.message = {
      messageBody: message,
      severity: severity
    };
  }

  clearMessage() {
    this.message = null;
  }

}


