<ng-container *ngIf="false">
    <div class="card card-container examiner-card">
        <div class="card-body">
            <div class="data-container-flex">
                <div class="column1">
                    <div class="title">{{searchResult.UserIdDisplay}}</div>
                    <img [src]="imagePath || defaultAvatarMediaUrl"
                        class="avatar-small avatar-banner-position avatar-image">
                </div>
                <div class="column3">
                    <div dwRow style="min-width: 250px">
                        <div dwCol="12">
                            <label class="label">Airport</label><br>
                            {{searchResult.LocationDistancesAirportIdDisplay}}
                        </div>
                        <div dwCol="6">
                            <label class="label">Distance</label><br>
                            {{searchResult.LocationDistancesDistance | number: '1.0-0'}} miles
                        </div>
                        <div dwCol="6">
                            <label class="label">Required Time</label><br>
                            {{searchResult.ScenarioTimeRequiredHours}} hours
                        </div>
                        <div dwCol="6">
                            <label class="label">Cost <i class="fa fa-info-circle"
                                    title="Checkride cost excluding Pilexos fee and any applicable Travel Costs"></i></label><br>
                            {{checkrideCost | currency}}
                        </div>
                        <div dwCol="6">
                            <label class="label">Timezone</label><br>
                            {{searchResult.AirportAddressTimezoneIdDisplay}}
                        </div>
                    </div>
                </div>
                <div class="column4">
                    <app-schedule-selector *ngIf="showDateSelection" [searchResult]="searchResult"
                        [isContainerSelected]="isContainerSelected()" [(selectedDateTime)]="selectedDateTime"
                        (selectedDateTimeChange)="setSelectedDateTime($event)"></app-schedule-selector>
                    <app-schedule-detail *ngIf="!showDateSelection" [searchResult]="searchresult"
                        [selectedDateTime]="selectedDateTime"></app-schedule-detail>
                </div>
            </div>
        </div>
    </div>
</ng-container>